import { configureStore } from '@reduxjs/toolkit';
import trackingNumbers from '../views/TrackingNumbers/state';
import trackingNumber from '../views/TrackingNumber/reduxState';
import ecomOrders from '../views/EcomOrders/state';
import ecomPickingList from '../views/EcomPickingList/state';
import ecomPacking from '../views/EcomPacking/state';
import user from './user';
import storeInfo from './storeInfo';
import lastBarcode from './lastBarcode';
import printers from './printers';
import maintenance from './maintenance';

export const store = configureStore({
  reducer: {
    user,
    storeInfo,
    trackingNumbers,
    trackingNumber,
    ecomOrders,
    ecomPickingList,
    ecomPacking,
    lastBarcode,
    printers,
    maintenance,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});
