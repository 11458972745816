import React from 'react';
import { Button, SplitButton, Dropdown, Badge } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import _ from 'lodash';
import { Link, useParams } from 'react-router-dom';
import RowField from '../../components/widgets/RowField';
import MountStatus from '../OrdersMount/components/MountStatus';
import { infoIsError } from './api';
import { getParcelStatus, humanizeParcelStatus } from './AddressingParcels';
import packageImage from '../../images/package-plus.png';

const OrderFields = ({
  children,
  completedLabel,
  cancelledLabel,
  isCompleted,
  isCancelled,
  isReplacedBy,
  replacedByOrder,
  isReplacement,
  replacementOrder,
  displayOrderNumber,
  displayAddress,
  displayMovingBy,
  onPreviewBarcode,
  store,
  row,
}) => {
  const { t } = useTranslation();
  return (
    <div className="order-item">
      {isCompleted && (
        <div className="text-center font-weight-bold text-danger">{completedLabel}</div>
      )}
      {isCancelled && (
        <div className="text-center font-weight-bold text-danger">{cancelledLabel}</div>
      )}
      {isReplacedBy && (
        <div className="text-center font-weight-bold text-danger mb-2">
          <span>{replacedByOrder} </span>
          <Link
            className="text-danger"
            to={`/${store}/orders?order=${row.free_order_link.replacement_order_number}`}
          >
            {`${row.free_order_link.replacement_order_number}-${row.free_order_link.replacement_shipping_group_number}`}
          </Link>
        </div>
      )}
      {isReplacement && (
        <div className="text-center font-weight-bold text-primary mb-2">
          <span>{replacementOrder} </span>
          <Link to={`/${store}/orders?order=${row.free_order_link.order_number}`}>
            {`${row.free_order_link.order_number}-${row.free_order_link.shipping_group_number}`}
          </Link>
        </div>
      )}
      {displayOrderNumber && (
        <RowField
          textBreak
          hideMobileLabel
          boldMobileValue
          label={t('orders.order', 'Order')}
          value={<Link to={`/${store}/orders?order=${row.order_number}`}>{row.order_number}</Link>}
        />
      )}
      <RowField
        hideMobileLabel
        boldMobileValue
        className="order-shipping-group"
        label={t('orders.shippingGroup', 'Shipping group')}
        value={
          <div className="d-flex align-items-center">
            <span className="text-break">{row.shipping_group_number}</span>
            {onPreviewBarcode ? (
              <Button
                variant="yellow"
                size="sm"
                className="ml-2 barcode-button"
                title={t('address.viewBarcode', 'View barcode')}
                onClick={onPreviewBarcode}
              >
                <img
                  className="img-icon"
                  alt="barcode"
                  src="/icons/barcode_line_black.svg"
                />
              </Button>
            ) : null}
          </div>
        }
      />
      <RowField
        hideMobileLabel
        boldMobileValue
        className="order-shipping-group"
        label={t('report.orders.shippingMethod', 'Shipping method')}
        value={row.shipping_method}
      />
      {row.parcelStatus && row.source === 'stores_fulfiller_api' && (
        <RowField
          hideMobileLabel
          boldMobileValue
          textBreak
          label={t('orders.status', 'Status')}
          value={humanizeParcelStatus(row.parcelStatus)}
        />
      )}
      {row.status && row.source !== 'stores_fulfiller_api' && (
        <RowField
          hideMobileLabel
          boldMobileValue
          textBreak
          label={t('orders.status', 'Status')}
          value={row.status}
        />
      )}
      {displayAddress && (
        <RowField
          inline
          textBreak
          label={
            displayMovingBy
              ? t('orders.initialAddress', 'Initial address')
              : t('orders.address', 'Address')
          }
          value={row.address}
        />
      )}
      {children}
      {displayMovingBy && (
        <RowField
          label={t('orders.movingBy', 'Moving by')}
          value={row.moving_by_user}
          textBreak
        />
      )}
    </div>
  );
};

const OrderActions = ({
  row,
  onPick,
  onMove,
  onRemove,
  onBackToSale,
  onAssign,
  canPick,
  canBackToSale,
}) => {
  return (
    <div className="order-actions">
      {onPick && canPick && (
        <SplitButton
          onClick={onPick}
          disabled={row.disabled}
          size="sm"
          variant="primary"
          title={
            <div className="d-flex flex-row align-items-center">
              <i className="vtmn-icon_account" />
              <Trans i18nKey="shared.pick">Pick</Trans>
            </div>
          }
        >
          <Dropdown.Item
            onClick={onMove}
            disabled={row.disabled}
          >
            <div className="d-flex flex-row align-items-center">
              <i className="vtmn-icon_arrow1_up" />
              <Trans i18nKey="shared.move">Move</Trans>
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={onRemove}
            disabled={row.disabled}
          >
            <div className="d-flex flex-row align-items-center">
              <i className="vtmn-icon_delete vtmn-icon-22px" />
              <Trans i18nKey="shared.remove">Remove</Trans>
            </div>
          </Dropdown.Item>
        </SplitButton>
      )}
      {onBackToSale && canBackToSale && (
        <SplitButton
          onClick={onBackToSale}
          disabled={row.disabled}
          size="sm"
          style={{ maxWidth: '125px' }}
          variant="danger"
          title={
            <div className="d-flex flex-row align-items-center" style={{ maxWidth: '80px' }}>
              <Trans i18nKey="orders.putInStock.putInStock">Put in store stock</Trans>
            </div>
          }
        >
          <Dropdown.Item
            onClick={onRemove}
            disabled={row.disabled}
          >
            <div className="d-flex flex-row align-items-center">
              <i className="vtmn-icon_delete vtmn-icon-22px" />
              <Trans i18nKey="shared.remove">Remove</Trans>
            </div>
          </Dropdown.Item>
        </SplitButton>
      )}
      {onRemove && !canPick && !canBackToSale && (
        <Button
          onClick={onRemove}
          size="sm"
          variant="primary"
          className="d-flex flex-row align-items-center m-0 pl-2 pr-2"
        >
          <i className="vtmn-icon_delete" />
          <Trans i18nKey="shared.remove">Remove</Trans>
        </Button>
      )}
      {onAssign && (
        <Button
          onClick={onAssign}
          size="sm"
          variant="primary"
          className="d-flex flex-row align-items-center m-0 pl-2 pr-2"
        >
          <i className="vtmn-icon_arrow1_down" />
          <Trans i18nKey="shared.assign">Assign</Trans>
        </Button>
      )}
    </div>
  );
};

const NoPackagingRow = ({
  row,
  items,
  onPick,
  onMove,
  onRemove,
  onBackToSale,
  onAssign,
  onPreviewItems,
  onPreviewBarcode,
  displayOrderNumber,
  displayAddress,
  displayMovingBy,
  displayQty,
  isCancelled,
  isCompleted,
  canBackToSale,
  mountStatus,
}) => {
  const { t } = useTranslation();
  const { store } = useParams();
  const item = items && items[0] ? items[0] : {};
  const isReplacedBy = row.free_order_link?.link_type === 'is_replaced_by';
  const isReplacement = row.free_order_link?.link_type === 'is_replacement';
  const replacedByOrder = t('freeOrder.replacedByOrder', 'This order was replaced by free order:');
  const replacementOrder = t('freeOrder.replacementOrder', 'This is a free order replacing order:');

  return (
    <div
      className="order-item-row"
      key={row.id}
    >
      <div className="order-item-image">
        <img
          src={`${item.image}?f=200x200`}
          alt=""
          onClick={() => onPreviewItems([item], row)}
        />
        <MountStatus status={mountStatus} />
      </div>
      <OrderFields
        completedLabel={
          <Trans i18nKey="orders.sgCompleted">This shipping group was completed</Trans>
        }
        cancelledLabel={
          <Trans i18nKey="orders.sgCanceled">This shipping group was cancelled</Trans>
        }
        isCompleted={isCompleted}
        isCancelled={isCancelled}
        isReplacedBy={isReplacedBy}
        replacedByOrder={replacedByOrder}
        isReplacement={isReplacement}
        replacementOrder={replacementOrder}
        displayOrderNumber={displayOrderNumber}
        displayAddress={displayAddress}
        displayMovingBy={displayMovingBy}
        onPreviewBarcode={onPreviewBarcode}
        store={store}
        row={row}
      >
        {displayQty && (
          <RowField
            inline
            label={t('items.qty', 'Qty')}
            value={row.qty}
          />
        )}
        <RowField
          hideMobileLabel
          textBreak
          label={t('orders.item', 'Item')}
          value={`${row.item_id} - ${item.item_description}`}
        />
      </OrderFields>
      <OrderActions
        row={row}
        canPick={!isCancelled}
        canBackToSale={canBackToSale}
        onPick={onPick}
        onMove={onMove}
        onRemove={onRemove}
        onBackToSale={onBackToSale}
        onAssign={onAssign}
      />
    </div>
  );
};

const ParcelRow = ({
  row,
  items,
  onPick,
  onAssign,
  onMove,
  onRemove,
  onBackToSale,
  onPreviewItems,
  onPreviewBarcode,
  displayOrderNumber,
  displayAddress,
  displayMovingBy,
  isCancelled,
  isCompleted,
  canBackToSale,
  mountStatus,
}) => {
  const { t } = useTranslation();
  const { store } = useParams();
  const item = items && items[0] ? items[0] : {};
  const itemCount = !_.isEmpty(items) ? items.length : 0;
  const showImage = !!(
    row.shipping_method &&
    row.shipping_method === 'IN_STORE_PREPARE_AND_PICKUP' &&
    item.image
  );
  const isReplacedBy = row.free_order_link?.link_type === 'is_replaced_by';
  const isReplacement = row.free_order_link?.link_type === 'is_replacement';
  const replacedByOrder = t('freeOrder.replacedByOrder', 'This order was replaced by free order:');
  const replacementOrder = t('freeOrder.replacementOrder', 'This is a free order replacing order:');

  return (
    <div
      className="order-item-row"
      key={row.id}
    >
      <div
        className={`order-item-image${!showImage ? ' package-plus' : ''}`}
        onClick={() => onPreviewItems(items, row)}
      >
        <img
          src={showImage ? `${item.image}?f=200x200` : packageImage}
          alt="parcel"
        />
        {row.customerTag ? (
          <div className={`order-customer-tag${showImage ? ' absolute' : ''}`}>
            <Badge variant="dark">{row.customerTag}</Badge>
          </div>
        ) : null}
        {itemCount > 1 && (
          <Badge
            variant="light"
            className="order-item-counter"
          >{`+${itemCount}`}</Badge>
        )}
        <MountStatus status={mountStatus} />
      </div>
      <OrderFields
        completedLabel={<Trans i18nKey="orders.orderCompleted">This order was completed</Trans>}
        cancelledLabel={<Trans i18nKey="orders.orderCanceled">This order was cancelled</Trans>}
        isCompleted={isCompleted}
        isCancelled={isCancelled}
        isReplacedBy={isReplacedBy}
        replacedByOrder={replacedByOrder}
        isReplacement={isReplacement}
        replacementOrder={replacementOrder}
        displayOrderNumber={displayOrderNumber}
        displayAddress={displayAddress}
        displayMovingBy={displayMovingBy}
        onPreviewBarcode={onPreviewBarcode}
        store={store}
        row={row}
      >
        <RowField
          inline
          textBreak
          label={t('orders.parcel', 'Parcel')}
          value={row.parcel_number}
        />
      </OrderFields>
      <OrderActions
        row={row}
        canPick={!isCancelled}
        canBackToSale={canBackToSale}
        onPick={onPick}
        onMove={onMove}
        onRemove={onRemove}
        onBackToSale={onBackToSale}
        onAssign={onAssign}
      />
    </div>
  );
};

export const OrderStockRow = ({
  row,
  items,
  onPick,
  onMove,
  onRemove,
  onBackToSale,
  onAssign,
  onPreviewItems,
  onPreviewBarcode,
  displayOrderNumber,
  displayAddress,
  displayMovingBy,
  isCancelled,
  isCompleted,
  canBackToSale,
  mountStatus,
}) => {
  if (row.packaging_type === 'no-packaging') {
    return (
      <NoPackagingRow
        row={row}
        items={items}
        onPick={onPick}
        onMove={onMove}
        onRemove={onRemove}
        onBackToSale={onBackToSale}
        onAssign={onAssign}
        onPreviewItems={onPreviewItems}
        onPreviewBarcode={onPreviewBarcode}
        displayOrderNumber={displayOrderNumber}
        displayAddress={displayAddress}
        displayMovingBy={displayMovingBy}
        isCancelled={isCancelled}
        isCompleted={isCompleted}
        canBackToSale={canBackToSale}
        mountStatus={mountStatus}
      />
    );
  }

  if (row.packaging_type === 'ecom-prep') {
    const item = items.find((x) => x.item_id === row.item_id);
    if (item) {
      item.quantity = row.qty;
    }
    return (
      <NoPackagingRow
        row={row}
        items={item ? [item] : []}
        onPick={onPick}
        onMove={onMove}
        onRemove={onRemove}
        onBackToSale={onBackToSale}
        onAssign={onAssign}
        onPreviewItems={onPreviewItems}
        onPreviewBarcode={onPreviewBarcode}
        displayOrderNumber={displayOrderNumber}
        displayAddress={displayAddress}
        displayQty
        displayMovingBy={displayMovingBy}
        isCancelled={isCancelled}
        isCompleted={isCompleted}
        canBackToSale={canBackToSale}
        mountStatus={mountStatus}
      />
    );
  }

  return (
    <ParcelRow
      row={row}
      items={items}
      onPick={onPick}
      onMove={onMove}
      onRemove={onRemove}
      onBackToSale={onBackToSale}
      onAssign={onAssign}
      onPreviewItems={onPreviewItems}
      onPreviewBarcode={onPreviewBarcode}
      displayOrderNumber={displayOrderNumber}
      displayAddress={displayAddress}
      displayMovingBy={displayMovingBy}
      isCancelled={isCancelled}
      isCompleted={isCompleted}
      canBackToSale={canBackToSale}
      mountStatus={mountStatus}
    />
  );
};

const getItems = (stockOrder, orderItems) => {
  if (infoIsError(orderItems)) {
    return null;
  } else if (stockOrder.packaging_type === 'no-packaging') {
    const items = orderItems
      .filter(
        (x) =>
          x.item_id === stockOrder.item_id &&
          x.shipping_group_number === stockOrder.shipping_group_number,
      )
      .map((x) => ({
        ...x,
        quantity: 1,
      }));
    return items;
  } else {
    const items = orderItems.filter(
      (x) => x.shipping_group_number === stockOrder.shipping_group_number,
    );
    return items;
  }
};

const OrderStock = ({
  stock,
  mountStatus,
  items,
  shippingGroups,
  checkCompleted,
  checkCancelled,
  checkCanBackToSale,
  customerTag,
  onPick,
  onPickAll,
  canPickAll,
  onMove,
  onRemove,
  onBackToSale,
  onPreviewItems,
  onPreviewBarcode,
}) => {
  const inStock = stock && stock.length > 0;
  if (!inStock) {
    return null;
  }
  return (
    <>
      <h3 className="text-center mt-3">
        <Trans i18nKey="orders.orderStock">In stock</Trans>
      </h3>
      <div className="mobile-table-wrapper order-stock-content">
        {stock.map((row, index) => {
          const isCancelled = checkCancelled(row);
          const isCompleted = checkCompleted(row);
          const canBackToSale = checkCanBackToSale(row);
          const isLocker = row.address_type === 'locker';
          const sg = shippingGroups.find((x) => x.id === row.shipping_group_number);
          const parcelStatus = getParcelStatus(row, sg);
          const isNewAddress = index === 0 || stock[index - 1]?.address !== row.address;
          return (
            <React.Fragment key={row.address}>
              {isNewAddress && (
                <div className="address">
                  <h4 className="title">{row.address}</h4>
                  <Button
                    onClick={() => onPickAll({ address: row.address })}
                    disabled={!canPickAll(row.address)}
                    size="sm"
                    variant="primary"
                    className="d-flex flex-row align-items-center m-0 pl-2 pr-2"
                  >
                    <Trans i18nKey="orders.pickAll">Pick all</Trans>
                  </Button>
                </div>
              )}
              <OrderStockRow
                key={row.id}
                items={getItems(row, items)}
                row={{
                  ...row,
                  customerTag,
                  status: sg?.status,
                  parcelStatus,
                }}
                onPick={() => onPick({ id: row.id })}
                onMove={() => onMove({ id: row.id })}
                onRemove={() => onRemove({ id: row.id })}
                onBackToSale={() => onBackToSale({ sg: row.shipping_group_number })}
                onPreviewItems={onPreviewItems}
                onPreviewBarcode={
                  isLocker
                    ? () => onPreviewBarcode(row.shipperbox_number || row.shipping_group_number)
                    : null
                }
                isCancelled={isCancelled}
                isCompleted={isCompleted}
                canBackToSale={canBackToSale}
                mountStatus={mountStatus}
                displayAddress
              />
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default OrderStock;
