import React from 'react';
import { Trans } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';

const RowBox = ({ box, onEdit, onDelete, readonly, editable }) => {
  return(
    <tr key={box.id}>
      <td>{box.name}</td>
      <td>{box.height}</td>
      <td>{box.width}</td>
      <td>{box.length}</td>
      <td>{box.weight}</td>
      {!readonly && (
        <td>
          {editable ? (
            <>
              <Button
                className="icon-button ml-2"
                variant="light"
                onClick={() => onEdit(box)}
              >
                <i className="m-0 vtmn-icon_edit" />
              </Button>
              <Button
                className="icon-button ml-2"
                variant="danger"
                onClick={() => onDelete(box)}
              >
                <i className="m-0 vtmn-icon_delete" />
              </Button>
            </>
          ) : '-'}
        </td>
      )}
    </tr>
  );
}

const ManageBoxSizeModal = ({ show, boxes, onEdit, onDelete, onNewBox, onHide, readonly }) => {
  return (
    <Modal
      dialogClassName="modal-95"
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="adminAddreses.manageBoxSize">Manage boxes size</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <table className="table table-hover mb-0">
          <thead>
          <tr>
            <th>
              <Trans i18nKey="adminAddreses.boxSizeName">Name</Trans>
            </th>
            <th>
              <Trans i18nKey="adminAddreses.boxSizHeight">Height (cm)</Trans>
            </th>
            <th>
              <Trans i18nKey="adminAddreses.boxSizeWidth">Width (cm)</Trans>
            </th>
            <th>
              <Trans i18nKey="adminAddreses.boxSizeLength">Length (cm)</Trans>
            </th>
            <th>
              <Trans i18nKey="adminAddreses.boxSizeWeight">Weight (kg)</Trans>
            </th>
            {!readonly && (
              <th>
                <Trans i18nKey="adminAddreses.actions">Actions</Trans>
              </th>
            )}
          </tr>
          </thead>
          <tbody>
            {boxes && boxes.map(box => (
              <RowBox
                key={box.id}
                box={box}
                onEdit={onEdit}
                onDelete={onDelete}
                editable={box.source !== 'stores_fulfiller_api'}
                readonly={readonly}
              />
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        {!readonly && (
          <Button
            variant="primary"
            onClick={onNewBox}
          >
            <Trans i18nKey="adminAddreses.newBoxSize">New box size</Trans>
          </Button>
        )}
        <Button
          variant="success"
          onClick={onHide}
        >
          <Trans i18nKey="shared.confirm">Confirm</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ManageBoxSizeModal;
