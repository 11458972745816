import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import useRequest from '../../../functions/useRequest';
import Loader from '../../../components/Loader';
import ErrorMessage from '../../../components/widgets/errorMessage';
import ItemScannerInput from '../../../components/widgets/ItemScannerInput';

import {
  getMissing,
  resolveProblemMissing,
  anomalyExchangeCC1H,
  anomalyExchange,
} from './api';
import {
  CanceledMessage,
  CompletedMessage,
  InStockMessage,
  NotInStockMessage,
  WrongStoreMessage,
  AnomalyExchangeError,
  ManualMissingMessage,
} from './messages';
import ProblemDetails from './ProblemDetails';
import { TNRelatedSgippingGroupMessage } from '../../../components/widgets/RelatedSgippingGroupMessage';
import { useStoreInfoReduxState } from '../../../store/storeInfo';

const ProblemMissing = ({
  id,
  user,
  updateTokens,
  onClose,
  epc,
  setEpc,
}) => {
  const { t } = useTranslation();
  const [{
    loading: fetching,
    error: fetchError,
    data: problem,
  }, fetchMissing] = useRequest(getMissing);
  const { store } = useParams();
  const [actionError, setActionError] = useState(null);
  const [itemId, setItemId] = useState('');
  const [address, setAddress] = useState('');
  const [cantFind, setCantFind] = useState(false);
  const [step, setStep] = useState('cc1h');
  const { store: storeInfo } = useStoreInfoReduxState();

  useEffect(() => {
    if (id) {
      setActionError(null);
      setItemId('');
      setAddress('');
      setCantFind(false);
      setStep('cc1h');
      fetchMissing(user, updateTokens, id, store);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [{
    loading: resolving,
  }, doResolve] = useRequest(resolveProblemMissing, {
    onSuccess: onClose,
    onError: setActionError,
  });
  const [{
    loading: creatingCC1h,
  }, doExchangeCC1H] = useRequest(anomalyExchangeCC1H, {
    onSuccess: onClose,
    onError: setActionError,
  });
  const [{
    loading: exhanging,
  }, doExchange] = useRequest(anomalyExchange, {
    onSuccess: onClose,
    onError: setActionError,
  });

  const loading = fetching || resolving || creatingCC1h || exhanging;

  const isCanceled = problem?.shipping_group?.is_canceled;
  const isCompleted = problem?.shipping_group?.is_completed;
  const isWrongStore = !isCanceled && !isCompleted && problem?.shipping_group?.is_wrong_store;
  const canDismiss = isCanceled || isCompleted || isWrongStore || !storeInfo.free_orders_enabled;
  const showManualMising = !isCanceled && !isCompleted && !isWrongStore && !storeInfo.free_orders_enabled;

  const isCC1hStep = step === 'cc1h';
  const isCreateOrderStep = step === 'create_order';

  const inStock = !canDismiss && !!problem?.item.stock && !cantFind;
  const notInStock = !canDismiss && (!problem?.item.stock || cantFind);
  const hasRelatedSG = !!problem?.related_shipping_groups?.length;

  return (
    <Modal
      show={!!id}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <Trans i18nKey="reviewProblems.missingItem">Missing item</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        {loading && <Loader />}
        {!loading && hasRelatedSG && (
          <TNRelatedSgippingGroupMessage
            relatedShippingGroups={problem.related_shipping_groups}
            mode="contained"
          />
        )}
        {!loading && fetchError && <ErrorMessage error={fetchError} />}
        {!loading && !fetchError && !!problem && (
          <>
            <ProblemDetails problem={problem}/>
            {actionError && notInStock && <AnomalyExchangeError error={actionError} />}
            {actionError && inStock && <ErrorMessage error={actionError} />}
            {isWrongStore && <WrongStoreMessage problem={problem} missing />}
            {isCanceled && <CanceledMessage missing />}
            {isCompleted && <CompletedMessage missing />}
            {notInStock && <NotInStockMessage />}
            {showManualMising && <ManualMissingMessage />}
            {inStock && (
              <>
                {isCC1hStep && <InStockMessage step={step} />}
                {(isCC1hStep || isCreateOrderStep) && (
                  <ItemScannerInput
                    user={user}
                    updateTokens={updateTokens}
                    disabled={isCreateOrderStep}
                    isValid={itemId && itemId === problem.item.item_id}
                    isInvalid={itemId && itemId !== problem.item.item_id}
                    invalidMessage={t('reviewProblems.invalidItemCode', 'Item code is not correct')}
                    placeholder={t('reviewProblems.inputItemCode', 'Scan item...')}
                    value={itemId}
                    setEpc={setEpc}
                    onChange={(val) => setItemId(val)}
                  />
                )}
                {isCC1hStep && (<div className="mt-3 ml-1 mr-1">{t('reviewProblems.inStockDidYouFind', 'Did you find the item?')}</div>)}
                {isCreateOrderStep && <InStockMessage step={step} />}
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          <Trans i18nKey="shared.close">Close</Trans>
        </Button>
        {!loading && (canDismiss || actionError) && (
          <Button
            variant="success"
            disabled={loading}
            onClick={() => doResolve(user, updateTokens, store, id)}
          >
            <Trans i18nKey="reviewProblems.resolve">Mark resolved</Trans>
          </Button>
        )}
        {!fetching && inStock && isCC1hStep && (
          <Button
            variant="danger"
            onClick={() => setCantFind(true)}
          >
            <Trans i18nKey="shared.no">No</Trans>
          </Button>
        )}
        {!fetching && inStock && isCC1hStep && (
          <Button
            variant="primary"
            disabled={itemId !== problem.item.item_id}
            onClick={() => setStep('create_order')}
          >
            <Trans i18nKey="shared.yes">Yes</Trans>
          </Button>
        )}
        {!fetching && inStock && isCreateOrderStep && (
          <Button
            variant="primary"
            disabled={
              loading
              || itemId !== problem.item.item_id
            }
            onClick={() => doExchangeCC1H(user, updateTokens, {
              id,
              store,
              item_id: itemId,
              epc,
              address,
            })}
          >
            {t('reviewProblems.createCC1h', 'Create free order C&C1h')}
          </Button>
        )}
        {!fetching && notInStock && (
          <Button
            variant="primary"
            disabled={loading}
            onClick={() => doExchange(user, updateTokens, store, id)}
          >
            <Trans i18nKey="reviewProblems.freeOrder">Create free order</Trans>
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ProblemMissing;
