import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { scanImageData } from 'zbar.wasm';
import Loader from '../../Loader';
import { logEvent, logBarcodeReadCanceledEvent } from '../../../functions/analytics';
import ScannerControls from './ScannerControls';
import { getActiveTrack, listVideoInputDevices } from './utils';
import { setLastBarcode } from '../../../store/lastBarcode';

const LOG_READ_CANCELLED_TIMEOUT = 5000;
const SCAN_PROID_MS = 300;
const FRAME_WIDTH = 3;

const FORMAT_MAP = {
  0: 'ZBAR_NONE',
  1: 'ZBAR_PARTIAL',
  2: 'ZBAR_EAN2',
  5: 'ZBAR_EAN5',
  8: 'ZBAR_EAN8',
  9: 'ZBAR_UPCE',
  10: 'ZBAR_ISBN10',
  12: 'ZBAR_UPCA',
  13: 'ZBAR_EAN13',
  14: 'ZBAR_ISBN13',
  15: 'ZBAR_COMPOSITE',
  25: 'ZBAR_I25',
  34: 'ZBAR_DATABAR',
  35: 'ZBAR_DATABAR_EXP',
  38: 'ZBAR_CODABAR',
  39: 'ZBAR_CODE39',
  57: 'ZBAR_PDF417',
  64: 'ZBAR_QRCODE',
  93: 'ZBAR_CODE93',
  128: 'ZBAR_CODE128',
};

class ZbarScanner extends PureComponent {
  containerRef = null;
  video = null;
  srcWidth = 640;
  srcHeight = 480;
  canvas = null;
  frameSize = {
    width: 640,
    height: 480,
    left: 0,
    top: 0,
  };
  isStarting = false;
  isStopping = false;
  scanTimeoutRef = null;

  state = {
    frameMode: 'off',  // qrcode / barcode / off
    isRunning: false,
    capabilities: {},
    devices: [],
    deviceId: null,
    torch: false,
  };

  constructor(props) {
    super(props);
    if (props.defaultFrameMode) {
      this.state.frameMode = props.defaultFrameMode;
    }
    const { deviceId, torch } = this.getLocalStorageState();
    this.state.deviceId = deviceId;
    this.state.torch = torch;
    this.containerRef = React.createRef();
    this.startTimestamp = (new Date()).getTime();
  }

  componentDidMount() {
    listVideoInputDevices()
      .then((devices) => {
        this.setState({ devices });
      })
      .catch((e) => console.error("Failed to load devices", e));

    if ('onorientationchange' in window) {
      window.addEventListener('orientationchange', this.recalcDimensions, false);
    } else if (window.screen && window.screen.orientation && window.screen.orientation.addEventListener) {
      window.screen.orientation.addEventListener('change', this.recalcDimensions);
    }
  }

  componentWillUnmount() {
    const { isRunning } = this.state;
    if (isRunning) {
      this.stopScanner();
    }

    if ('onorientationchange' in window) {
      window.removeEventListener('orientationchange', this.recalcDimensions, false);
    } else if (window.screen && window.screen.orientation && window.screen.orientation.removeEventListener) {
      window.screen.orientation.removeEventListener('change', this.recalcDimensions);
    }
    if (this.scanTimeoutRef) {
      clearTimeout(this.scanTimeoutRef)
    }
  }

  setSrcSize = (srcWidth, srcHeight) => {
    this.srcWidth = srcWidth;
    this.srcHeight = srcHeight;
  }

  calcFrameSize = () => {
    const srcWidth = this.srcWidth;
    const srcHeight = this.srcHeight;
    const videoWidth = Math.ceil(srcWidth);
    const videoHeight = Math.ceil(srcHeight);
    // off
    let width = videoWidth;
    let height = videoHeight;
    let left = 0
    let top = 0;
    if (this.state.frameMode === 'qrcode') {
      const size = Math.ceil(srcWidth < srcHeight ? srcWidth * 0.6 : srcHeight * 0.6);
      width = size;
      height = size;
      left = (videoWidth - size) / 2;
      top = (videoHeight - size) / 2;
    } else if (this.state.frameMode === 'barcode') {
      width = Math.ceil(srcWidth * 0.6);
      height = Math.ceil(width / 2);
      left = (videoWidth - width) / 2;
      top = (videoHeight - height) / 2;
    }
    this.frameSize = {
      width,
      height,
      left,
      top,
    };
    return this.frameSize;
  }

  onChangeFrameMode = (frameMode) => {
    this.setState({ frameMode }, this.restartScanner);
  }

  restartScanner = async () => {
    try {
      this.stopScanner();
      await this.startScanner();
    } catch (e) {
      console.error('Error during Zbar restartScanner:', e);
      this.isStopping = false;
      this.isStarting = false;
      this.stopScanner();
      this.clearLocalStorageState();
    }
  }

  recalcDimensions = () => setTimeout(() => {
    if (this.video && this.video.srcObject) {
      const track = getActiveTrack(this.video);
      const deviceSettings = track && track.getSettings();
      const { width, height } = deviceSettings ? deviceSettings : { width: 480, height: 640 };
      if (this.canvas) {
        this.canvas.width = width;
        this.canvas.height = height;
        this.setSrcSize(width, height);
        this.calcFrameSize();
        this.drawFrame();
      }
    }
  // on ios video track resolution changes not immediately
  }, 100);

  onToggleCamera = () => {
    const { devices, deviceId } = this.state;
    const currentIndex = devices.findIndex(x => x.deviceId === deviceId);
    let nextIndex = currentIndex + 1;
    if (nextIndex >= devices.length) {
      nextIndex = 0;
    }
    const nextDevice = devices[nextIndex];
    this.setDeviceIdState(nextDevice.deviceId, this.restartScanner);
  }

  drawBarcodeLine = (ctx) => {
    const { width } = this.frameSize;
    ctx.strokeStyle = 'red';
    ctx.fillStyle = 'red';
    const lineWidth = 2;
    const lineLength = width + 16;
    ctx.fillRect(
      this.srcWidth / 2 - lineLength / 2,
      this.srcHeight / 2 - lineWidth / 2,
      lineLength,
      lineWidth,
    );
  }

  drawFrame = () => {
    const canvas = this.canvas;
    if (canvas) {
      const { left, top, width, height } = this.frameSize;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (this.state.frameMode !== "off") {
        ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = '#007dbc';
        ctx.fillStyle = '#007dbc';
        ctx.fillRect(
          left - FRAME_WIDTH,
          top - FRAME_WIDTH,
          width + FRAME_WIDTH + FRAME_WIDTH,
          height + FRAME_WIDTH + FRAME_WIDTH,
        );
        ctx.clearRect(left, top, width, height);
        this.drawBarcodeLine(ctx);
      }
    }
  }

  createVideo = () => {
    this.video = document.createElement('video');
    this.video.setAttribute('autoplay', 'true');
    this.video.setAttribute('muted', 'true');
    this.video.setAttribute('playsinline', 'true');
    this.video.setAttribute('preload', 'auto');
    this.video.addEventListener('play', () => {
      const track = getActiveTrack(this.video);
      if (track) {
        const { deviceId } = track.getSettings();
        this.setDeviceIdState(deviceId);
      }
    });
    this.video.addEventListener('loadedmetadata', () => {
      this.video.play().catch(e => console.warn('Zbar autoplay error:', e));
    });
    this.containerRef.current.appendChild(this.video);
  }

  createCanvas = () => {
    this.canvas = document.createElement('canvas');
    this.canvas.className = 'scanner-canvas';

    if (this.video && this.video.srcObject) {
      const track = getActiveTrack(this.video);
      const deviceSettings = track && track.getSettings();
      const { width, height } = deviceSettings ? deviceSettings : { width: 480, height: 640 };
      this.canvas.setAttribute('width', `${width}`);
      this.canvas.setAttribute('height', `${height}`);
      this.setSrcSize(width, height);
    }

    this.containerRef.current.appendChild(this.canvas);
  }

  checkCapabilities = () => {
    let capabilities = {};
    const track = getActiveTrack(this.video);
    if (track && typeof track.getCapabilities === 'function') {
      capabilities = track.getCapabilities() || {};
      this.setState({ capabilities });
    }
    return capabilities;
  }

  startScanner = async () => {
    this.startTimestamp = (new Date()).getTime();
    if (!this.containerRef || !this.containerRef.current) {
      console.log('No container found to spawn video');
      return;
    }

    const { deviceId, torch } = this.state;
    this.isStarting = true;
    this.createVideo();

    let videoConstraints;
    if (!deviceId) {
      videoConstraints = { facingMode: 'environment' };
    } else {
      videoConstraints = { deviceId: { exact: deviceId } };
    }

    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: videoConstraints,
    });
    this.video.srcObject = mediaStream;

    this.createCanvas();
    this.calcFrameSize();
    this.drawFrame();
    this.setState({ isRunning: true });
    const capabilities = this.checkCapabilities();
    const canTorch = !!capabilities.torch;

    if (canTorch && torch) {
      this.toggleTorch(true);
    } else {
      this.setTorchState(false);
    }
    this.isStarting = false;
    this.scanTimeoutRef = setTimeout(this.scanFrame, 0);
  }

  scanFrame = async () => {
    const { isRunning } = this.state;
    if (!isRunning || this.isStopping) {
      return;
    }
    const captureCanvas = document.createElement('canvas');
    const {
      left,
      top,
      width,
      height,
    } = this.calcFrameSize();
    captureCanvas.style.width = `${width}px`;
    captureCanvas.style.height = `${height}px`;
    captureCanvas.width = width;
    captureCanvas.height = height;
    const ctx = captureCanvas.getContext('2d');
    ctx.drawImage(this.video, left, top, width, height, 0, 0, width, height);
    const imgData = ctx.getImageData(0, 0, width, height);
    const res = await scanImageData(imgData);
    this.scanTimeoutRef = null;
    if (res && res.length > 0) {
      this.onResult(res[0]);
    } else if (isRunning) {
      this.scanTimeoutRef = setTimeout(this.scanFrame, SCAN_PROID_MS);
    }
  }

  stopScanner = () => {
    this.isStopping = true;
    if (this.video && this.video.srcObject) {
      this.video.srcObject.getTracks().forEach(function(track) {
        track.stop();
      });
    }
    this.video = null;
    this.canvas = null;
    if (this.containerRef && this.containerRef.current) {
      this.containerRef.current.innerHTML = '';
    }

    this.setState({ isRunning: false });
    this.isStopping = false;
  }

  getLocalStorageState = () => {
    const cameraState = localStorage.cameraState;
    try {
      return JSON.parse(cameraState);
    } catch {
      return {
        torch: true,
        deviceId: null,
      };
    }
  }
  updateLocalStorageState = (state) => {
    localStorage.cameraState = JSON.stringify(state);
  }
  clearLocalStorageState = () => {
    localStorage.removeItem('cameraState');
  }

  setTorchState = (torch, callback) => {
    const localStorageState = this.getLocalStorageState()
    this.updateLocalStorageState({
      ...localStorageState,
      torch,
    });
    this.setState({ torch }, callback);
  }

  setDeviceIdState = (deviceId, callback) => {
    const localStorageState = this.getLocalStorageState()
    this.updateLocalStorageState({
      ...localStorageState,
      deviceId,
    });
    this.setState({ deviceId }, callback);
  }

  toggleTorch = (torch) => {
    const track = getActiveTrack(this.video);
    if (track && typeof track.getCapabilities === 'function') {
      this.setTorchState(torch);
      track.applyConstraints({ advanced: [{ torch }] });
    }
  }

  onToggleTorch = (e) => {
    const { torch } = this.state;
    this.toggleTorch(!torch);
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  onResult = (result) => {
    const { onDetected, setLastBarcode, readingType } = this.props;

    if (result) {
      const format = FORMAT_MAP[result.type] || 'UNKNOWN';
      const resultTimestamp = new Date().getTime();
      const duration = resultTimestamp - this.startTimestamp;
      const value = result.decode();
      logEvent('barcode_read_duration', {
        type: format,
        source: 'WEB_ZBAR',
        duration,
      });
      setLastBarcode({
        readingType,
        type: format,
        source: 'WEB_ZBAR',
        value,
        duration,
        date: new Date().getTime(),
      });
      // console.log('Found barcode!', result);
      onDetected(value);
      this.stopScanner();
      this.setState({ show: false });
    }
  }

  onClick = (event) => {
    if (this.isStarting || this.isStopping) {
      return;
    }
    const { isRunning } = this.state;
    if (isRunning) {
      this.onStop();
    } else {
      this.setState({ show: true });
    }
  }

  onStart = async () => {
    try {
      await this.startScanner();
    } catch (e) {
      console.error('Error during Zbar startScanner:', e);
      this.isStarting = false;
      this.stopScanner();
      this.clearLocalStorageState();
    }
  }

  onStop = () => {
    try {
      this.setState({ show: false });
      this.stopScanner();
      const stopTime  = new Date().getTime();
      const duration  = stopTime - this.startTimestamp;
      if (duration > LOG_READ_CANCELLED_TIMEOUT) {
        logBarcodeReadCanceledEvent({
          source: 'WEB_ZBAR',
          readingType: this.props.readingType || 'other',
          duration,
        });
      }
    } catch (e) {
      console.error('Error during Zbar stopScanner:', e);
      this.isStopping = false;
      this.clearLocalStorageState();
    }
  }

  render() {
    const {
      isRunning,
      capabilities,
      frameMode,
      devices,
      show,
      torch,
    } = this.state;
    const canTorch = !!capabilities.torch;
    const canZoom = !!capabilities.zoom;
    const zoomOpts = capabilities.zoom;
    return (
      <Modal
        size="lg"
        show={show}
        onHide={this.onStop}
        onEnter={this.onStart}
        className="scanner-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans i18nKey="scanner.scanBarcode">
              Scan barcode
            </Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
          {!isRunning && (
            <div className="scanner-loader-wrapper">
              <Loader/>
            </div>
          )}
          <div className="scanner-container-wrapper">
            <div
              className="scanner-container"
              ref={this.containerRef}
            />
            <ScannerControls
              canZoom={canZoom}
              canTorch={canTorch}
              canToggleCamera={devices.length > 1}
              isRunning={isRunning}
              zoomOpts={zoomOpts}
              video={this.video}
              frameMode={frameMode}
              torch={torch}
              onToggleTorch={this.onToggleTorch}
              onChangeFrameMode={this.onChangeFrameMode}
              onToggleCamera={this.onToggleCamera}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setLastBarcode: (barcode) => dispatch(setLastBarcode(barcode)),
});

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(ZbarScanner);
