import React, { useContext } from 'react';
import { Modal, Button, Accordion, Card, Table, AccordionContext, ProgressBar } from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Button variant="light" className="accordion-header-button d-flex" onClick={decoratedOnClick}>
      {children}
      {isCurrentEventKey
        ? <i className="d-inline-block vtmn-icon_chevron_up_v2" />
        : <i className="d-inline-block vtmn-icon_chevron_down_v2" />
      }
    </Button>
  );
}

const MassResultsDialog = ({ massResults, massProgress, onClose, onStop }) => {
  const { t } = useTranslation();
  const history = useHistory();

  if (!massResults && !massProgress) {
    return null;
  }

  if (massProgress) {
    const progress = (massProgress.processed / massProgress.total * 100).toFixed(2);

    return (
      <Modal show backdrop="static">
        <Modal.Header>
          <Modal.Title>
            <Trans i18nKey="shared.progress">Progress</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProgressBar now={progress} label={`${progress}%`} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={onStop}
          >
            <Trans i18nKey="shared.stop">Stop</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const hasErrors = massResults.failed.length > 0;

  return (
    <Modal
      show
      onHide={onClose}
    >
      <Modal.Header closeButton>
        {hasErrors
          ? <i className="modal-header-icon text-danger vtmn-icon_notif_alert" />
          : <i className="modal-header-icon text-success vtmn-icon_valid_rounded" />
        }
        <Modal.Title>
          <Trans i18nKey="shared.results">Results</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion>
          <Card>
            <Card.Header className="p-0">
              <ContextAwareToggle eventKey="0">
                <i className="accordion-header-icon text-success vtmn-icon_valid_rounded"></i>
                <h5 className="mb-0 flex-fill">
                  <Trans i18nKey="shopOrders.createdPickingLists">Created picking lists for orders</Trans>
                  {': '}
                  {massResults.succeed.length}
                </h5>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="p-0">
                <Table className="m-0" hover>
                  <thead>
                    <tr>
                      <th>{t('shopOrders.order', 'Order')}</th>
                      <th>{t('shopOrders.created', 'Created')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {massResults.succeed.map(({ order_id, created }) => (
                      <tr key={order_id}>
                        <td>{order_id}</td>
                        <td>{created}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className="p-0">
              <ContextAwareToggle eventKey="1">
                {hasErrors
                  ? <i className="accordion-header-icon text-danger vtmn-icon_notif_alert"></i>
                  : <i className="accordion-header-icon text-primary vtmn-icon_notif_information"></i>
                }
                <h5 className="mb-0 flex-fill">
                  <Trans i18nKey="shopOrders.failedPickingLists">Failed to create for orders</Trans>
                  {': '}
                  {massResults.failed.length}</h5>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body className="p-0">
                <Table className="m-0" hover>
                  <thead>
                    <tr>
                      <th>{t('shopOrders.order', 'Order')}</th>
                      <th>{t('shopOrders.created', 'Created')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {massResults.failed.map(({ order_id }) => (
                      <tr key={order_id}>
                        <td>{order_id}</td>
                        <td>-</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={onClose}
        >
          <Trans i18nKey="shared.close">Close</Trans>
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            history.push(`/${localStorage.getItem("store_number")}/EcomPickingList`);
          }}
        >
          <Trans i18nKey="shopOrders.openPickingLists">Open picking lists</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  )
};

export default MassResultsDialog;
