const { useState, useEffect } = require('react');

export const isAppInstalled = async () => {
  let installed = false;
  if (navigator.getInstalledRelatedApps) {
    // only chrome supports getInstalledRelatedApps
    try {
      const relatedApps = await navigator.getInstalledRelatedApps();
      if (relatedApps.length > 0) {
        installed = true;
      }
    } catch (e) {}
  }
  return installed;
};

export const useAppInstalled = () => {
  const [appInstalled, setAppInstalled] = useState(false);
  useEffect(() => {
    isAppInstalled().then(setAppInstalled);
  }, [])
  return appInstalled;
};
