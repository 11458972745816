import _ from "lodash";
import fetchWithJWT from "../../functions/fetchWithJWT";
import handleApiResponse from "../../functions/handleApiResponse";

export const getCurrentTrolley = async ({ store, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const trolley = await fetchWithJWT(`${process.env.REACT_APP_SERVERURL}/v1/${store}/ecommerce_trolleys/current`, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return trolley;
};

export const getPickingLists = async ({ store, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const pickingList = await fetchWithJWT(`${process.env.REACT_APP_SERVERURL}/v1/${store}/ecommerce_picking_lists`, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return pickingList;
};

export const getPackCount = async ({ store, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/ecommerce_trolleys/count?statuses=picked`;
  const count = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
  return count;
};

export const getRecommendedBoxes = async ({ store, orderIds, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/recommended_box`;
  const trolley = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    method: "POST",
    body: JSON.stringify({ order_ids: orderIds })
  })
  .then(handleApiResponse);
  return trolley;
};

export const getBoxes = async ({ store, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/boxes/`;
  const boxes = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
  return boxes
}

const getItemDetailsPage = async ({ store, items, endOfLife, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/items/infos/stores/${store}?item_ids=${items.join(',')}&end_of_life=${endOfLife || false}`;
  const itemDetails = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
  return itemDetails;
};

/**
 *  Function to fetch item details by array of item_id
 *
 * @return {Array<Object>} Return array of item info
 */
export const getItemDetails = async ({ store, items, endOfLife, user, updateTokens }) => {
  const itemChunks = _.chunk(items, 500);
  const details = [];
  for (const itemChunk of itemChunks) {
    const itemDetails = await getItemDetailsPage({ store, items: itemChunk, endOfLife, user, updateTokens });
    details.push(...(itemDetails || []));
  }
  return details;
};

export const getReserve = async ({ user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const reserve_id = localStorage.getItem("reserve_id");
  const url = `${process.env.REACT_APP_SERVERURL}/v1/stores/${reserve_id}`;
  const reserve = fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
  return reserve;
};

export const deletePickingRow = async ({ store, id_ecompicking_list, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/ecommerce_picking_lists/${id_ecompicking_list}`
  return fetchWithJWT(
    url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    method: "DELETE",
  })
  .then(handleApiResponse);
};

export const pickPickingList = ({
  store,
  id_ecompicking_list,
  boxNumber,
  package_id,
  qty,
  force,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/ecommerce_picking_lists/${id_ecompicking_list}/pick`

  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    method: "POST",
    body: JSON.stringify({
      box_number: boxNumber,
      package_id,
      qty,
      epcs: [],
      force,
    })
  })
  .then(handleApiResponse);
};

export const cantPickPickingList = ({ store, id_ecompicking_list, action, force, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/ecommerce_picking_lists/${id_ecompicking_list}/cannot_pick`
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    method: "POST",
    body: JSON.stringify({
      resolve_action: action,
      force,
    })
  })
  .then(handleApiResponse);
};
