import React, { PureComponent } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import dateFormat from 'dateformat';
import _ from 'lodash';
import { handleApiResponse, handlePaginatedApiResponse } from '../../functions/handleApiResponse';
import getQueryString from '../../functions/getQueryString';
import ExportButton from '../../components/widgets/ExportButton';
import Loader from '../../components/Loader';
import MessageBox from '../../components/widgets/MessageBox';
import ReportFilter from './ReportFilter';
import OrdersReportModal from './OrdersReportModal';
import { TableFooter } from '../../components/widgets/DataTable';
import InlineInputFilter from '../../components/widgets/filters/InlineInputFilter';
import fetchWithJWT from '../../functions/fetchWithJWT';
import { getSortParams } from '../../functions/getQueryString';
import OrderStatusHelpIcon, { getStatusDetails } from './OrderStatusHelpIcon';
import {ConfirmRemoveAllOrder, ConfirmRemoveOrder} from "../Orders/ConfirmActions";
import { pickOrder, removeOrdersByStatus } from '../Orders/api';

const serverUrl = process.env.REACT_APP_SERVERURL;
const STATUS_COMPLETED = 'COMPLETED';

class OrdersReport extends PureComponent {
  constructor(props) {
    super(props);
    const { t, lockersEnabled, selfpickupEnabled } = props;
    this.addressTypes = {
      'address': t('adminAddreses.addressTypes.address', 'Teammate pick-up'),
      'locker': t('adminAddreses.addressTypes.locker', 'Locker'),
      'selfpickup': t('adminAddreses.addressTypes.selfpickup', 'Self pick-up'),
    }
    this.statusDetails = getStatusDetails(t)
    this.state = {
      stockOrdersList: [],
      itemDetailsMap: {},
      sgEpcMap: {},
      lettersList: [],
      shippingMethodList: [],
      allowedTypes: this.getAllowedTypes(lockersEnabled, selfpickupEnabled),
      showAddressType: lockersEnabled || selfpickupEnabled,
      selectedOrder: null,
      orderModal: null,
      staleFilter: false,
      letter: "all",
      address: "",
      addressType: undefined,
      changeDate: undefined,
      order_number: "",
      shipping_group_number: "",
      shipping_method: undefined,
      loading: true,
      error: "",
      totals: {
        items: 0,
        pages: 0,
      },
      pagination: {
        page: 0,
        size: 20,
      },
      sort: {
        field: 'change_date',
        direction: 'DESC',
      },
      statuses: [],
      status: undefined,
      totalStale: 0,
      confirmRemoveShow: false,
      removeOrder: null,
      ordersDeletedMessage: null,
      orderAlertMessage: null,
      confirmRemoveAllShow: false,
    }
  }

  componentDidMount(){
    this.initializePage().catch(e => {});
  }

  getAllowedTypes = (lockersEnabled, selfpickupEnabled) => {
    const allowedTypes = [
      {
        id: 'address',
        label: this.addressTypes.address,
      }
    ];
    if (lockersEnabled) {
      allowedTypes.push({
        id: 'locker',
        label: this.addressTypes.locker,
      })
    }
    if (selfpickupEnabled) {
      allowedTypes.push({
        id: 'selfpickup',
        label: this.addressTypes.selfpickup,
      })
    }
    return allowedTypes;
  }

  initializePage = async () => {
    const { t } = this.props;
    try {
      await this.loadShippingMethodList();
      await this.loadAlleys();
      await this.loadStockOrders();
      this.setState({
        loading: false,
        error: "",
      });
    } catch (error) {
      console.warn(error);
      this.setState({
        loading: false,
        error: t('report.loadReportFailed', 'Failed to load report'),
      });
    }
  }

  loadShippingMethodList = async () => {
    const { user, updateTokens, store } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const shippingMethodList = await fetchWithJWT(`${serverUrl}/v1/${store}/history/stock_orders/shipping_methods`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    }).then(handleApiResponse);
    this.setState({ shippingMethodList });
  }

  loadAlleys = async () => {
    const { store, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const alleys = await fetchWithJWT(`${serverUrl}/v1/${store}/addresses/alleys`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    }).then(handleApiResponse);
    const lettersList = alleys.map(x => x.letter);
    this.setState({ lettersList });
  };

  loadStockOrders = async () => {
    const { user, updateTokens, store } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const { pagination, sort } = this.state;
    const filters = this.getFilters();
    const queryString = getQueryString({
      filters,
      pagination,
      sort,
    });
    const { result, contentRange } = await fetchWithJWT(`${serverUrl}/v1/${store}/reports/stock_orders?${queryString}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    }).then(handlePaginatedApiResponse);
    const { data: stockOrdersList, totalStale, statuses } = result;
    const { size } = pagination;
    const items = contentRange.max + 1;
    const pages = Math.max(1, Math.ceil(items / size));
    const totals = {
      items,
      pages,
    };
    const { itemDetailsMap, sgEpcMap } = await this.loadLockerItems(stockOrdersList);
    this.setState({
      stockOrdersList,
      itemDetailsMap,
      sgEpcMap,
      totals,
      totalStale,
      statuses,
    });
    return stockOrdersList;
  }

  loadLockerItems = async (stockOrdersList) => {
    if (!stockOrdersList || !stockOrdersList.length) {
      return { itemDetailsMap: {}, sgEpcMap: {} };
    }
    const { user, updateTokens, store } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const sgs = [];
    const item_ids = [];
    stockOrdersList.forEach((order) => {
      if (order.address_type === 'locker' && order.items?.length) {
        if (sgs.indexOf(order.shipping_group_number) === -1) sgs.push(order.shipping_group_number);
        order.items.forEach((itemId) => {
          if (item_ids.indexOf(itemId) === -1) item_ids.push(itemId)
        });
      }
    });
    if (!item_ids || !item_ids.length) {
      return { itemDetailsMap: {}, sgEpcMap: {} };
    }

    // load item details
    const itemDetails = [];
    const itemChunks = _.chunk(item_ids, 500);
    for (const itemChunk of itemChunks) {
      const itemDetailsChunk = await fetchWithJWT(`${serverUrl}/v1/items/infos/stores/${store}?item_ids=${itemChunk.join(',')}`, {
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        }
      }).then(handleApiResponse);
      itemDetails.push(...(itemDetailsChunk || []));
    }

    // load epc details
    const itemEpcDetails = [];
    const sgsChunks = _.chunk(sgs, 200);
    for (const sgsChunk of sgsChunks) {
      const itemEpcDetailsChunk = await fetchWithJWT(`${serverUrl}/v1/${store}/stock_orders/items?shipping_groups=${sgsChunk.join(',')}`, {
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        }
      }).then(handleApiResponse);
      itemEpcDetails.push(...(itemEpcDetailsChunk || []));
    }

    const itemDetailsMap = {};
    const sgEpcMap = {}
    for (const item of itemDetails) {
      const img = new Image();
      img.src = `https://contents.mediadecathlon.com/p${item.pixlId}/a.jpg?f=200x200`;
      itemDetailsMap[item.item_id] = {
        ...item,
        img,
      };
    }
    if (itemEpcDetails) {
      itemEpcDetails.forEach((sg) => {
        sgEpcMap[sg.shipping_group_number] = sg.items;
      });
    }
    return { itemDetailsMap, sgEpcMap };
  }

  reloadStockOrders = async () => {
    const { t } = this.props;
    this.setState({ loading: true, error: '' });
    try {
      await this.loadStockOrders();
    } catch (error) {
      console.warn(error);
      this.setState({
        error: t('report.loadReportFailed', 'Failed to load report'),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  setPagination = (nextPagination) => {
    const { totals } = this.state;
    const nextTotals = {
      items: totals.items,
      pages: Math.max(1, Math.ceil(totals.items / nextPagination.size)),
    };
    this.setState({
      totals: nextTotals,
      pagination: nextPagination,
    }, this.reloadStockOrders);
  }

  filterListByAddress = (address) => {
    this.setState({
      address,
      pagination: {
        ...this.state.pagination,
        page: 0,
      }
    }, this.reloadStockOrders);
  }

  filterListByOrderNumber = (order_number) => {
    this.setState({
      order_number,
      pagination: {
        ...this.state.pagination,
        page: 0,
      }
    }, this.reloadStockOrders);
  }

  filterListByShippingGroupNumber = (shipping_group_number) => {
    this.setState({
      shipping_group_number,
      pagination: {
        ...this.state.pagination,
        page: 0,
      }
    }, this.reloadStockOrders);
  }

  filterListByAddressType = (val) => {
    const addressType = val?.id;
    this.setState({
      addressType,
      pagination: {
        ...this.state.pagination,
        page: 0,
      }
    }, this.reloadStockOrders);
  }

  filterListByStatus = (status) => {
    this.setState({
      status,
      pagination: {
        ...this.state.pagination,
        page: 0,
      }
    }, this.reloadStockOrders);
  }

  filterListByShippingMethod = (shipping_method) => {
    this.setState({
      shipping_method,
      pagination: {
        ...this.state.pagination,
        page: 0,
      }
    }, this.reloadStockOrders);
  }

  filterListByLetter = (letter) => {
    this.setState ({
      letter,
      pagination: {
        ...this.state.pagination,
        page: 0,
      }
    }, this.reloadStockOrders);
  }

  filterListByStale = (staleFilter) => {
    this.setState ({
      staleFilter,
      pagination: {
        ...this.state.pagination,
        page: 0,
      }
    }, this.reloadStockOrders);
  }

  filterListByChangeDate = (changeDate) => {
    this.setState ({
      changeDate,
      pagination: {
        ...this.state.pagination,
        page: 0,
      }
    }, this.reloadStockOrders);
  }

  getFilters = () => {
    const filters = {};
    if (this.state.letter !== "all") {
      filters.address_letter = this.state.letter;
    }
    if (this.state.address !== "") {
      filters.address = this.state.address;
    }
    if (this.state.addressType) {
      filters.address_type = this.state.addressType;
    }
    if (this.state.order_number !== "") {
      filters.order_number = this.state.order_number;
    }
    if (this.state.shipping_group_number !== "") {
      filters.shipping_group_number = this.state.shipping_group_number;
    }
    if (this.state.shipping_method) {
      filters.shipping_method = this.state.shipping_method;
    }
    if (this.state.staleFilter) {
      filters.is_stale = true;
    }
    if (this.state.status) {
      filters.status = this.state.status;
    }
    if (this.state.changeDate) {
      filters.change_date_from = dateFormat(this.state.changeDate, "yyyy-mm-dd");
      filters.change_date_to = dateFormat(this.state.changeDate, "yyyy-mm-dd");
    }
    return filters;
  }

  onExport = (oauthToken, user, updateTokens, { filters, sort }) => {
    const { token, refreshToken, tokenExpireDate } = user;
    const url = `${serverUrl}/v1/${this.props.store}/reports/stock_orders/export`;
    return fetchWithJWT(url, {
      method: 'POST',
      body: JSON.stringify({
        oauthToken,
        filters,
        sort: getSortParams({ sort }),
      }),
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
    })
    .then(handleApiResponse);
  }

  sort = (field) => {
    const { sort } = this.state;
    if (sort.field !== field || sort.direction !== 'ASC') {
      this.setState({
        sort: {
          field,
          direction: 'ASC',
        },
      }, this.reloadStockOrders);
    } else {
      this.setState({
        sort: {
          field,
          direction: 'DESC',
        },
      }, this.reloadStockOrders);
    }
  }
  getSortIcon = (field) => {
    const { sort } = this.state;
    let sortIcon = 'sort';
    if (sort && sort.field === field) {
      if (sort.direction === 'ASC') {
        sortIcon = 'arrow1_down';
      } else {
        sortIcon = 'arrow1_up';
      }
    }
    return sortIcon;
  }

  getColCount = () => {
    let colCount = 7;
    const { showAddressType } = this.state;
    if (showAddressType) {
      colCount += 1;
    }
    return colCount;
  }

  doRemoveOrder = async () => {
    const { removeOrder } = this.state;
    const { user, updateTokens, store } = this.props;
    this.setState({ loading: true, confirmRemoveShow: false });
    try {
      await pickOrder({
        store,
        order_number: removeOrder.order_number,
        to_customer: false,
        id: removeOrder.id,
        user,
        updateTokens,
      });
      await this.initializePage();
    } catch (error) {
      console.warn(`Error while remove order : ${error}`);
      const message = error.message || String(error);
      this.setState({ loading: false, orderAlertMessage: message });
      await this.initializePage();
    }
  }

  doRemoveAllOrders = async () => {
    const { user, updateTokens, store } = this.props;
    this.setState({ loading: true, confirmRemoveAllShow: false });
    try {
      const pickRes = await removeOrdersByStatus({
        store,
        status: STATUS_COMPLETED,
        user,
        updateTokens,
      });
      if(pickRes) {
        this.setState({ ordersDeletedMessage: pickRes.deleted });
        await this.initializePage();
      }
    } catch (error) {
      console.warn(`Error while remove order : ${error}`);
      const message = error.message || String(error);
      this.setState({ loading: false, orderAlertMessage: message });
      await this.initializePage();
    }
  }

  displayOrderAlert() {
    if (this.state.orderAlertMessage !== null) {
      return(
        <div className="alert alert-danger alert-dismissible fade show" role="alert" aria-label="Open">
          <p>{this.state.orderAlertMessage}</p>
          <button
            type="button"
            className="btn btn-secondary displayAlert"
            onClick={() => this.setState({
              orderAlertMessage: null
            })}
            aria-label="Close"
          >
            <Trans i18nKey="shared.ok">OK</Trans>
          </button>
        </div>
      );
    }
  }

  getHasCompleted = () => {
    const orderCompleted = this.state.statuses.filter((sol) => sol === STATUS_COMPLETED);
    return orderCompleted.length > 0
  }

  renderRow(order, index) {
    const { t, lockersEnabled } = this.props;
    const { showAddressType } = this.state;
    const isLocker = lockersEnabled && order.address_type === 'locker';
    return (
      <tr key={index}>
        <td>{order.address}</td>
        {showAddressType && (
          <td>{this.addressTypes[order.address_type]}</td>
        )}
        <td>{order.order_number}</td>
        <td className="text-nowrap">
          {isLocker && (
            <Button
              variant="yellow"
              size="sm"
              className="mr-2 barcode-button"
              title={t('address.viewBarcode', 'View barcode')}
              onClick={() => this.setState({ selectedOrder: order, orderModal: 'barcode' })}
            >
              <img className="img-icon" alt="barcode" src="/icons/barcode_line_black.svg" />
            </Button>
          )}
          <span className="text-break">
            {order.shipping_group_number}
          </span>
          {isLocker && (
            <Button
              variant="light"
              size="sm"
              className="ml-2 icon-button"
              title={t('report.orders.items', 'Items')}
              onClick={() => this.setState({ selectedOrder: order, orderModal: 'items' })}
            >
              <i className="vtmn-icon_notif_information font-weight-bold text-primary m-0" />
            </Button>
          )}
        </td>
        <td>{order.parcel_number}</td>
        <td className="orderReportBreakableCell">{order.shipping_method}</td>
        <td className="orderReportBreakableCell" title={this.statusDetails[order.status]}>
          {order.status}
          {order.status === STATUS_COMPLETED && (
            <Button
              variant="danger"
              size="sm"
              className="icon-button btn btn-sm danger"
              title={t('shared.remove', 'Remove')}
              onClick={() => this.setState({ removeOrder: order, confirmRemoveShow: true })}
            >
              <i className="vtmn-icon_delete font-weight-bold m-0"></i>
            </Button>
          )}
        </td>
        <td>
          {order.change_date !== null ? order.change_date.slice(0, 10) : ""}
          {order.is_stale && (
            <i
              className="align-middle d-inline text-warning ml-1 vtmn-icon_hourglass"
              title={t('report.orders.staleIconTitle', 'Last movement > 15 days ago')}
            ></i>
          )}
        </td>
      </tr>
    );
  }

  renderItemsModalRow = (itemId) => {
    const { itemDetailsMap } = this.state;
    const item = itemDetailsMap[itemId] || {};
    return (
      <div key={itemId}>
        <div>
          <img
            key={item.pixlId}
            src={item.img.src}
            alt=""
            style={{ maxWidth: '100%' }}
          />
        </div>
        <div>
          <div>{itemId}</div>
          <div>{item.item_description || '-'}</div>
          <div>{item.department_description || '-'}</div>
        </div>
      </div>
    );
  }

  renderLetter(letter) {
    return (
      <button
        key={letter}
        type="button"
        className={`btn aisleLetter btn-sm ${this.state.letter === letter ? "active" : ""}`}
        onClick={ event => this.filterListByLetter(letter) }
      >
        {letter}
      </button>
    );
  }

  render() {
    const { t, user, updateTokens } = this.props;
    const {
      sort,
      stockOrdersList,
      staleFilter,
      totalStale,
      showAddressType,
      confirmRemoveShow,
      confirmRemoveAllShow,
    } = this.state;
    const colCount = this.getColCount();
    const hasCompleted = this.getHasCompleted();

    if (!this.props.isActive) {
      return null;
    }

    return (
      <>
        <table className="table table-hover text-center">
          <thead>
            <tr className="selectLine">
              <td colSpan={colCount - 1}>
                <button
                  type="button"
                  className={`btn aisleLetter btn-sm ${this.state.letter === "all" ? "active" : ""}`}
                  onClick={event => this.filterListByLetter("all")}
                >

                  <Trans i18nKey="report.orders.all">All</Trans>
                </button>
                {this.state.lettersList.map((letter) => this.renderLetter(letter))}
              </td>
              <td>
                <ExportButton
                  user={user}
                  updateTokens={updateTokens}
                  filters={this.getFilters()}
                  sort={sort}
                  exportReport={this.onExport}
                />
              </td>
            </tr>
            <tr className="title-row">
              <th className="column-sortable border-bottom-0 pb-1" onClick={() => this.sort('address')}>
                <div className="datatable-title-wraper">
                  <Trans i18nKey="report.orders.addresses">Addresses</Trans>
                </div>
                <i className={`sort-icon vtmn-icon_${this.getSortIcon('address')}`} aria-hidden="true" />
              </th>
              {showAddressType && (
                <th className="column-sortable border-bottom-0 pb-1" onClick={() => this.sort('address_type')}>
                  <div className="datatable-title-wraper">
                    <Trans i18nKey="report.orders.addressType">Type</Trans>
                  </div>
                  <i className={`sort-icon vtmn-icon_${this.getSortIcon('address_type')}`} aria-hidden="true" />
                </th>
              )}
              <th className="column-sortable border-bottom-0 pb-1" onClick={() => this.sort('order_number')}>
                <div className="datatable-title-wraper">
                  <Trans i18nKey="report.orders.orderNumber">Order number</Trans>
                </div>
                <i className={`sort-icon vtmn-icon_${this.getSortIcon('order_number')}`} aria-hidden="true" />
              </th>
              <th className="column-sortable border-bottom-0 pb-1" onClick={() => this.sort('shipping_group_number')}>
                <div className="datatable-title-wraper">
                  <Trans i18nKey="report.orders.shippingGroup">Shipping group</Trans>
                </div>
                <i className={`sort-icon vtmn-icon_${this.getSortIcon('shipping_group_number')}`} aria-hidden="true" />
              </th>
              <th className="column-sortable border-bottom-0 pb-1" onClick={() => this.sort('parcel_number')}>
                <div className="datatable-title-wraper">
                  <Trans i18nKey="report.orders.parcel">Parcel</Trans>
                </div>
                <i className={`sort-icon vtmn-icon_${this.getSortIcon('parcel_number')}`} aria-hidden="true" />
              </th>
              <th className="column-sortable border-bottom-0 pb-1" onClick={() => this.sort('shipping_method')}>
                <div className="datatable-title-wraper">
                  <Trans i18nKey="report.orders.shippingMethod">Shipping method</Trans>
                </div>
                <i className={`sort-icon vtmn-icon_${this.getSortIcon('shipping_method')}`} aria-hidden="true" />
              </th>
              <th className="column-sortable border-bottom-0 pb-1" onClick={() => this.sort('status')}>
                <div className="datatable-title-wraper">
                  <Trans i18nKey="report.orders.status">Status</Trans>
                </div>
                <OrderStatusHelpIcon/>
                <i className={`sort-icon vtmn-icon_${this.getSortIcon('status')}`} aria-hidden="true" />
              </th>
              <th className="column-sortable border-bottom-0 pb-1" onClick={() => this.sort('change_date')}>
                <div className="datatable-title-wraper">
                  <Trans i18nKey="report.orders.lastMovement">Last movement</Trans>
                </div>
                <i className={`sort-icon vtmn-icon_${this.getSortIcon('change_date')}`} aria-hidden="true" />
              </th>
            </tr>
            <tr>
              <th className="border-top-0 pt-0">
                <InlineInputFilter
                  placeholder={t('report.items.filter', 'filter...')}
                  onChange={this.filterListByAddress}
                  width="100%"
                />
              </th>
              {showAddressType && (
                <th className="border-top-0 pt-0">
                  <ReportFilter
                    controlId="addressTypeFilter"
                    placeholder={t('report.orders.filter', 'filter...')}
                    options={this.state.allowedTypes}
                    selected={this.state.addressType}
                    onChange={this.filterListByAddressType}
                  />
                </th>
              )}
              <th className="border-top-0 pt-0">
                <InlineInputFilter
                  placeholder={t('report.items.filter', 'filter...')}
                  onChange={this.filterListByOrderNumber}
                  width="100%"
                />
              </th>
              <th className="border-top-0 pt-0">
                <InlineInputFilter
                  placeholder={t('report.items.filter', 'filter...')}
                  onChange={this.filterListByShippingGroupNumber}
                  width="100%"
                />
              </th>
              <th className="border-top-0 pt-0">&nbsp;</th>
              <th className="border-top-0 pt-0">
                <ReportFilter
                  controlId="shippingMethodFilter"
                  options={this.state.shippingMethodList}
                  selected={this.state.shipping_method}
                  onChange={this.filterListByShippingMethod}
                />
              </th>
              <th className="border-top-0 pt-0">
                <ReportFilter
                  controlId="orderStatusFilter"
                  placeholder={t('report.orders.filter', 'filter...')}
                  options={this.state.statuses}
                  selected={this.state.status}
                  onChange={this.filterListByStatus}
                />
                {hasCompleted &&
                  <button
                    type="button"
                    className={`stale-status-button icon-button btn btn-sm`}
                    onClick={() => this.setState({ confirmRemoveAllShow: true })}
                  >
                    <i className="vtmn-icon_delete"></i>
                    {t('report.orders.remove_completed')}
                  </button>
                }
              </th>
              <th className="border-top-0 pt-0">
                <div className="report-filter-date-select">
                  <DatePicker
                    id="changeDateFilter"
                    className="form-control form-control-sm"
                    placeholderText={t('report.items.filter', 'filter...')}
                    popperPlacement="bottom-end"
                    isClearable
                    selected={this.state.changeDate}
                    onChange={this.filterListByChangeDate}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                {totalStale > 0 && (
                  <button
                    type="button"
                    className={`stale-cnt-button icon-button btn btn-sm ${staleFilter ? "btn-warning" : "btn-outline-warning"}`}
                    onClick={() => this.filterListByStale(!staleFilter) }
                  >
                    <i className="vtmn-icon_hourglass"></i>
                    {t('report.orders.oldOrders', {
                      defaultValue: '{{count}} old orders',
                      count: totalStale,
                    })}
                  </button>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.loading && (
              <tr>
                <td colSpan={colCount}>
                  <Loader/>
                </td>
              </tr>
            )}
            {!this.state.loading && !!this.state.error && (
              <tr>
                <td colSpan={colCount}>
                  <div className="alert alert-danger show" role="alert" aria-label="Open">
                    <h4>
                      <Trans i18nKey="shared.defaultErrorTitle">Error!</Trans>
                    </h4>
                    {this.state.error}
                  </div>
                </td>
              </tr>
            )}
            {!this.state.loading && !this.state.error && stockOrdersList.map(
              (address, index) => this.renderRow(address, index)
            )}
          </tbody>
        </table>
        <TableFooter
          pagination={this.state.pagination}
          totals={this.state.totals}
          setPagination={this.setPagination}
        />
        <OrdersReportModal
          mode={this.state.orderModal}
          order={this.state.selectedOrder}
          itemDetailsMap={this.state.itemDetailsMap}
          sgEpcMap={this.state.sgEpcMap}
          onHide={() => this.setState({ selectedOrder: null, orderModal: null })}
        />
        {this.displayOrderAlert()}
        <MessageBox
          show={this.state.ordersDeletedMessage !== null}
          onHide={() => this.setState({ ordersDeletedMessage: null })}
          title={t('shared.results')}
          body={t('report.orders.deleted_orders', {
            defaultValue: '({{count}}) order(s) deleted',
            count: this.state.ordersDeletedMessage,
          })}
        />
        <ConfirmRemoveOrder
          show={confirmRemoveShow}
          onHide={() => this.setState({ confirmRemoveShow: false })}
          onConfirm={() => this.doRemoveOrder()}
        />
        <ConfirmRemoveAllOrder
          show={confirmRemoveAllShow}
          onHide={() => this.setState({ confirmRemoveAllShow: false })}
          onConfirm={() => this.doRemoveAllOrders()}
        />
      </>
    );
  }
}

export default withTranslation()(OrdersReport);
