import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import './theme.scss';
import './App.css';
import fetchWithJWT, { getAuthHeaders } from './functions/fetchWithJWT';
import handleApiResponse, { checkMaintenance } from './functions/handleApiResponse';
import Login from './views/login';
import Home from './views/home';
import Orders from './views/Orders';
import TrackingNumber from './views/TrackingNumber';
import TrackingNumbers from './views/TrackingNumbers';
import SearchAddress from './views/SearchAddress';
import PickingList from './views/pickingList';
import ChangeStore from './views/changeStore';
import HelpFiles from './views/helpFiles';
import Report from './views/Report';
import AdminAddresses from './views/AdminAddresses';
import Releases from './views/Releases';
import Release from './views/Releases/Release';
import DashBoard from './views/dashBoard';
import EcomOrders from './views/EcomOrders';
import EcomPickingList from './views/EcomPickingList';
import EcomPacking from './views/EcomPacking';
import EcomTrolley from './views/EcomTrolley';
import Catalog from './views/catalog';
import AuthorizationDenied from './views/authorizationDenied';
import Authorizations from './views/authorizations';
import LayoutRequirements from './views/layoutRequirements';
import MovementsHistory from './views/MovementsHistory';
import GoogleOAuth from './views/googleOAuth';
import { setUserState, updateTokens } from './store/user';
import { resetAction } from './views/EcomOrders/state';
import OrderMountList from './views/OrdersMount/OrderMountList';
import Maintenance from './components/widgets/Maintenance';


const serverUrl = process.env.REACT_APP_SERVERURL;
const myHeader = {
  "Content-Type": "application/json",
  "x-api-key" : process.env.REACT_APP_APIKEY
};
const UserInfoOpenId_URL = process.env.REACT_APP_UserInfoOpenId_URL;
const redirect_uri = process.env.REACT_APP_Redirect_URI;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iAmRedirectedFromGoogleOAuth: false,
    }
  }

  componentDidMount() {
    const url = window.location.href;

    const iAmRedirectedFromFedID = (
      url.startsWith(redirect_uri + '?code=')
      && url.split("&state=").length > 1
      && url.split("googleOAuth").length === 1
    );

    const iAmRedirectedFromGoogleOAuth = url.split("googleOAuth").length > 1 ;
    let iHaveLoginDataInLocalStorage = false;
    if (typeof localStorage.getItem("email") === "string"){
      if (localStorage.getItem("email").split("@").length === 2 && localStorage.getItem("uid")) {
        iHaveLoginDataInLocalStorage = true;
      }
    }
    if (iAmRedirectedFromFedID && iHaveLoginDataInLocalStorage === false){
      const code = url.split("code=")[1].split("&")[0]
      const state = url.split("state=")[1].split("&")[0];
      this.getAuthTokens(code, state)
      .then(async (tokens) => {
        const isSuperAdmin = await this.getIsSuperAdmin()
        await this.getUserInfos(tokens, isSuperAdmin);
      });
    }
    else if (iAmRedirectedFromFedID && iHaveLoginDataInLocalStorage === true){
      const code = url.split("code=")[1].split("&")[0];
      const state = url.split("state=")[1].split("&")[0];
      this.getAuthTokens(code, state)
      .then(this.getIsSuperAdmin)
      .then((isSuperAdmin) => {
        this.props.setUserState({
          givenName: localStorage.getItem("givenName"),
          avatar: localStorage.getItem("avatar"),
          email: localStorage.getItem("email"),
          uid: localStorage.getItem("uid"),
          isSuperAdmin: isSuperAdmin || false,
          isLoggedIn: true,
          isLoggingIn: false,
        });
      })

    } else if (iAmRedirectedFromGoogleOAuth) {
      this.setState({ iAmRedirectedFromGoogleOAuth: true });
    }
  }

  getAuthTokens = (code, state) => {
    this.props.setUserState({ hasAuthError: false, isLoggingIn: true });
    const codeVerifier = sessionStorage.getItem(`login-code-verifier-${state}`);
    return fetch(`${serverUrl}/v1/auth/token`, {
      method: 'POST',
      headers: myHeader,
      body: JSON.stringify({
        code: code,
        redirect_uri: redirect_uri,
        code_verifier: codeVerifier,
      })
    })
    .then(response => {
      checkMaintenance(response);
      if (response.status !== 200) {
        return response.text()
        .then(e => {
            throw new Error(e)
          }
        );
      } else {
        return response.json();
      }
    })
    .then(tokens => {
      localStorage.removeItem('reauthenticateRequired');
      const token = tokens.access_token;
      const refreshToken = tokens.refresh_token;
      const tokenExpiresIn = tokens.expires_in;
      this.props.updateTokens({ token, refreshToken, tokenExpiresIn });
      return tokens;
    })
    .catch(error => {
      this.props.setUserState({ hasAuthError: true, isLoggingIn: false });
      console.warn(`Error while retrieving infos for user : ${error}`);
    })
  }

  getIsSuperAdmin = () => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/admins/is_super_admin`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
      .then(handleApiResponse)
      .then(result => result.is_super_admin || false)
      .catch(error => {
        console.warn(`Error while checking SuperAdmin rights : ${error}`);
      })
  }

  getUserInfos = (tokens, isSuperAdmin) => {
    const token = tokens.access_token;
    const myBearer = {
      Authorization: `Bearer ${token}`,
      Origin: window.location.href //for https://cors-anywhere.herokuapp.com/
    };
    return fetch(`${UserInfoOpenId_URL}`, {
      method: "POST",
      headers: myBearer
    })
    .then(result => { //if I don't receive your infos profile, you are not authorized to the app
      if(result.status === 200){
        return result;
      } else {
        this.props.setUserState({ isLoggingIn: false });
        window.location.replace(redirect_uri);
      }
    })
    .then(result => result.json())
    .then(result => {
      localStorage.setItem("givenName", result.givenName);
      localStorage.setItem("avatar", result.photourl);
      localStorage.setItem("email", result.mail.toLowerCase());
      localStorage.setItem("uid", result.uid);
      this.props.setUserState({
        givenName: result.givenName,
        avatar: result.photourl,
        email: result.mail.toLowerCase(),
        uid: result.uid,
        isSuperAdmin: isSuperAdmin || false,
        isLoggedIn: true,
        isLoggingIn: false,
      });
    })
    .catch(error => {
      console.warn(`Error while retrieving infos for user : ${error}`);
    })
  }

  updateTokens = ({ token, refreshToken, tokenExpiresIn }) => {
    const now = Date.now() / 1000;
    const tokenExpireDate = now + tokenExpiresIn;
    this.props.setUserState({ token, refreshToken, tokenExpireDate });
  }

  logOut = async () => {
    const { token, refreshToken, tokenExpireDate } = this.props.user;
    let updatedToken = token;
    let updatedRefreshToken = refreshToken;
    this.props.setUserState({ isLoggingOut: true });
    try {
      const authHeaders = await getAuthHeaders({
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens: ({ token, refreshToken, tokenExpiresIn }) => {
          updatedToken = token;
          updatedRefreshToken = refreshToken;
          this.props.updateTokens({ token, refreshToken, tokenExpiresIn });
        },
      });
      const result = await fetch(`${serverUrl}/v1/auth/logout`, {
        method: 'POST',
        headers: {
          ...authHeaders,
          ...myHeader,
        },
        body: JSON.stringify({
          access_token: updatedToken,
          refresh_token: updatedRefreshToken,
        }),
      });
      localStorage.setItem("reauthenticateRequired", "true");
      localStorage.setItem("givenName", "undefined");
      localStorage.setItem("email", "undefined");
      localStorage.setItem("avatar", "undefined");
      localStorage.setItem("uid", "undefined");
      this.props.resetState();
      if (result.status === 401) {
        window.location.reload();
      }
      this.props.setUserState({
        givenName: null,
        avatar: null,
        email: null,
        uid: null,
        isLoggedIn: false,
      });
    } catch (error) {
      console.warn(`Log out failed : ${error}`);
    } finally {
      this.props.setUserState({ isLoggingOut: false });
    }
  }

  checkAdmin = (routerProps, myRoute) => {
    const redirection = `/${localStorage.getItem("store_number")}/authorizationDenied`;
    if(this.props.user.admin){
      if (myRoute === "admin"){
        return(
          <AdminAddresses
            user={this.props.user}
            updateTokens={this.props.updateTokens}
            logOut={this.logOut}
            {...routerProps}
          />
        )
      } else if (myRoute === "authorizations"){
        return(
          <Authorizations
            user={this.props.user}
            updateTokens={this.props.updateTokens}
            logOut={this.logOut}
            {...routerProps}
          />
        )
      } else if (myRoute === "dashboard"){
        return(
          <DashBoard
            user={this.props.user}
            updateTokens={this.props.updateTokens}
            logOut={this.logOut}
            {...routerProps}
          />
        )
      }
    } else {
      return(
        <Redirect to = {redirection}/>
      )
    }
  }

  checkSuperAdmin = (routerProps, myRoute) => {
    const redirection = `/${localStorage.getItem("store_number")}/authorizationDenied`;
    if(this.props.user.isSuperAdmin){
      if (myRoute === "releases"){
        return(
          <Releases
            user={this.props.user}
            updateTokens={this.props.updateTokens}
            logOut={this.logOut}
            {...routerProps}
          />
        )
      } else if (myRoute === 'release'){
        return(
          <Release
            user={this.props.user}
            updateTokens={this.props.updateTokens}
            logOut={this.logOut}
            {...routerProps}
          />
        )
      }
    } else {
      return(
        <Redirect to = {redirection}/>
      )
    }
  }

  render() {
    if (this.state.iAmRedirectedFromGoogleOAuth) {
      return(
        <GoogleOAuth/>
      )
    } else {
      const reauthenticate = localStorage.reauthenticateRequired === "true";
      return (
        <Router>
          <div>
            <Route exact path="/" render= {(routerProps) =>
              <Login
                user={this.props.user}
                updateTokens={this.props.updateTokens}
                reauthenticate={reauthenticate}
                logOut={this.logOut}
                {...routerProps}
              >
                <Redirect to="/" />
              </Login>
            }/>
            <Route exact path="/:store/pickingList" render= {(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                <PickingList
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  logOut={this.logOut}
                  {...routerProps}
                />
              </NeedsToBeLoggedIn>
            }/>
            <Route path="/:store/home" render={(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                <Home
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  logOut={this.logOut}
                  {...routerProps}
                />
              </NeedsToBeLoggedIn>
            }/>
            <Route path="/:store/orders" render={(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                <Orders
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  logOut={this.logOut}
                  {...routerProps}
                />
              </NeedsToBeLoggedIn>
            }/>
            <Route exact path="/:store/trackingNumbers" render={(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                <TrackingNumbers
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  logOut={this.logOut}
                  {...routerProps}
                />
              </NeedsToBeLoggedIn>
            }/>
            <Route path="/:store/trackingNumbers/:trackingNumber" render={(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                <TrackingNumber
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  logOut={this.logOut}
                  {...routerProps}
                />
              </NeedsToBeLoggedIn>
            }/>
            <Route path="/:store/ordersMount" render={(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                <OrderMountList
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  logOut={this.logOut}
                  {...routerProps}
                />
              </NeedsToBeLoggedIn>
            }/>
            <Route path="/:store/searchAddress" render={(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                <SearchAddress
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  logOut={this.logOut}
                  {...routerProps}
                />
              </NeedsToBeLoggedIn>
            }/>
            <Route exact path="/:store/movements-history" render= {(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                <MovementsHistory
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  logOut={this.logOut}
                  {...routerProps}
                />
              </NeedsToBeLoggedIn>
            }/>
            <Route exact path="/:store/admin" render= {(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                {this.checkAdmin(routerProps, "admin")}
              </NeedsToBeLoggedIn>
            }/>
            <Route exact path="/releases" render= {(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                {this.checkSuperAdmin(routerProps, "releases")}
              </NeedsToBeLoggedIn>
            }/>
            <Route exact path="/releases/:id" render= {(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                {this.checkSuperAdmin(routerProps, "release")}
              </NeedsToBeLoggedIn>
            }/>
            <Route exact path="/changeStore" render={(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                <ChangeStore
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  resetState={this.props.resetState}
                  logOut={this.logOut}
                  {...routerProps}
                />
              </NeedsToBeLoggedIn>
            }/>
            <Route exact path="/:store/report" render={(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                <Report
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  logOut={this.logOut}
                  {...routerProps}
                />
              </NeedsToBeLoggedIn>
            }/>
            <Route exact path="/:store/helpFiles" render={(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                <HelpFiles
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  logOut={this.logOut}
                  {...routerProps}
                />
              </NeedsToBeLoggedIn>
            }/>
            <Route exact path="/:store/dashboard" render={(routerProps) =>
                <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                  {this.checkAdmin(routerProps, "dashboard")}
                </NeedsToBeLoggedIn>
            }/>
            <Route exact path="/:store/DecaShop_orders" render={(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                <EcomOrders
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  logOut={this.logOut}
                  {...routerProps}
                />
              </NeedsToBeLoggedIn>
            }/>
            <Route exact path="/:store/EcomPickingList" render= {(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                <EcomPickingList
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  logOut={this.logOut}
                  {...routerProps}
                />
              </NeedsToBeLoggedIn>
            }/>
            <Route exact path="/:store/ecom-packing" render= {(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                <EcomPacking
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  logOut={this.logOut}
                  {...routerProps}
                />
              </NeedsToBeLoggedIn>
            }/>
            <Route exact path="/:store/ecom-trolley/:id" render= {(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                <EcomTrolley
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  logOut={this.logOut}
                  {...routerProps}
                />
              </NeedsToBeLoggedIn>
            }/>
            <Route exact path="/:store/catalog" render= {(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                <Catalog
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  logOut={this.logOut}
                  {...routerProps}
                />
              </NeedsToBeLoggedIn>
            }/>
            <Route exact path="/:store/authorizationDenied" render= {(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                <AuthorizationDenied
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  logOut={this.logOut}
                  {...routerProps}
                />
              </NeedsToBeLoggedIn>
            }/>
            <Route exact path="/:store/authorizations" render= {(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                {this.checkAdmin(routerProps, "authorizations")}
              </NeedsToBeLoggedIn>
            }/>
            <Route exact path="/:store/layout-requirements" render= {(routerProps) =>
              <NeedsToBeLoggedIn isLoggedIn={this.props.user.isLoggedIn}>
                <LayoutRequirements
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  logOut={this.logOut}
                  {...routerProps}
                />
              </NeedsToBeLoggedIn>
            }/>
          </div>
          {this.props.maintenance && <Maintenance />}
        </Router>
      )
    }
  }
}

function NeedsToBeLoggedIn(props) {
  return props.isLoggedIn === true
    ? props.children
    : props.isLoggedIn === false
      ? <Redirect to="/" />
    : <div/>
}

const mapStateToProps = (state) => ({
  user: state.user,
  maintenance: state.maintenance.on,
});

const mapDispatchToProps = (dispatch) => {
  return {
    resetState: () => dispatch(resetAction()),
    setUserState: (user) => dispatch(setUserState(user)),
    updateTokens: ({ token, refreshToken, tokenExpiresIn }) => dispatch(updateTokens({ token, refreshToken, tokenExpiresIn })),
  }
};

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
const TranslatedApp = withTranslation()(ConnectedApp);

// root App component should be wrapped with withTranslation
export default TranslatedApp;
