import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

export const TablePagination = ({ pagination, totals, setPagination }) => {
  const isFirst = pagination.page === 0;
  const isLast = pagination.page + 1 === totals.pages;
  const hasFirst = !isFirst;
  const hasLast = totals.pages > 1 && !isLast;
  const hasLeftEllipsis = pagination.page + 1 >= 4;
  const hasRightEllipsis = totals.pages - pagination.page >= 4;
  const hasMiddleLeft = pagination.page + 1 >= 3;
  const hasMiddleRight = totals.pages - pagination.page >= 3;

  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <ul className="footer-pagination pagination pagination-sm">
      <li className={`page-item ${isFirst ? 'disabled' : ''}`} key="prev">
        <a
          className="page-link"
          role="button"
          href="#"
          onClick={() => setPagination({ ...pagination, page: pagination.page - 1 })}
        >
          <span aria-hidden="true">‹</span>
          <span className="sr-only">
            <Trans i18nKey="pagination.previous">Previous</Trans>
          </span>
        </a>
      </li>

      {hasFirst && (
        <li className="page-item" key={0}>
          <a
            className="page-link"
            role="button"
            href="#"
            onClick={() => setPagination({ ...pagination, page: 0 })}
          >
            1
          </a>
        </li>
      )}
      {hasLeftEllipsis && (
        <li className="page-item disabled">
          <span className="page-link" disabled="">
            <span aria-hidden="true">…</span>
            <span className="sr-only">
              <Trans i18nKey="pagination.more">More</Trans>
            </span>
          </span>
        </li>
      )}
      {hasMiddleLeft && (
        <li className="page-item" key={pagination.page - 1}>
          <a
            className="page-link"
            role="button"
            href="#"
            onClick={() => setPagination({ ...pagination, page: pagination.page - 1 })}
          >
            {pagination.page}
          </a>
        </li>
      )}
      <li className="page-item active" key={pagination.page}>
        <a
          className="page-link"
          role="button"
          href="#"
          onClick={() => setPagination({ ...pagination })}
        >
          {pagination.page + 1}
        </a>
      </li>
      {hasMiddleRight && (
        <li className="page-item" key={pagination.page + 1}>
          <a
            className="page-link"
            role="button"
            href="#"
            onClick={() => setPagination({ ...pagination, page: pagination.page + 1 })}
          >
            {pagination.page + 2}
          </a>
        </li>
      )}
      {hasRightEllipsis && (
        <li className="page-item disabled">
          <span className="page-link" disabled="">
            <span aria-hidden="true">…</span>
            <span className="sr-only">
              <Trans i18nKey="pagination.more">More</Trans>
            </span>
          </span>
        </li>
      )}
      {hasLast && (
        <li className="page-item" key={totals.pages - 1}>
          <a
            className="page-link"
            role="button"
            href="#"
            onClick={() => setPagination({ ...pagination, page: totals.pages - 1 })}
          >
            {totals.pages}
          </a>
        </li>
      )}

      <li className={`page-item ${isLast ? 'disabled' : ''}`} key="next">
        <a
          className="page-link"
          role="button"
          href="#"
          onClick={() => setPagination({ ...pagination, page: pagination.page + 1 })}
        >
          <span aria-hidden="true">›</span>
          <span className="sr-only">
            <Trans i18nKey="pagination.next">Next</Trans>
          </span>
        </a>
      </li>
    </ul>
  );
};

export const PageSizeSelect = ({ pagination, setPagination }) => (
  <div className="pagesize-wrapper">
    <div>
      <Trans i18nKey="pagination.pageSize">Page size:</Trans>
    </div>
    <select
      className="footer-size-select custom-select custom-select-sm"
      value={pagination.size}
      onChange={(event) => setPagination({
        page: 0,
        size: Number(event.target.value),
      })}
    >
      <option>10</option>
      <option>20</option>
      <option>50</option>
      <option>200</option>
    </select>
  </div>
);

export const PaginationInfo = ({ pagination, totals }) => {
  const { t } = useTranslation();
  const from = totals.items && (pagination.page * pagination.size + 1);
  const to = Math.min((pagination.page + 1) * pagination.size, totals.items);

  return (
    <div className="footer-info">
      {t('pagination.info', {
        defaultValue: 'Showing {{from}} to {{to}} of {{total}}',
        from,
        to,
        total: totals.items,
      })}
    </div>
  );
};
