import { useCallback } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

const INITIAL_STATE = {
  version: '1.0.3',
  lastPrinter: null,
  format: 'A4',
  orientation: 'portrait',
  padding: 0.2,
};


const getInitialState = () => {
  try {
    if (localStorage.printers) {
      const state = JSON.parse(localStorage.printers);
      if (state.version === INITIAL_STATE.version) {
        return state;
      }
    }
  } catch (e) {}
  return INITIAL_STATE;
};

export const printersSlice = createSlice({
  name: 'printers',
  initialState: getInitialState(),
  reducers: {
    setLastPrinterAction: (state, action) => {
      state.lastPrinter = action.payload;
      localStorage.printers = JSON.stringify(state);
    },
    setFormatAction: (state, action) => {
      state.format = action.payload;
      localStorage.printers = JSON.stringify(state);
    },
    setOrientationAction: (state, action) => {
      state.orientation = action.payload;
      localStorage.printers = JSON.stringify(state);
    },
    setPaddingAction: (state, action) => {
      state.padding = action.payload;
      localStorage.printers = JSON.stringify(state);
    },
    resetAction: () => {
      return INITIAL_STATE;
    },
  },
});

export const {
  setLastPrinterAction,
  setFormatAction,
  setOrientationAction,
  setPaddingAction,
  resetAction,
} = printersSlice.actions;

export const usePrintersState = () => {
  const { lastPrinter, format, orientation, padding } = useSelector((state) => state.printers);
  const dispatch = useDispatch();
  const setLastPrinter = useCallback((x) => dispatch(setLastPrinterAction(x)), [dispatch]);
  const setFormat = useCallback((x) => dispatch(setFormatAction(x)), [dispatch]);
  const setOrientation = useCallback((x) => dispatch(setOrientationAction(x)), [dispatch]);
  const setPadding = useCallback((x) => dispatch(setPaddingAction(x)), [dispatch]);
  const reset = useCallback(() => dispatch(resetAction()), [dispatch]);

  return {
    lastPrinter,
    setLastPrinter,
    format,
    setFormat,
    orientation,
    setOrientation,
    padding,
    setPadding,
    reset,
  };
}

export default printersSlice.reducer;
