import _ from "lodash";
import { normalizeName } from '../../functions/formatText';

export const NEED_MOUNT_STATUSES = ['partialy_received', 'ready_tomount'];
export const MOUNTED_STATUS = 'mounted';

export const getOrderToMount = (t, status) => {
  const isMounted = status === MOUNTED_STATUS;
  const toMount = NEED_MOUNT_STATUSES.includes(status);
  if (isMounted) {
    return {
      status,
      label: t("orderMountStatus.mounted", "Mounted"),
      color: "success",
    }
  }
  if (toMount) {
    return {
      status,
      label: t("orderMountStatus.readyToMount", "To mount"),
      color: "accent",
    }
  }
  return null;
};

export const getMountStatus = (t, status) => {
  const statusMap = {
    ready_tomount: t("orderMountStatus.readyToMount", "To mount"),
    partialy_received: t(
      "orderMountStatus.partiallyReceived",
      "Partially received"
    ),
    mounted: t("orderMountStatus.mounted", "Mounted"),
  };
  const colorMap = {
    ready_tomount: "accent",
    partialy_received: "warning",
    mounted: "success",
  };
  return {
    status,
    label: statusMap[status] || status,
    color: colorMap[status] || "primary",
  };
};

export const filterMounts = (mounts, filters, sort) => {
  let filteredMounts = (mounts || []).filter((mount) => {
    if (filters.order_number) {
      if (mount.order.order_number.indexOf(filters.order_number) === -1) {
        return false;
      }
    }
    if (filters.customer) {
      const customer = `${normalizeName(
        mount.order.customer.firstName
      )} ${normalizeName(mount.order.customer.lastName)}`.toLowerCase();
      if (customer.indexOf(filters.customer.toLowerCase()) === -1) {
        return false;
      }
    }
    if (filters.mount_status && filters.mount_status.length) {
      if (!filters.mount_status.includes(mount.mount_state.mount_status)) {
        return false;
      }
    }
    return true;
  });
  filteredMounts = _.orderBy(
    filteredMounts,
    [sort.field],
    [sort.direction.toLowerCase()],
  );
  return filteredMounts;
};

export const isNeedMount = (mountStatus, isOrderToMount) => {
  const needMount = isOrderToMount || NEED_MOUNT_STATUSES.includes(mountStatus);
  return needMount;
};
