import React, { useState, useEffect, useMemo } from 'react';
import { ClipLoader } from 'react-spinners';
import { useTranslation, Trans } from 'react-i18next';
import useRequest from '../../functions/useRequest';

const useEvent = (event, handler) => {
  useEffect(() => {
    window.addEventListener(event, handler);
    return () => window.removeEventListener(event, handler);
  }, [event, handler]);
};

const onExportGranted = (
  doExportReport,
  user,
  updateTokens,
  filters,
  sort,
  setOauthToken,
  setTokenValidUntil
) => (event) => {
  if (event.key === 'credentials' && event.newValue !== null){
    const credentials = JSON.parse(event.newValue)
    const oauthToken = credentials.token;
    const validUntil = credentials.validUntil;
    doExportReport(oauthToken, user, updateTokens, { filters, sort });
    setOauthToken(oauthToken);
    setTokenValidUntil(validUntil);
  }
};

const onExportOauth = () => {
  const baseUrl = "https://accounts.google.com/o/oauth2/v2/auth";
  const client_id = process.env.REACT_APP_spreadsheet_clientId;
  const redirect_uri = `${process.env.REACT_APP_fedID_redirect_URI}googleOAuth`;
  const scope = 'https://www.googleapis.com/auth/spreadsheets';
  window.open(`${baseUrl}?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=token&scope=${scope}`, "_blank")
};

const onExportComplete = (resp) => {
  window.open(`https://docs.google.com/spreadsheets/d/${resp.id}`, "_blank");
};

const isValid = (tokenValidUntil) => {
  if (!tokenValidUntil) {
    return false;
  }

  const VALID_LIMIT_MS = 20000;
  const validUntil = new Date(tokenValidUntil);
  const now = new Date();
  const msLeft = validUntil.getTime() - now.getTime();

  const tokenValid = msLeft > VALID_LIMIT_MS;
  return tokenValid;
};

const EXPORT_LIMIT = 50000;

const ExportConfirm = ({ onConfirm }) => {
  const { t } = useTranslation();
  return (
    <div
      id="export-confirm"
      className="filter-export-confirm fade modal"
      role="dialog" tabIndex="-1" aria-modal="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">
              <Trans i18nKey="shared.warning">Warning</Trans>
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              <Trans i18nKey="exportButton.toMuchData">You are about to export too much data.</Trans>
            </p>
            <p>
              {t('exportButton.limited', {
                defaultValue: 'The data would be limited with {{limit}} rows.',
                limit: EXPORT_LIMIT,
              })}
            </p>
            <p>
              <Trans i18nKey="exportButton.continue">Continue export?</Trans>
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              data-target="#export-confirm"
            >
              <Trans i18nKey="shared.close">Close</Trans>
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              data-target="#export-confirm"
              onClick={onConfirm}
            >
              <Trans i18nKey="exportButton.export">Export</Trans>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ExportButton = ({
  user,
  updateTokens,
  filters,
  sort,
  totalItems,
  exportReport,
  size,
}) => {
  const [oauthToken, setOauthToken] = useState(null);
  const [tokenValidUntil, setTokenValidUntil] = useState(null);
  const [{
    loading,
    error,
  }, doExportReport] = useRequest(exportReport, {
    onSuccess: onExportComplete,
    onError: (e) => console.error('Export error', e),
  })
  const memoizedOnExportGranted = useMemo(() => onExportGranted(
    doExportReport,
    user,
    updateTokens,
    filters,
    sort,
    setOauthToken,
    setTokenValidUntil,
  ), [
    doExportReport,
    user,
    updateTokens,
    filters,
    sort,
    setOauthToken,
    setTokenValidUntil,
  ]);
  useEvent('storage', memoizedOnExportGranted);

  const onExport = () => {
    if (oauthToken && isValid(tokenValidUntil)) {
      doExportReport(oauthToken, user, updateTokens, { filters, sort });
    } else {
      onExportOauth();
    }
  };

  return (
    <div className={`filter-export ${error ? 'filter-export-witherror' : ''}`}>
      <button
        type="button"
        disabled={loading}
        className={`icon-button filter-button btn btn-primary btn-${size || 'sm'}`}
        onClick={() => {
          if (totalItems > EXPORT_LIMIT) {
            window.$("#export-confirm").modal('show');
          } else {
            onExport();
          }
        }}
      >
        {(
          loading
            ? (
              <span className="button-loader">
                <ClipLoader color="#fff" size={15} loading />
              </span>
            )
            : <i className="vtmn-icon_external_link"></i>
        )}
        <Trans i18nKey="exportButton.export">Export</Trans>
      </button>
      {error && <span className="filter-export-error" title={error.message}>{error.message}</span>}

      <ExportConfirm
        onConfirm={() => {
          onExport();
        }}
      />
    </div>
  )
};

export default ExportButton;
