import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import _  from 'lodash';

import useRequest from '../../functions/useRequest';
import ErrorMessage from '../../components/widgets/errorMessage';
import Loader from '../../components/Loader';
import WithSidebar from '../WithSidebar';
import EcomTrolleyContainer from '../EcomTrolleyContainer';

import { getItemDetails, getBoxes } from '../EcomPickingList/api';
import { isOrderAddressed } from '../EcomTrolleyContainer/helpers';
import { getTrolley } from './api';

const resolvePackingOrderStatus = (order, trolleyStatus) => {
  const isAddressed = isOrderAddressed(order)
  const boxStatus = order.status
  const orderStatus = order.order_status
  const finalizeStatus = order.finalize_status

  // special cases
  if (boxStatus === 'cancelled' || orderStatus === 'Cancelled') return 'cancelled'
  if (orderStatus === 'Wait') return 'wait'
  if (boxStatus === 'started') return 'inProgress'
  // flow
  if (isAddressed) return 'addressed'
  // ready to address only for C&C orders
  if (order.can_address && !order.is_carrier_package) return 'readyToAddress'

  //carrier shipping case
  if (order.is_carrier_package) {
    if (order.carrier_status === 'pending') return 'pendingCarrier'
    if (order.carrier_status === 'anomaly') return 'anomaly'
    if (order.carrier_status === 'shipped') return 'shipped'
    if (order.carrier_status === 'tracked') return 'readyForCarrier'
    if (order.carrier_status === 'manual' || order.is_manual) return 'manual'
    if (order.can_print) return 'readyToPrint'
  }

  if (finalizeStatus) {
    if (finalizeStatus === 'error') return 'error'
    if (finalizeStatus === 'finalized' || finalizeStatus === 'skipped') return 'picked'
    if (finalizeStatus === 'cancelled') return 'cancelled'
  }
  if (trolleyStatus === 'started' && !finalizeStatus) {
    return 'inProgress'
  }

  return null
};

const EcomTrolley = props => {
  const { user, updateTokens, logOut } = props;
  const { store, id } = props.match.params || {};

  const [error, setError] = useState(null);

  const [{ loading, data }, fetchTrolley] = useRequest(getTrolley, {
    onError: setError,
  });

  const [{ loading: isItemsInfoLoading, data: itemInfos }, fetchItemsInfo] = useRequest(getItemDetails, {
    onError: setError,
  });

  const [{ data: boxes, loading: isBoxesLoading }, fetchBoxes] = useRequest(getBoxes, {
    onError: setError,
  });

  const refetchTrolley = async () => {
    const t = await fetchTrolley({ store, id, user, updateTokens });
    const orderIds = _.uniq(t?.orders.map((x) => x.order_id) || []);
    if (!orderIds.length) return;
    await fetchItemsInfo({ store, items: t?.item_ids, endOfLife: true , user, updateTokens });
  };

  useEffect(() => {
    fetchBoxes({ store, user, updateTokens });
    refetchTrolley();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WithSidebar user={user} updateTokens={updateTokens} logOut={logOut}>
      <div className = "jumbotron container">
        <h2 className="text-center">
          <Trans i18nKey="ecomTrolley.trolley">Ecom Trolley</Trans>
        </h2>
        {error && (
          <ErrorMessage error={error} onClose={() => setError(null)} />
        )}
        <div>
          {loading ? (
            <div className="text-center">
              <Loader />
            </div>
          ) : data ? (
            <EcomTrolleyContainer
              boxes={boxes || []}
              trolley={data || null}
              itemInfos={itemInfos || []}
              isLoading={isItemsInfoLoading || isBoxesLoading}
              showInfo
              store={store}
              user={user}
              updateTokens={updateTokens}
              onError={setError}
              onRefresh={refetchTrolley}
              pickingListCount={data.status === 'finalize-pending' ? 0 : undefined}
              statusResolver={resolvePackingOrderStatus}
            />
          ) : (
            <div className="text-center">
              <Trans i18nKey="ecomTrolley.notFound">Trolley not found</Trans>
            </div>
          )}
        </div>
      </div>
    </WithSidebar>
  );
}

export default EcomTrolley;
