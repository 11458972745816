import React from 'react';
import { useTranslation } from 'react-i18next';
import { truncate } from 'lodash';

import useRequest from '../../functions/useRequest';
import { useUserReduxState } from '../../store/user';
import Confirm from '../../components/widgets/Confirm';

import { removeReleaseChangelogQuery } from './api';

const ReleaseChangelogRemoveConfirmDialog = (props) => {
  const { show, release, changelog, onDone, onHide } = props;
  const { t } = useTranslation();
  const { user, updateTokens } = useUserReduxState();

  const [{ loading, error }, removeReleaseChangelog] = useRequest(removeReleaseChangelogQuery, {
    onSuccess: onDone,
  });

  const onConfirm = () => removeReleaseChangelog({
    id: release.id,
    changelogId: changelog.id,
    user,
    updateTokens,
  });

  return (
    <Confirm
      show={show}
      onHide={onHide}
      loading={loading}
      error={error}
      title={t('releases.changelogRemove', 'Changelog remove')}
      body={t('version.changelogRemoveConfirm', {
        defaultValue: 'Do you want to remove changelog «{{changelog}}» from {{release}} release?',
        changelog: truncate(changelog.title || '', { length: 30 }),
        release: `${release.version} (${release.version_code}) ${release.type}`,
      })}
      buttonOpts={{ variant: 'danger' }}
      onConfirm={onConfirm}
    />
  );
};

export default ReleaseChangelogRemoveConfirmDialog;
