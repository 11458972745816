import React from 'react';
import { useTranslation } from 'react-i18next';
import HelpTooltipIcon from '../../components/widgets/HelpTooltipIcon';

export const getStatusDetails = (t) => ({
  CREATED: t('report.orderStatus.created', 'The order was created but not yet prepared'),
  IN_LOGISTICS_PREPARATION: t('report.orderStatus.inLogisticsPreparation', 'The order is being prepared'),
  DELAYED: t('report.orderStatus.delayed', 'Late order'),
  SHIPPED: t('report.orderStatus.shipped', 'The order was shipped by the warehouse, but not received yet'),
  CAN_PICK_UP: t('report.orderStatus.canPickup', 'Order ready to be picked by the customer'),
  COMPLETED: t('report.orderStatus.completed', 'The order was given to the customer'),
  CANCELLED: t('report.orderStatus.cancelled', 'The order was canceled'),
  READY_FOR_PICKING: t('report.orderStatus.readyForPicking', 'The order picking is ready to start'),
  REPLENISHMENT_IN_PROGRESS: t('report.orderStatus.replenishmentInProgress', 'Some items of the order are not available. We are waiting for the full quantities to be available.'),
});

const OrderStatusHelpIcon = () => {
  const { t } = useTranslation();
  const statusDetails = getStatusDetails(t)
  return (
    <HelpTooltipIcon id="order-status-tooltip">
      {Object.entries(statusDetails).map(([status, details]) => (
        <div key={status}>
          <b>{status}</b>
          {' - '}
          {details}
        </div>
      ))}
    </HelpTooltipIcon>
  );
};

export default OrderStatusHelpIcon;
