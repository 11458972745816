const InputNumber = ({ inline, controlId, label, value, min, max, step, onChange }) => {
  return (
    <>
      {label && !inline && (
        <label className="select-form-label" htmlFor={controlId}>
          {label}
          {':'}
        </label>
      )}
      <div className="input-group">
        {label && inline && (
          <div className="input-group-prepend">
            <label className="input-group-text" htmlFor={controlId}>
              {label}
            </label>
          </div>
        )}
        <input
          id={controlId}
          type="number"
          min={min}
          max={max}
          step={step}
          className="form-control m-0"
          value={value}
          onChange={event => onChange(event.target.value)}
        />
      </div>
    </>
  );
};

export default InputNumber;
