export const getActiveTrack = (video) => {
  if (!video) {
    return;
  }

  const mediaStream = video.srcObject;
  const tracks = mediaStream && mediaStream.getVideoTracks();
  const track = tracks && tracks[0];
  return track;
};

function hasNavigator() {
  return typeof navigator !== 'undefined';
}

function isMediaDevicesSupported() {
  return hasNavigator() && !!navigator.mediaDevices;
}
function canEnumerateDevices() {
  return !!(isMediaDevicesSupported() && navigator.mediaDevices.enumerateDevices);
}

export const listVideoInputDevices = async () => {
  if (!hasNavigator()) {
    throw new Error('Can\'t enumerate devices, navigator is not present.');
  }

  if (!canEnumerateDevices()) {
    throw new Error('Can\'t enumerate devices, method not supported.');
  }

  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoDevices = [];

  for (const device of devices) {
    const kind = device.kind === 'video' ? 'videoinput' : device.kind;

    if (kind !== 'videoinput') {
      continue;
    }

    const deviceId = device.deviceId || device.id;
    const label = device.label || `Video device ${videoDevices.length + 1}`;
    const groupId = device.groupId;
    const videoDevice = { deviceId, label, kind, groupId };
    videoDevices.push(videoDevice);
  }

  return videoDevices;
}

const getDeviceName = () => {
  if (!hasNavigator() || !window.navigator.userAgent) {
    return 'Default';
  }
  const { userAgent } = window.navigator;
  const rx = /\([^()]+\)/;
  const sysInfo = userAgent.match(rx)[0] || '(Default)';
  const name = sysInfo.slice(1, sysInfo.length - 1).replace(/[\s;]+/g, '_');
  return name;
}

export const getLastEpcDeviceId = () => {
  try {
    const deviceId = JSON.parse(localStorage.cameraState).deviceId;
    const deviceName = getDeviceName();
    return `${deviceName}:${deviceId}`;
  } catch {
    return null
  }
}
