import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Loader from '../../components/Loader';
import ItemDetails from './ItemDetails';
import {
  AllLockedMessage,
  OtherTrackingFoundMessage,
  WrongStoreMessage,
  CanceledMessage,
  CompletedMessage,
  UnexpectedMessage,
  LockedMessage,
  NoPackagingOrderAddingError,
  OrderToMountMessage,
  InLogisticsMessage,
} from './messages';
import ItemScannerInput from '../../components/widgets/ItemScannerInput';
import AddressScannerInput from '../../components/widgets/AddressScannerInput';
import { TNRelatedSgippingGroupMessage, getTNHandleRelatedSgPress } from '../../components/widgets/RelatedSgippingGroupMessage';
import useRequest from '../../functions/useRequest';
import ItemToMountSwitch from '../OrdersMount/components/ItemToMountSwitch';
import { isNeedMount } from '../OrdersMount/helpers';
import { useStoreInfoReduxState } from '../../store/storeInfo';

const getChecks = (orderItem) => {
  let isUnexpected = false;
  let isAssigning = false;
  let isFound = false;
  const isCanceled = orderItem?.shipping_group?.is_canceled;
  const isCompleted = orderItem?.shipping_group?.is_completed;
  const isWrongStore = orderItem?.shipping_group?.is_wrong_store;
  const isInLogistics = orderItem?.shipping_group?.is_in_logistics;

  if (orderItem) {
    if (orderItem?.state === 'to_receive') {
      if (isCanceled || isCompleted || isWrongStore) {
        isUnexpected = true;
      } else {
        isAssigning = true;
      }
    } else if (orderItem?.state === 'missing') {
      isFound = true;
    } else {
      isUnexpected = true;
    }
  }

  return {
    isUnexpected,
    isAssigning,
    isFound,
    isCanceled,
    isCompleted,
    isWrongStore,
    isInLogistics,
  };
};

const OptionItem = ({
  orderItem,
  onAction,
}) => {
  const { t } = useTranslation();
  const { state, order, shipping_group, tracking_number, lockedBy } = orderItem;
  return (
    <div className="tracking-number-order-options">
      <div className="order-item">
        <div>
          <div className="text-secondary font-weight-bold d-inline-block mr-1">
            {t('trackingNumber.order', 'Order')}
            {': '}
          </div>
          {`${order.order_number || ''} - ${shipping_group.shipping_group_number || ''}`}
        </div>
        {tracking_number && (
          <div>
            <div className="text-secondary font-weight-bold d-inline-block mr-1">
              {t('trackingNumber.trackingNumber', 'Tracking number')}
              {': '}
            </div>
            {tracking_number}
          </div>
        )}
        {lockedBy && (
          <div>
            <div className="text-secondary font-weight-bold d-inline-block mr-1">
              <Trans i18nKey="trackingNumber.isProcessing">Is processing</Trans>
              {': '}
            </div>
            {lockedBy}
          </div>
        )}
      </div>
      <div className="order-actions">
        {state === 'to_receive' ? (
          <Button
            block
            variant="success"
            onClick={onAction}
          >
            <Trans i18nKey="trackingNumber.assign">Assign</Trans>
          </Button>
        ) : null}

        {state === 'missing' ? (
          <Button
            block
            variant="primary"
            onClick={onAction}
          >
            <Trans i18nKey="trackingNumber.found">Found</Trans>
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const Options = ({ options, onAction, title }) => {
  return (
    <>
      <h4>{title}</h4>
      {options.map(option => (
        <OptionItem
          key={option.id}
          orderItem={option}
          onAction={() => onAction(option)}
        />
      ))}
    </>
  );
};

// actions: Assign / Found missing / Unexpected
const ActionModal = ({
  user,
  updateTokens,
  modalLoading,
  modalError,
  orderItem,
  show,
  onClose,
  fromItemSearch,
  saveUnexpectedItem,
  backToSaleUnexpectedItem,
  foundMissing,
  assignOrderItem,
  doUnlockItem,
  doForceLockItem,
  reloadOrderItems,
  lockedOptions,
  otherTrackingOptions,
  setLockedOptions,
  setOtherTrackingOptions,
  setSelectedSGItem,
  setBarcodePreview,
  setEpc,
  latestAddress,
  setLatestAddress,
}) => {
  const {
    isUnexpected,
    isAssigning,
    isFound,
    isCanceled,
    isCompleted,
    isWrongStore,
    isInLogistics,
  } = getChecks(orderItem);
  const isOrderToMount = orderItem?.order?.is_order_tomount;
  const mountStatus = orderItem?.order?.mount_status;
  const needMount = isNeedMount(mountStatus, isOrderToMount);
  const { uid } = user;
  const hasLockedOptions = lockedOptions?.length > 0;
  const hasOtherTrackingOptions = otherTrackingOptions?.length > 0;
  const lockedBy = orderItem?.lockedBy;
  const isLocked = !!lockedBy && lockedBy !== uid;
  const { store } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { store: storeInfo } = useStoreInfoReduxState();
  const [itemId, setItemId] = useState('');
  const [address, setAddress] = useState('');
  const [isTomount, setIsTomount] = useState(false);
  const [validatedAddress, setValidatedAddress] = useState(null);
  const [error, setError] = useState(null);
  const onError = (e) => {
    setError(e);
    reloadOrderItems();
  };
  const onHide = () => {
    setItemId('');
    setAddress('');
    setIsTomount(needMount);
    setError(null);
    doUnlockItem();
    onClose();
  };
  const onSuccess = (res) => {
    setLatestAddress(address);
    setItemId('');
    setAddress('');
    setIsTomount(needMount);
    reloadOrderItems();
    setError(null);
    onClose();
    if (res?.stock?.address_type === 'locker') {
      setBarcodePreview(res.stock.barcode_preview);
    }
  };

  const [{
    loading: savingUnexpected,
  }, doSaveUnexpectedItem] = useRequest(saveUnexpectedItem, {
    onSuccess,
    onError,
  });

  const [{
    loading: savingBackToSaleUnexpected,
  }, doBackToSaleUnexpectedItem] = useRequest(backToSaleUnexpectedItem, {
    onSuccess,
    onError,
  });

  const [{
    loading: savingAssign,
  }, doAssign] = useRequest(assignOrderItem, {
    onSuccess,
    onError,
  });
  const [{
    loading: savingFound,
  }, doFoundMissing] = useRequest(foundMissing, {
    onSuccess,
    onError,
  });

  const isInvalidItem = itemId && itemId !== orderItem.item.item_id;
  const isInvalidAddress = !address || address !== validatedAddress;
  const hasRelatedSG = !!orderItem?.related_shipping_groups?.length;

  let onSave = doSaveUnexpectedItem;
  let onForceAssignSameItem = () => {};

  if ((isUnexpected || isFound) && isCanceled) {
    onSave = doBackToSaleUnexpectedItem;
  } else if (isAssigning) {
    onSave = () => {
      if (isInvalidItem || isInvalidAddress) {
        return;
      }
      return doAssign({ address, is_tomount: isTomount });
    };
    onForceAssignSameItem = () => {
      if (isInvalidItem || isInvalidAddress) {
        return;
      }
      doAssign({
        address,
        is_tomount: isTomount,
        force_same_item_to_address: true,
      });
    };
  } else if (isFound && !isWrongStore && !isCanceled && !isCompleted) {
    onSave = () => {
      if (isInvalidItem || isInvalidAddress) {
        return;
      }
      return doFoundMissing({ address, is_tomount: isTomount });
    };
    onForceAssignSameItem = () => {
      if (isInvalidItem || isInvalidAddress) {
        return;
      }
      doFoundMissing({
        address,
        is_tomount: isTomount,
        force_same_item_to_address: true,
      });
    };
  } else if (hasLockedOptions && hasOtherTrackingOptions) {
    onSave = () => setLockedOptions([]);
  }

  const loading = (
    modalLoading
    || savingUnexpected
    || savingBackToSaleUnexpected
    || savingAssign
    || savingFound
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="tracking-number-action-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isUnexpected && !isCanceled && <Trans i18nKey="trackingNumber.unexpectedItem">Unexpected item</Trans>}
          {(isUnexpected || isFound) && isCanceled && <Trans i18nKey="orders.putInStock.putInStock">Put in store stock</Trans>}
          {isAssigning && <Trans i18nKey="trackingNumber.assignOrderItem">Assign to address</Trans>}
          {isFound && !isCanceled && <Trans i18nKey="trackingNumber.foundMissing">Found missing item</Trans>}
          {!orderItem && modalLoading && <Trans i18nKey="shared.loading">Loading</Trans>}
          {!orderItem && !modalLoading && <Trans i18nKey="shared.error">Error</Trans>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <Loader />}
        {!loading && orderItem && (
          <>
            {!mountStatus && !!isOrderToMount && (
              <OrderToMountMessage />
            )}
            {hasRelatedSG && (
              <TNRelatedSgippingGroupMessage
                relatedShippingGroups={orderItem.related_shipping_groups || []}
                mode="contained"
                onClick={getTNHandleRelatedSgPress(
                  store,
                  orderItem.order,
                  orderItem.related_shipping_groups || [],
                  history,
                  onClose,
                )}
              />
            )}
            {hasLockedOptions && (
              <AllLockedMessage />
            )}

            {!hasLockedOptions && hasOtherTrackingOptions && (
              <OtherTrackingFoundMessage />
            )}

            {!hasLockedOptions && !hasOtherTrackingOptions && (
              <>
                {isWrongStore && (
                  <WrongStoreMessage orderItem={orderItem} />
                )}
                {isCanceled && (
                  <CanceledMessage />
                )}
                {isCompleted && (
                  <CompletedMessage />
                )}
                {isInLogistics && (
                  <InLogisticsMessage />
                )}
                {isUnexpected && !isWrongStore && !isCanceled && !isCompleted && (
                  <UnexpectedMessage />
                )}

                {isLocked && (
                  <LockedMessage lockedBy={lockedBy} />
                )}
              </>
            )}

            <ItemDetails orderItem={orderItem} />

            {!isLocked && (isAssigning || (isFound && !isWrongStore && !isCanceled && !isCompleted)) && (
              <>
                <ItemToMountSwitch
                  active={storeInfo?.to_mount_enabled}
                  isTomount={isTomount}
                  setIsTomount={setIsTomount}
                  isOrderToMount={isOrderToMount}
                  mountStatus={mountStatus}
                />
                {!fromItemSearch && (
                  <ItemScannerInput
                    user={user}
                    updateTokens={updateTokens}
                    isValid={itemId && itemId === orderItem.item.item_id}
                    isInvalid={itemId && itemId !== orderItem.item.item_id}
                    invalidMessage={t('reviewProblems.invalidItemCode', 'Item code is not correct')}
                    placeholder={t('reviewProblems.inputItemCode', 'Scan item...')}
                    value={itemId}
                    setEpc={setEpc}
                    onChange={(val) => setItemId(val)}
                  />
                )}
                <AddressScannerInput
                  user={user}
                  updateTokens={updateTokens}
                  store={store}
                  placeholder={t('reviewProblems.inputAddress', 'Scan address...')}
                  value={address}
                  onChange={setAddress}
                  validatedAddress={validatedAddress}
                  setValidatedAddress={setValidatedAddress}
                  onSubmit={onSave}
                  displayLockers
                  defaultLocker={latestAddress}
                />
              </>
            )}

            {hasLockedOptions && (
              <Options
                title={t('trackingNumber.otherLocked', 'Orders in progress')}
                options={lockedOptions}
                onAction={(option) => {
                  setLockedOptions([]);
                  setOtherTrackingOptions([]);
                  setSelectedSGItem(option);
                  doForceLockItem(option);
                }}
              />
            )}
            {!hasLockedOptions && hasOtherTrackingOptions && (
              <Options
                title={t('trackingNumber.otherMissingItems', 'Missing items')}
                options={otherTrackingOptions}
                onAction={(option) => {
                  setLockedOptions([]);
                  setOtherTrackingOptions([]);
                  setSelectedSGItem(option);
                }}
              />
            )}
          </>
        )}
        {!loading && (error || modalError) && (
          <NoPackagingOrderAddingError
            error={error || modalError}
            onClose={() => setError(null)}
            onForceAssignSameItem={onForceAssignSameItem}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        {isLocked && (
          <Button variant="warning" onClick={() => doForceLockItem(orderItem)} disabled={loading}>
            <Trans i18nKey="trackingNumber.processAnyway">Process anyway</Trans>
          </Button>
        )}
        {!isLocked && (
          <>
            {(
              (isUnexpected && !isCanceled)
              || (isFound && (isWrongStore || isCompleted))
              || hasLockedOptions
              || hasOtherTrackingOptions
            ) && (
                <Button variant="primary" onClick={onSave} disabled={loading}>
                  <Trans i18nKey="trackingNumber.unexpected">Unexpected</Trans>
                </Button>
              )}
            {(isUnexpected || isFound) && isCanceled && (
              <Button variant="primary" onClick={onSave} disabled={loading}>
                <Trans i18nKey="orders.putInStock.putInStock">Put in store stock</Trans>
              </Button>
            )}

            {isAssigning && (
              <Button
                variant="primary"
                onClick={onSave}
                disabled={loading || isInLogistics}
              >
                <Trans i18nKey="trackingNumber.assign">Assign</Trans>
              </Button>
            )}
            {isFound && !isWrongStore && !isCanceled && !isCompleted && (
              <Button
                variant="primary"
                onClick={onSave}
                disabled={loading}
              >
                <Trans i18nKey="trackingNumber.found">Found</Trans>
              </Button>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default ActionModal;
