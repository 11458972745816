import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import ImageThumbs from './ImageThumbs'

import '../../stylesheets/relatedSgippingGroupMessage.css';

export const getTNHandleRelatedSgPress = (
  store,
  order,
  related_shipping_groups,
  history,
  onClose,
) => (sgNumber) => {
  const relatedSG = related_shipping_groups || [].find(x => x.shipping_group_number === sgNumber)
  if (onClose) {
    onClose();
  }
  if (relatedSG?.noPack && relatedSG.trackingNumbers?.length) {
    const tn = relatedSG.trackingNumbers[0];
    history.push(`/${store}/trackingNumbers/${tn}`);
  } else {
    history.push(`/${store}/orders?order=${order.order_number}`);
  }
}

const BaseRelatedSgippingGroupMessage = ({
  message,
  shippingGroups,
  addresses,
  images,
  imageSg,
  addressSg,
  onClick,
  className,
}) => {
  const lineCls = `related-sg-line ${onClick ? 'related-sg-line-interactive' : ''}`;

  return (
    <div className={`related-sg ${className || ''}`}>
      <div className="related-sg-wrap">
        <img
          className="img-icon"
          alt="alert"
          src="/icons/alert_warning.svg"
        />
        <div className="related-sg-message-wrap">
          <div className="related-sg-message">{message}</div>
          <div className="related-sg-line-wrap">
            {shippingGroups.map((sg, index) => (
              <Fragment key={index}>
                {index > 0 ? ' / ' : null}
                <div className={lineCls} onClick={() => onClick(sg)}>{sg}</div>
              </Fragment>
            ))}
          </div>

          {!!addresses?.length && (
            <div className="related-sg-line-wrap">
              {addresses.map((address, index) => (
                <Fragment key={index}>
                  {index > 0 ? ' / ' : null}
                  <div className={lineCls} onClick={() => onClick(addressSg[index])}>{address}</div>
                </Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
      <ImageThumbs onImagePress={(image, index) => onClick(imageSg[index])} qty={8} size={40} value={images} />
    </div>
  )
}

export const TNListRelatedSgippingGroupMessage = ({ shippingGroups, items, itemsInfo }) => {
  const { t } = useTranslation()

  const images = items.map(itemId => {
    const info = itemsInfo[itemId]
    return info?.pixlId ? `https://contents.mediadecathlon.com/p${info.pixlId}/a.jpg?f=60x60` : null
  })

  return (
    <BaseRelatedSgippingGroupMessage
      message={t('orders.relatedClassicOrder', 'Be careful, this Shipping group got related Shipping groups')}
      shippingGroups={shippingGroups}
      images={images}
      className="related-sg-tnList"
    />
  )
}

export const OrderRelatedNoPackMessage = ({ info, onClick }) => {
  const { t } = useTranslation()

  const shippingGroups = info.shipping_groups.filter(x => x.noPack).map(x => x.id)
  let items = []
  const imageSg = {}
  if (Array.isArray(info.items)) {
    items = info.items.filter(x => shippingGroups.includes(x.shipping_group_number))
  }

  const images = items.map((item, index) => {
    imageSg[index] = item.shipping_group_number
    return item.image
  })

  return (
    <BaseRelatedSgippingGroupMessage
      message={t('orders.relatedBoth', 'Be careful, this order contains different Shipping groups')}
      shippingGroups={shippingGroups}
      images={images}
      imageSg={imageSg}
      onClick={onClick}
      className="related-sg-contained"
    />
  )
}

export const TNRelatedSgippingGroupMessage = ({ relatedShippingGroups, onClick, mode }) => {
  const { t } = useTranslation()

  const shippingGroups = []
  const addresses = []
  const images = []
  const imageSg = {}
  const addressSg = {}

  let imageIndex = 0
  let addressIndex = 0
  for (const sg of relatedShippingGroups) {
    shippingGroups.push(sg.shipping_group_number)
    for (const address of sg.addresses || []) {
      addresses.push(address)
      addressSg[addressIndex] = sg.shipping_group_number
      addressIndex += 1
    }
    for (const item of sg.items || []) {
      images.push(item.image)
      imageSg[imageIndex] = sg.shipping_group_number
      imageIndex += 1
    }
  }

  return (
    <BaseRelatedSgippingGroupMessage
      message={t('orders.relatedClassicOrder', 'Be careful, this Shipping group got related Shipping groups')}
      shippingGroups={shippingGroups}
      addresses={addresses}
      images={images}
      imageSg={imageSg}
      addressSg={addressSg}
      onClick={onClick}
      className={mode === 'attached' ? 'related-sg-attached' : 'related-sg-contained'}
    />
  )
}

