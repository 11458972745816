import React, { useState, useMemo, useEffect } from 'react';
import { Badge, Button, Form, ButtonGroup, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import _ from 'lodash';
import dayjs from 'dayjs';

import useRequest from '../../functions/useRequest';
import { logEvent } from '../../functions/analytics';
import ImageWithDetailsModal from '../../components/widgets/ImageWithDetailsModal';
import ImageModal from '../../components/widgets/ImageModal';
import BarcodeModal from '../../components/widgets/BarcodeModal';
import ErrorMessage from '../../components/widgets/errorMessage';
import Confirm from '../../components/widgets/Confirm';

import { finalizeOrder, finalizeTrolley, getShippingLabels, printShippingLabels, removeOrder } from './api';
import { getPrinters } from '../../components/print/api';
import { OrderView, ItemView, Messages, EcomTrolleySummary, TrolleyBoxes, getTrolleyBoxes } from './components';
import { itemToFieldsData, enrichOrderItems, handleFinalizeRes } from './helpers';
import AddressAllDialog from './AddressAllDialog';
import AddressByItemDialog from './AddressByItemDialog';
import SelectPrinterDialog from './SelectPrinterDialog';
import TransporterInfoDialog from './TransporterInfoDialog';
import { usePrintersState } from '../../store/printers';
import { findSelectedPrinter } from '../../components/print/helpers';

import '../../stylesheets/ecomTrolley.css';
import packageOpenImage from '../../images/package-open.png';

const TrolleyMenu = ({ onFinalize }) => {
  return (
    <Dropdown className="ml-1">
      <Dropdown.Toggle />
      <Dropdown.Menu>
        <Dropdown.Item onClick={onFinalize}>
          <Trans i18nKey="trolley.finalize">Finalize trolley</Trans>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const EcomTrolleyContainer = props => {
  const {
    trolley,
    boxes,
    itemInfos,
    isLoading,
    showInfo,
    store,
    packCount,
    pickingListCount,
    user,
    updateTokens,
    onError,
    onRefresh,
    statusResolver,
  } = props;
  const { t } = useTranslation();
  const trolleyInProgress = trolley?.status === 'started' || trolley?.status === 'finalize-pending'
  const canManualFinalize = pickingListCount === 0 && trolleyInProgress;

  const {
    lastPrinter,
    setLastPrinter,
    format: lastFormat,
    setFormat,
    orientation: lastOrientation,
    setOrientation,
    padding: lastPadding,
    setPadding,
  } = usePrintersState();

  const [dialog, setDialog] = useState(null); // null | 'addressAll' | 'addressByItem' | 'transporterInfo' | 'finalize'
  const [view, setView] = useState('orderView'); // 'orderView' | 'itemView'
  const [imageModalData, setImageModalData] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [barcodePreview, setBarcodePreview] = useState(null);
  const [orderIdsToPrint, setOrderIdsToPrint] = useState(null);
  const [selectPrinterShow, setSelectPrinterShow] = useState(false);
  const [failedActions, setFailedActions] = useState([]);
  const [orderToRemove, setOrderToRemove] = useState(null);
  const [{ loading: isRemoving }, doRemoveOrder] = useRequest(removeOrder, {
    onError
  });
  const [{ loading: isFinalizing }, doFinalizeTrolley] = useRequest(finalizeTrolley, {
    onError
  });

  const [{ loading: isFinalizingOrder }, doFinalizeOrder] = useRequest(finalizeOrder, {
    onError
  });

  const onAddressError = (error) => {
    const { status, response } = error;
    if (status === 420 && response.origin) {
      handleDialogClose();
      handleActionFail('address', [selectedOrder.order_id]);
      onError(new Error(error.response.origin.text || error.response.text));
      logEvent('ecom_address_fail', {
        orderId: selectedOrder.order_id,
        email: user?.email,
        code: error.response.origin.code,
        text: error.response.origin.text,
        failedAt: dayjs().toISOString(),
      });
    } else {
      onError(error);
    }
  };

  const onPrintError = (error) => {
    const { status, response, order_ids } = error;
    if (status === 420 && response.origin) {
      handleDialogClose();
      onError(new Error(error.response.origin.text || error.response.text));
      if (order_ids) {
        handleActionFail('print', order_ids);
        order_ids.forEach((order_id) => {
          logEvent('ecom_print_fail', {
            orderId: order_id,
            email: user?.email,
            code: error.response.origin.code,
            text: error.response.origin.text,
            failedAt: dayjs().toISOString(),
          });
        });
      }
    } else {
      onError(error);
    }
  };

  const [{ loading: isLabelsFetching }, fetchShippingLabels] = useRequest(getShippingLabels, {
    onError: onPrintError,
    onSuccess: (result) => {
      onRefresh();
      setFailedActions([]);
      const blobURL = URL.createObjectURL(result);
      window.open(blobURL, '_blank').focus();
    }
  });

  const [{ loading: isLabelsPrinting }, doPrintShippingLabels] = useRequest(printShippingLabels, {
    onError: onPrintError,
    onSuccess: () => {
      onRefresh();
      setFailedActions([]);
    }
  });

  const [{ loading: isPrintersFetching, data: printers }, fetchPrinters] = useRequest(getPrinters, { onError });

  const filePrinterLabel = t('selectPrinter.savePdf', 'Save PDF');
  const selectedPrinter = findSelectedPrinter(printers, lastPrinter, filePrinterLabel, false);
  const emptyLabel = _.capitalize(t('trackingNumbers.trackingNumberTypes.voluminous', 'voluminous'));
  const trolleyBoxes = getTrolleyBoxes({ trolley, boxes, emptyLabel });
  const orders = useMemo(
    () => _.orderBy(
      trolley?.orders.map((x) => enrichOrderItems(x, itemInfos)) || [],
      [(x) => x.finalize_status === 'error'],
      ['desc'],
    ),
    [trolley, itemInfos],
  );
  const canPrint = orders.some((x) => x.can_print);
  const hasFails = failedActions.length > 0;

  useEffect(() => {
    if (store) {
      fetchPrinters({ store, user, updateTokens });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store])

  const handleActionFail = (action, failedOrders) => {
    const newFailedActions = [...failedActions]

    failedOrders.forEach(orderId => {
      const actionIndex = newFailedActions.findIndex(x =>
        orderId ? x.orderId === orderId : x.action === action && !x.orderId,
      )
      if (actionIndex > -1) {
        newFailedActions[actionIndex].failedAt = dayjs().toISOString()
        newFailedActions[actionIndex].count++
      } else {
        newFailedActions.push({
          action,
          orderId,
          failedAt: dayjs().toISOString(),
          count: 1,
        })
      }
    })

    setFailedActions(newFailedActions)
  };

  const handlePrintShippingLabels = async (order_ids, opts) => {
    const { printerId, format, orientation, padding } = opts || {};
    const printer = _.isNil(printerId) ? selectedPrinter?.id : printerId;
    setOrderIdsToPrint(null);
    if (!order_ids || !order_ids.length || !trolley) return;
    if (!printer) {
      setSelectPrinterShow(true);
      setOrderIdsToPrint(order_ids);
    } else if (printer === 'file') {
      await fetchShippingLabels({
        store,
        format: _.isNil(format) ? lastFormat : format,
        orientation: _.isNil(orientation) ? lastOrientation : orientation,
        padding: _.isNil(padding) ? lastPadding : padding,
        order_ids,
        trolley_id: trolley.id,
        user,
        updateTokens,
      });
    } else {
      await doPrintShippingLabels({
        store,
        printer,
        format: _.isNil(format) ? lastFormat : format,
        orientation: _.isNil(orientation) ? lastOrientation : orientation,
        padding: _.isNil(padding) ? lastPadding : padding,
        order_ids,
        trolley_id: trolley.id,
        user,
        updateTokens,
      })
    }
  };

  const handlePrintAllShippingLabels = () => {
    if (!trolley) return;
    const v = _.uniq(trolley.orders.filter((x) => x.can_print).map((x) => x.order_id));
    if (!v.length) return;
    return handlePrintShippingLabels(v);
  };

  const handleSelectPrinter = () => {
    if (!trolley) return;
    const v = _.uniq(trolley.orders.filter((x) => x.can_print).map((x) => x.order_id));
    if (!v.length) return;
    setOrderIdsToPrint(v);
    setSelectPrinterShow(true);
  }

  const handleDialogClose = () => {
    setDialog(null);
    setSelectedOrder(null);
    setBarcodePreview(null);
  };

  const onAddress = (order) => {
    setDialog('addressAll');
    setSelectedOrder(order);
  };

  const onAddressSuccess = (res) => {
    setDialog(null);
    setSelectedOrder(null);
    setFailedActions([]);
    onRefresh();
    if (res?.stock?.address_type === 'locker') {
      setBarcodePreview(res.stock.barcode_preview);
    }
  };

  const onTransporterInfoClick = (order) => {
    setDialog('transporterInfo');
    setSelectedOrder(order);
  };

  const handleRemoveOrder = async () => {
    setOrderToRemove(null);
    const res = await doRemoveOrder({
      store,
      order_id: orderToRemove,
      trolley_id: trolley.id,
      user,
      updateTokens,
    });
    handleFinalizeRes(res, onError, t);
    onRefresh();
  };

  const handleFinalize =  async () => {
    setDialog(null);
    const res = await doFinalizeTrolley({
      store,
      trolley_id: trolley.id,
      user,
      updateTokens,
    });
    handleFinalizeRes(res, onError, t);
    onRefresh();
  }

  const handleRetryFinalizeOrder =  async (orderId) => {
    setDialog(null);
    const res = await doFinalizeOrder({
      store,
      trolley_id: trolley.id,
      order_id: orderId,
      user,
      updateTokens,
    });
    handleFinalizeRes(res, onError, t);
    onRefresh();
  }

  const loading = isLoading || isLabelsFetching || isLabelsPrinting || isRemoving || isFinalizing || isFinalizingOrder;
  const actionsDisabled = loading || trolleyInProgress;

  if (!trolley) return null;

  return (
    <div className="ecom-trolley">
      <Messages trolley={trolley} actionsDisabled={actionsDisabled} />
      <EcomTrolleySummary trolley={trolley} showInfo={showInfo} />
      {hasFails && (
        <ErrorMessage
          error={{
            message: t(
              'ecomPickingList.trolleyContainsFails',
              'Trolley contains orders with failed actions. Please try again.'
            )
          }}
        />
      )}
      <div className="d-flex flex-wrap flex-row justify-content-start align-items-center mb-3">
        <TrolleyBoxes trolleyBoxes={trolleyBoxes} />
        <div className="flex-fill" />
        <div className="d-flex flex-wrap justify-content-start">
          {packCount && packCount > 0 ? (
            <Link className="btn btn-sm icon-button pack-btn ml-0 mr-2" to={`/${store}/ecom-packing`}>
              <span className="pack-btn-label">
                <Trans i18nKey="trolley.toPack">To pack</Trans>
              </span>
              <img className="img-icon ml-1" alt="lbox" src={packageOpenImage} />
              <Badge pill className="pack-btn-badge" variant="yellow">{packCount}</Badge>
            </Link>
          ) : null}
          {canPrint && (
            <ButtonGroup size="sm" className="mr-2">
              <Button
                variant="primary"
                className="mx-0 icon-button print-all"
                onClick={handlePrintAllShippingLabels}
                disabled={isPrintersFetching || actionsDisabled}
                title={actionsDisabled
                  ? t('ecomPickingList.printDisabledMessage', 'You have to finish your current picking list in order to be able to print labels in the trolley.')
                  : t('trolley.printAll', 'Print all shipping labels')
                }
              >
                <i className="vtmn-icon_printer m-0 mr-2" />
                <span className="print-all-label">
                  {selectedPrinter ? selectedPrinter.name : t('selectPrinter.selectPrinter', 'Select printer')}
                </span>
              </Button>
              <Button
                variant="primary"
                className="mx-0 dropdown-toggle dropdown-toggle-split"
                onClick={handleSelectPrinter}
                disabled={isPrintersFetching || actionsDisabled}
                title={actionsDisabled
                  ? t('ecomPickingList.printDisabledMessage', 'You have to finish your current picking list in order to be able to print labels in the trolley.')
                  : t('selectPrinter.selectPrinter', 'Select printer')
                }
              >
              </Button>
            </ButtonGroup>
          )}
          <Form inline>
            <Form.Control
              as="select"
              value={view}
              size="sm"
              onChange={(e) => setView(e.target.value)}
              className="trolley-summary-view-select"
            >
              <option value="orderView">{t('trolley.orderView', 'Order view')}</option>
              <option value="itemView">{t('trolley.itemView', 'Item view')}</option>
            </Form.Control>
          </Form>
          {canManualFinalize && <TrolleyMenu onFinalize={() => setDialog('finalize')} />}
        </div>
      </div>
      {view === 'orderView' && (
        <OrderView
          orders={orders}
          loading={loading}
          boxes={trolleyBoxes}
          failedActions={failedActions}
          setImageModalData={setImageModalData}
          statusResolver={(order) => statusResolver ? statusResolver(order, trolley.status) : null}
          onPrintShippingLabels={handlePrintShippingLabels}
          onAddress={onAddress}
          onRetry={handleRetryFinalizeOrder}
          actionsDisabled={actionsDisabled}
          onRemoveOrder={setOrderToRemove}
          onTransporterInfoClick={onTransporterInfoClick}
          onFinalizeTrolley={() => setDialog('finalize')}
        />
      )}
      {view === 'itemView' && (
        <ItemView
          orders={orders}
          loading={loading}
          boxes={trolleyBoxes}
          failedActions={failedActions}
          setImageModalData={setImageModalData}
          onTransporterInfoClick={onTransporterInfoClick}
        />
      )}
      <ImageWithDetailsModal
        show={!!imageModalData}
        onHide={() => setImageModalData(null)}
        messages={imageModalData?.messages}
        pixlId={imageModalData?.pixlId}
        item={imageModalData?.item}
        item_description={imageModalData?.item_description}
        customerTag={imageModalData?.customer_tag}
        isFirst={imageModalData?.isFirst}
        isLast={imageModalData?.isLast}
        item_is_dangerous={imageModalData?.is_dangerous}
        endOfLife={imageModalData?.end_of_life}
        onLeft={() => {
          if (!imageModalData?.isFirst) {
            const nextIndex = imageModalData.index - 1;
            const nextItem = imageModalData.items[nextIndex];
            setImageModalData({
              ...imageModalData,
              pixlId: nextItem?.item_info?.pixlId,
              item: nextItem?.item_info?.item_id,
              item_description: nextItem?.item_info?.item_description,
              is_dangerous: nextItem?.is_dangerous,
              end_of_life: nextItem?.end_of_life,
              fields: itemToFieldsData(t, nextItem, imageModalData.order),
              index: nextIndex,
              isFirst: nextIndex <= 0,
              isLast: false,
            });
          }
        }}
        onRight={() => {
          if (!imageModalData?.isLast) {
            const nextIndex = imageModalData.index + 1;
            const nextItem = imageModalData.items[nextIndex];
            setImageModalData({
              ...imageModalData,
              pixlId: nextItem?.item_info?.pixlId,
              item: nextItem?.item_info?.item_id,
              item_description: nextItem?.item_info?.item_description,
              is_dangerous: nextItem?.is_dangerous,
              end_of_life: nextItem?.end_of_life,
              fields: itemToFieldsData(t, nextItem, imageModalData.order),
              index: nextIndex,
              isFirst: false,
              isLast: nextIndex >= imageModalData.items.length - 1,
            });
          }
        }}
        fields={imageModalData?.fields || []}
      />
      {dialog === 'addressAll' && (
        <AddressAllDialog
          trolleyId={trolley.id}
          order={selectedOrder}
          show
          onHide={handleDialogClose}
          onAddressByItem={() => setDialog('addressByItem')}
          onSuccess={onAddressSuccess}
          onError={onAddressError}
        />
      )}
      {dialog === 'addressByItem' && (
        <AddressByItemDialog
          trolleyId={trolley.id}
          order={selectedOrder}
          show
          onHide={handleDialogClose}
          onSuccess={onAddressSuccess}
          onError={onAddressError}
        />
      )}
      {dialog === 'transporterInfo' && (
        <TransporterInfoDialog
          order={selectedOrder}
          show
          onHide={handleDialogClose}
        />
      )}
      {dialog === 'finalize' && (
        <Confirm
          show
          onHide={() => setDialog(null)}
          onConfirm={handleFinalize}
          body={<Trans i18nKey="trolley.confirmFinalize">Are you going to finalize trolley?</Trans>}
          buttonOpts={{
            variant: 'primary',
            text: <Trans i18nKey="trolley.finalize">Finalize trolley</Trans>,
          }}
        />
      )}
      {imageModalData && (
        <ImageModal
          pixlId={imageModalData.pixlId}
          item={imageModalData.item}
          item_description={imageModalData.item_description}
        />
      )}
      {barcodePreview && (
        <BarcodeModal show code={barcodePreview} onHide={handleDialogClose} />
      )}
      <SelectPrinterDialog
        printers={printers}
        selectedPrinter={selectedPrinter}
        format={lastFormat}
        orientation={lastOrientation}
        padding={lastPadding}
        show={selectPrinterShow}
        onHide={() => setSelectPrinterShow(false)}
        onApply={({ printer, format, orientation, padding }) => {
          setLastPrinter(printer);
          setFormat(format);
          setOrientation(orientation);
          setPadding(padding);
        }}
        onPrint={({ printer, format, orientation, padding }) => {
          setLastPrinter(printer);
          setFormat(format);
          setOrientation(orientation);
          setPadding(padding);
          setSelectPrinterShow(false);
          const opts = { printerId: printer.id, format, orientation, padding };
          handlePrintShippingLabels(orderIdsToPrint, opts);
        }}
      />
      <Confirm
        show={!!orderToRemove}
        onHide={() => setOrderToRemove(null)}
        onConfirm={handleRemoveOrder}
        icon={<i className="modal-header-icon text-danger vtmn-icon_delete vtmn-icon-22px" />}
        body={<Trans i18nKey="trolley.confirmRemove">Are you going to remove order from trolley?</Trans>}
        buttonOpts={{
          variant: 'danger',
          text: <Trans i18nKey="orders.removeOrder">Remove order</Trans>,
        }}
      />
    </div>
  );
}

export default EcomTrolleyContainer;
