import React from 'react';
import { Trans } from 'react-i18next';

const Tabs = ({
  activeTab,
  setActiveTab,
  ordersEnabled,
  trackingNumbersEnabled,
  itemStorageEnabled,
}) => {
  const isItemsTab = activeTab === "items";
  const isOrdersTab = activeTab === "orders";
  const isTrakingNumbersTab = activeTab === "tracking-numbers";

  return (
    <div
      className="btn-group btn-block"
      role="group"
    >
      {ordersEnabled && (
        <button
          type="button"
          className={`tab-button col m-0 btn ${isOrdersTab ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={()=> setActiveTab("orders")}
        >
          <Trans i18nKey="movementsHistory.ordersTab">Orders</Trans>
        </button>
      )}
      {trackingNumbersEnabled && (
        <button
          type="button"
          className={`tab-button col m-0 btn ${isTrakingNumbersTab ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={()=> setActiveTab("tracking-numbers")}
        >
          <Trans i18nKey="movementsHistory.trakingNumbersTab">No-pack</Trans>
        </button>
      )}
      {itemStorageEnabled && (
        <button
          type="button"
          className={`col m-0 btn ${isItemsTab ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={()=> setActiveTab("items")}
        >
          <Trans i18nKey="movementsHistory.itemsTab">Items</Trans>
        </button>
      )}
    </div>
  );
}

export default Tabs;
