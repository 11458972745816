import React from 'react';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import ProblemRowField from './ProblemRowField';
import MountStatus from '../../OrdersMount/components/MountStatus';


const ProblemDetails = ({ problem }) => {
  const { t } = useTranslation();
  const mountStatus = problem?.order?.mount_status;

  return (
    <div className="d-flex align-items-start flex-column flex-sm-row">
      <div className="d-flex flex-column">
        <img
          className="mb-0 mr-2"
          src={`${problem.item.image}?f=200x200`}
          alt=""
          style={{ maxWidth: '100%', minWidth: '200px' }}
        />
        <MountStatus status={mountStatus} />
      </div>
      <div className="flex-fill">
        {problem.shipping_group && problem.order && (
          <ProblemRowField
            bold
            value={`${problem.order.order_number} - ${problem.shipping_group.shipping_group_number}`}
          />
        )}
        <ProblemRowField
          value={`${problem.item.department_description || '-'} / ${problem.item.item_id} ${problem.item.item_description}`}
        />
        {problem.order && (
          <>
            <ProblemRowField
              label={t('reviewProblems.customer', 'Customer')}
              value={`${problem.order.firstName} ${problem.order.lastName}`}
            />
            <ProblemRowField
              value={problem.order.email}
            />
            <ProblemRowField
              label={t('reviewProblems.phone', 'Phone')}
              value={problem.order.phoneNumber}
            />
            <ProblemRowField
              label={t('reviewProblems.created', 'Created')}
              value={problem.order.creationDate ? dateFormat(problem.order.creationDate, 'dd/mm/yyyy HH:MM:ss') : '-'}
            />
          </>
        )}
        {problem.shipping_group && (
          <ProblemRowField
            label={t('reviewProblems.shipping', 'Promised shipping')}
            value={(
              problem.shipping_group.promisedShippingDate
                ? dateFormat(problem.shipping_group.promisedShippingDate, 'dd/mm/yyyy HH:MM:ss')
                : '-'
            )}
          />
        )}
        {problem.item.hasOwnProperty('stock') && (
          <ProblemRowField
            inline
            label={t('reviewProblems.stock', 'In stock')}
            value={problem.item.stock}
          />
        )}
      </div>
    </div>
  )
}

export default ProblemDetails;
