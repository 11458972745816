import React, { useState } from 'react';
import { Alert, Button, Modal, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

import ClipboardValue from '../../components/widgets/ClipboardValue';
import { normalizeName } from '../../functions/formatText';

const TransporterInfoDialog = ({ order, show, onHide }) => {
  const { t } = useTranslation();

  const [copySuccess, setCopySuccess] = useState(null);

  const itemsWeight = order.items.reduce((acc, curr) => acc + curr.item_info.weight || 0, 0)

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="ecom-transporter-info-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="trolley.transporterInfo">Transporter info</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {copySuccess && <Alert variant="success" show dismissible onClose={() => setCopySuccess(null)}>{copySuccess}</Alert>}
        <ClipboardValue
          label={t('orders.customer', 'Customer')}
          value={order.customer_name ? normalizeName(order.customer_name) : null}
          onSuccess={setCopySuccess}
          successLable
        />
        <ClipboardValue
          label="Email"
          value={order.customer_email}
          onSuccess={setCopySuccess}
          successLable
        />
        <ClipboardValue
          label={t('reviewProblems.phone', 'Phone')}
          value={order.customer_phone}
          onSuccess={setCopySuccess}
          successLable
        />
        <ClipboardValue
          label={t('items.address', 'Address')}
          value={order.customer_address}
          onSuccess={setCopySuccess}
          successLable
        />
        <ClipboardValue
          label={t('shared.city', 'City')}
          value={order.customer_city}
          onSuccess={setCopySuccess}
          successLable
        />
        <Row>
          <ClipboardValue
            label={t('shared.zipCode', 'Zip code')}
            value={order.customer_postcode}
            onSuccess={setCopySuccess}
            successLable
          />
          <ClipboardValue
            label={t('shared.weight', 'Weight')}
            value={itemsWeight}
            onSuccess={setCopySuccess}
            successLable
          />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={onHide}
        >
          <Trans i18nKey="shared.close">Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TransporterInfoDialog;
