import { useReducer, useCallback } from 'react';

const SET_SEARCH_ITEM = 'SET_SEARCH_ITEM';
const SET_MODAL_ERROR = 'SET_MODAL_ERROR';
const SET_ACTION_OPENED = 'SET_ACTION_OPENED';
const SET_MISSING_OPENED = 'SET_MISSING_OPENED';
const SET_PREVIEW_OPENED = 'SET_PREVIEW_OPENED';
const SET_LOCKED_OPTIONS = 'SET_LOCKED_OPTIONS';
const SET_OTHER_TRACKING_OPTIONS = 'SET_OTHER_TRACKING_OPTIONS';
const SET_SELECTED_SGITEM = 'SET_SELECTED_SGITEM';
const PATCH_SELECTED_SGITEM = 'PATCH_SELECTED_SGITEM';
const SET_TRACKING_NUMBER_DATA = 'SET_TRACKING_NUMBER_DATA';
const PATCH_TRACKING_NUMBER_DATA = 'PATCH_TRACKING_NUMBER_DATA';
const SET_UNEXPECTED_MATCHES = 'SET_UNEXPECTED_MATCHES';
const SET_EPC = 'SET_EPC';
const SET_LATEST_ADDRESS = 'SET_LATEST_ADDRESS';

const initialState = {
  searchItem: '',
  modalError: null,
  actionOpened: false,
  fromItemSearch: false,
  missingOpened: false,
  previewOpened: false,
  selectedSGItem: null,
  lockedOptions: [],
  otherTrackingOptions: [],
  trackingNumberData: null,
  unexpectedMatches: null,
  epc: null,
  latestAddress: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_SEARCH_ITEM:
    case SET_MODAL_ERROR:
    case SET_ACTION_OPENED:
    case SET_MISSING_OPENED:
    case SET_PREVIEW_OPENED:
    case SET_LOCKED_OPTIONS:
    case SET_OTHER_TRACKING_OPTIONS:
    case SET_SELECTED_SGITEM:
    case SET_TRACKING_NUMBER_DATA:
    case SET_UNEXPECTED_MATCHES:
    case SET_EPC:
    case SET_LATEST_ADDRESS:
      return {
        ...state,
        ...action.payload,
      }
    case PATCH_SELECTED_SGITEM:
      return {
        ...state,
        selectedSGItem: state.selectedSGItem && {
          ...state.selectedSGItem,
          ...action.payload.patch,
        },
      }
    case PATCH_TRACKING_NUMBER_DATA:
      return {
        ...state,
        trackingNumberData: state.trackingNumberData && {
          ...state.trackingNumberData,
          ...action.payload.patch,
        },
      }
    default:
      return state;
  }
};

const useTrackingNumberState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setSearchItem = useCallback((searchItem) => dispatch({ type: SET_SEARCH_ITEM, payload: { searchItem } }), []);
  const setModalError = useCallback((modalError) => dispatch({ type: SET_MODAL_ERROR, payload: { modalError } }), []);
  const setActionOpened = useCallback((actionOpened, fromItemSearch) => dispatch({ type: SET_ACTION_OPENED, payload: {
    actionOpened,
    fromItemSearch,
  }}), []);
  const setMissingOpened = useCallback((missingOpened) => dispatch({ type: SET_MISSING_OPENED, payload: { missingOpened } }), []);
  const setPreviewOpened = useCallback((previewOpened) => dispatch({ type: SET_PREVIEW_OPENED, payload: { previewOpened } }), []);
  const setLockedOptions = useCallback((lockedOptions) => dispatch({ type: SET_LOCKED_OPTIONS, payload: { lockedOptions } }), []);
  const setOtherTrackingOptions = useCallback((otherTrackingOptions) => dispatch({ type: SET_OTHER_TRACKING_OPTIONS, payload: { otherTrackingOptions } }), []);
  const setSelectedSGItem = useCallback((selectedSGItem) => dispatch({ type: SET_SELECTED_SGITEM, payload: { selectedSGItem } }), []);
  const patchSelectedSGItem = useCallback((patch) => dispatch({ type: PATCH_SELECTED_SGITEM, payload: { patch } }), []);
  const setTrackingNumberData = useCallback((trackingNumberData) => dispatch({ type: SET_TRACKING_NUMBER_DATA, payload: { trackingNumberData } }), []);
  const patchTrackingNumberData = useCallback((patch) => dispatch({ type: PATCH_TRACKING_NUMBER_DATA, payload: { patch } }), []);
  const setUnexpectedMatches = useCallback((unexpectedMatches) => dispatch({ type: SET_UNEXPECTED_MATCHES, payload: { unexpectedMatches } }), []);
  const setEpc = useCallback((epc) => dispatch({ type: SET_EPC, payload: { epc } }), []);
  const setLatestAddress = useCallback((latestAddress) => dispatch({ type: SET_LATEST_ADDRESS, payload: { latestAddress } }), []);

  return {
    state,
    stateActions: {
      setSearchItem,
      setModalError,
      setActionOpened,
      setMissingOpened,
      setPreviewOpened,
      setLockedOptions,
      setOtherTrackingOptions,
      setSelectedSGItem,
      patchSelectedSGItem,
      setTrackingNumberData,
      patchTrackingNumberData,
      setUnexpectedMatches,
      setEpc,
      setLatestAddress,
    },
  };
};

export default useTrackingNumberState;
