import React from 'react';
import CommentsDialog from '../../components/widgets/CommentsDialog';
import { getComments, postComment } from './api';

const TrackingNumberComments = ({ isOpen, onClose, store, trackingNumber, user, updateTokens }) => (
  <CommentsDialog
    isOpen={isOpen}
    onClose={onClose}
    getComments={() => getComments({ store, trackingNumber, user, updateTokens })}
    postComment={({ text }) => postComment({ text, store, trackingNumber, user, updateTokens })}
  />
);

export default TrackingNumberComments;
