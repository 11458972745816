import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../../functions/useDebounce';

const isNil = (val) => val === null || val === undefined;

const NumrangeFilter = ({
  controlId,
  label,
  valueFrom,
  valueTo,
  onChange,
}) => {
  const { t } = useTranslation();
  const [stateValue, setStateValue] = useState([valueFrom, valueTo]);
  const debouncedValue = useDebounce(stateValue, 500);
  useEffect(
    () => {
      if (valueFrom !== debouncedValue[0] || valueTo !== debouncedValue[1]) {
        onChange(debouncedValue[0], debouncedValue[1]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedValue],
  );
  useEffect(
    () => { setStateValue([valueFrom, valueTo]); },
    [valueFrom, valueTo],
  );
  return (
    <div className="filter-item form-group">
      <label className="form-label" htmlFor={controlId}>
        {label}
        {': '}
      </label>
      <div className="filter-number-pare input-group">
        <input
          id={controlId}
          className="form-control form-control-sm"
          placeholder={t('filters.numrangeFilter.from', 'From')}
          type="number"
          value={isNil(stateValue[0]) ? '' : String(stateValue[0])}
          onChange={(event) => setStateValue([event.target.value, stateValue[1]])}
        />
        <input
          className="form-control form-control-sm"
          placeholder={t('filters.numrangeFilter.to', 'To')}
          type="number"
          value={isNil(stateValue[1]) ? '' : String(stateValue[1])}
          onChange={(event) => setStateValue([stateValue[0], event.target.value])}
        />
      </div>
    </div>
  );
};

export default NumrangeFilter
