import React from 'react';
import { PacmanLoader } from 'react-spinners';

const Loader = ({ loading, text }) => (
  <div className="packman-loader">
    {text && <strong className="packman-loader-text">{text}</strong>}
    <PacmanLoader
      color={'#0082C3'}
      loading={loading}
    />
  </div>
)

export default Loader;
