import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Modal, Button, Badge, Alert } from 'react-bootstrap';
import { normalizeName } from '../../functions/formatText';
import NumberField from '../../components/widgets/NumberField';
import Loader from '../../components/Loader';
import SimpleSelect from '../../components/widgets/SimpleSelect';
import MessageBox from '../../components/widgets/MessageBox';

const BoxSelect = ({
  trolley,
  addBox,
  boxNumber,
  setBoxNumber,
  boxNumbersAvailable,
  qty,
}) => {
  const { t } = useTranslation()
  const boxIndex = boxNumber && boxNumbersAvailable.indexOf(boxNumber);
  const isFirst = boxIndex === 0;
  const isLast = boxIndex === boxNumbersAvailable.length - 1;
  const noBoxes = boxNumbersAvailable.length === 0;
  const boxNumbersUsed = (trolley?.orders || []).map(x => x.box_numbers).flat();
  const emptyBoxes = boxNumbersAvailable.filter(num => !boxNumbersUsed.includes(num));
  const canAdd = emptyBoxes.length === 0;
  const message = noBoxes ? t('ecomPickingList.addBoxPutItem', {
    defaultValue: 'Please add a new box to trolley, and put the item into it:',
    count: qty
  }) : t('ecomPickingList.putItemInBox', {
    defaultValue: 'Put the item in box:',
    count: qty
  });

  return (
    <>
      <div>{message}</div>
      <div className="ecom-box-select">
        {!noBoxes && (
          <>
            <Button
              variant="light"
              className="icon-button"
              disabled={isFirst}
              title={t('ecomPickingList.prevBox', 'Previous box')}
              onClick={() => boxIndex !== null && setBoxNumber(boxNumbersAvailable[boxIndex - 1])}
            >
              <i className="vtmn-icon_arrow1_left font-weight-bold text-primary m-0" />
            </Button>
            <Badge className="ecom-pick-confirm-badge" variant="secondary">{`#${boxNumber}`}</Badge>
            <Button
              variant="light"
              className="icon-button"
              disabled={isLast}
              title={t('ecomPickingList.nextBox', 'Next box')}
              onClick={() => boxIndex !== null && setBoxNumber(boxNumbersAvailable[boxIndex + 1])}
            >
              <i className="vtmn-icon_arrow1_right font-weight-bold text-primary m-0" />
            </Button>
          </>
        )}
        {canAdd && (
          <Button
            variant="light"
            className="icon-button"
            title={t('ecomPickingList.addBox', 'Add box')}
            onClick={addBox}
          >
            <i className="vtmn-icon_plus_v2 font-weight-bold text-primary m-0" />
          </Button>
        )}
      </div>
    </>
  );
};

const PackageSelect = ({ packageId, setPackageId, boxes }) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  let options = boxes.map(({ id, name }) => ({ id, label: name }));
  options.push({ id: 'null', label: t('ecomPickingList.noPack', 'None packaging') });
  const boxName = (
    boxes.find(x => x.id === packageId)?.name
    || _.capitalize(t('trackingNumbers.trackingNumberTypes.voluminous', 'voluminous'))
  );
  return (
    <>
      <Badge
        onClick={() => setEditing(true)}
        variant="info"
        className="d-flex align-items-center ml-2"
        style={{ cursor: 'pointer' }}
      >
        <img className="img-icon mr-1" alt="lbox" src="/icons/package_variant_closed.svg" />
        {boxName}
        <i className="ml-1 vtmn-icon_edit" />
      </Badge>
      <MessageBox
        show={editing}
        onHide={() => setEditing(false)}
        icon={<i className="modal-header-icon vtmn-icon_edit" />}
        title={t('ecomPickingList.selectBox', 'Select box')}
        body={(
          <SimpleSelect
            controlId="box-select"
            selected={packageId || 'null'}
            options={options}
            onChange={(x) => setPackageId(x === 'null' ? null : x)}
          />
        )}
      />
    </>
  )
};

const PickConfirmModal = ({
  trolley,
  pickingList,
  recommendedBoxes,
  boxes,
  loading,
  show,
  onHide,
  onConfirm,
}) => {
  const [boxNumber, setBoxNumber] = useState(null);
  const [boxNumbersAvailable, setBoxNumbersAvailable] = useState([]);
  const { t } = useTranslation();
  const trolleyOrder = trolley?.orders.find((x) => x.order_id === pickingList?.order_id);
  const trolleyOrderItems = trolleyOrder ? trolleyOrder.items : [];
  const trolleyBoxPackageId = [...trolleyOrderItems]
    .reverse()
    .find((x) => x.item_id === pickingList?.item_code)?.package_id;
  const recommendedPackageId = trolleyBoxPackageId || recommendedBoxes?.[pickingList?.order_id]?.id || null;
  const [packageId, setPackageId] = useState(recommendedPackageId);
  const [pickQty, setPickQty] = useState(pickingList?.qty);
  useEffect(() => {
    if (show) {
      setPickQty(pickingList?.qty);
      setPackageId(recommendedPackageId);
    }
  }, [pickingList?.qty, recommendedPackageId, show]);

  useEffect(() => {
    if (pickingList?.order_id && trolley && (trolley.status === 'new' || trolley.status === 'started')) {
      const { box_count } = trolley;
      const currentOrder = (trolley?.orders || []).find(x => x.order_id === `${pickingList?.order_id}`);
      const currentOrderBoxNumber = currentOrder && currentOrder.box_numbers && currentOrder.box_numbers[0];
      const occupiedNumbers = (trolley?.orders || [])
        .filter(x => x.order_id !== `${pickingList?.order_id}`)
        .map(x => x.box_numbers)
        .flat();
      const boxNumbers = _.range(1, box_count + 1).filter(num => !occupiedNumbers.includes(num));
      setBoxNumber(currentOrderBoxNumber || boxNumbers[0]);
      setBoxNumbersAvailable(boxNumbers);
    }
  }, [trolley, pickingList?.order_id]);

  const addBox = () => {
    const maxAvail = _.max(boxNumbersAvailable);
    const boxNumbersUsed = (trolley?.orders || []).map(x => x.box_numbers).flat();
    const maxOccupied = _.max(boxNumbersUsed);
    const nextBox = _.max([maxAvail, maxOccupied]) + 1;
    setBoxNumbersAvailable([...boxNumbersAvailable, nextBox]);
    setBoxNumber(nextBox);
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="ecom-pick-confirm-modal"
    >
      <Modal.Header closeButton>
        <i className="modal-header-icon text-primary vtmn-icon_notif_help" />
        <Modal.Title>
          <Trans i18nKey="shared.confirm">Confirm</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center p-1">
        {loading && <Loader />}
        {!loading && (
          <>
            <Alert variant="info" className="mx-2 my-1 p-2 text-left d-flex justify-content-start align-items-center">
              <i className="vtmn-icon_notif_alert mr-2" />
              <div>
                <h5>
                  <Trans i18nKey="ecomPickingList.preferRfid">Please prefer picking with RFID</Trans>
                </h5>
                <div className="text-left">
                  <Trans i18nKey="ecomPickingList.preferRfidTraceability">
                    It will allow traceability for the customer.
                  </Trans>
                  <br />
                  <Trans i18nKey="ecomPickingList.preferRfidRinging">
                    It will also prevent the security gate from ringing.
                  </Trans>
                  <br />
                  <Trans i18nKey="ecomPickingList.preferRfidApp">
                    You can't use RFID on a website. Please use the app
                  </Trans>
                </div>
              </div>
            </Alert>
            {pickingList?.qty > 1 && (
              <Alert variant="info" className="mx-2 my-1 p-2 d-flex justify-content-start align-items-center">
                <i className="vtmn-icon_notif_alert mr-2" />
                <div className="text-left">
                  {t('ecomPickingList.pickQtyConfirmation', 'Be sure you took the correct qty')}
                  {':'}
                  <b className="ml-1">{pickingList?.qty}</b>
                </div>
              </Alert>
            )}
            <NumberField
              value={pickQty}
              minVal={1}
              maxVal={pickingList?.qty}
              onChange={setPickQty}
            />
            <div>
              {pickingList?.location_type === 'store'
                ? t('ecomPickingList.pickStoreConfirmation', {
                  defaultValue: 'You are picking {{count}} item from the store',
                  count: pickQty,
                })
                : t('ecomPickingList.pickConfirmation', {
                  defaultValue: 'You are picking {{count}} item from address {{address}}',
                  count: pickQty,
                  address: pickingList?.address,
                })
              }
            </div>
            <BoxSelect
              trolley={trolley}
              boxNumber={boxNumber}
              setBoxNumber={setBoxNumber}
              boxNumbersAvailable={boxNumbersAvailable}
              addBox={addBox}
              qty={pickingList?.qty}
            />
            {pickingList && (
              <div className="d-flex justify-content-center align-items-center row pt-2">
                {pickingList.customer_tag ? (
                  <Badge className="mr-1" variant="secondary">{pickingList.customer_tag}</Badge>
                ) : null}
                {normalizeName(pickingList.customer_name)}
              </div>
            )}
            <div className='d-flex justify-content-center align-items-center row pb-2'>
              <Trans i18nKey="ecomPickingList.recommendedBox">Suggested box:</Trans>
              <PackageSelect
                packageId={packageId}
                setPackageId={setPackageId}
                boxes={boxes}
              />
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
        >
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="primary"
          onClick={() => onConfirm(boxNumber, packageId, pickQty)}
          disabled={loading}
        >
          <Trans i18nKey="shared.pick">Pick</Trans>
          {pickQty && ` (${pickQty})`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PickConfirmModal;
