import React from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

const InlineFilterTag = ({ value, text, count, checked, onChange }) => (
  <ButtonGroup size="sm" toggle className="ml-1 mr-1 mt-2">
    <ToggleButton
      key={value}
      className={`ecom-tag-filter icon-button ${checked ? 'font-weight-bold' : '' }` }
      checked={checked}
      type="checkbox"
      variant="outline-secondary"
      value={value}
      onChange={onChange}
    >
      {checked && <i className="vtmn-icon_tiny_bold_valid" />}
      <span title={text} className="ecom-tag-filter-text text-truncate">{text}</span>
      {': '}
      {count}
    </ToggleButton>
  </ButtonGroup>
);

export default InlineFilterTag;
