import React, { useState, useEffect } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import fetchWithJWT from '../functions/fetchWithJWT';
import handleApiResponse from '../functions/handleApiResponse';
import useDebounce from '../functions/useDebounce';
import useRequest from '../functions/useRequest';
import { getCountryLabel } from '../functions/countries';

import Loader from '../components/Loader';
import '../slider.css';
import blankFlag from "../stylesheets/blankFlag.gif";

const serverUrl = process.env.REACT_APP_SERVERURL;


const getStores = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  return fetchWithJWT(`${serverUrl}/v1/stores/query/all_stores`, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
    .then(handleApiResponse)
}

const getViewers = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const reserve_id = localStorage.getItem("reserve_id");
  return fetchWithJWT(`${serverUrl}/v1/viewers/${reserve_id}`, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
    .then(handleApiResponse)
}

const getCurrentStore = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const reserve_id = localStorage.getItem("reserve_id");
  return fetchWithJWT(`${serverUrl}/v1/stores/${reserve_id}`, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
    .then(handleApiResponse)
}

const updateViewers = (auth_countries, auth_stores, user, updateTokens) => {
  const reserve_id = localStorage.getItem("reserve_id");
  const { token, refreshToken, tokenExpireDate } = user;
  const body = JSON.stringify({
    auth_countries,
    auth_stores
  });
  return fetchWithJWT(`${serverUrl}/v1/viewers/${reserve_id}`, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body
  })
    .then(handleApiResponse);
}

const Viewers = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const [stores, setStores] = useState([]);
  const [isInitAuth, setIsInitAuth] = useState(true);
  const [currentCountry, setCurrentCountry] = useState(null);
  const [isExpended, setExpended] = useState({});
  const [countryAuth, setCountryAuth] = useState([]);
  const [storesAuth, setStoresAuth] = useState([]);
  const debouncedCountry = useDebounce(countryAuth, 500);
  const debouncedStores = useDebounce(storesAuth, 500);

  const [{
    loading: storesLoading,
  }, fetchStores] = useRequest(getStores, {
    onSuccess: (result) => {
      let initExpended = {};
      result.forEach(country => {
        initExpended[country.country_code] = false
      });
      setStores(result);
      setExpended(initExpended);
    }
  });

  const [{
    loading: viewersLoading,
  }, fetchViewers] = useRequest(getViewers, {
    onSuccess: ({ viewers }) => {
      setCountryAuth(viewers.auth_countries)
      setStoresAuth(viewers.auth_stores)
    }
  });

  const [{
    loading: currentStoreLoading,
  }, fetchCurrentStore] = useRequest(getCurrentStore, {
    onSuccess: (store) => {
      setCurrentCountry(store.text.country_code)
    }
  });


  useEffect(() => {
    fetchStores(user, updateTokens);
    fetchViewers(user, updateTokens);
    fetchCurrentStore(user, updateTokens);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInitAuth) {
      updateViewers(debouncedCountry, debouncedStores, user, updateTokens)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCountry, debouncedStores]);

  const toggle = (id) => {
    setExpended({ ...isExpended, [id]: !isExpended[id] })
  };

  const sortCountries = (x, y) => x.country_code === currentCountry ? -1 : y.country_code === currentCountry ? 1 : 0

  const handleClickCountry = (country_info) => {
    const { country_code, store_info } = country_info
    if (isInitAuth) {
      setIsInitAuth(false)
    }
    if (countryAuth.includes(country_code)) {
      setCountryAuth((current) => current.filter((country) => country_code !== country))
    } else {
      setCountryAuth(current => [...current, country_code]);
    }
    let storeToAdd = storesAuth;
    store_info.forEach(store => {
      if (storeToAdd.includes(store.store_number)) {
        storeToAdd.splice(storeToAdd.indexOf(store.store_number), 1);
      }
    })
    setStoresAuth(storeToAdd);
  }

  const handleClickStore = (country_info, store_number) => {
    const { country_code, store_info } = country_info
    if (isInitAuth) {
      setIsInitAuth(false)
    }
    if (countryAuth.includes(country_code)) {
      setCountryAuth((current) => current.filter((country) => country_code !== country))
      let storeToAdd = []
      store_info.forEach(store => {
        if (store.store_number !== store_number) {
          storeToAdd.push(store.store_number)
        }
      })
      setStoresAuth(previous => [...previous.filter((store) => store_number !== store), ...storeToAdd]);
    } else {
      if (storesAuth.includes(store_number)) {
        setStoresAuth((current) => current.filter((store) => store_number !== store))
      } else {
        setStoresAuth(previous => [...previous, store_number]);
      }
    }
  }

  const loading = storesLoading || viewersLoading || currentStoreLoading;
  return (
    <div>
      {loading ? (
        <div className="align-self-center">
          <Loader />
        </div>
      ) : stores.sort(sortCountries).map((country, i) => {
        const countryId = country.country_code
        const flagClass = `flag flag-${countryId.toLowerCase()}`
        let title = getCountryLabel(t, countryId) || countryId;
        if (!countryAuth.includes(countryId)) {
          let countStores = 0
          country.store_info.forEach(elem => {
            if (storesAuth.includes(elem.store_number)) countStores++
          })
          if (countStores > 0) title = `${title} (${countStores})`
        }
        return (
          <div key={countryId}>
            <ul className="list-group">
              <li className="list-group-item">
                <div className="d-flex flex-row justify-content-between" >
                  <div className="country-flag-wrapper">
                    <input id={countryId} value={countryId} type="checkbox" checked={countryAuth.includes(countryId)} onChange={() => handleClickCountry(stores[i])} />
                    <label className="form-check-label row" htmlFor={countryId}>
                      <div className="country-flag mx-1">
                        <img src={blankFlag} className={flagClass} alt={countryId} />
                      </div>
                      <div>{title}</div>
                    </label>
                  </div>
                  <Button id={'toggle-' + countryId} className="align-self-end justify-self-end" onClick={() => { toggle(countryId) }} variant="primary">
                    <i className={isExpended[countryId] ? "vtmn-icon_arrow2_up" : "vtmn-icon_arrow2_down"} />
                  </Button>
                </div>
                {isExpended[countryId] && (
                  <ul className="list-group">
                    {country.store_info.map((store, y) =>
                      <div className="form-check ml-3" key={store.id}>
                        <input
                          id={store.id}
                          value={store.store_number}
                          className="form-check-input"
                          type="checkbox"
                          checked={countryAuth.includes(countryId) || storesAuth.includes(store.store_number)}
                          onChange={() => handleClickStore(stores[i], store.store_number)}
                        />
                        <label className="form-check-label" htmlFor={store.id}>
                          {store.store_number + '-' + store.description}
                        </label>
                      </div>)}
                  </ul>
                )}
              </li>
            </ul>
          </div>
        )
      })}
    </div>
  )
}

export default withTranslation()(Viewers);
