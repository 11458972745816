import { useCallback } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

const initialState = {
  id: null,
  store_number: null,
  ean: null,
  description: "",
  country_code: "",
  last_resupply_email: null,
  last_resupply_date: null,
  view_only_by_default: false,
  check_stock_picture: false,
  stock_orders_enabled: false,
  item_storage_enabled: false,
  tracking_numbers_enabled: false,
  free_orders_enabled: false,
  selfpickup_enabled: false,
  lockers_enabled: false,
  ecom_orders_enabled: false,
  to_mount_enabled: false,
  admin_catalog_enabled: false,
  auto_supply_enabled: false,
  viewer_right: false,
  orders_api: null,
  max_item_weight: null,
  max_item_volume: null,
  max_item_size: null,
};

export const storeSlice = createSlice({
  name: 'storeInfo',
  initialState,
  reducers: {
    setStoreAction: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetAction: () => {
      return initialState;
    },
  },
});

export const { setStoreAction, resetAction } = storeSlice.actions;

export const useStoreInfoReduxState = () => {
  const store = useSelector((state) => state.storeInfo);
  const dispatch = useDispatch();
  const setStore = useCallback((x) => dispatch(setStoreAction(x)), [dispatch]);
  const resetStore = useCallback(() => dispatch(resetAction()), [dispatch]);

  return {
    store,
    setStore,
    resetStore,
  };
}

export default storeSlice.reducer;
