import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import Loader from '../../components/Loader';
import Select from '../../components/widgets/Select';

const EditAddressTypeModal = ({ show, onHide, selectedAddresses, allowedTypes, loading, onSave }) => {
  const { t } = useTranslation();
  const [addressTepe, setAddressTepe] = useState(null);
  useEffect(() => {
    if (show) {
      setAddressTepe(null);
    }
  }, [show]);
  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <i className="modal-header-icon text-primary vtmn-icon_edit" />
        <Modal.Title>
          <Trans i18nKey="adminAddreses.editTypes">Edit types of addresses</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <Loader/>}
        {!loading && (
          <>
            <div>
              {t('adminAddreses.editTypesSelected', {
                defaultValue: 'You are editing {{count}} selected addresses',
                count: selectedAddresses.length,
              })}
            </div>
            <Select
              controlId="addressType"
              placeholder={t('adminAddreses.selectType', 'Select type')}
              options={allowedTypes}
              selected={addressTepe}
              onChange={(val) => setAddressTepe(val)}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
        >
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="primary"
          onClick={() => onSave(addressTepe)}
        >
          <Trans i18nKey="shared.save">Save</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditAddressTypeModal;
