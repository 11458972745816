import React from 'react';
import { Trans } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';

const MessageBox = ({ show, onHide, icon, title, body }) => (
  <Modal
    show={show}
    onHide={onHide}
  >
    <Modal.Header closeButton>
      {icon || (
        <i className="modal-header-icon text-primary vtmn-icon_notif_information" />
      )}
      <Modal.Title>
        {title || <Trans i18nKey="shared.confirm">Confirm</Trans>}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {body}
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="primary"
        onClick={onHide}
      >
        <Trans i18nKey="shared.ok">OK</Trans>
      </Button>
    </Modal.Footer>
  </Modal>
);

export default MessageBox;
