import React from 'react';
import { Trans } from 'react-i18next';
import { Modal, Button, InputGroup, Form } from 'react-bootstrap';

const EditBoxSizeModal = ({ show, box, updateBox, onHide, onConfirm }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <i className="modal-header-icon text-primary vtmn-icon_edit" />
        <Modal.Title>
          <Trans i18nKey="adminAddreses.editBox">Edit box</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <Trans i18nKey="adminAddreses.enterBoxSizeName">Enter box size name</Trans>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="text"
            className="m-0"
            value={box.name}
            onChange={(event) => updateBox({ name: event.target.value })}
          />
        </InputGroup>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <Trans i18nKey="adminAddreses.boxSizHeight">Height (cm)</Trans>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="text"
            className="m-0"
            value={box.height}
            onChange={(event) => updateBox({ height: event.target.value })}
          />
        </InputGroup>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <Trans i18nKey="adminAddreses.boxSizeWidth">Width (cm)</Trans>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="text"
            className="m-0"
            value={box.width}
            onChange={(event) => updateBox({ width: event.target.value })}
          />
        </InputGroup>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <Trans i18nKey="adminAddreses.boxSizeLength">Length (cm)</Trans>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="text"
            className="m-0"
            value={box.length}
            onChange={(event) => updateBox({ length: event.target.value })}
          />
        </InputGroup>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <Trans i18nKey="adminAddreses.boxSizeWeight">Weight (kg)</Trans>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="text"
            className="m-0"
            value={box.weight}
            onChange={(event) => updateBox({ weight: event.target.value })}
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
        >
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="primary"
          onClick={onConfirm}
        >
          <Trans i18nKey="shared.save">Save</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditBoxSizeModal;
