import { Button, Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getFromatOptions, getOrientationOptions, getPrinter } from '../../components/print/helpers';
import SimpleSelect from '../../components/widgets/SimpleSelect';
import InputNumber from '../../components/widgets/InputNumber';

const SelectPrinterDialog = ({
  printers,
  selectedPrinter,
  format,
  orientation,
  padding,
  show,
  onHide,
  onApply,
  onPrint,
}) => {
  const { t } = useTranslation();
  const options = (printers || []).map(({ id, name }) => ({ id, label: name }));
  const filePrinterLabel = t('selectPrinter.savePdf', 'Save PDF');
  options.push({
    id: 'file',
    label: filePrinterLabel,
  });
  const defaultPrinter = selectedPrinter?.id || options[0].id;
  const [selectedPrinterId, setSelectedPrinterId] = useState(defaultPrinter);
  const [selectedFormat, setSelectedFormat] = useState(format);
  const [selectedOrientation, setSelectedOrientation] = useState(orientation);
  const [selectedPadding, setSelectedPadding] = useState(padding);

  useEffect(() => {
    setSelectedPrinterId(defaultPrinter);
  }, [defaultPrinter]);
  useEffect(() => {
    setSelectedFormat(format);
  }, [format]);
  useEffect(() => {
    setSelectedPadding(padding);
  }, [padding]);


  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <i className="modal-header-icon text-primary vtmn-icon_printer" />
        <Modal.Title>
          <Trans i18nKey="selectPrinter.selectPrinter">Select printer</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SimpleSelect
          label={t('selectPrinter.selectPrinter', 'Select printer')}
          controlId="printer"
          options={options}
          selected={selectedPrinterId}
          onChange={(val) => setSelectedPrinterId(val)}
        />
        <SimpleSelect
          label={t('selectPrinter.pageFormat', 'Page format')}
          controlId="format"
          options={getFromatOptions(t, true)}
          selected={selectedFormat}
          onChange={(val) => setSelectedFormat(val)}
        />
        <SimpleSelect
          label={t('selectPrinter.orientation', 'Orientation')}
          controlId="orientation"
          options={getOrientationOptions(t)}
          selected={selectedOrientation}
          onChange={(val) => setSelectedOrientation(val)}
        />
        <InputNumber
          label={t('selectPrinter.printMargin', 'Print margin (cm)')}
          controlId="padding"
          value={selectedPadding}
          min={0}
          max={50}
          step={0.1}
          onChange={setSelectedPadding}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
        >
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="primary"
          disabled={(
            (
              selectedPrinter?.id === selectedPrinterId
              && format === selectedFormat
              && padding === selectedPadding
              && orientation === selectedOrientation
            )
            && !!selectedPrinter?.id
          )}
          onClick={() => {
            const printer = getPrinter(printers, selectedPrinterId, filePrinterLabel);
            onApply({
              printer,
              format: selectedFormat,
              orientation: selectedOrientation,
              padding: selectedPadding,
            });
          }}
        >
          <Trans i18nKey="filters.apply">Apply</Trans>
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            const printer = getPrinter(printers, selectedPrinterId, filePrinterLabel);
            onPrint({
              printer,
              format: selectedFormat,
              orientation: selectedOrientation,
              padding: selectedPadding,
            });
          }}
        >
          <Trans i18nKey="printLabel.print">Print</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SelectPrinterDialog;
