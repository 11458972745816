import React, { useState, useEffect, useRef } from 'react';
import useDebounce from '../../../functions/useDebounce';

const InlineInputFilter = ({
  placeholder,
  onChange,
  width,
}) => {
  const [stateValue, setStateValue] = useState('');
  const debouncedValue = useDebounce(stateValue, 500);
  const isMountedRef = useRef(false);
  useEffect(() => () => {
    isMountedRef.current = false;
  }, []);
  useEffect(
    () => {
      if (isMountedRef.current) {
        onChange(debouncedValue);
      } else {
        isMountedRef.current = true;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedValue],
  );
  return (
    <input
      className="form-control form-control-sm d-inline-block"
      style={{ width: width ? width : 'auto'}}
      type="text"
      placeholder={placeholder}
      onChange={(event) => { setStateValue(event.target.value); }}
    />
  );
};

export default InlineInputFilter;
