import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'react-bootstrap';

import '../../stylesheets/itemListGroup.css';

const ItemListGroup = ({ name, count, children }) => {
  const { t } = useTranslation();
  return (
    <div key={`group_${name}`} className="item-list-group">
      <div className="item-list-group-header">
        <span className="item-list-group-title">
          {name === 'undefined' ? t('shared.noGroup', 'No group').toUpperCase() : name}
        </span>
        {count && (
          <Badge pill variant="yellow" className="ml-2 mb-1">
            {count}
          </Badge>
        )}
      </div>
      <div className="item-list-group-items">{children}</div>
    </div>
  )
};

export default ItemListGroup;
