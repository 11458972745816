export const getCountryLabel = (t, countryId) => ({
  'AE': t('country.AE', 'United Arab Emirates'),
  'AT': t('country.AT', 'Austria'),
  'AU': t('country.AU', 'Australia'),
  'BE': t('country.BE', 'Belgium'),
  'BG': t('country.BG', 'Bulgaria'),
  'CA': t('country.CA', 'Canada'),
  'CD': t('country.CD', 'Republic of the Congo'),
  'CH': t('country.CH', 'Switzerland'),
  'CI': t('country.CI', 'Ivory Coast'),
  'CL': t('country.CL', 'Chile'),
  'CO': t('country.CO', 'Colombia'),
  'CZ': t('country.CZ', 'Czechia'),
  'DE': t('country.DE', 'Germany'),
  'DZ': t('country.DZ', 'Algeria'),
  'EG': t('country.EG', 'Egypt'),
  'ES': t('country.ES', 'Spain'),
  'FR': t('country.FR', 'France'),
  'GB': t('country.GB', 'Great Britain'),
  'GH': t('country.GH', 'Ghana'),
  'HK': t('country.HK', 'Hong Kong'),
  'HU': t('country.HU', 'Hungary'),
  'ID': t('country.ID', 'Indonesia'),
  'IE': t('country.IE', 'Ireland'),
  'IL': t('country.IL', 'Israel'),
  'IN': t('country.IN', 'India'),
  'IT': t('country.IT', 'Italy'),
  'KE': t('country.KE', 'Kenya'),
  'KH': t('country.KH', 'Cambodia'),
  'KR': t('country.KR', 'Korea'),
  'KZ': t('country.KZ', 'Kazakhstan'),
  'LB': t('country.LB', 'Lebanon'),
  'LV': t('country.LV', 'Latvia'),
  'MA': t('country.MA', 'Morocco'),
  'MT': t('country.MT', 'Malta'),
  'MU': t('country.MU', 'Mauritius'),
  'MX': t('country.MX', 'Mexico'),
  'MY': t('country.MY', 'Malaysia'),
  'NC': t('country.NC', 'New Caledonia'),
  'NL': t('country.NL', 'Netherlands'),
  'OM': t('country.OM', 'Oman'),
  'PA': t('country.PA', 'Panama'),
  'PF': t('country.PF', 'French Polynesia'),
  'PH': t('country.PH', 'Philippines'),
  'PL': t('country.PL', 'Poland'),
  'PT': t('country.PT', 'Portugal'),
  'QA': t('country.QA', 'Qatar'),
  'RO': t('country.RO', 'Romania'),
  'RS': t('country.RS', 'Serbia'),
  'SA': t('country.SA', 'Saudi Arabia'),
  'SE': t('country.SE', 'Sweden'),
  'SG': t('country.SG', 'Singapore'),
  'SK': t('country.SK', 'Slovakia'),
  'SN': t('country.SN', 'Senegal'),
  'TH': t('country.TH', 'Thailand'),
  'TN': t('country.TN', 'Tunisia'),
  'TW': t('country.TW', 'Taiwan'),
  'UA': t('country.UA', 'Ukraine'),
  'UY': t('country.UY', 'Uruguay'),
  'VN': t('country.VN', 'Vietnam'),
})[countryId];

export default getCountryLabel;
