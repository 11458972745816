import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Spinner, Form, Button, Row, Col } from 'react-bootstrap';

import WithSidebar from '../WithSidebar';
import useRequest from '../../functions/useRequest';

import '../../stylesheets/release.css';


import { getReleaseQuery, getTranslationsQuery } from './api';

import ReleaseBaseView from './ReleaseBaseView';
import ReleasePreview from './ReleasePreview';
import ReleaseChangelogView from './ReleaseChangelogView';
import ReleasePublishButton from './ReleasePublishButton';
import ReleaseEditDialog from './ReleaseEditDialog';
import ReleaseTranslationsEditDialog from './ReleaseTranslationsEditDialog';
import ReleaseRemoveConfirmDialog from './ReleaseRemoveConfirmDialog';
import ReleasePublishChangeConfirmDialog from './ReleasePublishChangeConfirmDialog';
import ReleaseChangelogAddDialog from './ReleaseChangelogAddDialog';
import ReleaseChangelogEditDialog from './ReleaseChangelogEditDialog';
import ReleaseChangelogRemoveConfirmDialog from './ReleaseChangelogRemoveConfirmDialog';
import ReleaseChangelogTranslationsEditDialog from './ReleaseChangelogTranslationsEditDialog';

const Release = (props) => {
  const { user, updateTokens, logOut } = props;
  const { id } = props.match.params;
  const { t } = useTranslation();
  const history = useHistory();

  const [release, setRelease] = useState(null);
  const [translations, setTranslations] = useState({ release: [], changelog: [] });

  const [{ loading, error: releaseError }, fetchRelease] = useRequest(getReleaseQuery, {
    onSuccess: setRelease,
  });

  const [{ error: translationsError }, fetchTranslations] = useRequest(getTranslationsQuery, {
    onSuccess: setTranslations,
  });

  const errors = [releaseError, translationsError];

  const [dialog, setDialog] = useState(null); // null | 'edit' | 'editTranslations' | 'removeConfirm' | 'publishConfirm' | 'addChangelog' | 'editChangelog' | 'removeChangelogConfirm' | 'editChangelogTranslations'
  const [dialogValue, setDialogValue] = useState(null); // null | Changelog object

  const updateRelease = () => {
    fetchRelease({ id, user, updateTokens }); // Fetch on English, translation separately
    fetchTranslations({ id, user, updateTokens });
  }

  const handleDialogClose = () => {
    setDialog(null);
    setDialogValue(null);
  };

  const handleDialogChange = (type, value) => {
    setDialog(type || null);
    setDialogValue(value || null);
  };

  const handleDialogActionDone = () => {
    updateRelease();
    handleDialogChange(null);
  };

  const onEdit = () => handleDialogChange('edit');
  const onEditTranslations = () => handleDialogChange('editTranslations');
  const onPublish = () => handleDialogChange('publishConfirm');
  const onRemove = () => handleDialogChange('removeConfirm');
  const onAddChangelog = () => handleDialogChange('addChangelog');
  const onEditChangelog = (v) => handleDialogChange('editChangelog', v);
  const onEditChangelogTranslations = (v) => handleDialogChange('editChangelogTranslations', v);
  const onRemoveChangelog = (v) => handleDialogChange('removeChangelogConfirm', v);
  const onReturnClick = () => history.push('/releases');

  const handleRemoveDone = () => onReturnClick();

  useEffect(() => {
    updateRelease();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id]);

  return (
    <WithSidebar user={user} updateTokens={updateTokens} logOut={logOut}>
      <div className = "jumbotron container" style={{ position:"relative" }}>
        <h2 className="text-center">
          {`${t('releases.release', 'Release')}${release ? ` ${release.version} (${release.version_code})` : ''}`}
        </h2>
        {loading && !!release && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
          />
        )}
        {!loading && errors.length > 0 && errors.map((x, index) => (
          <Form.Control.Feedback type="invalid" key={index}>
            {(x && x.message) || t('shared.error', 'Error')}
          </Form.Control.Feedback>
        ))}
        <Row className="release-header">
          <Button variant="outline-primary" size="sm" onClick={onReturnClick} className="icon-button">
            <i className="vtmn-icon_arrow1_left" />
            {t('shared.return', 'Return')}
          </Button>
        </Row>
        <Row className="release-body mh-spacer">
          {loading && !release ? (
              <Spinner
                as="span"
                animation="border"
                variant="primary"
                className="release-loader"
              />
            ) : !!release ? (
              <>
                <Col xs={12} lg={3}>
                  <ReleasePreview release={release} autoWidth />
                  <ReleaseBaseView
                    release={release}
                    translationsCount={translations.release.length}
                    onEdit={onEdit}
                    onEditTranslations={onEditTranslations}
                  />
                </Col>
                <Col xs={12} lg={9}>
                  <ReleaseChangelogView
                    changelog={release.changes || []}
                    translations={translations.changelog || []}
                    onAdd={onAddChangelog}
                    onEdit={onEditChangelog}
                    onEditTranslations={onEditChangelogTranslations}
                  />
                </Col>
              </>
            ) : null}
        </Row>
        {!!release && (
          <>
            <Row className="release-footer justify-content-center">
              <ReleasePublishButton published={release.published} size="sm" onClick={onPublish} />
              <Button
                variant="danger"
                size="sm"
                onClick={onRemove}
                className="icon-button"
              >
                <i className="vtmn-icon_delete" />
                {t('shared.remove', 'Remove')}
              </Button>
            </Row>
            {dialog === 'edit' && (
              <ReleaseEditDialog
                show
                release={release}
                onHide={handleDialogClose}
                onDone={handleDialogActionDone}
              />
            )}
            {dialog === 'editTranslations' && (
              <ReleaseTranslationsEditDialog
                show
                release={release}
                translations={translations.release}
                onHide={handleDialogClose}
                onUpdate={updateRelease}
                onDone={handleDialogActionDone}
              />
            )}
            {dialog === 'removeConfirm' && (
              <ReleaseRemoveConfirmDialog
                show
                release={release}
                onHide={handleDialogClose}
                onDone={handleRemoveDone}
              />
            )}
            {dialog === 'publishConfirm' && (
              <ReleasePublishChangeConfirmDialog
                show
                release={release}
                onHide={handleDialogClose}
                onDone={handleDialogActionDone}
              />
            )}
            {dialog === 'addChangelog' && (
              <ReleaseChangelogAddDialog
                show
                release={release}
                onDone={handleDialogActionDone}
                onHide={handleDialogClose}
              />
            )}
            {!!dialogValue && (
              <>
                {dialog === 'editChangelog' && (
                  <ReleaseChangelogEditDialog
                    show
                    release={release}
                    changelog={dialogValue}
                    onHide={handleDialogClose}
                    onRemove={onRemoveChangelog}
                    onDone={handleDialogActionDone}
                  />
                )}
                {dialog === 'removeChangelogConfirm' && (
                  <ReleaseChangelogRemoveConfirmDialog
                    show
                    release={release}
                    changelog={dialogValue}
                    onHide={handleDialogClose}
                    onDone={handleDialogActionDone}
                  />
                )}
                {dialog === 'editChangelogTranslations' && (
                  <ReleaseChangelogTranslationsEditDialog
                    show
                    release={release}
                    changelog={dialogValue}
                    translations={translations.changelog}
                    onHide={handleDialogClose}
                    onUpdate={updateRelease}
                    onDone={handleDialogActionDone}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </WithSidebar>
  );
};

export default Release;
