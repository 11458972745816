import React from 'react';
import { useTranslation } from 'react-i18next';
import SearchSelectFilter from './SearchSelectFilter';
import { handleApiResponse } from '../../../functions/handleApiResponse';
import fetchWithJWT from '../../../functions/fetchWithJWT';

const getAddresses = (store, user, updateTokens) => (query) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/addresses/query/${query}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const AddressFilter = ({
  user,
  updateTokens,
  store,
  controlId,
  label,
  value,
  onChange
}) => {
  const { t } = useTranslation();
  return (
    <SearchSelectFilter
      controlId={controlId}
      label={label}
      labelKey="address"
      fetcher={getAddresses(store, user, updateTokens)}
      selected={value ? [{ address: value }] : []}
      onChange={(address) => onChange(address && address.address)}
      placeholder={t('filters.itemFilter.enterAddress', 'Enter an address...')}
    />
  );
};

export default AddressFilter;
