import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import SelectToggleButton from '../../components/widgets/SelectToggleButton';

const ReportFilter = ({
  controlId,
  options,
  selected,
  onChange,
}) => {
  const { t } = useTranslation();
  const selectedItems = (selected && options.filter(val => (
    String(val) === String(selected)
    || String(val?.id) === String(selected)
  ))) || [];
  const hasValue = selectedItems && selectedItems.length;

  return (
    <div className="report-filter-select">
      <Typeahead
        placeholder={t('report.orders.filter', 'filter...')}
        size="sm"
        id={controlId}
        options={options}
        selected={selectedItems}
        clearButton
        filterBy={() => true}
        onChange={([opt]) => onChange(opt)}
      >
        {({ isMenuShown, toggleMenu }) => (
          !hasValue && (
            <SelectToggleButton
              isOpen={isMenuShown}
              onClick={e => {
                toggleMenu();
                e.stopPropagation();
                e.preventDefault();
                return false;
              }}
            />
          )
        )}
      </Typeahead>
    </div>
  );
}

export default ReportFilter;
