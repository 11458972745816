import React from 'react';
import { Carousel, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

import '../../stylesheets/releasePreview.css';
import loudspeakerSvg from '../../images/loudspeaker.svg';
import defaultImage from '../../images/default_image.png';

const ReleasePreviewItem = ({ title, description, image, imageClassName }) => {
  return (
    <div className="releasePreview_item">
      <div className="releasePreview_item_image">
        <img className={imageClassName} src={image} alt="" />
      </div>
      <div className="releasePreview_item_content">
        <div className="releasePreview_item_title">
          {title}
        </div>
        {!!description && (
          <div className="releasePreview_item_description">
            <Markdown remarkPlugins={[remarkBreaks]}>
              {description}
            </Markdown>
          </div>
        )}
      </div>
    </div>
  );
};

const ReleasePreview = (props) => {
  const { release, autoWidth, noIntro } = props;
  const { t } = useTranslation();

  if (!release) return null;

  const changes = release.changes || [];

  return (
    <Card className={`releasePreview${autoWidth ? ' w-100' : ''}`}>
      <Card.Body className="releasePreview_body">
        <Carousel interval={null}>
          {!noIntro && (
            <Carousel.Item key="intro">
              <ReleasePreviewItem
                image={loudspeakerSvg}
                title={t('releases.releaseValue', {
                  value: release.version,
                  defaultValue: 'Release {{value}}',
                }).toUpperCase()}
                description={release.summary}
                imageClassName="icon"
              />
            </Carousel.Item>
          )}
          {changes.map((x, index) => (
            <Carousel.Item key={index}>
              <ReleasePreviewItem
                image={x.image_url || defaultImage}
                title={x.title}
                description={x.description}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Card.Body>
    </Card>
  );
};

export default ReleasePreview;
export { ReleasePreviewItem };
