import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import dateFormat from 'dateformat';
import { useTranslation, Trans } from 'react-i18next';
import DaterangeFilter from '../../../components/widgets/filters/DaterangeFilter';
import NumrangeFilter from '../../../components/widgets/filters/NumrangeFilter';
import InputFilter from '../../../components/widgets/filters/InputFilter';
import AddressFilter from '../../../components/widgets/filters/AddressFilter';
import ItemFilter from '../../../components/widgets/filters/ItemFilter';
import ExportButton from '../../../components/widgets/ExportButton';
import { getFiltersParams, getSortParams } from '../../../functions/getQueryString';
import { handleApiResponse } from '../../../functions/handleApiResponse';
import fetchWithJWT from '../../../functions/fetchWithJWT';
import '../../../stylesheets/filters.css';

const exportData = (store) => (oauthToken, user, updateTokens, { filters, sort }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  if (formatedFilters.date_from) {
    formatedFilters.date_from = dateFormat(formatedFilters.date_from, "yyyy-mm-dd");
  }
  if (formatedFilters.date_to) {
    formatedFilters.date_to = dateFormat(formatedFilters.date_to, "yyyy-mm-dd");
  }
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/history/stock/export`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({
      oauthToken,
      filters: getFiltersParams({ filters: formatedFilters }),
      sort: getSortParams({ sort }),
    }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
};

const hasFilters = (filters) => {
  const keys = Object.keys(filters).filter(x => x !== 'date_from' && x !== 'date_to');
  const values = keys.map(k => filters[k]).filter(v => v);
  const filtered = values.length !== 0;
  return filtered;
};

const Filters = ({
  user,
  updateTokens,
  filters,
  totals,
  setFilters,
  onClear,
  sort,
}) => {
  const { t } = useTranslation();
  const [visibility, setVisibility] = useState('partial');
  const isVisible = visibility !== 'hidden';
  const isFull = visibility === 'full';
  const filtered = hasFilters(filters);
  const { store } = useParams();
  const memoizedExportData = useMemo(() => exportData(store), [store]);

  return (
    <div className={`card filter-card filter-card-${visibility} mb-3 mt-3`}>
      <div className="card-header">
        <button
          size="sm"
          className={`btn btn-primary btn-sm icon-button filter-button ${filtered ? 'filter-button-filtered' : ''}`}
          onClick={() => setVisibility(isVisible ? 'hidden' : 'partial')}
        >
          <i className="vtmn-icon_filter2"></i>
          {filtered && (<i className="vtmn-icon_tiny_bold_valid subicon"></i>)}
          <Trans i18nKey="filters.filters">Filters</Trans>
          {isVisible ? (
            <i className="vtmn-icon_chevron_up_v2 right"></i>
          ) : (
            <i className="vtmn-icon_chevron_down_v2 right"></i>
          )}
        </button>
        <div className="spacer"/>
        <ExportButton
          user={user}
          updateTokens={updateTokens}
          filters={filters}
          sort={sort}
          totalItems={totals && totals.items}
          exportReport={memoizedExportData}
        />
      </div>

      {isVisible && (
        <div className="card-body">
          <div className="row">
            <DaterangeFilter
              controlId="filter-date"
              label={t('movementsHistory.items.date', 'Date')}
              valueFrom={filters.date_from}
              valueTo={filters.date_to}
              onChange={(date_from, date_to) => setFilters({
                ...filters,
                date_from,
                date_to,
              })}
            />

            <AddressFilter
              user={user}
              updateTokens={updateTokens}
              store={store}
              controlId="filter-address"
              label={t('movementsHistory.items.address', 'Address')}
              value={filters.address}
              onChange={(address) => setFilters({
                ...filters,
                address,
              })}
            />

            <ItemFilter
              user={user}
              updateTokens={updateTokens}
              store={store}
              controlId="filter-item"
              label={t('movementsHistory.items.item', 'Item')}
              value={filters.item_id}
              onChange={(item_id) => setFilters({
                ...filters,
                item_id,
              })}
            />

            {isFull && (
              <>
                <NumrangeFilter
                  controlId="filter-stock-before"
                  label={t('movementsHistory.items.stockBefore', 'Stock before')}
                  valueFrom={filters.stock_before_from}
                  valueTo={filters.stock_before_to}
                  onChange={(stock_before_from, stock_before_to) => setFilters({ ...filters, stock_before_from, stock_before_to })}
                />

                <NumrangeFilter
                  controlId="filter-stock-movement"
                  label={t('movementsHistory.items.stockMovement', 'Stock movement')}
                  valueFrom={filters.stock_movement_from}
                  valueTo={filters.stock_movement_to}
                  onChange={(stock_movement_from, stock_movement_to) => setFilters({ ...filters, stock_movement_from, stock_movement_to })}
                />

                <InputFilter
                  controlId="filter-email"
                  label={t('movementsHistory.items.email', 'Email')}
                  value={filters.email}
                  onChange={(email) => setFilters({ ...filters, email })}
                />
              </>
            )}

            <div className="spacer" />

            <div>
              <button
                className="icon-button filter-button filter-button-toggle btn btn-primary btn-sm"
                onClick={() => setVisibility(isFull ? 'partial' : 'full')}
              >
                {isFull ? (
                  t('filters.less', 'Less')
                ) : (
                  t('filters.more', 'More')
                )}
                {isFull ? (
                  <i className="vtmn-icon_chevron_up_v2 right"></i>
                ) : (
                  <i className="vtmn-icon_chevron_down_v2 right"></i>
                )}
              </button>

              <button
                className="icon-button filter-button filter-button-clear btn btn-primary btn-sm"
                onClick={onClear}
              >
                <i className="vtmn-icon_cross"></i>
                <Trans i18nKey="filters.clear">Clear</Trans>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filters;
