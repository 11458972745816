import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Alert } from 'react-bootstrap';
import { OrderAddingError } from '../Orders/messages';
import ErrorMessage from '../../components/widgets/errorMessage';
import { useAppInstalled } from '../../functions/useAppInstalled';

export const ForDifferentStoresMessage = ({ countByStore }) => {
  const { t } = useTranslation();
  const forDifferentStores = t(
    'trackingNumber.messages.forDifferentStores',
    'This tracking # contains items for stores:',
  );
  const message = (
    <>
      <h5 >{forDifferentStores}</h5>
      <ul className="m-0 pl-4">
        {countByStore.map((x, index) => (
          <li key={index}>
            {`«${x.store}» - `}
            {t('shared.orders', {
              defaultValue: '{{count}} orders',
              count: x.cnt,
            })}
          </li>
        ))}
      </ul>
    </>
  );
  return <ErrorMessage error={{ message }} />
};

export const AllForWrongStoreMessage = ({ store, count }) => {
  const { t } = useTranslation();
  const message = t('trackingNumber.messages.allForWrongStore', {
    defaultValue: "This is order for «{{store}}»",
    count,
    store,
  });

  return <ErrorMessage error={{ message }} />
};

export const NoOrdersMessage = () => {
  const { t } = useTranslation();
  const notFound = t('trackingNumber.messages.notFound', 'Orders not found');
  const doConfirmNumber = t('trackingNumber.messages.doConfirmNumber', 'Do you confirm it is the right number that was read by the camera?');
  const callLogistics = t('trackingNumber.messages.callLogistics', 'If yes, please call your logistics to ask them to validate the expedition');
  const message = (
    <>
      <h5 >{notFound}</h5>
      <ul className="m-0 pl-4">
        <li>{doConfirmNumber}</li>
        <li>{callLogistics}</li>
      </ul>
    </>
  );
  return <ErrorMessage error={{ message }} />
};

export const OrderToMountMessage = () => {
  return (
    <Alert variant="warning" className="px-1 py-2">
      <div className="d-flex flex-row">
        <img
          className="img-icon mr-2"
          alt="alert"
          src="/icons/wrench_warning.svg"
        />
        <div>
          <Trans i18nKey="orderMount.isOrderToMount">
            It's seems this item has to be mounted or configured before to notify the customer.
            Prefer to address it in a dedicated address
          </Trans>
        </div>
      </div>
    </Alert>
  );
};

export const AllLockedMessage = () => (
  <>
    <div className="text-warning">
      <Trans i18nKey="shared.warning">Warning</Trans>
      {': '}
      <Trans i18nKey="trackingNumber.messages.allLockedWarning">All orders with this item are beeing processed now</Trans>
    </div>
    <div className="text-warning">
      <Trans i18nKey="trackingNumber.messages.allLockedQuestion">Do you want to assign item to one of them or declare this item unexpected?</Trans>
    </div>
  </>
);

export const OtherTrackingFoundMessage = () => (
  <>
    <div className="text-warning">
      <Trans i18nKey="shared.warning">Warning</Trans>
      {': '}
      <Trans i18nKey="trackingNumber.messages.otherTrackingFoundWarning">You found an extra item.</Trans>
    </div>
    <div className="text-warning">
      <Trans i18nKey="trackingNumber.messages.otherTrackingFoundQuestion">Do you want to associate it with a previous missing product?</Trans>
    </div>
  </>
);

export const WrongStoreMessage = ({
  orderItem,
  missing,
}) => (
  <>
    <div className="text-danger">
      <Trans i18nKey="shared.warning">Warning</Trans>
      {': '}
      <Trans i18nKey="trackingNumber.messages.forOtherStore">This is order for store</Trans>
      {' '}
      {orderItem.shipping_group.storeId}
      {' - '}
      {orderItem.shipping_group.storeName}
    </div>
    <div className="text-danger">
      <Trans i18nKey="trackingNumber.messages.callCustomerService">Please call customer service</Trans>
    </div>
    {!missing && (
      <div className="text-danger">
        <Trans i18nKey="trackingNumber.messages.unexpectedItemQuestion">Do you want to declare it unexpected?</Trans>
      </div>
    )}
  </>
);

export const CanceledMessage = ({ missing }) => (
  <>
    <div className="text-danger">
      <Trans i18nKey="shared.warning">Warning</Trans>
      {': '}
      <Trans i18nKey="trackingNumber.messages.orderCanceled">This order was cancelled. You can put the items into the store stock by clicking on the button.</Trans>
    </div>
    {!missing && (
      <div className="text-danger">
        <Trans i18nKey="trackingNumber.messages.unexpectedCanceledItemQuestion">Do you want to put it in store stock?</Trans>
      </div>
    )}
  </>
);

export const CompletedMessage = ({ missing }) => (
  <>
    <div className="text-danger">
      <Trans i18nKey="shared.warning">Warning</Trans>
      {': '}
      <Trans i18nKey="trackingNumber.messages.orderCompleted">This order has already been completed</Trans>
    </div>
    {!missing && (
      <div className="text-danger">
        <Trans i18nKey="trackingNumber.messages.unexpectedItemQuestion">Do you want to declare it unexpected?</Trans>
      </div>
    )}
  </>
);

export const InLogisticsMessage = () => (
  <>
    <div className="text-danger">
      <Trans i18nKey="shared.warning">Warning</Trans>
      {': '}
      <Trans i18nKey="trackingNumber.messages.orderInLogistics">This order IN_LOGISTICS_PREPARATION, please call CRC to ask Warehouse to check this Case, or open a ticket to CC-LOG</Trans>
    </div>

  </>
);

export const UnexpectedMessage = () => (
  <>
    <div className="text-danger">
      <Trans i18nKey="shared.warning">Warning</Trans>
      {': '}
      <Trans i18nKey="trackingNumber.messages.unexpectedItemWarning">You found an extra item.</Trans>
    </div>
    <div className="text-danger">
      <Trans i18nKey="trackingNumber.messages.unexpectedItemQuestion">Do you want to declare it unexpected?</Trans>
    </div>
  </>
);

export const LockedMessage = ({ lockedBy }) => (
  <>
    <div className="text-warning">
      <Trans i18nKey="shared.warning">Warning</Trans>
      {': '}
      <Trans i18nKey="trackingNumber.messages.lockedItemWarning">This order is beeing processed by</Trans>
      {': '}
      {lockedBy}
    </div>
    <div className="text-warning">
      <Trans i18nKey="trackingNumber.messages.lockedItemQuestion">Are you sure you want to process it?</Trans>
    </div>
  </>
);

export const GivenMessage = () => {
  const { t } = useTranslation();
  return (
    <div className="text-primary font-weight-bold">
      {t('trackingNumber.messages.givenToCustomer', 'This item was given to customer')}
    </div>
  );
};

export const ResolvedMessage = ({ orderItem }) => {
  const { t } = useTranslation();
  const resolveType = orderItem.missing?.resolve_type || orderItem.unexpected?.resolve_type;
  const resolveAddress = orderItem.missing?.resolve_address || orderItem.unexpected?.resolve_address;
  let message;
  if (resolveType === 'mark_resolved') {
    message = t('trackingNumber.messages.markResolved', 'This problem was marked resolved');
  } else if (resolveType === 'assigned_store_stock') {
    message = t('trackingNumber.messages.assignedStoreStock', 'Item from store stock was assigned');
  } else if (resolveType === 'anomaly_exchange') {
    message = t('trackingNumber.messages.freeOrder', 'Free order was created');
  } else if (resolveType === 'assigned_from_wrong_store') {
    message = t('trackingNumber.messages.assignedFromWrongStore', 'Customer agreed to pick this order from our store');
  } else if (resolveType === 'back_to_sale') {
    message = t('trackingNumber.messages.backToSale', 'This item was returned back to sale');
  } else {
    return null;
  }
  return (
    <>
      {resolveAddress && (
        <div className="text-primary font-weight-bold">
          {resolveAddress}
        </div>
      )}
      <div className="text-primary font-weight-bold">
        {message}
      </div>
    </>
  );
};

export const SameItemError = ({ onClose, onForceAssignSameItem, className }) => {
  const { t } = useTranslation();
  const message = t(
    'trackingNumber.messages.addressHasSameItem',
    'Please do not place the same item code into the same address. This address already has this item for other order.',
  );
  return (
    <Alert
      variant="danger"
      dismissible
      onClose={onClose}
      className={className}
    >
      <p>{message}</p>
      <Button
        className="displayAlert"
        variant="secondary"
        onClick={onClose}
      >
        <Trans i18nKey="shared.cancel">Cancel</Trans>
      </Button>
      <Button
        variant="danger"
        className="displayAlert"
        onClick={onForceAssignSameItem}
      >
        <Trans i18nKey="trackingNumber.assignAnyway">Assign anyway</Trans>
      </Button>
    </Alert>
  );
}

export const NoPackagingOrderAddingError = ({ error, onClose, onForceAssignSameItem }) => {
  const isAddressHasSameItem = error.response?.order_number && error.response?.has_same_item === true;
  const isLockedByError = error.response?.code === '409' && error.response?.lockedBy;

  if (isLockedByError) {
    return null;
  }

  if (isAddressHasSameItem) {
    return (
      <SameItemError
        onClose={onClose}
        onForceAssignSameItem={onForceAssignSameItem}
      />
    )
  }

  return(
    <OrderAddingError
      error={error}
      onClose={onClose}
    />
  );
};

export const PreferAppMessage = () => {
  const [show, setShow] = useState(true);
  const appInstalled = useAppInstalled();
  if (!show) {
    return null;
  }

  return (
    <Alert
      variant="info"
      className="my-1 px-3 py-2 text-left"
      dismissible
      onClose={() => setShow(false)}
    >
      <Trans i18nKey="trackingNumber.preferAppMessage.useRfid">
        You should use the RFID to receive a no-pack box:
      </Trans>
      <ul className="m-0 pl-3">
        <li >
          <Trans i18nKey="trackingNumber.preferAppMessage.avoidRinging">
            It avoids the security gate to ring when giving the order to the customer.
          </Trans>
        </li>
        <li>
          <Trans i18nKey="trackingNumber.preferAppMessage.traceability">
            It allows traceability features for the customer.
          </Trans>
        </li>
      </ul>
      <hr className="my-1"/>
      <div>
      {appInstalled ? (
        <Trans i18nKey="trackingNumber.preferAppMessage.clickToOpen">
          Click <a href="productlocator://">here</a> to open the app
        </Trans>
      ) : (
        <Trans i18nKey="trackingNumber.preferAppMessage.clickHere">
          Click <a href="https://play.google.com/store/apps/details?id=com.decathlon.productlocator">here</a> to download the app
        </Trans>
      )}
      </div>
    </Alert>
  );
}
