import React from 'react';
import _ from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import { Button } from 'react-bootstrap';
import dateFormat from 'dateformat';
import { Link, useParams } from 'react-router-dom';
import { isReceived } from './AddressingParcels';

const IN_STORE_SHIPPING_METHODS = ['IN_STORE_PREPARE_AND_PICKUP', 'PICKUP_IN_STORE'];

export const getFreeOrderNumber = (freeOrderLink) => {
  if (freeOrderLink.link_type === 'is_replaced_by') {
    let order = `${freeOrderLink.replacement_order_number}`;
    if (freeOrderLink.replacement_shipping_group_number) {
      order += `-${freeOrderLink.replacement_shipping_group_number}`;
    }
    return order
  } else {
    let order = `${freeOrderLink.order_number}`;
    if (freeOrderLink.shipping_group_number) {
      order += `-${freeOrderLink.shipping_group_number}`;
    }
    return order
  }
}

export const OrderStatusWarning = ({ order, onBackToSale }) => {
  const isCanceled = order?.info?.status === 'CANCELLED';
  const isCompleted = order?.info?.status === 'COMPLETED';
  const isReplacedBy = order?.freeOrderLink?.link_type === 'is_replaced_by';
  const sgIsCanceled = (
    order &&
    order.addressableFormat &&
    ((order.info?.shipping_groups || []).find(x => x.id === order.code || x.trackingNumbers?.includes(order.code)) || {}).status === 'CANCELLED'
  );
  const sgIsCompleted = (
    order &&
    order.addressableFormat &&
    ((order.info?.shipping_groups || []).find(x => x.id === order.code || x.trackingNumbers?.includes(order.code)) || {}).status === 'COMPLETED'
  );
  const isInStorePrepareAndPickup = (
    order &&
    ((order.info?.shipping_groups || []).find(x => x.id === order.code || x.trackingNumbers?.includes(order.code)) || {}).shippingMethod === 'IN_STORE_PREPARE_AND_PICKUP'
  );
  const canBackToSale = !isInStorePrepareAndPickup && order?.backToSaleRequired;

  if (!isCanceled && (!isCompleted || isReplacedBy) && !sgIsCanceled && (!sgIsCompleted || isReplacedBy)) {
    return null;
  }

  return (
    <div className="alert alert-danger fade show" role="alert" id="orderStatusError">
      <h4>
        <Trans i18nKey="shared.warning">Warning</Trans>
      </h4>
      {isCanceled && <div><Trans i18nKey="orders.orderCanceled">This order was cancelled</Trans></div>}
      {sgIsCanceled && <div><Trans i18nKey="orders.sgCanceled">This shipping group was cancelled</Trans></div>}
      {sgIsCanceled && isInStorePrepareAndPickup &&
          <div>
              <Trans i18nKey="orders.orderCanceledAutoPutBackInStore">It was automatically put back into the stock of the store</Trans><br/>
              <Trans i18nKey="orders.orderCanceledPutItems">Please put the items back to the store area</Trans>
          </div>
      }
      {sgIsCanceled && canBackToSale &&
        <Button
          variant="danger"
          className="mt-2"
          onClick={onBackToSale}
        >
          <Trans i18nKey="orders.putInStock.putInStock">Put in store stock</Trans>
        </Button>
      }
      {isCompleted && <div><Trans i18nKey="orders.orderCompleted">This order was completed</Trans></div>}
      {sgIsCompleted && <div><Trans i18nKey="orders.sgCompleted">This shipping group was completed</Trans></div>}
    </div>
  );
};

export const OrderError = ({ errors, onClose }) => {
  const { t } = useTranslation();
  if (!errors) {
    return null;
  }

  return (
    <div className="alert alert-danger alert-dismissible fade show" role="alert" id="orderError">
      {errors.map((error, index) => {
        if (!error.isInfoError && error.code === '470') {
          return null;
        }
        return(
          <>
            {index === 0 && <h5>
              {error.isInfoError
                ? t('shared.warning', 'Warning')
                : t('orders.errorTitle', 'An error occured')}
            </h5>}
            <div>{error.item_id && `${error.item_id}: `}{error.message || error}</div>
          </>
        )
      })}
      <button
        type="button" className="close" aria-label="Close"
        onClick={onClose}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

const ParcelMessage = ({ message }) => (
  <div className="alert alert-info fade show" role="alert">
    <div>
      {message}
    </div>
  </div>
);

const InfoMessage = ({ message }) => (
  <div className="alert alert-info fade show" role="alert">
    <h4>
      <Trans i18nKey="orders.beCareful">Be careful</Trans>
    </h4>
    <div>
      {message}
    </div>
  </div>
);

const WarningMessage = ({ message }) => (
  <div className="alert alert-danger fade show" role="alert">
    <h4>
      <Trans i18nKey="shared.warning">Warning</Trans>
    </h4>
    <div>
      {message}
    </div>
  </div>
);

export const FreeOrderLinkMessage = ({ freeOrderLink }) => {
  const { t } = useTranslation();
  const { store } = useParams();

  if (!freeOrderLink) {
    return null;
  }

  const isReplacedBy = freeOrderLink.link_type === 'is_replaced_by';

  const replacedByOrder = t('freeOrder.replacedByOrder', 'This order was replaced by free order:');
  const replacementOrder = t('freeOrder.replacementOrder', 'This is a free order replacing order:');
  const createdAt = t('freeOrder.createdAt', 'created at:');
  const createdBy = t('freeOrder.createdBy', 'created by:');

  if (isReplacedBy) {
    return (
      <WarningMessage message={(
        <>
          <div>
            {replacedByOrder}
            {' '}
            <b>
              <Link className="alert-danger" to={`/${store}/orders?order=${freeOrderLink.replacement_order_number}`}>
                {getFreeOrderNumber(freeOrderLink)}
              </Link>
            </b>
          </div>
          <div>
            {createdBy}
            {' '}
            <b>{freeOrderLink.employee}</b>
          </div>
          <div>
            {createdAt}
            {' '}
            <b>
              {dateFormat(
                new Date(freeOrderLink.change_date),
                "dd/mm/yyyy HH:MM:ss",
              )}
            </b>
          </div>
        </>
      )} />
    );
  } else {
    return (
      <InfoMessage message={(
        <>
          <div>
            {replacementOrder}
            {' '}
            <b>
              <Link className="alert-info" to={`/${store}/orders?order=${freeOrderLink.order_number}`}>
                {getFreeOrderNumber(freeOrderLink)}
              </Link>
            </b>
          </div>
          <div>
            {createdBy}
            {' '}
            <b>{freeOrderLink.employee}</b>
          </div>
          <div>
            {createdAt}
            {' '}
            <b>
              {dateFormat(
                new Date(freeOrderLink.change_date),
                "dd/mm/yyyy HH:MM:ss",
              )}
            </b>
          </div>
        </>
      )} />
    );
  }
};

const checkMultiparcel = (
  code,
  codeFormat,
  info,
) => {
  let isMultiparcelContainer = false
  let multiparcelCount = 0
  let multiparcelSg = ''

  if (!code || !info || !('shipping_groups' in info)) {
    return {
      multiparcelContainers: [],
      isMultiparcelContainer,
      multiparcelCount,
      multiparcelSg,
    }
  }
  const shippingGroups = info.shipping_groups || []

  const multiparcelContainers = shippingGroups.filter(sg => sg.parcels && sg.parcels.length > 1)

  if (codeFormat === 'tracking_number') {
    for (const sg of shippingGroups) {
      if (sg.parcels && sg.parcels.length > 1) {
        for (const parcel of sg.parcels) {
          if (parcel.tracking_number === code) {
            const received = _.every(sg.parcels, isReceived)
            if (!received) {
              isMultiparcelContainer = true
              multiparcelCount = sg.parcels.length
              multiparcelSg = sg.id
            }
          }
        }
      }
    }
  }
  return {
    multiparcelContainers,
    isMultiparcelContainer,
    multiparcelCount,
    multiparcelSg,
  }
}

export const MultiparcelMessages = ({ order }) => {
  const { t } = useTranslation();
  if (!order) {
    return null;
  }

  const { code, codeFormat, info } = order;

  const { multiparcelContainers, isMultiparcelContainer, multiparcelCount } = checkMultiparcel(
    code,
    codeFormat,
    info,
  );

  const messages = [];
  if (isMultiparcelContainer) {
    messages.push({
      message: (
        <Trans
          i18nKey="orders.multiparcelContainerParcel"
          count={multiparcelCount}
          components={{ b: <b /> }}
          defaults="This parcel is part of a delivery with {{count}} parcels. \nPlease, scan and address <b>each</b> parcel."
        />
      ),
    });
  } else if (multiparcelContainers.length) {
    for (const sg of multiparcelContainers) {
      messages.push({
        message: t('orders.multiparcelContainer', {
          defaultValue: 'Container {{container}} has {{count}} parcels',
          container: sg.id,
          count: (sg.parcels || []).length,
        }),
      });
    }
  }

  return (
    <div className="mt-2">
      {messages.map(({ message }, index) => <ParcelMessage key={index} message={message} />)}
    </div>
  );
};

export const OrderInfoMessage = ({ order }) => {
  const { t } = useTranslation();
  if (!order) {
    return null;
  }

  const isSplittedOrder = order.info?.shipping_groups?.length > 1;
  if (!isSplittedOrder) {
    return null;
  }

  let message = null;
  const { canAddress } = order;
  const stock = order.stock || [];


  if (canAddress) {
    const SGTotal = order.info.shipping_groups.length;
    const SG = order.info.shipping_groups.find(x => x.id === order.code || x.trackingNumbers?.includes(order.code));
    const shipping_group_number = SG?.id;

    const SGInStock = [...new Set(stock.map(x => x.shipping_group_number))].length;
    const isCurrentSGInStock = !!stock.find(x => x.shipping_group_number === shipping_group_number);
    const isAnotherSGInStock = !!stock.find(x => x.shipping_group_number !== shipping_group_number);
    if (!isCurrentSGInStock && isAnotherSGInStock) {
      const inStockAddreses = [...new Set(stock.map(x => x.address))].join(', ');
      message = <>
        <Trans i18nKey="orders.splitedOrder">This is a splitted order.</Trans>
        <Trans i18nKey="orders.splitedOrderThere">There are</Trans>
        {' '}
        <b>{SGInStock}/{SGTotal}</b>
        {' '}
        <Trans i18nKey="orders.splitedOrderParcelsInStock">shipping groups in stock in addresses</Trans>
        {' '}
        <b>{inStockAddreses}</b>
      </>;
    } else if (!isCurrentSGInStock && !isAnotherSGInStock) {
      const notInStockSG = order.info.shipping_groups.filter(
        x => x.id !== shipping_group_number && IN_STORE_SHIPPING_METHODS.indexOf(x.shippingMethod) > -1,
      );
      if (notInStockSG.length) {
        message = <>
        <Trans i18nKey="orders.splitedOrderThere">There are</Trans>
        {' '}
        <b>{SGTotal}</b>
        {' '}
        <Trans i18nKey="orders.splitedOrderParcelsInOrder">shipping groups in this order. The others are not in stock yet</Trans>
      </>;
      }
    }
  } else {
    const notGiven = order.info.shipping_groups
      .filter(x => x.status !== 'COMPLETED' && x.status !== 'CANCELLED');
    const SGNumbersInStock = [...new Set(stock.map(x => x.shipping_group_number))]
    const notArrivedYet = notGiven.filter(x => SGNumbersInStock.indexOf(x.id) === -1);
    if (notArrivedYet && notArrivedYet.length) {
      message = (
        <>
          <div>
            <Trans i18nKey="orders.splitedOrder">This is a splitted order.</Trans>
            <Trans i18nKey="orders.splitedOrderThereStill">There are still</Trans>
            {' '}
            <b>{notArrivedYet.length}</b>
            {' '}
            <Trans i18nKey="orders.splitedOrderThereStillParcels">shipping groups:</Trans>
          </div>
          <ul className="m-0">
            {notArrivedYet.map((x, index) => (
              <li key={index}>
                {x.id}
                {' - Shipping date: '}
                {x.promisedShippingDate ? dateFormat(
                  new Date(x.promisedShippingDate),
                  "dd/mm/yyyy HH:MM:ss",
                ) : t('orders.unknown', 'Unknown')}
              </li>
            ))}
          </ul>
        </>
      );
    }
  }

  if (message) {
    return <InfoMessage message={message} />
  }

  return null;
};

export const OrderAddingError = ({ error, onClose }) => {
  const { t } = useTranslation();
  if (!error || error.code === '470') {
    return null;
  }

  const isAddressNotFound = error.response && error.response.address && error.response.exists === false;
  const isAddressDisabled = error.response && error.response.address_id && error.response.disabled === true;
  if (isAddressNotFound || isAddressDisabled) {
    const message = (
      isAddressNotFound
        ? t('orders.addressNotExists', 'Address does not exist.')
        : t('orders.addressDisabled','Address is existing but was disabled previously.')
    );
    return (
      <div className="alert alert-danger alert-dismissible fade show" role="alert" id="orderAddressError">
        <p>{message}</p>
        <button
          type="button" className="close" aria-label="Close"
          onClick={onClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
  if (error) {
    return (
      <div className="alert alert-danger alert-dismissible fade show" role="alert" id="orderAddressError">
        <h4>
          {
            error.isInfoError
              ? t('shared.warning', 'Warning')
              : t('orders.errorTitle', 'An error occured')
          }
        </h4>
        <div>
          {error.message || error}
        </div>
        <button
          type="button" className="close" aria-label="Close"
          onClick={onClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
  return null;
};

