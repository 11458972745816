import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ReleasePublishButton = props => {
  const { published = false, size = 'sm', disabled, onClick } = props;
  const { t } = useTranslation();
  const textStyle = { paddingLeft: 8 };
  if (size === 'sm') textStyle.fontSize = 12;
  return (
    <Button
      variant={published ? 'outline-primary' : 'outline-success'}
      size={size}
      disabled={disabled}
      onClick={onClick}
    >
      <span style={{ display: 'flex', flexDirection: 'row' }}>
        <i className={published ? 'vtmn-icon_eye_off' : 'vtmn-icon_eye_on' } />
        <span style={textStyle}>
          {published ? t('releases.unpublish', 'Unpublish') : t('releases.publish', 'Publish')}
        </span>
      </span>
    </Button>
  );
};

export default ReleasePublishButton;
