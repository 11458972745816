import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { withPrintState, saveOptions, withPrintRequest } from './withPrintState';
import { FILETYPE_OPTIONS, getFromatOptions, getOrientationOptions, getPrinter } from './helpers';
import { printAddresses } from './api';
import SimpleSelect from '../widgets/SimpleSelect';
import InputNumber from '../widgets/InputNumber';
import RangeInput from '../widgets/RangeInput';
import Switch from '../widgets/Switch';
import '../../stylesheets/printLabelModal.css';


const serverUrl = process.env.REACT_APP_SERVERURL;

const getLabelUrl = ({ store, options, filters, pagination }) => {
  const {
    width,
    height,
    padding,
    fontsize,
    ratio,
    fit,
    qr,
    horizontal,
    format,
    filetype,
    orientation,
  } = options;
  const {
    letter,
    address,
    addresses,
    printDisabled,
  } = filters;

  let queryParams = {};

  if (letter) {
    queryParams.letter = letter;
  }
  if (address) {
    queryParams.address = address;
  }
  if (addresses) {
    queryParams.addresses = addresses.join(',');
  }
  if (printDisabled) {
    queryParams.printDisabled = printDisabled;
  }
  if (width) {
    queryParams.width = width;
  }
  if (height) {
    queryParams.height = height;
  }
  if (padding) {
    queryParams.padding = padding;
  }
  if (fontsize) {
    queryParams.fontsize = fontsize;
  }
  if (ratio) {
    queryParams.ratio = ratio;
  }
  let codeType;
  if (qr) {
    codeType = 'qrcode';
    if (horizontal) {
      queryParams.horizontal = horizontal;
    }
  } else {
    codeType = 'barcode';
    if (fit) {
      queryParams.fit = fit;
    }
  }
  if (pagination) {
    const from = pagination.size * pagination.page;
    const to = from + pagination.size - 1;
    const range = `${from}-${to}`;
    queryParams.range = range;
  }
  if (format) {
    queryParams.format = format;
  }
  if (orientation) {
    queryParams.orientation = orientation;
  }
  if (filetype) {
    queryParams.filetype = filetype;
  }

  let params = new URLSearchParams(queryParams).toString();
  if (params) {
    params = '?' + params;
  }
  const url = `${serverUrl}/v1/print/${codeType}/${store}/addresses${params}`;
  return url;
};

const getPrintParams = ({ options, filters, pagination }) => {
  const {
    width,
    height,
    padding,
    fontsize,
    ratio,
    fit,
    qr,
    horizontal,
    format,
    orientation,
    filetype,
  } = options;

  let printOpts = {};
  let queryParams = {};

  if (width) {
    printOpts.width = Number(width);
  }
  if (height) {
    printOpts.height = Number(height);
  }
  if (padding) {
    printOpts.padding = Number(padding);
  }
  if (fontsize) {
    printOpts.fontsize = Number(fontsize);
  }
  if (ratio) {
    printOpts.ratio = Number(ratio);
  }
  let codetype;
  if (qr) {
    codetype = 'qrcode';
    if (horizontal) {
      printOpts.horizontal = horizontal;
    }
  } else {
    codetype = 'barcode';
    if (fit) {
      printOpts.fit = fit;
    }
  }
  if (format) {
    printOpts.format = format;
  }
  if (orientation) {
    printOpts.orientation = orientation;
  }
  if (filetype) {
    printOpts.filetype = filetype;
  }
  if (pagination) {
    const from = pagination.size * pagination.page;
    const to = from + pagination.size - 1;
    const range = `${from}-${to}`;
    queryParams.range = range;
  }

  let query = new URLSearchParams(queryParams).toString();

  return {
    codetype,
    query,
    filters,
    printOpts,
  }
};

const PrintPreview = ({ options, filters, pagination }) => {
  const { store } = useParams();
  const url = getLabelUrl({
    store,
    options: { ...options, filetype: 'svg' },
    filters,
    pagination,
  });
  return (
    <div className="label-preview-wrapper pt-2">
      <iframe
        title="Preview"
        src={url}
        className="multi-addresses-preview-image"
      />
    </div>
  );
};



export const StatelessPrintMultiAdressesModal = ({
  storageOptsKey,
  filters,
  pagination,
  // print state
  width,
  setWidth,
  height,
  setHeight,
  padding,
  setPadding,
  fontsize,
  setFontsize,
  ratio,
  setRatio,
  fit,
  setFit,
  qr,
  setQr,
  horizontal,
  setHorizontal,
  format,
  setFormat,
  filetype,
  setFiletype,
  orientation,
  setOrientation,
  selectedPrinterId,
  setSelectedPrinterId,
  saveLastPrinter,
  printers,
  printersFetching,
  printersError,
  printing,
  onPrint,
  options,
  previewOptions,
  setPreviewOptions,
}) => {

  const { store } = useParams();
  const { t } = useTranslation();
  const printUrl = getLabelUrl({ store, options, filters, pagination });
  const filePrinterLabel = t('selectPrinter.saveFile', 'Save File');
  const printerOptions = (printers || []).map(({ id, name }) => ({ id, label: name }));
  printerOptions.push({
    id: 'file',
    label: filePrinterLabel,
  });
  const isFilePrinting = !selectedPrinterId || selectedPrinterId === 'file';
  const isPdf = filetype === 'pdf';

  return (
    <div className="modal fade" id="printMultiAddresses" tabIndex="-1" role="dialog" aria-labelledby="modalPrintLabelTitle" aria-hidden="true">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalPrintLabelTitle">
              <Trans i18nKey="printLabel.printingAddresses">Printing addresses</Trans>
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="closeModalPrintLabel">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <InputNumber
              inline
              controlId="address-label-width"
              label={<Trans i18nKey="printLabel.width">Width (cm):</Trans>}
              min={2}
              max={50}
              step={0.5}
              value={width}
              onChange={setWidth}
            />
            <InputNumber
              inline
              controlId="address-label-height"
              label={<Trans i18nKey="printLabel.height">Height (cm):</Trans>}
              min={2}
              max={50}
              step={0.5}
              value={height}
              onChange={setHeight}
            />
            <InputNumber
              inline
              controlId="address-label-padding"
              label={<Trans i18nKey="printLabel.padding">Padding (cm):</Trans>}
              min={0}
              max={5}
              step={0.1}
              value={padding}
              onChange={setPadding}
            />
            <InputNumber
              inline
              controlId="address-label-fontsize"
              label={<Trans i18nKey="printLabel.fontsize">Fontsize (px):</Trans>}
              min={6}
              max={100}
              step={1}
              value={fontsize}
              onChange={setFontsize}
            />
            <SimpleSelect
              inline
              label={t('selectPrinter.selectPrinter', 'Select printer')}
              controlId="address-printer"
              options={printerOptions}
              selected={selectedPrinterId}
              onChange={(val) => setSelectedPrinterId(val)}
              loading={printersFetching}
              error={printersError}
            />
            {isFilePrinting && (
              <SimpleSelect
                inline
                label={t('selectPrinter.fileType', 'File type')}
                controlId="address-filetype"
                options={FILETYPE_OPTIONS}
                selected={filetype}
                onChange={(val) => setFiletype(val)}
              />
            )}
            <SimpleSelect
              inline
              label={t('selectPrinter.pageFormat', 'Page format')}
              controlId="address-format"
              options={getFromatOptions(t)}
              selected={format}
              onChange={(val) => setFormat(val)}
            />
            {(!isFilePrinting || isPdf) && (
              <SimpleSelect
                inline
                label={t('selectPrinter.orientation', 'Orientation')}
                controlId="address-orientation"
                options={getOrientationOptions(t)}
                selected={orientation}
                onChange={(val) => setOrientation(val)}
              />
            )}

            <RangeInput
              controlId="address-label-ratio"
              label={(
                <>
                  <Trans i18nKey="printLabel.barcodeSize">Barcode size:</Trans>
                  {' '}
                  {ratio * 100}
                  {'%'}
                </>
              )}
              min={0}
              max={1}
              step={0.1}
              value={ratio}
              error={(
                <>
                  {(ratio * 100) >= 80 && (
                    <Trans i18nKey="printLabel.warningBarcode80">Be careful: the text might not be visible when the barcode size is bigger than 80%</Trans>
                  )}
                  {(ratio * 100) <= 20 && (
                      <Trans i18nKey="printLabel.warningBarcode20">Be careful: the barcode might not be visible when the barcode size is less than 20%</Trans>
                  )}
                </>
              )}
              onChange={setRatio}
            />

            <Switch
              controlId="address-label-qr"
              checked={!!qr}
              label={<Trans i18nKey="printLabel.qr">Barcode / QR</Trans>}
              onChange={() => {
                setQr(!qr);
                setPreviewOptions({
                  ...options,
                  qr: !qr,
                });
              }}
            />

            {qr ? (
              <Switch
                controlId="address-label-horizontal"
                onChange={() => setHorizontal(!horizontal)}
                checked={!!horizontal}
                label={<Trans i18nKey="printLabel.horizontal">Place label to the right</Trans>}
              />
            ) : (
              <Switch
                controlId="address-label-fit"
                onChange={() => setFit(!fit)}
                checked={!!fit}
                label={<Trans i18nKey="printLabel.fitBarcode">Fit barcode</Trans>}
              />
            )}

            <PrintPreview
              options={previewOptions}
              filters={filters}
              pagination={pagination}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
            >
              <Trans i18nKey="shared.cancel">Cancel</Trans>
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                saveOptions(storageOptsKey, options);
                saveLastPrinter(getPrinter(printers, selectedPrinterId, filePrinterLabel));
                setPreviewOptions(options)
              }}
            >
              <Trans i18nKey="printLabel.updatePreview">Update preview</Trans>
            </button>
            {isFilePrinting ? (
              <a
                href={printUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary"
                onClick={() => {
                  saveOptions(storageOptsKey, options);
                  saveLastPrinter(getPrinter(printers, selectedPrinterId, filePrinterLabel));
                  setPreviewOptions(options);
                }}
              >
                <Trans i18nKey="printLabel.print">Print</Trans>
              </a>
            ) : (
              <button
                disabled={printing}
                type="button"
                className="btn btn-primary icon-button"
                onClick={() => {
                  saveOptions(storageOptsKey, options);
                  saveLastPrinter(getPrinter(printers, selectedPrinterId, filePrinterLabel));
                  setPreviewOptions(options);
                  onPrint(getPrintParams({ options, filters, pagination }));
                }}
              >
                {printing ? (
                  <span className="button-loader">
                    <ClipLoader color="#fff" size={15} loading />
                  </span>
                ) : null}
                <Trans i18nKey="printLabel.print">Print</Trans>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withPrintState(withPrintRequest(
  StatelessPrintMultiAdressesModal,
  printAddresses,
));
