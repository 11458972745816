import React from 'react';
import ModalVideo from 'react-modal-video';
import { Trans } from 'react-i18next';
import '../modal-video.css';

class OpenVIdeoModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isOpen:false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal(props) {
    this.setState({isOpen: true})
  }

  render() {
    return(
      <div>
        <ModalVideo
        channel='youtube'
        isOpen={this.state.isOpen}
        videoId={this.props.video.currentvideoId}
        onClose={() => this.setState({isOpen:false})}
        />
        <button type="button" className="btn btn-outline-secondary btn-sm " onClick={this.openModal}>
          <Trans i18nKey="videoModal.play">Play vidéo</Trans>
        </button>
        <span className="glyphicon glyphicon-play" aria-hidden="true"></span>
      </div>
    )
  }
}


export default OpenVIdeoModal;
