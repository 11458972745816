import { React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { getCountryLabel } from '../../functions/countries';
import "../../stylesheets/flags.css";
import '../../stylesheets/countrySelect.css';
import blankFlag from "../../stylesheets/blankFlag.gif";

const getCountries = (t, stores) => {
  const countryCodes = _.uniq(stores.map(x => x.country_code).filter(x => !!x));
  let countries = countryCodes.map(x => {
    const codeLower = x.toLowerCase();
    const codeUpper = x.toUpperCase();
    return {
      code: codeLower,
      flag: `flag-${codeLower}`,
      label: getCountryLabel(t, codeUpper) || codeUpper,
    }
  });
  countries = _.orderBy(countries, ['label'], ['asc']);
  return countries;
}

const CountrySelect = ({ updateSelectedCountry, stores }) => {
  const { t } = useTranslation();

  const [selectedCountry, setSelectedCountry] = useState("");
  const countries = getCountries(t, stores);

  return (
    <div className="dropdown-country-select clickableText nav-item dropdown">
      <button
        className="vtmn-btn vtmn-btn_variant--secondary dropdown-toggle countryFilter"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {selectedCountry
          ? (
            <img
              src={blankFlag}
              className={`flag flag-${selectedCountry}`}
              alt={`flag of ${selectedCountry}`}
            />
          )
          : <i className="vtmn-icon_filter2" />
        }
      </button>
      <div className="dropdown-menu dropdown-menu-right" aria-labelledby="langMenuButton">
        {countries.map((country) => {
          const isSelected = country.code === selectedCountry;

          return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              key={country.code}
              className={`dropdown-item clickableText ${isSelected ? 'dropdown-country-item-selected' : ''}`}
              href="#"
              role="button"
              onClick={() => {
                if (isSelected) {
                  setSelectedCountry('');
                  updateSelectedCountry('');
                } else {
                  setSelectedCountry(country.code);
                  updateSelectedCountry(country.code);
                }
              }}
            >
              <img
                src={blankFlag}
                className={`flag ${country.flag}`}
                alt={country.label}
              />
              {' '}
              <div className="dropdown-country-label">
                {country.label}
              </div>
              {isSelected && <i className="dropdown-country-check vtmn-icon_valid" />}
            </a>
          )
        })}

      </div>
    </div >
  );
};

export default CountrySelect;
