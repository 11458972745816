import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import dateFormat from 'dateformat';
import _  from 'lodash';

import useRequest from '../../functions/useRequest';
import WithSidebar from '../WithSidebar';
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/widgets/errorMessage';
import ImageThumbs from '../../components/widgets/ImageThumbs';

import { getItemDetails } from '../EcomPickingList/api';

import { getTrolleysList } from './api';
import { useEcomPackingState } from './state';
import FiltersModal from './FiltersModal';

import '../../stylesheets/ecomPacking.css';
import defaultImage from '../../images/default_image.png';

const NUMBER_OF_COL = 4;

const EcomPacking = props => {
  const {
    showImages,
    setShowImages,
    filters,
    sort,
    hasFilters,
    setFilters,
    setSort,
    resetFilters,
    clearFilters,
    user,
    updateTokens,
    logOut,
  } = props;

  const { store } = useParams();
  const history = useHistory();

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [error, setError] = useState(null);
  const [itemIds, setItemIds] = useState([]);

  const [{ loading, data }, fetchTrolleys] = useRequest(getTrolleysList, {
    onError: setError,
  });

  const [{ data: itemInfos }, fetchItemsInfo] = useRequest(getItemDetails, {
    onError: setError,
  });

  const updateList = async (args) => {
    const t = await fetchTrolleys(args);
    const newItemIds = _.uniq(t?.map((x) => x.item_ids).flat().sort() || []);
    // fetch items only if array is changed
    if (newItemIds.length && (!itemIds.length || !_.isEqual(newItemIds, itemIds))) {
      await fetchItemsInfo({ store, items: newItemIds, user, updateTokens });
    }
    setItemIds([...newItemIds]);
  };

  const getSortIcon = (field) => {
    if (sort.field !== field) {
      return 'sort';
    } else if (sort.direction === 'ASC') {
      return 'arrow1_down';
    } else {
      return 'arrow1_up';
    }
  }

  const updateSort = (field) => {
    const nextSort = { field };
    if (sort.field !== field) {
      nextSort.direction = 'ASC';
    } else if (sort.direction === 'ASC') {
      nextSort.direction = 'DESC';
    } else {
      nextSort.direction = 'ASC';
    }
    setSort(nextSort);
  }

  const updateFilter = (v) => {
    for (const key in v) {
      if (!v[key]) v[key] = null;
    }
    setFilters({ ...filters, ...v });
  }

  const handleTrolleyClick = (trolley) => {
    history.push(`/${localStorage.getItem("store_number")}/ecom-trolley/${trolley.id}`);
  }

  const toggleShowImages = () => setShowImages(!showImages);

  useEffect(() => {
    updateList({ store, filters, sort, user, updateTokens });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, filters, sort]);

  const renderHeader = () => {
    return (
      <thead>
        <tr>
          <th scope="col" className="column-sortable" onClick={() => updateSort('email')}>
            <Trans i18nKey="ecomPacking.teammate">Teammate</Trans>{" "}
            {" "}
            <i className={`sort-icon vtmn-icon_${getSortIcon('email')}`} />
          </th>
          <th scope="col" className="column-sortable" onClick={() => updateSort('min_order_date')}>
            <Trans i18nKey="ecomPacking.orders">Orders</Trans>
            {" "}
            <i className={`sort-icon vtmn-icon_${getSortIcon('min_order_date')}`} />
          </th>
          <th scope="col" className="column-sortable" style={{ width: 180 }} onClick={() => updateSort('create_date')}>
            <Trans i18nKey="ecomPacking.firstPick">First pick</Trans>{" "}
            {" "}
            <i className={`sort-icon vtmn-icon_${getSortIcon('create_date')}`} />
          </th>
          <th scope="col" className="column-sortable" style={{ width: 180 }} onClick={() => updateSort('last_pick_date')}>
            <Trans i18nKey="ecomPacking.lastPick">Last pick</Trans>{" "}
            {" "}
            <i className={`sort-icon vtmn-icon_${getSortIcon('last_pick_date')}`} />
          </th>
        </tr>
      </thead>
    )
  }

  const renderRow = (trolley) => {
    const items = (itemInfos || []).filter((x) => trolley.item_ids.includes(x.item_id))
    return (
      <>
        <tr key={trolley.id} onClick={() => handleTrolleyClick(trolley)}>
          <td>{trolley.email}</td>
          <td>{trolley.order_ids.map((x) => <span key={x} className="tag tag-sm">{x}</span>)}</td>
          <td className="text-right">{dateFormat(trolley.create_date, "dd/mm/yyyy HH:MM:ss")}</td>
          <td className="text-right">{dateFormat(trolley.last_pick_date, "dd/mm/yyyy HH:MM:ss")}</td>
        </tr>
        {showImages && (
          <tr onClick={() => handleTrolleyClick(trolley)}>
            <td colSpan={NUMBER_OF_COL} className="ecom-packing-table-thumbs">
              {items.length ? (
                <div className="ecom-packing-table-thumbs-content">
                  <ImageThumbs
                    value={items.map((item) => {
                      if (item.pixlId) {
                        return `https://contents.mediadecathlon.com/p${item.pixlId}/a.jpg?f=100x100`;
                      }
                      return defaultImage;
                    })}
                    size={50}
                    qty={8}
                  />
                </div>
              ) : null}
            </td>
          </tr>
        )}
      </>
    )
  }

  return (
    <WithSidebar user={user} updateTokens={updateTokens} logOut={logOut}>
      <div className = "jumbotron container">
        <h2 className="text-center">
          <Trans i18nKey="ecomPacking.title">Ecom packing</Trans>
        </h2>
        {error && (
          <ErrorMessage error={error} onClose={() => setError(null)} />
        )}
        <div className="ecom-packing-header">
          <button
            type="button"
            id="buttonShowImages"
            className="vtmn-btn vtmn-btn_size--small vtmn-btn_variant--secondary vtmn-btn_with-only-icon mr-2"
            onClick={toggleShowImages}
            disabled={!data || !data.length}
          >
            <img
              className="img-icon"
              alt="showImages"
              src={showImages ? '/icons/image_off_outline.svg' : '/icons/image_outline.svg'}
            />
          </button>
          <button
            className={`vtmn-btn vtmn-btn_size--small vtmn-btn_variant--secondary vtmn-btn_with-only-icon ${hasFilters ? 'ecom-filtered-button' : ''}`}
            disabled={loading}
            onClick={() => setFiltersOpen(true)}
          >
            <i className="vtmn-icon_filter2 font-weight-bold m-0" />
            {hasFilters && (<i className="vtmn-icon_tiny_bold_valid subicon ml-2"></i>)}
          </button>
        </div>
        <table className="ecom-packing-table dataTable table table-bordered table-striped table-hover">
          {renderHeader()}
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={NUMBER_OF_COL}>
                  <Loader />
                </td>
              </tr>
            ) : data ? (
              data.map(renderRow)
            ) : (
              <tr>
                <td colSpan={NUMBER_OF_COL}>
                  <Loader />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <FiltersModal
          show={filtersOpen}
          onClose={() => setFiltersOpen(false)}
          onClear={clearFilters}
          onReset={resetFilters}
          filters={filters}
          updateFilter={updateFilter}
        />
    </WithSidebar>
  );
}

const EcomPackingWithState = (props) => {
  const {
    showImages,
    setShowImages,
    filters,
    hasFilters,
    sort,
    setFilters,
    setSort,
    resetFilters,
    clearFilters,
  } = useEcomPackingState();
  return (
    <EcomPacking
      showImages={showImages}
      setShowImages={setShowImages}
      filters={filters}
      hasFilters={hasFilters}
      sort={sort}
      setFilters={setFilters}
      setSort={setSort}
      resetFilters={resetFilters}
      clearFilters={clearFilters}
      {...props}
    />
  );
}

export default withTranslation()(EcomPackingWithState);
