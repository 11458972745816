import React, { PureComponent } from 'react';
import { Pie } from 'react-chartjs-2';
import { withTranslation, Trans } from 'react-i18next';
import ItemsReport from './ItemsReport';
import OrdersReport from './OrdersReport';
import WithSidebar from '../WithSidebar';
import Loader from '../../components/Loader';
import fetchWithJWT from '../../functions/fetchWithJWT';
import '../../stylesheets/report.css';

const serverUrl = process.env.REACT_APP_SERVERURL;

class Report extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      error: "",
      freeRate: 0,
      activeTab: "items",
      stockOrdersEnabled: false,
      itemStorageEnabled: false,
      lockersEnabled: false,
      selfpickupEnabled: false,
    };
  }

  // retrieve the stock list of this store
  componentDidMount(){
    Promise.all([
      this.getReserveStatus(),
      this.getEmptyAddresses(),
    ])
    .then(() => this.setState({ loading: false }));
  }

  // calculate the free rate of this reserve
  calculateFreeRate(addressesRatio){
    this.setState ({
      ...this.state,
      freeRate : Math.round( 100 * addressesRatio.empty_count / addressesRatio.address_count )
    });
  }

  getEmptyAddresses = () => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/${this.props.match.params.store}/addresses/empty_ratio`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(response => response.json())
    .then(result => this.calculateFreeRate(result))
    .catch(error => {
      console.warn(error);
    });
  }

  getReserveStatus = () => {
    const reserve_id = localStorage.getItem("reserve_id");
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/stores/${reserve_id}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(result => result.json())
    .then(details => {
      const stockOrdersEnabled = details && details.text && details.text.stock_orders_enabled;
      const itemStorageEnabled = details && details.text && details.text.item_storage_enabled;
      const lockersEnabled = details && details.text && details.text.lockers_enabled;
      const selfpickupEnabled = details && details.text && details.text.selfpickup_enabled;
      this.setState({
        stockOrdersEnabled,
        itemStorageEnabled,
        lockersEnabled,
        selfpickupEnabled,
        activeTab: stockOrdersEnabled ? "orders" : "items",
      });
    })
    .catch((e) => {
      console.warn('Error at getReserveStatus', e);
    });
  }

  renderChart(){
    const { t } = this.props;
    const data = {
      labels: [
        t('report.atLeast1', 'At least one item % '),
        t('report.empty', 'Empty % '),
      ],
      datasets: [{
        data: [100 - this.state.freeRate, this.state.freeRate],
        backgroundColor: [
          '#ff4d4d',
          '#00cc00'
        ],
        hoverBackgroundColor: [
          '#ff8080',
          '#1aff1a'
        ]
      }]
    };
    return(
      <div className="pie">
        <Pie data={data}
            width={120}
            height={60}
            legend={null}
            options={{
              maintainAspectRatio: false
            }}
          />
      </div>
    );
  }

  renderTabs = () => {
    const { activeTab } = this.state;
    const isItemsTab = activeTab === "items";
    const isOrdersTab = activeTab === "orders";
    return (
      <div
        className="btn-group btn-block mb-3"
        role="group"
        style={{
          paddingLeft: '2px',
          paddingRight: '2px',
        }}
      >
        <button
          type="button"
          className={`tab-button col m-0 btn ${isOrdersTab ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={()=> this.setState({ activeTab: "orders" })}
        >
          <Trans i18nKey="report.ordersTab">Orders</Trans>
        </button>
        <button
          type="button"
          className={`tab-button col m-0 btn ${isItemsTab ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={()=> this.setState({ activeTab: "items" })}
        >
          <Trans i18nKey="report.itemsTab">Items</Trans>
        </button>
      </div>
    );
  }

  render() {
    const {
      activeTab,
      stockOrdersEnabled,
      itemStorageEnabled,
      lockersEnabled,
      selfpickupEnabled,
    } = this.state;
    const isItemsTab = activeTab === "items";
    const isOrdersTab = activeTab === "orders";
    return(
      <WithSidebar user={this.props.user} updateTokens={this.props.updateTokens} logOut={this.props.logOut}>
        <div className = "stockReport jumbotron container">
          {this.state.loading && <Loader/>}
          {!this.state.loading && (
            <div>
              <h2 className="text-center">
                <Trans i18nKey="report.report">Stock report</Trans>
              </h2>
              {this.renderChart()}
              <h6 className="text-center">
                <em>
                  <Trans i18nKey="report.emptyAddresses">Empty addresses</Trans>
                  {' : '}
                  {this.state.freeRate}
                  {' %'}
                </em>
              </h6>
              {stockOrdersEnabled && itemStorageEnabled && this.renderTabs()}
              {itemStorageEnabled && (
                <ItemsReport
                  isActive={isItemsTab}
                  store={this.props.match.params.store}
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                />
              )}
              {stockOrdersEnabled && (
                <OrdersReport
                  isActive={isOrdersTab}
                  store={this.props.match.params.store}
                  user={this.props.user}
                  updateTokens={this.props.updateTokens}
                  selfpickupEnabled={selfpickupEnabled}
                  lockersEnabled={lockersEnabled}
                />
              )}
            </div>
          )}
        </div>
      </WithSidebar>
    )
  }
}


export default withTranslation()(Report);
