import React, { PureComponent } from 'react';
import { getActiveTrack } from './utils';

const TARGET_ZOOM_LEVEL_COUNT = 10;

export class ScannerControls extends PureComponent {
  state = {
    zoom: 1,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isRunning && !this.props.isRunning) {
      this.setState({
        zoom: 1,
      });
    }
  }

  getButtonZoomStep = (min, max, step) => {
    const multiplyer = Math.floor((max - min) / (step * TARGET_ZOOM_LEVEL_COUNT));
    if (multiplyer < 1) {
      return step;
    }
    const buttonZoomStep = step * multiplyer;
    return buttonZoomStep;
  }

  onZoomIn = (e) => {
    const { zoom } = this.state;
    const { zoomOpts, video } = this.props;
    const { min, max, step } = zoomOpts;
    const track = getActiveTrack(video);
    if (track && typeof track.getCapabilities === 'function') {
      if (zoom >= max) {
        return max;
      }
      const buttonZoomStep = this.getButtonZoomStep(min, max, step);
      let nextZoom;
      if (zoom + buttonZoomStep > max) {
        nextZoom = max;
      } else {
        nextZoom = zoom + buttonZoomStep;
      }
      this.setState({ zoom: nextZoom });
      track.applyConstraints({ advanced: [{zoom: nextZoom}] });
    }
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  onZoomOut = (e) => {
    const { zoom } = this.state;
    const { zoomOpts, video } = this.props;
    const { min, max, step } = zoomOpts;
    const track = getActiveTrack(video);
    if (track && typeof track.getCapabilities === 'function') {
      if (zoom <= min) {
        return min;
      }
      const buttonZoomStep = this.getButtonZoomStep(min, max, step);
      let nextZoom;
      if (zoom - buttonZoomStep < min) {
        nextZoom = min;
      } else {
        nextZoom = zoom - buttonZoomStep;
      }
      this.setState({ zoom: nextZoom });
      track.applyConstraints({ advanced: [{zoom: nextZoom}] });
    }
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  render() {
    const { zoom } = this.state;
    const {
      torch,
      isRunning,
      canTorch,
      canZoom,
      canToggleCamera,
      zoomOpts,
      frameMode,
      onChangeFrameMode,
      onToggleCamera,
      onToggleTorch,
    } = this.props;
    return (
      <div className="scanner-container-controls">
        {isRunning && (
          <div className="btn-group btn-group-toggle">
            <button
              className={`btn btn-md btn-primary ${frameMode === 'qrcode' ? 'active' : ''}`}
              onClick={() => onChangeFrameMode('qrcode')}
            >
              <img className="d-block" src="/icons/frame_qr.svg" alt="frame-qr" />
            </button>
            <button
              className={`btn btn-md btn-primary ${frameMode === 'barcode' ? 'active' : ''}`}
              onClick={() => onChangeFrameMode('barcode')}
            >
              <img className="d-block" src="/icons/frame_bar.svg" alt="frame-bar" />
            </button>
            <button
              className={`btn btn-md btn-primary ${frameMode === 'off' ? 'active' : ''}`}
              onClick={() => onChangeFrameMode('off')}
            >
              <img className="d-block" src="/icons/frame_off.svg" alt="frame-off" />
            </button>
          </div>
        )}
        {isRunning && canToggleCamera && (
          <button
            className="btn btn-md btn-primary"
            onClick={onToggleCamera}
          >
            <i className="vtmn-icon_360"></i>
          </button>
        )}
        {isRunning && canTorch && (
          <button
            className="btn btn-md btn-primary"
            onClick={onToggleTorch}
          >
            <i className={torch ? "vtmn-icon_eye_off" : "vtmn-icon_eye_on"}></i>
          </button>
        )}
        {isRunning && canZoom && (
          <button
            className="btn btn-md btn-primary"
            disabled={zoom >= zoomOpts.max}
            onClick={this.onZoomIn}
          >
            <i className="vtmn-icon_edit_plus"></i>
          </button>
        )}
        {isRunning && canZoom && (
          <button
            className="btn btn-md btn-primary"
            disabled={zoom <= zoomOpts.min}
            onClick={this.onZoomOut}
          >
            <i className="vtmn-icon_edit_minus"></i>
          </button>
        )}
      </div>
    );
  }
}

export default ScannerControls;
