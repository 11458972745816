import React from 'react';
import { Trans } from 'react-i18next';
import { OrderStockRow } from '../Orders/OrderStock';

const OrderStock = ({
  stockOrders,
  address,
  isLocker,
  onPickOrder,
  onMoveOrder,
  onRemoveOrder,
  onBackToSale,
  onPreviewItems,
  onPreviewBarcode,
  disabled,
}) => {
  return (
    <div className="mobile-table-wrapper order-stock-content">
      {stockOrders.length === 0 ? (
        address === 'content' ? null : (
          <div className="p-2">
            <Trans i18nKey="address.noStockOrders">No stock orders</Trans>
          </div>
        )
      ) : (
        stockOrders.map((order) => {
          const isCancelled =
            order.status === 'CANCELLED' ||
            order.parcel_status === 'CANCELLED' ||
            order.parcel_status === 'REJECTED';
          const isCompleted = order.status === 'COMPLETED' || order.parcel_status === 'FULFILLED';
          const canBackToSale = order.parcel_status === 'CANCELLED' && order.shipping_method !== 'IN_STORE_PREPARE_AND_PICKUP';
          return (
            <OrderStockRow
              displayOrderNumber
              isCancelled={isCancelled}
              isCompleted={isCompleted}
              canBackToSale={canBackToSale}
              mountStatus={order.mount_status}
              key={order.id}
              items={order.items && order.items.length ? order.items : []}
              row={{
                ...order,
                parcelStatus: order.parcel_status,
                disabled,
              }}
              onPick={() => onPickOrder(order)}
              onMove={() => onMoveOrder(order)}
              onRemove={() => onRemoveOrder(order)}
              onBackToSale={() => onBackToSale(order)}
              onPreviewItems={onPreviewItems}
              onPreviewBarcode={
                isLocker
                  ? () => onPreviewBarcode(order.shipperbox_number || order.shipping_group_number)
                  : null
              }
            />
          );
        })
      )}
    </div>
  );
};

export default OrderStock;
