import React, { useState, useEffect, useRef, useCallback } from 'react';
import _ from 'lodash';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import dateFormat from 'dateformat';
import { useTranslation, Trans } from 'react-i18next';
import WithSidebar from '../WithSidebar';
import ScannerButton from '../../components/widgets/scanner/ScannerButton';
import ZXScanner from '../../components/widgets/scanner/ZXScanner';
import ButtonBadge from '../../components/widgets/ButtonBadge';
import SearchByOrder from './SearchByOrder';
import SearchByCustomer from './SearchByCustomer';
import {
  ConfirmPickOrder,
  ConfirmPickAllOrder,
  ConfirmRemoveOrder,
} from './ConfirmActions';
import ModalFoundMissing from './ModalFoundMissing';
import ModalCompleteMovement from './ModalCompleteMovement';
import OrderStock from './OrderStock';
import OrderMissing from './OrderMissing';
import OrderComments from './OrderComments';
import DeactivationError from './DeactivationError';
import ModalBackToSale from './ModalBackToSale';
import BarcodeModal from '../../components/widgets/BarcodeModal';
import ImageReactModal from '../../components/widgets/ImageReactModal';
import {
  OrderStatusWarning,
  OrderError,
  OrderInfoMessage,
  OrderAddingError,
  FreeOrderLinkMessage,
  MultiparcelMessages,
} from './messages';
import { OrderContentModal, ParcelContentModal } from './ParcelContent';
import { OrderRelatedNoPackMessage } from '../../components/widgets/RelatedSgippingGroupMessage';
import Loader from '../../components/Loader';
import useRequest from '../../functions/useRequest';
import { logBarcodeReadEvent } from '../../functions/analytics';
import {
  infoIsError,
  getOrder,
  addOrder,
  pickOrder,
} from './api';
import '../../stylesheets/orders.css';
import ItemToMountSwitch from '../OrdersMount/components/ItemToMountSwitch';
import { isNeedMount } from '../OrdersMount/helpers';
import { OrderToMountMessage } from '../TrackingNumber/messages';
import { useStoreInfoReduxState } from '../../store/storeInfo';
import { OrderParcels, getOrderParcels } from './AddressingParcels';

const cancelledChecker = (status, sgStatus, parcelStatus) => {
  return (
    ['CANCELLED', 'REJECTED'].includes(parcelStatus) ||
    sgStatus === 'CANCELLED' ||
    status === 'CANCELLED'
  );
};

const completedChecker = (status, sgStatus, parcelStatus) => {
  return parcelStatus === 'FULFILLED' || sgStatus === 'COMPLETED' || status === 'COMPLETED';
};

const checkCanBackToSale = (orderInfo) => (row) => {
  for (const sg of orderInfo.shipping_groups || []) {
    if (sg.shippingMethod !== 'IN_STORE_PREPARE_AND_PICKUP') {
      if (row.packaging_type === 'parcel') {
        const parcel = (sg.parcels || []).find((x) => x.tracking_number === row.tracking_number);
        if (parcel && parcel.status === 'CANCELLED') {
          return true;
        }
      }
      if (row.packaging_type === 'no-packaging') {
        const parcel = (sg.parcels || []).find((x) => x.box_tracking_number === row.tracking_number);
        if (parcel && parcel.status === 'CANCELLED') {
          return true;
        }
      }
    }
  }
  return false
};

const checkStatus = (checker, orderInfo) => (row) => {
  for (const sg of orderInfo.shipping_groups || []) {
    if (row.packaging_type === 'parcel') {
      const parcel = (sg.parcels || []).find((x) => x.tracking_number === row.tracking_number);
      if (parcel) {
        return checker(orderInfo.status, sg.status, parcel.status);
      }
    }
    if (row.packaging_type === 'no-packaging') {
      const parcel = (sg.parcels || []).find((x) => x.box_tracking_number === row.tracking_number);
      if (parcel) {
        return checker(orderInfo.status, sg.status, parcel.status);
      }
    }
    if (sg.id === row.shipping_group_number) {
      return checker(orderInfo.status, sg.status, null);
    }
  }
  return checker(orderInfo.status, null, null);
};

const OrderSearch = ({ onSelectOrder, user, updateTokens, searchInputRef }) => {
  const [searchByCustomer, setSearchByCustomer] = useState(false);
  const orderScannerRef = useRef();

  return (
    <>
      {searchByCustomer ? (
        <SearchByCustomer
          onSelectOrder={onSelectOrder}
          user={user}
          updateTokens={updateTokens}
        />
      ) : (
        <SearchByOrder
          onSelectOrder={onSelectOrder}
          orderScannerRef={orderScannerRef}
          user={user}
          updateTokens={updateTokens}
          searchInputRef={searchInputRef}
        />
      )}
      <div className="row">
        <p className="text">
          <Trans i18nKey="orders.searchByOrder">Search by order</Trans>
        </p>
        <label className="switch switch-sm">
          <input
            type="checkbox"
            onChange={() => setSearchByCustomer(!searchByCustomer)}
            checked={searchByCustomer}
          />
          <span className="slider round"></span>
        </label>
        <p className="text">
          <Trans i18nKey="orders.searchByCustomer">by customer</Trans>
        </p>
      </div>
      <ZXScanner
        readingType="order"
        ref={orderScannerRef}
        onDetected={(val) => onSelectOrder(val)}
      />
    </>
  );
};

const OrderDetails = ({ order, onComments, onPreviewOrderItems }) => {
  const orderDate =
    order.info &&
    order.info.creationDate &&
    dateFormat(new Date(order.info.creationDate), 'dd/mm/yyyy HH:MM:ss');
  const hasInfo = order.info && !infoIsError(order.info);
  const orderText = hasInfo
    ? order.info.order_number || order.info.shipping_group_numbers?.[0]
    : order.code;
  const inStock = order.stock && order.stock.length > 0;
  return (
    <div className="mb-3">
      <h2 className="text-center mb-0">
        <Trans i18nKey="orders.order">Order</Trans> {orderText}
      </h2>
      {hasInfo && (
        <>
          <p className="text-center mb-0">
            {order.info.firstName} {order.info.lastName} {order.info.email}
          </p>
          <p className="text-center mb-0">
            <Trans i18nKey="orders.orderDate">Order date:</Trans> {orderDate}
          </p>
        </>
      )}
      {!inStock && (
        <p className="text-center mb-0">
          <Trans i18nKey="orders.notInStock">Order is not in stock</Trans>
        </p>
      )}
      {!!order?.info?.order_number && (
        <div className="text-center">
          <Button
            size="sm"
            variant="primary"
            onClick={onComments}
          >
            <Trans i18nKey="comments.comments">Comments</Trans>
            {order.commentsCount ? <ButtonBadge value={order.commentsCount} /> : null}
          </Button>
          <Button
            size="sm"
            variant="secondary"
            className="icon-button"
            onClick={onPreviewOrderItems}
          >
            <i className="vtmn-icon_eye_on"></i>
            <Trans i18nKey="orders.details">Details</Trans>
          </Button>
        </div>
      )}
    </div>
  );
};

const AddForm = ({
  address,
  setAddress,
  parcelNumber,
  setParcelNumber,
  isTomount,
  setIsTomount,
  isOrderToMount,
  mountStatus,
  onAdd,
  setIsScan,
  toMountActive,
  source,
}) => {
  const addressScannerRef = useRef();
  const { t } = useTranslation();
  return (
    <form className="form-inline flex-column mb-3 mt-3">
      <div className="form-group col row mb-1">
        <input
          id="HomeAddress"
          type="text"
          className="form-control col rbt-input-main"
          placeholder={t('orders.address', 'Address')}
          value={address}
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="none"
          onChange={(event) => setAddress(event.target.value)}
        />
        <ScannerButton scannerRef={addressScannerRef} />
        {source !== 'stores_fulfiller_api' && (
          <input
            id="ParcelNumber"
            type="text"
            className="form-control col-2 rbt-input-main"
            placeholder="1/1"
            value={parcelNumber}
            onChange={(event) => setParcelNumber(event.target.value)}
          />
        )}
        <button
          id="AddStockButton"
          type="button"
          className="btn btn-success"
          onClick={() => onAdd()}
        >
          <Trans i18nKey="shared.ok">OK</Trans>
        </button>
      </div>
      <div className="form-group col row align-items-center">
        <ItemToMountSwitch
          active={toMountActive}
          isTomount={isTomount}
          setIsTomount={setIsTomount}
          isOrderToMount={isOrderToMount}
          mountStatus={mountStatus}
        />
      </div>
      <ZXScanner
        readingType="address"
        ref={addressScannerRef}
        onDetected={(addressValue) => {
          setAddress(addressValue);
          setIsScan(true);
          document.getElementById('ParcelNumber')?.focus();
        }}
      />
    </form>
  );
};

const useUrlParam = (name) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  if (params.has(name)) {
    const param = params.get(name);
    return param;
  }
  return null;
};

const Orders = ({ user, updateTokens, logOut }) => {
  const { t } = useTranslation();
  const { store } = useParams();
  const history = useHistory();
  const { store: storeInfo } = useStoreInfoReduxState();
  const searchInputRef = useRef();
  const [order, setOrder] = useState(null);
  const [address, setAddress] = useState('');
  const [parcelNumber, setParcelNumber] = useState('');
  const [isTomount, setIsTomount] = useState(false);
  const [errors, setErrors] = useState(null);
  const [deactivations, setDeactivations] = useState(null);
  const [addingError, setAddingError] = useState(null);
  const [pickingOpts, setPickingOpts] = useState(null);
  const [foundMissingOrderItem, setFoundMissingOrderItem] = useState(null);
  const [confirmPickShow, setConfirmPickShow] = useState(false);
  const [confirmPickAllShow, setConfirmPickAllShow] = useState(false);
  const [confirmMoveShow, setConfirmMoveShow] = useState(false);
  const [confirmRemoveShow, setConfirmRemoveShow] = useState(false);
  const [modalFoundShow, setModalFoundShow] = useState(false);
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [backToSaleSG, setBackToSaleSG] = useState(null);
  const [barcodePreview, setBarcodePreview] = useState(null);
  const [itemsExpanded, setItemsExpanded] = useState(false);
  const [selectedStockItem, setSelectedStockItem] = useState(null);
  const [previewItems, setPreviewItems] = useState(null);
  const [isScan, setIsScan] = useState(false);
  const [massPickLoading, setMassPickLoading] = useState(false);
  const urlParamOrder = useUrlParam('order');

  const info = order?.info || {};
  const hasNoPack = 'shipping_groups' in info && !!info.shipping_groups.find((x) => x.noPack);
  const hasClassic = 'shipping_groups' in info && !!info.shipping_groups.find((x) => !x.noPack);
  const mixedNoPackClassic = hasNoPack && hasClassic;
  const isOrderToMount = order?.is_order_tomount;
  const mountStatus = order?.mount_status;
  const containerParcels = getOrderParcels(
    order?.codeFormat === 'tracking_number' ? order.code : null,
    info,
    order?.stock,
  );

  const canPickAll = useCallback((address) => {
    if (!order?.stock) {
      return false;
    }
    const ordersToPick = order.stock.filter((ord) => {
      const matchedAddress = ord.address === address;
      const isCancelled = checkStatus(cancelledChecker, order?.info || {})(ord);
      return !isCancelled && matchedAddress;
    });
    const canPick = ordersToPick.length > 0;
    return canPick;
  }, [order?.stock, order?.info]);

  useEffect(() => {
    if (urlParamOrder) {
      fetchOrder({
        store,
        orderCode: urlParamOrder,
        user,
        updateTokens,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParamOrder]);

  useEffect(() => {
    if (isScan) {
      onAdd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScan]);

  const [{ loading: orderLoading }, fetchOrder] = useRequest(getOrder, {
    onError: (e) => setErrors([e]),
    onSuccess: (order) => {
      const isNopackToRedirect = order?.is_nopack_to_redirect;
      if (isNopackToRedirect) {
        history.push(`/${store}/trackingNumbers/${order.code}`);
      }

      setOrder(order);
      setDeactivations(null);
      setAddingError(null);
      setAddress('');
      setParcelNumber('');
      const needMount = isNeedMount(order?.mount_status, order?.is_order_tomount);
      setIsTomount(needMount);
      setIsScan(false);
      const infoError = order && order.info && infoIsError(order.info) ? order.info : null;
      if (infoError) {
        const err = new Error(infoError.text);
        err.code = infoError.code;
        err.isInfoError = true;
        setErrors([err]);
      } else {
        setErrors(null);
      }
    },
  });

  const reloadOrder = () =>
    fetchOrder({
      store,
      orderCode: order.code,
      user,
      updateTokens,
    });

  const [{ loading: isPicking }, doPick] = useRequest(pickOrder, {
    onError: (e) => reloadOrder().then(() => setErrors([e])),
    onSuccess: (res) => reloadOrder().then(() => setDeactivations([res?.deactivation])),
  });

  const [{ loading: isAdding }, doAdd] = useRequest(addOrder, {
    onError: (e) => {
      const currentAddress = address;
      const isAddressNotFound = e.response && e.response.address && e.response.exists === false;
      if (isAddressNotFound) {
        logBarcodeReadEvent({
          success: false,
          readingType: 'address',
          value: address,
        });
      }
      return reloadOrder().then(() => {
        setAddingError(e);
        setAddress(currentAddress);
      });
    },
    onSuccess: (res) => {
      logBarcodeReadEvent({
        success: true,
        readingType: 'address',
        value: address,
      });
      if (res?.stock?.address_type === 'locker') {
        setBarcodePreview(res.stock.barcode_preview);
      }
      return reloadOrder();
    },
  });

  const onAdd = () =>
    doAdd({
      store,
      shipping_group_number: order.codeFormat === 'shipping_group_number' ? order.code : null,
      tracking_number: order.codeFormat === 'tracking_number' ? order.code : null,
      address,
      parcel_number: info.source === 'stores_fulfiller_api' ? order.code : parcelNumber || '1/1',
      is_tomount: isTomount,
      user,
      updateTokens,
    });

  const loading =
    orderLoading ||
    isPicking ||
    isAdding ||
    massPickLoading;

  const IsWrongStoreMessage = ({ info }) => (
    <div
      className="alert alert-danger fade show"
      role="alert"
      id="orderStatusError"
    >
      <h4>
        <Trans i18nKey="shared.warning">Warning</Trans>
      </h4>
      <p>
        {t('orders.inTheWrongStore', {
          defaultValue:
            'This order is in the wrong store. This order should be in {{storeName}}, with the store code: {{storeId}}. Please contact the customer service.',
          storeName: info.shipping_groups[0].storeName,
          storeId: info.shipping_groups[0].storeId,
        })}
      </p>
    </div>
  );

  return (
    <WithSidebar
      user={user}
      updateTokens={updateTokens}
      logOut={logOut}
    >
      <div className="jumbotron container">
        {order ? (
          <OrderDetails
            order={order}
            onComments={() => setCommentsOpen(true)}
            onPreviewOrderItems={() => setItemsExpanded(true)}
          />
        ) : (
          <h2 className="text-center">
            <Trans i18nKey="orders.orders">Orders</Trans>
          </h2>
        )}
        <OrderSearch
          user={user}
          updateTokens={updateTokens}
          searchInputRef={searchInputRef}
          onSelectOrder={async (value) => {
            if (!value) {
              return;
            }
            await fetchOrder({
              store,
              orderCode: value,
              user,
              updateTokens,
            });
          }}
        />
        {!loading && !mountStatus && !!isOrderToMount && <OrderToMountMessage />}
        {!loading && mixedNoPackClassic && (
          <OrderRelatedNoPackMessage
            info={info}
            onClick={(sgNumber) => {
              const sg = info.shipping_groups.find(
                (x) => x.noPack && x.id === sgNumber && x.trackingNumbers?.length,
              );
              if (!sg) return;
              // first not shipperbox TN
              const tn = sg.trackingNumbers.find((x) => !x.startsWith('99000'));
              if (!tn) return;
              history.push(`/${store}/trackingNumbers/${tn}`);
            }}
          />
        )}
        {!loading && <OrderInfoMessage order={order} />}
        {!loading && order?.info?.is_wrong_store && <IsWrongStoreMessage info={order.info} />}
        {!loading && <FreeOrderLinkMessage freeOrderLink={order?.freeOrderLink} />}
        {!loading && (
          <OrderError
            errors={errors}
            onClose={() => setErrors(null)}
          />
        )}
        {!loading && (
          <OrderStatusWarning
            order={order}
            onBackToSale={() => {
              const sg = (order.info?.shipping_groups || []).find(x => x.id === order.code || x.trackingNumbers?.includes(order.code));
              if (sg && sg.id) {
                setBackToSaleSG(sg.id);
              }
            }}
          />
        )}
        {!loading && (
          <DeactivationError
            deactivations={deactivations}
            onClose={() => setDeactivations(null)}
          />
        )}
        {loading && <Loader />}
        {!loading && order && (
          <OrderStock
            checkCompleted={checkStatus(completedChecker, info)}
            checkCancelled={checkStatus(cancelledChecker, info)}
            checkCanBackToSale={checkCanBackToSale(info)}
            mountStatus={mountStatus}
            items={_.isArray(order?.info?.items) ? order.info.items : []}
            stock={order.stock || []}
            customerTag={order?.info?.customerTag}
            shippingGroups={order?.info?.shipping_groups || []}
            onPick={({ id }) => {
              setPickingOpts({ id });
              setConfirmPickShow(true);
            }}
            canPickAll={canPickAll}
            onPickAll={({ address }) => {
              setPickingOpts({ address });
              setConfirmPickAllShow(true);
            }}
            onMove={({ id }) => {
              setPickingOpts({ id });
              setConfirmMoveShow(true);
            }}
            onRemove={({ id }) => {
              setPickingOpts({ id });
              setConfirmRemoveShow(true);
            }}
            onBackToSale={({ sg }) => {
              setBackToSaleSG(sg);
            }}
            onPreviewItems={(items, row) => {
              setPreviewItems(items);
            }}
            onPreviewBarcode={setBarcodePreview}
          />
        )}
        {!loading && order && (
          <OrderMissing
            items={_.isArray(order?.info?.items) ? order.info.items : []}
            shippingGroups={order?.info?.shipping_groups || []}
            missing={order.missing || []}
            onFound={(item) => {
              setFoundMissingOrderItem(item);
              setModalFoundShow(true);
            }}
            onPreviewItems={(items, row) => {
              setPreviewItems(items);
            }}
          />
        )}
        {!loading && <MultiparcelMessages order={order} />}
        {!loading && <OrderParcels containers={containerParcels} onBackToSale={setBackToSaleSG} />}
        {!loading && order && order.canAddress && !order?.info?.is_wrong_store && (
          <>
            <AddForm
              address={address}
              setAddress={setAddress}
              parcelNumber={parcelNumber}
              setParcelNumber={setParcelNumber}
              isTomount={isTomount}
              setIsTomount={setIsTomount}
              isOrderToMount={isOrderToMount}
              mountStatus={mountStatus}
              onAdd={onAdd}
              setIsScan={setIsScan}
              toMountActive={storeInfo?.to_mount_enabled}
              source={info.source}
            />
            <OrderAddingError
              error={addingError}
              onClose={() => setAddingError(null)}
            />
          </>
        )}
      </div>
      <ConfirmPickOrder
        show={confirmPickShow}
        onHide={() => setConfirmPickShow(false)}
        onConfirm={() => {
          setConfirmPickShow(false);
          return doPick({
            store,
            order_number: order.code,
            id: pickingOpts?.id,
            user,
            updateTokens,
            to_customer: true,
          });
        }}
      />
      <ConfirmPickAllOrder
        show={confirmPickAllShow}
        address={pickingOpts?.address}
        onHide={() => setConfirmPickAllShow(false)}
        onConfirm={async () => {
          setConfirmPickAllShow(false);
          setMassPickLoading(true);
          const ordersToPick = order.stock.filter((ord) => {
            const matchedAddress = ord.address === pickingOpts.address;
            const isCancelled = checkStatus(cancelledChecker, info)(ord);
            return !isCancelled && matchedAddress;
          });
          const errorsArray = [];
          const deactivationsArray = [];
          for (const item of ordersToPick) {
            if (item.id) {
              try {
                const res = await pickOrder({
                  store,
                  order_number: order.code,
                  to_customer: true,
                  id: item.id,
                  user,
                  updateTokens,
                });
                if (res?.deactivation) {
                  res.deactivation.item_id = item.item_id;
                  deactivationsArray.push(res.deactivation);
                }
              } catch (e) {
                e.item_id = item.item_id;
                errorsArray.push(e);
              }
            }
          }
          await reloadOrder();
          if (errorsArray.length === 0) {
            setErrors(null);
          } else {
            setErrors(errorsArray);
          }
          if (deactivationsArray.length === 0) {
            setDeactivations(null);
          } else {
            setDeactivations(deactivationsArray);
          }
          setMassPickLoading(false);
        }}
      />
      <ConfirmRemoveOrder
        show={confirmRemoveShow}
        onHide={() => setConfirmRemoveShow(false)}
        onConfirm={() => {
          setConfirmRemoveShow(false);
          return doPick({
            store,
            order_number: order.code,
            id: pickingOpts?.id,
            user,
            updateTokens,
            to_customer: false,
          });
        }}
      />
      <ModalFoundMissing
        show={modalFoundShow}
        onClose={() => setModalFoundShow(false)}
        store={store}
        missingOrderItem={foundMissingOrderItem}
        user={user}
        updateTokens={updateTokens}
        onSuccess={reloadOrder}
      />
      <OrderComments
        isOpen={commentsOpen}
        onClose={() => {
          setCommentsOpen(false);
          reloadOrder();
        }}
        store={store}
        orderNumber={order?.info?.order_number}
      />
      <ModalCompleteMovement
        show={confirmMoveShow}
        onClose={() => setConfirmMoveShow(false)}
        stockOrderId={pickingOpts?.id}
        orderNumber={order?.info?.order_number}
        store={store}
        user={user}
        updateTokens={updateTokens}
        onSuccess={reloadOrder}
      />
      <ModalBackToSale
        store={store}
        order={order}
        show={!!backToSaleSG}
        sg={backToSaleSG}
        onClose={() => setBackToSaleSG(null)}
        onSuccess={() => {
          setBackToSaleSG(null);
          reloadOrder();
        }}
        user={user}
        updateTokens={updateTokens}
      />
      <ParcelContentModal
        show={!!previewItems}
        onClose={() => setPreviewItems(null)}
        onPreview={(item) => {
          setSelectedStockItem(item);
        }}
        orderNumber={order?.info?.order_number}
        mountStatus={mountStatus}
        customer={{
          email: order?.info?.email,
          phoneNumber: order?.info?.phoneNumber,
          firstName: order?.info?.firstName,
          lastName: order?.info?.lastName,
          friendPickupName: order?.info?.friendPickupName,
        }}
        items={previewItems}
        shippingGroups={_.isArray(order?.info?.shipping_groups) ? order?.info?.shipping_groups : []}
        showTrackingNumber
      />
      <OrderContentModal
        show={itemsExpanded}
        onClose={() => setItemsExpanded(false)}
        onPreview={(item) => {
          setSelectedStockItem(item);
        }}
        orderNumber={order?.info?.order_number}
        mountStatus={mountStatus}
        customer={{
          email: order?.info?.email,
          phoneNumber: order?.info?.phoneNumber,
          firstName: order?.info?.firstName,
          lastName: order?.info?.lastName,
          friendPickupName: order?.info?.friendPickupName,
        }}
        mixedNoPackSg={
          mixedNoPackClassic && _.isArray(order?.info?.shipping_groups)
            ? order.info.shipping_groups.filter((x) => x.noPack).map((x) => x.id)
            : []
        }
        shippingGroups={_.isArray(order?.info?.shipping_groups) ? order?.info?.shipping_groups : []}
        items={_.isArray(order?.info?.items) ? order.info.items : []}
      />
      <ImageReactModal
        show={!!selectedStockItem}
        onHide={() => setSelectedStockItem(null)}
        imageUrl={selectedStockItem?.image}
        item={selectedStockItem?.item_id}
        item_description={selectedStockItem?.item_description}
      />
      <BarcodeModal
        code={barcodePreview}
        show={!!barcodePreview}
        onHide={() => setBarcodePreview(null)}
      />
    </WithSidebar>
  );
};

export default Orders;
