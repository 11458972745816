import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import Tabs from './Tabs';
import Items from './Items';
import Orders from './Orders';
import TrackingNumbers from './TrackingNumbers';
import WithSidebar from '../WithSidebar';
import Loader from '../../components/Loader';
import { handleApiResponse } from '../../functions/handleApiResponse';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';

const getReserveStatus = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const reserve_id = localStorage.getItem("reserve_id");
  const url = `${process.env.REACT_APP_SERVERURL}/v1/stores/${reserve_id}`
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse)
  .then(resp => ({
    ordersEnabled: resp.text && (resp.text.stock_orders_enabled || resp.text.ecom_orders_enabled),
    trackingNumbersEnabled: resp.text && resp.text.tracking_numbers_enabled,
    itemStorageEnabled: resp.text && resp.text.item_storage_enabled
  }));
}

const MovementsHistory = ({ user, updateTokens, logOut }) => {
  const [activeTab, setActiveTab] = useState('items');
  const [{
    data: reserveStatus,
    loading,
  }, fetchReserveStatus] = useRequest(getReserveStatus, {
    onSuccess: ({ ordersEnabled }) => {
      if (ordersEnabled) {
        setActiveTab('orders');
      }
    }
  });
  useEffect(() => {
    fetchReserveStatus(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isItemsTab = activeTab === "items";
  const isOrdersTab = activeTab === "orders";
  const isTrakingNumbersTab = activeTab === "tracking-numbers";
  const { ordersEnabled, trackingNumbersEnabled, itemStorageEnabled } = reserveStatus || {};
  const tabCount = 0 + ordersEnabled + itemStorageEnabled + trackingNumbersEnabled;
  return (
    <WithSidebar user={user} updateTokens={updateTokens} logOut={logOut}>
      <div className="jumbotron container wide-container">
        <h2 className="text-center">
          <Trans i18nKey="movementsHistory.movementsHistory">History of movements</Trans>
        </h2>
        {loading && <Loader/>}
        {!loading && (
          <>
            {tabCount > 1 && (
              <Tabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                ordersEnabled={ordersEnabled}
                trackingNumbersEnabled={trackingNumbersEnabled}
                itemStorageEnabled={itemStorageEnabled}
              />
            )}
            {itemStorageEnabled && <Items isActive={isItemsTab} user={user} updateTokens={updateTokens} />}
            {ordersEnabled && <Orders isActive={isOrdersTab} user={user} updateTokens={updateTokens} />}
            {trackingNumbersEnabled && <TrackingNumbers isActive={isTrakingNumbersTab} user={user} updateTokens={updateTokens} />}
          </>
        )}
      </div>
    </WithSidebar>
  )
}

export default MovementsHistory;
