import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import WithSidebar from './WithSidebar';
import _ from "lodash";
import fetchWithJWT from '../functions/fetchWithJWT';
import '../stylesheets/dashboard.css';
import blankFlag from "../stylesheets/blankFlag.gif";

const serverUrl = process.env.REACT_APP_SERVERURL;

class DashBoard extends Component {

  constructor(props){
    super(props);
    this.state = {
      dashBoard: [],
      loading: true,
      sort: "desc",
      sortField: null,
    }
  }

  // ---------------------------------
  // retrieve the dashboard to display
  // ---------------------------------
  componentDidMount(){
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/dashboard`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(response => response.json())
    .then(result => {
      this.setState({
        dashBoard : result,
        loading : false
      });
    })
    .catch(error => {
        console.warn(error);
    });
  }

  // --------------
  // sort the table
  // --------------
  sortColumn = (column) => {
    let sortedTable = this.state.dashBoard;

    if (this.state.sort === "asc"){
      sortedTable = _.orderBy(this.state.dashBoard, [column], ['desc']);
      this.setState({ sort : "desc", sortField: column });
    } else if (this.state.sort === "desc") {
      sortedTable = _.orderBy(this.state.dashBoard, [column], ['asc']);
      this.setState({ sort : "asc", sortField: column });
    }

    this.setState({ dashBoard : sortedTable })
  }

  getSortIcon = (field) => {
    const { sort, sortField } = this.state;
    let sortIcon = 'sort';
    if (sortField === field) {
      if (sort === 'asc') {
        sortIcon = 'arrow1_down';
      } else {
        sortIcon = 'arrow1_up';
      }
    }
    return sortIcon;
  }

  // -----------------------
  // insert a dashboard line
  // -----------------------
  insertRow(line, index) {
    const flagClass = `flag flag-${line.country_code.toLowerCase()}`;
    return (
      <tr key={index}>
        <td className="to-center">{line.store_number} - {line.description}</td>
        <td className="to-center">
          <div className="country-flag-wrapper">
            <div className="country-flag">
              <img src={blankFlag} className={flagClass} alt={line.country_code} />
            </div>
          </div>
        </td>
        <td className="to-center">{line.address_count}</td>
        <td className="to-center">{line.item_qty}</td>
        <td className="to-center">{line.item_count}</td>
        <td className="to-center">{line.stock_orders_count}</td>
      </tr>
    )
  }

  getTotal = (param) => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    if (this.state.dashBoard.length === 0){
      return 0;
    }
    else {
      return (this.state.dashBoard.map(e => e[param])).reduce(reducer);
    }
  }

  render(){
    return(
      <WithSidebar user={this.props.user} updateTokens={this.props.updateTokens} logOut={this.props.logOut}>
      <div className = "jumbotron container dashboard" style={{position:"relative"}}>
        <h2 className="text-center">
          <Trans i18nKey="dashboard.dashboard">Dashboard</Trans>
        </h2>
        { this.state.loading ? <i className="vtmn-icon_hourglass" style={{position:"absolute",top:"10px",right:"10px"}}></i> : null}

        <table className="table table-hover text-center">
          <thead>
            <tr className="title-row">
              <th className="column-sortable border-bottom-0 pb-0" onClick={() => this.sortColumn('store_number')}>
                <div className="datatable-title-wraper">
                  <Trans i18nKey="dashboard.store">Store</Trans>
                </div>
                <i className={`sort-icon vtmn-icon_${this.getSortIcon('store_number')}`} aria-hidden="true" />
              </th>
              <th className="column-sortable border-bottom-0 pb-0" onClick={() => this.sortColumn('country_code')}>
                <div className="datatable-title-wraper">
                  <Trans i18nKey="dashboard.country">Country</Trans>
                </div>
                <i className={`sort-icon vtmn-icon_${this.getSortIcon('country_code')}`} aria-hidden="true" />
              </th>
              <th className="column-sortable border-bottom-0 pb-0" onClick={() => this.sortColumn('address_count')}>
                <div className="datatable-title-wraper">
                  <Trans i18nKey="dashboard.qtyAddresses">Qty addresses</Trans>
                </div>
                <i className={`sort-icon vtmn-icon_${this.getSortIcon('address_count')}`} aria-hidden="true" />
              </th>
              <th className="column-sortable border-bottom-0 pb-0" onClick={() => this.sortColumn('item_qty')}>
                <div className="datatable-title-wraper">
                  <Trans i18nKey="dashboard.qtyItems">Qty items</Trans>
                </div>
                <i className={`sort-icon vtmn-icon_${this.getSortIcon('item_qty')}`} aria-hidden="true" />
              </th>
              <th className="column-sortable border-bottom-0 pb-0" onClick={() => this.sortColumn('item_count')}>
                <div className="datatable-title-wraper">
                  <Trans i18nKey="dashboard.qtyRef">Qty ref</Trans>
                </div>
                <i className={`sort-icon vtmn-icon_${this.getSortIcon('item_count')}`} aria-hidden="true" />
              </th>
              <th className="column-sortable border-bottom-0 pb-0" onClick={() => this.sortColumn('stock_orders_count')}>
                <div className="datatable-title-wraper">
                  <Trans i18nKey="dashboard.qtyOrders">Qty orders</Trans>
                </div>
                <i className={`sort-icon vtmn-icon_${this.getSortIcon('stock_orders_count')}`} aria-hidden="true" />
              </th>
            </tr>
            <tr className="subtitle-row">
              <th className="border-top-0 pt-0" onClick={() => this.sortColumn('store_number')}></th>
              <th className="border-top-0 pt-0" onClick={() => this.sortColumn('country_code')}></th>
              <th className="border-top-0 pt-0" onClick={() => this.sortColumn('address_count')}>
                <div className="font-weight-light">
                  <Trans i18nKey="dashboard.total">Total</Trans>
                  {' '}
                  {this.getTotal("address_count")}
                </div>
              </th>
              <th className="border-top-0 pt-0" onClick={() => this.sortColumn('item_qty')}>
                <div className="font-weight-light">
                  <Trans i18nKey="dashboard.total">Total</Trans>
                  {' '}
                  {this.getTotal("item_qty")}
                </div>
              </th>
              <th className="border-top-0 pt-0" onClick={() => this.sortColumn('item_count')}>
                <div className="font-weight-light">
                  <Trans i18nKey="dashboard.total">Total</Trans>
                  {' '}
                  {this.getTotal("item_count")}
                </div>
              </th>
              <th className="border-top-0 pt-0" onClick={() => this.sortColumn('stock_orders_count')}>
                <div className="font-weight-light">
                  <Trans i18nKey="dashboard.total">Total</Trans>
                  {' '}
                  {this.getTotal("stock_orders_count")}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            { this.state.dashBoard.map( (line,index) => this.insertRow(line,index) ) }
          </tbody>
        </table>

      </div>
      </WithSidebar>

    );
  }
}


export default DashBoard;
