import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import '../../stylesheets/numberField.css';

const NumberField = ({
  value,
  minVal,
  maxVal,
  onChange,
}) => {
  const [textVal, setTextVal] = useState(_.isNil(value) ? '' : String(value));
  useEffect(() => {
    setTextVal(_.isNil(value) ? '' : String(value))
  }, [value])
  const onMinus = () => {
    const nextVal = (Number(textVal) || 0) - 1;
    setTextVal(String(nextVal));
    onChange(nextVal);
  };
  const onPlus = () => {
    const nextVal = (Number(textVal) || 0) + 1;
    setTextVal(String(nextVal));
    onChange(nextVal);
  };
  const onEdit = (e) => {
    const numVal = Number(e.target.value.replace(/[^\d]/g, ''));
    let nextVal = (numVal || numVal === 0) ? numVal : null;
    if (minVal && nextVal < minVal) {
      nextVal = minVal;
    }
    if (maxVal && nextVal > maxVal) {
      nextVal = maxVal;
    }
    setTextVal(_.isNil(nextVal) ? '' : String(nextVal));
    return onChange(nextVal)
  };
  return (
    <InputGroup className="number-field d-inline-flex">
      <InputGroup.Prepend>
        <Button
          className="m-0"
          onClick={onMinus}
          disabled={(Number(value) || 0) <= minVal}
        >
          -
        </Button>
      </InputGroup.Prepend>
      <FormControl
        type="number"
        value={textVal}
        onChange={onEdit}
        className="m-0 number-field-input"
      />
      <InputGroup.Append>
        <Button
          className="m-0"
          onClick={onPlus}
          disabled={(Number(value) || 0) >= maxVal}
        >
          +
        </Button>
      </InputGroup.Append>
    </InputGroup>
  )
};

export default NumberField;
