import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SearchSelectFilter from './SearchSelectFilter';
import { handleApiResponse } from '../../../functions/handleApiResponse';
import fetchWithJWT from '../../../functions/fetchWithJWT';

const getItems = (store, user, updateTokens) => (query) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/items/stores/${store}/query/${query}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const ItemFilter = ({
  user,
  updateTokens,
  store,
  controlId,
  label,
  value,
  onChange
}) => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState();
  useEffect(() => {
    if (!value) {
      // onClearAll filters
      setSelectedItem();
    }
  }, [value]);

  return (
    <SearchSelectFilter
      controlId={controlId}
      label={label}
      labelKey={(item) => `${item.id} - ${item.item_description}`}
      fetcher={getItems(store, user, updateTokens)}
      selected={selectedItem ? [selectedItem] : []}
      onChange={(item) => {
        setSelectedItem(item);
        onChange(item && item.id);
      }}
      placeholder={t('filters.itemFilter.enterItem', 'Enter item code/name...')}
    />
  )
}

export default ItemFilter;
