import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { PacmanLoader } from 'react-spinners';
import { Trans } from 'react-i18next'
import { isMobile } from 'react-device-detect';
import { Modal, Button } from 'react-bootstrap';
import { generateRandomString56, generateCodeChallengeFromVerifier } from '../functions/pkce';
import fetchWithJWT from '../functions/fetchWithJWT';
import { useAppInstalled } from '../functions/useAppInstalled';

const serverUrl = process.env.REACT_APP_SERVERURL;
const FedId_ClientId = process.env.REACT_APP_FedId_ClientId;
const FedId_URL = process.env.REACT_APP_FedId_URL;
const redirect_uri = process.env.REACT_APP_Redirect_URI;

const PreferAppModal = ({ isOpen, onClose }) => {
  const appInstalled = useAppInstalled();
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      className="mobile-modal-dialog"
      aria-label='Mobile modal'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="preferApp.title">Experience the Best with Our Mobile App</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src="/mobileappPL.png" width="100%" alt="" /> <br /> <br />
        <p>
          <Trans i18nKey="preferApp.description">
            We've noticed you're accessing our platform via a web browser.
            For the best experience, we recommend using our dedicated mobile application.
            It's faster, smoother, and delivers a superior user experience.
          </Trans>
        </p>

        {appInstalled ? (
          <>
            <p>
              <Trans i18nKey="preferApp.installed">
                You can open it pressing the logo below and enjoy an improved experience.
              </Trans>
            </p>
            <a href="productlocator://">
              <img src="/app_icon.png" width={153} alt="Product locator" />
            </a>
          </>
        ) : (
          <>
            <p>
              <Trans i18nKey="preferApp.download">
                Download the app today on your favorite app store and enjoy an improved experience.
              </Trans>
            </p>
            <a href="https://play.google.com/store/apps/details?id=com.decathlon.productlocator">
              <img src="/MDM.png" width={153} alt="MDM" />
            </a>
          </>
        )}

      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={onClose}>
          <Trans i18nKey="shared.close">Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  )
};

export default class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      store_number: null,
      stockOrdersEnabled: false,
      itemStorageEnabled: false,
      reserve_id: localStorage.getItem("reserve_id"),
      isModalOpen: false,
    }
  }

  componentDidMount() {
    if (isMobile) {
      this.checkPreferAppModal();
    }
  }

  checkPreferAppModal = () => {
    const lastCloseTime = localStorage.getItem("lastCloseTime");
    if (lastCloseTime) {
      const now = new Date();
      const lastCloseDate = new Date(Number(lastCloseTime));
      const oneWeek = 7 * 24 * 60 * 60 * 1000;
      if (now.getTime() - lastCloseDate.getTime() >= oneWeek) {
        this.setState({ isModalOpen: true });
      }
    } else {
      this.setState({ isModalOpen: true })
    }
  }

  handleCloseModal = () => {
    this.setState({ isModalOpen: false })
    const now = new Date();
    localStorage.setItem("lastCloseTime", now.getTime().toString());
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.user.isLoggedIn && this.props.user.isLoggedIn) {
      if (localStorage.getItem("reserve_id") !== null) {
        return this.getStoreNumber(localStorage.getItem("reserve_id"));
      }
    }
  }

  connectToFedid = async () => {
    const state = generateRandomString56();
    const codeVerifier = generateRandomString56();
    sessionStorage.setItem(`login-code-verifier-${state}`, codeVerifier);
    const codeChallenge = await generateCodeChallengeFromVerifier(codeVerifier);
    let authUrl = `${FedId_URL}?response_type=code&client_id=${FedId_ClientId}&state=${state}&redirect_uri=${redirect_uri}&code_challenge=${codeChallenge}&code_challenge_method=S256&scope=profile openid`;
    if (this.props.reauthenticate) {
      authUrl += '&prompt=login';
    }
    window.location.replace(authUrl);
  }

  getStoreNumber = (reserve_id) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate, hasAuthError } = user;
    if (!token || hasAuthError) {
      return;
    }
    return fetchWithJWT(`${serverUrl}/v1/stores/${reserve_id}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
      .then(result => result.json())
      .then(result => {
        if (result.code === '403') {
          localStorage.removeItem('reserve_id')
          this.setState({ reserve_id: null })
        }
        localStorage.setItem("store_number", result.text.store_number.toString())//for report and sidebar
        this.setState({
          store_number: result.text.store_number.toString(),
          stockOrdersEnabled: result.text.stock_orders_enabled,
          itemStorageEnabled: result.text.item_storage_enabled,
        })
      })
      .catch(error => {
        console.warn(`Error while fetching store numer fore reserve_id ${this.state.reserve_id}`);
      })
  }

  waitForStoreNumber = () => {
    const { store_number, stockOrdersEnabled, itemStorageEnabled } = this.state;

    if (store_number === null) {
      return (
        <div className="login-loader">
          <PacmanLoader
            color={'#0082C3'}
          />
        </div>
      )
    } else {
      let route = `/${store_number}/home`;
      if (!itemStorageEnabled) {
        if (stockOrdersEnabled) {
          route = `/${store_number}/orders`;
        } else {
          route = `/${store_number}/searchAddress`;
        }
      }
      return (
        <Redirect to={route} />
      )
    }
  }

  declareUser = (email) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate, hasAuthError } = user;
    if (!email || !token || hasAuthError) {
      return;
    }
    return fetchWithJWT(`${serverUrl}/v1/users/${email}`, { //create user if he does not exist
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      method: "POST"
    })
      .catch(error => {
        console.warn(`Error while posting user ${email} : ${error}`);
      })
  }

  render() {
    const { isLoggedIn, hasAuthError, email, isLoggingIn } = this.props.user;
    if (isLoggingIn) {
      return (
        <div>
          <div className="login-loader">
            <PacmanLoader
              color={'#0082C3'}
            />
          </div>
        </div>
      );
    }
    if (isLoggedIn && !hasAuthError) {
      this.declareUser(email);
      if (localStorage.getItem("reserve_id") === null) {
        return (
          <div>
            <Redirect to={"/changeStore"} />
          </div>
        )
      } else {
        return (
          <div>
            {this.waitForStoreNumber()}
          </div>
        )
      }
    } else {
      return (
        <div className="jumbotron container" style={{ textAlign: "center" }} >
          <PreferAppModal
            isOpen={this.state.isModalOpen}
            onClose={this.handleCloseModal}
          />
          <img src="/logo_decat.png" alt="Logo Decathlon" />
          <br />
          <h3 className="text-center" style={{ marginTop: "10vh" }}>
            {' '}
            <Trans i18nKey="login.welcome">Welcome to Product Locator</Trans>
            {' '}
          </h3>
          <br />
          <img src="/logo_google_pointer.png" alt="Logo Google Pointer" height="100" />

          <div className="row d-flex flex-column align-items-center justify-content-center" style={{ marginTop: "10vh" }}>
            {hasAuthError && (
              <div className="alert alert-danger alert-dismissible fade show" role="alert" id="orderError">
                <Trans i18nKey="login.authError">Authorization error</Trans>
              </div>
            )}
            <button onClick={this.connectToFedid} className="btn btn-outline-primary">
              <Trans i18nKey="login.login">Login</Trans>
            </button>
          </div>
        </div>
      );
    }
  }
}
