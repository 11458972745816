export const DEFAULT_RELEASE_BASE = {
  version_code: '',
  version: '',
  summary: '',
  has_breaking_changes: false,
};

export const DEFAULT_RELEASE_CHANGELOG = {
  title: null,
  description: null,
  image_url: null,
};

export const RELEASE_TYPE_OPTIONS = [
  { id: 'app', label: 'App' },
  { id: 'api', label: 'Api' },
];
