import React from "react";
import { Modal } from "react-bootstrap";
import { Trans } from 'react-i18next';
import useRequest from "../../functions/useRequest";
import handleApiResponse, { disableMaintenance } from "../../functions/handleApiResponse";
import Loader from "../Loader";
import fetchWithJWT from '../../functions/fetchWithJWT';
import useInterval, { sleep } from '../../functions/useInterval';

import '../../stylesheets/maintenance.css';
import constructionSvg from '../../images/construction.svg';

const CHECK_TIMEOUT = 2 * 60000; // 2 min
const LOADING_SLEEP = 3000;

const ping = async () => {
  const url = `${process.env.REACT_APP_SERVERURL}/v1/ping`;
  const res = await fetchWithJWT(url, {});
  await sleep(LOADING_SLEEP);
  return handleApiResponse(res);
};

const Maintenance = () => {
  const [{ loading }, checkStatus] = useRequest(ping, {
    onSuccess: disableMaintenance,
  });

  useInterval(() => {
    checkStatus();
  }, CHECK_TIMEOUT);

  return (
    <Modal show backdrop="static" keyboard={false} className="maintenance">
      <Modal.Header>
        <Modal.Title as="h5">
          <Trans i18nKey="maintenance.title">Maintenance mode</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="maintenance-image">
          <img src={constructionSvg} alt="" width="200px" />
        </div>
        <div className="p-4">
          <Trans i18nKey="maintenance.message">
            Our app is currently undergoing scheduled maintenance. We will be back soon.
          </Trans>
        </div>
        {loading && (
          <Loader
            text={
              <Trans i18nKey="maintenance.checking">Checking...</Trans>
            }
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Maintenance;
