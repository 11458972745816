import fetchWithJWT from '../../../functions/fetchWithJWT';
import { handleApiResponse } from '../../../functions/handleApiResponse';
import { getLastEpcDeviceId } from '../../../components/widgets/scanner/utils';

export const getMissing = async (user, updateTokens, id, store) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/problems/missing/${id}`;
  const result = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return result;
};

export const getUnexpected = async (user, updateTokens, id, store) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/problems/unexpected/${id}`;
  const result = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return result;
};

export const resolveProblemMissing = async (user, updateTokens, store, id) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/problems/missing/${id}/resolve`;
  const result = await fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return result;
};

export const resolveProblemUnexpected = async (user, updateTokens, store, id) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/problems/unexpected/${id}/resolve`;
  const result = await fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return result;
};

export const anomalyExchangeCC1H = async (
  user,
  updateTokens,
  {
    id,
    store,
    item_id,
    epc,
  },
) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/problems/missing/${id}/anomaly_exchange_cc1h`;
  const result = await fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({
      epc,
      item_id,
    }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return result;
};

export const anomalyExchange = async (user, updateTokens, store, id) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/problems/missing/${id}/anomaly_exchange`;
  const result = await fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return result;
};

export const assignOrderFromWrongStore = async (
  user,
  updateTokens,
  {
    id,
    store,
    item_id,
    epc,
    address,
    original_store,
    force_same_item_to_address,
    is_tomount,
  },
) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/problems/unexpected/${id}/assign`;
  const result = await fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({
      epc,
      epc_device_type: epc ? 'CAMERA_WEB' : null,
      epc_device_id: epc ? getLastEpcDeviceId() : null,
      item_id,
      address,
      original_store,
      force_same_item_to_address,
      is_tomount,
    }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return result;
};

export const unexpectedBackToSale = async (user, updateTokens, store, id) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/problems/unexpected/${id}/back_to_sale`;
  const result = await fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return result;
};

