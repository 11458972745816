import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Modal, Button, Alert } from "react-bootstrap";
import dateFormat from "dateformat";
import AddressScannerInput from "../../components/widgets/AddressScannerInput";
import RowField from "../../components/widgets/RowField";
import Confirm from "../../components/widgets/Confirm";
import ErrorMessage from "../../components/widgets/errorMessage";
import Loader from "../../components/Loader";
import ButtonBadge from "../../components/widgets/ButtonBadge";
import OrderComments from "../Orders/OrderComments";
import MountStatus from "./components/MountStatus";
import { useUserReduxState } from "../../store/user";
import { normalizeName } from "../../functions/formatText";
import useRequest from "../../functions/useRequest";
import { delMount, getMount, mountOrder } from "./api";
import "../../stylesheets/orderMountItemDialog.css";
import defaultImage from "../../images/default_image.png";

const OrderMountItemPart = ({ part }) => {
  const { t } = useTranslation();
  return (
    <div className="mount-dialog-part">
      <div className="order-mount-list-image-wrap">
        <img
          src={part.item.image || defaultImage}
          alt={part.item.item_description}
          className="order-mount-list-image"
        />
      </div>
      <div className="mount-dialog-part-fields">
        <RowField
          label={t("orderMount.item", "Item")}
          value={`${part.item.item_id} - ${part.item.item_description}`}
        />
        <RowField
          label={t("orders.quantity", "Quantity")}
          value={part.quantity}
        />
        <RowField
          label={t("orderMount.address", "Address")}
          value={part.address || "-"}
        />
        <RowField
          label={t("orderMount.shippingGroup", "Shipping group")}
          value={part.shipping_group}
        />
        {part.tracking_numbers.map((tn, index) => (
          <RowField
            key={index}
            label={t("orderMount.trackingNumber", "Tracking #")}
            value={tn}
          />
        ))}
      </div>
    </div>
  );
};

const ConfirmMount = ({
  show,
  onHide,
  mountStatus,
  defaultAddress,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const { store } = useParams();
  const [address, setAddress] = useState(defaultAddress || "");
  const [validatedAddress, setValidatedAddress] = useState(null);
  const { user, updateTokens } = useUserReduxState();
  useEffect(() => {
    if (show) {
      setAddress(defaultAddress || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="shared.confirm">Confirm</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {mountStatus === 'partialy_received' && (
          <Alert variant="warning">
            <Trans i18nKey="orderMount.partiallyReceivedWarning">
              This order seems to have multiple deliveries, yet not all received at your store
            </Trans>
          </Alert>
        )}
        <div className="p-1">
          <Trans i18nKey="orderMount.mountConfirmation">
            Do you want to mark this order as mounted and notify the customer?
          </Trans>
        </div>
        <label className="mount-dialog-address-label form-label">
          <Trans i18nKey="orderMount.addressToMount">Address to mount</Trans>
          {":"}
        </label>
        <AddressScannerInput
          user={user}
          updateTokens={updateTokens}
          store={store}
          placeholder={t("reviewProblems.inputAddress", "Scan address...")}
          value={address}
          onChange={setAddress}
          validatedAddress={validatedAddress}
          setValidatedAddress={setValidatedAddress}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="primary"
          onClick={() => onConfirm(address)}
          disabled={address && validatedAddress !== address}
        >
          <Trans i18nKey="shared.confirm">Confirm</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const OrderMountItemDialog = ({ show, onHide, orderNumber, onMounted }) => {
  const { t } = useTranslation();
  const [confirmMount, setConfirmMount] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [error, setError] = useState(null);
  const { store } = useParams();
  const { user, updateTokens } = useUserReduxState();
  const [{ loading: fetching, data: mount }, fetchMount] = useRequest(
    getMount,
    {
      onError: setError,
    }
  );
  const [{ loading: mounting }, doMountOrder] = useRequest(mountOrder, {
    onSuccess: onMounted,
    onError: setError,
  });
  const [{ loading: deleting }, doDeleteMount] = useRequest(delMount, {
    onSuccess: onMounted,
    onError: setError,
  });
  const isMounted = mount?.mount_state?.mount_status === "mounted";
  const canRemove = mount?.mount_state?.can_remove;
  const commentsCount = mount?.order?.comments_count;
  const mountStatus = mount?.mount_state?.mount_status;
  const loading = fetching || mounting || deleting;
  useEffect(() => {
    if (show) {
      setError(null);
      fetchMount({ store, order_number: orderNumber, user, updateTokens });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  return (
    <>
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans i18nKey="orderMount.orderToMount">Item to mount</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-0">
          {loading && <Loader />}
          {!loading && error && <ErrorMessage error={error} />}
          {!loading && mount ? (
            <>
              <div className="mount-dialog-order-fields px-1">
                <RowField
                  label={t("shared.order", "Order")}
                  value={
                    <Link
                      to={`/${store}/orders?order=${mount.order.order_number}`}
                    >
                      {mount.order.order_number}
                    </Link>
                  }
                />
                <RowField
                  label={t("orderMount.address", "Address")}
                  value={mount.main_item.address}
                />
                <RowField
                  label={t("shared.customer", "Customer")}
                  value={`${normalizeName(
                    mount.order.customer.firstName
                  )} ${normalizeName(mount.order.customer.lastName)}`}
                />
                <RowField
                  label={t("shared.email", "Email")}
                  value={mount.order.customer.email}
                />
                <RowField
                  label={t("shared.phone", "Phone")}
                  value={mount.order.customer.phoneNumber}
                />
                <RowField
                  label={t("orderMount.promiseDate", "Promise date")}
                  value={
                    mount.main_item.promised_shipping_date
                      ? dateFormat(
                          new Date(mount.main_item.promised_shipping_date),
                          "dd/mm/yyyy HH:MM:ss"
                        )
                      : null
                  }
                />
                <RowField
                  label={t("orderMount.shippingMethod", "Shipping method")}
                  value={mount.main_item.shipping_method}
                />
                <RowField
                  label={t("orderMount.orderStatus", "Order status")}
                  value={mount.order.order_status}
                />
                <RowField
                  label={t("orderMount.mountStatus", "Mount status")}
                  value={<MountStatus status={mountStatus} horizontal />}
                />
              </div>
              <hr className="mx-0" />
              <div className="mount-dialog-main">
                <h4 className="text-center mb-3">
                  <Trans i18nKey="orderMount.itemToMount">Item to mount</Trans>
                </h4>
                <OrderMountItemPart part={mount.main_item} />
              </div>
              {mount.accessories.length > 0 ? (
                <>
                  <hr className="mx-0" />
                  <div className="mount-dialog-accessories">
                    <h4 className="text-center mb-3">
                      <Trans i18nKey="orderMount.accessories">
                        Accessories
                      </Trans>
                    </h4>
                    {mount.accessories.map((part, index) => (
                      <OrderMountItemPart key={index} part={part} />
                    ))}
                  </div>
                </>
              ) : null}
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          {canRemove && (
            <Button
              variant="danger"
              disabled={loading}
              onClick={() => setConfirmDelete(true)}
            >
              <Trans i18nKey="shared.remove">Remove</Trans>
            </Button>
          )}
          <div className="spacer" />
          <Button variant="light" onClick={onHide}>
            <Trans i18nKey="shared.close">Close</Trans>
          </Button>
          <Button variant="light" onClick={() => setShowComments(true)}>
            <Trans i18nKey="comments.comments">Comments</Trans>
            {commentsCount ? <ButtonBadge value={commentsCount} /> : null}
          </Button>
          {!isMounted && (
            <Button
              variant="primary"
              disabled={loading}
              onClick={() => setConfirmMount(true)}
            >
              <Trans i18nKey="orderMount.mount">Mount</Trans>
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Confirm
        show={confirmDelete}
        onHide={() => setConfirmDelete(false)}
        onConfirm={() => {
          setConfirmDelete(false);
          doDeleteMount({
            store,
            order_number: orderNumber,
            user,
            updateTokens,
          });
        }}
        icon={
          <i className="modal-header-icon text-danger vtmn-icon_delete vtmn-icon-22px" />
        }
        body={
          <Trans i18nKey="orderMount.removeConfirmation">
            Do you want to remove this order from mounting list?
          </Trans>
        }
        buttonOpts={{
          variant: "danger",
          text: <Trans i18nKey="shared.remove">Remove</Trans>,
        }}
      />
      <ConfirmMount
        show={confirmMount}
        onHide={() => setConfirmMount(false)}
        defaultAddress={mount?.main_item?.address}
        onConfirm={(address) => {
          setConfirmMount(false);
          doMountOrder({
            store,
            order_number: orderNumber,
            address,
            user,
            updateTokens,
          });
        }}
      />
      <OrderComments
        isOpen={showComments}
        onClose={() => {
          setShowComments(false);
          fetchMount({ store, order_number: orderNumber, user, updateTokens });
        }}
        store={store}
        orderNumber={orderNumber}
      />
    </>
  );
};

export default OrderMountItemDialog;
