import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Alert, Button } from 'react-bootstrap';

export const WrongStoreMessage = ({ problem, missing, step }) =>{
  const isCallCustomerStep = step === 'call';
  const isCC1HStep = step === 'cc1h';
  const isPutBackStep = step === 'putBack';
  return (
    <Alert variant="info" className="mb-0 mt-2">
      <h5>
        <Trans i18nKey="reviewProblems.messages.forWrongStore">Order for the wrong store</Trans>
      </h5>
      {missing ? (
        <ul>
          <li>
            <Trans i18nKey="reviewProblems.messages.forOtherStore">This is order for store:</Trans>
            {' '}
            {problem.shipping_group.storeId}
            {' - '}
            {problem.shipping_group.storeName}
          </li>
          <li>
            <Trans i18nKey="reviewProblems.messages.forOtherStoreAssociated">This is order was associated to tracking number delivered to your store.</Trans>
          </li>
          <li>
            <Trans i18nKey="reviewProblems.messages.forOtherStoreMissing">But it was not delivered.</Trans>
          </li>
          <li>
            <Trans i18nKey="reviewProblems.messages.forOtherStoreHelpDesk">Please contact customer service.</Trans>
          </li>
        </ul>
      ) : (
        <ul>
          {isCallCustomerStep && (
            <>
              <li>
                <Trans i18nKey="reviewProblems.messages.forOtherStore">This is order for store:</Trans>
                {' '}
                {problem.shipping_group.storeId}
                {' - '}
                {problem.shipping_group.storeName}
              </li>
              <li>
                <Trans i18nKey="reviewProblems.messages.forOtherStoreCall">Please call the customer and ask if he is OK to come to the store and pick his order</Trans>
              </li>
            </>
          )}
          {isCC1HStep && (
            <li>
              <Trans i18nKey="reviewProblems.messages.pleaseAssign">Please assign item to address and contact customer service</Trans>
            </li>
          )}
          {isPutBackStep && (
            <li>
              <Trans i18nKey="reviewProblems.messages.canPutInStockAndHelpdesk">Please put it in store stock and contact customer service</Trans>
            </li>
          )}
        </ul>
      )}

    </Alert>
  );
};

export const CanceledMessage = ({ missing }) => (
  <Alert variant="info" className="mb-0 mt-2">
    <h5>
      <Trans i18nKey="reviewProblems.messages.orderCanceled">This order was cancelled</Trans>
    </h5>
    {missing && (
      <div>
        <Trans i18nKey="reviewProblems.messages.helpDesk">Please contact customer service.</Trans>
      </div>
    )}
    {!missing && (
      <div>
        <Trans i18nKey="reviewProblems.messages.canPutInStock">Please put it in store stock</Trans>
      </div>
    )}
  </Alert>
);

export const CompletedMessage = ({ missing }) => (
  <Alert variant="info" className="mb-0 mt-2">
    <h5>
      <Trans i18nKey="reviewProblems.messages.orderCompleted">This order is already completed</Trans>
    </h5>
    {missing && (
      <div>
        <Trans i18nKey="reviewProblems.messages.helpDesk">Please contact customer service.</Trans>
      </div>
    )}
    {!missing && (
      <div>
        <Trans i18nKey="reviewProblems.messages.canPutInStock">Please put it in store stock</Trans>
      </div>
    )}
  </Alert>
);

export const UnexpectedMessage = () => (
  <Alert variant="info" className="mb-0 mt-2">
    <h5>
      <Trans i18nKey="reviewProblems.messages.unexpected">This is an unexpected item</Trans>
    </h5>
    <ul>
      <li>
        <Trans i18nKey="reviewProblems.messages.unexpectedCanPlus">Please use CubeInStore to make an IP+ to add the item to your stock sale. to add the item in stock</Trans>
      </li>
      <li>
        <Trans i18nKey="reviewProblems.messages.unexpectedMoveManually">Then put the product into the shelf</Trans>
      </li>
    </ul>
  </Alert>
);

export const NotInStockMessage = () => (
  <Alert variant="info" className="mb-0 mt-2">
    <h5>
      <Trans i18nKey="reviewProblems.messages.notInStock">This item is not in stock.</Trans>
    </h5>
    <ul>
      <li>
        <Trans i18nKey="reviewProblems.messages.notInStockAnomaly">We will create a free order to deliver this shipping group again</Trans>
      </li>
      <li>
        <Trans i18nKey="reviewProblems.messages.notInStockCall">Please, call the customer and tell, that his order will be delayed</Trans>
      </li>
    </ul>
  </Alert>
);

export const InStockMessage = ({ step }) => {
  const { t } = useTranslation();
  return (
    <Alert variant="info" className="mb-0 mt-2">
      <h5>
        <Trans i18nKey="reviewProblems.messages.inStock">This item is in stock.</Trans>
      </h5>
      <div>
        {step === 'cc1h' && (
          <ul>
            <li>
              <Trans i18nKey="reviewProblems.messages.inStockGoFind">Go and find the item in the store</Trans>
            </li>
          </ul>
        )}
        {step === 'create_order' && (
          <ul>
            <li>
              {t('reviewProblems.messages.inStockCC1h', 'We will create a C&C1h free order from the stock of the store')}
            </li>
            <li>
              <Trans i18nKey="reviewProblems.messages.inStockCallReplaced">Please, call the customer and tell that his original order will be replaced</Trans>
            </li>
            <li>
              {t('reviewProblems.messages.inStockCubeInStore', 'Go to CubeInStore to prepare the order')}
            </li>
            <li>
              {t('reviewProblems.messages.inStockComeBack', "Don't forget to come back to Product Locator to address it after")}
            </li>
          </ul>
        )}
      </div>

    </Alert>
  );
};

export const AnomalyExchangeError = ({ error }) => {
  const [detailsVisible, setDetailsVisible] = useState(false);
  return (
    <Alert variant="danger" className="mb-0 mt-2">
      <h5>
        <Trans i18nKey="reviewProblems.messages.errorTitle">Error occurred</Trans>
      </h5>
      <div>
        <ul>
          <li>
            <Trans i18nKey="reviewProblems.messages.retry">Please try again</Trans>
          </li>
          <li>
            <Trans i18nKey="reviewProblems.messages.orHelpDesk">Or contact customer service and mark problem resolved</Trans>
          </li>
        </ul>
      </div>
      {detailsVisible && (
        <div className="mb-2">
          {error.message}
        </div>
      )}
      <Button variant="danger" onClick={() => setDetailsVisible(!detailsVisible)}>
        {detailsVisible ? '-' : '+'}
        {' '}
        <Trans i18nKey="shared.details">Details</Trans>
      </Button>
    </Alert>
  )
};

export const ManualMissingMessage = () => (
  <Alert variant="info" className="mb-0 mt-2">
    <div>
      <Trans i18nKey="reviewProblems.messages.contactAboutMissing">Please contact the customer service to manage this missing order.</Trans>
    </div>
  </Alert>
);
