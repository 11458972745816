import React, { useState, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { Alert, Badge, Button, Collapse } from 'react-bootstrap';
import { useTrackingNumbersState } from './state';
import { useTrackingNumberReduxState } from '../TrackingNumber/reduxState';
import dateFormat from 'dateformat';
import _ from 'lodash';
import useRequest from '../../functions/useRequest';
import { formatDurationSec } from '../../functions/formatDuration';
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/widgets/errorMessage';
import DaterangeFilter from '../../components/widgets/filters/DaterangeFilter';
import RowField from '../../components/widgets/RowField';
import Tabs from '../../components/widgets/Tabs';
import ButtonBadge from '../../components/widgets/ButtonBadge';

import fetchWithJWT from '../../functions/fetchWithJWT';
import { handleApiResponse } from '../../functions/handleApiResponse';
import WithSidebar from '../WithSidebar';
import SearchByTrackingNumber from './SearchByTrackingNumber';
import '../../stylesheets/trackingNumbers.css';
import packageImage from '../../images/package.png';
import packageImageSuccess from '../../images/package-success.png';
import packageImageDanger from '../../images/package-danger.png';
import packageOpenImage from '../../images/package-open.png';
import packageOpenImageSuccess from '../../images/package-open-success.png';
import packageOpenImageDanger from '../../images/package-open-danger.png';

const getToday = () => {
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  return today;
};

const getTrackingNumberTypeTag = (t, trackingNumberType) => ({
  'heterogeneous': t('trackingNumbers.trackingNumberTypes.heterogeneous', 'heterogeneous'),
  'bicycle': t('trackingNumbers.trackingNumberTypes.bicycle', 'bicycle'),
  'voluminous': t('trackingNumbers.trackingNumberTypes.voluminous', 'voluminous'),
})[trackingNumberType];

const isStartedToday = (today) => (trackingNumber) => {
  if (!trackingNumber.start_date) {
    return false;
  }
  const startDate = new Date(trackingNumber.start_date);
  return startDate > today;
};

const getItemDetailsPage = async ({ store, items, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/items/infos/stores/${store}?item_ids=${items.join(',')}`;
  const itemDetails = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
  return itemDetails;
};

const getItemDetails = async ({ store, items, user, updateTokens }) => {
  const itemChunks = _.chunk(items, 500);
  const details = [];
  for (const itemChunk of itemChunks) {
    const itemDetails = await getItemDetailsPage({ store, items: itemChunk, user, updateTokens });
    details.push(...(itemDetails || []));
  }
  return details;
};

const getShipmentDates = async ({ store, trackingNumbers, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/get_shipment_info`;
  const shipmentDates = await fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({ tracking_numbers: trackingNumbers }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return shipmentDates || [];
};

const getTrackingNumbers = async ({ store, filters, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const queryParams = {};
  if (filters.date_from) {
    queryParams.date_from = filters.date_from.toISOString();
  }
  if (filters.date_to) {
    queryParams.date_to = filters.date_to.toISOString();
  }
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers?${queryString}`;
  const trackingNumbers = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);

  const singleItems = trackingNumbers.filter(x => x.items.length === 1).map(x => x.items).flat();
  const relatedItems = trackingNumbers
      .filter(item => item.related_items && item.related_items.length > 0)
      .map(item => item.related_items)
      .flat();
  const itemDetails = await getItemDetails({
    store,
    items: [...singleItems, ...relatedItems],
    user,
    updateTokens,
  });
  const itemDetailsMap = {};
  for (const item of itemDetails) {
    itemDetailsMap[item.item_id] = item;
  }

  let finished = [];
  let toProcess = [];
  let missing = [];
  let unexpected = [];
  let problemsToProcessCount = 0;

  // eslint-disable-next-line no-unused-vars
  for (const tn of trackingNumbers) {
    if (tn.status === 'finished') {
      finished.push(tn);
      if (tn.missing_to_solve || tn.unexpected_to_solve) {
        toProcess.push(tn);
        problemsToProcessCount += tn.missing_to_solve;
        problemsToProcessCount += tn.unexpected_to_solve;
      }
      if (tn.missing_count) {
        missing.push(tn);
      }
      if (tn.unexpected_count) {
        unexpected.push(tn);
      }
    } else {
      toProcess.push(tn);
    }
  }

  toProcess = _.orderBy(
    toProcess,
    [x => x.missing_to_solve > 0 || x.unexpected_to_solve > 0, 'start_date', 'order_count'],
    ['desc', 'desc', 'desc'],
  );
  finished = _.orderBy(
    finished,
    [x => x.missing_to_solve > 0 || x.unexpected_to_solve > 0, 'start_date'],
    ['desc', 'desc'],
  );
  missing = _.orderBy(
    missing,
    [x => x.missing_to_solve > 0, 'start_date'],
    ['desc', 'desc'],
  );
  unexpected = _.orderBy(
    unexpected,
    [x => x.unexpected_to_solve > 0, 'start_date'],
    ['desc', 'desc'],
  );

  const today = getToday();

  return {
    toProcess,
    finished,
    missing,
    unexpected,
    counts: {
      totalCount: trackingNumbers.length,
      toProcessBadgeCount: toProcess.length,
      finishedBadgeCount: finished.filter(isStartedToday(today)).length,
      missingBadgeCount: missing.filter(x => x.missing_to_solve > 0).length,
      unexpectedBadgeCount: unexpected.filter(x => x.unexpected_to_solve > 0).length,
      problemsToProcessCount,
    },
    itemDetailsMap,
  }
};

const TrackingNumberRow = ({ trackingNumber, shipmentDates, itemDetailsMap, activeTab }) => {
  const { store } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { setActiveTab: setNextActiveTab } = useTrackingNumberReduxState();
  const isNew = trackingNumber.status === 'new';
  const isStarted = trackingNumber.status === 'started';
  const isFinished = trackingNumber.status === 'finished';
  let imageSrc;
  if (isNew) {
    imageSrc = packageImage;
  } else if (isStarted) {
    if (trackingNumber.missing_count || trackingNumber.unexpected_count) {
      imageSrc = packageOpenImageDanger;
    } else if (trackingNumber.stock_count) {
      imageSrc = packageOpenImageSuccess;
    } else {
      imageSrc = packageOpenImage;
    }
  } else if (trackingNumber.missing_to_solve || trackingNumber.unexpected_to_solve) {
    imageSrc = packageImageDanger;
  } else {
    imageSrc = packageImageSuccess;
  }

  const isToProcessTab = activeTab === 'toProcess';
  const isFinishedTab = activeTab === 'finished';
  const isMissingTab = activeTab === 'missing';
  const isUnexpectedTab = activeTab === 'unexpected';

  const showAddressed = isToProcessTab || isFinishedTab;
  const showMissing = !isUnexpectedTab;
  const showUnexpected = !isMissingTab;
  const showTotalToSolve = isFinishedTab;
  const showMissingToSolve = isMissingTab;
  const showUnexpectedToSolve = isUnexpectedTab;

  const isSingleItem = trackingNumber.items.length === 1;
  const itemInfo = itemDetailsMap[trackingNumber.items[0]];
  const pixlId = itemInfo?.pixlId;
  const itemDescription = itemInfo && `${itemInfo.item_id} ${itemInfo.item_description}`;
  const shipmentDate = shipmentDates.find((x) => x.tracking_number === trackingNumber.tracking_number)?.shipment_date || null;

  const onClickTab = (isFinishedTab || isToProcessTab) ? 'toReceive' : activeTab;
  const trackingNumberTypeTag = getTrackingNumberTypeTag(t, trackingNumber.tracking_number_type);

  return (
    <div
      className="tracking-numbers-item d-flex flex-column"
      onClick={() => {
        setNextActiveTab(onClickTab);
        history.push(`/${store}/trackingNumbers/${trackingNumber.tracking_number}`);
      }}
      role="button"
    >
      <div className="d-flex flex-row align-items-center">
        <div className="tracking-numbers-image pl-2 pr-2">
          {(isSingleItem && pixlId
            ? (
              <>
                <img
                  title={itemDescription}
                  src={`https://contents.mediadecathlon.com/p${pixlId}/a.jpg?f=60x60`}
                  alt=""
                />
                <img
                  className="tracking-numbers-image-small-image"
                  src={imageSrc}
                  alt="parcel"
                />
              </>
            )
            : (
              <img
                src={imageSrc}
                alt="parcel"
              />
            )
          )}
        </div>
        <div className="d-flex flex-fill flex-column pr-2">
          <div className="d-flex flex-wrap flex-row-reverse justify-content-between">
            {(
              trackingNumberTypeTag
                ? <Badge variant="secondary">{trackingNumberTypeTag}</Badge>
                : <div className="tracking-numbers-item-spacer"/>
            )}
            <RowField
              inline
              className="tracking-numbers-tracking-number-row-field"
              label={t('trackingNumbers.trackingNumber', 'Tracking #')}
              value={trackingNumber.tracking_number}
            />
          </div>
          {isNew && (
            <div className="d-flex flex-row justify-content-between">
              <RowField
                inline
                label={t('trackingNumbers.orderCount', 'Orders')}
                value={trackingNumber.order_count}
              />
              <RowField
                inline
                label={t('comments.comments', 'Comments')}
                value={(trackingNumber.comments_count || 0) + (trackingNumber.order_comments_count || 0)}
              />
            </div>
          )}
          {!isNew && (
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-column align-items-start justify-content-start">
                <RowField
                  inline
                  label={t('trackingNumbers.startedAt', 'Start')}
                  value={trackingNumber.start_date ? dateFormat(trackingNumber.start_date, 'dd/mm/yyyy HH:MM:ss') : '-'}
                />
                {isFinished && (
                  <RowField
                    inline
                    label={t('trackingNumbers.finishedAt', 'Finish')}
                    value={trackingNumber.finish_date ? dateFormat(trackingNumber.finish_date, 'dd/mm/yyyy HH:MM:ss') : '-'}
                  />
                )}
                <RowField
                  inline
                  label={t('trackingNumbers.itemCount', 'Items')}
                  value={trackingNumber.item_count}
                />
                {showAddressed && (
                  <RowField
                    inline
                    variant={trackingNumber.stock_count ? 'success' : null}
                    label={t('trackingNumbers.addressedItems', 'Addressed')}
                    value={trackingNumber.stock_count}
                  />
                )}
              </div>
              <div className="d-flex flex-column align-items-end justify-content-end">
                <RowField
                  inline
                  label={t('comments.comments', 'Comments')}
                  value={(trackingNumber.comments_count || 0) + (trackingNumber.order_comments_count || 0)}
                />
                {showMissing && (
                  <RowField
                    inline
                    variant={isToProcessTab && trackingNumber.missing_count ? 'danger' : null}
                    label={t('trackingNumbers.missingItems', 'Missing')}
                    value={trackingNumber.missing_count}
                  />
                )}
                {showUnexpected && (
                  <RowField
                    inline
                    variant={isToProcessTab && trackingNumber.unexpected_count ? 'danger' : null}
                    label={t('trackingNumbers.unexpectedItems', 'Unexpected')}
                    value={trackingNumber.unexpected_count}
                  />
                )}
                {showMissingToSolve && (
                  <RowField
                    inline
                    variant={trackingNumber.missing_to_solve ? 'danger' : null}
                    label={t('trackingNumbers.toSolve', 'To solve')}
                    value={trackingNumber.missing_to_solve}
                  />
                )}
                {showUnexpectedToSolve && (
                  <RowField
                    inline
                    variant={trackingNumber.unexpected_to_solve ? 'danger' : null}
                    label={t('trackingNumbers.toSolve', 'To solve')}
                    value={trackingNumber.unexpected_to_solve}
                  />
                )}
                {showTotalToSolve && (
                  <RowField
                    inline
                    variant={
                      (trackingNumber.unexpected_to_solve || trackingNumber.missing_to_solve)
                        ? 'danger'
                        : null
                    }
                    label={t('trackingNumbers.toSolve', 'To solve')}
                    value={trackingNumber.unexpected_to_solve + trackingNumber.missing_to_solve}
                  />
                )}
              </div>
            </div>
          )}
          <RowField
            inline
            label={t('trackingNumbers.shippingAt', 'Shipped on')}
            value={shipmentDate ? dateFormat(shipmentDate, 'dd/mm/yyyy HH:MM:ss') : '-'}
          />
        </div>
      </div>
    </div>
  );
};

const TrackingNumbersContent = ({
  trackingNumbers,
  shipmentDates,
  itemDetailsMap,
  activeTab,
  tabExpanded,
  setTabExpanded,
}) => {
  const { t } = useTranslation();
  let primaryTrackingNumbers = trackingNumbers;
  let moreTrackingNumbers = [];
  let noPrimaryMessage = null;
  let moreText = null;

  if (activeTab === 'finished') {
    const today = getToday();
    [primaryTrackingNumbers, moreTrackingNumbers] = _.partition(trackingNumbers, isStartedToday(today));
    noPrimaryMessage = t('trackingNumbers.notFoundToday', 'No finished tracking numbers found for today');
    moreText = t('trackingNumbers.older', 'Older');
  } else if (activeTab === 'missing') {
    [primaryTrackingNumbers, moreTrackingNumbers] = _.partition(trackingNumbers, x => x.missing_to_solve > 0);
    noPrimaryMessage = t('trackingNumbers.notFoundUnresolved', 'No unresolved tracking numbers found');
    moreText = t('trackingNumbers.resolved', 'Resolved');
  } else if (activeTab === 'unexpected') {
    [primaryTrackingNumbers, moreTrackingNumbers] = _.partition(trackingNumbers, x => x.unexpected_to_solve > 0);
    noPrimaryMessage = t('trackingNumbers.notFoundUnresolved', 'No unresolved tracking numbers found');
    moreText = t('trackingNumbers.resolved', 'Resolved');
  }
  const hasPrimary = !!(primaryTrackingNumbers && primaryTrackingNumbers.length);
  const hasMore = !!(moreTrackingNumbers && moreTrackingNumbers.length);
  return (
    <>
      <div className="tracking-numbers-content">

        {primaryTrackingNumbers.map(trackingNumber => (
          <TrackingNumberRow
            key={trackingNumber.tracking_number}
            trackingNumber={trackingNumber}
            shipmentDates={shipmentDates}
            itemDetailsMap={itemDetailsMap}
            activeTab={activeTab}
          />
        ))}
        {!hasPrimary && hasMore && (
          <Alert variant="info" className="mb-2">
            {noPrimaryMessage}
          </Alert>
        )}
        {hasMore && (
        <>
          <Button
            onClick={() => setTabExpanded(!tabExpanded)}
            aria-expanded={tabExpanded}
            aria-controls={`tracking-numbers-${activeTab}-more`}
            className="icon-button tracking-numbers-more-button m-0 mb-1"
          >
            {moreText}
            <ButtonBadge value={moreTrackingNumbers.length} variant="light"/>
            {tabExpanded ? (
              <i className="vtmn-icon_chevron_up_v2 right"></i>
            ) : (
              <i className="vtmn-icon_chevron_down_v2 right"></i>
            )}
          </Button>
          <Collapse in={tabExpanded}>
            <div id={`tracking-numbers-${activeTab}-more`}>
              {moreTrackingNumbers.map(trackingNumber => (
                <TrackingNumberRow
                  key={trackingNumber.tracking_number}
                  trackingNumber={trackingNumber}
                  shipmentDates={shipmentDates}
                  itemDetailsMap={itemDetailsMap}
                  activeTab={activeTab}
                />
              ))}
            </div>
          </Collapse>
        </>
      )}
      </div>
    </>
  );
};

const TrackingNumbersStatistics = ({ trackingNumbers, problemsToProcessCount }) => {
  const { t } = useTranslation();
  const orderCount = _.sumBy(trackingNumbers, 'order_count');
  const itemCount = _.sumBy(trackingNumbers, 'item_count');
  const assignedItems = _.sumBy(trackingNumbers, 'stock_count');
  const missingItems = _.sumBy(trackingNumbers, 'missing_count');
  const unexpectedItems = _.sumBy(trackingNumbers, 'unexpected_count');
  const durationSec = _.sumBy(trackingNumbers, 'performance_duration_seconds');
  const duration = formatDurationSec(durationSec);

  return (
    <Alert variant="info">
      <div>
        <Trans i18nKey="trackingNumbers.allTrackingsProcessed">All tracking numbers for this dates were processed:</Trans>
      </div>
      <ul>
        <li>
          <Trans i18nKey="trackingNumbers.orderCount">Orders</Trans>
          {': '}
          {orderCount}
        </li>
        <li>
          <Trans i18nKey="trackingNumbers.itemCount">Items</Trans>
          {': '}
          {itemCount}
        </li>
        <li>
          <Trans i18nKey="trackingNumbers.assignedItems">Assigned</Trans>
          {': '}
          {assignedItems}
        </li>
        <li>
          <Trans i18nKey="trackingNumbers.missingItems">Missing</Trans>
          {': '}
          {missingItems}
        </li>
        <li>
          <Trans i18nKey="trackingNumbers.unexpectedItems">Unexpected</Trans>
          {': '}
          {unexpectedItems}
        </li>
        <li>
          <Trans i18nKey="trackingNumbers.duration">Duration</Trans>
          {': '}
          {duration}
        </li>
        {
          problemsToProcessCount
            ? (
              <li className="font-weight-bold text-danger">
                {t('trackingNumbers.problemsToSolve', {
                  defaultValue: 'You still have {{count}} problems to solve',
                  count: problemsToProcessCount,
                })}
              </li>
            )
            : null
        }
      </ul>
    </Alert>
  )
};

const TrackingNumbers = ({ user, updateTokens, logOut }) => {
  const history = useHistory();
  const { store } = useParams();
  const { t } = useTranslation();

  const {
    filters,
    activeTab,
    tabExpanded,
    setFilters,
    setActiveTab,
    setTabExpanded,
  } = useTrackingNumbersState();
  const { setActiveTab: setNextActiveTab } = useTrackingNumberReduxState();
  const [searchFilter, setSearchFilter] = useState('');
  const [{
    loading,
    data: trackingNumbersData,
    error,
  }, fetchTrackingNumbers] = useRequest(getTrackingNumbers);

  const [{
    data: shipmentDates,
    error: shipmentDatesError,
  }, fetchShipentDates] = useRequest(getShipmentDates);
  const {
    counts,
    itemDetailsMap,
  } = trackingNumbersData || {};
  const {
    totalCount,
    toProcessBadgeCount,
    finishedBadgeCount,
    missingBadgeCount,
    unexpectedBadgeCount,
    problemsToProcessCount,
  } = counts || {};
  const allTrackingNumbers = useMemo(() => {
    const {
      toProcess,
      finished,
      missing,
      unexpected,
    } = trackingNumbersData || {};
    return _.flatten([toProcess,
    finished,
    missing,
    unexpected]).filter(Boolean).map((x) => x.tracking_number);
  }, [trackingNumbersData]);
  let trackingNumbers = trackingNumbersData && trackingNumbersData[activeTab];
  if (searchFilter && trackingNumbers) {
    const searchFilterLower = searchFilter.toLowerCase();
    trackingNumbers = trackingNumbers.filter(x => x.tracking_number.toLowerCase().startsWith(searchFilterLower));
  }

  const showStatistics = (
    !loading
    && activeTab === 'toProcess'
    && !!trackingNumbersData?.finished.length
    && (trackingNumbersData?.toProcess || []).filter(x => x.status !== 'finished').length === 0
  );
  const showNoData = !loading && !error && !showStatistics && !totalCount;

  useEffect(() => {
    fetchTrackingNumbers({ store, filters, user, updateTokens });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, filters]);

  useEffect(() => {
    if (store && allTrackingNumbers.length) {
      fetchShipentDates({ trackingNumbers: allTrackingNumbers, store, user, updateTokens });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, allTrackingNumbers]);

  return (
    <WithSidebar user={user} updateTokens={updateTokens} logOut={logOut}>
      <div className="jumbotron container trackingNumbers">
        <h2 className="text-center">
          <Trans i18nKey="trackingNumbers.noPackagingOrders">No-packaging orders</Trans>
        </h2>
        <SearchByTrackingNumber
          onSearch={(trackingNumber) => {
            setNextActiveTab('toProcess');
            history.push(`/${store}/trackingNumbers/${trackingNumber}`);
          }}
          onFilter={setSearchFilter}
        />
        <div className="filter-card d-flex flex-row justify-content-between align-items-end mb-3">
          <DaterangeFilter
            controlId="filter-date"
            label={t('trackingNumbers.date', 'Date')}
            valueFrom={filters.date_from}
            valueTo={filters.date_to}
            onChange={(date_from, date_to) => setFilters({
              date_from,
              date_to,
            })}
          />
        </div>
        <Tabs
          className="tracking-number-tabs"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={[
            {
              code: "toProcess",
              label: (
                <>
                  {t('trackingNumbers.toProcessTab', 'To process')}
                  {toProcessBadgeCount ? <ButtonBadge value={toProcessBadgeCount} variant="yellow"/> : null}
                </>
              ),
            },
            {
              code: "finished",
              label: (
                <>
                  {t('trackingNumbers.finishedTab', 'Finished')}
                  {finishedBadgeCount ? <ButtonBadge value={finishedBadgeCount} variant="secondary"/> : null}
                </>
              ),
            },
            {
              code: "missing",
              label: (
                <>
                  {t('trackingNumbers.missingTab', 'Missing')}
                  {missingBadgeCount ? <ButtonBadge value={missingBadgeCount}/> : null}
                </>
              ),
            },
            {
              code: "unexpected",
              label: (
                <>
                  {t('trackingNumbers.unexpectedTab', 'Unexpected')}
                  {unexpectedBadgeCount ? <ButtonBadge value={unexpectedBadgeCount}/> : null}
                </>
              ),
            },
          ]}
        />
        {loading && <Loader />}
        {!loading && error && <ErrorMessage error={error} />}
        {!!shipmentDatesError && <ErrorMessage error={shipmentDatesError} />}
        {showNoData && <ErrorMessage error={{ message: t('trackingNumbers.notFound', 'No tracking numbers found')}} />}
        {showStatistics && (
          <TrackingNumbersStatistics
            trackingNumbers={trackingNumbersData.finished}
            problemsToProcessCount={problemsToProcessCount}
          />
        )}
        {!loading && !error && trackingNumbers && trackingNumbers.length > 0 && (
          <TrackingNumbersContent
            trackingNumbers={trackingNumbers}
            shipmentDates={shipmentDates || []}
            activeTab={activeTab}
            tabExpanded={tabExpanded}
            setTabExpanded={setTabExpanded}
            itemDetailsMap={itemDetailsMap}
          />
        )}
      </div>
    </WithSidebar>
  );
};

export default TrackingNumbers;
