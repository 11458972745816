import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Modal, Alert, Button } from 'react-bootstrap';
import _ from 'lodash';
import { backToSaleOrder } from './api';
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/widgets/errorMessage';
import useRequest from '../../functions/useRequest';
import { ParcelContentItems } from './ParcelContent';
import ImageReactModal from '../../components/widgets/ImageReactModal';

const ModalBackToSale = ({
  store,
  order,
  onSuccess,
  show,
  sg,
  onClose,
  user,
  updateTokens,
}) => {
  const [selectedStockItem, setSelectedStockItem] = useState(null);
  const [error, setError] = useState(null);
  const [{
    loading,
  }, doBackToSaleOrder] = useRequest(backToSaleOrder, {
    onSuccess,
    onError: setError,
  });
  useEffect(() => {
    if (show) {
      setError(null);
    }
  }, [show]);

  const onBackToSale = () => doBackToSaleOrder({
    store,
    orderNumber: order?.info?.order_number,
    shippingGroup: sg,
    user,
    updateTokens,
  });
  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="orders.putInStock.putInStock">Put in store stock</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pl-0 pr-0 pb-0">
        {loading && <Loader/>}
        {!loading && (
          <>
            <Alert className="ml-3 mr-3" variant="info">
              <h5>
                <Trans i18nKey="orders.putInStock.cancelled">This order was canceled</Trans>
              </h5>
              <ul>
                <li>
                  <Trans i18nKey="orders.putInStock.openAndCheck">Please open the box and chek that all items are present</Trans>
                </li>
                <li>
                  <Trans i18nKey="orders.putInStock.canPut">Please put the items back to the store area</Trans>
                </li>
              </ul>
            </Alert>
            <ParcelContentItems
              items={(_.isArray(order?.info?.items) ? order.info.items : []).filter(x => x.shipping_group_number === sg)}
              onPreview={(item) => {
                setSelectedStockItem(item);
              }}
            />
          </>
        )}
        {!loading && error && <div className="ml-3 mr-3 mt-3"><ErrorMessage error={error} /></div>}

      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onClose}>
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        <Button variant="danger" onClick={onBackToSale} disabled={loading}>
          <Trans i18nKey="orders.putInStock.putInStock">Put in store stock</Trans>
        </Button>
      </Modal.Footer>
      <ImageReactModal
        show={!!selectedStockItem}
        onHide={() => setSelectedStockItem(null)}
        imageUrl={selectedStockItem?.image}
        item={selectedStockItem?.item_id}
        item_description={selectedStockItem?.item_description}
      />
    </Modal>
  );
};

export default ModalBackToSale;
