import React from 'react';
import { Trans } from 'react-i18next';
import MountStatus from '../OrdersMount/components/MountStatus';

const ItemDetails = ({ orderItem }) => {
  if (!orderItem) {
    return null;
  }

  const mountStatus = orderItem?.order?.mount_status;

  return (
    <div className="d-flex align-items-start flex-column flex-sm-row">
      <div className="d-flex flex-column">
        <img
          className="mb-0"
          src={`${orderItem.item.image}?f=200x200`}
          alt=""
          style={{ maxWidth: '100%' }}
        />
        <MountStatus status={mountStatus} />
      </div>
      <div>
        {orderItem.order && (
          <div>
            <div className="text-secondary font-weight-bold">
              <Trans i18nKey="trackingNumber.order">Order</Trans>
              {':'}
            </div>
            <div>{orderItem.order.order_number}</div>
          </div>
        )}
        {orderItem.shipping_group && (
          <div>
            <div className="text-secondary font-weight-bold">
              <Trans i18nKey="trackingNumber.shippingGroup">Shipping group</Trans>
              {':'}
            </div>
            <div>{orderItem.shipping_group.shipping_group_number}</div>
          </div>
        )}
        <div>
          <div className="text-secondary font-weight-bold">
            <Trans i18nKey="trackingNumber.itemCode">Item code</Trans>
            {':'}
          </div>
          <div>{orderItem.item.item_id}</div>
        </div>
        <div>
          <div className="text-secondary font-weight-bold">
            <Trans i18nKey="trackingNumber.description">Description</Trans>
            {':'}
          </div>
          <div>{orderItem.item.item_description}</div>
        </div>
      </div>
    </div>
  );
};

export default ItemDetails;
