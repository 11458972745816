import React from 'react';
import RowField from '../../components/widgets/RowField';
import { useTranslation, Trans } from 'react-i18next';
import { getParcelStatus, humanizeParcelStatus } from './AddressingParcels';

const OrderMissingRow = ({ row, item, onFound, onPreviewItems }) => {
  const { t } = useTranslation();
  return (
    <tr key={row.id}>
        <td className="to-center order-item-image" onClick={() => onPreviewItems([item], row)}>
          {(item.image) && (
            <img
              src={`${item.image}?f=200x200`}
              alt=""
            />
          )}
        </td>
        <td className="to-center">
          <RowField
            label={t('orders.shippingGroup', 'Shipping group')}
            value={row.shipping_group_number}
          />
          {row.parcelStatus && row.source === 'stores_fulfiller_api' && (
            <RowField
              label={t('orders.status', 'Status')}
              value={humanizeParcelStatus(row.parcelStatus)}
            />
          )}
          {row.status && row.source !== 'stores_fulfiller_api' && (
            <RowField
              label={t('orders.status', 'Status')}
              value={row.status}
            />
          )}
          <RowField
            label={t('orders.trackingNumber', 'Tracking number')}
            value={row.tracking_number}
          />
          <RowField
            label={t('orders.item', 'Item')}
            value={`${row.item_id} - ${item.item_description}`}
          />
        </td>
        <td className="text-center to-center">
          <button
            onClick={() => onFound({
              ...row,
              item,
            })}
            className="vtmn-btn vtmn-btn_variant--primary vtmn-btn_size--small pl-2 pr-2"
            style={{ minWidth: '0' }}
          >
            <Trans i18nKey="orders.found">Found</Trans>
          </button>
        </td>
      </tr>
  )
}

const OrderMissing = ({ missing, items, shippingGroups, onFound, onPreviewItems }) => {
  if (!missing || !missing.length) {
    return null;
  }

  return (
    <>
      <h3 className="text-center mt-3">
        <Trans i18nKey="orders.missingItems">Missing items</Trans>
      </h3>
      <div className="mobile-table-wrapper">
        <table id="table" className="table table-hover mt-2 mobile-table">
          <tbody>
            {missing.map(row => {
              const item = items.find(x => (
                x.item_id === row.item_id
                && x.shipping_group_number === row.shipping_group_number
              )) || {};
              const sg = shippingGroups.find(x => (
                x.id === row.shipping_group_number
              ));
              const parcelStatus = getParcelStatus({
                ...row,
                packaging_type: 'no-packaging',
              }, sg);
              return (
                <OrderMissingRow
                  key={row.id}
                  row={{
                    ...row,
                    status: sg?.status,
                    parcelStatus,
                  }}
                  item={item}
                  onFound={onFound}
                  onPreviewItems={onPreviewItems}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  )
};

export default OrderMissing;
