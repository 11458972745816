import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dateFormat from 'dateformat';
import { useTranslation, Trans } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import getQueryString from '../../../functions/getQueryString';
import { handlePaginatedApiResponse } from '../../../functions/handleApiResponse';
import useTableRequest from '../../../functions/useTableRequest';
import Filters from './Filters';
import { DataTable, TableFooter } from '../../../components/widgets/DataTable';
import fetchWithJWT from '../../../functions/fetchWithJWT';
import HelpTooltipIcon from '../../../components/widgets/HelpTooltipIcon';


const getTrackingNumbersReport = (user, updateTokens, { filters, pagination, sort }, store) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  if (formatedFilters.start_date_from) {
    formatedFilters.start_date_from = dateFormat(formatedFilters.start_date_from, "yyyy-mm-dd");
  }
  if (formatedFilters.start_date_to) {
    formatedFilters.start_date_to = dateFormat(formatedFilters.start_date_to, "yyyy-mm-dd");
  }
  if (formatedFilters.finish_date_from) {
    formatedFilters.finish_date_from = dateFormat(formatedFilters.finish_date_from, "yyyy-mm-dd");
  }
  if (formatedFilters.finish_date_to) {
    formatedFilters.finish_date_to = dateFormat(formatedFilters.finish_date_to, "yyyy-mm-dd");
  }
  const queryString = getQueryString({
    filters: formatedFilters,
    pagination,
    sort,
  });
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/history/tracking_numbers?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

const getFields = ({ t }) => [
  {
    field: 'tracking_number',
    title: t('movementsHistory.trackingNumbers.trackingNumber', 'Tracking number'),
    sortable: true
  }, {
    field: 'status',
    title: t('movementsHistory.trackingNumbers.status', 'Status'),
    sortable: true
  }, {
    field: 'start_date',
    title: t('movementsHistory.trackingNumbers.startDate', 'Start date'),
    valueRenderer: (val) => val ? dateFormat(new Date(val), "dd/mm/yyyy HH:MM:ss") : null,
    sortable: true
  }, {
    field: 'finish_date',
    title: t('movementsHistory.trackingNumbers.finishDate', 'Finish date'),
    valueRenderer: (val) => val ? dateFormat(new Date(val), "dd/mm/yyyy HH:MM:ss") : null,
    sortable: true
  }, {
    field: 'duration',
    title: t('movementsHistory.trackingNumbers.duration', 'Duration'),
    sortable: true
  }, {
    field: 'performance_duration',
    title: (
      <div className="order-history-tooltip-title">
        {t('movementsHistory.trackingNumbers.productivityDuration', 'Productivity duration')}
        <HelpTooltipIcon>
          {t('movementsHistory.trackingNumbers.productivityDurationTooltip', 'Duration for calculating productivity, ignoring pauses longer then 1 minute')}
        </HelpTooltipIcon>
      </div>
    ),
    sortable: true
  }, {
    field: 'problems_duration',
    title: (
      <div className="order-history-tooltip-title">
        {t('movementsHistory.trackingNumbers.problemsDuration', 'Problems duration')}
        <HelpTooltipIcon>
          {t('movementsHistory.trackingNumbers.problemsDurationTooltip', 'Time from Tracking # finish till resolving last problem')}
        </HelpTooltipIcon>
      </div>
    ),
    sortable: true
  }, {
    field: 'order_count',
    title: t('movementsHistory.trackingNumbers.orderCount', 'Orders'),
    sortable: true
  }, {
    field: 'item_count',
    title: t('movementsHistory.trackingNumbers.itemCount', 'Items'),
    sortable: true
  }, {
    field: 'addressed_count',
    title: t('movementsHistory.trackingNumbers.addressedCount', 'Addressed'),
    sortable: true
  }, {
    field: 'missing_count',
    title: t('movementsHistory.trackingNumbers.missingCount', 'Missing'),
    sortable: true
  }, {
    field: 'found_count',
    title: t('movementsHistory.trackingNumbers.foundCount', 'Found'),
    sortable: true
  }, {
    field: 'unexpected_count',
    title: t('movementsHistory.trackingNumbers.unexpectedCount', 'Unexpected'),
    sortable: true
  }, {
    field: 'problem_resolved_count',
    title: t('movementsHistory.trackingNumbers.problemResolvedCount', 'Problems resolved'),
    sortable: true
  }, {
    field: 'teammates',
    title: t('movementsHistory.trackingNumbers.teammates', 'Teammates'),
    sortable: true
  },
];

const TrackingNumbers = ({ isActive, user, updateTokens }) => {
  const { t } = useTranslation();
  const { store } = useParams();
  const now = new Date();
  const monthAgo = new Date();
  monthAgo.setDate(monthAgo.getDate() - 28);
  const initialFilters = {
    start_date_from: monthAgo,
    start_date_to: now,
  };
  const [
    {
      loading,
      data: reportData,
      error,
      filters,
      pagination,
      sort,
      totals,
    },
    fetchTrackingNumbersReport,
    setFilters,
    setPagination,
    setSort,
  ] = useTableRequest(getTrackingNumbersReport, { initialState: { filters: initialFilters } });
  const { data, totalsRow } = reportData || {};
  const { productivity } = totalsRow || {};
  useEffect(() => {
    fetchTrackingNumbersReport(user, updateTokens, { filters, pagination, sort }, store);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, filters, pagination, sort, fetchTrackingNumbersReport]);
  if (!isActive) {
    return null;
  }
  return (
    <>
      <Filters
        user={user}
        updateTokens={updateTokens}
        filters={filters}
        totals={totals}
        sort={sort}
        setFilters={setFilters}
        onClear={() => setFilters(initialFilters)}
      />
      {productivity && (
        <Alert variant="info">
          <Alert.Heading>
            <Trans i18nKey="movementsHistory.trackingNumbers.productivity">Productivity</Trans>
          </Alert.Heading>
          {t('movementsHistory.trackingNumbers.productivityMessage', {
            defaultValue: '1 teammate processes 1 quantity in {{count}} seconds average',
            count: productivity.toFixed(0),
          })}
        </Alert>
      )}
      <DataTable
        data={data}
        fields={getFields({ t })}
        loading={loading}
        error={error}
        sort={sort}
        setSort={setSort}
        rowKey="tracking_number"
        summaryRow={{
          ...totalsRow,
          tracking_number: t('movementsHistory.trackingNumbers.grandTotal', 'Grand total'),
        }}
      />
      <TableFooter
        pagination={pagination}
        totals={totals}
        setPagination={setPagination}
      />
    </>
  );
};

export default TrackingNumbers;
