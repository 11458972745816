import fetchWithJWT from "../../functions/fetchWithJWT";
import handleApiResponse from "../../functions/handleApiResponse";

export const getMounts = async ({ store, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/orders_mount`;
  const mounts = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse);
  return mounts;
};

export const getMount = async ({ store, order_number, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/orders_mount/${order_number}`;
  const mount = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse);
  return mount;
};

export const mountOrder = async ({ store, order_number, address, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/orders_mount/${order_number}/mount`;
  const mount = await fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body: JSON.stringify({ address }),
  }).then(handleApiResponse);
  return mount;
};

export const delMount = async ({ store, order_number, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/orders_mount/${order_number}`;
  const mount = await fetchWithJWT(url, {
    method: 'DELETE',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse);
  return mount;
};
