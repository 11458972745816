import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import fetchWithJWT from '../functions/fetchWithJWT';
import '../slider.css';

const serverUrl = process.env.REACT_APP_SERVERURL;

class Pickers extends Component {
  constructor(props){
    super(props);
    this.state = {
      pickerInput: "",
      allPickers: [],
      onlyPickersCanPick: false
    }
  }

  componentDidMount(){
    this.getPickers();
    const reserve_id = localStorage.getItem("reserve_id");
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    fetchWithJWT(`${serverUrl}/v1/stores/${reserve_id}`,{
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(result => result.json())
    .then(result => {
      this.setState({
        onlyPickersCanPick: result.text.view_only_by_default
      })
    })
    .catch(error => {
      console.warn(`Error while searching for reserve details ${reserve_id} : ${error}`);
    })
  }

  declarePicker = (email) => {
    const reserve_id = localStorage.getItem("reserve_id");
    const myBody = {
      email,
    };
    const { t, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/pickers/${reserve_id}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      method: 'POST',
      body: JSON.stringify(myBody)
    })
    .then(result => result.json())
    .then(result => {
      if (result.code === "201"){
        this.setState({pickerInput: ""})
      } else {
        const alertPrefix = t('picker.errorDeclaring', 'Error while declaring picker');
        alert(`${alertPrefix} ${result}`);
        this.setState({pickerInput: ""})
      }
    })
    .then(result => this.getPickers())
    .then(function(){
      document.getElementById(`closeModal`).click();
    })
    .catch(error => {
      console.warn(`Error while declaring new picker ${email} in reserve ${reserve_id} : ${error}`);
    })
  }

  displayRow = (picker) => {
    return(
      <tr key={picker.id}>
        <td>{picker.email}</td>
        <td>
          <button className="btn btn-sm btn-danger" onClick={event => this.removePicker(picker.id)}>
            <i className="vtmn-icon_delete vtmn-icon-22px"></i>
          </button>
        </td>
      </tr>
    )
  }

  removePicker = (picker_id) => {
    const reserve_id = localStorage.getItem("reserve_id");
    const { t, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/pickers/${reserve_id}/${picker_id}`,{
      method:'DELETE',
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
    })
    .then(result => result.json())
    .then(result => {
      if (result.code === "500"){
        const alertPrefix = t('picker.cantDelete', 'Sorry, impossible to delete picker_id');
        alert(`${alertPrefix} ${picker_id}`);
        return result;
      }
    })
    .then(result => this.getPickers())
    .catch(error => {
      console.warn(`Error while removing admin rights for picker_id ${picker_id} : ${error}`);
    })
  }

  getPickers = () => {
    const reserve_id = localStorage.getItem("reserve_id");
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/pickers/${reserve_id}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(response => response.json())
    .then(result => {
      this.setState({allPickers: result.pickers})
    })
    .catch(error => {
      console.warn(`Error while retrieving pickers of reserve ${reserve_id} for profile ${this.state.email} : ${error}`);
    })
  }

  switchPickerOption = (event) => {
    const myBody = {
      email: this.props.user.email
    }
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    fetchWithJWT(`${serverUrl}/v1/${this.props.store}/settings/pickers`,{
      method:'PATCH',
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      body: JSON.stringify(myBody)
    })
    .then(result => {
      this.setState({
        onlyPickersCanPick: !this.state.onlyPickersCanPick
      })
    })
    .catch(error => {
      console.warn(`Error when changing pickers option for store ${this.props.store} from user ${this.props.user.email} : ${error}`);
    })
  }

  render(){
    return(
    <div>
      <div className="row">
        <label className="switch">
          <input type="checkbox" onChange={this.switchPickerOption} checked={this.state.onlyPickersCanPick}/>
          <span className="slider round"></span>
        </label>
        <p className="text">
          <Trans i18nKey="pickers.whitelistLablel">If activated, teammates will not be able to pick, if not listed below</Trans>
        </p>
      </div>

      <div className="input-group">
        <button type="button" className="btn blueButton btn-sm" data-toggle="modal" data-target="#newPickerModal">
          <Trans i18nKey="pickers.newPicker">New picker</Trans>
        </button>
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>
              <Trans i18nKey="pickers.user">User</Trans>
            </th>
            <th>
              <Trans i18nKey="pickers.removeRights">Remove picker rights</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.allPickers.map(admin => this.displayRow(admin))}
        </tbody>
      </table>
      <div  className="modal fade" id="newPickerModal" tabIndex="-1" role="dialog" aria-labelledby="newPickerModal" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <Trans i18nKey="pickers.declareNewPicker">Declare new picker</Trans>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="closeModal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <Trans i18nKey="pickers.email">Email address</Trans>
                  </span>
                </div>
                <input type="email" className="form-control m-0" value={this.state.pickerInput} onChange={event => this.setState({pickerInput: event.target.value})} />
              </div>
              <div><span id="error">{ this.state.error }</span></div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal">
                {' '}
                <Trans i18nKey="shared.cancel">Cancel</Trans>
                {' '}
              </button>
              <button type="button" className="btn btn-success" onClick={() => this.props.checkUserExistence(this.state.pickerInput, this.declarePicker)} >
                <Trans i18nKey="pickers.declarePicker">Declare picker</Trans>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

export default withTranslation()(Pickers);
