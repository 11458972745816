import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Form, Button, Dropdown} from 'react-bootstrap';

import { LOCALES_MAP } from '../../locales/constants';

import '../../stylesheets/releaseTranslationsList.css';
import blankFlag from "../../stylesheets/blankFlag.gif";


const ReleaseTranslationsList = (props) => {
  const { original, translations, fields, onChange, onDelete, onLangChange } = props;
  const { t } = useTranslation();

  const langs = Object.entries(LOCALES_MAP)
    .map(([key, value]) => ({ id: key, label: value.alt, flag: value.flag }))
    .filter((x) => x.id !== 'en');

  return (
    <div className="releaseTranslationsList">
      <div className="releaseTranslationsList_title">
        {`${t('releases.fields', 'Fields')}:`}
      </div>
      <Card className="releaseTranslationsList_fields">
        <Card.Body className='p-0'>
          {fields.map((f, fIndex) => (
            <div key={`field-${fIndex}`} className="releaseTranslationsList_field">
              <div className="releaseTranslationsList_field_label">
                {f.label}
              </div>
              <div className="releaseTranslationsList_field_value">
                {original[f.id] || '-'}
              </div>
            </div>
          ))}
        </Card.Body>
      </Card>
      <div className="releaseTranslationsList_title">
        {`${t('releases.translations', 'Translations')}:`}
      </div>
      <div className={`releaseTranslationsList_translations${!translations.length ? ' empty' : ''}`}>
      {translations.length ?
        translations.map((x, index) => {
          const currentLang = langs.find((l) => l.id === x.lang);
          return (
            <div key={index} className="releaseTranslationsList_item">
              {!x.id && <div className="dot dot-success releaseTranslationsList_item_new" />}
              <div className="releaseTranslationsList_item_header">
                <Dropdown className="releaseTranslationsList_lang">
                  <Dropdown.Toggle variant="light" size="sm" id="lang">
                    {currentLang ? (
                      <>
                        <img
                          src={blankFlag}
                          className={`flag ${currentLang.flag}`}
                          alt={currentLang.label}
                        />
                        <span className="releaseTranslationsList_lang_option">
                          {`${currentLang.id.toUpperCase()} (${currentLang.label})`}
                        </span>
                      </>
                    ) : t('releases.chooseLang', 'Choose language')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {langs.map((l, langIndex) => (
                      <Dropdown.Item
                        key={`${l.id}_${langIndex}`}
                        onClick={() => onLangChange(l.id, index)}
                      >
                        <img
                          src={blankFlag}
                          className={`flag ${l.flag}`}
                          alt={l.label}
                        />
                        <span className="releaseTranslationsList_lang_option">
                          {`${l.id.toUpperCase()} (${l.label})`}
                        </span>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Button
                  variant="outline-danger"
                  onClick={() => onDelete(x, index)}
                >
                  <i className="vtmn-icon_delete" />
                </Button>
              </div>
              <div>
                {fields.map((f, fIndex) => (
                  <Form.Group key={`${index}-field-${fIndex}`} className="mb-3 text-left">
                    <Form.Label className="my-1 mr-2 small">{f.label}</Form.Label>
                    <Form.Control
                      key={`${index}_${f.id}_${fIndex}`}
                      as="textarea"
                      rows={f.rows || 1}
                      value={x[f.id] || ''}
                      placeholder={f.label}
                      onChange={(e) => onChange(f.id, e.target.value || null, index)}
                    />
                  </Form.Group>

                ))}
              </div>
            </div>
          )
        }) : (
          <span className="releaseTranslationsList_empty">
            {t('releases.noTranslations', 'No translations yet')}
          </span>
      )}
      </div>
    </div>
  );
};

export default ReleaseTranslationsList;
