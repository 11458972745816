import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import Switch from '../../../components/widgets/Switch';
import HelpTooltipIcon from '../../../components/widgets/HelpTooltipIcon';
import "../../../stylesheets/itemToMountSwitch.css";

const ItemToMountSwitch = ({
  active,
  isTomount,
  setIsTomount,
  isOrderToMount,
  mountStatus,
}) => {
  const needMount = isOrderToMount || ['partialy_received', 'ready_tomount'].includes(mountStatus);

  useEffect(() => {
    setIsTomount(needMount);
  }, [setIsTomount, needMount])

  if (!active) return null

  return (
    <div className="item-tomount-switch d-flex flex-row align-items-center ">
      <div className="mr-2">
        <Switch
          controlId="item-to-mount"
          disabled={!!mountStatus}
          checked={isTomount}
          label={<Trans i18nKey="orderMount.itemToMount">Item to mount</Trans>}
          onChange={() => {
            setIsTomount(!isTomount);
          }}
        />
      </div>
      <HelpTooltipIcon placement="right">
        <Trans i18nKey="orderMount.markDescription">Activate this switch if items in order requires to be mounted before notifying the customer</Trans>
      </HelpTooltipIcon>
    </div>
  )
}

export default ItemToMountSwitch;
