import React, { useEffect, useState } from 'react';
import { Alert, Modal, Button, Container, Form, InputGroup } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import _ from 'lodash';

import fetchWithJWT from '../../functions/fetchWithJWT';
import Loader from "../../components/Loader";

const serverUrl = process.env.REACT_APP_SERVERURL;

export const PickingListCreateModal = ({ show, onHide, onSuccess, stock, store, user, updateTokens }) => {
  const { t } = useTranslation();

  const [qty, setQty] = useState(0);
  const [conflict, setConflict] = useState(null);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);

  const createPickingList = async (createMode) => {
    const { email, token, refreshToken, tokenExpireDate } = user;
    const url = `${serverUrl}/v1/${store}/picking_lists`;
    const result = await fetchWithJWT(url, {
      method: "POST",
      body: JSON.stringify({
        stock_address_id: stock.stock_address_id,
        email,
        qty,
        createMode,
      }),
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
    }).then(result => result.json());
    return result;
  };

  const resetFetchState = () => {
    setConflict(null);
    setError(null);
  };

  const handleCreateClick = async (createMode) => {
    try {
      resetFetchState();
      setIsPending(true);
      const result = await createPickingList(createMode || null);
      setIsPending(false);
      if (result.code === '201') {
        onSuccess()
      }
      else if (result.code === '409' && result.details?.pickingLists) {
        setConflict(result.details.pickingLists);
      }
      else {
        setError(result.text || result.error_description);
      }
    } catch (error) {
      setIsPending(false);
      onHide();
    }
  }

  const handleHide = () => {
    if (!isPending) {
      resetFetchState();
      onHide();
    }
  }

  const handleQtyChange = (e) => {
    setError(null);
    setQty(parseInt(e.target.value, 10) || 0);
  };

  useEffect(() => {
    if (stock && !_.isNil(stock.qty)) setQty(stock.qty);
  }, [stock]);

  if (!stock) return null;

  const pickingEmails = conflict ? _.uniq(conflict.map((l) => l.email).filter(Boolean)) : [];

  return (
    <Modal size="lg" show={show} onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t('items.addingPickingList', 'Adding to picking list')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        {error && <Alert variant="danger">{error}</Alert>}
        {isPending ? (
          <Loader loading />
        ) : (
          <Container className="p-0">
            <Form.Group className="mb-3">
              <Form.Label>{`${stock.item_id} - ${stock.item_description}`}</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>{t('items.qtyAdded', 'Quantities added')}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  isInvalid={!!error}
                  value={qty.toString()}
                  onChange={(e) => handleQtyChange(e)}
                  className="m-0"
                />
              </InputGroup>
            </Form.Group>
          </Container>
        )}
        {pickingEmails.length > 0 ? (
          <Alert variant="info">
            <div>
              {`${t('items.pickInProgress', 'The stock is already in the picking list of')}: ${pickingEmails.join(', ')}`}
            </div>
            <div>
              <Trans i18nKey="items.wantToMerge">
                Do you want to merge your quantities into his picking list?
              </Trans>
              {' '}
              <Trans i18nKey="items.orCreate">Or to create your own picking?</Trans>
            </div>
          </Alert>
        ) : null}
      </Modal.Body>
      {!isPending && (
        <Modal.Footer>
          <Button className="btn btn-danger" onClick={handleHide}>
            <Trans i18nKey="shared.close">Close</Trans>
          </Button>
          {pickingEmails.length > 0 ? (
            <>
              <Button className="btn btn-primary" onClick={() => handleCreateClick('merge')}>
                <Trans i18nKey="items.merge">Merge</Trans>
              </Button>
              <Button className="btn btn-success" onClick={() => handleCreateClick('create')}>
                <Trans i18nKey="items.newList">New picking list</Trans>
              </Button>
            </>
          ) : (
            <Button className="btn btn-success" disabled={!!error || qty === 0} onClick={() => handleCreateClick(null)}>
              <Trans i18nKey="items.newList">New picking list</Trans>
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export const BatchPickingListCreateModal = ({ show, onHide, onSuccess, stock, store, user, updateTokens, byAddress }) => {
  const { t } = useTranslation();
  const [itemsToPick, setItemsToPick] = useState([]);
  const [conflict, setConflict] = useState(null);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);

  const createPickingList = async (items, createMode) => {
    const { email, token, refreshToken, tokenExpireDate } = user;
    const url = `${serverUrl}/v1/${store}/picking_lists/batch_create`;
    const result = await fetchWithJWT(url, {
      method: "POST",
      body: JSON.stringify({
        email,
        items,
        createMode,
      }),
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
    }).then(result => result.json());
    return result;
  };

  const resetFetchState = () => {
    setConflict(null);
    setError(null);
  };

  const handleCreateClick = async (createMode) => {
    const toPick = itemsToPick.filter(x => x.qty);
    if (!toPick.length) {
      return;
    }
    try {
      resetFetchState();
      setIsPending(true);
      const result = await createPickingList(toPick, createMode || null);
      setIsPending(false);
      if (result.code === '201') {
        onSuccess();
      }
      else if (result.code === '409' && result.details) {
        setConflict(result.details);
      }
      else {
        setError(result.text || result.error_description);
      }
    } catch (error) {
      setIsPending(false);
      onHide()
    }
  }

  const handleHide = () => {
    if (!isPending) {
      resetFetchState();
      onHide();
    }
  }

  const handleQtyChange = (e, id, available) => {
    const newQty = parseInt(e.target.value, 10);
    const newItemsToPick = itemsToPick.map((s) => s.stock_address_id === id
      ? { ...s, qty: newQty }
      : s
    );
    if (available && conflict?.no_stock?.length && newQty <= available) {
      setConflict({
        ...conflict,
        no_stock: conflict.no_stock.filter((item) => item.stock_address_id !== id),
      })
    }
    setItemsToPick(newItemsToPick);
  };

  useEffect(() => {
    setItemsToPick(stock.map(({ stock_address_id, qty }) => ({
      stock_address_id,
      qty,
    })));
  }, [stock]);

  useEffect(() => {
    if (conflict && stock?.length) {
      let conflictIds = new Set();
      if (conflict.in_progress && conflict.in_progress.length) {
        conflict.in_progress.forEach((item) => conflictIds.add(item.stock_address_id));
      }
      if (conflict.no_stock && conflict.no_stock.length) {
        conflict.no_stock.forEach((item) => conflictIds.add(item.stock_address_id));
      }
      const firstStock = conflictIds.size ? stock.find((stockInfo) => conflictIds.has(stockInfo.stock_address_id)) : null;
      if (firstStock) {
        document.getElementById(firstStock.stock_address_id).focus();
      }
    }
  }, [conflict, stock]);

  if (!stock.length) return null;

  const hasInProgressConflicts = conflict?.in_progress?.length > 0 || false;
  const hasNoStockConflicts = conflict?.no_stock?.length > 0 || false;
  const canResolve = !hasNoStockConflicts && hasInProgressConflicts;

  return (
    <Modal size="lg" show={show} onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {`${t('items.addingPickingList', 'Adding to picking list')} (${
            stock.length} ${(byAddress
              ? t('address.address', { defaultValue: 'Address', count: stock.length })
              : t('address.itemCode', { defaultValue: 'Item code', count: stock.length })
            ).toLowerCase()
          })`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        {error && <Alert variant="danger">{error}</Alert>}
        {isPending ? (
          <Loader loading />
        ) : (
          <Container className="p-0">
            {stock.map((stockInfo, index) => {
              const itemState = itemsToPick.find((item) => item.stock_address_id === stockInfo.stock_address_id);
              const noStock = hasNoStockConflicts
                ? conflict.no_stock.find((item) => item.stock_address_id === stockInfo.stock_address_id) : false;
              const noStockError = noStock && itemState?.qty > noStock.available
              const picking = hasInProgressConflicts
                ? conflict.in_progress.find((item) => item.stock_address_id === stockInfo.stock_address_id) : null;
              return (
                <Form.Group key={index} className="mb-3">
                  <Form.Label>{byAddress ? stockInfo.address : `${stockInfo.item_id} - ${stockInfo.item_description}`}</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>{t('items.qtyAdded', 'Quantities added')}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      id={stockInfo.stock_address_id}
                      isInvalid={noStockError || picking ? true : false}
                      value={(itemState?.qty || 0).toString()}
                      onChange={(e) => handleQtyChange(e, stockInfo.stock_address_id, noStock?.available)}
                      className="m-0"
                    />
                  </InputGroup>
                  {noStockError && (
                    <Form.Text className="text-danger">
                      {t('items.pickNotEnoughStock', {
                        defaultValue: 'The stock is insufficient ({{qtyInStock}} available)',
                        qtyInStock: noStock?.available || 0
                      })}
                    </Form.Text>
                  )}
                  {picking && (
                    <Form.Text className="text-danger">
                      {`${t('items.pickInProgress', 'The stock is already in the picking list of')}: ${picking.emails?.join(', ')}`}
                    </Form.Text>
                  )}
                </Form.Group>
              );
            })}
          </Container>
        )}
        {canResolve ? (
          <Alert variant="info">
            <Trans i18nKey="items.wantToMergeBatch">
              Do you want to merge your quantities on conflict items into users picking lists?
            </Trans>
            {' '}
            <Trans i18nKey="items.orCreate">Or to create your own picking?</Trans>
          </Alert>
        ) : null}
      </Modal.Body>
      {!isPending && (
        <Modal.Footer>
          <Button className="btn btn-danger" onClick={handleHide}>
            <Trans i18nKey="shared.close">Close</Trans>
          </Button>
          {canResolve ? (
            <>
              <Button className="btn btn-primary" onClick={() => handleCreateClick('merge')}>
                <Trans i18nKey="items.merge">Merge</Trans>
              </Button>
              <Button className="btn btn-success" onClick={() => handleCreateClick('create')}>
                <Trans i18nKey="items.newList">New picking list</Trans>
              </Button>
            </>
          ) : (
            <Button className="btn btn-success" disabled={hasInProgressConflicts} onClick={() => handleCreateClick(null)}>
              <Trans i18nKey="items.newList">New picking list</Trans>
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};
