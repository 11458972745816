import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Loader from '../../components/Loader';
import AddressScannerInput from '../../components/widgets/AddressScannerInput';
import useRequest from '../../functions/useRequest';
import { useUserReduxState } from '../../store/user';
import { SameItemError } from '../TrackingNumber/messages';

import { assignAddress } from './api';


const AddressAllDialog = ({ trolleyId, order, show, onHide, onAddressByItem, onSuccess, onError }) => {
  const { user, updateTokens } = useUserReduxState();
  const { store } = useParams();
  const { t } = useTranslation();

  const [address, setAddress] = useState('');
  const [validatedAddress, setValidatedAddress] = useState(null);
  const [isAddressHasSameItem, setIsAddressHasSameItem] = useState(false);

  const [{ loading }, doAssignAddress] = useRequest(assignAddress, {
    onError: (error) => {
      if (error?.response?.order_number && error.response?.has_same_item === true) {
        setIsAddressHasSameItem(true);
      } else {
        onError(error);
      }
    },
    onSuccess,
  });

  useEffect(() => {
    setIsAddressHasSameItem(false);
    setAddress('');
    setValidatedAddress(null);
  }, [show, order])

  const onAddress = (force_same_item_to_address) => {
    if (!order) {
      return;
    }
    doAssignAddress({
      store,
      trolley_id: trolleyId,
      order_id: order.order_id,
      address,
      items: order.items
        .filter(x => x.resolve_action === 'picked')
        .map(({ item_id, qty, addressed_qty }) => ({ item_id, qty: qty - addressed_qty })),
      force_same_item_to_address,
      user,
      updateTokens
    });
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="ecom-address-all-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="trolley.addressing">Addressing</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {loading && <Loader />}
        {!loading && (
          <AddressScannerInput
            user={user}
            updateTokens={updateTokens}
            store={store}
            placeholder={t('reviewProblems.inputAddress', 'Scan address...')}
            value={address}
            onChange={setAddress}
            validatedAddress={validatedAddress}
            setValidatedAddress={setValidatedAddress}
          />
        )}
        {isAddressHasSameItem && (
          <SameItemError
            onClose={() => setIsAddressHasSameItem(false)}
            onForceAssignSameItem={() => onAddress(true)}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
        >
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        <Button
          disabled={loading || !order}
          variant="primary"
          onClick={onAddressByItem}
        >
          <Trans i18nKey="trolley.addressByItem">Address by item</Trans>
        </Button>
        <Button
          disabled={loading || !order || !address || validatedAddress !== address}
          variant="primary"
          onClick={() => onAddress()}
        >
          <Trans i18nKey="trolley.addressAll">Address all</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddressAllDialog;
