import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { InputGroup } from 'react-bootstrap';
import SelectToggleButton from './SelectToggleButton';

const Select = ({
  disabled,
  controlId,
  label,
  placeholder,
  options,
  selected,
  onChange,
  loading,
}) => {
  const selectedItems = (selected && options.filter(val => String(val.id) === String(selected))) || [];
  const hasValue = selectedItems && selectedItems.length;
  return (
    <>
      {label && (
        <label className="select-form-label" htmlFor={controlId}>
          {label}
          {':'}
        </label>
      )}
      <InputGroup className="d-flex flex-column">
        <Typeahead
          disabled={disabled}
          isLoading={loading}
          className="input-select"
          placeholder={placeholder}
          id={controlId}
          inputProps={{id: controlId}}
          options={options}
          selected={selectedItems}
          clearButton
          filterBy={() => true}
          onChange={([opt]) => onChange(opt && opt.id)}
        >
          {({ isMenuShown, toggleMenu }) => (
            !hasValue && (
              <SelectToggleButton
                isOpen={isMenuShown}
                onClick={e => {
                  toggleMenu();
                  e.stopPropagation();
                  e.preventDefault();
                  return false;
                }}
              />
            )
          )}
        </Typeahead>
      </InputGroup>
    </>
  );
}

export default Select;
