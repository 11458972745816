import React, { useState, useEffect } from 'react';
import useDebounce from '../../../functions/useDebounce';


const InputFilter = ({
  controlId,
  label,
  value,
  onChange,
}) => {
  const [stateValue, setStateValue] = useState(value);
  const debouncedValue = useDebounce(stateValue, 500);

  useEffect(
    () => {
      if (value !== debouncedValue) {
        onChange(debouncedValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedValue],
  );
  useEffect(
    () => { setStateValue(value); },
    [value],
  );

  return (
    <div className="filter-item form-group">
      <label className="form-label" htmlFor={controlId}>
        {label}
        {': '}
      </label>

      <input
        id={controlId}
        className="form-control form-control-sm"
        type="text"
        value={stateValue || ''}
        onChange={(event) => { setStateValue(event.target.value); }}
      />
    </div>
  );
};

export default InputFilter;
