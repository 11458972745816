import React, { Component } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { withTranslation, Trans } from 'react-i18next';
import Loader from '../components/Loader';
import fetchWithJWT from '../functions/fetchWithJWT';

const serverUrl = process.env.REACT_APP_SERVERURL;

class PickingListAssignModal extends Component {
  constructor(props){
    super(props);
    this.modalRef = React.createRef();
    this.state = {
      isLoadingUsers: false,
      options: [],
      userEmail: "",
      selectedUser: "",
      isAssigning: false,
      error: null,
    }
  }

  searchUsers = (query) => {
    this.setState({isLoadingUsers: true, userEmail : query});
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    fetchWithJWT(`${serverUrl}/v1/users/query/${query}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(response => response.json())
    .then(json => this.setState({
        options: json,
        isLoadingUsers : false
    }))
    .catch(error => {
      console.warn(error);
    });
  }

  assignPickingLists = (selectedUser) => {
    const { t, pickingLists, store, onFinish } = this.props;
    this.setState({ isAssigning: true });
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    fetchWithJWT(`${serverUrl}/v1/${store}/picking_lists`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      body: JSON.stringify({
        "picking_list_ids" : pickingLists,
        "email": selectedUser,
      }),
      method: "PATCH",
    })
    .then(response => response.json())
    .then(json => {
      if (json.code === "200") {
        window.$(this.modalRef.current).modal('hide');
        onFinish();
        this.setState({
          error: null,
          isAssigning: false,
          selectedUser: "",
          userEmail: "",
        });
      } else {
        const error = json.text || t('shared.defaultError', 'Error occured');
        this.setState({ error, isAssigning: false });
      }
    })
    .catch(error => {
      this.setState({ error: t('shared.defaultError', 'Error occured'), isAssigning: false });
      console.warn(error);
    });
  }

  renderUserSelect() {
    const { t } = this.props;
    const { isLoadingUsers, options, userEmail } = this.state;
    return (
      <form className="form-group row col-12" onSubmit={(event) => {
        event.preventDefault();
        if(this.state.userEmail !== ""){
          this.searchUsers(userEmail)
        }
      }}>
        <div className="input-group ">
          <AsyncTypeahead
            ref = "typeahead"
            id="user_select"
            isLoading={isLoadingUsers}
            onSearch={this.searchUsers}
            placeholder={t('pickingList.enterEmail', 'Enter user email ...')}
            useCache={false}
            minLength={3}
            delay={500}
            options={options || []}
            defaultSelected={[{ email: userEmail }]}
            filterBy={op => op.email}
            labelKey={op => `${op.email}`}
            renderMenuItemChildren={(option) => (
              <div onClick={() => {
                this.setState({
                  userEmail: option.email,
                  selectedUser: option.email,
                }, () => {
                  document.getElementById("buttonSubmit").click();
                })
              }}>{option.email}</div>
            )}
          />
          <div className="input-group-append">
            <button type="submit" id="buttonSubmit" className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_with-only-icon" >
              <i className="vtmn-icon_search" alt="search"></i>
            </button>
          </div>
        </div>
    </form>
    )
  }

  render() {
    const { t, pickingLists } = this.props;
    const { selectedUser, isAssigning, error } = this.state;
    const assignDisabled = !selectedUser || isAssigning;
    const pickingListsCount = (pickingLists && pickingLists.length) || 0;
    return(
      <div
        key="assign-picking-lists" className="modal fade" id="modal-assign-picking-lists" tabIndex="-1"
        role="dialog" aria-labelledby="modalPickTitle" aria-hidden="true"
        ref={this.modalRef}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalPickTitle">
                <Trans i18nKey="pickingList.assignTitle">Assign picking lists</Trans>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close-modal-assign-picking-lists">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {(
                isAssigning
                  ? (
                    <Loader
                      loading
                      text={t('shared.saving', 'Saving')}
                    />
                  )
                  : (
                    <>
                      <p>
                        {t('pickingList.cntPickingListSelected', {
                          defaultValue: '({{count}}) picking list selected',
                          count: pickingListsCount,
                        })}
                      </p>
                      {this.renderUserSelect()}
                      {error && <p className="text-danger">{error}</p>}
                    </>
                  )
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal"> Cancel </button>
              <button
                type="button" className="btn btn-success" disabled={assignDisabled}
                onClick={() => this.assignPickingLists(selectedUser)}
              >
                {' '}
                <Trans i18nKey="pickingList.assign">Assign</Trans>
                {' '}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PickingListAssignModal);
