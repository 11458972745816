import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Row, Button } from 'react-bootstrap';
import dateFormat from 'dateformat';

import WithSidebar from '../WithSidebar';
import useTableRequest from '../../functions/useTableRequest';
import { DataTable, TableFooter } from '../../components/widgets/DataTable';

import '../../stylesheets/releases.css';

import Filters from './Filters';
import { getReleasesQuery } from './api';
import { RELEASE_TYPE_OPTIONS } from './constants';
import ReleaseAddDialog from './ReleaseAddDialog';
import ReleasePreviewDialog from './ReleasePreviewDialog';
import ReleaseRemoveConfirmDialog from './ReleaseRemoveConfirmDialog';
import ReleasePublishChangeConfirmDialog from './ReleasePublishChangeConfirmDialog';
import ReleasePublishButton from './ReleasePublishButton';

const getFields = ({ t, onPublish, onPreview, onEdit, onRemove }) => [
  {
    field: 'version',
    title: t('releases.version', 'Version'),
    sortable: true,
    valueRenderer: (val, _f, row) => (
      <Button
        variant="link"
        size="sm"
        onClick={() => onPreview(row)}
      >
        {val}
      </Button>
    ),
  },
  {
    field: 'version_code',
    title: t('releases.versionCode', 'Version code'),
    sortable: true,
    align: 'center',
    width: 80,
  },
  {
    field: 'type',
    title: t('releases.type', 'Type'),
    sortable: true,
    align: 'center',
    width: 80,
    valueRenderer: (val) => RELEASE_TYPE_OPTIONS.find((x) => x.id === val)?.label || val,
  },
  {
    field: 'summary',
    title: t('releases.summary', 'Summary'),
    sortable: true,
  },
  {
    field: 'has_breaking_changes',
    title: t('releases.breakingChanges', 'Breaking changes'),
    sortable: true,
    align: 'center',
    width: 80,
    valueRenderer: (val) => <span className={`dot ${val === true ? 'dot-success' : 'dot-error'}`} />
  },
  {
    field: 'published',
    title: t('releases.published', 'Published'),
    sortable: true,
    align: 'center',
    width: 80,
    valueRenderer: (val) => <span className={`dot ${val === true ? 'dot-success' : 'dot-error'}`} />
  },
  {
    field: 'change_date',
    title: t('releases.changeDate', 'Change date'),
    sortable: true,
    align: 'right',
    width: 100,
    valueRenderer: (val) => val ? dateFormat(new Date(val), "dd/mm/yyyy HH:MM:ss") : null,
  },
  {
    field: 'create_date',
    title: t('releases.createDate', 'Create date'),
    sortable: true,
    align: 'right',
    width: 100,
    valueRenderer: (val) => val ? dateFormat(new Date(val), "dd/mm/yyyy HH:MM:ss") : null,
  },
  {
    field: 'publish',
    title: '',
    valueRenderer: (_val, _f, row) => (
      <ReleasePublishButton published={row.published} size="sm" onClick={() => onPublish(row)} />
    ),
  },
  {
    field: 'edit',
    title: '',
    valueRenderer: (_val, _f, row) => (
      <Button
        variant="outline-primary"
        size="sm"
        onClick={() => onEdit(row)}
      >
        <i className="vtmn-icon_edit" />
      </Button>
    ),
  },
  {
    field: 'remove',
    title: '',
    valueRenderer: (_val, _f, row) => (
      <Button
        variant="outline-danger"
        size="sm"
        onClick={() => onRemove(row)}
      >
        <i className="vtmn-icon_delete" />
      </Button>
    ),
  },
];

const initialFilters = {};

const Releases = (props) => {
  const { user, updateTokens, logOut } = props;
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const currentLang = i18n.resolvedLanguage;


  const [
    {
      loading,
      data,
      error,
      filters,
      pagination,
      sort,
      totals,
    },
    fetchReleases,
    setFilters,
    setPagination,
    setSort,
  ] = useTableRequest(getReleasesQuery, { initialState: { filters: initialFilters } });

  const [dialog, setDialog] = useState(null); // null | 'add' | 'preview' | 'removeConfirm' | 'publishConfirm'
  const [dialogValue, setDialogValue] = useState(null); // null | ReviewBase object

  const updateReleases = () => {
    fetchReleases({ filters, pagination, sort, lang: currentLang, user, updateTokens });
  };

  const handleDialogClose = () => {
    setDialog(null);
    setDialogValue(null);
  };

  const handleDialogChange = (type, value) => {
    setDialog(type || null);
    setDialogValue(value || null);
  };

  const handleDialogActionDone = () => {
    updateReleases();
    handleDialogChange(null);
  };

  const onAdd = () => handleDialogChange('add');
  const onPreview = (row) => handleDialogChange('preview', row);
  const onPublish = (row) => handleDialogChange('publishConfirm', row);
  const onRemove = (row) => handleDialogChange('removeConfirm', row);
  const onEdit = (row) => history.push(`/releases/${row.id}`);

  useEffect(() => {
    updateReleases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filters, pagination, sort, currentLang, fetchReleases]);

  return (
    <WithSidebar user={user} updateTokens={updateTokens} logOut={logOut}>
      <div className = "jumbotron container" style={{ position:"relative" }}>
        <h2 className="text-center">{t('releases.title', 'Releases')}</h2>
        <div className="releases_btn_wrap">
          <Button
            variant="primary"
            size="sm"
            onClick={onAdd}
          >
            <Row style={{ alignItems: 'center' }}>
              <i className="vtmn-icon_plus_v2" />
              <span style={{ paddingLeft: 6 }}>{t('releases.addRelease', 'Add release')}</span>
            </Row>
          </Button>
        </div>
        <Filters filters={filters} setFilters={setFilters} onClear={() => setFilters(initialFilters)} />
        <DataTable
          data={data}
          fields={getFields({ t, onPublish, onPreview, onEdit, onRemove })}
          loading={loading}
          error={error}
          sort={sort}
          setSort={setSort}
        />
        <TableFooter
          pagination={pagination}
          totals={totals}
          setPagination={setPagination}
        />
        {dialog === 'add' && <ReleaseAddDialog show onHide={handleDialogClose} />}
        {dialog === 'preview' && !!dialogValue && (
          <ReleasePreviewDialog
            show
            id={dialogValue.id}
            lang={currentLang}
            onHide={handleDialogClose}
            onEdit={() => onEdit(dialogValue)}
            onPublish={() => onPublish(dialogValue)}
          />
        )}
        {dialog === 'removeConfirm' && !!dialogValue && (
          <ReleaseRemoveConfirmDialog
            show
            release={dialogValue}
            onHide={handleDialogClose}
            onDone={handleDialogActionDone}
          />
        )}
        {dialog === 'publishConfirm' && !!dialogValue && (
          <ReleasePublishChangeConfirmDialog
            show
            release={dialogValue}
            onHide={handleDialogClose}
            onDone={handleDialogActionDone}
          />
        )}
      </div>
    </WithSidebar>
  );
};

export default Releases;
