import React, { Component } from 'react';
import { Trans } from 'react-i18next';

export default class GoogleOAuth extends Component {
  componentDidMount(){
    const token = window.location.href.split("access_token=")[1].split("&")[0];
    const expires_in = window.location.href.split("expires_in=")[1].split("&")[0];
    const tokenValidUntil = new Date();
    const securityMargin = 0.1;//10% security margin on the token validity
    tokenValidUntil.setSeconds(tokenValidUntil.getSeconds() + expires_in * securityMargin);
    const credentials = {
      token: token,
      validUntil: tokenValidUntil
    }
    window.localStorage.setItem('credentials', JSON.stringify(credentials));
    window.localStorage.removeItem('credentials');
    window.close();
  }
  render(){
    return(
      <div>
        <Trans i18nKey="googleOAuth.thanks">Thanks for the access. Google Spreadsheet will now be created</Trans>
      </div>
    )
  }

}
