import React from 'react';
import { Trans } from 'react-i18next';

import BarcodeModal from '../../components/widgets/BarcodeModal';
import ItemsModal, { MultipleQtyWarning } from './ItemsModal';

const OrdersReportModal = ({ mode, order, itemDetailsMap, sgEpcMap, onHide }) => {
  if (!mode || !order) return null;
  const { items, packaging_type, shipping_group_number, has_same_addressed_item, shipperbox_number } = order;
  const showMultipleQtyWarning = (packaging_type === 'no-packaging' || packaging_type === 'ecom-prep') && has_same_addressed_item;
  switch (mode) {
    case 'barcode':
      return (
        <BarcodeModal
          code={shipperbox_number || shipping_group_number}
          show
          onHide={onHide}
          topComponent={showMultipleQtyWarning ? (
            <>
              <MultipleQtyWarning />
              <div className="row justify-content-center">
                <div className="row col-12 col-md-8">
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th scope="col">Item ID</th>
                        <th scope="col">
                          <Trans i18nKey="shared.serial">Serial number</Trans>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((itemId, index) => {
                        const epcItems = sgEpcMap[shipping_group_number] || {}
                        return epcItems.map(({ serial }, epcIndex) => {
                          if (!serial) return null
                          return (
                            <tr key={`${index}-${epcIndex}`}>
                              <th scope="row">{itemId}</th>
                              <td>{serial}</td>
                            </tr>
                          );
                        });
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : undefined}
        />
      );
    case 'items':
      return (
        <ItemsModal
          items={items}
          itemDetailsMap={itemDetailsMap}
          epcItems={sgEpcMap[shipping_group_number]}
          showMultipleQtyWarning={showMultipleQtyWarning}
          show
          onHide={onHide}
        />
      );
    default:
      return null;
  }
}

export default OrdersReportModal;
