import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import '../../stylesheets/errorMessage.css';

const ErrorMessage = ({ error, onClose }) => {
  const { t } = useTranslation();
  return (
    <Alert dismissible={!!onClose} onClose={onClose} variant="danger" className="error-message">
      <i className="error-message-icon vtmn-icon_cross_rounded"></i>
      <div className="error-message-text">
        {error.message || t('shared.error', 'Error')}
      </div>
    </Alert>
  );
};

export default ErrorMessage;
