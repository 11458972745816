import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../functions/useDebounce';

const SearchBar = ({
  value,
  onChange,
  onRefresh,
  loading,
}) => {
  const { t } = useTranslation();
  const [stateValue, setStateValue] = useState(value);
  const debouncedValue = useDebounce(stateValue, 500);

  useEffect(
    () => {
      if (value !== debouncedValue) {
        onChange(debouncedValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedValue],
  );
  useEffect(
    () => { setStateValue(value); },
    [value],
  );

  return (
    <div id="searchbar" className="searchbar input-group mb-3">
      <input
        className="form-control"
        placeholder={t('searchBar.search', 'Search...')}
        type="text"
        value={stateValue || ''}
        onChange={(event) => { setStateValue(event.target.value); }}
      />
      <div className="input-group-append">
        <button
          className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_with-only-icon"
          onClick={onRefresh}
          disabled={loading}
        >
          <i className="vtmn-icon_search"></i>
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
