import { useCallback } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

const EMPTY_FILTERS = {
  statuses: [],
  email: null,
  last_pick_from: null,
  last_pick_to: null,
  created_from: null,
  created_to: null,
};

const DEFAULT_FILTERS = { ...EMPTY_FILTERS, statuses: ['picked', 'packing'] };

const INITIAL_STATE = {
  version: '1.0.1',
  filters: DEFAULT_FILTERS,
  sort: {
    field: 'min_order_date',
    direction: 'ASC',
  },
  showImages: true,
};

const getInitialState = () => {
  return INITIAL_STATE;
};

export const ecomPackingSlice = createSlice({
  name: 'ecomPacking',
  initialState: getInitialState(),
  reducers: {
    setSortAction: (state, action) => {
      state.sort = action.payload;
    },
    setShowImagesAction: (state, action) => {
      state.showImages = action.payload;
    },
    setFiltersAction: (state, action) => {
      state.filters = action.payload;
    },
    resetFiltersAction: (state) => {
      state.filters = DEFAULT_FILTERS;
    },
    clearFiltersAction: (state) => {
      state.filters = EMPTY_FILTERS;
    },
    resetAction: () => {
      return INITIAL_STATE;
    },
  },
});

export const {
  setShowImagesAction,
  setSortAction,
  setFiltersAction,
  resetFiltersAction,
  clearFiltersAction,
  resetAction,
} = ecomPackingSlice.actions;

export const useEcomPackingState = () => {
  const filters = useSelector((state) => state.ecomPacking.filters);
  const hasFilters = !(_.isEqual(DEFAULT_FILTERS, filters) ||  _.isEqual(EMPTY_FILTERS, filters));
  const sort = useSelector((state) => state.ecomPacking.sort);
  const showImages = useSelector((state) => state.ecomPacking.showImages);
  const dispatch = useDispatch();
  const setShowImages = useCallback((x) => dispatch(setShowImagesAction(x)), [dispatch]);
  const setFilters = useCallback((x) => dispatch(setFiltersAction(x)), [dispatch]);
  const setSort = useCallback((x) => dispatch(setSortAction(x)), [dispatch]);
  const resetFilters = useCallback(() => dispatch(resetFiltersAction()), [dispatch]);
  const clearFilters = useCallback(() => dispatch(clearFiltersAction()), [dispatch]);
  const reset = useCallback(() => dispatch(resetAction()), [dispatch]);


  return {
    showImages,
    setShowImages,
    filters,
    hasFilters,
    sort,
    setFilters,
    setSort,
    resetFilters,
    clearFilters,
    reset,
  };
}

export default ecomPackingSlice.reducer;
