import React from 'react';
import { Trans } from 'react-i18next';
import Confirm from '../../components/widgets/Confirm';

export const ConfirmPickOrder = ({ show, onHide, onConfirm }) => (
  <Confirm
    show={show}
    onHide={onHide}
    onConfirm={onConfirm}
    icon={<i className="modal-header-icon text-success vtmn-icon_account" />}
    body={<Trans i18nKey="orders.givingToCustomer">Are you going to give the order to the customer?</Trans>}
    buttonOpts={{
      variant: 'success',
      text: (
        <>
          <Trans i18nKey="orders.giveCustomerGive">Give to</Trans>
          {' '}
          <Trans i18nKey="orders.giveCustomerCustomer">customer</Trans>
        </>
      )
    }}
  />
);

export const ConfirmPickAllOrder = ({ show, address, onHide, onConfirm }) => (
  <Confirm
    show={show}
    onHide={onHide}
    onConfirm={onConfirm}
    icon={<i className="modal-header-icon text-success vtmn-icon_account" />}
    body={<Trans i18nKey="orders.pickAllConfirmation" values={{address: address}}>Do you want to give all item from address {{address}}?</Trans>}
    buttonOpts={{
      variant: 'success',
      text: (
        <>
          <Trans i18nKey="orders.giveCustomerGive">Give to</Trans>
          {' '}
          <Trans i18nKey="orders.giveCustomerCustomer">customer</Trans>
        </>
      )
    }}
  />
);

export const ConfirmRemoveOrder = ({ show, onHide, onConfirm }) => (
  <Confirm
    show={show}
    onHide={onHide}
    onConfirm={onConfirm}
    icon={<i className="modal-header-icon text-danger vtmn-icon_delete vtmn-icon-22px" />}
    body={<Trans i18nKey="orders.removingOrder">Are you going to remove this order?</Trans>}
    buttonOpts={{
      variant: 'danger',
      text: <Trans i18nKey="orders.removeOrder">Remove order</Trans>,
    }}
  />
);

export const ConfirmRemoveAllOrder = ({ show, onHide, onConfirm }) => (
  <Confirm
    show={show}
    onHide={onHide}
    onConfirm={onConfirm}
    icon={<i className="modal-header-icon text-danger vtmn-icon_delete vtmn-icon-22px" />}
    body={<Trans i18nKey="orders.removingOrders">Are you going to remove all orders with status COMPLETED?</Trans>}
    buttonOpts={{
      variant: 'danger',
      text: <Trans i18nKey="shared.remove">Remove</Trans>,
    }}
  />
);
