import React, { useEffect, useState, useRef } from 'react';
import { Trans } from 'react-i18next';
import { Modal, Button, InputGroup, Form } from 'react-bootstrap';
import fetchWithJWT from '../../functions/fetchWithJWT';
import { handleApiResponse } from '../../functions/handleApiResponse';
import Loader from '../../components/Loader';
import '../../stylesheets/addStoreModal.css';

const serverUrl = process.env.REACT_APP_SERVERURL;
const formUrl = process.env.REACT_APP_form_newStore;

const AddStoreDialog = ({ isOpen, onClose, jwtOpts }) => {
  const bodyRef = useRef(null);
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [exist, setExist] = useState(false);

  const handleAddStoreConfirm = () => {
    window.open(formUrl, '_blank');
    onClose();
  }

  const handleSearching = async () => {
    if (text.length > 0) {
      setLoading(true);
      fetchWithJWT(`${serverUrl}/v1/stores/exist/${text}`, {
        jwtOpts: jwtOpts}).then(handleApiResponse).then(({storeExist}) => {
          setLoading(false);
          if (storeExist) {
            setExist(true)
          } else {
            handleAddStoreConfirm()
          }
        }).catch(error=>console.warn(error))
    }
  }

  useEffect(() => {
    if (isOpen) {
      setText('');
      setLoading(false);
      setExist(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      className="add-store-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="changeStore.addStore">Add Store</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={bodyRef}>
        <div className="instruction">
          { loading ? 
            <Loader/> 
            : exist ? 
              <Trans i18nKey="changeStore.noAccess">Your store already exists in Product Locator. If you can’t see it, it’s because you don’t have rights declared in Identity Access. Please ask your manager</Trans>:
              <Trans i18nKey="changeStore.instruction">Please enter the store number you want to add</Trans>
          }
        </div>
        { exist ? 
          <div className='btnContainer'>
            <Button
              id='existOkBtn'
              disabled={loading}
              className="btn"
              variant="primary"
              onClick={onClose}
            >
              <Trans i18nKey="shared.ok">OK</Trans>
            </Button>
          </div>
          : <InputGroup>
              <Form.Control
                id='existStoreInput'
                disabled={loading}
                className="mr-0"
                type="text"
                value={text}
                onChange={(event) => setText(event.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.repeat && !e.ctrlKey && !e.shiftKey) {
                    setTimeout(() => {
                      handleSearching()
                    }, 100);
                  }
                }}
              />
              <InputGroup.Append>
                <Button
                  id='existStoreBtn'
                  className="bout"
                  disabled={loading}
                  onClick={handleSearching}
                >
                  <Trans i18nKey="comments.send">Send</Trans>
                </Button>
              </InputGroup.Append>
            </InputGroup>
        }
      </Modal.Body>
    </Modal>
  );
};

export default AddStoreDialog;
