import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';

import '../../stylesheets/simpleSelect.css';

const SimpleSelect = ({
  disabled,
  controlId,
  label,
  inline,
  options,
  selected,
  onChange,
  loading,
  error,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {label && !inline && (
        <label className="select-form-label" htmlFor={controlId}>
          {label}
          {':'}
        </label>
      )}
      <InputGroup className="simple-select">
        {label && inline && (
          <div className="input-group-prepend">
            <label className="input-group-text" htmlFor={controlId}>
              {label}
              {':'}
            </label>
          </div>
        )}
        <Form.Control
          isInvalid={!!error}
          as="select"
          disabled={disabled}
          id={controlId}
          className="custom-select"
          value={selected}
          onChange={(event) => onChange(event.target.value)}
        >
          {options.map(({ id, label }) => <option key={id} value={id}>{label}</option>)}
        </Form.Control>
        {loading && (
          <div className="simple-select-loader">
            <ClipLoader color="#ccc" size={15} loading />
          </div>
        )}
        {error && (
          <Form.Control.Feedback type="invalid">
            {error.message || t('shared.error', 'Error')}
          </Form.Control.Feedback>
        )}
        </InputGroup>
    </>
  );
}

export default SimpleSelect;
