import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import InputFilter from '../../components/widgets/filters/InputFilter';
import SelectFilter from '../../components/widgets/filters/SelectFilter';

import { RELEASE_TYPE_OPTIONS } from './constants';

import '../../stylesheets/filters.css';


const hasFilters = (filters) => {
  const keys = Object.keys(filters);
  const values = keys.map(k => filters[k]).filter(v => v);
  const filtered = values.length !== 0;
  return filtered;
};

const Filters = (props) => {
  const { filters, setFilters, onClear } = props;
  const { t } = useTranslation();

  const [visibility, setVisibility] = useState('partial');

  const isVisible = visibility !== 'hidden';
  const isFull = visibility === 'full';
  const filtered = hasFilters(filters);

  return (
    <div className={`card filter-card filter-card-${visibility} mb-3 mt-3`}>
      <div className="card-header">
        <button
          size="sm"
          className={`btn btn-primary btn-sm icon-button filter-button ${filtered ? 'filter-button-filtered' : ''}`}
          onClick={() => setVisibility(isVisible ? 'hidden' : 'partial')}
        >
          <i className="vtmn-icon_filter2"></i>
          {filtered && (<i className="vtmn-icon_tiny_bold_valid subicon"></i>)}
          <Trans i18nKey="filters.filters">Filters</Trans>
          {isVisible ? (
            <i className="vtmn-icon_chevron_up_v2 right"></i>
          ) : (
            <i className="vtmn-icon_chevron_down_v2 right"></i>
          )}
        </button>
        <div className="spacer"/>
      </div>

      {isVisible && (
        <div className="card-body">
          <div className="row">
            <SelectFilter
              controlId="filter-type"
              label={t('releases.type', 'Type')}
              selected={filters.type}
              options={RELEASE_TYPE_OPTIONS}
              onChange={type => setFilters({ ...filters, type })}
            />

            <InputFilter
              controlId="filter-version"
              label={t('releases.version', 'Version')}
              value={filters.version}
              onChange={version => setFilters({ ...filters, version })}
            />
            <SelectFilter
              controlId="filter-published"
              label={t('releases.published', 'Published')}
              options={[{
                id: true,
                label: t('shared.yes', 'Yes'),
              }, {
                id: false,
                label: t('shared.no', 'No'),
              }]}
              selected={filters.published}
              onChange={published => setFilters({ ...filters, published })}
            />
            {isFull && (
              <>
                <SelectFilter
                  controlId="filter-has-breaking-changes"
                  label={t('releases.breakingChanges', 'Breaking changes')}
                  options={[{
                    id: true,
                    label: t('shared.yes', 'Yes'),
                  }, {
                    id: false,
                    label: t('shared.no', 'No'),
                  }]}
                  selected={filters.has_breaking_changes}
                  onChange={has_breaking_changes => setFilters({ ...filters, has_breaking_changes })}
                />
                <InputFilter
                  controlId="filter-version-code"
                  label={t('releases.versionCode', 'Version code')}
                  value={filters.version_code}
                  onChange={version_code => setFilters({ ...filters, version_code })}
                />
                <InputFilter
                  controlId="filter-version-code-from"
                  label={t('releases.versionCodeFrom', 'Version code from')}
                  value={filters.version_code_from}
                  onChange={version_code_from => setFilters({ ...filters, version_code_from })}
                />

                <InputFilter
                  controlId="filter-version-code-to"
                  label={t('releases.versionCodeTo', 'Version code to')}
                  value={filters.version_code_to}
                  onChange={version_code_to => setFilters({ ...filters, version_code_to })}
                />
                
              </>
            )}

            <div className="spacer" />

            <div>
              <button
                className="icon-button filter-button filter-button-toggle btn btn-primary btn-sm"
                onClick={() => setVisibility(isFull ? 'partial' : 'full')}
              >
                {isFull ? (
                  t('filters.less', 'Less')
                ) : (
                  t('filters.more', 'More')
                )}
                {isFull ? (
                  <i className="vtmn-icon_chevron_up_v2 right"></i>
                ) : (
                  <i className="vtmn-icon_chevron_down_v2 right"></i>
                )}
              </button>

              <button
                className="icon-button filter-button filter-button-clear btn btn-primary btn-sm"
                onClick={onClear}
              >
                <i className="vtmn-icon_cross"></i>
                <Trans i18nKey="filters.clear">Clear</Trans>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filters;
