import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import { InputGroup, Form, Button, SplitButton, Dropdown } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import ZXScanner from './scanner/ZXScanner';
import ScannerButton from './scanner/ScannerButton';
import { useUpdatableDebounce } from '../../functions/useDebounce';
import useRequest from '../../functions/useRequest';
import { handleApiResponse } from '../../functions/handleApiResponse';
import fetchWithJWT from '../../functions/fetchWithJWT';
import { logBarcodeReadEvent } from '../../functions/analytics';
import '../../stylesheets/itemScannerInput.css';


const getAddress = (t, user, updateTokens) => async (store, address) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/addresses/${address}/status`;
  const response = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  logBarcodeReadEvent({ success: response.exists, readingType: 'address', value: address });
  if (!response.exists) {
    throw new Error(t('addressScanner.addressNotExist', 'Address does not exist'))
  }
  if (response.disabled) {
    throw new Error(t('addressScanner.addressDisabled', 'Address is existing but was disabled'))
  }
  return address;
};

export const getLockers = async ({ store, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const params = {
    range: '0-100',
    disabled: false,
    address_type: 'locker',
  };
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/addresses?${new URLSearchParams(params).toString()}`;
  const lockers = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
  return lockers;
};

const AddressScannerInputLockers = ({ lockers, isFetching, defaultLocker, onChange }) => {
  if (isFetching) {
    return <ClipLoader color="#ccc" size={15} loading />;
  }
  if (_.isArray(lockers) && lockers.length) {
    if (lockers.length === 1) {
      return (
        <Button
          variant="light"
          size="sm"
          onClick={() => onChange(lockers[0].address)}
        >
          {lockers[0].address}
        </Button>
      );
    }
    const firstLocker = defaultLocker || lockers[0].address;
    const otherLockers = defaultLocker ? lockers.filter(l => l.address !== defaultLocker) : lockers.slice(1);

    return (
      <SplitButton
        onClick={() => onChange(firstLocker)}
        size="sm"
        variant="light"
        title={
          <div className="d-flex flex-row align-items-center">
            {firstLocker}
          </div>
        }
      >
        {otherLockers.map((l, index) => (
          <Dropdown.Item key={index} onClick={() => onChange(l.address)}>
            <div className="d-flex flex-row align-items-center">
              {l.address}
            </div>
          </Dropdown.Item>
        ))}
      </SplitButton>
    );
  }
  return null;
};


const AddressScannerInput = ({
  user,
  updateTokens,
  store,
  value,
  onChange,
  validatedAddress,
  setValidatedAddress,
  placeholder,
  onSubmit,
  displayLockers,
  defaultLocker,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [lockers, setLockers] = useState([]);
  const [debouncedValue, setDebouncedValue] = useUpdatableDebounce(value, 500);
  const [{
    loading,
  }, fetchAddress] = useRequest(getAddress(t, user, updateTokens), {
    onSuccess: setValidatedAddress,
    onError: setError,
  });
  const [{
    loading: lockersFetching,
  }, fetchLockers] = useRequest(getLockers, {
    onSuccess: (val) => {
      if (_.isArray(val)) {
        setLockers(val)
      }
    },
    onError: setError,
  });
  const scannerRef = useRef();


  useEffect(
    () => {
      if (debouncedValue && debouncedValue !== validatedAddress) {
        fetchAddress(store, debouncedValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [store, debouncedValue],
  );

  useEffect(
    () => {
      if (displayLockers && store) {
        fetchLockers({ store, user, updateTokens });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [store, displayLockers],
  );

  return (
    <InputGroup className="item-scanner-input">
      <Form.Control
        isValid={value && value === validatedAddress}
        isInvalid={!loading && !!error}
        placeholder={placeholder}
        type="text"
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
          setError(null);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.repeat) {
            setTimeout(() => {
              if (onSubmit) {
                onSubmit();
              } else {
                fetchAddress(store, value);
              }
            } , 100);
          }
        }}
      />
      {loading && (
        <InputGroup.Append className="item-scanner-input-loader">
          <InputGroup.Text>
            <ClipLoader color="#ccc" size={15} loading />
          </InputGroup.Text>
        </InputGroup.Append>
      )}
      <InputGroup.Append>
        <ScannerButton scannerRef={scannerRef} disabled={loading} />
      </InputGroup.Append>
      {!loading && error && (
        <Form.Control.Feedback type="invalid">
          {error.message}
        </Form.Control.Feedback>
      )}
      {displayLockers && (
        <div className="item-scanner-bottom">
          <AddressScannerInputLockers
            lockers={lockers}
            isFetching={lockersFetching}
            defaultLocker={defaultLocker}
            onChange={onChange}
          />
        </div>
      )}
      <ZXScanner
        readingType="address"
        ref={scannerRef}
        onDetected={(val) => {
          onChange(val);
          setError(null);
          setDebouncedValue(val);
        }}
      />
    </InputGroup>
  );
};

export default AddressScannerInput;
