import { handleApiResponse, handlePaginatedApiResponse } from '../../functions/handleApiResponse';
import getQueryString from '../../functions/getQueryString';
import fetchWithJWT from '../../functions/fetchWithJWT';

const serverUrl = process.env.REACT_APP_SERVERURL;

// Get list of release base. Paginated
export const getReleasesQuery = async ({ filters, pagination, sort, lang, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  const queryString = getQueryString({
    filters: formatedFilters,
    pagination,
    sort,
    lang,
  }, true);
  const url = `${serverUrl}/v1/releases${queryString}`;
  const response = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
  return response;
};

// Get release by ID
export const getReleaseQuery = async ({ id, lang, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const queryString = getQueryString({ lang }, true);
  const url = `${serverUrl}/v1/releases/${id}${queryString}`;
  const response = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
  return response;
};

// Get release & changelog translations
export const getTranslationsQuery = async ({ id, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${serverUrl}/v1/releases/${id}/translations`;
  const response = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
  return response;
};

// Add release base
export const addReleaseQuery = async ({ release, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${serverUrl}/v1/releases`;
  const response = await fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body: JSON.stringify(release),
  })
  .then(handleApiResponse);
  return response;
};

// Edit release base
export const editReleaseQuery = async ({ id, release, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${serverUrl}/v1/releases/${id}`;
  const response = await fetchWithJWT(url, {
    method: 'PATCH',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body: JSON.stringify(release),
  })
  .then(handleApiResponse);
  return response;
};

// Remove release base
export const removeReleaseQuery = async ({ id, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${serverUrl}/v1/releases/${id}`;
  const response = await fetchWithJWT(url, {
    method: 'DELETE',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
  return response;
};

// Batch edit release translations
export const editReleaseTranslationsQuery = async ({ id, actions, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${serverUrl}/v1/releases/${id}/translations`;
  const { to_add = [], to_edit = [], to_delete = [] } = actions;
  const response = await fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body: JSON.stringify({ to_add, to_edit, to_delete }),
  })
  .then(handleApiResponse);
  return response;
};

// Add release changelog
export const addReleaseChangelogQuery = async ({ id, changelog, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${serverUrl}/v1/releases/${id}/changelog`;
  const response = await fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body: JSON.stringify(changelog),
  })
  .then(handleApiResponse);
  return response;
};

// Edit release changelog
export const editReleaseChangelogQuery = async ({ id, changelogId, changelog, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${serverUrl}/v1/releases/${id}/changelog/${changelogId}`;
  const response = await fetchWithJWT(url, {
    method: 'PATCH',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body: JSON.stringify(changelog),
  })
  .then(handleApiResponse);
  return response;
};

// Remove release changelog
export const removeReleaseChangelogQuery = async ({ id, changelogId, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${serverUrl}/v1/releases/${id}/changelog/${changelogId}`;
  const response = await fetchWithJWT(url, {
    method: 'DELETE',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
  return response;
};

// Batch edit release changelog translations
export const editReleaseChangelogTranslationsQuery = async ({ id, changelogId, actions, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${serverUrl}/v1/releases/${id}/changelog/${changelogId}/translations`;
  const { to_add = [], to_edit = [], to_delete = [] } = actions
  const response = await fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body: JSON.stringify({ to_add, to_edit, to_delete }),
  })
  .then(handleApiResponse);
  return response;
};
