import { Badge } from 'react-bootstrap';
import dateFormat from 'dateformat';
import _ from 'lodash';

import { getErrorTooltip, normalizeName } from '../../functions/formatText';
import decodeEPC from '../../functions/decodeEPC';

export const getItemsForItemView = (orders) => {
  const boxItems = [];
  for (const order of orders) {
    for (const item of order.items) {
      if (item.resolve_action === 'picked') {
        boxItems.push({
          item,
          order: {
            ...order,
            items: [item],
          },
        })
      }
    }
  }
  return _.orderBy(boxItems, ['create_date'], ['desc']);
};

const resolveActionTagColor = {
  Picked: 'success',
  Wait: 'warning',
  'To replace': 'danger',
  'To cancel': 'danger',
  'Cancelled': 'warning',
  'To pick': 'primary',
  'Pick cancelled': 'primary',
};

export const getDetailsStatusTagColor = (resolve_action) => resolveActionTagColor[resolve_action] || 'primary';

export const itemToFieldsData = (t, boxItem, order) => {
  const { order_id, customer_name } = order;
  const itemStatus =
    boxItem.order_details_status === 'To pick' && boxItem.resolve_action === 'picked' && order.status === 'cancelled'
      ? 'Pick cancelled'
      : boxItem.order_details_status
  const fields = [
    ...(
      boxItem.resolve_action === 'picked'
        ? [{ label: t('trolley.boxNumber', 'Box number'), value: `#${boxItem.box_number}` }]
        : []
    ),
    { label: t('shared.order', 'Order'), value: order_id },
    {
      label: t('orders.status', 'Status'),
      value: (
        <Badge variant={getDetailsStatusTagColor(itemStatus)}>
          {itemStatus}
        </Badge>
      ),
    },
    { label: t('orders.customer', 'Customer'), value: normalizeName(customer_name) },
    { label: t('items.item', 'Item'), value: boxItem.item_id },
    { label: t('items.qty', 'Qty'), value: `${boxItem.qty || '-'}` },
    {
      label: boxItem.resolve_action === 'picked' ? t('trolley.pickDate', 'Pick date') : t('trolley.changeDate', 'Change date'),
      value: dateFormat(boxItem?.create_date, "dd/mm/yyyy HH:MM:ss"),
    },
  ];
  if (boxItem.addresses?.length) {
    fields.push({ label: t('items.address', 'Address'), value: boxItem.addresses.join(',') });
  }
  return fields;
};

export const enrichOrderItems = (order, itemInfos) => {
  const boxWithInfo = {
    ...order,
    items: order.items.map((boxItem) => {
      const { epcs, ...boxRest } = boxItem;
      const tags = (epcs || []).map(epc => {
        const { ean, serial12 } = decodeEPC(epc) || {};
        return { epc, ean: ean || '', serial: serial12 || '' };
      });
      const item_info = (itemInfos || []).find((info) => info.item_id === boxItem.item_id) || null;
      return {
        ...boxRest,
        id: `${boxRest.resolve_action === 'picked' ? 'b' : 'cp'}-${boxRest.id}`,
        tags,
        item_info,
      }
    }),
  };
  return boxWithInfo;
};

export const isOrderAddressed = (order) => {
  return order.can_address && order.items.filter(x => (x?.addressed_qty || 0) < x.qty).length === 0;
};

export const getEcomOrderStatusType = (status) => {
  const EcomOrderStatusTypeMap = {
    addressed: 'success',
    anomaly: 'danger',
    cancelled: 'warning',
    inProgress: 'primary',
    manual: 'warning',
    packed: 'success',
    pendingCarrier: 'primary',
    picked: 'primary',
    readyForCarrier: 'success',
    readyToAddress: 'primary',
    readyToPrint: 'primary',
    shipped: 'success',
    wait: 'warning',
    error: 'danger',
  };
  return (status && EcomOrderStatusTypeMap[status]) || null;
};

export const getEcomOrderStatusLabel = (status, t) => {
  const EcomOrderStatusLabelMap = {
    addressed: t('trolley.statuses.addressed', 'Addressed'),
    anomaly: t('trolley.statuses.anomaly', 'Anomaly'),
    cancelled: t('trolley.statuses.cancelled', 'Cancelled'),
    inProgress: t('trolley.statuses.inProgress', 'In progress'),
    manual: t('trolley.statuses.manual', 'Manual'),
    packed: t('trolley.statuses.packed', 'Packed'),
    pendingCarrier: t('trolley.statuses.pendingCarrier', 'Waiting for carrier response'),
    picked: t('trolley.statuses.picked', 'Picked'),
    readyForCarrier: t('trolley.statuses.readyForCarrier', 'Ready to carrier'),
    readyToAddress: t('trolley.statuses.readyToAddress', 'Ready to address'),
    readyToPrint: t('trolley.statuses.readyToPrint', 'Ready to print'),
    shipped: t('trolley.statuses.shipped', 'Shipped'),
    wait: t('trolley.statuses.wait', 'Wait'),
    error: t('trolley.statuses.error', 'Error'),
  };
  return (status && EcomOrderStatusLabelMap[status]) || null;
};

export const getOrderMessages = (order, t) => {
  const isCancelled = _.every(order.items.map(x => x.order_details_status === 'Cancelled'));
  const messages = [];
  if (order.finalize_error) {
    messages.push({
      icon: 'notif_alert',
      variant: 'danger',
      message: `${t('ecomTrolley.finalizeError', 'Finalize error: ')}${getErrorTooltip(order.finalize_error)}`,
    });
  }
  if (order.finalize_status === 'cancelled') {
    messages.push({
      icon: 'notif_alert',
      variant: 'warning',
      message: t('trolley.otherCancelledOrderMessage', 'Order was cancelled with other app'),
    });
  } else if (isCancelled) {
    messages.push({
      icon: 'notif_alert',
      variant: 'warning',
      message: t('trolley.cancelledOrderMessage', { defaultValue: 'Order {{order}} was cancelled', order: order.order_id })
    });
  }
  return messages;
};


export const handleFinalizeRes = (res, onError, t) => {
  if (res?.error_count > 0) {
    onError(new Error(t('trolley.finalizeTrolleyErrors', {
      defaultValue: "{{count}} error happened during finalize",
      count: res.error_count,
    })));
  }
  const hasWarnings = res?.warnings?.length > 0;
  if (hasWarnings) {
    onError(new Error(t('trolley.cancelledOrdersMessage', {
      defaultValue: '{{count}} orders were cancelled with other app',
      count: res.warnings.length,
    })));
  }
}
