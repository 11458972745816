import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const HelpTooltipIcon = ({ id, children, placement }) => (
  <OverlayTrigger
    placement={placement || "left"}
    overlay={
      <Tooltip className="help-tooltip" id={id}>
        <div className="d-flex flex-column justify-content-start align-items-start">
          {children}
        </div>
      </Tooltip>
    }
  >
    <i className="vtmn-icon_notif_help help-tooltip-icon text-primary" />
  </OverlayTrigger>
);

export default HelpTooltipIcon;
