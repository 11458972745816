import React, { useRef } from 'react';
import { Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import { Button, InputGroup } from 'react-bootstrap';

const SortSelect = ({
  controlId,
  sort,
  updateSort,
  getSortIcon,
  options,
  variant,
}) => {
  const selected = sort.field;
  const icon = getSortIcon(sort.field);
  const onIconClick = () => updateSort(sort.field);
  const ref = useRef();
  const selectedItems = (selected && options.filter(val => String(val.id) === String(selected))) || [];
  return (
    <InputGroup>
      <Typeahead
        ref={ref}
        className="input-select"
        id={controlId}
        options={options}
        selected={selectedItems}
        filterBy={() => true}
        onChange={([opt]) => updateSort(opt && opt.id)}
        renderMenu={(opts, menuProps) => (
          <Menu {...menuProps}>
            {opts.map((opt, index) => {
              const selectedCls = String(opt.id) === String(selected) ? 'font-weight-bold' : '';
              const sortIcon = getSortIcon(opt.id);
              return (
                <MenuItem key={index} option={opt} position={index}>
                  <div className={`d-flex flex-row align-items-center justify-content-between ${selectedCls}`}>
                    {opt.label}
                    {' '}
                    <i className={`sort-icon vtmn-icon_${sortIcon} ${selectedCls}`} />
                  </div>
                </MenuItem>
              )
            })}
          </Menu>
        )}
      />
      <InputGroup.Append
        className="item-scanner-input-loader"
        onClick={onIconClick}
      >
        <Button variant={variant || "secondary"} className="icon-button m-0 pl-2 pr-0">
          <i className={`sort-icon font-weight-bold vtmn-icon_${icon}`} />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
}

export default SortSelect;
