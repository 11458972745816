import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import WithSidebar from './WithSidebar';
import PrintLabelModal from '../components/print/printLabelModal';
import fetchWithJWT from '../functions/fetchWithJWT';
import '../stylesheets/catalog.css';

const serverUrl = process.env.REACT_APP_SERVERURL;

const getBarcode = (item) => item.item_id;

const getText = (item) => `${item.item_id} ${item.item_description}`;

const defaultOptions = {
  width: 10,
  height: 2,
  padding: 0.2,
  fontsize: 10,
  ratio: 0.6,
  fit: false,
};

class Catalog extends Component {
  constructor(props){
    super(props);
    this.state = {
      item: "",
      isLoadingCatalog: false,
      listOfItems: [],
      itemToCreate: {
        item_id: null,
        item_description: null,
        department_description: ""
      },
      itemToPrint: {
        item_id: null,
        item_description: null,
        department_description: ""
      },
      creationAllowed: true,
      firstAvailableItemCode: ""
    }
  }

  componentDidMount(){
    this.findFirstAvailableCode();
  }

  findFirstAvailableCode = () => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    fetchWithJWT(`${serverUrl}/v1/items/first_available_item_code`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(result => result.json())
    .then(result => {
      this.setState({
        firstAvailableItemCode: result.text.toString(),
        itemToCreate: {...this.state.itemToCreate, item_id: result.text.toString()}
      });
    })
    .catch(error => {
      console.warn(`Error while getting the first available item code : ${error}`);
    })
  }

  searchCatalogue = (query) => {
      const item = query?.target?.value;
      this.setState({
        isLoadingCatalog: true,
        item,
      });
      const { user, updateTokens } = this.props;
      const { token, refreshToken, tokenExpireDate } = user;
      if (item){
        return fetchWithJWT(`${serverUrl}/v1/items/stores/${this.props.match.params.store}/query/${item}`, {
          jwtOpts: {
            token,
            refreshToken,
            tokenExpireDate,
            updateTokens,
          }
        })
        .then(response => response.json())
        .then(json => this.setState({
            listOfItems: json,
            isLoadingCatalog : false
        }))
        .catch(error => {
          console.warn(`Error while loading from catalog for query : ${item}`);
        })
      } else {
        this.setState({
          listOfItems: [],
          isLoadingCatalog : false
        })
      }
  }

  searchCatalogueUpdate = (query) => {
      this.setState({
        isLoadingCatalog: true
      });
      const { user, updateTokens } = this.props;
      const { token, refreshToken, tokenExpireDate } = user;
      if (query !== ""){
        return fetchWithJWT(`${serverUrl}/v1/items/stores/${this.props.match.params.store}/query/${query}`, {
          jwtOpts: {
            token,
            refreshToken,
            tokenExpireDate,
            updateTokens,
          }
        })
        .then(response => response.json())
        .then(json => this.setState({
            listOfItems: json,
            isLoadingCatalog : false
        }))
        .catch(error => {
          console.warn(`Error while loading from catalog for query : ${query}`);
        })
      } else {
        this.setState({
          listOfItems: [],
          isLoadingCatalog : false
        })
      }
  }


  displayRow = (row) => {
    return(
      <tr key = {row.id}>
        <td>
          <button
            type="button"
            className="btn btn-sm btn-secondary"
            data-toggle="modal"
            data-target="#printLabel"
            onClick={() => {
              this.setState({
                itemToPrint: {
                  item_id: row.id,
                  item_description: row.item_description,
                  department_description: row.department_description,
                }
              });
            }}
          >
            <i className="vtmn-icon_printer"></i>
          </button>
        </td>
        <td>{row.id}</td>
        <td>
          <div className="row">
            {row.item_description}
            {' '}
            <span className="d-inline-flex" data-toggle="modal" data-target={`#changeDescription-${row.id}`} ><i className="vtmn-icon_edit"></i></span>
          </div>
        </td>
        <td>{row.department_description}</td>
      </tr>
    )
  }

  createModalsChangeDescription = (row) => {
    return(
      <div key={row.id} className="modal fade" id={`changeDescription-${row.id}`} tabIndex="-1" role="dialog" aria-labelledby={`changeDescription-${row.id}`} aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <Trans i18nKey="catalog.changeDescriptionTitle">Change item description for item</Trans>
                {' '}
                {row.id}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="closeModal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="text">
                <Trans i18nKey="catalog.currentDescription">Current description:</Trans>
                {' '}
                {row.item_description}
              </div>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <Trans i18nKey="catalog.newDescription">New description</Trans>
                  </span>
                </div>
                <input type="text" className="form-control m-0" onChange={event => this.setState({new_description: event.target.value})} />
              </div>
              <div><span id="error">{ this.state.error }</span></div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal">
                <Trans i18nKey="shared.cancel">Cancel</Trans>
              </button>
              <button type="button" className="btn btn-success" onClick={() => this.changeDescription(row.id, row.department_description, this.state.new_description)} >
                <Trans i18nKey="shared.create">Create</Trans>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  changeDescription = (item_id, department_description, new_description) => {
    const { t } = this.props;
    if (department_description.split("Z_")[0] !== ""){
      alert(t('catalog.alertCanEditZ', 'You can only modify items with sport starting by Z_ to avoid differences with masterdata'));
    } else {
      const myBody = JSON.stringify({
        item_id: item_id,
        new_description: new_description
      });
      const { user, updateTokens } = this.props;
      const { token, refreshToken, tokenExpireDate } = user;
      fetchWithJWT(`${serverUrl}/v1/items`,{
        method: "PATCH",
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        },
        body: myBody
      })
      .then(response => response.json())
      .then(response => {
        if (response.code !== "200"){
          const alertPrefix = t('catalog.alertErrorChangeDescription', 'Error while changing description');
          alert(`${alertPrefix} : ${response.text}`);
        }
      })
      .then(function() {
        document.getElementById(`closeModal`).click();
      })
      .then(result => this.searchCatalogueUpdate(item_id))
      .catch(error => {
        console.warn(`Error while updating item ${item_id} : ${error}`);
      })
    }
  }

  createItem = (item) => { //gérer le item = ""
    this.setState({error: null});
    const { t, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const trimmedItemId = item.item_id.trim();
    if(trimmedItemId.length !== 8) {
      const alertPrefix = t('catalog.alert8Digits', 'You can only create an item if it has 8 digits. Your item is');
      alert(`${alertPrefix} : ${trimmedItemId}`);
    } else if (item.department_description.slice(0,15) !== "Z_Components / ") {
      alert(t('catalog.alertShouldZ', 'Your sport should start by Z_Components / '));
    } else {
      fetchWithJWT(`${serverUrl}/v1/items/${trimmedItemId}/stores/${this.props.match.params.store}`, {
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        }
      })
      .then(response => response.json())
      .then(response => {
        if(response.item_id === "location"){
          const myBody={
            item_id: trimmedItemId,
            item_description: item.item_description,
            department_description: item.department_description
          };
          return fetchWithJWT(`${serverUrl}/v1/items`, {
            jwtOpts: {
              token,
              refreshToken,
              tokenExpireDate,
              updateTokens,
            },
            method: 'POST',
            body: JSON.stringify(myBody)
          })
          .then(result => result.json())
          .then(result => {
            if (result.code === "201"){
              const alertText = t('catalog.createdSuccess', {
                defaultValue: 'Item {{item}} successfully created',
                item: trimmedItemId,
              });
              alert(alertText);
              this.findFirstAvailableCode();
            } else {
              const alertPrefix = t('catalog.alertProblemCreation', 'Problem during item creation');
              alert(`${alertPrefix} : ${result.code} - ${result.text}`);
            }
          })
          .then(function() {
            document.getElementById(`closeModal`).click();
          })

        } else {
          const error = t('catalog.errorExists', {
            defaultValue: 'Item code {{item}} already exists',
            item: trimmedItemId,
          });
          this.setState({ error });
        }
      })
      .catch(error => {
        console.warn(`Error while creating item ${trimmedItemId} : ${error}`);
      })
    }
  }

  render(){
    const { t } = this.props;
    return(
      <WithSidebar user={this.props.user} updateTokens={this.props.updateTokens} logOut={this.props.logOut}>
      <div className = "jumbotron container catalog-page" style={{position:"relative"}}>
        <h2 className="text-center">
          <Trans i18nKey="catalog.catalog">Catalog</Trans>
        </h2>
        <div className="input-group mb-3">
          <input
            id="itemInput"
            type="text"
            placeholder={t('catalog.enterItem', 'Enter item code or description')}
            className="form-control form-control-lg col"
            onChange={(event) => this.searchCatalogue(event)}
          />
          <button type="button" className="btn blueButton btn-sm" data-toggle="modal" data-target="#newItemModal">
            <Trans i18nKey="catalog.newItem">New item</Trans>
          </button>
        </div>

        <div className="mobile-table-wrapper">
          <table className="table table-hover text-center mobile-table">
            <thead>
              <tr>
                <th width={34}>&nbsp;</th>
                <th>
                  <Trans i18nKey="catalog.itemCode">Item code</Trans>
                </th>
                <th>
                  <Trans i18nKey="catalog.description">Description</Trans>
                </th>
                <th>
                  <Trans i18nKey="catalog.sport">Sport</Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.listOfItems.map(row => this.displayRow(row))}
            </tbody>
          </table>
        </div>
      </div>

      {this.state.listOfItems.map(row => this.createModalsChangeDescription(row))}

      <div  className="modal fade" id="newItemModal" tabIndex="-1" role="dialog" aria-labelledby="newItemModal" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <Trans i18nKey="catalog.newItemTitle">Create new item</Trans>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="closeModal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <Trans i18nKey="catalog.itemCode">Item code</Trans>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control m-0"
                  value={this.state.itemToCreate.item_id || ''}
                  onChange={event => this.setState({
                    itemToCreate: {
                      ...this.state.itemToCreate,
                      item_id: event.target.value
                    }
                  })}
                />
              </div>
              <div><span id="error">{ this.state.error }</span></div>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <Trans i18nKey="catalog.itemDescription">Item description</Trans>
                  </span>
                </div>
                <input type="text" className="form-control m-0" onChange={event => this.setState({itemToCreate: {...this.state.itemToCreate, item_description: event.target.value}})} />
              </div>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <Trans i18nKey="catalog.sport">Sport</Trans>
                  </span>
                </div>
                <input type="text" className="form-control m-0" onChange={event => this.setState({itemToCreate: {...this.state.itemToCreate, department_description: event.target.value}})} />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal">
                {' '}
                <Trans i18nKey="shared.cancel">Cancel</Trans>
                {' '}
              </button>
              <button type="button" className="btn btn-success" onClick={() => this.createItem(this.state.itemToCreate)} >
                <Trans i18nKey="shared.create">Create</Trans>
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#printLabel"
                onClick={() => {
                  this.setState({
                    itemToPrint: this.state.itemToCreate,
                  });
                }}
              >
                <Trans i18nKey="catalog.printLabel">Print label</Trans>
              </button>
            </div>
          </div>
        </div>
      </div>
      <PrintLabelModal
        getBarcode={getBarcode}
        getText={getText}
        defaultOptions={defaultOptions}
        item={this.state.itemToPrint}
        storageOptsKey="printItemLabelOptions"
      />

      </WithSidebar>
    );
  }

}

export default withTranslation()(Catalog);
