import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Select from '../../components/widgets/Select';

import { RELEASE_TYPE_OPTIONS } from './constants';

const ReleaseBaseForm = (props) => {
  const { release, disabled, onChange } = props;
  const { t } = useTranslation();

  return (
    <>
      
      <Form.Row>
        <Form.Group as={Col} className="mb-3 text-left">
          <Form.Label className="my-1 mr-2 small">{t('releases.version', 'Version')}</Form.Label>
          <Form.Control
            id="version"
            placeholder={t('releases.version', 'Version')}
            disabled={disabled}
            required
            value={release.version}
            isInvalid={!disabled && !release.version.length}
            onChange={(e) => onChange({ ...release, version: e.target.value || '' })}
          />
        </Form.Group>
        <Form.Group as={Col} className="mb-3 text-left">
          <Form.Label className="my-1 mr-2 small">{t('releases.versionCode', 'Version code')}</Form.Label>
          <Form.Control
            id="version-code"
            placeholder={t('releases.versionCode', 'Version code')}
            disabled={disabled}
            required
            value={release.version_code}
            isInvalid={!disabled && !release.version_code}
            type="number"
            onChange={(e) => onChange({ ...release, version_code: e.target.value ?  Number(e.target.value): 0 })}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} className="mb-3 text-left">
          <Form.Label className="my-1 mr-2 small">{t('releases.type', 'Type')}</Form.Label>
          <Select
            id="release-id"
            placeholder={t('releases.type', 'Type')}
            selected={release.type}
            disabled={disabled}
            options={RELEASE_TYPE_OPTIONS}
            onChange={(v) => onChange({ ...release, type: v || null })}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="summary" className="mb-3 text-left">
          <Form.Label className="my-1 mr-2 small">{t('releases.summary', 'Summary')}</Form.Label>
          <Form.Control
            placeholder={t('releases.summary', 'Summary')}
            disabled={disabled}
            value={release.summary}
            onChange={(e) => onChange({ ...release, summary: e.target.value || '' })}
          />
          <Form.Text className="text-muted text-left ml-1">
            {t('releases.summaryDescription', 'Short summary of main release changes')}
          </Form.Text>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="has-breaking-changes" className="mt-3">
          <Form.Check
            disabled={disabled}
            type="switch"
            label={t('releases.breakingChanges', 'Breaking changes')}
            checked={release.has_breaking_changes}
            onChange={() => onChange({ ...release, has_breaking_changes: !release.has_breaking_changes })}
          />
        </Form.Group>
      </Form.Row>
    </>
  );
};

export default ReleaseBaseForm;
