import { useCallback } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

const initialState = {
  id: null,
  givenName: null,
  avatar: null,
  email: null,
  uid: null,
  token: null,
  refreshToken: null,
  tokenExpireDate: null,
  isLoggedIn: false,
  isLoggingIn: false,
  isLoggingOut: false,
  admin: true,
  isSuperAdmin: false,
  hasAuthError: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    updateTokens: (state, action) => {
      const { token, refreshToken, tokenExpiresIn } = action.payload;
      const now = Date.now() / 1000;
      const tokenExpireDate = now + tokenExpiresIn;
      state.token = token;
      state.refreshToken = refreshToken;
      state.tokenExpireDate = tokenExpireDate;
    }
  },
});

export const { setUserState, updateTokens } = userSlice.actions;

export const useUserReduxState = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const setUserState = useCallback((x) => dispatch(setUserState(x)), [dispatch]);
  const updateTokens = useCallback((x) => dispatch(updateTokens(x)), [dispatch]);

  return {
    user,
    setUserState,
    updateTokens,
  };
}

export default userSlice.reducer;
