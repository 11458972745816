import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import ScannerButton from '../../components/widgets/scanner/ScannerButton';
import useRequest from '../../functions/useRequest';
import { queryOrders } from './api';

const SearchByOrder = ({
  onSelectOrder,
  orderScannerRef,
  user,
  updateTokens,
  searchInputRef,
}) => {
  const { t } = useTranslation();
  const { store } = useParams();
  const [{
    loading,
    error,
    data: options,
  }, fetchQueryOrders] = useRequest(queryOrders);
  const [searchValue, setSearchValue] = useState('');
  const memoizedOnSearch = useCallback((search) => {
    setSearchValue(search);
    fetchQueryOrders({ store, search, user, updateTokens })
  }, [fetchQueryOrders, store, user, updateTokens]);

  return (
    <>
      {error && (<p id="search_error" className="text-center">{error.message || error}</p>)}
      <div className="form-group row col-12 mb-2">
        <div className="input-group">
          <AsyncTypeahead
            id="order_search"
            key="order_search"
            ref={searchInputRef}
            isLoading={loading}
            onSearch={memoizedOnSearch}
            placeholder={t('orders.enterOrder', 'Enter order / shipping group...')}
            useCache={false}
            minLength={2}
            delay={500}
            inputProps={{
              autoCorrect: 'off',
              autoComplete: 'off',
              autoCapitalize: 'none',
            }}
            options={options || []}
            filterBy={() => true}
            labelKey={op => `${op.order_number || op.shipping_group_numbers[0]}`}
            onChange={([option]) => {
              const value = option ? (
                option.order_number || option.shipping_group_numbers[0]
              ) : '';
              setSearchValue(value);
              onSelectOrder(value);
            }}
            onInputChange={value => {
              setSearchValue(value);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13 && !e.repeat) {
                setTimeout(() => {
                  document.getElementById("buttonOrderSubmit").click();
                }, 100);
              }
            }}
            renderMenuItemChildren={(option, props, index) => (
              <div className="order-search-item">
                <div className="order-number">
                  <Highlighter search={props.text}>
                    {`${option.order_number || '-'}`}
                  </Highlighter>
                </div>
                <div className="order-shipping-group-numbers">
                  <Highlighter search={props.text}>
                    {`${(option.shipping_group_numbers || []).join(', ')}`}
                  </Highlighter>
                </div>
              </div>
            )}
          />
          <div className="input-group-append">
            <button
              id="buttonOrderSubmit"
              className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_with-only-icon"
              onClick={() => onSelectOrder(searchValue)}
            >
              <i className="vtmn-icon_search"></i>
            </button>
            <ScannerButton scannerRef={orderScannerRef} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchByOrder;
