import React, { useRef } from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import ZXScanner from './scanner/ZXScanner';
import ZbarScanner from './scanner/ZbarScanner';
import ScannerButton from './scanner/ScannerButton';

const ScannerInput = ({
  value,
  onChange,
  onSubmit,
  placeholder,
  controlClassName,
  isValid,
  isInvalid,
  defaultFrameMode,
  zbar,
  readingType,
}) => {
  const scannerRef = useRef();
  return (
    <>
      <InputGroup>
        <Form.Control
          className={controlClassName}
          placeholder={placeholder}
          type="text"
          value={value}
          onChange={(event) => onChange(event.target.value)}
          onKeyDown={onSubmit ? (e) => {
            if (e.key === 'Enter' && !e.repeat) {
              setTimeout(() => onSubmit(value), 100);
            }
          } : undefined}
          isValid={isValid}
          isInvalid={isInvalid}
        />
        <InputGroup.Append>
          <ScannerButton scannerRef={scannerRef} />
        </InputGroup.Append>
      </InputGroup>
      {
        zbar
        ? (
          <ZbarScanner
            readingType={readingType}
            defaultFrameMode={defaultFrameMode}
            ref={scannerRef}
            onDetected={(val) => {
              onChange(val);
              if (onSubmit) {
                onSubmit(val);
              }
            }}
          />
        )
        : (
          <ZXScanner
            readingType={readingType}
            defaultFrameMode={defaultFrameMode}
            ref={scannerRef}
            onDetected={(val) => {
              onChange(val);
              if (onSubmit) {
                onSubmit(val);
              }
            }}
          />
        )
      }
    </>
  );
};

export default ScannerInput;
