import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ReleaseChangelogForm = (props) => {
  const { changelog, disabled, onChange } = props;
  const { t } = useTranslation();

  return (
    <>
      <Form.Row>
        <Form.Group as={Col} className="mb-3 text-left">
          <Form.Label className="my-1 mr-2 small">{t('shared.title', 'Title')}</Form.Label>
          <Form.Control
            id="title"
            placeholder={t('shared.title', 'Title')}
            disabled={disabled}
            required
            value={changelog.title || ''}
            isInvalid={!disabled && !changelog.title}
            onChange={(e) => onChange({ ...changelog, title: e.target.value || null })}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} className="mb-3 text-left">
          <Form.Label className="my-1 mr-2 small">{t('shared.description', 'Description')}</Form.Label>
          <Form.Control
            id="description"
            as="textarea"
            rows={3}
            placeholder={t('shared.description', 'Description')}
            disabled={disabled}
            value={changelog.description || ''}
            onChange={(e) => onChange({ ...changelog, description: e.target.value || null })}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} className="mb-3 text-left">
          <Form.Label className="my-1 mr-2 small">{t('releases.imageUrl', 'Image URL')}</Form.Label>
          <Form.Control
            id="image_url"
            placeholder={t('releases.imageUrl', 'Image URL')}
            disabled={disabled}
            value={changelog.image_url || ''}
            onChange={(e) => onChange({ ...changelog, image_url: e.target.value || null })}
          />
        </Form.Group>
      </Form.Row>
    </>
  );
};

export default ReleaseChangelogForm;
