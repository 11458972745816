import _ from 'lodash';
import { handleApiResponse } from '../../functions/handleApiResponse';
import fetchWithJWT from '../../functions/fetchWithJWT';
import { getLastEpcDeviceId } from '../../components/widgets/scanner/utils';
import { logBarcodeReadEvent } from '../../functions/analytics';

const groupByShippingGroup = (trackingItems) => {
  const bySg = _.groupBy(trackingItems || [], 'shipping_group.shipping_group_number');
  const SGs = Object.values(bySg).map((items) => ({
    shipping_group: items[0].shipping_group,
    order: items[0].order,
    free_order_link: items[0].free_order_link,
    related_shipping_groups: items[0].related_shipping_groups,
    items,
  }));
  return SGs;
};

const getCountByStore = (data) => {
  const byStore = {};
  for (const x of data) {
    const currentCnt = byStore[x.shipping_group.storeId]?.cnt || 0;
    byStore[x.shipping_group.storeId] = {
      storeId: x.shipping_group.storeId,
      storeName: x.shipping_group.storeName,
      cnt: currentCnt + 1,
    };
  }
  const countByStore = Object.values(byStore).map(x => ({
    store: `${x.storeId} - ${x.storeName}`,
    cnt: x.cnt,
  }));
  return countByStore;
}

const transformTrackingNumber = (trackingNumber) => {
  try {
    const { orders, unexpectedItems, data, comments_count, ...rest } = trackingNumber;
    const byState = _.groupBy(data, 'state');
    const wrongStores = data.filter(x => x.shipping_group?.is_wrong_store);
    const countByStore = getCountByStore(wrongStores);
    const forDifferentStores = (
      data.length > 0
      && (
        // mixed our store and wrong stores
        (countByStore.length === 1 && wrongStores.length !== data.length)
        // different wrong stores
        || countByStore.length > 1
      )
    );
    const allForWrongStore = (
      data.length > 0
      // all wrong stores
      && wrongStores.length === data.length
      // not different wrong stores
      && countByStore.length === 1
    );

    const countToReceive = byState.to_receive?.length || 0;
    const countStockItems = byState.stock?.length || 0;
    const countMissingItems = byState.missing?.length || 0;
    const countUnexpectedItems = byState.unexpected?.length || 0;
    const countInvalid = (byState.to_receive || []).filter(x => (
      x.shipping_group.is_canceled
      || x.shipping_group.is_completed
      || x.shipping_group.is_wrong_store
    )).length;
    const trackingNumberData = {
      ...rest,
      countToReceive,
      countStockItems,
      countMissingItems,
      countUnexpectedItems,
      countInvalid,
      countComments: comments_count,
      allForWrongStore,
      forDifferentStores,
      countByStore,
      toReceive: groupByShippingGroup(byState.to_receive),
      stock: groupByShippingGroup(byState.stock),
      missing: groupByShippingGroup(byState.missing),
      unexpected: groupByShippingGroup(byState.unexpected),
    };
    return trackingNumberData;
  } catch (e) {
    console.error('Error parsing tracking number data', e);
  }
}

export const getTrackingNumberData = async ({ store, trackingNumber, resetCache, user, updateTokens }) => {
  if (!trackingNumber) {
    return;
  }
  const { token, refreshToken, tokenExpireDate } = user;
  let url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/${encodeURIComponent(trackingNumber)}`;
  if (resetCache) {
    url += '?reset_cache=true';
  }
  const trackingNumberResp =  await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
  const trackingNumberData = transformTrackingNumber(trackingNumberResp);
  return trackingNumberData;
};

export const getTrackingNumberDuration = async ({ store, trackingNumber, user, updateTokens }) => {
  if (!trackingNumber) {
    return;
  }
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/${encodeURIComponent(trackingNumber)}/duration`;
  const duration =  await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
  return duration;
};

export const encodeEpc = async ({ sgtin, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/items/encode_epc/${sgtin}`;
  const { epc } =  await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
  return epc;
};

export const findNextItem = async ({
  store,
  trackingNumber,
  item_id,
  ean,
  epc,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/${trackingNumber}/find_next_item`;
  const body = {
    item_id,
    ean,
    epc,
  };
  try {
    const res = await fetchWithJWT(url, {
      method: 'POST',
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      body: JSON.stringify(body)
    })
    .then(handleApiResponse);
    logBarcodeReadEvent({ success: true, readingType: 'item', value: epc || ean || item_id });
    return res;
  } catch (e) {
    if (e.status === 404) {
      logBarcodeReadEvent({ success: false, readingType: 'item', value: epc || ean || item_id });
    }
    throw e;
  }
};

export const lockItem = ({
  store,
  tracking_number,
  tracking_number_item_id,
  force,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/${tracking_number}/item/${tracking_number_item_id}/lock`;
  const body = JSON.stringify({ force });
  return fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body
  })
  .then(handleApiResponse);
};

export const unlockItem = ({
  store,
  tracking_number,
  tracking_number_item_id,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/${tracking_number}/item/${tracking_number_item_id}/unlock`;
  const body = JSON.stringify({});
  return fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body
  })
  .then(handleApiResponse);
};

export const assignOrderItem = ({
  store,
  tracking_number,
  order_number,
  shipping_method,
  shipping_group_number,
  tracking_number_item_id,
  address,
  item_id,
  epc,
  force_same_item_to_address,
  is_tomount,
  shipperbox_number,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/assign_item`;
  const body = JSON.stringify({
    tracking_number,
    order_number,
    shipping_method,
    shipping_group_number,
    tracking_number_item_id,
    address,
    item_id,
    epc,
    epc_device_type: epc ? 'CAMERA_WEB' : null,
    epc_device_id: epc ? getLastEpcDeviceId() : null,
    force_same_item_to_address,
    is_tomount,
    shipperbox_number,
  });
  return fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body
  })
  .then(handleApiResponse);
};

export const saveUnexpectedItem = ({
  store,
  tracking_number,
  order_number,
  shipping_method,
  shipping_group_number,
  tracking_number_item_id,
  item_id,
  epc,
  missing_id,
  do_back_to_sale,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/unexpected_item`;
  const body = JSON.stringify({
    tracking_number,
    order_number,
    shipping_method,
    shipping_group_number,
    tracking_number_item_id,
    item_id,
    epc,
    missing_id,
    do_back_to_sale,
  });
  return fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body
  })
  .then(handleApiResponse);
};

export const foundMissing = ({
  store,
  id,
  epc,
  address,
  tracking_number,
  force_same_item_to_address,
  is_tomount,
  shipperbox_number,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/missing_item/${id}/found`;
  const body = JSON.stringify({
    epc,
    epc_device_type: epc ? 'CAMERA_WEB' : null,
    epc_device_id: epc ? getLastEpcDeviceId() : null,
    address,
    tracking_number,
    force_same_item_to_address,
    is_tomount,
    shipperbox_number,
  });
  return fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body
  })
  .then(handleApiResponse);
};

export const saveMissing = ({
  store,
  tracking_number,
  order_number,
  shipping_group_number,
  shipping_method,
  tracking_number_item_id,
  item_id,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/missing_item`;
  const body = JSON.stringify({
    tracking_number,
    order_number,
    shipping_group_number,
    shipping_method,
    tracking_number_item_id,
    item_id,
  });
  return fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body
  })
  .then(handleApiResponse);
};

export const findUnexpectedByItem = ({
  store,
  item_id,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const startDate = new Date();
  startDate.setHours(startDate.getHours() - 24);
  const formatedStartDate = startDate.toISOString();
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/unexpected_item/by_item/${item_id}?start_date=${formatedStartDate}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

export const unexpectedMatchMissing = ({
  store,
  id,
  tracking_number,
  order_number,
  shipping_group_number,
  shipping_method,
  item_id,
  epc,
  tracking_number_item_id,
  address,
  force_same_item_to_address,
  is_tomount,
  shipperbox_number,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/unexpected_item/${id}/match_missing`;
  const body = JSON.stringify({
    tracking_number,
    order_number,
    shipping_group_number,
    shipping_method,
    item_id,
    epc,
    epc_device_type: epc ? 'CAMERA_WEB' : null,
    epc_device_id: epc ? getLastEpcDeviceId() : null,
    tracking_number_item_id,
    address,
    force_same_item_to_address,
    is_tomount,
    shipperbox_number,
  });
  return fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body,
  })
  .then(handleApiResponse);
};

export const finishTrackingNumber = ({
  store,
  trackingNumber,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/${trackingNumber}/finish`;
  return fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body: JSON.stringify({}),
  })
  .then(handleApiResponse);
};

export const exportTrackingNumber = (store, tracking_number) => (oauthToken, user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/${tracking_number}/export`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({
      oauthToken,
    }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
};

export const getComments = async ({ store, trackingNumber, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/${trackingNumber}/comments`;
  const comments = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
  return comments;
};

export const postComment = async ({ store, trackingNumber, text, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/${trackingNumber}/comments`;
  const comment = await fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body: JSON.stringify({ text }),
  })
  .then(handleApiResponse);
  return comment;
};

/**
 *  Function to fetch nopack labels
 *
 * @return {Promise<Blob>} Return PDF blob
 */
export const getNopackLabels = async ({
  store,
  format,
  tracking_number,
  shipping_group,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const params = { tracking_number, shipping_group };
  if (format) {
    params.format = format;
  }
  const url = `${process.env.REACT_APP_SERVERURL}/v1/print/nopack_labels/${store}?${new URLSearchParams(params).toString()}`;
  const response = await fetchWithJWT(url, {
    method: 'GET',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  });
  if (response.status === 200) {
    const blob = await response.blob();
    return blob;
  }
  const res = await handleApiResponse(response).catch((error) => {
    // add order_ids from args to error response
    error.shipping_group = shipping_group;
    throw error;
  });
  return res;
};

export const printNopackLabels = async ({
  store,
  printer,
  format,
  tracking_number,
  shipping_group,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/print/nopack_labels/${store}/printer/${printer}/print`;
  const response = await fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({
      tracking_number,
      shipping_group,
      format,
    }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  });
  const res = await handleApiResponse(response).catch((error) => {
    // add order_ids from args to error response
    error.shipping_group = shipping_group;
    throw error;
  });
  return res;
};
