import _ from 'lodash';
import handleApiResponse from '../../functions/handleApiResponse';
import fetchWithJWT from '../../functions/fetchWithJWT';

/**
 *  Function to fetch shipping labels. Prop "shipping_group" can be a stringified array of values
 *
 * @return {Promise<Blob>} Return PDF blob
 */
export const getShippingLabels = async ({
  store,
  format,
  orientation,
  padding,
  order_ids,
  trolley_id,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const params = { order_id: order_ids.join(','), trolley_id };
  if (format) {
    params.format = format;
  }
  if (orientation) {
    params.orientation = orientation;
  }
  if (!_.isNil(padding)) {
    params.padding = padding;
  }
  const url = `${process.env.REACT_APP_SERVERURL}/v1/print/shipping_labels/${store}?${new URLSearchParams(params).toString()}`;
  const response = await fetchWithJWT(url, {
    method: 'GET',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  });
  if (response.status === 200) {
    const blob = await response.blob();
    return blob;
  }
  const res = await handleApiResponse(response).catch((error) => {
    // add order_ids from args to error response
    error.order_ids = order_ids;
    throw error;
  });
  return res;
};

export const printShippingLabels = async ({
  store,
  printer,
  format,
  orientation,
  padding,
  order_ids,
  trolley_id,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/print/shipping_labels/${store}/printer/${printer}/print`;
  const response = await fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({
      trolley_id,
      order_ids,
      format,
      orientation,
      padding,
    }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  });
  const res = await handleApiResponse(response).catch((error) => {
    // add order_ids from args to error response
    error.order_ids = order_ids;
    throw error;
  });
  return res;
};

export const assignAddress = async ({
  store,
  order_id,
  trolley_id,
  address,
  items,
  force_same_item_to_address,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/ecommerce_orders/${order_id}/address`;
  const body = JSON.stringify({
    trolley_id,
    address,
    items,
    force_same_item_to_address,
  })
  const res = await fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body,
  })
  .then(handleApiResponse);
  return res;
};

export const removeOrder = async ({
  store,
  order_id,
  trolley_id,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/ecommerce_trolleys/${trolley_id}/order/${order_id}`;
  const res = await fetchWithJWT(url, {
    method: 'DELETE',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
  return res;
}

export const finalizeTrolley = async ({
  store,
  trolley_id,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/ecommerce_trolleys/${trolley_id}/finalize`;
  const res = await fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
  return res;
}

export const finalizeOrder = async ({
  store,
  trolley_id,
  order_id,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/ecommerce_trolleys/${trolley_id}/order/${order_id}/finalize`;
  const res = await fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
  return res;
}

