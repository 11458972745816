import React from 'react';
import '../../stylesheets/rowField.css';

export const IconField = ({
  icon,
  title,
  value,
}) => {
  let rowClassName = `row-field d-flex flex-row`;
  let valueClassName = `row-field-value flex-fill text-break`;
  const iconClassNAme = `${icon} d-inline-flex align-items-center mr-1 ml-sm-2 lg-5`;
  let imgClassName = `img-icon text-secondary font-weight-bold d-inline-flex align-items-center mr-1 ml-sm-2 lg-5`;
  return (
    <div className={rowClassName} title={title}>
      {icon.includes('vtmn-icon') && (
        <i className={iconClassNAme}/>
      )}
      {!icon.includes('vtmn-icon') && (
        <img
          className={imgClassName}
          src={icon}
          alt={title}
        />
      )}
      <div className={valueClassName}>
        {value}
      </div>
    </div>
  );
};

const RowField = ({
  label,
  value,
  variant,
  inline,
  hideMobileLabel,
  boldMobileValue,
  textBreak,
  className,
}) => {
  const inlineClassName = inline ? 'flex-row' : 'flex-column';
  const textClassName = variant ? `text-${variant}` : '';
  let rowClassName = `row-field d-flex flex-sm-row ${inlineClassName}`;
  if (hideMobileLabel) {
    rowClassName += ' hide-mobile-label';
  }
  if (boldMobileValue) {
    rowClassName += ' bold-mobile-value';
  }
  if (className) {
    rowClassName += ` ${className}`;
  }

  const labelClassName = `row-field-label text-sm-right text-secondary font-weight-bold mr-2 ${textClassName}`;
  let valueClassName = `row-field-value flex-sm-fill ${textClassName}`;
  if (textBreak) {
    valueClassName += ' text-break';
  }
  return (
    <div className={rowClassName}>
      <div className={labelClassName}>
        {label}
        {label ? ':' : null}
      </div>
      <div className={valueClassName}>
        {value}
      </div>
    </div>
  );
};

export default RowField;
