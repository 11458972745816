import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroup, Form } from 'react-bootstrap';
import { BarcodeFormat } from '@zxing/browser';
import ZXScanner from '../../components/widgets/scanner/ZXScanner';
import ScannerButton from '../../components/widgets/scanner/ScannerButton';
import useDebounce from '../../functions/useDebounce';

const SearchByTrackingNumber = ({ onSearch, onFilter }) => {
  const { t } = useTranslation();
  const [trackingNumber, setTrackingNumber] = useState('');
  const debouncedTrackingNumber = useDebounce(trackingNumber, 200);
  useEffect(() => {
    onFilter(debouncedTrackingNumber);
  }, [onFilter, debouncedTrackingNumber]);
  const trackingScannerRef = useRef();
  const placeholder = t('trackingNumbers.enterTrackingNumber', 'Enter tracking number...');
  return (
    <Form.Group className="searchbar mb-2">
      <InputGroup>
        <Form.Control
          className="ml-0"
          type="text"
          value={trackingNumber}
          placeholder={placeholder}
          onChange={(event) => setTrackingNumber(event.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.repeat) {
              setTimeout(() => onSearch(trackingNumber), 100);
            }
          }}
        />
        <InputGroup.Append>
          <button
            id="buttonTrackingNumberSubmit"
            className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_with-only-icon"
            onClick={() => onSearch(trackingNumber)}
          >
            <i className="vtmn-icon_search"></i>
          </button>
          <ScannerButton scannerRef={trackingScannerRef} />
        </InputGroup.Append>
      </InputGroup>
      <ZXScanner
        readingType="trackingNumber"
        defaultFrameMode="off"
        formats={[BarcodeFormat.ITF]}
        ref={trackingScannerRef}
        onDetected={(trackingNumber) => {
          setTrackingNumber(trackingNumber);
          onSearch(trackingNumber);
        }}
      />
    </Form.Group>
  )
};

export default SearchByTrackingNumber;
