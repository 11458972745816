import { useCallback } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

const getDefaultFilters = () => {
  const startDate = new Date();
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);
  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + 21);
  startDate.setDate(startDate.getDate() - 30);
  return {
    date_from: startDate,
    date_to: endDate,
  };
};

const initialState = {
  activeTab: 'toProcess',  // toProcess, finished, missing, unexpected
  filters: getDefaultFilters(),
  expandedTabs: {
    toProcess: false,
    finished: false,
    missing: false,
    unexpected: false,
  }
};

export const trackingNumbersSlice = createSlice({
  name: 'trackingNumbers',
  initialState,
  reducers: {
    setActiveTabAction: (state, action) => {
      state.activeTab = action.payload;
    },
    setFiltersAction: (state, action) => {
      state.filters = action.payload;
      state.expandedTabs = initialState.expandedTabs;
    },
    setTabExpandedAction: (state, action) => {
      const tab = state.activeTab;
      state.expandedTabs[tab] = action.payload;
    }
  },
});

export const { setActiveTabAction, setFiltersAction, setTabExpandedAction } = trackingNumbersSlice.actions;

export const useTrackingNumbersState = () => {
  const filters = useSelector((state) => state.trackingNumbers.filters);
  const activeTab = useSelector((state) => state.trackingNumbers.activeTab);
  const tabExpanded = useSelector((state) =>
    state.trackingNumbers.expandedTabs[state.trackingNumbers.activeTab]
  );
  const dispatch = useDispatch();
  const setFilters = useCallback((x) => dispatch(setFiltersAction(x)), [dispatch]);
  const setActiveTab = useCallback((x) => dispatch(setActiveTabAction(x)), [dispatch]);
  const setTabExpanded = useCallback((x) => dispatch(setTabExpandedAction(x)), [dispatch]);

  return {
    filters,
    activeTab,
    tabExpanded,
    setFilters,
    setActiveTab,
    setTabExpanded,
  };
}

export default trackingNumbersSlice.reducer;
