import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal, Button, Badge, Alert } from 'react-bootstrap';
import RowField from './RowField';

import '../../stylesheets/imageWithDetailsModal.css';
import defaultImage from '../../images/default_image.png';
import dangerousItem from '../../images/dangerous_item.png';

const ListableImage = ({ imageUrl, pixlId, isFirst, isLast, onLeft, onRight }) => {
  const { t } = useTranslation();

  let imageSrc;
  if (imageUrl) {
    imageSrc = imageUrl;
  } else if (pixlId) {
    imageSrc = `https://contents.mediadecathlon.com/p${pixlId}/a.jpg?f=340x340`;
  } else {
    imageSrc = defaultImage;
  }

  return (
    <div className="d-flex flex-row justify-content-between align-items-center">
      <Button
        variant="light"
        className="icon-button"
        size="lg"
        disabled={isFirst}
        title={t('items.previousItem', 'Previous item')}
        onClick={onLeft}
      >
        <i className="vtmn-icon_arrow1_left font-weight-bold text-primary m-0" />
      </Button>
      {imageSrc && (
        <img
          src={imageSrc}
          alt=""
          style={{ maxWidth: '100%', width: 32, flex: '1 1 auto' }}
        />
      )}
      <Button
        variant="light"
        className="icon-button"
        size="lg"
        disabled={isLast}
        title={t('items.nextItem', 'Next item')}
        onClick={onRight}
      >
        <i className="vtmn-icon_arrow1_right font-weight-bold text-primary m-0" />
      </Button>
    </div>
  );
};

const Fields = ({ fields }) => {
  return (
    <div className="d-flex flex-column">
      {fields.map((field, index) => (
        <RowField
          inline
          key={index}
          label={field.label}
          value={field.value}
        />
      ))}
    </div>
  );
};

const ImageWithDetailsModal = ({
  item,
  item_description,
  customerTag,
  pixlId,
  imageUrl,
  show,
  onHide,
  fields,
  onLeft,
  onRight,
  isFirst,
  isLast,
  item_is_dangerous,
  endOfLife,
  messages,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          {item}
          {' - '}
          {item_description}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 d-flex flex-column justify-content-center align-items-stretch">
        {messages && messages.map(({ icon, variant, message }, index) => (
          <Alert key={index} variant={variant} className="rounded-0 p-2 d-flex justify-content-start align-items-center">
            {icon && <i className={`vtmn-icon_${icon} mr-2`} />}
            <div className="text-left">
              {message}
            </div>
          </Alert>
        ))}
        <div className="p-3">
          <ListableImage
            imageUrl={imageUrl}
            pixlId={pixlId}
            isFirst={isFirst}
            isLast={isLast}
            onLeft={onLeft}
            onRight={onRight}
          />
          {item_is_dangerous && (
            <div className="icon-dangerous-item">
              <img src={dangerousItem} alt="dangerous item" height="35" width="35"/>
            </div>
          )}
          <div className="image-details-tag-container">
            {endOfLife && (
              <span className="tag tag-warning">
                {t('items.endOfLife').toUpperCase()}
              </span>
            )}
          </div>
          {customerTag && (
            <h3 className="text-center mb-0">
              <Badge variant="dark">{customerTag}</Badge>
            </h3>
          )}
          <Fields fields={fields} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>
          {' '}
          <Trans i18nKey="shared.close">Close</Trans>
          {' '}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageWithDetailsModal;
