import React, { useState, useEffect } from 'react';
import { Spinner, Form, Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useUserReduxState } from '../../store/user';
import useRequest from '../../functions/useRequest';

import { getReleaseQuery } from './api';
import ReleasePreview from './ReleasePreview';
import ReleasePublishButton from './ReleasePublishButton';

const ReleasePreviewDialog = (props) => {
  const { show, id, lang, onEdit, onPublish, onHide } = props;
  const { t } = useTranslation();
  const { user, updateTokens } = useUserReduxState();

  const [release, setRelease] = useState(null);

  const [{ loading, error }, fetchRelease] = useRequest(getReleaseQuery, {
    onSuccess: setRelease,
  });

  useEffect(() => {
    fetchRelease({ id, lang, user, updateTokens });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id, lang]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="release-view-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {`${t('versions.releasePreview', 'Release preview')}${
            release ? ` ${release.version} (${release.version_code})` : ''
          }`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center d-flex justify-content-center">
        {!loading && !!error && (
          <Form.Control.Feedback type="invalid">
            {(error && error.message) || t('shared.error', 'Error')}
          </Form.Control.Feedback>
        )}
        {loading && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
          />
        )}
        {!!release && <ReleasePreview release={release} />}
      </Modal.Body>
      <Modal.Footer>
        {!!release && (
          <div className="mr-auto">
            <ReleasePublishButton
              published={release?.published || false}
              size="md"
              onClick={onPublish}
            />
          </div>
        )}
        <Button
          variant="light"
          onClick={onHide}
        >
          {t('shared.close', 'Close')}
        </Button>
        {!!onEdit && (
          <Button variant="primary" onClick={onEdit}>
            {t('shared.edit', 'Edit')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ReleasePreviewDialog;
