import React from 'react';
import { Trans } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';

const ImageReactModal = ({
  item,
  item_description,
  pixlId,
  imageUrl,
  show,
  onHide,
  tags,
}) => (
  <Modal
    show={show}
    onHide={onHide}
  >
    <Modal.Header closeButton>
      <Modal.Title as="h5">
        {item}
        {' - '}
        {item_description}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="d-flex justify-content-center align-items-center">
      {imageUrl && (
        <img
          key={item}
          src={imageUrl}
          alt=""
          style={{ maxWidth: '100%' }}
        />
      )}
      {pixlId && (
        <img
          key={pixlId}
          src={`https://contents.mediadecathlon.com/p${pixlId}/a.jpg?f=340x340`}
          alt=""
          style={{ maxWidth: '100%' }}
        />
      )}
      <div className="image-details-tag-container">
        {tags}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={onHide}>
        {' '}
        <Trans i18nKey="shared.close">Close</Trans>
        {' '}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ImageReactModal;
