import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import WithSidebar from './WithSidebar';
import OpenVIdeoModal from './openVideoModal';

class HelpFiles extends Component {
  constructor(props){
    super(props);
    const { t } = props;
    this.state= {
      movies:[
        {
          title:t('help.changeOrderButton', 'Change the address of an order'),
          youtubeUrl:"https://www.youtube.com/embed/3bbyfsapnQc?rel=0",
          youtubevideoId :"3bbyfsapnQc",
          thumbnail:"https://i.ytimg.com/vi/3bbyfsapnQc/1.jpg?time=1519403161738"
        },
        {
          title:t('help.checkItem', 'Check item location and stock'),
          youtubeUrl:"https://www.youtube.com/embed/ssNby-zLEo4?rel=0",
          youtubevideoId :"ssNby-zLEo4",
          thumbnail:"https://i.ytimg.com/vi/ssNby-zLEo4/1.jpg?time=1519403161738"
        },
        {
          title:t('help.pickItem', 'Pick an item'),
          youtubeUrl:"https://www.youtube.com/embed/f0bIqQ6JM-0?rel=0",
          youtubevideoId:"f0bIqQ6JM-0",
          thumbnail:"https://i.ytimg.com/vi/f0bIqQ6JM-0/1.jpg?time=1519403065362"
        },
        {
          title:t('help.addItem', 'Add stock to item'),
          youtubeUrl:"https://www.youtube.com/embed/YuC0PmVUfAo?rel=0",
          youtubevideoId:"YuC0PmVUfAo",
          thumbnail:"https://i.ytimg.com/vi/YuC0PmVUfAo/1.jpg?time=1519403131594"
        },
        {
          title:t('help.addAddress', 'Add a new address'),
          youtubeUrl:"https://www.youtube.com/embed/Wud68uqRGUs?rel=0",
          youtubevideoId:"Wud68uqRGUs",
          thumbnail:"https://i.ytimg.com/vi/Wud68uqRGUs/1.jpg?time=1519403110880"
        },
        {
          title:t('help.pickingList', 'The picking list'),
          youtubeUrl:"https://www.youtube.com/embed/x8tZC46l7jo?rel=0",
          youtubevideoId:"x8tZC46l7jo",
          thumbnail:"https://i.ytimg.com/vi/x8tZC46l7jo/1.jpg?time=1519403087775"
        },
        {
          title:t('help.checkReserve', 'Check your reserve'),
          youtubeUrl:"https://www.youtube.com/embed/Ss0YwybtWEk?rel=0",
          youtubevideoId:"Ss0YwybtWEk",
          thumbnail :"https://i.ytimg.com/vi/Ss0YwybtWEk/1.jpg?time=1519402782499"
        },
        {
          title:t('help.manageReserve', 'Manage your reserve'),
          youtubeUrl:"https://www.youtube.com/embed/Y6lu31oNq84?rel=0",
          youtubevideoId:"Y6lu31oNq84",
          thumbnail:"https://i.ytimg.com/vi/Y6lu31oNq84/1.jpg?time=1519403039063"
        }
      ],
    currentmovie_title:"",
    currentmovie_youtubeUrl:"",
    currentvideoId:"",
  };
  }
sendVideoId = (event) => {
  this.setState({
    currentmovie_title:event.title,
    currentmovie_youtubeUrl: event.youtubeUrl,
    currentvideoId:event.youtubevideoId,
  });
}

  render() {
    return(
      <WithSidebar user={this.props.user} updateTokens={this.props.updateTokens} logOut={this.props.logOut}>
      <div>
        <div className= "jumbotron container">
          <div className="row">
            <div className="videoList table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <td colSpan="2" style={{textAlign:"center"}}>
                      <Trans i18nKey="help.chooseTutorial">Choose a tutorial</Trans>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.movies.map((e) => {
                      return(
                      <tr key = {e.title} onClick= {() => this.sendVideoId(e)} >
                        <td>
                          <img src={e.thumbnail} alt={e.title}/>
                        </td>
                        <td>
                          {e.title}
                          <OpenVIdeoModal video= {this.state}/>
                        </td>
                    </tr>
                  )
                    })
                  }
                  </tbody>
              </table>
            </div>
        </div>
        </div>

      </div>
      </WithSidebar>
    );
  }
}
export default withTranslation()(HelpFiles);
