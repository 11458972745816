import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Alert, SplitButton, Dropdown } from 'react-bootstrap';
import { Link, useParams, useHistory } from 'react-router-dom';
import dateFormat from 'dateformat';
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/widgets/errorMessage';
import RowField from '../../components/widgets/RowField';
import Tabs from '../../components/widgets/Tabs';
import ButtonBadge from '../../components/widgets/ButtonBadge';
import {
  ResolvedMessage,
  GivenMessage,
  NoOrdersMessage,
  ForDifferentStoresMessage,
  AllForWrongStoreMessage,
} from './messages';
import { TNRelatedSgippingGroupMessage, getTNHandleRelatedSgPress } from '../../components/widgets/RelatedSgippingGroupMessage';
import MountStatus from '../OrdersMount/components/MountStatus';
import { getFreeOrderNumber } from '../Orders/messages';

const OrderItem = ({
  isToReceiveTab,
  orderItem,
  onAssign,
  onMissing,
  onFound,
  onUnexpectedItem,
  onPreview,
  onReviewMissing,
  onReviewUnexpected,
  onPreviewBarcode,
}) => {
  const { t } = useTranslation();
  const { store } = useParams();
  const isResolved = (
    (orderItem.state === 'unexpected' && orderItem.unexpected.resolved)
    || (orderItem.state === 'missing' && orderItem.missing.resolved)
  );
  const { item, shipping_group, stock, lockedBy, shipperbox_number } = orderItem;
  const isGiven = orderItem.given_to_customer;
  const orderAddresses = shipping_group?.addresses;
  const mountStatus = orderItem?.order?.mount_status;
  return (
    <div className="tracking-number-order-item">
      <div className="tracking-number-order-item-image">
        <img
          src={`${item.image}?f=200x200`}
          alt=""
          onClick={() => onPreview(orderItem)}
        />
        <MountStatus status={mountStatus} />
        {!!stock?.epc && (
          <i
            className="epc-verified text-success vtmn-icon_valid_rounded"
            title={t('trackingNumber.rfidVerified', 'Item verified by RFID')}
          />
        )}
      </div>
      <div className="order-item">
        <div>
          {item.item_id}
          {' - '}
          {item.item_description}
        </div>
        {isResolved && <ResolvedMessage orderItem={orderItem} />}
        {isGiven && <GivenMessage/>}
        {stock && stock.address && (
          <div>
            <Link className="text-primary font-weight-bold" to={`/${store}/searchAddress?address=${stock.address}`}>
              {stock.address}
            </Link>
            {stock.address_type === 'locker' && (
              <Button
                variant="yellow"
                size="sm"
                className="ml-2 barcode-button"
                title={t('address.viewBarcode', 'View barcode')}
                onClick={() => onPreviewBarcode(shipperbox_number || shipping_group.shipping_group_number)}
              >
                <img className="img-icon" alt="barcode" src="/icons/barcode_line_black.svg" />
              </Button>
            )}
          </div>
        )}
        {(isToReceiveTab && orderAddresses && orderAddresses.length) ? (
          <div className="text-primary font-weight-bold">
            <Trans i18nKey="trackingNumber.orderParts">This order has parts at</Trans>
            {': '}
            {orderAddresses.join(', ')}
          </div>
        ) : null}
        {lockedBy && (
          <div className="text-muted">
            <Trans i18nKey="trackingNumber.isProcessing">Is processing</Trans>
            {': '}
            {lockedBy}
          </div>
        )}
        {isToReceiveTab && item.source === 'catalog' && (
          <div className="text-warning">
            <Trans i18nKey="shared.warning">Warning</Trans>
            {': '}
            <Trans i18nKey="trackingNumber.messages.failedQuantity">Failed to load quantity from Cube api</Trans>
          </div>
        )}
      </div>
      {isResolved ? (
        <div className="order-actions">
          <i
            className="resolved-icon text-success vtmn-icon_valid_rounded"
            title={t('trackingNumber.resolved', 'Resolved')}
          />
        </div>
      ) : (
        <div className="order-actions">
          {onAssign ? (
            <Button
              block
              variant="success"
              onClick={() => onAssign(orderItem)}
            >
              <Trans i18nKey="trackingNumber.assign">Assign</Trans>
            </Button>
          ) : null}

          {onUnexpectedItem ? (
            <Button
              block
              variant="primary"
              onClick={() => onUnexpectedItem(orderItem)}
            >
              {(
                shipping_group?.is_canceled
                ? <Trans i18nKey="orders.putInStock.putInStock">Put in store stock</Trans>
                : <Trans i18nKey="trackingNumber.unexpected">Unexpected</Trans>
              )}

            </Button>
          ) : null}

          {onMissing ? (
            <Button
              block
              variant="danger"
              onClick={() => onMissing(orderItem)}
            >
              <Trans i18nKey="trackingNumber.missing">Missing</Trans>
            </Button>
          ): null}

          {onFound ? (
            <Button
              block
              variant="primary"
              onClick={() => onFound(orderItem)}
            >
              <Trans i18nKey="trackingNumber.found">Found</Trans>
            </Button>
          ) : null}

          {onReviewMissing ? (
            <Button
              block
              variant="warning"
              onClick={() => onReviewMissing(orderItem)}
            >
              <Trans i18nKey="trackingNumber.solve">Solve problem</Trans>
            </Button>
          ) : null}

          {onReviewUnexpected ? (
            <Button
              block
              variant="warning"
              onClick={() => onReviewUnexpected(orderItem)}
            >
              <Trans i18nKey="trackingNumber.solve">Solve problem</Trans>
            </Button>
          ) : null}
        </div>
      )}
    </div>
  );
};

const OrderShippingGroup = ({
  isToReceiveTab,
  order,
  shippingGroup,
  trackingNumberType,
  relatedShippingGroups,
  freeOrderLink,
  items,
  onAssign,
  onMissing,
  onFound,
  onUnexpectedItem,
  onPreview,
  onReviewMissing,
  onReviewUnexpected,
  onOrderComments,
  onPreviewBarcode,
  onPrintSummarySheet,
}) => {
  const canPrint = trackingNumberType === 'bicycle' || trackingNumberType === 'voluminous';
  const { t } = useTranslation();
  const history = useHistory();
  const { store } = useParams();
  const isReplacedBy = freeOrderLink?.link_type === 'is_replaced_by';
  const isReplacement = freeOrderLink?.link_type === 'is_replacement';
  const hasRelatedSG = !!relatedShippingGroups?.length;
  return (
    <div className={`tracking-number-shipping-group ${hasRelatedSG ? 'tracking-number-shipping-group-warning' : ''}`}>
      <div className="tracking-number-shipping-group-header">
        <div className="tracking-number-shipping-group-header-info">
          <RowField
            hideMobileLabel
            boldMobileValue
            label={t('trackingNumber.order', 'Order')}
            value={(
              <Link to={`/${store}/orders?order=${order.order_number}`}>
                {`${order.order_number || ''} - ${shippingGroup.shipping_group_number || ''}`}
              </Link>
            )}
          />
          <RowField
            hideMobileLabel
            label={t('trackingNumber.customer', 'Customer')}
            value={(
              <>
                <div>
                  {order.firstName || ''}
                  {' '}
                  {order.lastName || ''}
                </div>
                <div>{order.email || ''}</div>
              </>
            )}
          />
          <RowField
            inline
            label={t('trackingNumber.created', 'Created')}
            value={order.creationDate ? dateFormat(order.creationDate, 'dd/mm/yyyy HH:MM:ss') : '-'}
          />
          <RowField
            inline
            label={t('trackingNumber.shipping', 'Shipping')}
            value={shippingGroup.promisedShippingDate ? dateFormat(shippingGroup.promisedShippingDate, 'dd/mm/yyyy HH:MM:ss') : '-'}
          />
          {shippingGroup.is_wrong_store && (
            <RowField
              variant="danger"
              label={t('shared.warning', 'Warning')}
              value={(
                <>
                  <div>{`${t('trackingNumber.messages.forOtherStore','This is order for store')} ${shippingGroup.storeId || ''} - ${shippingGroup.storeName || ''}`}</div>
                  <div>{t('trackingNumber.messages.callCustomerService','Please call customer service')}</div>
                </>
              )}
            />
          )}
          {shippingGroup.is_canceled && (
            <RowField
              variant="danger"
              label={t('shared.warning', 'Warning')}
              value={t('trackingNumber.messages.orderCanceled', 'This order was cancelled. You can put the items into the store stock by clicking on the button.')}
            />
          )}
          {shippingGroup.is_completed && !isReplacedBy && (
            <RowField
              variant="danger"
              label={t('shared.warning', 'Warning')}
              value={t('trackingNumber.messages.orderCompleted', 'This order has already been completed')}
            />
          )}
          {shippingGroup.is_in_logistics && (
            <RowField
              variant="danger"
              label={t('shared.warning', 'Warning')}
              value={t('trackingNumber.messages.orderInLogistics', 'This order IN_LOGISTICS_PREPARATION, please call CRC to ask Warehouse to check this Case, or open a ticket to CC-LOG')}
            />
          )}
          {shippingGroup.cancel_in_progress && (
            <RowField
              variant="danger"
              label={t('shared.warning', 'Warning')}
              value={t('trackingNumber.messages.cancellationInProgress', 'Cancellation in progress')}
            />
          )}
          {isReplacedBy && (
            <RowField
              variant="danger"
              label={t('shared.warning', 'Warning')}
              value={
                <>
                  <span>{t('freeOrder.replacedByOrder', 'This order was replaced by free order:')} </span>
                  <Link className="text-danger" to={`/${store}/orders?order=${freeOrderLink.replacement_order_number}`}>
                    {getFreeOrderNumber(freeOrderLink)}
                  </Link>
                </>
              }
            />
          )}
          {isReplacement && (
            <RowField
              variant="primary"
              value={
                <>
                  <span>{t('freeOrder.replacementOrder', 'This is a free order replacing order:')} </span>
                  <Link to={`/${store}/orders?order=${freeOrderLink.order_number}`}>
                    {getFreeOrderNumber(freeOrderLink)}
                  </Link>
                </>
              }
            />
          )}
        </div>
        <div className="tracking-number-shipping-group-header-buttons">
          {canPrint ? (
            <SplitButton
              block
              className="pl-1 pr-1"
              variant="primary"
              onClick={() => onOrderComments(items[0])}
              title={
                <>
                  <Trans i18nKey="comments.comments">Comments</Trans>
                  {order.commentsCount ? <ButtonBadge value={order.commentsCount} /> : null}
                </>
              }
            >
              <Dropdown.Item onClick={() => onPrintSummarySheet(shippingGroup.shipping_group_number)}>
                <Trans i18nKey="trackingNumber.printSummarySheet">Print a summary sheet</Trans>
              </Dropdown.Item>
            </SplitButton>
          ) : (
            <Button
              block
              className="pl-1 pr-1"
              variant="primary"
              onClick={() => onOrderComments(items[0])}
            >
              <Trans i18nKey="comments.comments">Comments</Trans>
              {order.commentsCount ? <ButtonBadge value={order.commentsCount} /> : null}
            </Button>
          )}
        </div>
      </div>
      {items.map((item) => (
        <OrderItem
          isToReceiveTab={isToReceiveTab}
          key={item.id}
          orderItem={item}
          onAssign={(
            shippingGroup.is_wrong_store
            || shippingGroup.is_canceled
            || shippingGroup.is_completed
            || shippingGroup.is_in_logistics
          ) ? null : onAssign}
          onMissing={shippingGroup.is_in_logistics ? null : onMissing}
          onUnexpectedItem={(
            shippingGroup.is_wrong_store
            || shippingGroup.is_canceled
            || shippingGroup.is_completed
          ) ? onUnexpectedItem : null}
          onReviewMissing={onReviewMissing}
          onReviewUnexpected={onReviewUnexpected}
          onPreview={onPreview}
          onFound={onFound}
          onPreviewBarcode={onPreviewBarcode}
        />
      ))}
      {hasRelatedSG && (
        <TNRelatedSgippingGroupMessage
          relatedShippingGroups={relatedShippingGroups || []}
          mode="attached"
          onClick={getTNHandleRelatedSgPress(
            store,
            order,
            relatedShippingGroups || [],
            history,
          )}
        />
      )}
    </div>
  );
}

const UnexpectedItems = ({
  items,
  onPreview,
  onReviewUnexpected,
}) => {
  const hasNoItems = !items || !items.length;
  if (hasNoItems) {
    return null;
  }

  return (
    <div className="tracking-number-shipping-group">
      {items.map((item) => (
        <OrderItem
          key={item.id}
          orderItem={item}
          onPreview={onPreview}
          onReviewUnexpected={onReviewUnexpected}
        />
      ))}
    </div>
  );
};

const SummaryMessage = ({
  onUnexpected,
  onFinish,
  trackingNumberData,
  duration,
}) => {
  const {
    countStockItems,
    countMissingItems,
    countUnexpectedItems,
    status,
  } = trackingNumberData;

  const canFinish = status !== 'finished';

  if (duration.loading) {
    return <Loader/>;
  }

  if (!duration.loading && duration.error) {
    return <ErrorMessage error={duration.error} />
  }

  return (
    <>
      <Alert variant="info">
        <div>
          <Trans i18nKey="trackingNumber.summary.allProcessed">All items in this tracking number were processed:</Trans>
        </div>
        <ul>
          <li>
            <Trans i18nKey="trackingNumber.summary.addressed">Addressed</Trans>
            {': '}
            {countStockItems}
          </li>
          <li>
            <Trans i18nKey="trackingNumber.summary.missing">Missing</Trans>
            {': '}
            {countMissingItems}
          </li>
          <li>
            <Trans i18nKey="trackingNumber.summary.unexpected">Unexpected</Trans>
            {': '}
            {countUnexpectedItems}
          </li>
          <li>
            <Trans i18nKey="trackingNumber.summary.duration">Duration</Trans>
            {': '}
            {duration?.data?.performance_duration}
          </li>
        </ul>
        <Button className="mr-1 unexpected-item-button" variant="warning" onClick={onUnexpected}>
          <Trans i18nKey="trackingNumber.unexpectedItem">Unexpected item</Trans>
        </Button>
      </Alert>
      {canFinish && (
        <Alert variant="warning">
          <div>
            <Trans i18nKey="trackingNumber.summary.statusProblem">It seems there was a problem with changing a parcel status.</Trans>
          </div>
          <div>
            <Trans i18nKey="trackingNumber.summary.clickHere">Please click here:</Trans>
          </div>
          <Button className="mr-1" variant="warning" onClick={onFinish}>
            <Trans i18nKey="trackingNumber.finishParcel">Finish parcel</Trans>
          </Button>
        </Alert>
      )}
    </>
  );
};

const OrdersContent = ({
  trackingNumberData,
  duration,
  onAssign,
  onMissing,
  onFound,
  onPreview,
  onUnexpectedItem,
  onUnexpectedButton,
  onFinishButton,
  onReviewMissing,
  onReviewUnexpected,
  onOrderComments,
  isFinished,
  activeTab,
  setActiveTab,
  onPreviewBarcode,
  onPrintSummarySheet,
}) => {
  const { t } = useTranslation();
  const isToReceiveTab = activeTab === "toReceive";
  const isAddressedTab = activeTab === "addressed";
  const isMissingTab = activeTab === "missing";
  const isUnexpectedTab = activeTab === "unexpected";

  const {
    toReceive,
    stock,
    missing,
    unexpected,
    countToReceive,
    countStockItems,
    countMissingItems,
    countUnexpectedItems,
    allForWrongStore,
    forDifferentStores,
    countByStore,
    tracking_number_type,
  } = trackingNumberData;

  const noOrders = (
    countToReceive === 0
    && countStockItems === 0
    && countMissingItems === 0
    && countUnexpectedItems === 0
  );
  const hasItemsToReceive = countToReceive > 0;
  const hasStockItems = countStockItems > 0;
  const hasMissingItems = countMissingItems > 0;
  const hasUnexpectedItems = countUnexpectedItems > 0;

  if (noOrders) {
    return <NoOrdersMessage />
  }

  return (
    <div>
      {forDifferentStores && <ForDifferentStoresMessage countByStore={countByStore} />}
      {allForWrongStore && (
        <AllForWrongStoreMessage
          store={countByStore[0].store}
          count={countByStore[0].cnt}
        />
      )}
      <Tabs
        className="tracking-number-tabs"
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={[
          {
            code: "toReceive",
            label: (
              <>
                {t('trackingNumber.toReceive', 'To receive')}
                {countToReceive ? <ButtonBadge value={countToReceive} variant="yellow" /> : null}
              </>
            ),
          },
          {
            code: "addressed",
            label: (
              <>
                {t('trackingNumber.addressedTab', 'Addressed')}
                {countStockItems ? <ButtonBadge value={countStockItems} variant="success" /> : null}
              </>
            ),
          },
          {
            code: "missing",
            label: (
              <>
                {t('trackingNumber.missingTab', 'Missing')}
                {countMissingItems ? <ButtonBadge value={countMissingItems} variant="warning" /> : null}
              </>
            ),
          },
          {
            code: "unexpected",
            label: (
              <>
                {t('trackingNumber.unexpectedTab', 'Unexpected')}
                {countUnexpectedItems ? <ButtonBadge value={countUnexpectedItems} variant="warning" /> : null}
              </>
            ),
          },
        ]}
      />
      {isToReceiveTab && hasItemsToReceive && (
        toReceive.map(sg => (
          <OrderShippingGroup
            isToReceiveTab
            key={sg.shipping_group.shipping_group_number}
            trackingNumberType={tracking_number_type}
            order={sg.order}
            shippingGroup={sg.shipping_group}
            relatedShippingGroups={sg.related_shipping_groups}
            freeOrderLink={sg.free_order_link}
            items={sg.items}
            onAssign={onAssign}
            onMissing={onMissing}
            onUnexpectedItem={onUnexpectedItem}
            onPreview={onPreview}
            onOrderComments={onOrderComments}
            onPreviewBarcode={onPreviewBarcode}
            onPrintSummarySheet={onPrintSummarySheet}
          />
        ))
      )}
      {isToReceiveTab && !hasItemsToReceive && (
        <SummaryMessage
          onUnexpected={onUnexpectedButton}
          onFinish={onFinishButton}
          trackingNumberData={trackingNumberData}
          duration={duration}
        />
      )}

      {isAddressedTab && hasStockItems && (
        stock.map(sg => (
          <OrderShippingGroup
            key={sg.shipping_group.shipping_group_number}
            trackingNumberType={tracking_number_type}
            order={sg.order}
            shippingGroup={sg.shipping_group}
            relatedShippingGroups={sg.related_shipping_groups}
            freeOrderLink={sg.free_order_link}
            items={sg.items}
            onPreview={onPreview}
            onOrderComments={onOrderComments}
            onPreviewBarcode={onPreviewBarcode}
            onPrintSummarySheet={onPrintSummarySheet}
          />
        ))
      )}
      {isAddressedTab && !hasStockItems && (
        <Alert variant="info">
          <Trans i18nKey="trackingNumber.messages.noAddressedYet">No items addressed yet</Trans>
        </Alert>
      )}

      {isMissingTab && hasMissingItems && (
        missing.map(sg => (
          <OrderShippingGroup
            key={sg.shipping_group.shipping_group_number}
            trackingNumberType={tracking_number_type}
            order={sg.order}
            shippingGroup={sg.shipping_group}
            relatedShippingGroups={sg.related_shipping_groups}
            freeOrderLink={sg.free_order_link}
            items={sg.items}
            onFound={onFound}
            onReviewMissing={isFinished ? onReviewMissing : null}
            onPreview={onPreview}
            onOrderComments={onOrderComments}
            onPreviewBarcode={onPreviewBarcode}
            onPrintSummarySheet={onPrintSummarySheet}
          />
        ))
      )}
      {isMissingTab && !hasMissingItems && (
        <Alert variant="info">
          <Trans i18nKey="trackingNumber.messages.noMissingYet">No items declared missing yet</Trans>
        </Alert>
      )}

      {isUnexpectedTab && hasUnexpectedItems && (
        unexpected.map((sg, index) => (
          sg.shipping_group ? (
            <OrderShippingGroup
              key={sg.shipping_group.shipping_group_number}
              trackingNumberType={tracking_number_type}
              order={sg.order}
              shippingGroup={sg.shipping_group}
              relatedShippingGroups={sg.related_shipping_groups}
              freeOrderLink={sg.free_order_link}
              items={sg.items}
              onPreview={onPreview}
              onReviewUnexpected={isFinished ? onReviewUnexpected : null}
              onOrderComments={onOrderComments}
              onPreviewBarcode={onPreviewBarcode}
              onPrintSummarySheet={onPrintSummarySheet}
            />
          ) : (
            <UnexpectedItems
              key={`unexpected-${index}`}
              items={sg.items}
              onPreview={onPreview}
              onReviewUnexpected={isFinished ? onReviewUnexpected : null}
            />
          )
        ))
      )}
      {isUnexpectedTab && !hasUnexpectedItems && (
        <Alert variant="info">
          <Trans i18nKey="trackingNumber.messages.noUnexpectedYet">No items declared unexpected yet</Trans>
        </Alert>
      )}

    </div>
  );
};

export default OrdersContent;
