import { useEffect, useRef } from "react";

export const sleep = (ms) =>
  new Promise(resolve => {
    setTimeout(resolve, ms)
  });

function useInterval(callback, delay) {
  const savedCallback = useRef(callback);
  const savedCounter = useRef(0);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0) {
      savedCounter.current = 0;
      return;
    }

    const id = setInterval(() => {
      savedCounter.current++;
      savedCallback.current(savedCounter.current);
    }, delay);

    return () => clearInterval(id);
  }, [delay]);
}

export default useInterval;
