import React from 'react';
import { Modal, Button, Badge } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import RowField, { IconField } from '../../components/widgets/RowField';
import MountStatus from '../OrdersMount/components/MountStatus';
import pickupImage from '../../images/group-line.svg';

const OrderCustomerInfo = ({ orderNumber, customer, mountStatus }) => {
  const { t } = useTranslation();
  const hasInfo = !!(
    orderNumber
    || customer?.firstName
    || customer?.lastName
    || customer?.phoneNumber
    || customer?.email
    || customer?.friendPickupName
  );
  let isFriendPickupName = false;
  if (!hasInfo) {
    return null;
  }

  if (customer?.friendPickupName) {
    isFriendPickupName = customer?.friendPickupName !== customer?.firstName + ' ' + customer?.lastName
  }

  return (
    <div className="p-2">
      <IconField
        inline
        icon="vtmn-icon_document"
        title={t('orders.order', 'Order')}
        value={orderNumber || '-'}
      />
      {(customer?.firstName || customer?.lastName) ? (
        <IconField
          icon="vtmn-icon_account"
          title={t('orders.customer', 'Customer')}
          value={`${customer?.firstName || ''} ${customer?.lastName || ''}`}
        />
      ) : null}
      {customer?.phoneNumber && (
        <IconField
          icon="vtmn-icon_mobile"
          title={t('orders.phone', 'Phone')}
          value={(
            <a href={`tel:${customer?.phoneNumber}`}>
              {customer?.phoneNumber}
            </a>
          )}
        />
      )}
      {customer?.email && (
        <IconField
          icon="vtmn-icon_mail"
          title={t('orders.email', 'Email')}
          value={(
            <a href={`mailto:${customer?.email}`}>
              {customer?.email}
            </a>
          )}
        />
      )}
      {isFriendPickupName && (
        <IconField
          icon={pickupImage}
          title={t('orders.friendPickupName', 'friendPickupName')}
          value={`${customer?.friendPickupName || ''}`}
        />
      )}
      {mountStatus && (
        <div className="px-2 py-1">
          <MountStatus status={mountStatus} horizontal />
        </div>
      )}
    </div>
  );
};

export const OrderContentModal = ({
  show,
  onClose,
  onPreview,
  items,
  shippingGroups,
  mixedNoPackSg,
  orderNumber,
  mountStatus,
  customer,
}) => {

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="orders.orderDetails">Order details</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <OrderCustomerInfo orderNumber={orderNumber} customer={customer} mountStatus={mountStatus} />
        <ParcelContentItems
          items={items}
          shippingGroups={shippingGroups}
          showTrackingNumber
          mixedNoPackSg={mixedNoPackSg}
          onPreview={onPreview}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          <Trans i18nKey="shared.close">Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ParcelContentModal = ({
  show,
  onClose,
  onPreview,
  items,
  shippingGroups,
  showTrackingNumber,
  orderNumber,
  mountStatus,
  customer,
}) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="orders.shippingGroupContent">Shipping group content</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <OrderCustomerInfo orderNumber={orderNumber} customer={customer} mountStatus={mountStatus} />
        <ParcelContentItems
          items={items}
          onPreview={onPreview}
          shippingGroups={shippingGroups}
          showTrackingNumber={showTrackingNumber}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          <Trans i18nKey="shared.close">Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ParcelContentItems = ({ items, shippingGroups, mixedNoPackSg, onPreview, showTrackingNumber }) => {
  const { t } = useTranslation();

  return (
    <div className="order-stock-content">
      {
        items && items.length
          ? (items.map((item) => {
            const isMixedNoPack = mixedNoPackSg?.includes(item.shipping_group_number);
            const sg = (shippingGroups || []).find((x) => x.id === item.shipping_group_number);
            const trackingNumbers = (sg && sg.trackingNumbers) || [];
            return (
              <div className="order-item-row" key={item.item_id}>
                <div className="order-item-image">
                  {(item.image) && (
                    <img
                      src={`${item.image}?f=200x200`}
                      alt=""
                      onClick={() => onPreview(item)}
                    />
                  )}
                  {isMixedNoPack && <Badge className="mixed-no-pack-tag" variant="warning">no-pack</Badge>}
                </div>
                <div className="order-item">
                  <RowField
                    hideMobileLabel
                    label={t('orders.item', 'Item')}
                    value={`${item.item_id} - ${item.item_description}`}
                  />
                  <RowField
                    inline
                    label={t('orders.quantity', 'Quantity')}
                    value={item.quantity}
                  />
                  <RowField
                    hideMobileLabel
                    boldMobileValue
                    label={t('orders.shippingGroup', 'Shipping group')}
                    value={item.shipping_group_number}
                  />
                  {showTrackingNumber && (
                    <RowField
                      hideMobileLabel
                      boldMobileValue
                      label={t('orders.trackingNumber', 'Tracking number')}
                      value={trackingNumbers.length ? trackingNumbers.map((tn, index) => <div key={index}>{tn}</div>) : '-'}
                    />
                  )}
                </div>
              </div>
            );
          }))
          : (
            <div className="p-2 order-item-row" key="empty">
              <Trans i18nKey="orders.failedFindItems">Failed to find items</Trans>
            </div>
          )
      }
    </div>
  );
}
