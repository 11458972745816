import { useCallback } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash'
import dayjs from 'dayjs'

const INITIAL_STATE = {
  version: '1.0.0',
  pickingListCount: 0,
  trolleyBoxCount: 0,
  failedActions: [],
};

const getInitialState = () => {
  return INITIAL_STATE;
};

export const ecomPickingListSlice = createSlice({
  name: 'ecomPickingList',
  initialState: getInitialState(),
  reducers: {
    setPickingListCountAction: (state, action) => {
      state.pickingListCount = action.payload;
    },
    setTrolleyBoxCountAction: (state, action) => {
      state.trolleyBoxCount = action.payload;
    },
    setFailedActionAction: (state, { payload }) => {
      const { action, orderId, itemId } = payload
      const newFailedActions = _.cloneDeep(state.failedActions)
      const actionIndex = newFailedActions.findIndex(x =>
        orderId ? x.orderId === orderId : x.action === action && !x.orderId,
      )
      if (actionIndex > -1) {
        newFailedActions[actionIndex].failedAt = dayjs().toISOString()
        newFailedActions[actionIndex].count++
      } else {
        newFailedActions.push({
          action,
          orderId,
          itemId,
          failedAt: dayjs().toISOString(),
          count: 1,
        })
      }
      return { ...state, failedActions: newFailedActions }
    },
    removeFailedActionsByOrderAction: (state, { payload }) => {
      const newFailedActions = state.failedActions.filter(x => x.orderId !== payload)
      return { ...state, failedActions: newFailedActions }
    },
    removeFailedActionAction: (state, { payload }) => {
      const newFailedActions = state.failedActions.filter(x => x.action !== payload)
      return { ...state, failedActions: newFailedActions }
    },
    cleanFailedActionsAction: state => ({ ...state, failedActions: [] }),
    resetAction: () => {
      return INITIAL_STATE;
    },
  },
});

export const {
  setPickingListCountAction,
  setTrolleyBoxCountAction,
  setFailedActionAction,
  removeFailedActionsByOrderAction,
  removeFailedActionAction,
  cleanFailedActionsAction,
  resetAction,
} = ecomPickingListSlice.actions;

export const useEcomPickingListState = () => {
  const { pickingListCount, trolleyBoxCount, failedActions } = useSelector((state) => state.ecomPickingList);
  const dispatch = useDispatch();
  const setPickingListCount = useCallback((x) => dispatch(setPickingListCountAction(x)), [dispatch]);
  const setTrolleyBoxCount = useCallback((x) => dispatch(setTrolleyBoxCountAction(x)), [dispatch]);
  const setFailedAction = useCallback((x) => dispatch(setFailedActionAction(x)), [dispatch]);
  const removeFailedActionsByOrder = useCallback((x) => dispatch(removeFailedActionsByOrderAction(x)), [dispatch]);
  const removeFailedAction = useCallback((x) => dispatch(removeFailedActionAction(x)), [dispatch]);
  const cleanFailedActions = useCallback((x) => dispatch(cleanFailedActionsAction(x)), [dispatch]);


  const reset = useCallback(() => dispatch(resetAction()), [dispatch]);


  return {
    pickingListCount,
    trolleyBoxCount,
    failedActions,
    setPickingListCount,
    setTrolleyBoxCount,
    setFailedAction,
    removeFailedActionsByOrder,
    removeFailedAction,
    cleanFailedActions,
    reset,
  };
}

export default ecomPickingListSlice.reducer;
