import React from 'react';
import { useTranslation } from 'react-i18next';

import "../../stylesheets/flags.css";
import '../../stylesheets/langSelect.css';

import { LOCALES_MAP } from '../../locales/constants';
import blankFlag from "../../stylesheets/blankFlag.gif";



const LangSelect = () => {
  const { i18n } = useTranslation();
  const currentLocale = LOCALES_MAP[i18n.resolvedLanguage];

  return (
    <div className="dropdown-lang-select clickableText nav-item dropdown">
      <button
        id="langMenuButton"
        className="btn btn-primary dropdown-toggle"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img
          src={blankFlag}
          className={`flag ${currentLocale.flag}`}
          alt={currentLocale.alt}
        />
      </button>
      <div className="dropdown-menu dropdown-menu-right" aria-labelledby="langMenuButton">
        {Object.values(LOCALES_MAP).map((locale) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            key={locale.code}
            onClick={() => i18n.changeLanguage(locale.code)}
            className="dropdown-item clickableText"
            href="#"
            role="button"
          >
            <img
              src={blankFlag}
              className={`flag ${locale.flag}`}
              alt={locale.alt}
            />
            {' '}
            {locale.alt}
          </a>
        ))}
      </div>
    </div>
  );
};

export default LangSelect;
