import { pickBy } from 'lodash';

export const getFiltersParams = ({ filters }) => {
  const params = {};
  if (filters) {
    Object.keys(filters).forEach(key => {
      if (filters[key] || filters[key] === false) {
        params[key] = filters[key];
      }
    });
  }
  return params;
};

export const getSortParams = ({ sort }) => {
  const params = {};
  if (sort) {
    params.sort = sort.field;
    if (sort.direction === 'DESC') {
      params.desc = sort.field;
    }
  }
  return params;
};

export const getPaginationParams = ({ pagination }) => {
  const params = {};
  if (pagination) {
    const from = pagination.page * pagination.size;
    const to = (pagination.page + 1) * pagination.size - 1;
    const range = `${from}-${to}`;
    params.range = range;
  }
  return params;
};


/**
 * Function to convert params to query string
 *
 * @param {Object} Params object. Has reserved names: `filters`, `pagination`, `sort`
 * @param {string?} addQuestionMark // Pretend question mark to query string
 * @return {string} Query string
 */
export const getQueryString = ({ filters, pagination, sort, ...other }, addQuestionMark) => {
  const filtersParams = getFiltersParams({ filters });
  const sortParams = getSortParams({ sort });
  const paginationParams = getPaginationParams({ pagination });
  const otherParams = other ? pickBy(other, (x) => !!x) : {}; // clean empty object props
  const queryParams = {
    ...filtersParams,
    ...sortParams,
    ...paginationParams,
    ...otherParams,
  };
  const query = new URLSearchParams(queryParams).toString();
  return addQuestionMark && query.length ? '?' + query : query;
}

export default getQueryString;
