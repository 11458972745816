import React from 'react';
import { Badge } from 'react-bootstrap';
import defaultImage from '../../images/default_image.png';

const SummaryImage = ({
  item,
  qty,
  onClick,
}) => {
  return (
    <div className="summary-image-wrapper">
      {qty > 1 && <Badge className="summary-image-qty" pill variant="warning">{qty}</Badge>}
      <img
        src={item?.pixlId ? `https://contents.mediadecathlon.com/p${item?.pixlId}/a.jpg?f=60x60` : defaultImage}
        alt=""
        style={{ maxWidth: '100%' }}
        onClick={onClick}
      />
    </div>
  );
};

export default SummaryImage;
