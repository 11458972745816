import React from 'react';

import '../../stylesheets/imageThumbs.css';

const DEFAULT_THUMB_COUNT = 6

const ImageThumbs = ({ value, size, qty = DEFAULT_THUMB_COUNT, onImagePress }) => {
  if (!value || !value.length) return null;

  return (
    <div className='imageThumbs'>
      {value.slice(0, qty).map((image, index) => {
        return (
          <div key={index} className='imageThumbs__thumb' style={{ width: size, height: size }}>
            {index === qty - 1 && value.length > qty ? (
              <div className='imageThumbs__more'>
                <span>{`+${value.length - qty + 1}`}</span>
              </div>
            ) : (
              image && (
                <img
                  src={image}
                  alt=""
                  className={`imageThumbs__image${onImagePress ? ' thumb_link' : ''}`}
                  onClick={() => onImagePress?.(image, index)}
                />
              )
            )}
          </div>
        )
      })}
    </div>
  )
};

export default ImageThumbs;
