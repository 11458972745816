import React from 'react';
import { TablePagination, PaginationInfo, PageSizeSelect } from './Pagination';
import Loader from '../Loader';
import ErrorMessage from './errorMessage';
import '../../stylesheets/dataTable.css';

const TableCell = ({ row, field, rowIndex }) => {
  const val = row[field.field];
  const renderredVal = field.valueRenderer ? field.valueRenderer(val, field, row, rowIndex) : val;
  return (
    <td style={field.align ? { textAlign: field.align } : null }>
      {renderredVal}
    </td>
  );
};

const TableRow = ({ row, fields, index, isSummary }) => {
  return (
    <tr className={isSummary ? 'summary-row' : ''}>
      {fields.map(field => <TableCell key={field.field} field={field} row={row} rowIndex={index} />)}
    </tr>
  );
};

const TableHeaderCell = ({ field, sort, setSort }) => {
  let sortIcon = 'sort';
  if (sort && sort.field === field.field) {
    if (sort.direction === 'ASC') {
      sortIcon = 'arrow1_down';
    } else {
      sortIcon = 'arrow1_up';
    }
  }
  return (
    <th
      className={field.sortable ? 'column-sortable' : null}
      style={field.width ? { width: `${field.width}px` } : null}
      onClick={() => {
        if (field.sortable) {
          const nextDirection = (
            sort && sort.field === field.field && sort.direction !== 'DESC'
          ) ? 'DESC' : 'ASC';
          setSort({
            field: field.field,
            direction: nextDirection,
          });
        }
      }}
    >
      <div className="datatable-title-wraper">{field.title}</div>
      {field.sortable && (
        <i className={`sort-icon vtmn-icon_${sortIcon}`}></i>
      )}
    </th>
  );
}


export const DataTable = ({
  data,
  fields,
  error,
  loading,
  sort,
  setSort,
  rowKey,
  summaryRow,
}) => {
  if (loading) {
    return <Loader/>;
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!data) {
    return null;
  }
  return (
    <table
      className="dataTable table table-sm table-striped table-bordered table-hover"
    >
      <thead>
        <tr>
          {fields.map(field => <TableHeaderCell key={field.field} field={field} sort={sort} setSort={setSort} />)}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => {
          let key = row.id;
          if (rowKey) {
            if (typeof rowKey === 'function') {
              key = rowKey(row);
            } else {
              key = row[rowKey];
            }
          }
          return (
            <TableRow key={key} row={row} fields={fields} index={index} />
          );
        })}
        {summaryRow && (<TableRow key="summary" row={summaryRow} fields={fields} index={-1} isSummary />)}
      </tbody>
    </table>
  )
};

export const TableFooter = ({ pagination, totals, setPagination, hideInfo }) => (
  <div className="dataTableFooter">
    {hideInfo ? <div className="footer-info"/> : (
      <PaginationInfo
        pagination={pagination}
        totals={totals}
      />
    )}
    <PageSizeSelect
      pagination={pagination}
      setPagination={setPagination}
    />
    <TablePagination
      pagination={pagination}
      totals={totals}
      setPagination={setPagination}
    />
  </div>
);
