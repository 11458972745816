import React from 'react';
import { Badge } from 'react-bootstrap';
import '../../stylesheets/buttonBadge.css'


const ButtonBadge = ({ value, variant }) => {
  return (
    <Badge
      pill
      variant={variant || 'warning'}
      className="button-badge ml-1"
    >
      {value}
    </Badge>
  );
}

export default ButtonBadge;
