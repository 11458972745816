import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  on: false,
}

const slice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    setMaintenance: (state, action) => {
      return { ...state, ...action.payload }
    }
  },
})

export const { setMaintenance } = slice.actions

export default slice.reducer
