import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import SelectToggleButton from '../SelectToggleButton';

const SelectFilter = ({
  controlId,
  label,
  options,
  selected,
  multiple,
  onChange,
}) => {
  const selectedItems = multiple
    ? (selected && selected.length && options.filter(val => selected.indexOf(val.id) !== -1)) || []
    : (selected && options.filter(val => String(val.id) === String(selected))) || [];
  const hasValue = selectedItems && selectedItems.length;
  return (
    <div className="filter-item form-group">
      <label className="form-label" htmlFor={controlId}>
        {label}
        {':'}
      </label>
      <div className="filter-select">
        <Typeahead
          size="sm"
          id={controlId}
          options={options}
          selected={selectedItems}
          multiple={multiple}
          clearButton
          filterBy={() => true}
          onChange={
            multiple
              ? (opts) => onChange(opts.map((opt) => opt.id))
              : ([opt]) => onChange(opt && opt.id)
          }
        >
          {({ isMenuShown, toggleMenu }) => (
            !hasValue && (
              <SelectToggleButton
                isOpen={isMenuShown}
                onClick={e => {
                  toggleMenu();
                  e.stopPropagation();
                  e.preventDefault();
                  return false;
                }}
              />
            )
          )}
        </Typeahead>
      </div>
    </div>
  );
}

export default SelectFilter;
