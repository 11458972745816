import React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import SelectToggleButton from '../SelectToggleButton';
import useRequest from '../../../functions/useRequest';

/**
 * Server side select filter
 * @param {string} controlId - html id to attach label
 * @param {string} label - filter label
 * @param {Object[]} selected - array of selected options
 * @param {string} placeholder - placeholder
 * @param {function} onChange - onChange handler: (item: Object) => {}
 * @param {function} fetcher - search handler: (qyery: string) => []
 */
const SearchSelectFilter = ({
  controlId,
  label,
  selected,
  labelKey,
  placeholder,
  onChange,
  fetcher,
}) => {
  const { t } = useTranslation();
  const [{
    loading,
    error,
    data: options,
  }, fetchOptions] = useRequest(fetcher);
  const isInvalid = !loading && error;

  const hasValue = selected && selected.length;
  return (
    <div className={`filter-item form-group`}>
      <label className="form-label" htmlFor={controlId}>
        {label}
        {':'}
      </label>
      <div className="filter-select">
        <AsyncTypeahead
          size="sm"
          id={controlId}
          labelKey={labelKey}
          options={options || []}
          selected={selected}
          clearButton
          filterBy={() => true}
          isLoading={loading}
          isInvalid={isInvalid}
          className={isInvalid ? 'is-invalid' : undefined}
          onChange={([opt]) => onChange(opt)}
          onSearch={fetchOptions}
          placeholder={placeholder}
          minLength={3}
          delay={500}
        >
          {({ isMenuShown, toggleMenu }) => (
            !hasValue && (
              <SelectToggleButton
                isOpen={isMenuShown}
                onClick={e => {
                  toggleMenu();
                  e.stopPropagation();
                  e.preventDefault();
                  return false;
                }}
              />
            )
          )}
        </AsyncTypeahead>
        <div className="invalid-feedback">
          {(error && error.message) || t('shared.error', 'Error')}
        </div>
      </div>
    </div>
  );
}

export default SearchSelectFilter;
