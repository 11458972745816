import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import _ from "lodash";
import dateFormat from "dateformat";
import ErrorMessage from "../../components/widgets/errorMessage";
import ItemListGroup from "../../components/widgets/ItemListGroup";
import ImageThumbs from "../../components/widgets/ImageThumbs";
import RowField from "../../components/widgets/RowField";
import Loader from "../../components/Loader";
import WithSidebar from "../WithSidebar";
import useRequest from "../../functions/useRequest";
import OrderMountItemDialog from "./OrderMountItemDialog";
import { DEFAULT_FILTERS, Filters } from './Filters';
import { getMounts } from "./api";
import { filterMounts, getMountStatus } from './helpers';
import "../../stylesheets/orderMountList.css";
import defaultImage from "../../images/default_image.png";

const OrderMountListItem = ({ mount, onMountSelect }) => {
  const { t } = useTranslation();
  const mountStatus = getMountStatus(t, mount.mount_state.mount_status);
  const status = mount.mount_state.mount_status;
  const canMount =
    !!status && ["partialy_received", "ready_tomount"].includes(status);
  return (
    <div
      className={`order-mount-list-item order-mount-list-item-${mountStatus.color}`}
      onClick={() => onMountSelect(mount)}
    >
      <div className="order-mount-list-item-status-wrap">
        <div className="order-mount-list-item-status">{mountStatus.label}</div>
      </div>
      {canMount && <div className="order-mount-list-item-can-mount"></div>}
      <div className="order-mount-list-item-title">
        {mount.main_item.item.item_id}
        {" - "}
        {mount.main_item.item.item_description}
      </div>
      <div className="order-mount-list-item-content">
        <div className="order-mount-list-image-wrap">
          <img
            src={mount.main_item.item.image || defaultImage}
            alt={mount.main_item.item.item_description}
            className="order-mount-list-image"
          />
        </div>
        <div className="order-mount-list-item-fields">
          <RowField
            label={t("shared.order", "Order")}
            value={mount.order.order_number}
          />
          <RowField
            label={t("orderMount.promiseDate", "Promise date")}
            value={
              mount.main_item.promised_shipping_date
                ? dateFormat(
                    new Date(mount.main_item.promised_shipping_date),
                    "dd/mm/yyyy HH:MM:ss"
                  )
                : null
            }
          />
          <div className="order-mount-list-item-accessories">
            <ImageThumbs
              value={mount.accessories.map(
                (accessory) => accessory.item.image || defaultImage
              )}
              size={50}
              qty={6}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const OrderMountListAddress = ({ address, mounts, onMountSelect }) => {
  const { t } = useTranslation();
  return (
    <ItemListGroup
      name={
        (address === "null" || address === "undefined")
          ? t("shared.noGroup", "No group").toUpperCase()
          : address
      }
      count={mounts.length}
    >
      {mounts.map((mount) => (
        <OrderMountListItem
          key={mount.order.order_number}
          mount={mount}
          onMountSelect={onMountSelect}
        />
      ))}
    </ItemListGroup>
  );
};

const OrderMountList = ({ user, updateTokens, logOut }) => {
  const [{ loading, data: mounts, error }, fetchMounts] = useRequest(getMounts);
  const { store } = useParams();
  const [selectedMount, setSelectedMount] = useState(null);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [sort, setSort] = useState({
    field: "main_item.promised_shipping_date",
    direction: "ASC",
  });

  const reload = useCallback(
    () => fetchMounts({ store, user, updateTokens }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [store]
  );
  useEffect(() => {
    reload();
  }, [reload]);

  const filteredMounts = filterMounts(mounts || [], filters, sort);
  const byAdderess = _.groupBy(filteredMounts, "main_item.address");
  const addresses = Object.keys(byAdderess);

  return (
    <WithSidebar user={user} updateTokens={updateTokens} logOut={logOut}>
      <div className="jumbotron container">
        <h2 className="text-center">
          <Trans i18nKey="orderMount.title">Items to mount</Trans>
        </h2>
        {error && <ErrorMessage error={error} />}
        <Filters
          filters={filters}
          setFilters={setFilters}
          onClear={() => setFilters(DEFAULT_FILTERS)}
          sort={sort}
          setSort={setSort}
        />
        <div>
          {loading && (
            <div className="text-center">
              <Loader />
            </div>
          )}
          {!loading && !filteredMounts.length && (
            <div className="p-2">
              <Trans i18nKey="orders.noOrderMatch">
                No orders matching filter
              </Trans>
            </div>
          )}
          {!loading &&
            !!filteredMounts.length &&
            addresses.map((address) => (
              <OrderMountListAddress
                key={address}
                address={address}
                mounts={byAdderess[address]}
                onMountSelect={setSelectedMount}
              />
            ))}
        </div>
      </div>
      <OrderMountItemDialog
        show={!!selectedMount}
        onHide={() => setSelectedMount(null)}
        orderNumber={selectedMount?.order?.order_number}
        onMounted={() => {
          setSelectedMount(null);
          reload();
        }}
      />
    </WithSidebar>
  );
};

export default OrderMountList;
