import React from 'react';
import { Trans } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import Barcode from 'react-barcode';

const BarcodeModal = ({
  code,
  topComponent,
  children,
  show,
  onHide,
}) => (
  <Modal
    show={show}
    onHide={onHide}
    size="lg"
  >
    <Modal.Header closeButton>
      <Modal.Title as="h5">
        <Trans i18nKey="shared.barcodePreview">Barcode preview</Trans>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {topComponent}
      <div className="d-flex justify-content-center align-items-center">
        {code && (
          <Barcode
            value={code}
            width={3}
            height={150}
            margin={25}
            textMargin={5}
          />
        )}
      </div>
      {children}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={onHide}>
        <Trans i18nKey="shared.close">Close</Trans>
      </Button>
    </Modal.Footer>
  </Modal>
);

export default BarcodeModal;
