import React from "react";
import { useTranslation } from "react-i18next";
import { getMountStatus } from "../helpers";
import { Badge } from "react-bootstrap";

const MountStatus = ({ status, horizontal }) => {
  const { t } = useTranslation();

  if (!status) {
    return null;
  }

  const statuses = [getMountStatus(t, status)];
  if (status === "partialy_received") {
    statuses.unshift(getMountStatus(t, "ready_tomount"));
  }

  return (
    <div
      className={`d-flex align-items-center ${
        horizontal ? "flex-row" : "flex-column"
      }`}
    >
      {statuses.map((x) => (
        <Badge
          key={x.status}
          className={`tag tag-${x.color} font-weight-bold ${horizontal ? "mr-1" : "mb-1"}`}
        >
          {x.label}
        </Badge>
      ))}
    </div>
  );
};

export default MountStatus;
