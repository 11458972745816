import React, { useState } from 'react';
import { Row, Col, Spinner, Form, Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import useRequest from '../../functions/useRequest';
import { useUserReduxState } from '../../store/user';

import ReleasePreview from './ReleasePreview';
import ReleaseChangelogForm from './ReleaseChangelogForm';
import { DEFAULT_RELEASE_CHANGELOG } from './constants';
import { addReleaseChangelogQuery } from './api';

const ReleaseChangelogAddDialog = (props) => {
  const { show, release, onDone, onHide } = props;
  const { t } = useTranslation();
  const { user, updateTokens } = useUserReduxState();

  const [changelog, setChangelog] = useState(DEFAULT_RELEASE_CHANGELOG);
  const [isChanged, setIsChanged] = useState(false);

  const [{ loading, error }, addReleaseChangelog] = useRequest(addReleaseChangelogQuery, {
    onSuccess: onDone,
  });

  const handleChangelogChange = (v) => {
    setChangelog(v);
    setIsChanged(true);
  };

  const onSaveClick = () => {
    const { title, description, image_url } = changelog;
    addReleaseChangelog({
      id: release.id,
      changelog: { title, description, image_url },
      user,
      updateTokens,
    });
  };

  return (
    <Modal
      show={show}
      size="lg"
      onHide={onHide}
      className="release-view-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('releases.newReleaseChangelog', 'New release changelog')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {!loading && error && (
          <Form.Control.Feedback type="invalid">
            {(error && error.message) || t('shared.error', 'Error')}
          </Form.Control.Feedback>
        )}
        <Row>
          <Col xs={12} lg={4} className="p-0 d-flex justify-content-center">
            <ReleasePreview noIntro release={{ ...release, changes: [changelog] }} />
          </Col>
          <Col xs={12} lg={8}>
            <ReleaseChangelogForm changelog={changelog} disabled={loading} onChange={handleChangelogChange} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
        >
          {t('shared.cancel', 'Cancel')}
        </Button>
        <Button
          variant="success"
          disabled={!isChanged || loading}
          onClick={onSaveClick}
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                className="mr-2"
              />
              {t('shared.saving', 'Saving')}
            </>
          ) : t('shared.save', 'Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReleaseChangelogAddDialog;
