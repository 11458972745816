import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import { handleApiResponse, handlePaginatedApiResponse } from '../../functions/handleApiResponse';
import getQueryString from '../../functions/getQueryString';
import ExportButton from '../../components/widgets/ExportButton';
import Loader from '../../components/Loader';
import { TableFooter } from '../../components/widgets/DataTable';
import InlineInputFilter from '../../components/widgets/filters/InlineInputFilter';
import fetchWithJWT from '../../functions/fetchWithJWT';
import { getSortParams } from '../../functions/getQueryString';

const serverUrl = process.env.REACT_APP_SERVERURL;

class ItemsReport extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filteredList: [],
      lettersList: [],
      letter: "all",
      departmentsList: [],
      department: "all",
      item_description: "",
      address: "",
      totalQty: 0,
      loading: true,
      error: "",
      totals: {
        items: 0,
        pages: 0,
      },
      pagination: {
        page: 0,
        size: 20,
      },
      sort: {
        field: 'date',
        direction: 'DESC',
      },
    }
  }

  componentDidMount() {
    this.initializePage();
  }

  initializePage = async () => {
    const { t } = this.props;
    try {
      await this.loadDepartmentsList();
      await this.loadAlleys();
      await this.loadReport();
      this.setState({
        loading: false,
        error: "",
      });
    } catch (error) {
      this.setState({
        loading: false,
        error: t('report.loadReportFailed', 'Failed to load report'),
      });
      console.warn(error);
    }
  }

  loadAlleys = async () => {
    const { store, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const alleys = await fetchWithJWT(`${serverUrl}/v1/${store}/addresses/alleys`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    }).then(handleApiResponse);
    const lettersList = alleys.map(x => x.letter);
    this.setState({ lettersList });
  };

  loadDepartmentsList = async () => {
    const { store, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const departmentsList = await fetchWithJWT(`${serverUrl}/v1/items/stores/${store}/departments`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    }).then(handleApiResponse);
    this.setState({ departmentsList });
  }

  loadReport = async () => {
    const { store, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const { pagination, sort } = this.state;
    const filters = this.getFilters();

    const queryString = getQueryString({
      filters,
      pagination,
      sort,
    });
    const { result, contentRange } = await fetchWithJWT(`${serverUrl}/v1/${store}/reports/stock_items?${queryString}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    }).then(handlePaginatedApiResponse);
    const { data: filteredList, totalQty } = result;
    const { size } = pagination;
    const items = contentRange.max + 1;
    const pages = Math.max(1, Math.ceil(items / size));
    const totals = {
      items,
      pages,
    };
    this.setState({ filteredList, totals, totalQty });
  }

  reloadReport = async () => {
    const { t } = this.props;
    this.setState({ loading: true, error: '' });
    try {
      await this.loadReport();
    } catch (error) {
      console.warn(error);
      this.setState({
        error: t('report.loadReportFailed', 'Failed to load report'),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  setPagination = (nextPagination) => {
    const { totals } = this.state;
    const nextTotals = {
      items: totals.items,
      pages: Math.max(1, Math.ceil(totals.items / nextPagination.size)),
    };
    this.setState({
      totals: nextTotals,
      pagination: nextPagination,
    }, this.reloadReport);
  }

  filterListByItemDescription = (item_description) => {
    this.setState({
      item_description,
      pagination: {
        ...this.state.pagination,
        page: 0,
      }
    }, this.reloadReport);
  }

  filterListByAddress = (address) => {
    this.setState({
      address,
      pagination: {
        ...this.state.pagination,
        page: 0,
      }
    }, this.reloadReport);
  }

  filterListByDepartment = (department) => {
    this.setState({
      department,
      pagination: {
        ...this.state.pagination,
        page: 0,
      }
    }, this.reloadReport);
  }

  filterListByLetter = (letter) => {
    this.setState ({
      letter,
      pagination: {
        ...this.state.pagination,
        page: 0,
      }
    }, this.reloadReport);
  }

  getFilters = () => {
    const filters = {};
    if (this.state.department !== "all") {
      filters.department_description = this.state.department;
    }
    if (this.state.letter !== "all") {
      filters.address_letter = this.state.letter;
    }
    if (this.state.address !== "") {
      filters.address = this.state.address;
    }
    if (this.state.item_description !== "") {
      filters.item_description = this.state.item_description;
    }
    return filters;
  }

  onExport = (oauthToken, user, updateTokens, { filters, sort }) => {
    const url = `${serverUrl}/v1/${this.props.store}/reports/stock_items/export`;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(url, {
      method: 'POST',
      body: JSON.stringify({
        oauthToken,
        filters,
        sort: getSortParams({ sort }),
      }),
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
    })
    .then(handleApiResponse);
  }

  sort = (field) => {
    const { sort } = this.state;
    if (sort.field !== field || sort.direction !== 'ASC') {
      this.setState({
        sort: {
          field,
          direction: 'ASC',
        },
      }, this.reloadReport);
    } else {
      this.setState({
        sort: {
          field,
          direction: 'DESC',
        },
      }, this.reloadReport);
    }
  }

  getSortIcon = (field) => {
    const { sort } = this.state;
    let sortIcon = 'sort';
    if (sort && sort.field === field) {
      if (sort.direction === 'ASC') {
        sortIcon = 'arrow1_down';
      } else {
        sortIcon = 'arrow1_up';
      }
    }
    return sortIcon;
  }

  insertRow(address, index){
    return (
      <tr key={index}>
        <td>{ address.address }</td>
        <td>{ address.item_id !== null ?
            <Link to={`/${localStorage.getItem("store_number")}/home?item_id=${address.item_id}`} className="clickableText">{address.item_id + " - " + address.item_description}</Link>
            :
            "" }
        </td>
        <td>{ address.qty }</td>
        <td>{ address.date !== null ?
             address.date.slice(0,10)
            :
            "" }</td>
      </tr>
    )
  }

  insertLetter(letter){
    return(
      <button key={letter} type="button" className={`btn aisleLetter btn-sm ${this.state.letter === letter ? "active" : ""}`} onClick={ event => this.filterListByLetter(letter) }>{letter}</button>
    )
  }

  render() {
    const { t, user, updateTokens } = this.props;

    if (!this.props.isActive) {
      return null;
    }

    return (
      <>
        <table className="table table-hover text-center">
          <thead>
            <tr className="selectLine">
              <td>
                <select className="btn aisleLetter" name="department" value={this.state.department} onChange={event => this.filterListByDepartment(event.target.value)}>
                  <option key="all" value="all">{t('report.items.allSports', 'All sports')}</option>;
                  {this.state.departmentsList.map((department) => {
                    return <option  key={department.department_description} value={department.department_description}>{department.department_description}</option>;
                  })}
                </select>
              </td>
              <td colSpan="2">
                <button
                  type="button"
                  className={`btn aisleLetter btn-sm ${this.state.letter === "all" ? "active" : ""}`}
                  onClick={event => this.filterListByLetter("all")}
                >
                  <Trans i18nKey="report.items.all">All</Trans>
                </button>
                {this.state.lettersList.map( (letter) => this.insertLetter(letter))}
              </td>
              <td>
                <ExportButton
                  user={user}
                  updateTokens={updateTokens}
                  filters={this.getFilters()}
                  sort={this.state.sort}
                  exportReport={this.onExport}
                />
              </td>
            </tr>
            <tr className="title-row">
              <th className="column-sortable border-bottom-0 pb-1" onClick={() => this.sort('address')}>
                <div className="datatable-title-wraper">
                  <Trans i18nKey="report.items.addresses">Addresses</Trans>
                </div>
                <i className={`sort-icon vtmn-icon_${this.getSortIcon('address')}`} aria-hidden="true" />
              </th>
              <th className="column-sortable border-bottom-0 pb-1" onClick={() => this.sort('item_id')}>
                <div className="datatable-title-wraper">
                  <Trans i18nKey="report.items.item">Item</Trans>
                </div>
                <i className={`sort-icon vtmn-icon_${this.getSortIcon('item_id')}`} aria-hidden="true" />
              </th>
              <th className="column-sortable border-bottom-0 pb-1" onClick={() => this.sort('qty')}>
                <div className="datatable-title-wraper">
                  <Trans i18nKey="report.items.qty">Qty</Trans>
                </div>
                <i className={`sort-icon vtmn-icon_${this.getSortIcon('qty')}`} aria-hidden="true" />
              </th>
              <th className="column-sortable border-bottom-0 pb-1" onClick={() => this.sort('date')}>
                <div className="datatable-title-wraper">
                  <Trans i18nKey="report.items.lastMovement">Last movement</Trans>
                </div>
                <i className={`sort-icon vtmn-icon_${this.getSortIcon('date')}`} aria-hidden="true" />
              </th>
            </tr>
            <tr>
              <th className="border-top-0 pt-0">
                <InlineInputFilter
                  placeholder={t('report.items.filter', 'filter...')}
                  onChange={this.filterListByAddress}
                />
              </th>
              <th className="border-top-0 pt-0">
                <InlineInputFilter
                  placeholder={t('report.items.filter', 'filter...')}
                  onChange={this.filterListByItemDescription}
                />
              </th>
              <th className="border-top-0 pt-0 pb-0">
                <span className="font-weight-light">
                  {' '}
                  <Trans i18nKey="report.items.total">Total</Trans>
                  {' : '}
                  {this.state.totalQty}
                  {' '}
                </span>
              </th>
              <th className="border-top-0 pt-0"></th>
            </tr>
          </thead>
          <tbody>
            {this.state.loading && (
              <tr>
                <td colSpan={4}>
                  <Loader/>
                </td>
              </tr>
            )}
            {!this.state.loading && !!this.state.error && (
              <tr>
                <td colSpan={4}>
                  <div className="alert alert-danger show" role="alert" aria-label="Open">
                    <h4>
                      <Trans i18nKey="shared.defaultErrorTitle">Error!</Trans>
                    </h4>
                    {this.state.error}
                  </div>
                </td>
              </tr>
            )}
            {!this.state.loading && !this.state.error && this.state.filteredList.map(
              (address, index) => this.insertRow(address,index)
            )}
          </tbody>
        </table>
        <TableFooter
          pagination={this.state.pagination}
          totals={this.state.totals}
          setPagination={this.setPagination}
        />
      </>
    );
  }
}

export default withTranslation()(ItemsReport);
