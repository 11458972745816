import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap';

const Confirm = ({
  show,
  onHide,
  icon,
  title,
  important,
  cheboxLabel,
  body,
  loading,
  error,
  buttonOpts,
  onConfirm,
}) => {
  const { t } = useTranslation()
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (show) {
      setChecked(false);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header className="d-flex align-items-center" closeButton>
        {icon || (
          <i className="modal-header-icon text-primary vtmn-icon_notif_help" />
        )}
        <Modal.Title>
          {title || <Trans i18nKey="shared.confirm">Confirm</Trans>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        {loading ? (
          <div className="m-5 d-flex align-items-center justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ): (
          <>
            {!loading && error && (
              <Form.Control.Feedback type="invalid">
                {(error && error.message) || t('shared.error', 'Error')}
              </Form.Control.Feedback>
            )}
            {important && (
              <Alert variant="warning" className="rounded-0 mb-0 p-2 d-flex justify-content-start align-items-center">
                {icon && <i className={`vtmn-icon_notif_alert mr-2`} />}
                <div className="text-left">
                  {t('shared.warning', 'Warning')}
                </div>
              </Alert>
            )}
            <div className="p-3">
              {body}
              {important && (
                <Form.Check
                  id="confirm-checked"
                  className="mt-3"
                  type="checkbox"
                  label={cheboxLabel || t('shared.confirm', 'Confirm')}
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              )}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          disabled={loading}
          onClick={onHide}
        >
          {buttonOpts?.noText || <Trans i18nKey="shared.cancel">Cancel</Trans>}
        </Button>
        <Button
          disabled={loading || (important && !checked)}
          variant={buttonOpts?.variant || "primary"}
          onClick={onConfirm}
        >
          {buttonOpts?.icon && buttonOpts.icon}
          {buttonOpts?.text || <Trans i18nKey="shared.confirm">Confirm</Trans>}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Confirm;
