import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import _ from 'lodash';
import NumrangeFilter from '../../components/widgets/filters/NumrangeFilter';
import DaterangeFilter from '../../components/widgets/filters/DaterangeFilter';
import SelectFilter from '../../components/widgets/filters/SelectFilter';
import { normalizeText, normalizeName } from '../../functions/formatText';
import { ECOM_ORDER_STATUSES } from './constants';

const MobileFiltersModal = ({
  show,
  onClose,
  onClear,
  onReset,
  sport,
  alleys,
  teammates,
  shippingMethods,
  customersName,
  boxes,
  filters,
  updateFilter,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      onHide={onClose}
      className="ecom-filters-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="filters.filters">Filters</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0 pl-0 pr-0 filter-card d-flex flex-row flex-wrap align-items-stretch">
        <NumrangeFilter
          controlId="filter-nb-items"
          label={t('shopOrders.nbItems', 'nb Items')}
          valueFrom={filters.count_from}
          valueTo={filters.count_to}
          onChange={(count_from, count_to) => updateFilter({ count_from, count_to })}
        />
        <DaterangeFilter
          controlId="filter-date"
          label={t('shopOrders.date', 'Date')}
          valueFrom={filters.order_date_from}
          valueTo={filters.order_date_to}
          onChange={(order_date_from, order_date_to) => updateFilter({ order_date_from, order_date_to })}
        />
        <SelectFilter
          controlId="filter-status"
          label={t('shopOrders.status', 'Status')}
          multiple
          selected={filters.states}
          onChange={(states) => updateFilter({ states })}
          options={ECOM_ORDER_STATUSES.map((x) => ({ id: x, label: x }))}
        />
        <SelectFilter
          controlId="filter-sports"
          label={t('shopOrders.sport', 'Sport')}
          selected={filters.sport}
          onChange={(sport) => updateFilter({ sport })}
          options={sport.map((val) => ({
            id: val,
            label: val,
          }))}
        />
        <SelectFilter
          controlId="filter-alley"
          label={t('shopOrders.alley', 'Alley')}
          selected={filters.alley}
          onChange={(alley) => updateFilter({ alley })}
          options={alleys.map((val) => ({
            id: val,
            label: val,
          }))}
        />
        <SelectFilter
          controlId="filter-voluminous"
          label={_.capitalize(t('trackingNumbers.trackingNumberTypes.voluminous'))}
          selected={filters.voluminous}
          onChange={(voluminous) => updateFilter({ voluminous })}
          options={[
            {
              id: 'true',
              label: t('shared.yes', 'Yes'),
            }, {
              id: 'false',
              label: t('shared.no', 'No'),
            }
          ]}
        />
        <SelectFilter
          controlId="filter-teammate"
          label={t('shopOrders.teammate', 'Teammate')}
          selected={filters.teammate}
          onChange={(teammate) => updateFilter({ teammate })}
          options={[...teammates].map((mail) => ({
            id: mail,
            label: mail,
          }))}
        />
        <SelectFilter
          controlId="filter-shipping-method"
          label={t('orders.shippingMethod', 'Shipping method')}
          multiple
          selected={filters.shipping_methods}
          onChange={(shipping_methods) => updateFilter({ shipping_methods })}
          options={shippingMethods.map((sm) => ({
            id: sm,
            label: normalizeText(sm),
          }))}
        />
        <SelectFilter
            controlId="filter-customer-name"
            label={t('orders.customerName', 'Customer Name')}
            selected={filters.customer_name}
            onChange={(customer_name) => updateFilter({ customer_name })}
            options={customersName.map((val) => ({
              id: val,
              label: normalizeName(val),
            }))}
        />
        <SelectFilter
            controlId="filter-recommended-box"
            label={t('shopOrders.box', 'Box')}
            selected={filters.recommended_box}
            onChange={(recommended_box) => updateFilter({ recommended_box })}
            options={[...boxes].map((rb) => ({
              id: rb,
              label: rb,
            }))}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outlined"
          onClick={onClear}
        >
          <Trans i18nKey="shared.clear">Clear</Trans>
        </Button>
        <Button
          variant="secondary"
          onClick={onReset}
        >
          <Trans i18nKey="shared.reset">Reset</Trans>
        </Button>
        <Button
          variant="primary"
          onClick={onClose}
        >
          <Trans i18nKey="filters.apply">Apply</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default MobileFiltersModal;
