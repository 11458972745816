import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Confirm from '../../components/widgets/Confirm';

const DeleteBoxSizeModal = ({ show, onHide, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Confirm
      show={show}
      onHide={onHide}
      onConfirm={onConfirm}
      icon={<i className="modal-header-icon text-danger vtmn-icon_delete" />}
      title={t('adminAddreses.deleteBox', 'Delete box')}
      body={(
        <div>
          <p>
            <Trans i18nKey="adminAddreses.confirmBoxDelete">Are you sure, you want to delete box?</Trans>
          </p>
        </div>
      )}
    />
  );
}

export default DeleteBoxSizeModal;
