import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import WithSidebar from './WithSidebar';
import ImageModal from '../components/widgets/ImageModal';
import ScannerButton from '../components/widgets/scanner/ScannerButton';
import ZXScanner from '../components/widgets/scanner/ZXScanner';
import ZbarScanner from '../components/widgets/scanner/ZbarScanner';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import fetchWithJWT from '../functions/fetchWithJWT';
import handleApiResponse from '../functions/handleApiResponse';
import Loader from '../components/Loader';
import { BatchPickingListCreateModal } from './SearchAddress/PickingListModal';
import { logBarcodeReadEvent } from '../functions/analytics';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../stylesheets/home.css';
import '../index.css';
import ErrorMessage from '../components/widgets/errorMessage';

const serverUrl = process.env.REACT_APP_SERVERURL;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item_id: "location",
      item_description: " ",
      endOfLife: false,
      pixlId: null,
      stock: [],
      stockPicking: [],
      stock_problem: undefined,
      valueItem: "",
      valueAddress: "",
      valueAddressId: "",
      valueQty: "",
      valueModal: "",
      error: null,
      loading: false,
      alertMessage: null,
      alertMessagePicking: null,
      alertMessageAddPickingList: null,
      isLoadingCatalog: false,
      reserveIsPickersOnly: false,
      iAmPicker: false,
      iAmViewer: false,
      pickingListCreating: false,
      pickingListError: null,
      pickingListErrorDetails: null,
      allPickinglistShow: false,
    }
    this.addressScannerRef = React.createRef();
    this.itemScannerRef = React.createRef();
    this.itemInputRef = React.createRef();
  }

  componentDidMount() {
    this.initItem();
    const reserve_id = localStorage.getItem("reserve_id");
    return Promise.all([this.checkReserveIsPickersOnly(reserve_id), this.checkIfIAmPicker(this.props.user.email, reserve_id)]);
  }

  initItem = async () => {
    const params = new URLSearchParams(this.props.location.search);
    if (params.has("item_id")) {
      const item_id = params.get("item_id");
      this.setState({
        valueItem: item_id,
        loading: true,
      });
      try {
        await this.getItemDetails(item_id);
        this.setState({ loading: false });
      } catch (error) {
        this.setState({ loading: false, error });
      }
    }
  }

  checkReserveIsPickersOnly = async (reserve_id) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const url = `${serverUrl}/v1/stores/${reserve_id}`;
    try {
      const result = await fetchWithJWT(url, {
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        }
      }).then(handleApiResponse);
      this.setState({
        reserveIsPickersOnly: result.text.view_only_by_default,
        iAmViewer: result.text.viewer_right,
      });
    } catch (error) {
      this.setState({ error });
    }
  }

  checkIfIAmPicker = async (email, reserve_id) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const url = `${serverUrl}/v1/pickers/${reserve_id}`;
    try {
      const result = await fetchWithJWT(url, {
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        }
      }).then(handleApiResponse);
      this.setState({
        iAmPicker: result.pickers.map(picker => picker.email).includes(email)
      })
    } catch (error) {
      this.setState({ error });
    }
  }

  onItemScannerDetected = (code) => {
    this.setState({
      valueItem: code,
      valueAddress: "",
      valueQty: ""
    });
    document.getElementById("buttonSubmit").click();
  }

  onAddressScannerDetected = (code) => {
    this.setState({
      valueAddress: code,
      valueItem: ""
    });
    document.getElementById("HomeQty").focus();
  }

  handleChangeItem(valueItem) {
    this.setState({
      valueItem: valueItem
    })
  }

  handleChangeAddress = (event) => {
    this.setState({
      valueAddress: event.target.value
    })
  }

  handleChangeQty = (event) => {
    this.setState({
      valueQty: event.target.value
    })
  }

  handleChangeModal = (event) => {
    this.setState({
      valueModal: event.target.value.replace(/\D/g, "")
    })
  }

  handleChangeModalFull = (item, event) => {
    const value = event.target.value ? event.target.value : 0;
    const stocks = this.state.stockPicking.map(s => s.id === item.id ? { ...s, qty: parseInt(value, 10) } : s);
    this.setState({ stockPicking: stocks });
  }

  setUnknownEan = (ean) => {
    const { t } = this.props;
    this.setState({
      item_description: t('items.unknownEan', 'unknown EAN') + " - " + ean,
      stock: [],
      valueItem: "",
      item_id: "location",
      endOfLife: false,
      stock_problem: undefined,
      pixlId: null,
    });
  }

  setItemState = (item) => {
    this.setState({
      item_id: item.item_id,
      item_description: item.item_description,
      endOfLife: item.end_of_life,
      stock: item.stock,
      stockPicking: item.stock,
      valueItem: "",
      stock_problem: item.stock_problem,
      pixlId: item.pixlId,
    });
  }

  resetItemState = () => {
    this.setState({
      item_description: " ",
      stock: [],
      valueItem: "",
      item_id: "location",
      endOfLife: false,
      stock_problem: undefined,
      pixlId: null,
    });
  }

  getItem = async (input) => {
    this.setState({ loading: true, error: null })
    try {
      // if an ean is entered, go to find the item_id
      if (input.length === 13 || input.length === 30) {
        let ean13;
        if (input.length === 30) {
          ean13 = input.substr(3, 13);
        } else {
          ean13 = input;
        }

        const itemRes = await this.getItemFromEan(ean13);
        if (itemRes.code === "200") { // ean found
          logBarcodeReadEvent({ success: true, readingType: 'item', value: input });
          await this.getItemDetails(itemRes.text);
        } else { // ean not found
          logBarcodeReadEvent({ success: false, readingType: 'item', value: input });
          this.setUnknownEan(input);
        }
      } else {
        const itemDetails = await this.getItemDetails(input);
        logBarcodeReadEvent({ success: itemDetails.code === "200", readingType: 'item', value: input });
      }
      this.setState({ loading: false });
    } catch (error) {
      logBarcodeReadEvent({ success: false, readingType: 'item', value: input });
      this.resetItemState();
      this.setState({ loading: false, error });
    }
  }


  getItemFromEan = async (ean) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const url = `${serverUrl}/v1/items/ean/${ean}`;
    const result = await fetchWithJWT(url, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    }).then(handleApiResponse);
    return result;
  }

  getItemDetails = async (item_id) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const url = `${serverUrl}/v1/items/${item_id}/stores/${this.props.match.params.store}`;
    const result = await fetchWithJWT(url, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    }).then(handleApiResponse);
    this.setItemState(result);
    return result;
  }

  stockMovement = async (address, item_id, qtyInStock, qty, method) => {
    const { t } = this.props;
    let myBody;
    let myUser = localStorage.getItem("email");
    if (this.props.user.email !== null || this.props.user.email !== undefined) {
      myUser = this.props.user.email;
    }

    if (method === "pick") {
      myBody = {
        item_id: item_id,
        qty: - qty,
        user: myUser
      };
    } else {
      myBody = {
        item_id: item_id,
        qty: qty,
        user: myUser
      };
    }

    // you can't pick more than the available stock
    if (method === "pick" && qty > qtyInStock) {
      this.setState({
        alertMessagePicking: t('items.pickInsufficientStock', {
          defaultValue: 'Impossible to pick {{qty}} because the stock is insufficient ({{qtyInStock}})',
          qty,
          qtyInStock
        })
      });
      return;
    }

    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const url = `${serverUrl}/v1/${this.props.match.params.store}/addresses/${address}`;

    await fetchWithJWT(url, {
      method: "PUT",
      body: JSON.stringify(myBody),
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    }).then(handleApiResponse);
    this.setState({
      // valueAddress: "",
      valueQty: "",
      alertMessage: null
    });
    await this.getItemDetails(this.state.item_id);
    // to close the modal if necessary
    if (document.getElementById(`closeModalPick-${address}`) !== null) {
      document.getElementById(`closeModalPick-${address}`).click();
    }
  }

  createPickingList = async (stockInfo, createMode) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const url = `${serverUrl}/v1/${this.props.match.params.store}/picking_lists`;
    const result = await fetchWithJWT(url, {
      method: "POST",
      body: JSON.stringify({
        stock_address_id: stockInfo.id,
        email: this.props.user.email,
        qty: this.state.valueModal,
        createMode,
      }),
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    }).then(result => result.json());
    return result;
  }

  addToPickingList = async (stockInfo, createMode) => {
    // you can't pick more than the available stock
    const { t } = this.props;
    if (this.state.valueModal <= stockInfo.qty) {
      this.setState({
        pickingListCreating: true,
        pickingListError: null,
        pickingListErrorDetails: null,
        alertMessageAddPickingList: null,
      });
      try {
        const result = await this.createPickingList(stockInfo, createMode);
        if (result.code === '480') {
          this.setState({
            pickingListCreating: false,
            alertMessageAddPickingList:
              t('items.alreadyInPickingList', 'Impossible to put into picking list because the stock is already in the picking list of')
              + ` ${result.emails.join(t('items.alreadyInPickingListAnd', ' and '))}`
          })
        } else if (result.code === '201') {
          window.$(`#modal-${stockInfo.address_id}`).modal('hide');
          this.onCloseAddToPickingList();
        } else {
          this.setState({
            pickingListCreating: false,
            pickingListError: result.text,
            pickingListErrorDetails: result.details,
          });
        }
      } catch (e) {
        console.warn(e);
        this.setState({
          pickingListCreating: false,
          pickingListError: t('items.errorHappened', 'Error happened'),
          pickingListErrorDetails: null,
        });
      }
    } else {
      this.setState({
        alertMessageAddPickingList: t('items.putInsufficientStock', {
          defaultValue: 'Impossible to put into picking list {{qty}} because the stock is insufficient ({{qtyInStock}})',
          qty: this.state.valueModal,
          qtyInStock: stockInfo.qty,
        })
      });
    }
  }

  displayAlert() {
    if (this.state.alertMessage !== null) {
      return (
        <div className="alert alert-danger alert-dismissible fade show" role="alert" aria-label="Open">
          <p>{this.state.alertMessage}</p>
          <button
            type="button"
            className="btn btn-secondary displayAlert"
            onClick={() => this.setState({ alertMessage: null })}
            aria-label="Close"
          >
            <Trans i18nKey="shared.close">Close</Trans>
          </button>
        </div>
      )
    }
  }

  checkAddress = async () => {
    const { t, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const url = `${serverUrl}/v1/${this.props.match.params.store}/addresses/${this.state.valueAddress}`;
    this.setState({ error: null, loading: true });
    try {
      const result = await fetchWithJWT(url, {
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        }
      }).then(handleApiResponse);
      logBarcodeReadEvent({ success: result.exists, readingType: 'address', value: this.state.valueAddress });
      if (!result.exists) {
        this.setState({
          alertMessage: t('items.addressNotExist', 'Address does not exist.')
        });
      } else if (result.disabled) {
        this.setState({
          valueAddressId: result.address_id,
          alertMessage: t('items.addressDisabled', 'Address is existing but was disabled previously.')
        })
      } else {
        await this.addressIsOK();
      }
      this.setState({ error: null, loading: false });
    } catch (error) {
      logBarcodeReadEvent({ success: false, readingType: 'address', value: this.state.valueAddress });
      this.setState({ error, loading: false });
    }
  }

  addressIsOK = () => this.stockMovement(
    this.state.valueAddress,
    this.state.item_id,
    null,
    this.state.valueQty,
    "add",
  );

  handleAdd = async (stockInfo) => {
    this.setState({ error: null, loading: true });
    try {
      await this.stockMovement(
        stockInfo.address,
        stockInfo.item_id,
        stockInfo.qty,
        this.state.valueModal,
        "add",
      );
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  };

  handlePick = async (stockInfo) => {
    this.setState({ error: null, loading: true });
    try {
      await this.stockMovement(
        stockInfo.address,
        stockInfo.item_id,
        stockInfo.qty,
        this.state.valueModal,
        "pick",
      );
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  };

  allocationModule() {
    const { t } = this.props;
    if (this.state.item_id !== "location" && (!this.state.reserveIsPickersOnly || this.state.iAmPicker || this.state.iAmViewer)) {
      return (
        <>
          <form className="form" onSubmit={(event) => {
            event.preventDefault();
            this.checkAddress();
          }}>
            <div className="form-group col row">
              <input
                id="HomeAddress"
                type="text"
                className="form-control col"
                placeholder={t('items.address', 'Address')}
                value={this.state.valueAddress}
                autoCorrect="off"
                autoComplete="off"
                autoCapitalize="none"
                onChange={this.handleChangeAddress}
              />
              <ScannerButton
                scannerRef={this.addressScannerRef}
              />
              <input
                id="HomeQty"
                type="number"
                className="form-control col-2"
                placeholder={t('items.qty', 'Qty')}
                value={this.state.valueQty}
                onChange={this.handleChangeQty}
                step={1}
                min={1}
              />
              <button id="AddStockButton" type="submit" className="btn btn-success">
                <Trans i18nKey="shared.ok">OK</Trans>
              </button>

            </div>
            <ZXScanner
              readingType="address"
              ref={this.addressScannerRef}
              onDetected={this.onAddressScannerDetected}
            />
          </form>
          {this.displayAlert()}
        </>
      )
    }
  }

  modalPick(stockInfo) {
    return (
      <div key={stockInfo.address_id} className="modal fade" id={`pick-${stockInfo.address_id}`} tabIndex="-1" role="dialog" aria-labelledby="modalPickTitle" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalPickTitle">
                <Trans i18nKey="items.pickingItem">Picking item</Trans>
                {' '}
                {this.state.item_id}
                <br />
                <Trans i18nKey="items.pickingItemIn">in</Trans>
                {' '}
                {stockInfo.address}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" id={`closeModalPick-${stockInfo.address}`}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>{this.state.item_description}</p>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <Trans i18nKey="items.qtyPicked">Quantities picked</Trans>
                  </span>
                </div>
                <input type="text" className="form-control m-0" value={this.state.valueModal} onChange={this.handleChangeModal} />
              </div>
              {this.state.alertMessagePicking !== null ?
                <p className="text-danger">{this.state.alertMessagePicking}</p> :
                null}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal">
                {' '}
                <Trans i18nKey="shared.cancel">Cancel</Trans>
                {' '}
              </button>
              <button type="button" className="btn btn-success" onClick={() => this.handlePick(stockInfo)} >
                <Trans i18nKey="shared.pick">Pick</Trans>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  modalPutMoreStock(stockInfo) {
    return (
      <div key={stockInfo.address_id} className="modal fade" id={`add-${stockInfo.address_id}`} tabIndex="-1" role="dialog" aria-labelledby="modalAddTitle" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalAddTitle">
                <Trans i18nKey="items.addingStock">Adding stock</Trans>
                {' '}
                {this.state.item_id}
                {' '}
                <br />
                <Trans i18nKey="items.addingIn">in</Trans>
                {' '}
                {stockInfo.address}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>{this.state.item_description}</p>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <Trans i18nKey="items.qtyAdded">Quantities added</Trans>
                  </span>
                </div>
                <input type="text" className="form-control m-0" value={this.state.valueModal} onChange={this.handleChangeModal} />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal">
                {' '}
                <Trans i18nKey="shared.cancel">Cancel</Trans>
                {' '}
              </button>
              <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => this.handleAdd(stockInfo)} >
                <Trans i18nKey="shared.add">Add</Trans>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  onCloseAddToPickingList = () => this.setState({
    pickingListCreating: false,
    pickingListError: null,
    pickingListErrorDetails: null,
    alertMessageAddPickingList: null,
  });

  modalAddToPickingList(stockInfo) {
    const { t } = this.props;
    const hasConflictError = !!(
      this.state.pickingListError
      && this.state.pickingListErrorDetails
      && this.state.pickingListErrorDetails.type === 'conflict'
    );
    return (
      <div key={stockInfo.address_id} className="modal fade" id={`modal-${stockInfo.address_id}`} tabIndex="-1" role="dialog" aria-labelledby="modalPickingListTitle" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalPickingListTitle">
                <Trans i18nKey="items.addingPickingList">Adding to picking list</Trans>
                {' '}
                <Trans i18nKey="items.addingPickingListIn">in</Trans>
                {' '}
                {stockInfo.address}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id={`closeModalAddPickingList-${stockInfo.address_id}`}
                onClick={this.onCloseAddToPickingList}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {this.state.pickingListCreating
                ? (
                  <Loader
                    loading
                    text={t('shared.saving', 'Saving')}
                  />
                )
                : (
                  <>
                    <p>
                      <Trans i18nKey="layoutRequirements.itemCode">Item code</Trans>
                      {': '} {this.state.item_id} {' - '}
                      <Trans i18nKey="layoutRequirements.description">Description</Trans>
                      {': '} {this.state.item_description}
                    </p>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          <Trans i18nKey="items.qtyAdded">Quantities added</Trans>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control m-0"
                        value={this.state.valueModal}
                        onChange={this.handleChangeModal}
                        disabled={hasConflictError}
                      />
                    </div>
                    {this.state.alertMessageAddPickingList !== null ?
                      <p className="text-danger">{this.state.alertMessageAddPickingList}</p> :
                      null}
                    {this.state.pickingListError !== null ?
                      <p className="text-danger">{this.state.pickingListError}</p> :
                      null}
                    {hasConflictError && (
                      <>
                        <p>
                          <Trans i18nKey="items.wantToMerge">
                            Do you want to merge your quantities into his picking list?
                          </Trans>
                        </p>
                        <p>
                          <Trans i18nKey="items.orCreate">Or to create your own picking?</Trans>
                        </p>
                      </>
                    )}
                  </>
                )
              }
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={this.onCloseAddToPickingList}
              >
                {' '}
                <Trans i18nKey="shared.cancel">Cancel</Trans>
                {' '}
              </button>
              {hasConflictError && (
                <>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => this.addToPickingList(stockInfo, 'merge')}
                    disabled={this.state.pickingListCreating}
                  >
                    {' '}
                    <Trans i18nKey="items.merge">Merge</Trans>
                    {' '}
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => this.addToPickingList(stockInfo, 'create')}
                    disabled={this.state.pickingListCreating}
                  >
                    {' '}
                    <Trans i18nKey="items.newList">New picking list</Trans>
                    {' '}
                  </button>
                </>
              )}
              {!hasConflictError && (
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.addToPickingList(stockInfo)}
                  disabled={this.state.pickingListCreating}
                >
                  {' '}
                  <Trans i18nKey="items.addMyList">Add to my picking list</Trans>
                  {' '}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  searchCatalogue = async (query) => {
    this.setState({ isLoadingCatalog: true, valueItem: query, error: null });
    const { store } = this.props.match.params;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const url = `${serverUrl}/v1/items/stores/${store}/query/${query}`;
    try {
      const options = await fetchWithJWT(url, {
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        }
      }).then(handleApiResponse);
      this.setState({
        options,
        isLoadingCatalog: false
      });
    } catch (error) {
      this.setState({
        error,
        isLoadingCatalog: false
      });
    }
  }

  setValueItem = (valueItem) => this.setState({ valueItem })

  getTotalQty = () => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    if (!this.state.stock || this.state.stock.length === 0) {
      return "";
    }
    else {
      return (this.state.stock.map(e => e.qty)).reduce(reducer);
    }
  }

  handleShowBatchPickModal = () => {
    this.setState({ allPickinglistShow: true });
  }

  onCloseAddToFullPickingList = () => {
    this.setState({ allPickinglistShow: false })
  }

  onCreatePickingListSuccess = () => {
    this.onCloseAddToFullPickingList()
  }

  displayRow(stockInfo) {
    const { t } = this.props;
    const stockControlDisabled = this.state.reserveIsPickersOnly && !(this.state.iAmPicker || this.state.iAmViewer);
    return (
      <tr key={stockInfo.address_id}>
        <td className="text-center to-center">
          {stockInfo.address}
          {stockInfo.disabled ? ` (${t('shared.disabled', 'disabled')})` : null}
        </td>
        <td className="text-center to-center">
          {stockControlDisabled
            ? stockInfo.qty
            : (
              <div className="d-flex justify-content-center">
                <button
                  disabled={stockInfo.disabled}
                  type="button"
                  className="plusMinus vtmn-btn vtmn-btn_variant--primary vtmn-btn_with-only-icon vtmn-btn_size--small"
                  data-toggle="modal"
                  data-target={`#pick-${stockInfo.address_id}`}
                  onClick={() => this.setState({
                    valueModal: stockInfo.qty,
                    alertMessagePicking: null,
                  })}
                >
                  <i className="vtmn-icon_minus_v2" alt="minus"></i>
                </button>
                <div className="align-self-center">{stockInfo.qty}</div>
                <button
                  disabled={stockInfo.disabled}
                  type="button"
                  className="plusMinus vtmn-btn vtmn-btn_variant--primary vtmn-btn_with-only-icon vtmn-btn_size--small"
                  data-toggle="modal"
                  data-target={`#add-${stockInfo.address_id}`}
                  onClick={() => this.setState({
                    valueModal: 1
                  })}
                >
                  <i className="vtmn-icon_plus_v2" alt="plus"></i>
                </button>
              </div>
            )}
        </td>
        <td className="to-center">
          <button
            disabled={stockInfo.disabled}
            className="addPickingList vtmn-btn vtmn-btn_variant--ghost vtmn-btn_with-only-icon"
            id={`addPickingList-${stockInfo.address_id}`}
            data-toggle="modal"
            data-target={`#modal-${stockInfo.address_id}`}
            onClick={() => this.setState({
              valueModal: stockInfo.qty,
              alertMessageAddPickingList: null,
            })}
          >
            <i className="vtmn-icon_cart"></i>
          </button>
        </td>
      </tr>
    );
  }

  displayTable = () => {
    const { t } = this.props;
    return (
      <div className="mobile-table-wrapper">
        <table id="table" className="table table-hover mobile-table">
          <thead>
            <tr>
              <th className="text-center align-middle">
                <Trans i18nKey="items.address">Address</Trans>
              </th>
              <th className="text-center align-middle">
                <Trans i18nKey="items.stock">Stock</Trans>
                <div className="font-weight-light">
                  <Trans i18nKey="items.total">Total</Trans>
                  {' '}
                  {this.getTotalQty()}
                </div>
              </th>
              <th className="text-center align-middle" width={85}>
                {this.state.stock.length > 0 && (
                  <button
                    className="vtmn-btn vtmn-btn_variant--ghost vtmn-btn_with-only-icon"
                    onClick={() => this.handleShowBatchPickModal()}
                    title={t('item.pickAll', 'Pick all items')}
                  >
                    <i className="vtmn-icon_cart"></i>
                  </button>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.stock.length === 0
              ? (
                this.state.item_id === "location"
                  ? null
                  : (
                    <tr>
                      <td colSpan="3" className="text-center align-center">
                        <Trans i18nKey="items.noStock">No stock</Trans>
                      </td>
                    </tr>
                  )
              )
              : this.state.stock.map(stockInfo => this.displayRow(stockInfo))
            }
          </tbody>
        </table>
      </div>
    )
  }

  displayStockProblem = () => {
    const { t } = this.props;
    const { stock_problem } = this.state
    return stock_problem && (
      <div className="alert alert-danger alert-dismissible fade show" role="alert" id="stockAlertItemPage">
        <h4>
          <Trans i18nKey="items.problemDetected">Problem detected</Trans>
        </h4>
        <div>
          {t('items.moreThenStoresCom', {
            defaultValue: 'There is more stock in Product Locator ({{qty}}) than in stores.com ({{qtyInStock}}).',
            qty: this.state.stock_problem.in_reserve,
            qtyInStock: this.state.stock_problem.stores_com_stock,
          })}
        </div>
        <div>
          <Trans i18nKey="items.pleaseControl">Please control.</Trans>
        </div>
        <button
          type="button" className="close" aria-label="Close"
          onClick={() => this.setState({ stock_problem: undefined })}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  };

  render() {
    const { t, updateTokens } = this.props;
    const { allPickinglistShow, stock, item_description, endOfLife, loading, error } = this.state;
    return (
      <WithSidebar user={this.props.user} updateTokens={updateTokens} logOut={this.props.logOut}>
        <div>
          <div className="jumbotron container">
            <div className="row justify-content-center align-items-center">
              <div className="" style={{ width: '60px', height: '60px', marginRight: '10px' }}>
                {this.state.pixlId
                  ? (
                    <img
                      key={this.state.pixlId}
                      src={`https://contents.mediadecathlon.com/p${this.state.pixlId}/a.jpg?f=60x60`}
                      alt=""
                      onClick={() => {
                        window.$("#imageModal").modal('show');
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  )
                  : null
                }
              </div>
              <div>
                <h2 id="item_id" className="text-center">
                  <Trans i18nKey="items.item">Item</Trans>
                  {' '}
                  {this.state.item_id}
                </h2>
                <p id="item_description" className="text-center mb-0">{this.state.item_description}</p>
                <div className="mb-3 d-flex justify-content-center align-items-center">
                  {endOfLife && (
                    <span className="tag tag-warning mr-0">
                      {t('items.endOfLife').toUpperCase()}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <form className="form-group row col-12" onSubmit={(event) => {
              event.preventDefault();
              if (this.state.valueItem !== "") {
                if (this.itemInputRef && this.itemInputRef.current) {
                  this.itemInputRef.current.blur();
                }
                this.getItem(this.state.valueItem)
              }
            }}>
              <div className="input-group ">
                <AsyncTypeahead
                  ref={this.itemInputRef}
                  id="item_code"
                  isLoading={this.state.isLoadingCatalog}
                  onSearch={this.searchCatalogue}
                  onInputChange={this.setValueItem}
                  placeholder={t('items.enterItem', 'Enter item code or description ...')}
                  useCache={false}
                  minLength={3}
                  delay={500}
                  inputProps={{
                    autoCorrect: 'off',
                    autoComplete: 'off',
                    autoCapitalize: 'none',
                  }}
                  options={this.state.options || []}
                  filterBy={op => op.item_description}
                  labelKey={op => `${op.id}`}
                  renderMenuItemChildren={(option) => (
                    <div onClick={() => {
                      this.setState({
                        valueItem: option.id
                      }, () => {
                        document.getElementById("buttonSubmit").click();
                      })
                    }}>{option.id} - {option.item_description}</div>
                  )}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13 && !e.repeat) {
                      setTimeout(() => {
                        if (!loading) {
                          document.getElementById("buttonSubmit").click();
                        }
                      }, 100);
                    }
                  }}
                />
                <div className="input-group-append">
                  <button type="submit" id="buttonSubmit" className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_with-only-icon" >
                    <i className="vtmn-icon_search" alt="search"></i>
                  </button>
                  <ScannerButton
                    scannerRef={this.itemScannerRef}
                  />
                </div>
              </div>
            </form>
            <ZbarScanner
              readingType="item"
              ref={this.itemScannerRef}
              onDetected={this.onItemScannerDetected}
            />
            {loading && <Loader />}
            {!loading && error && <ErrorMessage error={error} />}
            {!loading && !error && (
              <>
                {this.displayStockProblem()}
                {this.displayTable()}
                {this.allocationModule()}
              </>
            )}
          </div>

          {this.state.stock.map(stockInfo => this.modalPick(stockInfo))}
          {this.state.stock.map(stockInfo => this.modalPutMoreStock(stockInfo))}
          {this.state.stock.map(stockInfo => this.modalAddToPickingList(stockInfo))}
          <ImageModal
            pixlId={this.state.pixlId}
            item={this.state.item_id}
            item_description={item_description}
            tags={this.state.endOfLife ? (
              <span className="tag tag-warning">
                {t('items.endOfLife').toUpperCase()}
              </span>
            ) : null}
          />
          <BatchPickingListCreateModal
            show={!!allPickinglistShow}
            stock={stock.map((stockInfo) => ({
              address: stockInfo.address,
              stock_address_id: stockInfo.id,
              item_id: stockInfo.item_id,
              item_description: item_description || '',
              qty: stockInfo.qty,
              qty_available: stockInfo.qty_available,
            }))}
            byAddress
            store={this.props.match.params.store}
            user={this.props.user}
            updateTokens={updateTokens}
            onHide={() => this.onCloseAddToFullPickingList()}
            onSuccess={() => this.onCreatePickingListSuccess()}
          />
        </div>
      </WithSidebar>
    );
  }
}


export default withTranslation()(Home);
