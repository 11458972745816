import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import fetchWithJWT from '../functions/fetchWithJWT';
import WithSidebar from './WithSidebar';

const serverUrl = process.env.REACT_APP_SERVERURL;

class AuthorizationDenied extends Component {

  constructor(props){
    super(props);
    this.state = {
      admins : [],
      reserve_id: localStorage.getItem("reserve_id")
    }
  }

  componentDidMount(){
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    fetchWithJWT(`${serverUrl}/v1/admins/${this.state.reserve_id}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(result => result.json())
    .then(result => {
      this.setState({
        admins: result.admins
      })
    })
    .catch(error => {
      console.warn(`Error while getting admins for reserve ${this.state.reserve_id} : ${error}`);
    })
  }

  displayAdmins = (reserve_id) => {
    if(this.state.admins.length === 0){
      return(
        <p>
          <Trans i18nKey="authDenied.noAdmin">There is no admin for this reserve. Ask the app owner to input the first one.</Trans>
        </p>
      )
    } else {
      return(
        <table className="table table-hover">
          <tbody>
            {this.state.admins.map(row => this.displayRow(row))}
          </tbody>
        </table>
      )
    }
  }

  displayRow = (row) => {
    return (
      <tr key = {row.id}>
        <td>{row.user_email}</td>
      </tr>
    )
  }

  render(){
    return(
      <WithSidebar user={this.props.user} updateTokens={this.props.updateTokens} logOut={this.props.logOut}>
        <div className = "jumbotron container">
          <h2 className = "text-center">
            <Trans i18nKey="authDenied.authDenied">Authorization Denied</Trans>
          </h2>
          <div>
            <br/>
            <p>
              <Trans i18nKey="authDenied.notAuthorized">Sorry, You are not authorized to access this page.</Trans>
            </p>
            <p>
              <Trans i18nKey="authDenied.listTeamAccess">
                Here is the list of your team mates who can grant you access:
              </Trans>
            </p>
            {this.displayAdmins(this.state.reserve_id)}
          </div>
        </div>
      </WithSidebar>
    )
  }
}

export default AuthorizationDenied;
