import React, { Component } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import dateFormat from 'dateformat';
import { withTranslation, Trans } from 'react-i18next';
import WithSidebar from './WithSidebar';
import fetchWithJWT from '../functions/fetchWithJWT';
import handleApiResponse from '../functions/handleApiResponse';
import ResupplyResultDialog from '../components/ResupplyResultDialog';
import Confirm from '../components/widgets/Confirm';
import ErrorDialog from '../components/widgets/ErrorDialog';
import '../stylesheets/layoutRequirements.css';

const serverUrl = process.env.REACT_APP_SERVERURL;

class LayoutRequirements extends Component {
  constructor(props){
    super(props);
    this.state = {
      layoutRequirements: [],
      tempItem: "",
      valueQty: "",
      isLoadingCatalog: false,
      isLoadingLayoutRequirements: true,
      reserve_id: null,
      layoutsChanged: [],
      buttonCalculateResupplyDisabled: false,
      currentError: null,
      resupplyResults: {},
      checkProblemEnabled: false,
      optimizationOption : "picking",
      createMode: "merge",
      userClickedOnMassImport: false,
      googleCredentials: {
        token: null,
        validUntil: null
      },
      alertItemDoesNotExist: [],
      buttonValidateImportDisabled: false,
      loadingIndicator: {
        done: 0,
        todo: 1
      },
      optionMinimumOneActivated: true,
      lastResupplyEmail: null,
      lastResupplyDate: null,
      confirmDeleteVisible: false,
    }
    this.modalResultsRef = React.createRef();
  }

  componentDidMount(){
    const reserve_id = localStorage.getItem("reserve_id");
    this.setState({
      reserve_id: localStorage.getItem("reserve_id")
    })
    this.getReserveInfo(reserve_id);
    this.getLayoutRequirements(reserve_id);
    this.getCheckProblemsEnabled();
    window.addEventListener('storage', this.onImportAsked);
  }

  onImportAsked = (event) => {
    if (event.key === 'credentials' && event.newValue !== null){
      const credentials = JSON.parse(event.newValue)
      this.massImport(credentials.token)
      this.setState({
        googleCredentials: credentials
      })
    }
  }

  componentWillUnmount(){
    window.removeEventListener('storage', this.onImportAsked);
  }

  getCheckProblemsEnabled = () => {
    const { store } = this.props.match.params;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/${store}/settings/check_store_stock`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(handleApiResponse)
    .then(({ enabled }) => {
      this.setState({
        checkProblemEnabled: enabled,
      });
    })
    .catch(error=>{
      this.setState({
        currentError: error
      });
    });
  }

  getReserveInfo = (reserve_id) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/stores/${reserve_id}`,{
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(handleApiResponse)
    .then(result => {
      this.setState({
        lastResupplyEmail: result.text.last_resupply_email,
        lastResupplyDate: result.text.last_resupply_date && new Date(result.text.last_resupply_date),
      });
    })
    .catch(error => {
      console.warn(`Error while checking reserve info ${reserve_id} : ${error}`);
      this.setState({
        currentError: error
      });
    })
  }

  getLayoutRequirements = (reserve_id) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/stores/${reserve_id}/layout_requirements`,{
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(handleApiResponse)
    .then(layoutRequirements => {
      this.setState({
        layoutRequirements: layoutRequirements[0] ? layoutRequirements : [],
        isLoadingLayoutRequirements: false
      })
    })
    .catch(error=>{
      this.setState({
        currentError: error
      });
    });
  }

  addLayoutRequirement = (item, qty, reserve_id) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/stores/${reserve_id}/layout_requirements`,{
      method:'PUT',
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      body:JSON.stringify({
        item: item,
        qty: qty
      })
    })
    .then(handleApiResponse)
    .then(result => this.getLayoutRequirements(reserve_id))
    .catch(error=>{
      this.setState({
        currentError: error
      });
    });
  }

  addLayoutRequirementWithNoRefresh = (item, qty, reserve_id) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/stores/${reserve_id}/layout_requirements`,{
      method:'PUT',
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      body:JSON.stringify({
        item: item,
        qty: qty
      })
    })
    .then(handleApiResponse)
    .catch(error=>{
      this.setState({
        currentError: error
      });
    });
  }

  updateLayoutRequirement = (layoutId, newQty) => {
    if(newQty !== ""){
      const { user, updateTokens } = this.props;
      const { token, refreshToken, tokenExpireDate } = user;
      return fetchWithJWT(`${serverUrl}/v1/stores/${this.state.reserve_id}/layout_requirements/${layoutId}`,{
        method:'PATCH',
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        },
        body: JSON.stringify({
          newQty: newQty
        })
      })
      .then(handleApiResponse)
      .catch(error=>{
        this.setState({
          currentError: error
        });
      });
    }
  }

  deleteLayoutRequirement = (layoutId) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/stores/${this.state.reserve_id}/layout_requirements/${layoutId}`,{
      method: 'DELETE',
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
    })
    .then(handleApiResponse)
    .then(result => this.getLayoutRequirements(this.state.reserve_id))
    .catch(error=>{
      this.setState({
        currentError: error
      });
    });
  }

  deleteAllLayoutRequirements = () => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/stores/${this.state.reserve_id}/layout_requirements`,{
      method: 'DELETE',
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
    })
    .then(handleApiResponse)
    .then(result => this.getLayoutRequirements(this.state.reserve_id))
    .catch(error=>{
      this.setState({
        currentError: error
      });
    });
  }

  handleChangeQty = (event) => {
    this.setState({
      valueQty: event.target.value
    })
  }

  getItemDetails = (item_id) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/items/${item_id}/stores/${this.props.match.params.store}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
    })
      .then(handleApiResponse)
      .then(result => {
        this.setState({
          item_id: result.item_id,
          item_description: result.item_description,
          stock: result.stock,
          tempItem : ""
        });
        return result;
      })
      .catch(error => {
        console.warn(`Error in getItemDetails for item ${item_id} : ${error}`);
        this.setState({
          currentError: error
        });
      })
  }

  searchCatalogue = (query) => {
      const { user, updateTokens } = this.props;
      const { token, refreshToken, tokenExpireDate } = user;
      const { store } = this.props.match.params;
      const trimmedQuery = query.trim();
      this.setState({isLoadingCatalog: true, tempItem : trimmedQuery});
      fetchWithJWT(`${serverUrl}/v1/items/stores/${store}/query/${trimmedQuery}`, {
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        },
      })
      .then(handleApiResponse)
      .then(result => this.setState({
          options: result,
          isLoadingCatalog : false
      }))
      .catch(error=>{
        this.setState({
          currentError: error
        });
      });
  }

  displayLayoutRequirements = () => {
    const { t } = this.props;
    if (this.state.isLoadingLayoutRequirements){
      return(
        <p>
          <Trans i18nKey="layoutRequirements.loading">Loading requirements</Trans>
        </p>
      )
    } else if (this.state.layoutRequirements.length === 0){
      return(
        <p>
          <Trans i18nKey="layoutRequirements.notExist">No existing layout requirement.</Trans>
        </p>
      )
    } else {
      return(
        <table className="table table-hover text-center">
          <thead>
            <tr className="selectLine">
              <td className="to-center">
                <Trans i18nKey="layoutRequirements.item">Item</Trans>
              </td>
              <td className="to-center">
                <Trans i18nKey="layoutRequirements.description">Description</Trans>
              </td>
              <td className="to-center">
                <Trans i18nKey="layoutRequirements.layoutRequirement">Layout Requirement</Trans>
              </td>
              <td className="p-0 to-center">
                <button
                  className="vtmn-btn vtmn-btn_variant--ghost vtmn-btn_with-only-icon vtmn-btn_size--large deleteIcon"
                  onClick={() => this.setState({ confirmDeleteVisible: true })}
                  title={t('layoutRequirements.deleteAll', 'Delete all')}
                >
                  <i className="vtmn-icon_delete vtmn-icon-32px" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          </thead>
          <tbody>
            {this.state.layoutRequirements.map(line => this.displayLineLayoutRequirement(line))}
          </tbody>
        </table>
      )
    }
  }

  displayLineLayoutRequirement = (line) => {
    return(
      <tr key={line.id}>
        <td className="to-center lineLayoutRequirement">{line.item_id}</td>
        <td className="to-center itemDescription lineLayoutRequirement">{line.item_description}</td>
        <td className="to-center lineLayoutRequirement">
          <input
            id="HomeQty"
            type="number"
            className="form-control enterQty"
            value={this.displayQtyOfALine(line.id)}
            onInput={(event) => this.handleChangeOneQty(line.id, event.target.value)}
            onChange={(event) => this.updateLayoutRequirement(line.id, event.target.value)}
          />
        </td>
        <td className="to-center lineLayoutRequirement">
          <button className="vtmn-btn vtmn-btn_variant--ghost vtmn-btn_with-only-icon vtmn-btn_size--large deleteIcon">
            <i className="vtmn-icon_delete vtmn-icon-32px" aria-hidden="true" onClick={() => this.deleteLayoutRequirement(line.id)}></i>
          </button>
        </td>
      </tr>
    )
  }

  displayQtyOfALine = (layoutId) => {
    if (this.state.layoutsChanged.map(e => e.layoutId).includes(layoutId)){
      return this.state.layoutsChanged.find(e => e.layoutId === layoutId).newQty;
    } else {
      const myLayout = this.state.layoutRequirements.find(e => e.id === layoutId);
      if (myLayout !== undefined){
        return myLayout.qty;
      } else return 0
    }
  }

  handleChangeOneQty = (layoutId, newQty) => {
    const alreadyChangedBefore = this.state.layoutsChanged.map(e => e.layoutId).includes(layoutId);
    if (alreadyChangedBefore){
      const newState = this.state.layoutsChanged;
      newState.find(e => e.layoutId === layoutId).newQty = newQty;
      this.setState({
        layoutsChanged: newState
      })
    } else {
      const newState = this.state.layoutsChanged;
      newState.push({
        layoutId: layoutId,
        newQty: newQty
      });
      this.setState({
        layoutsChanged: newState
      })
    }
  }

  showResults = () => {
    window.$(this.modalResultsRef.current).modal('show');
  }

  onItemLink = () => {
    window.$(this.modalResultsRef.current).modal('hide');
  }

  calculateResupply = () => {
    this.setState({
      buttonCalculateResupplyDisabled: true
    });
    const { t, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/stores/${this.state.reserve_id}/re_supply/${this.props.match.params.store}`,{
      method: 'POST',
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      body: JSON.stringify({
          token: this.props.user.token,
          optimizationOption : this.state.optimizationOption,
          optionMinimumOneActivated : this.state.optionMinimumOneActivated,
          createMode: this.state.createMode,
        }
      )
    })
    .then(handleApiResponse)
    .then(result => {
      this.setState({
        buttonCalculateResupplyDisabled: false,
        resupplyResults: result || {},
        currentError: null,
      });
      this.showResults();
      const reserve_id = localStorage.getItem("reserve_id");
      this.getReserveInfo(reserve_id);
    })
    .catch(error => {
      let errorMessage = t('layoutRequirements.errorResuply', 'There was an error during calculation of your re-supply.');
      if (error.status === 429) {
        errorMessage = (
          <>
            <div id="StockExtractorLimitReached">
              <Trans i18nKey="layoutRequirements.limitReached">
                We have reached the limit of queries per day.
              </Trans>
            </div>
            <div>
              <Trans i18nKey="layoutRequirements.cantResupply">
                I can't calculate reSupply anymore for today.
              </Trans>
            </div>
            <div>
              <Trans i18nKey="layoutRequirements.comeBack">
                Please come back tomorrow and warn
              </Trans>

              {' '}
              <a className="alert-link" href="mailto:fabien.lebas@decathlon.com">
                fabien.lebas@decathlon.com
              </a>
            </div>
          </>
        );
      }
      error.message = errorMessage;
      this.setState({
        buttonCalculateResupplyDisabled: false,
        currentError: error,
      })
      console.warn(`Error in calculateResupply : ${error}`);
    })
  }

  displayResultsDialog() {
    const { resupplyResults, checkProblemEnabled } = this.state;
    const { t } = this.props;

    return (
      <ResupplyResultDialog
        resupplyResults={resupplyResults}
        checkProblemEnabled={checkProblemEnabled}
        showProblems
        onItemLink={this.onItemLink}
        modalResultsRef={this.modalResultsRef}
        title={t('layoutRequirements.resupplyFinished', 'Resupply finished')}
      />
    );
  }

  displayCurrentError = () => (
    <ErrorDialog
      error={this.state.currentError}
      setError={() => this.setState({ currentError: null })}
    />
  )

  switchOptimizationOption = () => {
    if (this.state.optimizationOption === "picking"){
      this.setState({
        optimizationOption : "space"
      })
    } else {
      this.setState({
        optimizationOption : "picking"
      })
    }
  }

  displaySwitch = () => {
    return(
      <div className="row">
        <p className="text">
          <Trans i18nKey="layoutRequirements.minimizePicking">Minimize picking</Trans>
        </p>
        <label className="switch switch-sm">
          <input type="checkbox" onChange={this.switchOptimizationOption} checked={this.state.onlyPickersCanPick}/>
          <span className="slider round"></span>
        </label>
        <p className="text">
          <Trans i18nKey="layoutRequirements.minimizeLocations">Minimize locations</Trans>
        </p>
      </div>
    )
  }

  switchCreateMode = () => {
    if (this.state.createMode === "create"){
      this.setState({
        createMode : "merge"
      })
    } else {
      this.setState({
        createMode : "create"
      })
    }
  }

  displayCreateModeSwitch = () => {
    return(
      <div className="row">
        <p className="text">
          <Trans i18nKey="layoutRequirements.merge">If an item already has a picking list: Merge</Trans>
        </p>
        <label className="switch switch-sm">
          <input type="checkbox" onChange={this.switchCreateMode} checked={this.state.createMode === 'create'}/>
          <span className="slider round"></span>
        </label>
        <p className="text">
          <Trans i18nKey="layoutRequirements.newPickingList">New picking list</Trans>
        </p>
      </div>
    )
  }

  displayOptionMinimumOne = () => {
    let iconToDisplay = "vtmn-icon_checkbox_on";
    if (!this.state.optionMinimumOneActivated){
      iconToDisplay = "vtmn-icon_checkbox_off";
    }
    return(
      <div className="row">
        <button className="vtmn-btn vtmn-btn_variant--ghost vtmn-btn_with-only-icon vtmn-btn_size--large">
          <i className={iconToDisplay} aria-hidden="true" onClick={this.switchOptionMinimumOne}></i>
        </button>
        <p className="text">
          <Trans i18nKey="layoutRequirements.minimum1">Minimum 1 qty in store for each item code in reserve</Trans>
        </p>
      </div>
    )
  }

  switchOptionMinimumOne = () => {
    const newOption = !this.state.optionMinimumOneActivated;
    this.setState({
      optionMinimumOneActivated: newOption
    })
  }

  openMassImport = () => {
    this.setState({
      userClickedOnMassImport: true
    })
    window.open("https://docs.google.com/spreadsheets/d/1lDvlXkRKMImXV5KZBPv-yuvI6ebkzbAtiroUpIvERgI")
  }

  massImport = (token) => {
    this.setState({
      buttonValidateImportDisabled: true
    })
    return fetch("https://sheets.googleapis.com/v4/spreadsheets/1lDvlXkRKMImXV5KZBPv-yuvI6ebkzbAtiroUpIvERgI",{
      method: 'GET',
      headers:{"Authorization": "Bearer " + token}
    })
    .then(handleApiResponse)
    .then(result => fetch(`https://sheets.googleapis.com/v4/spreadsheets/1lDvlXkRKMImXV5KZBPv-yuvI6ebkzbAtiroUpIvERgI/values/A3:B${result.sheets.find(sheet => sheet.properties.title === 'data').properties.gridProperties.rowCount}`,{
      method: 'GET',
      headers:{"Authorization": "Bearer " + token}
    }))
    .then(handleApiResponse)
    .then(result => this.splitMassImport(result.values))
    .then(result => this.getLayoutRequirements(this.state.reserve_id))
    .then(result => {
      this.setState({
        buttonValidateImportDisabled: false
      })
    })
    .catch(error => {
      console.warn(`Error in massImport : ${error}`);
      this.setState({
        currentError: error
      })
    })
  }

  splitMassImport = async (values) => {
    for (let i = 0; i< values.length; i++){
      await this.importASingleLine(values[i])
      this.setState({
        loadingIndicator: {
          done: i + 1,
          todo: values.length
        }
      })
    }
  }

  importASingleLine = (layoutRequirement) => {
    const item_id = String(layoutRequirement[0]).trim();
    const qty = layoutRequirement[1];
    const alreadyHasALayoutRequirement = this.state.layoutRequirements.find(e => e.item_id === item_id);
    if (alreadyHasALayoutRequirement !== undefined){
      if (alreadyHasALayoutRequirement.qty !== Number(qty)) {
        return this.updateLayoutRequirement(alreadyHasALayoutRequirement.id, qty)
      } else return "nothing to do"
    } else {
      const { user, updateTokens } = this.props;
      const { token, refreshToken, tokenExpireDate } = user;
      return fetchWithJWT(`${serverUrl}/v1/items/${item_id}/stores/${this.props.match.params.store}`, {
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        }
      })
      .then(handleApiResponse)
      .then(result => {
        return this.addLayoutRequirementWithNoRefresh(item_id, qty, this.state.reserve_id)
      })
      .catch(error => {
        if (error.status === 404) {
          const newList = this.state.alertItemDoesNotExist;
          newList.push(item_id);
          this.setState({
            alertItemDoesNotExist: newList
          })
        } else {
          this.setState({
            currentError: error
          });
        }
      });
    }
  }

  displayAlertItemDoesNotExist = (item_id) => {
    const { t } = this.props;
    return (
      <div key={item_id} className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>
          {t('layoutRequirements.itemNotExist', {
            defaultValue: 'Item {{item}} does not exist',
            item: item_id,
          })}
        </strong>
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    )
  }

  displayMassImportButton = () => {
    if (!this.state.userClickedOnMassImport){
      return(
        <button className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small" onClick={this.openMassImport}>
          <i className="vtmn-icon_document"></i>
          <Trans i18nKey="layoutRequirements.import">Import</Trans>
        </button>
      )
    } else {
      return(
        <button className="vtmn-btn vtmn-btn_variant--conversion vtmn-btn_size--small" onClick={this.importOAuth} disabled={this.state.buttonValidateImportDisabled}>
          <i className="vtmn-icon_document"></i>
          <Trans i18nKey="layoutRequirements.validateImport">Validate Import</Trans>
        </button>
      )
    }
  }

  displayProgressBar = () => {
    if (this.state.buttonValidateImportDisabled){
      let myClassProgressBar="progress-bar";
      const percentDone = this.state.loadingIndicator.done / this.state.loadingIndicator.todo * 100;
      if (percentDone === 1){
        myClassProgressBar += " bg-success"
      }
      return(
        <div className="progress">
          <div
            className={myClassProgressBar}
            role="progressbar"
            style={{width: percentDone + "%"}}
            aria-valuenow={percentDone}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <span>{this.state.loadingIndicator.done} / {this.state.loadingIndicator.todo}</span>
          </div>
        </div>
      )
    }
  }

  importOAuth = () => {
    const baseUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    const client_id = process.env.REACT_APP_spreadsheet_clientId;
    const redirect_uri = `${process.env.REACT_APP_fedID_redirect_URI}googleOAuth`;
    const scope = 'https://www.googleapis.com/auth/spreadsheets';
    window.open(`${baseUrl}?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=token&scope=${scope}`, "_blank")
  }

  displayLastResupply = () => {
    const { lastResupplyEmail, lastResupplyDate } = this.state;
    if (lastResupplyEmail && lastResupplyDate) {
      const date = dateFormat(lastResupplyDate, "dd/mm/yyyy HH:MM:ss")
      return (
        <div className="row">
          <span className="text small">
            <Trans i18nKey="layoutRequirements.lastResupplyBy">Last calculation of re-supply by</Trans>
            {' '}
            {lastResupplyEmail}
            {' '}
            <Trans i18nKey="layoutRequirements.lastResupplyOn">on</Trans>
            {' '}
            {date}
          </span>
        </div>
      );
    }
  }

  render(){
    const { t } = this.props;
    return(
      <WithSidebar user={this.props.user} updateTokens={this.props.updateTokens} logOut={this.props.logOut}>
        <div className="jumbotron container">
          {this.displayCurrentError()}
          {this.state.alertItemDoesNotExist.map(item_id => this.displayAlertItemDoesNotExist(item_id))}
          <h2 className="text-center">
            <Trans i18nKey="layoutRequirements.layoutRequirements">Layout Requirements</Trans>
          </h2>
          {this.displayLastResupply()}
          <button className="vtmn-btn vtmn-btn_variant--primary vtmn-btn_size--small" onClick={this.calculateResupply} disabled={this.state.buttonCalculateResupplyDisabled}>
            <i className="vtmn-icon_exchange_return"></i>
            <Trans i18nKey="layoutRequirements.calculateResupply">Calculate re-supply</Trans>
          </button>
          {this.displayMassImportButton()}
          {this.displayProgressBar()}
          {this.displaySwitch()}
          {this.displayCreateModeSwitch()}
          {this.displayOptionMinimumOne()}

          <form className="form-group row col-12" onSubmit={(event) => {
            event.preventDefault();
            if(this.state.tempItem !== ""){
              this.getItemDetails(this.state.tempItem)
            }
          } }>
            <div className="input-group">
              <AsyncTypeahead
                ref = "typeahead"
                id="item_code"
                isLoading={this.state.isLoadingCatalog}
                onSearch={this.searchCatalogue}
                placeholder={t('layoutRequirements.enterItem', 'Enter item code or description ...')}
                useCache={false}
                minLength={3}
                delay={500}
                options={this.state.options || []}
                filterBy={op => op.item_description}
                labelKey={op => `${op.id}`}
                renderMenuItemChildren={(option) => (
                  <div onClick={() => {
                    this.setState({
                      tempItem: option.id,
                      item_id: option.id
                    })
                  }}>{option.id} - {option.item_description}</div>
                )}
              />
              <div className="input-group-append">
                <button type="submit" id="buttonSearch" className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_with-only-icon" >
                  <i className="vtmn-icon_search" alt="search"></i>
                </button>
                <input
                  id="HomeQty"
                  type="number"
                  className="form-control enterQty rbt-input-main"
                  placeholder={t('layoutRequirements.qty', 'Qty')}
                  value={this.state.valueQty}
                  onChange={this.handleChangeQty}
                />
                <button
                  type="submit"
                  id="buttonSubmit"
                  className="OKbutton plusMinus vtmn-btn vtmn-btn_variant--primary vtmn-btn_with-only-icon vtmn-btn_size--small"
                  onClick={() => this.addLayoutRequirement(
                    this.state.item_id ? this.state.item_id.trim() : this.state.item_id,
                    this.state.valueQty,
                    this.state.reserve_id,
                  )}
                >
                  <i className="vtmn-icon_plus_v2" alt="plus"></i>
                </button>
              </div>
            </div>
          </form>
          {this.displayLayoutRequirements()}
          {this.displayResultsDialog()}
          <Confirm
            show={this.state.confirmDeleteVisible}
            onHide={() => this.setState({ confirmDeleteVisible: false })}
            onConfirm={() => {
              this.deleteAllLayoutRequirements();
              this.setState({ confirmDeleteVisible: false });
            }}
            icon={<i className="modal-header-icon text-danger vtmn-icon_delete vtmn-icon-22px" />}
            body={<Trans i18nKey="layoutRequirements.confirmDeleteAll">You are going to delete all your layout requirements. There is no undo for this action. Are you sure?</Trans>}
            buttonOpts={{
              variant: 'danger',
              text: <Trans i18nKey="layoutRequirements.deleteAll">Delete all</Trans>,
            }}
          />
        </div>
      </WithSidebar>
    )
  }
}

export default withTranslation()(LayoutRequirements);
