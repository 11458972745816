import _ from 'lodash';

import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';

export const getTrolleysList = async ({ store, filters, sort, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const filtersFormatted = {};
  Object.entries(filters).forEach(([key, value]) => {
    if (!_.isNil(value)) {
      const dates = ['last_pick_from', 'last_pick_to', 'created_from', 'created_to'];
      if (dates.includes(key)) {
        const date = new Date(value);
        if (key.endsWith('_to')) date.setUTCHours(23,59,59,999)
        else date.setUTCHours(0,0,0,0);
        filtersFormatted[key] = date.toISOString();
        return;
      }
      filtersFormatted[key] = value;
    }
  });
  const params = new URLSearchParams({
    ...filtersFormatted,
    sort: sort.field,
    ...(sort.direction === 'DESC' ? { desc: sort.field } : {}),
  });
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/ecommerce_trolleys?${params}`;
  const trolleys = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
  return trolleys;
};
