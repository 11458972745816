import _ from 'lodash';

export const normalizeText = (v) => (v ? _.capitalize(v.replace(/_/g, ' ')) : '-');

export const normalizeName = (v) => (v ? v.split(' ').map(_.capitalize).join(' ') : '-');

export const getErrorTooltip = (error) => {
  const maxLength = 100;
  let tooltip = error.text || 'Error';
  if (tooltip.length > maxLength) {
    return `${tooltip.slice(0, maxLength)}...`;
  }
  return tooltip;
};
