import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Alert, Button } from 'react-bootstrap';

const DeactivationError = ({ deactivations, onClose }) => {
  const [detailsVisible, setDetailsVisible] = useState(false);
  const errorDeactivations = deactivations?.filter(d => d?.success === false)

  useEffect(() => () => {
    setDetailsVisible(false);
  }, [])

  if ( !errorDeactivations || errorDeactivations.length === 0) {
    return null;
  }


  return (
    <Alert dismissible variant="danger" className="mb-0 mt-2" onClose={onClose}>
      <h5>
        <Trans i18nKey="orders.deactivationError.title">Deactivation error</Trans>
      </h5>
      <div>
        <ul>
          <li>
            <Trans i18nKey="orders.deactivationError.wasError">There was an error with the security gate: we tried but could not deactivate.</Trans>
          </li>
          <li>
            <Trans i18nKey="orders.deactivationError.raiseAlarm">The products might raise alarm when going out of the store.</Trans>
          </li>
          <li>
            <Trans i18nKey="orders.deactivationError.ifPersists">If the problem persists, you can contact the helpdesk to tell them you have a problem with the EAS API.</Trans>
          </li>
        </ul>
      </div>
      {detailsVisible && (
        errorDeactivations.map(error =>
          <div className="mb-2">
            {error.item_id && `${error.item_id}: `}{error.message}
          </div>
        )
      )}
      <Button variant="danger" onClick={() => setDetailsVisible(!detailsVisible)}>
        {detailsVisible ? '-' : '+'}
        {' '}
        <Trans i18nKey="shared.details">Details</Trans>
      </Button>
    </Alert>
  );
}

export default DeactivationError;
