import React from 'react';
import { Col, Button, Card, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import { maxBy } from 'lodash';

import { DataTable } from '../../components/widgets/DataTable';

import '../../stylesheets/releaseChangelogView.css'
import translateSvg from '../../images/translate.svg';
import defaultImage from '../../images/default_image.png';

const getFields = ({ t, onEdit, onEditTranslations }) => [
  {
    field: 'image_url',
    title: t('shared.image', 'Image'),
    width: 80,
    align: 'center',
    valueRenderer: (val) => (
      <img className="releaseChangelogView_image" src={val || defaultImage} width={80} alt="" />
    ),
  },
  {
    field: 'title',
    title: t('shared.title', 'Title'),
  },
  {
    field: 'description',
    title: t('shared.description', 'Description'),
    valueRenderer: (val) => val ? <span className="releaseChangelogView_description">{val}</span> : null,
  },
  {
    field: 'change_date',
    title: t('releases.changeDate', 'Change date'),
    sortable: true,
    align: 'right',
    width: 120,
    valueRenderer: (val) => val ? dateFormat(new Date(val), "dd/mm/yyyy HH:MM:ss") : null,
  },
  {
    field: 'edit',
    title: '',
    align: 'center',
    width: 80,
    valueRenderer: (_val, _f, row) => (
      <Button
        variant="outline-primary"
        size="sm"
        onClick={() => onEdit(row)}
      >
        <i className="vtmn-icon_edit" />
      </Button>
    ),
  },
  {
    field: 'editTranslations',
    title: '',
    align: 'center',
    width: 100,
    valueRenderer: (_val, _f, row) => (
      <Button variant="light" size="sm" onClick={() => onEditTranslations(row)}>
        <img src={translateSvg} width={20} height={20} alt='' />
        {row.translations.length > 0 && (
          <Badge pill variant="light" className="ml-2">{row.translations.length}</Badge>
        )}
      </Button>
    ),
  },
];

const ReleaseChangelogView = (props) => {
  const { changelog, translations, onAdd, onEdit, onEditTranslations } = props;
  const { t } = useTranslation();

  const items = changelog.map((x) => ({ ...x, translations: translations.filter((t) => t.changelog_id === x.id) }))

  return (
    <Card className="mh-spacer mh-100">
      <Card.Body className="d-flex releaseChangelogView">
        {items.length ? (
          <DataTable
            data={items}
            fields={getFields({ t, onEdit, onEditTranslations })}
          />
        ) : (
          <Col className="text-center justify-self-center align-self-center">
            <span>{t('releases.noChangelog', 'No changelog yet')}</span>
          </Col>
        )}
      </Card.Body>
      <Card.Footer>
        {!!onEdit && (
          <Button variant="primary" size="sm" onClick={onAdd}>
            {t('releases.addChangelog', 'Add changelog')}
          </Button>
        )}
        {!!items.length && (
          <span className="text-muted text-sm mb-2 ml-2">
            {`${t('shared.lastChange', 'Last change')}: ${
              dateFormat(
                new Date(maxBy(items, 'change_date').change_date),
                'dd/mm/yyyy HH:MM:ss'
              )
            }`}
          </span>
        )}
      </Card.Footer>
    </Card>
  );
};

export default ReleaseChangelogView;
