import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import SortSelect from '../../components/widgets/SortSelect';

const MobileSort = ({
  sort,
  updateSort,
  getSortIcon,
}) => {
  const { t } = useTranslation();
  return (
    <div className="ecom-mobile-sort">
      <SortSelect
        controlId="mobile-sort"
        sort={sort}
        updateSort={updateSort}
        getSortIcon={getSortIcon}
        options={[
          {
            id: 'order_id',
            label: t('shopOrders.order', 'Order'),
          },
          {
            id: 'count',
            label: t('shopOrders.nbItems', 'nb Items'),
          },
          {
            id: 'order_date',
            label: t('shopOrders.date', 'Date'),
          },
          {
            id: 'status',
            label: t('shopOrders.status', 'Status'),
          },
          {
            id: 'sport',
            label: t('shopOrders.sport', 'Sport'),
          },
          {
            id: 'alleys',
            label: t('shopOrders.alley', 'Alley'),
          },
          {
            id: 'has_voluminous',
            label: _.capitalize(t('trackingNumbers.trackingNumberTypes.voluminous')),
          },
          {
            id: 'email',
            label: t('shopOrders.teammate', 'Teammate'),
          },
          {
            id: 'shipping_method',
            label: t('shopOrders.shippingMethod', 'Shipping method'),
          },
          {
            id: 'action',
            label: t('shopOrders.action', 'Action'),
          },
          {
            id: 'recommended_box',
            label: t('shopOrders.box', 'Box'),
          },
        ]}
      />
    </div>
  );
};

export default MobileSort;
