import fetchWithJWT from './fetchWithJWT';
import { store } from '../store';
import { updateTokens } from '../store/user';

const sendAnalyticsEvent = async (eventType, payload) => {
  const { user } = store.getState();
  const { dispatch } = store;
  const {
    token,
    refreshToken,
    tokenExpireDate,
  } = user;

  const url = `${process.env.REACT_APP_SERVERURL}/v1/analytics_events/${eventType}`;
  await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens: ({ token, refreshToken, tokenExpiresIn }) => dispatch(updateTokens({ token, refreshToken, tokenExpiresIn }))
    },
    method: 'POST',
    body: JSON.stringify(payload)
  })
};

/**
 * Sends event to analytics
 * @param {string} eventName
 * @param {Object} params - any flat object
 */
export const logEvent = (eventName, params) => {
  sendAnalyticsEvent(eventName, params).catch((e) => console.error('Error sendAnalyticsEvent:', e));
  if (!window.gtag) {
    return;
  }
  window.gtag('event', eventName, params);
};

export const logBarcodeReadEvent = ({ success, readingType, value }) => {
  const state = store.getState();
  const { lastBarcode } = state;
  const sameBarcode = value && lastBarcode.value === value;
  const sameReadingType = readingType === lastBarcode.readingType;
  const sameTime = new Date().getTime() - (lastBarcode.date || 0) < 20000;
  if (sameBarcode && sameReadingType && sameTime) {
    logEvent('barcode_read', {
      ...lastBarcode,
      success,
      readingType,
      date: undefined,
    });
  }
}

export const logBarcodeReadCanceledEvent = ({ source, readingType, duration }) => {
  logEvent('barcode_read_canceled', {
    source,
    readingType,
    duration,
  });
}
