import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';

import { normalizeText } from '../../functions/formatText';

import DaterangeFilter from '../../components/widgets/filters/DaterangeFilter';
import InputFilter from '../../components/widgets/filters/InputFilter';
import SelectFilter from '../../components/widgets/filters/SelectFilter';

const TROLLEY_STATUSES = ['picked', 'packing', 'packed', 'finalize-pending']

const FiltersModal = ({
  show,
  onClose,
  onClear,
  onReset,
  filters,
  updateFilter,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      onHide={onClose}
      className="ecom-filters-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="filters.filters">Filters</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0 pl-0 pr-0 filter-card d-flex flex-row flex-wrap align-items-stretch">
        <InputFilter
          controlId="filter-email"
          label={t('ecomPacking.teammate', 'Teammate')}
          value={filters.email}
          onChange={(email) => updateFilter({ email })}
        />
        <DaterangeFilter
          controlId="filter-created"
          label={t('ecomPacking.firstPick', 'First pick')}
          valueFrom={filters.created_from}
          valueTo={filters.created_to}
          onChange={(created_from, created_to) => updateFilter({ created_from, created_to })}
        />
        <DaterangeFilter
          controlId="filter-last-pick"
          label={t('ecomPacking.lastPick', 'Last pick')}
          valueFrom={filters.last_pick_from}
          valueTo={filters.last_pick_to}
          onChange={(last_pick_from, last_pick_to) => updateFilter({ last_pick_from, last_pick_to })}
        />
        <SelectFilter
          controlId="filter-statuses"
          label={t('ecomPacking.status', 'Status')}
          multiple
          selected={filters.statuses || []}
          onChange={(statuses) => updateFilter({ statuses })}
          options={TROLLEY_STATUSES.map((val) => ({
            id: val,
            label: normalizeText(val),
          }))}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outlined"
          onClick={onClear}
        >
          <Trans i18nKey="shared.clear">Clear</Trans>
        </Button>
        <Button
          variant="secondary"
          onClick={onReset}
        >
          <Trans i18nKey="shared.reset">Reset</Trans>
        </Button>
        <Button
          variant="primary"
          onClick={onClose}
        >
          <Trans i18nKey="filters.apply">Apply</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default FiltersModal;
