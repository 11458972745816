import React from 'react';
import { useTranslation } from 'react-i18next';

import useRequest from '../../functions/useRequest';
import { useUserReduxState } from '../../store/user';
import Confirm from '../../components/widgets/Confirm';

import { editReleaseQuery } from './api';

const ReleasePublishChangeConfirmDialog = (props) => {
  const { show, release, onDone, onHide } = props;
  const { t } = useTranslation();
  const { user, updateTokens } = useUserReduxState();

  const isPublished = release.published;

  const [{ loading, error }, editRelease] = useRequest(editReleaseQuery, {
    onSuccess: onDone,
  });

  const onConfirm = () => editRelease({
    id: release.id,
    release: { published: !isPublished },
    user,
    updateTokens,
  });

  return (
    <Confirm
      show={show}
      onHide={onHide}
      loading={loading}
      error={error}
      title={isPublished ?
        t('releases.releaseUnpublish', 'Release unpublish') :
        t('releases.releasePublish', 'Release publish')
      }
      body={t('version.releaseActionConfirm', {
        defaultValue: 'Do you want to {{action}} {{value}} release?',
        action: (
          isPublished ? t('releases.unpublish', 'Unpublish') : t('releases.publish', 'Publish')
        ).toLowerCase(),
        value: `${release.version} (${release.version_code}) ${release.type}`,
      })}
      buttonOpts={{ variant: isPublished ? 'primary' : 'success' }}
      onConfirm={onConfirm}
    />
  );
};

export default ReleasePublishChangeConfirmDialog;
