import React from 'react';
import CommentsDialog from '../../components/widgets/CommentsDialog';
import { getComments, postComment } from './api';
import { useUserReduxState } from '../../store/user';

const OrderComments = ({ isOpen, onClose, store, orderNumber }) => {
  const { user, updateTokens } = useUserReduxState();
  return (
    <CommentsDialog
      isOpen={isOpen}
      onClose={onClose}
      getComments={() => getComments({ store, orderNumber, user, updateTokens })}
      postComment={({ text }) => postComment({ text, store, orderNumber, user, updateTokens })}
    />
  );
}

export default OrderComments;
