import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isNil, truncate } from 'lodash'

import '../../stylesheets/clipboardValue.css';

const ClipboardValue = props => {
  const { value, label, disabled, onSuccess, onError, successLable, clipboard = true, style: customStyle } = props;

  const { t } = useTranslation();

  const [copySuccess, setCopySuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const isEmptyValue = isNil(value);

  const handleCopyPress = async () => {
    try {
      if (isEmptyValue) return;
      const toClipboard = `${value}`;
      await navigator.clipboard.writeText(toClipboard);

      if (onSuccess) {
        onSuccess(t(
          'clipboard.copySuccess',
          '«{{ value }}» successfully copied to clipboard',
          { value: truncate(successLable ? label : toClipboard, { length: 80 }) },
        ));
      } else {
        setCopySuccess(true);
      }
    } catch (error) {
      onError?.(error);
      setErrorMessage(
        error.message ? truncate(error.message, { length: 120 })
        : t('clipboard.copyError', 'Something wrong. Cant copy to clipboard.')
      );
    }
  };

  return (
    <div className={`clipboard-value${customStyle ? ` ${customStyle}` : ''}`}>
      <div className="clipboard-value-container">
        {!!label && <span className="clipboard-value-label text-muted">{label}</span>}
        <div className="clipboard-value-content">
          <span
            className={`clipboard-value-value${disabled ? ` disabled`: ''}`}
          >
            {value || '-'}
          </span>
          {clipboard && !disabled && !isEmptyValue && (
            <span
              onClick={handleCopyPress}
              className='icon-button-hover mr-3'
            >
              <i className="vtmn-icon_document" />
            </span>
          )}
        </div>
      </div>
      {!!(copySuccess || errorMessage) && (
        <div className="clipboard-value-description">
          {errorMessage ? (
            <span className="text-danger">{errorMessage}</span>
          ) : copySuccess ? (
            <span className="text-success">{t('clipboard.copied', 'Successfully copied to clipboard!')}</span>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ClipboardValue;
