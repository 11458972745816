import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal, Button, InputGroup, Form, Row, Col } from 'react-bootstrap';

const EditItemLimitsModal = ({ show, limits, onHide, onSave }) => {

  const [limitsVal, setLimitsVal] = useState(null)

  const { t } = useTranslation(); 

  const handleMaxParamChange = (e, name) => {
    setLimitsVal({ ...limitsVal, [name]: e.target.value.replace(/[^.\d]/g, '') })
  }
  const handleSaveClick = () => {
    const newLimits = {};
    Object.keys(limitsVal).forEach((name) => {
      newLimits[name] = parseFloat(limitsVal[name])
    });
    onSave(newLimits);
  }

  useEffect(() => {
    const newLimits = {};
    Object.keys(limits).forEach((name) => {
      newLimits[name] = limits[name]?.toString() || '0'
    });
    setLimitsVal(newLimits)
  }, [limits]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
    >
      <Modal.Header closeButton>
        <i className="modal-header-icon text-primary vtmn-icon_edit" />
        <Modal.Title>
          <Trans i18nKey="adminAddreses.voluminousLimits">Voluminous limits</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-">
          <Col sm="12" md="4">
            <Form.Label>
              {`${t('shared.max', 'Max')} ${t('shared.size', 'Size').toLowerCase()}:`}
            </Form.Label>
            <InputGroup className="mb-0">
              <Form.Control
                value={limitsVal?.maxItemSize || '0'}
                className="m-0"
                onChange={e => handleMaxParamChange(e, 'maxItemSize')}
              />
              <InputGroup.Append>
                <InputGroup.Text>CM</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <Form.Text muted>
              <small><Trans i18nKey="adminAddreses.sizeOfBiggest">Size of the biggest dimension</Trans></small>
            </Form.Text>
          </Col>
          <Col sm="12" md="4">
            <Form.Label>
              {`${t('shared.max', 'Max')} ${t('shared.weight', 'Weight').toLowerCase()}:`}
            </Form.Label>
            <InputGroup className="mb-0">
              <Form.Control
                value={limitsVal?.maxItemWeight || '0'}
                className="m-0"
                onChange={e => handleMaxParamChange(e, 'maxItemWeight')}
              />
              <InputGroup.Append>
                <InputGroup.Text>KG</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col sm="12" md="4">
            <Form.Label>
              {`${t('shared.max', 'Max')} ${t('shared.volume', 'Volume').toLowerCase()}:`}
            </Form.Label>
            <InputGroup className="mb-0">
              <Form.Control
                value={limitsVal?.maxItemVolume || '0'}
                className="m-0"
                onChange={e => handleMaxParamChange(e, 'maxItemVolume')}
              />
              <InputGroup.Append>
                <InputGroup.Text>CDM</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <Form.Text muted>
              <small><Trans i18nKey="adminAddreses.maxItemVolumeDesc">Example: 40*40*60 cm / 1000 = 96 cdm</Trans></small>
            </Form.Text>
          </Col>
        </Row>
        <Form.Text muted className="p-3">
          <small className="form-text text-muted">* CDM - Cubic Decimeter</small>
        </Form.Text>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
        >
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="primary"
          onClick={handleSaveClick}
        >
          <Trans i18nKey="shared.save">Save</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditItemLimitsModal;
