import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dateFormat from 'dateformat';
import { useTranslation } from 'react-i18next';
import getQueryString from '../../../functions/getQueryString';
import { handlePaginatedApiResponse } from '../../../functions/handleApiResponse';
import useTableRequest from '../../../functions/useTableRequest';
import Filters from './Filters';
import { DataTable, TableFooter } from '../../../components/widgets/DataTable';
import fetchWithJWT from '../../../functions/fetchWithJWT';


const getMovements = (user, updateTokens, { filters, pagination, sort }, store) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  if (formatedFilters.date_from) {
    formatedFilters.date_from = dateFormat(formatedFilters.date_from, "yyyy-mm-dd");
  }
  if (formatedFilters.date_to) {
    formatedFilters.date_to = dateFormat(formatedFilters.date_to, "yyyy-mm-dd");
  }
  const queryString = getQueryString({
    filters: formatedFilters,
    pagination,
    sort,
  });
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/history/stock?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
}

const getFields = ({ t }) => [
  {
    field: 'date',
    title: t('movementsHistory.items.date', 'Date'),
    sortable: true,
    valueRenderer: (val) => val ? dateFormat(new Date(val), "dd/mm/yyyy HH:MM:ss") : null,
  },
  {
    field: 'address',
    title: t('movementsHistory.items.address', 'Address'),
    sortable: true,
  },
  {
    field: 'stock_before',
    title: t('movementsHistory.items.stockBefore', 'Stock before'),
    sortable: true,
  },
  {
    field: 'stock_movement',
    title: t('movementsHistory.items.stockMovement', 'Stock movement'),
    sortable: true,
  },
  {
    field: 'item_id',
    title: t('movementsHistory.items.itemId', 'Item id'),
    sortable: true,
  },
  {
    field: 'item_description',
    title: t('movementsHistory.items.itemDescription', 'Item description'),
    sortable: true,
  },
  {
    field: 'department_description',
    title: t('movementsHistory.items.departmentDescription', 'Department description'),
    sortable: true,
  },
  {
    field: 'email',
    title: t('movementsHistory.items.email', 'Email'),
    sortable: true,
  },
];

const Items = ({ isActive, user, updateTokens }) => {
  const { t } = useTranslation();
  const { store } = useParams();
  const now = new Date();
  const monthAgo = new Date();
  monthAgo.setDate(monthAgo.getDate() - 28);
  const initialFilters = {
    date_from: monthAgo,
    date_to: now,
  };
  const [
    {
      loading,
      data,
      error,
      filters,
      pagination,
      sort,
      totals,
    },
    fetchMovements,
    setFilters,
    setPagination,
    setSort,
  ] = useTableRequest(getMovements, { initialState: { filters: initialFilters } });
  useEffect(() => {
    fetchMovements(user, updateTokens, { filters, pagination, sort }, store);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, filters, pagination, sort, fetchMovements]);
  if (!isActive) {
    return null;
  }
  return (
    <>
      <Filters
        user={user}
        updateTokens={updateTokens}
        filters={filters}
        totals={totals}
        sort={sort}
        setFilters={setFilters}
        onClear={() => setFilters(initialFilters)}
      />
      <DataTable
        data={data}
        fields={getFields({ t })}
        loading={loading}
        error={error}
        sort={sort}
        setSort={setSort}
      />
      <TableFooter
        pagination={pagination}
        totals={totals}
        setPagination={setPagination}
      />
    </>
  );
};

export default Items;
