import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { withTranslation, Trans } from 'react-i18next';
import fetchWithJWT from '../functions/fetchWithJWT';
import LangSelect from '../components/widgets/LangSelect';
import { useStoreInfoReduxState } from '../store/storeInfo';

const serverUrl = process.env.REACT_APP_SERVERURL;

class WithSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNav3: false,
      admin: false,
      superAdmin: false,
    }
  }

  toggleSidebar = () => {
    this.setState({
      showNav3: !this.state.showNav3
    });
  }

  componentDidMount() {
    const { user, updateTokens, setStore } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/stores/${localStorage.getItem("reserve_id")}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
      .then(response => response.json())
      .then(result => {
        if (result.code === "200") {
          setStore(result.text);
        } else {
          console.warn(result.text);
        }
      })
      .then(result => this.getAdmins())
      .catch(error => {
        console.warn(error);
      });
  }

  componentDidUpdate(prevProps) {
    // TODO: do AdminAddresses page refactoring to remove this
    let nextStore = { ...this.props.store };
    let hasStoreChanges = false;
    if (this.props.stockOrdersEnabled !== undefined
      && prevProps.stockOrdersEnabled !== this.props.stockOrdersEnabled
      && this.props.store.stock_orders_enabled !== this.props.stockOrdersEnabled
    ) {
      nextStore.stock_orders_enabled = this.props.stockOrdersEnabled;
      hasStoreChanges = true;
    }
    if (this.props.itemStorageEnabled !== undefined
      && prevProps.itemStorageEnabled !== this.props.itemStorageEnabled
      && this.props.store.item_storage_enabled !== this.props.itemStorageEnabled
    ) {
      nextStore.item_storage_enabled = this.props.itemStorageEnabled;
      hasStoreChanges = true;
    }
    if (this.props.trackingNumbersEnabled !== undefined
      && prevProps.trackingNumbersEnabled !== this.props.trackingNumbersEnabled
      && this.props.store.tracking_numbers_enabled !== this.props.trackingNumbersEnabled
    ) {
      nextStore.tracking_numbers_enabled = this.props.trackingNumbersEnabled;
      hasStoreChanges = true;
    }

    if (this.props.ecomOrdersEnabled !== undefined
      && prevProps.ecomOrdersEnabled !== this.props.ecomOrdersEnabled
      && this.props.store.ecom_orders_enabled !== this.props.ecomOrdersEnabled
    ) {
      nextStore.ecom_orders_enabled = this.props.ecomOrdersEnabled;
      hasStoreChanges = true;
    }

    if (this.props.adminCatalogEnabled !== undefined
      && prevProps.adminCatalogEnabled !== this.props.adminCatalogEnabled
      && this.props.store.admin_catalog_enabled !== this.props.adminCatalogEnabled
    ) {
      nextStore.admin_catalog_enabled = this.props.adminCatalogEnabled;
      hasStoreChanges = true;
    }

    if (this.props.autoSupplyEnabled !== undefined
      && prevProps.autoSupplyEnabled !== this.props.autoSupplyEnabled
      && this.props.store.auto_supply_enabled !== this.props.autoSupplyEnabled
    ) {
      nextStore.auto_supply_enabled = this.props.autoSupplyEnabled;
      hasStoreChanges = true;
    }

    if (hasStoreChanges) {
      this.props.setStore(nextStore);
    };
  }

  getAdmins = () => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/admins/${localStorage.getItem("reserve_id")}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
      .then(response => response.json())
      .then(result => {
        const admins = result.admins.map(element => element.user_email);
        this.setState({ admin: admins.includes(this.props.user.email) })
      })
      .catch(error => {
        console.warn(`Error while deciding to display or not admin menu in WithSidebar : ${error}`);
      })
  }

  render() {
    const { admin } = this.state;
    const { store } = this.props;

    const { isLoggingOut, isSuperAdmin } = this.props.user;
    const { t } = this.props;

    return (
      <div className="contentContainer">
        <div className={this.state.showNav3 ? "sidebar active" : "sidebar"}>
          <br />
          <h2 className="text-center srt">
            <Trans i18nKey="sidebar.title">Product Locator</Trans>
          </h2>
          <div className="row">
            <div className="col">
              <h5 className="text-center givenName">{this.props.user.givenName}</h5>
            </div>
          </div>
          <div className="row align-items-stretch justify-content-center">
            <button
              onClick={this.props.logOut}
              className="btn btn-primary"
              disabled={isLoggingOut}
              title={t('sidebar.logout', 'Logout')}
            >
              {isLoggingOut ? (
                <span className="button-loader">
                  <ClipLoader color="#fff" size={15} loading />
                </span>
              ) : <i className="vtmn-icon_power" alt="search"></i>}
            </button>
            <LangSelect />
          </div>

          <br />
          <hr />
          <ul>
            <li>
              <Link className="menu" to={`/${localStorage.getItem("store_number")}/searchAddress`}>
                <Trans i18nKey="sidebar.address">Address</Trans>
              </Link>
            </li>
            {store.stock_orders_enabled && (
              <li>
                <Link className="menu" to={`/${localStorage.getItem("store_number")}/orders`}>
                  <Trans i18nKey="sidebar.orders">Orders</Trans>
                </Link>
              </li>
            )}
            {store.stock_orders_enabled && store.tracking_numbers_enabled && (
              <li>
                <Link className="menu" to={`/${localStorage.getItem("store_number")}/trackingNumbers`}>
                  <Trans i18nKey="sidebar.noPackagingOrders">No-packaging orders</Trans>
                </Link>
              </li>
            )}
            {store.item_storage_enabled && (
              <li>
                <Link className="menu" to={`/${localStorage.getItem("store_number")}/home`}>
                  <Trans i18nKey="sidebar.item">Item</Trans>
                </Link>
              </li>
            )}
            {store.item_storage_enabled && (
              <li>
                <Link className="menu" to={`/${localStorage.getItem("store_number")}/PickingList`}>
                  <Trans i18nKey="sidebar.pickingList">Picking list</Trans>
                </Link>
              </li>
            )}
            <li>
              <Link className="menu" to={`/${localStorage.getItem("store_number")}/report`}>
                <Trans i18nKey="sidebar.stockReport">Stock report</Trans>
              </Link>
            </li>
            {admin && store.admin_catalog_enabled ? (
              <li>
                <Link className="menu" to={`/${localStorage.getItem("store_number")}/Catalog`}>
                  <Trans i18nKey="sidebar.catalog">Catalog</Trans>
                </Link>
              </li>
            ) : null}
            {store.ecom_orders_enabled ? (
              <>
                <li>
                  <Link className="menu" to={`/${localStorage.getItem("store_number")}/DecaShop_orders`}>Ecom pick in store</Link>
                </li>
                <li>
                  <Link className="menu" to={`/${localStorage.getItem("store_number")}/EcomPickingList`}>Ecom picking list</Link>
                </li>
                <li>
                  <Link className="menu" to={`/${localStorage.getItem("store_number")}/ecom-packing`}>Ecom packing</Link>
                </li>
              </>
            ) : null}
            <li>
              <Link className="menu" to={`/${localStorage.getItem("store_number")}/movements-history`}>
                <Trans i18nKey="sidebar.history">History</Trans>
              </Link>
            </li>
            {admin && store.auto_supply_enabled ? (
              <li>
                <Link className="menu" to={`/${localStorage.getItem("store_number")}/layout-requirements`}>
                  <Trans i18nKey="sidebar.autoSupply">Auto supply</Trans>
                </Link>
              </li>
            ) : null}
            {admin ? (
              <>
                <li>
                  <Link className="menu" to={`/${localStorage.getItem("store_number")}/admin`}>
                    <Trans i18nKey="sidebar.admin">Admin</Trans>
                  </Link>
                </li>
                <li>
                  <Link className="menu" to={`/${localStorage.getItem("store_number")}/authorizations`}>
                    <Trans i18nKey="sidebar.authorizations">Authorizations</Trans>
                  </Link>
                </li>
              </>
            ) : null}
            {isSuperAdmin ? (
              <li>
                <Link className="menu" to={`/releases`}>
                  <Trans i18nKey="sidebar.releases">Releases</Trans>
                </Link>
              </li>
            ) : null}
            {admin ? (
              <li>
                <Link className="menu" to={`/${localStorage.getItem("store_number")}/dashboard`}>
                  <Trans i18nKey="sidebar.dashboard">Dashboard</Trans>
                </Link>
              </li>
            ) : null}
            <li>
              <Link className="menu" to={"/changeStore"}>
                <Trans i18nKey="sidebar.changeStore">Change store</Trans>
              </Link>
            </li>
            <li>
              <Link className="menu" to={`/${localStorage.getItem("store_number")}/helpFiles`}>
                <Trans i18nKey="sidebar.help">Help</Trans>
              </Link>
            </li>
          </ul>
        </div>
        <div className="container-fluid p-0">
          <div className="toggle-btn hamburger" onClick={this.toggleSidebar}>
            <div className="row">
              <div className="col-1">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="col my-auto">
                <h6 className="text-center m-0"><em>{localStorage.getItem("store_number")} - {this.props.store.description || ''} - {this.props.store.ean || ''}</em></h6>
              </div>
              {this.state.showNav3
                ? null
                : (
                  <div className="col-3 d-none d-sm-block" style={{ minWidth: '175px' }}>
                    <div className="logo_decat"
                      style={{ textAlign: "right", display: "flex", justifyContent: "flex-end" }}
                    />
                  </div>
                )
              }
            </div>
          </div>
          {this.props.children}
        </div>
      </div>
    )
  }
}

const WithStoreSidebar = (props) => {
  const { store, setStore } = useStoreInfoReduxState();
  return (
    <WithSidebar
      {...props}
      store={store}
      setStore={setStore}
    />
  );
}

export default withTranslation()(WithStoreSidebar);
