import React from 'react';
import { Trans } from 'react-i18next';
import { Alert, Button, Modal } from 'react-bootstrap';

export const MultipleQtyWarning = () => (
  <Alert variant="warning" className="error-message">
    <i className="error-message-icon vtmn-icon_notif_alert"></i>
    <div className="error-message-text">
      <Trans i18nKey="report.orders.sameItemsWarning">There are several quantities of the same item code. Please control the serial number on the product</Trans>
    </div>
  </Alert>
)

const ItemsModalRow = ({ itemId, details, epcItems }) => {
  const serials = epcItems.map(x => x.serial).filter(x => x).join(',');
  return (
    <div className="order-item-row" key={itemId}>
      <div className="order-item-image">
        <img
          key={details.pixlId}
          src={details.img?.src}
          alt=""
          style={{ maxWidth: '100%' }}
        />
      </div>
      <div className="order-item">
        <div className="order-item-field">{`${itemId} - ${details.item_description}`}</div>
        {serials && (
          <div className="order-item-field">
            <span><Trans i18nKey="shared.serial">Serial number</Trans>: </span>
            <span> {serials}</span>
          </div>
        )}
      </div>
    </div>
  );
}

const ItemsModal = ({ items, itemDetailsMap, epcItems, showMultipleQtyWarning, show, onHide }) => (
  <Modal
    show={show}
    onHide={onHide}
  >
    <Modal.Header closeButton>
      <Modal.Title as="h5">
        <Trans i18nKey="report.orders.itemsInOrder">Items in order</Trans>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body >
      {showMultipleQtyWarning && <MultipleQtyWarning />}
      <div className="d-flex justify-content-center align-items-center order-stock-content p-0">
        {(items || []).map((itemId => (
          <ItemsModalRow
            key={itemId}
            itemId={itemId}
            details={itemDetailsMap[itemId] || {}}
            epcItems={epcItems || []}
          />
        )))}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={onHide}>
        {' '}
        <Trans i18nKey="shared.close">Close</Trans>
        {' '}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ItemsModal;
