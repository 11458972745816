import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import useRequest from '../../functions/useRequest';
import Loader from '../../components/Loader';
import SummaryImage from '../../components/widgets/SummaryImage';
import AddressScannerInput from '../../components/widgets/AddressScannerInput';
import { SameItemError } from '../TrackingNumber/messages';
import { useUserReduxState } from '../../store/user';

import { assignAddress } from './api';

const getOrderItems = (order) => {
  if (!order) {
    return []
  };

  const items = [];

  for (const boxItem of order.items) {
    const { qty, addressed_qty, addresses, ...rest } = boxItem;
    if (boxItem.resolve_action === 'picked' && boxItem.qty > boxItem.addressed_qty) {
      const qtyToAssigh = boxItem.qty - boxItem.addressed_qty;
      for (let i = 0; i < qtyToAssigh; i++) {
        items.push({
          key: `${rest.id}-${i}`,
          ...rest,
        });
      }
    }
  }

  return items;
}

const SelectItems = ({ items, selected, setSelected }) => {
  return items.map((item) => {
    const checked = selected.indexOf(item.key) !== -1;
    const onClick = () => {
      if (checked) {
        setSelected(selected.filter(x => x!== item.key));
      } else {
        setSelected([...selected, item.key]);
      }
    };
    return (
      <div onClick={onClick} key={item.key} className="summary-addressing-by-item-selection-line">
        <div className="p-1">
          <input
            id={item.key}
            type="checkbox"
            onChange={onClick}
            checked={checked}
          />
        </div>
        <div className="summary-address-image-column">
          <SummaryImage
            key={item.item_id}
            qty={item.qty}
            item={item.item_info}
          />
        </div>
        <div className="ml-1 text-left">
          {`${item.item_id} ${item.item_info?.item_description || ''}`}
        </div>
      </div>
    )
  });
};

const AddressByItemDialog = ({ trolleyId, order, show, onHide, onSuccess, onError }) => {
  const { user, updateTokens } = useUserReduxState();
  const { store } = useParams();
  const { t } = useTranslation();
  const items = getOrderItems(order);

  const [selected, setSelected] = useState([]);
  const [address, setAddress] = useState('');
  const [validatedAddress, setValidatedAddress] = useState(null);
  const [step, setStep] = useState('select');
  const [isAddressHasSameItem, setIsAddressHasSameItem] = useState(false);

  const [{ loading }, doAssignAddress] = useRequest(assignAddress, {
    onError: (error) => {
      if (error?.response?.order_number && error.response?.has_same_item === true) {
        setIsAddressHasSameItem(true);
      } else {
        onError(error);
      }
    },
    onSuccess: (res) => {
      setStep('select');
      onSuccess(res);
    },
  });

  useEffect(() => {
    setSelected([]);
    setIsAddressHasSameItem(false);
    setAddress('');
    setValidatedAddress(null);
    setStep('select');
  }, [show, order]);

  const onAddress = (force_same_item_to_address) => {
    if (!order || !selected.length) {
      return;
    }
    const selectedItems = items.filter(x => selected.includes(x.key));
    const counted = _.countBy(selectedItems, 'item_id');
    doAssignAddress({
      store,
      trolley_id: trolleyId,
      order_id: order.order_id,
      address,
      items: Object.entries(counted).map(([item_id, qty]) => ({ item_id, qty })),
      force_same_item_to_address,
      user,
      updateTokens
    });
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="ecom-address-by-item-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="trolley.addressingByItem">Addressing by item</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center p-0">
        {loading && <Loader />}
        {!loading && step === 'select' && (
          <div className="p-1">
            <SelectItems
              items={items}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
        )}
        {!loading && step === 'address' && (
          <div className="p-3">
            <AddressScannerInput
              user={user}
              updateTokens={updateTokens}
              store={store}
              placeholder={t('reviewProblems.inputAddress', 'Scan address...')}
              value={address}
              onChange={setAddress}
              validatedAddress={validatedAddress}
              setValidatedAddress={setValidatedAddress}
            />
            {isAddressHasSameItem && (
              <SameItemError
                onClose={() => setIsAddressHasSameItem(false)}
                onForceAssignSameItem={() => onAddress(true)}
              />
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
        >
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        {step === 'select' && (
          <Button
            variant="primary"
            disabled={!selected.length}
            onClick={() => setStep('address')}
          >
            <Trans i18nKey="shared.next">Next</Trans>
          </Button>
        )}
        {step === 'address' && (
          <Button
            variant="primary"
            disabled={loading}
            onClick={() => setStep('select')}
          >
            <Trans i18nKey="shared.back">Back</Trans>
          </Button>
        )}
        {step === 'address' && (
          <Button
            variant="primary"
            disabled={loading || !order || !selected.length || !address || validatedAddress !== address}
            onClick={() => onAddress()}
          >
            <Trans i18nKey="trolley.addressSelected">Address selected</Trans>
            {` (${selected.length})`}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default AddressByItemDialog;
