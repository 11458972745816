import React from 'react';
import { withPrintRequest, withPrintState } from './withPrintState';
import { StatelessPrintMultiAdressesModal } from './printMultiAdressesModal';
import { StatelessPrintLabelModal } from './printLabelModal';
import { printAddresses, printCode } from './api';

const getBarcode = (address) => address;

const getText = (address) => address;

const PrintSingleAddressModal = withPrintRequest(StatelessPrintLabelModal, printCode);
const PrintMultiAdressesModal = withPrintRequest(StatelessPrintMultiAdressesModal, printAddresses);

const PrintAddressModals = ({
  item,
  filters,
  pagination,
  storageOptsKey,
  ...printStateProps
}) => {
  return (
    <>
      <PrintSingleAddressModal
        item={item}
        getBarcode={getBarcode}
        getText={getText}
        storageOptsKey={storageOptsKey}
        {...printStateProps}
      />
      <PrintMultiAdressesModal
        filters={filters}
        pagination={pagination}
        storageOptsKey={storageOptsKey}
        {...printStateProps}
      />
    </>
  )
}

export default withPrintState(PrintAddressModals);
