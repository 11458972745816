
/**
 * Functionm to decode data from EPC tag
 */
const decodeEPC = (epc) => {
  const STANDART = 'SGTIN-96';
  const HEADER = '00110000';
  const BIT_LENGTH = 96;
  const START_OFFSET = 14;

  const partMap = new Map();

  // SGTIN-96 Partitions
  partMap.set(12, [0, 40, 4, 1]);
  partMap.set(11, [1, 37, 7, 2]);
  partMap.set(10, [2, 34, 10, 3]);
  partMap.set(9, [3, 30, 14, 4]);
  partMap.set(8, [4, 27, 17, 5]);
  partMap.set(7, [5, 24, 20, 6]);
  partMap.set(6, [6, 20, 24, 7]);

  const zeroFill = (v, n) => {
    if (v.length >= n) return v;
    return v.padStart(n, '0');
  };

  const getPartitionsByPartitionValue = (pv) => {
    for (const [key, value] of partMap.entries()) {
      if (value[0] === pv) return [key, ...value.slice(1)];
    }
  };

  const getEan13checksum = (ean12) => {
    if (!ean12 || ean12.length !== 12) return null;

    const multiply = [1, 3];
    let total = 0;

    ean12.split('').forEach((letter, index) => {
      total += parseInt(letter, 10) * multiply[index % 2];
    });

    const base10Superior = Math.ceil(total / 10) * 10;

    return base10Superior - total;
  };

  const hex = epc.match(/.{1,2}/g);

  if (!hex) return null;

  const binary = zeroFill(
    hex.reduce((acc, curr) => [...acc, zeroFill(parseInt(curr, 16).toString(2), 8)], []).join(''),
    BIT_LENGTH,
  );

  if (binary.slice(0, 8) !== HEADER) return null;

  // const filter = parseInt(binary.substring(8, 11), 2)
  const pv = parseInt(binary.substring(11, START_OFFSET), 2);
  const lookup = getPartitionsByPartitionValue(pv);

  if (!lookup) return null;

  const companyDigitsLength = lookup[0];
  const companyBitLength = lookup[1];
  const itemBitLength = lookup[2];
  const itemDigitsLength = lookup[3];

  const companyPrefixEnd = START_OFFSET + companyBitLength;

  const company = parseInt(binary.slice(START_OFFSET, companyPrefixEnd), 2);
  const item = parseInt(binary.slice(companyPrefixEnd, companyPrefixEnd + itemBitLength), 2);

  let ean = zeroFill(`${company}${item}`, 12);
  ean += getEan13checksum(ean);

  let serial = parseInt(binary.substring(58), 2).toString();
  if (serial.length > 1 && serial.charAt(0) === '0') serial = '';

  return {
    standart: STANDART,
    binary,
    company: zeroFill(company.toString(), companyDigitsLength),
    item: zeroFill(item.toString(), itemDigitsLength),
    serial,
    serial12: serial.length > 1 ? zeroFill(serial, 12) : '',
    ean,
  };
};

export default decodeEPC;
