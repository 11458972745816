const RangeInput = ({ controlId, label, value, min, max, step, error, onChange }) => {
  return (
    <div className="input-group">
      <label htmlFor={controlId} className="m-0">
        {label}
      </label>
      <input
        id={controlId}
        type="range"
        className="custom-range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={event => onChange(event.target.value)}
      />
      <div className="text-danger">
        {error}
      </div>
    </div>
  );
};

export default RangeInput;
