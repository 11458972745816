import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';

export const getTrolley = async ({ store, id, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/ecommerce_trolleys/${id}`;
  const trolley = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
  return trolley;
};
