import React, { PureComponent } from 'react';
import { Badge } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { normalizeName } from '../../functions/formatText';
import defaultImage from '../../images/default_image.png';

const itemCode = (item_id, item_description, address) => {
  if(item_description === undefined ) {
    return (item_id);
  } else if ( item_id === "page" || item_id === undefined || item_description.includes("does not exist")) {
    return (item_description.split(" ")[0]);
  } else {
    return (item_id);
  }
};

const Image = ({ product, pixlId, onImageClick, tag }) => (
  <div style={{ width: '100px', height: '100px', position: 'relative' }}>
    {!!tag && (
      <Badge
        className="status-badge position-absolute m-1"
        variant="warning"
      >
        {tag}
      </Badge>
    )}
    <img
      src={pixlId ? `https://contents.mediadecathlon.com/p${pixlId}/a.jpg?f=100x100` : defaultImage}
      alt=""
      style={{ cursor: 'pointer', width: '100px', height: '100px' }}
      onClick={() => onImageClick(product, pixlId)}
    />
  </div>
);

class EcomOrderDetailsDesktop extends PureComponent {
  renderRow = (orderItem) => {
    const { t, order_details } = this.props;
    const { addresses } = orderItem;
    if (!addresses) return null
    return addresses.map((element, index) => {
      return (
        <tr key = {index} >
          <td>{itemCode(element.stock.item_id, element.stock.item_description)}</td>
          <td>
            <Image
              product={element.stock}
              pixlId={element.pixlId}
              onImageClick={this.props.onImageClick}
              tag={orderItem.status === 'Wait' ? 'Wait' : null}
            />
          </td>
          <td>
            <div>
              <span>{element.stock.item_description}</span>
            </div>
            <div style={{ paddingTop: 5 }}>
              {orderItem.price > 0 && (
                <span className="tag tag-accent">{`${orderItem.price.toFixed(2)} ${order_details?.currency || ''}`}</span>
              )}
              <span className="tag">{`${orderItem.width} / ${orderItem.height} / ${orderItem.length} cm`}</span>
              <span className="tag">{`${orderItem.weight} kg`}</span>
              {orderItem.is_voluminous && (
                <span className="tag tag-danger">
                  {t('trackingNumbers.trackingNumberTypes.voluminous').toUpperCase()}
                </span>
              )}
              {orderItem.end_of_life && (
                <span className="tag tag-warning">
                  {t('items.endOfLife').toUpperCase()}
                </span>
              )}
            </div>
          </td>
          <td>
            <span className="text-sm text-muted">{orderItem.department_label}</span>
          </td>
          <td>{element.stock.address}</td>
          <td>{element.toPick}</td>
        </tr>
      )
    })
  }

  renderOrderDetails = (details) => {
    if (details && details.items && details.items.length) {
      return details.items.map((orderItem) => this.renderRow(orderItem));
    }
    return null;
  }

  render() {
    const { t } = this.props;
    return (
      <table className="ecom-order-details-desktop table table-striped bor" >
        <thead>
          <tr>
            <th colSpan="6" className="tableTitle">
              <div className="d-flex flex-row justify-content-between align-items-start">
                <div />
                <div>
                  <div>
                    {t('ecomOrdersDetails.orderDetails', {
                      defaultValue: 'Order # {{order}}',
                      order: this.props.order_id,
                    })}
                  </div>
                  <div>
                    <Badge className="mr-2" variant="secondary">
                      {this.props.order_details?.customer_tag}
                    </Badge>
                    <span className="text-muted font-weight-normal" >
                      {normalizeName(this.props.order_details?.customer_name)}
                    </span>
                  </div>
                </div>
                <button
                  className="btn btn-sm closeBtn"
                  onClick={this.props.onClose}
                >
                  <i className="vtmn-icon_cross"></i>
                </button>
              </div>
            </th>
          </tr>
          <tr>
            <th>
              {' '}
              <Trans i18nKey="ecomOrdersDetails.item">Item</Trans>
            </th>
            <th>
              {' '}
              <Trans i18nKey="ecomOrdersDetails.image">Image</Trans>
            </th>
            <th>
              {' '}
              <Trans i18nKey="ecomOrdersDetails.description">Description</Trans>
            </th>
            <th>
              {' '}
              <Trans i18nKey="ecomOrdersDetails.sport">Sport</Trans>
            </th>
            <th>
              {' '}
              <Trans i18nKey="ecomOrdersDetails.location">Location</Trans>
            </th>
            <th>
              {' '}
              <Trans i18nKey="ecomOrdersDetails.qtyPick">Qty to pick</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {this.renderOrderDetails(this.props.order_details)}
        </tbody>
      </table>
    );
  }
}

const MobileSummary = ({ onClose, order_id, order_details }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-row justify-content-between align-items-start">
      <div className="d-flex flex-column">
        <div className="font-weight-bold">
          {t('ecomOrdersDetails.orderDetails', {
            defaultValue: 'Order # {{order}}',
            order: order_id,
          })}
        </div>
        <div>
          <Badge className="mr-2" variant="secondary">
            {order_details?.customer_tag}
          </Badge>
          <span className="text-muted">
            {normalizeName(order_details?.customer_name)}
          </span>
        </div>
      </div>
      <button
        className="btn btn-sm closeBtn"
        onClick={onClose}
      >
        <i className="vtmn-icon_cross"></i>
      </button>
    </div>
  )
};
const MobileRow = ({ orderItem, element, currency, onImageClick }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-row align-items-center">
      <Image
        product={element.stock}
        pixlId={element.pixlId}
        onImageClick={onImageClick}
        tag={orderItem.status === 'Wait' ? 'Wait' : null}
      />
      <div className="d-flex flex-column ml-2">
        <div>
          {`${itemCode(element.stock.item_id, element.stock.item_description)} - ${element.stock.item_description}`}
        </div>
        <div style={{ paddingTop: 5 }}>
          {orderItem.price > 0 && (
            <span className="tag tag-accent">{`${orderItem.price.toFixed(2)} ${currency}`}</span>
          )}
          <span className="tag">{`${orderItem.width} / ${orderItem.height} / ${orderItem.length} cm`}</span>
          <span className="tag">{`${orderItem.weight} kg`}</span>
          {orderItem.is_voluminous && (
            <span className="tag tag-danger">
              {t('trackingNumbers.trackingNumberTypes.voluminous').toUpperCase()}
            </span>
          )}
          {orderItem.end_of_life && (
            <span className="tag tag-warning">
              {t('items.endOfLife').toUpperCase()}
            </span>
          )}
        </div>
        <div>
          <span className="tag">{orderItem.department_label}</span>
        </div>
      </div>
      <div className="flex-fill"/>
      <div className="ml-2">
        <Badge variant="secondary">
          {element.stock.address}
        </Badge>
      </div>
      <div className="ml-2">
        <Badge pill variant="primary">{element.toPick}</Badge>
      </div>
    </div>
  )
};


const EcomOrderDetailsMobile = ({
  order_id,
  order_details,
  email,
  onImageClick,
  onClose,
}) => {
  return (
    <div className="ecom-order-details-mobile flex-column">
      <MobileSummary onClose={onClose} order_id={order_id} order_details={order_details} />
      {order_details?.items?.map((orderItem, itemIndex) => {
        const { addresses } = orderItem;
        if (!addresses) return null;
        return addresses.map((element, addressIndex) => (
          <MobileRow
            key={`${itemIndex}_${addressIndex}`}
            orderItem={orderItem}
            element={element}
            currency={order_details?.currency || ''}
            onImageClick={onImageClick}
          />
        ));
      })}
    </div>
  )
};

const EcomOrderDetails = ({
  order_id,
  order_details,
  email,
  onImageClick,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <EcomOrderDetailsDesktop
        order_id={order_id}
        order_details={order_details}
        email={email}
        onImageClick={onImageClick}
        onClose={onClose}
        t={t}
      />
      <EcomOrderDetailsMobile
        order_id={order_id}
        order_details={order_details}
        email={email}
        onImageClick={onImageClick}
        onClose={onClose}
      />
    </>
  );
}

export default EcomOrderDetails;
