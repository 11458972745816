import React from 'react';
import '../../stylesheets/tabs.css';

const Tabs = ({
  className,
  tabs,
  activeTab,
  setActiveTab,
}) => {
  return (
    <div
      className={`${className} btn-group btn-block mb-2`}
      role="group"
    >
      {tabs.map(({ code, label }) => (
        <button
          key={code}
          type="button"
          className={`tab-button col pl-1 pr-1 m-0 btn ${activeTab === code ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={()=> setActiveTab(code)}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
