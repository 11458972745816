import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import WithSidebar from './WithSidebar';
import Admins from "./admins.js";
import Pickers from "./pickers.js";
import Viewers from "./viewers.js";
import fetchWithJWT from '../functions/fetchWithJWT';
import Loader from '../components/Loader';

const serverUrl = process.env.REACT_APP_SERVERURL;

class Authorizations extends Component {
  constructor(props){
    super(props);
    this.state = {
      selectedMenu: "pickers",
      itemStorageEnabled: false,
      loading: true,
    }
  }
  componentDidMount(){
    this.getReserveStatus();
  }

  getReserveStatus = () => {
    const reserve_id = localStorage.getItem("reserve_id");
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/stores/${reserve_id}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(result => result.json())
    .then(details => {
      const itemStorageEnabled = details && details.text && details.text.item_storage_enabled;
      const selectedMenu = itemStorageEnabled ? "pickers" : "admins";
      this.setState({
        itemStorageEnabled,
        selectedMenu,
        loading: false,
      });
    })
    .catch((e) => {
      this.setState({
        loading: false,
      });
      console.warn('Error at getReserveStatus', e);
    });
  }

  checkUserExistence = (email, giveRights) => {
    const { t, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/users/${email}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(response => response.json())
    .then(result => {
      if (result.code === "200"){
        return giveRights(result.infos[0].email);
      } else if (result.code === "404"){
        const alertText = t('authorizations.userNotExist', {
          defaultValue: 'User {{email}} does not exist in Product Locator, please ask him to connect once before giving him rights',
          email,
        });
        alert(alertText);
      } else {
        return "problem in query"
      }
    })
    .catch(error => {
      console.warn(`Error while checking existence of user ${email} : ${error}`);
    })
  }

  switchMenu = (desiredState) => {
    if (this.state.selectedMenu !== desiredState){
      this.setState({
        selectedMenu: desiredState
      })
    }
  }

  renderTabs = () => {
    const { selectedMenu } = this.state;
    const isAdminsTab = selectedMenu === "admins";
    const isPickersTab = selectedMenu === "pickers";
    const isViewersTab = selectedMenu === "viewers";
    return (
      <div
        className="btn-group btn-block mb-3"
        role="group"
      >
        <button
          id="Pickers"
          type="button"
          className={`tab-button col m-0 btn ${isPickersTab ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={() => this.switchMenu("pickers")}
        >
          <Trans i18nKey="authorizations.pickers">Pickers</Trans>
        </button>
        <button
          id="Admins"
          type="button"
          className={`tab-button col m-0 btn ${isAdminsTab ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={() => this.switchMenu("admins")}
        >
          <Trans i18nKey="authorizations.admins">Admins</Trans>
        </button>
        <button
          id="Viewers"
          type="button"
          className={`tab-button col m-0 btn ${isViewersTab ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={() => this.switchMenu("viewers")}
        >
          <Trans i18nKey="authorizations.viewers">Viewers</Trans>
        </button>
      </div>
    );
  }

  render(){
    const { t } = this.props;
    const { selectedMenu, itemStorageEnabled, loading } = this.state;
    const isAdminsTab = selectedMenu === "admins";
    const isPickersTab = selectedMenu === "pickers";
    const isViewersTab = selectedMenu === "viewers";
    const title = (
      itemStorageEnabled
        ? t('authorizations.authorizations', 'Authorizations')
        : t('authorizations.authorizationsAdmins', 'Authorizations - Admins')
    );
    return(
      <WithSidebar user={this.props.user} updateTokens={this.props.updateTokens} logOut={this.props.logOut}>
        <div className = "jumbotron container" style={{position:"relative"}}>
          <h2 className="text-center">{title}</h2>
          {loading && <Loader />}
          {!loading && itemStorageEnabled && this.renderTabs()}
          {!loading && isPickersTab && (
            <Pickers
              checkUserExistence={this.checkUserExistence}
              store={this.props.match.params.store}
              user= {this.props.user}
              updateTokens={this.props.updateTokens}
            />
          )}
          {!loading && isAdminsTab && (
            <Admins
              checkUserExistence={this.checkUserExistence}
              user= {this.props.user}
              updateTokens={this.props.updateTokens}
            />
          )}
          {!loading && isViewersTab && (
            <Viewers
              user= {this.props.user}
              updateTokens={this.props.updateTokens}
            />
          )}
        </div>
      </WithSidebar>
    )
  }
}

export default withTranslation()(Authorizations);
