import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Alert, Badge, Button, ButtonGroup, Card, Modal, ToggleButton } from "react-bootstrap";

import '../../stylesheets/cardCantDialog.css';
import Loader from '../../components/Loader';

const CantPickDialog = ({
  loading,
  item,
  ifStoreFulfiller,
  show,
  onHide,
  onConfirm,
}) => {
  const [radio, setRadio] = useState({ action: '' });
  const { action } = radio;

  useEffect(() => {
    if (show) {
      setRadio({ action: '' });
    }
  }, [show]);

  const handleChange = e => {
    e.persist();

    setRadio(prevState => ({
      ...prevState,
      action: e.target.value
    }));
  };

  return (
    <Modal
      className="cardCantPick-dialog"
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <i className="modal-header-icon text-primary vtmn-icon_notif_help" />
        <Modal.Title>
          <Trans i18nKey="shared.confirm">Confirm</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        {loading && <Loader />}
        {!loading && (
          <>
            <div className="p-3 customer-info">
              <Alert variant="info">
                <Trans i18nKey="ecomPickingList.cannotPickingListsInfo">
                  You can't pick this item, you can contact the customer.
                </Trans>
              </Alert>
              <div className="px-2">
                <div className="row align-items-center ">
                  <i className="customer-icon vtmn-icon_account mr-2" /> {item?.customer_name ? item.customer_name : '-'}
                </div>
                <div className="row align-items-center">
                  <i className="customer-icon vtmn-icon_mail mr-2" /> {item?.customer_email ? item.customer_email : '-'}
                </div>
                <div className="row align-items-center">
                  <i className="customer-icon vtmn-icon_mobile mr-2" />
                  <a href={`tel:${item?.customer_phone}`}>
                    {item?.customer_phone ? item.customer_phone : '-'}
                  </a>
                </div>
              </div>
            </div>
            <ButtonGroup className="p-3">
              <div className="row justify-content-center align-content-center">
                <Card className="cardCantPick text-center">
                  <Card.Body className="px-3 py-2">
                    <Card.Title className="mb-0">
                      <ToggleButton
                        id="radio-cancel"
                        type="radio"
                        variant="outline-light"
                        name="radio"
                        value="cancel"
                        checked={action === "cancel"}
                        onChange={handleChange}
                        className="d-inline-flex"
                      >
                        <div className="boxToggle">
                          <Badge className="tag tag-danger m-0">
                            <Trans i18nKey="shared.cancel">Cancel</Trans>
                          </Badge>
                        </div>
                      </ToggleButton>
                    </Card.Title>
                    <Card.Text>
                      <span className="text-muted small cardCantPick-text">
                        <Trans i18nKey="ecomPickingList.cancelPickingListsInfo">
                          The customer wants to cancel the item for which there is not enough stock.
                          Please finish the action in CubeInStore.
                        </Trans>
                    </span>
                    </Card.Text>
                  </Card.Body>
                </Card>
                {!ifStoreFulfiller && (
                <Card className="cardCantPick text-center">
                  <Card.Body className="px-3 py-2">
                    <Card.Title className="mb-0">
                      <ToggleButton
                        id="radio-replace"
                        type="radio"
                        variant="outline-light"
                        name="radio"
                        value="replace"
                        checked={action === "replace"}
                        onChange={handleChange}
                        className="d-inline-flex"
                      >
                        <div className="boxToggle">
                          <Badge className="tag tag-accent m-0">
                            <Trans i18nKey="shared.replace">Replace</Trans>
                          </Badge>
                        </div>
                      </ToggleButton>
                    </Card.Title>
                    <Card.Text>
                    <span className="text-muted small cardCantPick-text">
                      <Trans i18nKey="ecomPickingList.replacePickingListsInfo">
                        The customer wants to change an item by another (with same or lower price). Please finish
                        the action in CubeInStore.
                      </Trans>
                    </span>
                    </Card.Text>
                  </Card.Body>
                </Card>
                )}
              </div>
            </ButtonGroup>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          <Trans i18nKey="shared.close">Close</Trans>
        </Button>
        <Button
          variant="primary"
          onClick={() => onConfirm(action)}
          disabled={loading || action === ''}
        >
          <Trans i18nKey="shared.confirm">Confirm</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CantPickDialog;
