import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/Loader';
import useRequest from '../../functions/useRequest';
import {
  LockedMessage,
  CanceledMessage,
  CompletedMessage,
  WrongStoreMessage,
  NoPackagingOrderAddingError,
  OrderToMountMessage,
} from './messages';
import ItemDetails from './ItemDetails';
import ItemScannerInput from '../../components/widgets/ItemScannerInput';
import AddressScannerInput from '../../components/widgets/AddressScannerInput';
import { TNRelatedSgippingGroupMessage, getTNHandleRelatedSgPress } from '../../components/widgets/RelatedSgippingGroupMessage';
import ItemToMountSwitch from '../OrdersMount/components/ItemToMountSwitch';
import { isNeedMount } from '../OrdersMount/helpers';
import { useStoreInfoReduxState } from '../../store/storeInfo';

const OptionItem = ({
  orderItem,
  onAction,
}) => {
  const { t } = useTranslation();
  const { tracking_number } = orderItem;
  return (
    <div className="tracking-number-order-options">
      <div className="order-item">
        {tracking_number && (
          <div>
            <div className="text-secondary font-weight-bold d-inline-block mr-1">
              {t('trackingNumber.trackingNumber', 'Tracking number')}
              {': '}
            </div>
            {tracking_number}
          </div>
        )}
      </div>
      <div className="order-actions">
        <Button
          block
          variant="success"
          onClick={onAction}
        >
          <Trans i18nKey="trackingNumber.match">Match</Trans>
        </Button>
      </div>
    </div>
  );
};

const Options = ({ options, onAction, title }) => {
  return (
    <>
      <h4>{title}</h4>
      {options.map(option => (
        <OptionItem
          key={option.id}
          orderItem={option}
          onAction={() => onAction(option)}
        />
      ))}
    </>
  );
};

const ConfirmMissing = ({
  store,
  user,
  updateTokens,
  show,
  onClose,
  saveMissing,
  unexpectedMatchMissing,
  reloadOrderItems,
  orderItem,
  unexpectedMatches,
  setUnexpectedMatches,
  uid,
  modalLoading,
  modalError,
  doUnlockItem,
  doForceLockItem,
  setBarcodePreview,
  setEpc,
}) => {
  const { t } = useTranslation();
  const { store: storeInfo } = useStoreInfoReduxState();
  const lockedBy = orderItem?.lockedBy;
  const isLocked = !!lockedBy && lockedBy !== uid;
  const isCanceled = orderItem?.shipping_group?.is_canceled;
  const isCompleted = orderItem?.shipping_group?.is_completed;
  const isWrongStore = orderItem?.shipping_group?.is_wrong_store;
  const [selectedOption, setSelectedOption] = useState(null);
  const [itemId, setItemId] = useState('');
  const [address, setAddress] = useState('');
  const [isTomount, setIsTomount] = useState(false);
  const [error, setError] = useState(null);
  const [validatedAddress, setValidatedAddress] = useState(null);
  const history = useHistory();
  const hasRelatedSG = !!orderItem?.related_shipping_groups?.length;
  const isOrderToMount = orderItem?.order?.is_order_tomount;
  const mountStatus = orderItem?.order?.mount_status;
  const needMount = isNeedMount(mountStatus, isOrderToMount);

  const onHide= () => {
    doUnlockItem();
    setSelectedOption(null);
    setError(null);
    setItemId('');
    setAddress('');
    setIsTomount(needMount);
    onClose();
  };
  const [{
    loading: saving,
  }, doSaveMissing] = useRequest(saveMissing, {
    onError: setError,
    onSuccess: () => {
      reloadOrderItems();
      setSelectedOption(null);
      setError(null);
      setItemId('');
      setAddress('');
      setIsTomount(needMount);
      onClose();
    }
  });
  const [{
    loading: matching,
  }, doUnexpectedMatchMissing] = useRequest(unexpectedMatchMissing, {
    onError: setError,
    onSuccess: (res) => {
      reloadOrderItems();
      setSelectedOption(null);
      setError(null);
      setItemId('');
      setAddress('');
      setIsTomount(needMount);
      onClose();
      if (res?.stock?.address_type === 'locker') {
        setBarcodePreview(res.stock.barcode_preview);
      }
    }
  });
  const onDoMatch = () => doUnexpectedMatchMissing({
    id: selectedOption.unexpected.id,
    is_tomount: isTomount,
    address,
  });
  const loading = saving || matching || modalLoading;
  return (
    <Modal
      show={show}
      onHide={onHide}
      className="modalAlert"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="trackingNumber.confirmMissing">Confirm item missing</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <Loader />}
        {!loading && !mountStatus && !!isOrderToMount && (
          <OrderToMountMessage />
        )}
        {!loading && hasRelatedSG && (
          <TNRelatedSgippingGroupMessage
            relatedShippingGroups={orderItem.related_shipping_groups || []}
            mode="contained"
            onClick={getTNHandleRelatedSgPress(
              store,
              orderItem.order,
              orderItem.related_shipping_groups || [],
              history,
              onClose,
            )}
          />
        )}
        {!loading && isLocked && (
          <LockedMessage lockedBy={lockedBy} />
        )}
        {!loading && isCanceled && (
          <CanceledMessage missing />
        )}
        {!loading && isCompleted && (
          <CompletedMessage missing />
        )}
        {!loading && isWrongStore && (
          <WrongStoreMessage missing orderItem={orderItem} />
        )}
        {!loading && <ItemDetails orderItem={orderItem} />}
        {!isLocked && !loading && (
          <>
            {unexpectedMatches && unexpectedMatches.length > 0 && (
              <Options
                title={t('trackingNumber.otherUnexpectedItems', 'Unexpected items')}
                options={unexpectedMatches}
                onAction={(option) => {
                  setUnexpectedMatches(null);
                  setSelectedOption(option);
                }}
              />
            )}
            {selectedOption && (
              <>
                <ItemToMountSwitch
                  active={storeInfo?.to_mount_enabled}
                  isTomount={isTomount}
                  setIsTomount={setIsTomount}
                  isOrderToMount={isOrderToMount}
                  mountStatus={mountStatus}
                />
                {itemId !== orderItem?.item.item_id && (
                  <h5>
                    <Trans i18nKey="trackingNumber.confirmMissingScanItem">Scan or input item_id to continue</Trans>
                  </h5>
                )}
                <ItemScannerInput
                  user={user}
                  updateTokens={updateTokens}
                  placeholder={t('reviewProblems.inputItemCode', 'Scan item...')}
                  value={itemId}
                  onChange={setItemId}
                  isValid={itemId && itemId === orderItem?.item.item_id}
                  isInvalid={itemId && itemId !== orderItem?.item.item_id}
                  invalidMessage={t('reviewProblems.invalidItemCode', 'Item code is not correct')}
                  setEpc={setEpc}
                />
                {itemId === orderItem?.item.item_id && (!address || validatedAddress !== address) && (
                  <h5>
                    <Trans i18nKey="trackingNumber.confirmMissingScanAddress">Scan or input address</Trans>
                  </h5>
                )}
                <AddressScannerInput
                  user={user}
                  updateTokens={updateTokens}
                  store={store}
                  placeholder={t('reviewProblems.inputAddress', 'Scan address...')}
                  value={address}
                  onChange={setAddress}
                  validatedAddress={validatedAddress}
                  setValidatedAddress={setValidatedAddress}
                />
              </>
            )}
          </>
        )}
        {!loading && (error || (!isLocked && modalError)) && (
          <NoPackagingOrderAddingError
            error={error || (!isLocked && modalError)}
            onClose={() => setError(null)}
            onForceAssignSameItem={() => doUnexpectedMatchMissing({
              id: selectedOption.unexpected.id,
              address,
              is_tomount: isTomount,
              force_same_item_to_address: true,
            })}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        {isLocked && (
          <Button variant="warning" onClick={() => doForceLockItem(orderItem)} disabled={loading}>
            <Trans i18nKey="trackingNumber.processAnyway">Process anyway</Trans>
          </Button>
        )}
        {!isLocked && (
          <Button variant="danger" disabled={loading} onClick={() => doSaveMissing()}>
            <Trans i18nKey="trackingNumber.missing">Missing</Trans>
          </Button>
        )}
        {!isLocked && selectedOption && (
          <Button
            variant="success"
            disabled={
              !address
              || validatedAddress !== address
              || itemId !== orderItem?.item.item_id
              || loading
            }
            onClick={onDoMatch}
          >
            <Trans i18nKey="trackingNumber.saveMatch">Save match</Trans>
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmMissing;
