import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Confirm from '../../components/widgets/Confirm';

export const WrongPickerDialog = ({ wrongPicker, onConfirm, onClose }) => {
  const { t } = useTranslation();
  return (
    <Confirm
      show
      important
      cheboxLabel={t('ecomPickingList.confirmPick', 'I confirm picking')}
      onHide={onClose}
      icon={<i className="modal-header-icon text-warning vtmn-icon_notif_help" />}
      title={t('shared.warning', 'Warning')}
      body={
        <>
          {t('ecomPickingList.wrongPicker', 'Current order is being picked by')}
          {':'}
          {`${wrongPicker.first_name} ${wrongPicker.last_name} (${wrongPicker.id})`}
          <br />
          {t('ecomPickingList.doPickAnyway', { defaultValue: 'Do you want to pick it anyway?', count: 1 })}
        </>
      }
      buttonOpts={{
        text: t('shared.pickAnyway', 'Pick anyway'),
        variant: 'warning',
      }}
      onConfirm={onConfirm}
    />
  );
};

const getWrongPickers = (wrongPickers) => {
  const pickers = _.uniq(wrongPickers.map(x => `${x.first_name} ${x.last_name} (${x.id})`)).join(', ')
  return pickers
};

export const MassWrongPickerDialog = ({ wrongPickers, onConfirm, onClose }) => {
  const { t } = useTranslation();

  return (
    <Confirm
      show
      important
      cheboxLabel={t('ecomPickingList.confirmPick', 'I confirm picking')}
      onHide={onClose}
      icon={<i className="modal-header-icon text-warning vtmn-icon_notif_help" />}
      title={t('shared.warning', 'Warning')}
      body={
        <>
          {t('ecomPickingList.wrongPickerBatch', {
            defaultValue: '{{count}} order is being picked by',
            count: wrongPickers.length,
          })}
          {': '}
          {getWrongPickers(wrongPickers)}
          <br />
          {t('ecomPickingList.doPickAnyway', { defaultValue: 'Do you want to pick it anyway?', count: wrongPickers.length })}
        </>
      }
      buttonOpts={{
        text: t('shared.pickAnyway', 'Pick anyway'),
        variant: 'warning',
      }}
      onConfirm={onConfirm}
    />
  );
};
