import { handleApiResponse } from '../../functions/handleApiResponse';
import fetchWithJWT from '../../functions/fetchWithJWT';
import { logBarcodeReadEvent } from '../../functions/analytics';

export const infoIsError = (info) => !!(info && info.code && info.text && info.error);

export const getOrder = async ({ store, orderCode, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/stock_orders/${encodeURIComponent(orderCode)}`;
  try {
    const res = await fetchWithJWT(url, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(handleApiResponse);
    const notFound = res?.info?.code === '404';
    logBarcodeReadEvent({ success: !notFound, readingType: 'order', value: orderCode });
    return res;
  } catch (e) {
    throw e;
  }
};

export const addOrder = ({
  store,
  tracking_number,
  shipping_group_number,
  address,
  parcel_number,
  is_tomount,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/stock_orders`;
  const body = JSON.stringify({
    shipping_group_number,
    tracking_number,
    address,
    parcel_number,
    is_tomount,
    confirm_not_found: true,
  });
  return fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body
  })
  .then(handleApiResponse);
};

export const pickOrder = ({ store, order_number, to_customer, id, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/stock_orders/${encodeURIComponent(order_number)}/${id}?to_customer=${to_customer}`;
  return fetchWithJWT(url, {
    method: 'DELETE',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

export const removeOrdersByStatus = ({ store, status, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/stock_orders?status=${status}`;
  return fetchWithJWT(url, {
    method: 'DELETE',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
      .then(handleApiResponse);
};

export const completeMovement = ({ store, order_number, id, address, force_same_item_to_address, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/stock_orders/${encodeURIComponent(order_number)}/${id}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body:JSON.stringify({
      address,
      force_same_item_to_address,
    }),
  })
  .then(handleApiResponse);
};

export const queryOrders = ({ store, search, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/stock_orders/query/${search}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

export const queryOrdersByCustomer = ({ store, email, firstName, lastName, mobile, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const query = {};
  let hasEnoughLetters = false;
  if (email) {
    query.email = email;
    if (email.length >= 2) {
      hasEnoughLetters = true;
    }
  }
  if (firstName) {
    query.first_name = firstName;
    if (firstName.length >= 2) {
      hasEnoughLetters = true;
    }
  }
  if (lastName) {
    query.last_name = lastName;
    if (lastName.length >= 2) {
      hasEnoughLetters = true;
    }
  }
  if (mobile) {
    query.mobile = mobile;
    if (mobile.length >= 2) {
      hasEnoughLetters = true;
    }
  }
  if (!hasEnoughLetters) {
    return Promise.resolve([]);
  }
  const queryString = new URLSearchParams(query).toString();
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/stock_orders/query_by_customer?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

export const foundMissing = ({
  store,
  id,
  address,
  force_same_item_to_address,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/tracking_numbers/missing_item/${id}/found`;
  const body = JSON.stringify({
    address,
    force_same_item_to_address,
  });
  return fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body
  })
  .then(handleApiResponse);
};

export const getCurrentStore = ({
  user,
  updateTokens,
}) => {
  const reserveId = localStorage.getItem("reserve_id");
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/stores/${reserveId}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

export const getComments = async ({ store, orderNumber, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/stock_orders/${orderNumber}/comments`;
  const comments = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
  return comments;
};

export const postComment = async ({ store, orderNumber, text, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/stock_orders/${orderNumber}/comments`;
  const comment = await fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body: JSON.stringify({ text }),
  })
  .then(handleApiResponse);
  return comment;
};

export const cancelOrder = async ({
  store,
  orderNumber,
  shippingGroup,
  cancelReason,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/stock_orders/${orderNumber}/cancel`;
  const resp = await fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body: JSON.stringify({
      shipping_group_number: shippingGroup,
      cancel_reason: cancelReason,
    }),
  })
  .then(handleApiResponse);
  return resp;
};

export const backToSaleOrder = async ({
  store,
  orderNumber,
  shippingGroup,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/stock_orders/${orderNumber}/back_to_sale`;
  const resp = await fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body: JSON.stringify({
      shipping_group_number: shippingGroup,
    }),
  })
  .then(handleApiResponse);
  return resp;
};
