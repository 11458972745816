export const FORMATS = ['A3', 'A4', 'A5', 'A6', 'A7'];

export const getFromatOptions = (t, addOriginal) => {
  const options = FORMATS.map((x) => ({ id: x, label: x }));
  if (addOriginal) {
    options.push({
      id: 'original',
      label: t('selectPrinter.originalSize', 'Original size'),
    });
  }
  return options;
};

export const getOrientationOptions = (t) => ([
  { id: 'portrait', label: t('selectPrinter.portrait', 'Portrait') },
  { id: 'landscape', label: t('selectPrinter.landscape', 'Landscape') },
]);

export const FILETYPES = ['svg', 'pdf'];
export const FILETYPE_OPTIONS = FILETYPES.map((x) => ({ id: x, label: x.toLocaleUpperCase() }));

export const getPrinter = (printers, selected, filePrinterLabel) => {
  let printer = null;
  if (selected === 'file') {
    printer = {
      id: 'file',
      name: filePrinterLabel,
    }
  } else {
    printer = printers.find(x => x.id === selected);
  }
  return printer
}

export const findSelectedPrinter = (
  printers,
  lastPrinter,
  filePrinterLabel,
  defaultToFile = false,
) => {
  if (!printers || !lastPrinter) {
    if (defaultToFile) {
      return {
        id: 'file',
        name: filePrinterLabel,
      };
    }
    return null;
  }

  if (lastPrinter.id === 'file') {
    return {
      id: 'file',
      name: filePrinterLabel,
    };
  }

  const printer = printers.find(x => x.id === lastPrinter.id);
  return printer;
};
