import React from 'react';

const ProblemRowField = ({ label, value, bold, inline }) => {
  return (
    <div className={`${inline ? 'd-flex align-items-center' : ''}`}>
      <div className="text-secondary font-weight-bold">
        {label && `${label}:`}
      </div>
      <div
        className={`${bold ? 'font-weight-bold' : ''} ${inline ? 'ml-1 flex-fill' : ''}`}
        style={{ wordWrap: 'anywhere' }}
      >
        {value}
      </div>
    </div>
  );
};

export default ProblemRowField;
