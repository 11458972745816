import React, { useState, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import Loader from '../../components/Loader';
import useRequest from '../../functions/useRequest';
import ZXScanner from '../../components/widgets/scanner/ZXScanner';
import ScannerButton from '../../components/widgets/scanner/ScannerButton';
import { NoPackagingOrderAddingError } from '../TrackingNumber/messages';
import { logBarcodeReadEvent } from '../../functions/analytics';
import { completeMovement } from './api';

const ModalCompleteMovement = ({
  store,
  orderNumber,
  stockOrderId,
  show,
  onClose,
  user,
  updateTokens,
  onSuccess,
}) => {
  const [address, setAddress] = useState('');
  const [error, setError] = useState(null);
  const addressScannerRef = useRef();
  const { t } = useTranslation();

  const onHide = () => {
    setAddress('');
    onClose();
  };

  const [{
    loading: isCompleting,
  }, doCompleteMovement] = useRequest(completeMovement, {
    onSuccess: () => {
      logBarcodeReadEvent({ success: true, readingType: 'address', value: address });
      onHide();
      onSuccess();
    },
    onError: (e) => {
      const isAddressNotFound = e.response && e.response.address && e.response.exists === false;
      if (isAddressNotFound) {
        logBarcodeReadEvent({ success: false, readingType: 'address', value: address });
      }
      return setError(e)
    },
  });

  const onSave = (newAddress) => {
    doCompleteMovement({
    store,
    order_number: orderNumber,
    id: stockOrderId,
    address: newAddress,
    user,
    updateTokens,
  })};

  const loading = isCompleting;
  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="orders.completeMovement">Complete movement</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? <Loader/> : (
          <>
            <p>
              <Trans i18nKey="orders.movingOrder">Are you going to move the order to another address?</Trans>
            </p>
            <InputGroup>
              <Form.Control
                placeholder={t('orders.addressInput', 'Input address...')}
                type="text"
                value={address}
                onChange={(event) => setAddress(event.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.repeat) {
                    setTimeout(()=>onSave(address), 100);
                  }
                }}
              />
              <InputGroup.Append>
                <ScannerButton scannerRef={addressScannerRef} />
              </InputGroup.Append>
            </InputGroup>
          </>
        )}
        {!loading && error && (
            <NoPackagingOrderAddingError
              error={error}
              onClose={() => setError(null)}
              onForceAssignSameItem={() => doCompleteMovement({
                store,
                order_number: orderNumber,
                id: stockOrderId,
                address,
                force_same_item_to_address: true,
                user,
                updateTokens,
              })}
            />
          )}
        <ZXScanner
          readingType="address"
          ref={addressScannerRef}
          onDetected={(val) => {
            setAddress(val)
            onSave(val)
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        <Button variant="primary" onClick={()=>onSave(address)} disabled={loading || !address}>
          <>
            <Trans i18nKey="orders.moveOrderMove">Move</Trans>
            {' '}
            <Trans i18nKey="orders.moveOrderOrder">order</Trans>
          </>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalCompleteMovement;
