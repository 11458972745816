import React from 'react';
import { Row, Badge, Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import { RELEASE_TYPE_OPTIONS } from './constants';
import translateSvg from '../../images/translate.svg';

const ReleaseBaseView = (props) => {
  const { release, translationsCount, onEdit, onEditTranslations } = props;
  const { t } = useTranslation();

  if (!release) return null

  const typeLabel = RELEASE_TYPE_OPTIONS.find(x => x.id === release.type)?.label || release.type

  return (
    <Card className="d-flex mb-2">
      <Card.Body>
        <Row className="mb-2">
        <Badge pill variant="dark">{typeLabel}</Badge>
        <Badge pill variant={release.published ? 'success' : 'light'} className="ml-1">
          {release.published ?
            t('releases.published', 'Published') :
            t('releases.unpublished', 'Unpublished')
          }
        </Badge>
        </Row>
        <Card.Text className="text-muted">
          <Row as="span">
            {`${t('releases.version_code', 'Version code')}: ${release.version_code || '-'}`}
          </Row>
          <Row as="span" className={release.has_breaking_changes ? 'text-warning' : ''}>
            {`${t('releases.breakingChanges', 'Breaking changes')}: ${
              release.has_breaking_changes ? t('shared.yes') : t('shared.no')
            }`}
          </Row>
          <Row as="span">
            {`${t('releases.summary', 'Summary')}: ${release.summary || '-'}`}
          </Row>
        </Card.Text>
        {!!onEditTranslations && (
          <Button variant="light" size="sm" onClick={onEditTranslations}>
            <img src={translateSvg} width={20} height={20} alt='' />
            {translationsCount > 0 && (
              <Badge pill variant="light" className="ml-2">{translationsCount}</Badge>
            )}
          </Button>
        )}
      </Card.Body>
      <Card.Footer>
        {!!onEdit && (
          <Button variant="primary" size="sm" onClick={onEdit}>
            {t('releases.editRelease', 'Edit release')}
          </Button>
        )}
        <Row as="span" className="text-muted text-sm mt-2 ml-1 mr-1">
          {`${t('shared.lastChange', 'Last change')}: ${
            dateFormat(
              new Date(release.change_date),
              'dd/mm/yyyy HH:MM:ss'
            )
          }`}
        </Row>
      </Card.Footer>
    </Card>
  );
};

export default ReleaseBaseView;
