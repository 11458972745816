import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';

export const getPrinters = async ({
  store,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/print/${store}/printers`;
  const res = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse);
  return res;
};

export const printLink = async ({
  store,
  printer,
  format,
  link,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/print/store/${store}/printer/${printer}/link/print`;
  const res = await fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({ format, link }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse);
  return res;
};

export const printCode = async ({
  store,
  printer,
  codetype,
  code,
  printOpts,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/print/store/${store}/printer/${printer}/${codetype}/${code}/print`;
  const res = await fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify(printOpts),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse);
  return res;
};

export const printAddresses = async ({
  store,
  printer,
  codetype,
  query,
  filters,
  printOpts,
  user,
  updateTokens,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/print/store/${store}/printer/${printer}/address/${codetype}/print?${query}`;
  const res = await fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({
      filters,
      opts: printOpts,
    }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse);
  return res;
};
