import React from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const DaterangeFilter = ({
  controlId,
  label,
  valueFrom,
  valueTo,
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <div className="filter-item form-group">
      <label className="form-label" htmlFor={controlId}>
        {label}
        {': '}
      </label>
      <div className="filter-date-pare input-group">
        <DatePicker
          id={controlId}
          className="form-control form-control-sm"
          placeholderText={t('filters.daterangeFilter.from', 'From')}
          isClearable
          selected={valueFrom}
          onChange={(nextValueFrom) => onChange(nextValueFrom, valueTo)}
          dateFormat="dd/MM/yyyy"
        />
        <DatePicker
          className="form-control form-control-sm"
          placeholderText={t('filters.daterangeFilter.to', 'To')}
          isClearable
          selected={valueTo}
          onChange={(nextValueTo) => onChange(valueFrom, nextValueTo)}
          dateFormat="dd/MM/yyyy"
        />
      </div>
    </div>
  );
}

export default DaterangeFilter;
