import React from 'react';
import { Trans } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';

const ErrorDialog = ({
  error,
  setError,
  title,
}) => (
  <Modal
    show={!!error}
    onHide={() => setError(null)}
  >
    <Modal.Header closeButton>
      <i className="modal-header-icon text-danger vtmn-icon_notif_alert"></i>
      <Modal.Title as="h5">
        {title || <Trans i18nKey="shared.error">Error</Trans>}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body >
      {error?.message || <Trans i18nKey="shared.defaultError">Error occured</Trans>}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={() => setError(null)}>
      <Trans i18nKey="shared.ok">OK</Trans>
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ErrorDialog;

