import React, { Component } from 'react';
import _ from "lodash";
import { withTranslation, Trans } from 'react-i18next';
import fetchWithJWT from '../functions/fetchWithJWT';
import { handleApiResponse } from '../functions/handleApiResponse';
import SearchBar from '../components/widgets/SearchBar';
import Loader from '../components/Loader';
import CountrySelect from '../components/widgets/CountrySelect';
import "../stylesheets/flags.css";
import { Button } from 'react-bootstrap';
import AddStoreDialog from '../components/widgets/AddStoreDialog';
import '../stylesheets/changeStore.css';
import blankFlag from "../stylesheets/blankFlag.gif";

const serverUrl = process.env.REACT_APP_SERVERURL;

class ChangeStore extends Component {

  state = {
    search: '',
    sort: 'asc',
    storesList: [],
    error: null,
    loading: true,
    addNewStoreOpen: false,
    selectedCountry: '',
  }

  // ---------------------------
  // retrieve the list of stores
  // ---------------------------
  componentDidMount() {
    this.loadStores();
  }

  loadStores = () => {
    this.setState({ loading: true, error: null });
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/stores`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
      .then(handleApiResponse)
      .then(result => {
        this.setState({
          storesList: result,
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          error,
          loading: false
        });
        console.log(error);
      });
  }

  cleanNbsp = (text) => {
    return text && text.replace('\xa0', ' ');
  }

  // -----------------------------
  // insert a backroom in the list
  // -----------------------------
  insertRow = (store) => {
    const flagClass = `flag flag-${store.country_code.toLowerCase()}`;
    return (
      <tr key={store.id}>
        <td className="to-center">{store.store_number}</td>
        <td className="to-center">{this.cleanNbsp(store.description)}</td>
        <td className="to-center">
          <div className="country-flag-wrapper">
            <div className="country-flag">
              <img src={blankFlag} className={flagClass} alt={store.country_code} />
            </div>
          </div>
        </td>
        <td>
          <button className="btn btn-success btn-sm" onClick={() => this.chooseStore(store)}>
            <Trans i18nKey="shared.ok">OK</Trans>
          </button>
        </td>
      </tr>
    )
  }

  // -------------------------------------
  // change the store in the local storage
  // and redirect
  // -------------------------------------
  chooseStore = (store) => {
    localStorage.setItem("store_number", store.store_number);
    localStorage.setItem("reserve_id", store.id);
    this.props.resetState();
    let route = `/${store.store_number}/home`;
    if (!store.item_storage_enabled) {
      if (store.stock_orders_enabled) {
        route = `/${store.store_number}/orders`;
      } else {
        route = `/${store.store_number}/searchAddress`;
      }
    }
    this.props.history.push(route);
  }

  filterStores = (stores, search, countryCode) => {
    if (!stores) {
      return [];
    }
    let filtered = stores;
    if (search) {
      // filter by search
      filtered = filtered.filter(store => {
        const toSearch = `${store.store_number} ${this.cleanNbsp(store.description)}`.toLowerCase();
        const matches = toSearch.indexOf(search.toLowerCase()) !== -1;
        return matches;
      })
    }
    if (countryCode) {
      // filter by country
      filtered = filtered.filter(store => store.country_code === countryCode.toUpperCase())
    }
    return filtered;
  }

  sortColumn = (column) => {
    let sortedTable = this.state.storesList;

    if (this.state.sort === "asc") {
      sortedTable = _.sortBy(sortedTable, column);
      this.setState({ sort: "desc" });
    } else if (this.state.sort === "desc") {
      sortedTable = _.sortBy(sortedTable, column).reverse();
      this.setState({ sort: "asc" });
    }

    this.setState({ storesList: sortedTable })
  }

  updateSelectedCountry = (countryCode) => {
    this.setState({ selectedCountry: countryCode });
  }

  render() {
    const { loading, storesList, search, error, addNewStoreOpen, selectedCountry } = this.state;
    const { t, user, updateTokens, logOut } = this.props;
    const { isLoggingOut } = user;
    const filtered = this.filterStores(storesList, search, selectedCountry);
    const { token, refreshToken, tokenExpireDate } = user;
    return (
      <div className="jumbotron container change-store" style={{ position: "relative" }}>
        <h2 className="text-center">
          <Trans i18nKey="changeStore.title">Choose store</Trans>
        </h2>
        <div className="p-2 d-flex align-items-stretch">
          <div className="btn-new-store-wrapper">
            <button
              id="btnNew"
              className="vtmn-btn vtmn-btn_variant--secondary pl-2 pr-3"
              onClick={() => {
                this.setState({ addNewStoreOpen: true })
              }}
            >
              <i className="vtmn-icon_plus_v2"></i>
              <Trans i18nKey="changeStore.new">New</Trans>
            </button>
          </div>
          <SearchBar
            loading={loading}
            value={search}
            onChange={(search) => this.setState({ search })}
            onRefresh={this.loadStores}
          />
          <div className="btn-new-store-wrapper">
            <CountrySelect updateSelectedCountry={this.updateSelectedCountry} stores={storesList} />
          </div>
        </div>
        {error && (
          <div className="alert alert-danger fade show ml-2 mr-2" role="alert">
            <div>
              {error.message || t('shared.defaultError', 'Error occured')}
            </div>
            <Button
              className="icon-button mt-2"
              variant="danger"
              size="sm"
              disabled={isLoggingOut}
              onClick={logOut}
            >
              <i className="vtmn-icon_power" alt="search"></i>
              {t('sidebar.logout', 'Logout')}
            </Button>
          </div>
        )}
        {loading && <Loader />}
        {!loading && (
          <div className="mobile-table-wrapper">
            <table className="table table-hover text-center mobile-table">
              <thead>
                <tr>
                  <th onClick={this.sortColumn.bind(this, 'store_number')}>
                    <Trans i18nKey="changeStore.store">Store</Trans>
                  </th>
                  <th onClick={this.sortColumn.bind(this, 'description')}>
                    <Trans i18nKey="changeStore.description">Description</Trans>
                  </th>
                  <th onClick={this.sortColumn.bind(this, 'country_code')}>
                    <Trans i18nKey="changeStore.country">Country</Trans>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  filtered.length
                    ? filtered.map((store) => this.insertRow(store))
                    : (
                      <tr>
                        <td colSpan={4} className="p-0">
                          <div className="d-flex justify-content-center align-items-center p-2">
                            <Trans i18nKey="changeStore.notFound">Store not found</Trans>
                            <Button
                              variant="link"
                              onClick={() => this.setState({ addNewStoreOpen: true })}
                              className="link-button ml-1"
                            >
                              <Trans i18nKey="changeStore.newRequest"> new request here</Trans>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    )
                }
              </tbody>
            </table>
          </div>
        )}
        <AddStoreDialog
          isOpen={addNewStoreOpen}
          onClose={() => { this.setState({ addNewStoreOpen: false }) }}
          jwtOpts={{
            token,
            refreshToken,
            tokenExpireDate,
            updateTokens,
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(ChangeStore);
