import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroup, Form } from 'react-bootstrap';
import ZbarScanner from '../../components/widgets/scanner/ZbarScanner';
import ScannerButton from '../../components/widgets/scanner/ScannerButton';

const SearchByItem = ({
  onSearch,
  item,
  setItem,
}) => {
  const { t } = useTranslation();

  const trackingScannerRef = useRef();
  const placeholder = t('trackingNumber.enterItem', 'Enter next item_id...');
  return (
    <Form.Group className="searchbar mb-2">
      <InputGroup>
        <Form.Control
          id="tracking-number-item-search"
          className="ml-0"
          type="text"
          value={item}
          placeholder={placeholder}
          onChange={(event) => setItem(event.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.repeat) {
              setTimeout(() => {
                onSearch(item);
                setItem('');
              }, 100);
            }
          }}
        />
        <InputGroup.Append>
          <button
            id="buttonTrackingNumberSubmit"
            className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_with-only-icon"
            onClick={() => {
              onSearch(item);
              setItem('');
            }}
          >
            <i className="vtmn-icon_search"></i>
          </button>
          <ScannerButton scannerRef={trackingScannerRef} />
        </InputGroup.Append>
      </InputGroup>
      <ZbarScanner
        readingType="item"
        ref={trackingScannerRef}
        onDetected={(scannedItem) => {
          onSearch(scannedItem);
          setItem('');
        }}
      />
    </Form.Group>
  )
};

export default SearchByItem;
