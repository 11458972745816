import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dateFormat from 'dateformat';
import { Trans, useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import getQueryString from '../../../functions/getQueryString';
import { handlePaginatedApiResponse, handleApiResponse } from '../../../functions/handleApiResponse';
import useRequest from '../../../functions/useRequest';
import useTableRequest from '../../../functions/useTableRequest';
import Filters from './Filters';
import ActionsHelpIcon from './ActionsHelpIcon';
import { DataTable, TableFooter } from '../../../components/widgets/DataTable';
import fetchWithJWT from '../../../functions/fetchWithJWT';


const getMovements = (user, updateTokens, { filters, pagination, sort }, store) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  if (formatedFilters.date_from) {
    formatedFilters.date_from = dateFormat(formatedFilters.date_from, "yyyy-mm-dd");
  }
  if (formatedFilters.date_to) {
    formatedFilters.date_to = dateFormat(formatedFilters.date_to, "yyyy-mm-dd");
  }
  const queryString = getQueryString({
    filters: formatedFilters,
    pagination,
    sort,
  });
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/history/stock_orders?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

const getShippingMethods = (user, updateTokens, store) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/history/stock_orders/shipping_methods`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const getEcomDurations = (user, updateTokens, filters, store) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  if (filters.date_from) {
    formatedFilters.date_from = dateFormat(filters.date_from, "yyyy-mm-dd");
  }
  if (filters.date_to) {
    formatedFilters.date_to = dateFormat(filters.date_to, "yyyy-mm-dd");
  }
  const queryString = getQueryString({
    filters: formatedFilters,
  });
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/ecommerce_trolleys/history/duration?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
}

const getFields = ({ t }) => [
  {
    field: 'date',
    title: t('movementsHistory.orders.date', 'Date'),
    sortable: true,
    valueRenderer: (val) => val ? dateFormat(new Date(val), "dd/mm/yyyy HH:MM:ss") : null,
  },
  {
    field: 'address',
    title: t('movementsHistory.orders.address', 'Address'),
    sortable: true,
  },
  {
    field: 'order_number',
    title: t('movementsHistory.orders.order', 'Order'),
    sortable: true,
  },
  {
    field: 'shipping_group_number',
    title: t('movementsHistory.orders.shippingGroup', 'Shipping group'),
    sortable: true,
  },
  {
    field: 'tracking_number',
    title: t('movementsHistory.orders.trackingNumber', 'Tracking number'),
    sortable: true,
  },
  {
    field: 'parcel_number',
    title: t('movementsHistory.orders.parcel', 'Parcel'),
    sortable: true,
  },
  {
    field: 'item_id',
    title: t('movementsHistory.orders.item', 'Item'),
    sortable: true,
  },
  {
    field: 'packaging_type',
    title: t('movementsHistory.orders.packagingType', 'Type'),
    sortable: true,
  },
  {
    field: 'shipping_method',
    title: t('movementsHistory.orders.shippingMethod', 'Shipping method'),
    sortable: true,
  },
  {
    field: 'action_type',
    title: (
      <div className="order-history-tooltip-title">
        {t('movementsHistory.orders.action', 'Action')}
        <ActionsHelpIcon />
      </div>
    ),
    sortable: true,
  },
  {
    field: 'email',
    title: t('movementsHistory.orders.email', 'Email'),
    sortable: true,
  },
  {
    field: 'read_rfid',
    title: t('movementsHistory.orders.readRFID', 'Read with RFID?'),
    sortable: true,
    valueRenderer: (val) => val === 'yes' ? t('shared.yes', 'Yes') : t('shared.no', 'No')
  },
];

const HOUR_SECONDS = 3600;

const EcomDurationsMessage = ({ durations }) => {
  const {
    pick_count,
    order_count,
    qty_total,
    duration_seconds,
    performance_duration_seconds,
    pack_performance_duration_seconds,
  } = durations;

  const { t } = useTranslation();

  if (!pick_count || !order_count || !qty_total || !performance_duration_seconds || !duration_seconds) {
    return null;
  }

  const qtyPickProductivity = performance_duration_seconds / order_count;
  const qtyPackProductivity = pack_performance_duration_seconds / order_count;
  const qtyTotalProductivity = qtyPickProductivity + qtyPackProductivity;
  const qtyPerOrder = qty_total / order_count;
  const orderPerHour = order_count * HOUR_SECONDS / (performance_duration_seconds + pack_performance_duration_seconds);

  return (
    <Alert variant="info">
      <Alert.Heading>
        <Trans i18nKey="movementsHistory.ecomProductivity.productivity">Ecom preparation productivity</Trans>
      </Alert.Heading>
      <ul>
        <li>
          {t('movementsHistory.ecomProductivity.secondsPerQty', {
            defaultValue: '1 teammate picks 1 order in {{count}} seconds average',
            count: qtyPickProductivity.toFixed(0),
          })}
        </li>
        {qtyPackProductivity > 0 && (
          <li>
            {t('movementsHistory.ecomPackProductivity.secondsPerQty', {
              defaultValue: '1 teammate packs 1 order in {{count}} seconds average',
              count: qtyPackProductivity.toFixed(0),
            })}
          </li>
        )}
        {qtyPackProductivity > 0 && (
          <li>
            {t('movementsHistory.ecomTotalProductivity.secondsPerQty', {
              defaultValue: '1 teammate prepares 1 order in {{count}} seconds average',
              count: qtyTotalProductivity.toFixed(0),
            })}
          </li>
        )}
        <li>
          {t('movementsHistory.ecomProductivity.qtyPerOrder', {
            defaultValue: 'Average quantities per order: {{count}}',
            count: qtyPerOrder.toFixed(2),
          })}
        </li>
        <li>
          {t('movementsHistory.ecomProductivity.orderPerHour', {
            defaultValue: 'Number of orders per hour: {{count}}',
            count: orderPerHour.toFixed(2),
          })}
        </li>
      </ul>
    </Alert>
  );
};

const Orders = ({ isActive, user, updateTokens }) => {
  const { t } = useTranslation();
  const { store } = useParams();
  const now = new Date();
  const monthAgo = new Date();
  monthAgo.setDate(monthAgo.getDate() - 28);
  const initialFilters = {
    date_from: monthAgo,
    date_to: now,
  };
  const [
    {
      loading: historyLoading,
      data,
      error,
      filters,
      pagination,
      sort,
      totals,
    },
    fetchMovements,
    setFilters,
    setPagination,
    setSort,
  ] = useTableRequest(getMovements, { initialState: { filters: initialFilters } });
  const [{ data: ecomDurations, loading: durationsLoading }, fetchEcomDurations] = useRequest(getEcomDurations, { initialState: { filters: initialFilters } })
  const [{ data: shippingMethods }, fetchShippingMethods] = useRequest(getShippingMethods);
  useEffect(() => {
    fetchShippingMethods(user, updateTokens, store);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[store, fetchShippingMethods]);
  useEffect(() => {
    fetchMovements(user, updateTokens, { filters, pagination, sort }, store);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, filters, pagination, sort, fetchMovements]);
  useEffect(() => {
    fetchEcomDurations(user, updateTokens, {
      date_from: filters.date_from,
      date_to: filters.date_to,
      email: filters.email,
      read_rfid: filters.read_rfid,
      shipping_method: filters.shipping_method,
    }, store)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, filters.date_from, filters.date_to, filters.email, filters.read_rfid, filters.shipping_method])
  const loading = historyLoading || durationsLoading;
  if (!isActive) {
    return null;
  }

  return (
    <>
      <Filters
        user={user}
        updateTokens={updateTokens}
        filters={filters}
        totals={totals}
        sort={sort}
        setFilters={setFilters}
        shippingMethods={shippingMethods || []}
        onClear={() => setFilters(initialFilters)}
      />
      {!loading && ecomDurations && <EcomDurationsMessage durations={ecomDurations} />}
      <DataTable
        data={data}
        fields={getFields({ t })}
        loading={loading}
        error={error}
        sort={sort}
        setSort={setSort}
      />
      <TableFooter
        pagination={pagination}
        totals={totals}
        setPagination={setPagination}
      />
    </>
  );
};

export default Orders;
