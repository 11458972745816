const Switch = ({ controlId, checked, label, onChange, disabled }) => {
  return (
    <div className="input-group">
      <label className={`switch ${disabled ? 'switch-disabled' : ''}`}>
        <input
          id={controlId}
          type="checkbox"
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />
        <span className="slider round"></span>
      </label>
      <label htmlFor={controlId} className="switch-label text">
        {label}
      </label>
    </div>
  );
};

export default Switch;
