import React from 'react';
import { Trans } from 'react-i18next';

const ImageModal = ({ item, item_description, pixlId, imageUrl, tags }) => (
  <div className="modal fade" id="imageModal" tabIndex="-1" role="dialog" aria-labelledby="imageModalTitle" aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="imageModalTitle">
            {item}
            {' - '}
            {item_description}
          </h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body d-flex justify-content-center align-items-center">
          {imageUrl && (
            <img
              key={item}
              src={imageUrl}
              alt=""
              style={{ maxWidth: '100%' }}
            />
          )}
          {pixlId && (
            <img
              key={pixlId}
              src={`https://contents.mediadecathlon.com/p${pixlId}/a.jpg?f=340x340`}
              alt=""
              style={{ maxWidth: '100%' }}
            />
          )}
          <div className="image-details-tag-container">
            {tags}
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" data-dismiss="modal">
            {' '}
            <Trans i18nKey="shared.close">Close</Trans>
            {' '}
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default ImageModal;
