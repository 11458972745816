import React from 'react';
import { useTranslation } from 'react-i18next';

import useRequest from '../../functions/useRequest';
import { useUserReduxState } from '../../store/user';
import Confirm from '../../components/widgets/Confirm';

import { removeReleaseQuery } from './api';

const ReleaseRemoveConfirmDialog = (props) => {
  const { show, release, onDone, onHide } = props;
  const { t } = useTranslation();
  const { user, updateTokens } = useUserReduxState();

  const [{ loading, error }, removeRelease] = useRequest(removeReleaseQuery, {
    onSuccess: onDone,
  });

  const onConfirm = () => removeRelease({ id: release.id, user, updateTokens });

  return (
    <Confirm
      show={show}
      onHide={onHide}
      loading={loading}
      error={error}
      title={t('releases.releaseRemove', 'Release remove')}
      body={t('version.releaseRemoveConfirm', {
        defaultValue: 'Do you want to remove {{value}} release?',
        value: `${release.version} (${release.version_code}) ${release.type}`
      })}
      buttonOpts={{ variant: 'danger' }}
      onConfirm={onConfirm}
    />
  );
};

export default ReleaseRemoveConfirmDialog;
