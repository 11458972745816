import React, { useState } from "react";
import _ from "lodash";
import InputFilter from "../../components/widgets/filters/InputFilter";
import SelectFilter from "../../components/widgets/filters/SelectFilter";
import SortSelect from '../../components/widgets/SortSelect';
import { Trans, useTranslation } from 'react-i18next';
import { getMountStatus } from './helpers';

export const DEFAULT_FILTERS = {
  mount_status: ["ready_tomount"],
  order_number: undefined,
  customer: undefined,
};

const getSortIcon = (sort) => (field) => {
  if (sort.field !== field) {
    return "sort";
  } else if (sort.direction === "ASC") {
    return "arrow1_down";
  } else {
    return "arrow1_up";
  }
};

const updateSort = (sort, setSort) => (field) => {
  const nextSort = { field };
  if (sort.field !== field) {
    nextSort.direction = "ASC";
  } else if (sort.direction === "ASC") {
    nextSort.direction = "DESC";
  } else {
    nextSort.direction = "ASC";
  }
  setSort(nextSort);
};

const hasFilters = (filters) => {
  const values = Object.entries(filters).filter(([k, v]) => !_.isEqual(v || '', DEFAULT_FILTERS[k] || ''));
  const filtered = values.length !== 0;
  return filtered;
};

export const Filters = ({
  filters,
  setFilters,
  onClear,
  sort,
  setSort,
}) => {
  const { t } = useTranslation();
  const [visibility, setVisibility] = useState("partial");
  const isVisible = visibility !== "hidden";
  const filtered = hasFilters(filters);
  const mountStatusOptions = [
    {
      id: "ready_tomount",
      label: getMountStatus(t, "ready_tomount").label,
    },
    {
      id: "partialy_received",
      label: getMountStatus(t, "partialy_received").label,
    },
    {
      id: "mounted",
      label: getMountStatus(t, "mounted").label,
    },
  ];
  const sortOptions = [
    {
      id: "main_item.promised_shipping_date",
      label: t("orderMount.promiseDate", "Promise date"),
    },
  ];

  return (
    <div
      className={`card filter-card filter-card-${visibility} mb-0 mt-3 mx-2`}
    >
      <div className="card-header">
        <button
          size="sm"
          className={`btn btn-primary btn-sm icon-button filter-button ${
            filtered ? "filter-button-filtered" : ""
          }`}
          onClick={() => setVisibility(isVisible ? "hidden" : "partial")}
        >
          <i className="vtmn-icon_filter2"></i>
          {filtered && <i className="vtmn-icon_tiny_bold_valid subicon"></i>}
          <Trans i18nKey="filters.filters">Filters</Trans>
          {isVisible ? (
            <i className="vtmn-icon_chevron_up_v2 right"></i>
          ) : (
            <i className="vtmn-icon_chevron_down_v2 right"></i>
          )}
        </button>
        <div className="spacer" />
        <div className="filter-sort">
          <SortSelect
            controlId="sort"
            sort={sort}
            updateSort={updateSort(sort, setSort)}
            getSortIcon={getSortIcon(sort)}
            options={sortOptions}
            variant="primary"
          />
        </div>
      </div>

      {isVisible && (
        <div className="card-body">
          <div className="row">
            <InputFilter
              controlId="filter-order-number"
              label={t("shared.order", "Order")}
              value={filters.order_number}
              onChange={(order_number) =>
                setFilters({ ...filters, order_number })
              }
            />
            <InputFilter
              controlId="filter-customer"
              label={t("shared.customer", "Customer")}
              value={filters.customer}
              onChange={(customer) => setFilters({ ...filters, customer })}
            />
            <SelectFilter
              multiple
              controlId="filter-mount-status"
              label={t("orderMount.mountStatus", "Mount status")}
              options={mountStatusOptions}
              selected={filters.mount_status}
              onChange={(mount_status) =>
                setFilters({ ...filters, mount_status })
              }
            />

            <div className="spacer" />
            <div>
              <button
                className="icon-button filter-button btn btn-primary btn-sm"
                onClick={onClear}
              >
                <i className="vtmn-icon_cross"></i>
                <Trans i18nKey="filters.reset">Reset</Trans>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
