import React from "react";
import _ from "lodash";
import { Trans, useTranslation } from "react-i18next";
import { Badge, Button } from 'react-bootstrap';
import "../../stylesheets/addressingParcels.css";
import packageImage from "../../images/package.png";

export const isReceived = (parcel) =>
  parcel.status === 'TO_COLLECT' || parcel.status === 'FULFILLED'

export const getParcelStatus = (stockItem, sg) => {
  if (!sg || !sg.parcels) {
    return null;
  }

  if (stockItem.packaging_type === "no-packaging") {
    const parcel = sg.parcels.find(
      (p) => p.box_tracking_number === stockItem.tracking_number
    );
    return parcel?.status || null;
  }

  if (stockItem.packaging_type === "parcel") {
    const parcel = sg.parcels.find(
      (p) => p.tracking_number === stockItem.tracking_number
    );
    return parcel?.status || null;
  }

  return null;
};

const formatParcel = (parcel, stock, trackingNumber) => {
  const stockItem = (stock || []).find((s) => {
    if (s.packaging_type === "no-packaging") {
      return s.tracking_number === parcel.box_tracking_number;
    } else if (s.packaging_type === "parcel") {
      return s.tracking_number === parcel.tracking_number;
    }
    return false;
  });
  const current = parcel.tracking_number === trackingNumber;
  const address = stockItem?.address || null;
  const received = isReceived(parcel);
  return { ...parcel, address, current, received };
};

export const getOrderParcels = (trackingNumber, info, stock) => {
  if (!info || info.source !== "stores_fulfiller_api") {
    return [];
  }

  const parcels = info.shipping_groups
    .filter((sg) => sg.parcels?.length)
    .map((sg) => ({
      container: sg.id,
      parcels: _.orderBy(
        (sg.parcels || []).map((p) => formatParcel(p, stock, trackingNumber)),
        ["current", "tracking_number"],
        ["desc", "asc"]
      ),
    }));

  return parcels;
};

const PARCEL_STATUS_MAP = {
  IN_TRANSIT: "TO RECEIVE",
};
export const humanizeParcelStatus = (status) => {
  if (status in PARCEL_STATUS_MAP) {
    return PARCEL_STATUS_MAP[status];
  }
  return status.replace(/_/g, " ");
};

const ParcelNumber = ({ value }) => {
  if (!value) {
    return null;
  }
  const firstChars = value.slice(0, -4);
  const lastChars = value.slice(-4);

  return (
    <div className="parcel-number">
      <div className="parcel-number-first">{firstChars}</div>
      <div className="parcel-number-last">{lastChars}</div>
    </div>
  );
};

const AddressingParcelItem = ({
  address,
  tracking_number,
  box_tracking_number,
  status,
  current,
  received,
  onBackToSale,
}) => {
  const { t } = useTranslation();
  const isNopack = !!box_tracking_number;
  let parcelCls = "addressing-parcel";
  if (received) {
    parcelCls += " addressing-parcel-success";
  }
  if (current) {
    parcelCls += " addressing-parcel-current";
  }
  return (
    <div className={parcelCls}>
      <img src={packageImage} alt="parcel" />
      {isNopack && <Badge className="no-pack-tag" variant="warning">no-pack</Badge>}
      <div className="parcel-info">
        <div className="parcel-line">
          <ParcelNumber value={tracking_number} />
          {address ? <div className="parcel-address">{address}</div> : null}
        </div>
        {current && (
          <div className="current-label">
            {t("orders.scannedParcel", "The parcel you just scanned")}
          </div>
        )}
        {status === 'CANCELLED' && (
          // this button displayed at mobile view
          <div className="d-flex">
            <Button
              variant="danger"
              size="sm"
              className="action-btn action-btn-sm d-flex d-sm-none"
              onClick={onBackToSale}
            >
              <Trans i18nKey="orders.putInStock.putInStock">Put in store stock</Trans>
            </Button>
          </div>
        )}
      </div>
      {status === 'CANCELLED' && (
        // this button displayed at desktop view
        <Button
          variant="danger"
          size="sm"
          className="action-btn action-btn-wide d-none d-sm-flex"
          onClick={onBackToSale}
        >
          <Trans i18nKey="orders.putInStock.putInStock">Put in store stock</Trans>
        </Button>
      )}
      <div className="parcel-status">
        <div className="parcel-status-text">{humanizeParcelStatus(status)}</div>
      </div>
    </div>
  );
};

export const AddressingParcels = ({ parcels, onBackToSale }) => {
  return (
    <div className="addressing-parcels">
      {parcels.map((p, index) => (
        <AddressingParcelItem key={index} onBackToSale={onBackToSale} {...p} />
      ))}
    </div>
  );
};

export const OrderParcels = ({ containers, onBackToSale }) => {
  return (
    <div className="order-parcels">
      {containers.map(({ container, parcels }, index) => (
        <div className="container-parcels" key={index}>
          <div className="container-text">{container}</div>
          <AddressingParcels parcels={parcels} onBackToSale={() => onBackToSale(container)} />
        </div>
      ))}
    </div>
  );
};
