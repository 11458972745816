import JWTManager from './JWTManager';
import { checkMaintenance } from './handleApiResponse';

const jwtManager = new JWTManager();

const defaultHeaders = {
  "Content-Type": "application/json",
};

const fetchWithJWT = async (
  url, {
    method,
    headers,
    body,
    jwtOpts,
  }={},
) => {
  const {
    token,
    refreshToken,
    tokenExpireDate,
    updateTokens,
  } = jwtOpts || {};

  const authHeaders = await jwtManager.getAuthHeaders({
    token,
    refreshToken,
    tokenExpireDate,
    updateTokens,
  });
  return fetch(url, {
    method,
    headers: {
      ...authHeaders,
      ...headers || defaultHeaders,
    },
    body,
  })
  .then(result => {
    checkMaintenance(result);
    if (result.status === 401) {
      if (window.location.search && new URLSearchParams(window.location.search).get('code')) {
        // remove params, to avoid auth loop
        window.location = window.location.href.split("?")[0];
      } else {
        window.location.reload();
      }
    }
    return result;
  })
};

export const getAuthHeaders = async (jwtOpts) => {
  const {
    token,
    refreshToken,
    tokenExpireDate,
    updateTokens,
  } = jwtOpts || {};
  const authHeaders = await jwtManager.getAuthHeaders({
    token,
    refreshToken,
    tokenExpireDate,
    updateTokens,
  });
  return authHeaders;
}

export default fetchWithJWT;
