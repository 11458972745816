import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';

const buildUniqueLettersList = (addressesList) => {
  const arrayLetters = addressesList.map(address => address.address.substring(0,1));
  return Array.from(new Set(arrayLetters));
}

const AlleyFilter = ({
  alleys,
  addresses,
  selected,
  onFilter,
}) => {
  const letters = alleys || buildUniqueLettersList(addresses || []);
  const selectedMissing = !!(
    selected
    && selected.toLowerCase() !== "all"
    && letters
    && letters.indexOf(selected) === -1
  );
  useEffect(() => {
    if (selectedMissing) {
      // reset filter if selected mismatch
      onFilter("all");
    }
  }, [selectedMissing, onFilter]);

  return (
    <>
      <button
        type="button"
        className={`btn aisleLetter btn-sm ${selected && ['all', ''].includes(selected.toLowerCase()) === "all" ? "active" : ""}`}
        onClick={() => onFilter("all")}
      >
        <Trans i18nKey="filters.alleyFilter.all">All</Trans>
      </button>
      {letters.map(letter => (
        <button
          key={letter}
          type="button"
          className={`btn aisleLetter btn-sm ${selected === letter ? "active" : ""}`}
          onClick={() => onFilter(letter) }
        >{letter}</button>
      ))}
    </>
  );
}

export default AlleyFilter;
