const LANGUAGES = ['en', 'fr', 'it', 'de', 'es', 'nl'];

const LOCALES_MAP = {
  en: {
    code: 'en-US',
    flag: 'flag-gb',
    alt: 'English'
  },
  fr: {
    code: 'fr-FR',
    flag: 'flag-fr',
    alt: 'French'
  },
  it: {
    code: 'it-IT',
    flag: 'flag-it',
    alt: 'Italian'
  },
  de: {
    code: 'de-DE',
    flag: 'flag-de',
    alt: 'German'
  },
  es: {
    code: 'es-ES',
    flag: 'flag-es',
    alt: 'Spanish'
  },
  nl: {
    code: 'nl-NL',
    flag: 'flag-nl',
    alt: 'Dutch'
  }
};

export { LANGUAGES, LOCALES_MAP };
