import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

const ResupplyResultDialog = ({
  resupplyResults,
  checkProblemEnabled,
  showProblems,
  onItemLink,
  modalResultsRef,
  title,
}) => {
  const pickingsCreated = resupplyResults.pickingsCreated || [];
  const notEnoughStock = resupplyResults.notEnoughStock || [];
  const stockProblems = resupplyResults.stockProblems || [];
  const hasProblems = checkProblemEnabled && stockProblems.length > 0;
  const storeNumber = localStorage.getItem("store_number");

  return (
    (
      <div
        key="results-modal" className="modal fade" id="modal-results" tabIndex="-1"
        role="dialog" aria-labelledby="modalResultsTitle" aria-hidden="true"
        ref={modalResultsRef}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              {hasProblems
                ? <i className="modal-header-icon text-danger vtmn-icon_notif_alert"></i>
                : <i className="modal-header-icon text-success vtmn-icon_valid_rounded"></i>
              }

              <h5 className="modal-title" id="modalResultsTitle">
                {title}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close-modal-results">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div id="accordion" className="accordion">
                {showProblems && (
                  <div className="card">
                    <div className="card-header p-0" id="stockProblemsHeading">
                      <h5 className="mb-0">
                        <button
                          className={`btn btn-light accordion-header-button ${hasProblems ? '' : 'collapsed'}`}
                          data-toggle="collapse"
                          data-target="#stockProblems"
                          aria-expanded="true"
                          aria-controls="stockProblems"
                        >
                          {hasProblems
                            ? <i className="accordion-header-icon text-danger vtmn-icon_notif_alert"></i>
                            : <i className="accordion-header-icon text-primary vtmn-icon_notif_information"></i>
                          }
                          <span className="accordion-header-text">
                            <Trans i18nKey="layoutRequirements.stockProblems">Stock problems:</Trans>
                            {' '}
                            ({stockProblems.length})
                          </span>
                          <i className="accordion-header-down vtmn-icon_chevron_down_v2"></i>
                          <i className="accordion-header-up vtmn-icon_chevron_up_v2"></i>
                        </button>
                      </h5>
                    </div>

                    <div
                      id="stockProblems"
                      className={`collapse ${hasProblems ? 'show' : ''}`}
                      aria-labelledby="stockProblemsHeading"
                      data-parent="#accordion"
                    >
                      <div className="card-body p-0">
                        <table id="stockProblemsTable" className="table table-hover mb-0">
                        <thead>
                          <tr>
                            <th className="text-center">
                              <Trans i18nKey="layoutRequirements.itemCode">Item code</Trans>
                            </th>
                            <th className="text-center">
                              <Trans i18nKey="layoutRequirements.productLocator">Product Locator</Trans>
                            </th>
                            <th className="text-center">stores.com</th>
                          </tr>
                        </thead>
                          <tbody>
                            {stockProblems.map(item => (
                              <tr key={item.item_id} id="stockAlertAfterResupplyCalculation">
                                <td className="to-center text-center">
                                  <Link
                                    to={`/${storeNumber}/home?item_id=${item.item_id}`}
                                    className="clickableText"
                                    onClick={onItemLink}
                                  >
                                    {item.item_id}
                                  </Link>
                                </td>
                                <td className="to-center text-center">
                                  {item.in_reserve}
                                </td>
                                <td className="to-center text-center">
                                  {item.stores_com_stock}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                <div className="card">
                  <div className="card-header p-0" id="notEnoughStockHeading">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-light accordion-header-button collapsed"
                        data-toggle="collapse"
                        data-target="#notEnoughStock"
                        aria-expanded="true"
                        aria-controls="notEnoughStock"
                      >
                        <i className="accordion-header-icon text-primary vtmn-icon_notif_information"></i>
                        <span className="accordion-header-text">
                          <Trans i18nKey="layoutRequirements.notEnough">Not enough in stock:</Trans>
                          {' '}
                          ({notEnoughStock.length})
                        </span>
                        <i className="accordion-header-down vtmn-icon_chevron_down_v2"></i>
                        <i className="accordion-header-up vtmn-icon_chevron_up_v2"></i>
                      </button>
                    </h5>
                  </div>

                  <div id="notEnoughStock" className="collapse" aria-labelledby="notEnoughStockHeading" data-parent="#accordion">
                    <div className="card-body p-0">
                      <table id="notEnoughStockTable" className="table table-hover mb-0">
                      <thead>
                        <tr>
                          <th className="text-center">
                            <Trans i18nKey="layoutRequirements.itemCode">Item code</Trans>
                          </th>
                          <th className="text-center">
                            <Trans i18nKey="layoutRequirements.quantity">Quantity</Trans>
                          </th>
                        </tr>
                      </thead>
                        <tbody>
                          {notEnoughStock.map(item => (
                            <tr key={item.item_id}>
                              <td className="to-center text-center">
                                {item.item_id}
                              </td>
                              <td className="to-center text-center">
                                {item.qty}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header p-0" id="pickingsCreatedHeading">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-light accordion-header-button collapsed"
                        data-toggle="collapse"
                        data-target="#pickingsCreated"
                        aria-expanded="true"
                        aria-controls="pickingsCreated"
                      >
                        <i className="accordion-header-icon text-success vtmn-icon_valid_rounded"></i>
                        <span className="accordion-header-text">
                          <Trans i18nKey="layoutRequirements.pickingLists">Picking lists:</Trans>
                          {' '}
                          ({pickingsCreated.length})
                        </span>
                        <i className="accordion-header-down vtmn-icon_chevron_down_v2"></i>
                        <i className="accordion-header-up vtmn-icon_chevron_up_v2"></i>
                      </button>
                    </h5>
                  </div>

                  <div id="pickingsCreated" className="collapse" aria-labelledby="pickingsCreatedHeading" data-parent="#accordion">
                    <div className="card-body p-0">
                      <table id="pickingsCreatedTable" className="table table-hover mb-0">
                      <thead>
                        <tr>
                          <th className="text-center">
                            <Trans i18nKey="layoutRequirements.itemCode">Item code</Trans>
                          </th>
                          <th className="text-center">
                            <Trans i18nKey="layoutRequirements.quantity">Quantity</Trans>
                          </th>
                        </tr>
                      </thead>
                        <tbody>
                          {pickingsCreated.map(item => (
                            <tr key={item.item_id}>
                              <td className="to-center text-center">
                                {item.item_id}
                              </td>
                              <td className="to-center text-center">
                                {item.qty}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn" data-dismiss="modal">
                {' '}
                <Trans i18nKey="shared.ok">OK</Trans>
                {' '}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default ResupplyResultDialog;
