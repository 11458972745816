import { useCallback } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

const initialState = {
  activeTab: 'toReceive',  // toReceive, addressed, missing, unexpected
};

export const trackingNumberSlice = createSlice({
  name: 'trackingNumber',
  initialState,
  reducers: {
    setActiveTabAction: (state, action) => {
      state.activeTab = action.payload;
    },
  },
});

export const { setActiveTabAction } = trackingNumberSlice.actions;

export const useTrackingNumberReduxState = () => {
  const activeTab = useSelector((state) => state.trackingNumber.activeTab);
  const dispatch = useDispatch();
  const setActiveTab = useCallback((x) => dispatch(setActiveTabAction(x)), [dispatch]);

  return {
    activeTab,
    setActiveTab,
  };
}

export default trackingNumberSlice.reducer;
