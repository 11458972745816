import React, { useRef, useState, useEffect } from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import ZbarScanner from './scanner/ZbarScanner';
import ScannerButton from './scanner/ScannerButton';
import { useUpdatableDebounce } from '../../functions/useDebounce';
import useRequest from '../../functions/useRequest';
import { handleApiResponse } from '../../functions/handleApiResponse';
import fetchWithJWT from '../../functions/fetchWithJWT';
import { logBarcodeReadEvent } from '../../functions/analytics';
import '../../stylesheets/itemScannerInput.css';

const getItemFromEan = (t, user, updateTokens) => async (ean) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/items/ean/${ean}`;
  const response = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  if (response.code === '200') {
    return response.text;
  } else {
    throw new Error(t('items.unknownEan', 'unknown EAN'));
  }
};

const encodeEpc = (user, updateTokens) => async (sgtin) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_SERVERURL}/v1/items/encode_epc/${sgtin}`;
  const { epc } =  await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
  return epc;
};

const ItemScannerInput = ({
  user,
  updateTokens,
  value,
  onChange,
  placeholder,
  isValid,
  isInvalid,
  invalidMessage,
  disabled,
  setEpc,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [debouncedValue, setDebouncedValue] = useUpdatableDebounce(value, 500);
  const [{
    loading: loadingItemId,
  }, fetchItemFromEan] = useRequest(getItemFromEan(t, user, updateTokens), {
    onSuccess: (val) => {
      logBarcodeReadEvent({ success: true, readingType: 'item', value: debouncedValue });
      return onChange(val);
    },
    onError: (e) => {
      logBarcodeReadEvent({ success: false, readingType: 'item', value: debouncedValue });
      return setError(e);
    },
  });
  const [{
    loading: loadingEpc,
  }, doEncodeEpc] = useRequest(encodeEpc(user, updateTokens), {
    onSuccess: setEpc,
    onError: setError,
  });
  const loading = loadingItemId || loadingEpc;
  const scannerRef = useRef();
  useEffect(()=> {
    if (isInvalid && setEpc) {
      setEpc(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvalid]);
  useEffect(
    () => {
      const isEan = debouncedValue && debouncedValue.length === 13;
      const isSgtin = debouncedValue && debouncedValue.length === 30;
      if (isEan) {
        fetchItemFromEan(debouncedValue);
      } else if (isSgtin) {
        const ean = debouncedValue.substr(3, 13);
        doEncodeEpc(debouncedValue).then(() => fetchItemFromEan(ean));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedValue],
  );

  return (
    <InputGroup className="item-scanner-input">
      <Form.Control
        isValid={isValid}
        isInvalid={!loading && isInvalid}
        disabled={loading || disabled}
        placeholder={placeholder}
        type="text"
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
          setError(null);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.repeat) {
            setTimeout(() => {
              const isEan = value && value.length === 13;
              const isSgtin = value && value.length === 30;
              if (isEan) {
                fetchItemFromEan(value);
              } else if (isSgtin) {
                const ean = value.substr(3, 13);
                doEncodeEpc(value).then(() => fetchItemFromEan(ean));
              }
            } , 100);
          }
        }}
      />
      {loading && (
        <InputGroup.Append className="item-scanner-input-loader">
          <InputGroup.Text>
            <ClipLoader color="#ccc" size={15} loading />
          </InputGroup.Text>
        </InputGroup.Append>
      )}
      <InputGroup.Append>
        <ScannerButton scannerRef={scannerRef} disabled={loading || disabled} />
      </InputGroup.Append>
      {!loading && error && (
        <Form.Control.Feedback type="invalid">
          {error.message}
        </Form.Control.Feedback>
      )}
      {!loading && !error && isInvalid && (
        <Form.Control.Feedback type="invalid">
          {invalidMessage}
        </Form.Control.Feedback>
      )}
      <ZbarScanner
        readingType="item"
        ref={scannerRef}
        onDetected={(val) => {
          onChange(val);
          setDebouncedValue(val);
        }}
      />
    </InputGroup>
  );
};

export default ItemScannerInput;
