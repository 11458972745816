import React, { useState } from 'react';
import { Spinner, Form, Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import useRequest from '../../functions/useRequest';
import { useUserReduxState } from '../../store/user';

import ReleaseBaseForm from './ReleaseBaseForm';

import { editReleaseQuery } from './api';

const ReleaseEditDialog = (props) => {
  const { show, onDone, onHide } = props;
  const { t } = useTranslation();
  const { user, updateTokens } = useUserReduxState();

  const { id, type, summary, version, version_code, has_breaking_changes } = props.release;

  const [release, setRelease] = useState({ type, version, version_code, summary, has_breaking_changes });
  const [isChanged, setIsChanged] = useState(false);

  const [{ loading, error }, editRelease] = useRequest(editReleaseQuery, {
    onSuccess: onDone,
  });

  const handleReleaseChange = (v) => {
    setRelease(v);
    setIsChanged(true);
  };

  const onSaveClick = () => editRelease({ id, release, user, updateTokens });

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="release-view-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('releases.editRelease', 'Edit release')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {!loading && error && (
          <Form.Control.Feedback type="invalid">
            {(error && error.message) || t('shared.error', 'Error')}
          </Form.Control.Feedback>
        )}
        <ReleaseBaseForm release={release} disabled={loading} onChange={handleReleaseChange} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
        >
          {t('shared.cancel', 'Cancel')}
        </Button>
        <Button
          variant="success"
          disabled={!isChanged || loading}
          onClick={onSaveClick}
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                className="mr-2"
              />
              {t('shared.saving', 'Saving')}
            </>
          ) : t('shared.save', 'Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReleaseEditDialog;
