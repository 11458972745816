import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import ScannerInput from '../../components/widgets/ScannerInput';

const ScanUnexpectedModal = ({ show, onClose, onConfirm }) => {
  const [value, setValue] = useState('');
  useEffect(() => {
    setValue('');
  }, [show])
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <Trans i18nKey="trackingNumber.unexpectedItem">Unexpected item</Trans>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body >
        <ScannerInput
          value={value}
          onChange={setValue}
          onSubmit={(val) => onConfirm(val)}
          placeholder={t('reviewProblems.inputItemCode', 'Scan item...')}
          readingType="item"
          zbar
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onClose}>
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        <Button variant="primary" disabled={!value} onClick={() => onConfirm(value)}>
          <Trans i18nKey="shared.ok">OK</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ScanUnexpectedModal;
