import React, { useEffect, useState, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Modal, Button, InputGroup, Form } from 'react-bootstrap';
import dateFormat from 'dateformat';
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/widgets/errorMessage';
import useRequest from '../../functions/useRequest';
import '../../stylesheets/comments.css';

const Comment = ({ comment }) => {
  return (
    <div className="comment">
      <div className="comment-email">{comment.email}</div>
      <div className="comment-text">{comment.text}</div>
      <div className="comment-date">
        {comment.create_date ? dateFormat(new Date(comment.create_date), "dd/mm/yyyy HH:MM:ss") : null}
      </div>
    </div>
  );
};

const Comments = ({ comments }) => {
  if (!comments || !comments.length) {
    return (
      <div className="no-comment">
        <Trans i18nKey="comments.noComments">No comments yet</Trans>
      </div>
    )
  }
  return comments.map(comment => <Comment key={comment.id} comment={comment}/>)
};

const CommentsDialog = ({ isOpen, onClose, getComments, postComment }) => {
  const { t } = useTranslation();
  const bodyRef = useRef(null);
  const [text, setText] = useState('');
  const [comments, setComments] = useState([]);
  const [{
    loading: commentsLoading,
    error: commentsError,
  }, fetchComments] = useRequest(getComments, {
    onSuccess: setComments,
  });
  const [{
    loading: sending,
    error: sendError,
  }, addComment] = useRequest(postComment, {
    onSuccess: (comment) => {
      setComments([...comments, comment]);
      setText('');
      if (bodyRef && bodyRef.current) {
        bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
      }
    },
  });
  useEffect(() => {
    if (isOpen) {
      setText('');
      fetchComments();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      className="comment-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="comments.comments">Comments</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={bodyRef}>
        {commentsLoading && (
          <div className="align-self-center">
            <Loader/>
          </div>
        )}
        {!commentsLoading && commentsError && <ErrorMessage error={commentsError}/>}
        {!commentsLoading && !commentsError && <Comments comments={comments}/>}
      </Modal.Body>
      <Modal.Footer>
        <InputGroup>
          <Form.Control
            disabled={sending}
            className="mr-0"
            type="text"
            value={text}
            onChange={(event) => setText(event.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.repeat && !e.ctrlKey && !e.shiftKey) {
                setTimeout(() => {
                  if (text) {
                    addComment({ text })
                  }
                }, 100);
              }
            }}
          />
          {!sending && sendError && (
            <Form.Control.Feedback type="invalid">
              {(sendError && sendError.message) || t('shared.error', 'Error')}
            </Form.Control.Feedback>
          )}
          <InputGroup.Append>
            <Button
              disabled={sending}
              className="ml-0"
              variant="primary"
              onClick={() => {
                if (text) {
                  addComment({ text })
                }
              }}
            >
              <Trans i18nKey="comments.send">Send</Trans>
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default CommentsDialog;
