import React, { Component } from 'react';
import _ from 'lodash';
import { withTranslation, Trans } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import WithSidebar from './WithSidebar';
import PickingListAssignModal from './pickingListAssignModal';
import Loader from '../components/Loader';
import ImageModal from '../components/widgets/ImageModal';
import AlleyFilter from '../components/widgets/AlleyFilter';
import Papa from 'papaparse';
import fileDownload from 'js-file-download';
import fetchWithJWT from '../functions/fetchWithJWT';
import { handleApiResponse } from '../functions/handleApiResponse';
import ResupplyResultDialog from '../components/ResupplyResultDialog';
import dayjs from 'dayjs';

const serverUrl = process.env.REACT_APP_SERVERURL;

const IMPORT_SPREADSHEET_ID = '1g45xQRT2AxsdDLkhkMfWXfcOudh4crPw0wj-Z45EV3A';

class PickingList extends Component {
  constructor(props){
    super(props);
    this.state = {
      error: null,
      confirmMode: null,
      selectionStartIndex: null,
      pickingListSelected: [],
      pickingList : [],
      pickingListFilteredByEmail : [],
      loading : true,
      itemsLoading: {},
      emails : [],
      defaultEmail : this.props.user.email,
      reserveIsPickersOnly: false,
      iAmPicker: false,
      iAmViewer: false,
      lastResupplyEmail: null,
      lastResupplyDate: null,
      imageModalData: {
        pixlId: null,
        item: null,
        item_description: null,
      },
      letter: "all",
      userClickedOnMassImport: false,
      isImporting: false,
      importResults: {},
    };
    this.selectAllRef = React.createRef();
    this.modalErrorRef = React.createRef();
    this.modalConfirmRef = React.createRef();
    this.modalImportResultsRef = React.createRef();
  }

  // ------------------------
  // retrieve my picking list
  // ------------------------
  componentDidMount(){
    this.reload();
    window.addEventListener('storage', this.onImportAsked);
  }

  componentWillUnmount(){
    window.removeEventListener('storage', this.onImportAsked);
  }

  openMassImport = () => {
    this.setState({
      userClickedOnMassImport: true
    });
    window.open(`https://docs.google.com/spreadsheets/d/${IMPORT_SPREADSHEET_ID}`);
  }

  importOAuth = () => {
    const baseUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    const client_id = process.env.REACT_APP_spreadsheet_clientId;
    const redirect_uri = `${process.env.REACT_APP_fedID_redirect_URI}googleOAuth`;
    const scope = 'https://www.googleapis.com/auth/spreadsheets';
    window.open(`${baseUrl}?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=token&scope=${scope}`, "_blank")
  }

  onImportAsked = (event) => {
    if (event.key === 'credentials' && event.newValue !== null) {
      const credentials = JSON.parse(event.newValue);
      this.massImport(credentials.token);
    }
  }

  parseImportSpreadsheet = async (token) => {
    const sheetsRes = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${IMPORT_SPREADSHEET_ID}`, {
      headers:{
        Authorization: "Bearer " + token
      }
    }).then(result => result.json());
    const { sheets } = sheetsRes;
    const rowCount = sheets.find(sheet => sheet.properties.title === 'data').properties.gridProperties.rowCount;
    const valuesRes = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${IMPORT_SPREADSHEET_ID}/values/A2:B${rowCount}`, {
      headers:{
        Authorization: "Bearer " + token
      }
    }).then(result => result.json());

    const toImport  = [];
    for (const row of valuesRes.values) {
      if (row[0] && row[1]) {
        const item_id = String(row[0]).trim();
        const qty = Number(row[1]);
        if (item_id && qty) {
          toImport.push({
            item_id,
            qty,
          });
        }
      }
    }
    const groupedByItem = _.groupBy(toImport, 'item_id');
    const summedByItem = [];
    for (const [item_id, vals] of Object.entries(groupedByItem)) {
      summedByItem.push({
        item_id,
        qty: _.sumBy(vals, 'qty'),
      });
    }

    return summedByItem;
  }

  saveImportData = async (data) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const res = await fetchWithJWT(`${serverUrl}/v1/${this.props.match.params.store}/picking_lists/import`, {
      method: 'POST',
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      body: JSON.stringify(data),
    })
    .then(handleApiResponse);
    return res || {};
  }

  massImport = async (token) => {
    const { t } = this.props;
    this.setState({ isImporting: true });
    try {
      const data = await this.parseImportSpreadsheet(token);
      if (!data.length) {
        throw new Error(t('pickingList.noData', 'No data to import'));
      }
      const importResults = await this.saveImportData(data);
      this.setState({ isImporting: false, userClickedOnMassImport: false, importResults });
      this.showResults();
    } catch (error) {
      this.showError(error.message || t('shared.defaultError', 'Error occured'));
      this.setState({ isImporting: false });
    }
  }

  showResults = () => {
    window.$(this.modalImportResultsRef.current).modal('show');
  }

  onItemLink = () => {
    window.$(this.modalImportResultsRef.current).modal('hide');
  }

  reload = () => {
    const reserve_id = localStorage.getItem("reserve_id");
    Promise.all([this.checkReserveIsPickersOnly(reserve_id), this.checkIfIAmPicker(this.props.user.email, reserve_id)]);
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/${this.props.match.params.store}/picking_lists`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(response => response.json())
    .then(response => {
      // at the beginning, there is no error for each line
      response.map(element => element.error = "");
      return response;
    })
    .then(result => {
      // to get distincts emails from result array.
      const emailSet = new Set(result.map(item => item.email));
      if (this.props.user.email) {
        emailSet.add(this.props.user.email)
      }

      this.setState({
        error: null,
        confirmMode: null,
        selectionStartIndex: null,
        pickingListSelected: [],
        pickingList : result,
        pickingListFilteredByEmail : result,
        loading : false,
        emails : [...emailSet],
      });
      this.filterListByEmail(this.state.defaultEmail);
      if (this.selectAllRef.current) {
        this.selectAllRef.current.indeterminate = false;
      }
    })
    .catch(error => {
        console.warn(error);
    });
  }

  checkReserveIsPickersOnly = (reserve_id) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/stores/${reserve_id}`,{
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(result => result.json())
    .then(result => {
      this.setState({
        reserveIsPickersOnly: result.text.view_only_by_default,
        lastResupplyEmail: result.text.last_resupply_email,
        lastResupplyDate: result.text.last_resupply_date && new Date(result.text.last_resupply_date),
        iAmViewer: result.text.viewer_right,
      })
    } )
    .catch(error => {
      console.warn(`Error while checking if reserve ${reserve_id} is pickers only : ${error}`);
    })
  }

  checkIfIAmPicker = (email, reserve_id) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/pickers/${reserve_id}`,{
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(result => result.json())
    .then(result => {
      this.setState({
        iAmPicker: result.pickers.map(picker => picker.email).includes(email)
      })
    })
    .catch(error => {
      console.warn(`Error while checking if ${email} is picker of reserve ${reserve_id} : ${error}`);
    })
  }

  onShiftClickCheckbox = (e, index) => {
    const shiftKey = e.shiftKey;
    const { selectionStartIndex } = this.state;
    const isShifSelecting = selectionStartIndex !== null && shiftKey;
    if (!isShifSelecting) {
      return;
    }
    // processing shift+click selection
    const { pickingListSelected, pickingListFilteredByEmail } = this.state;
    const from = selectionStartIndex < index ? selectionStartIndex : index;
    const to = selectionStartIndex > index ? selectionStartIndex : index;
    const selection = (pickingListFilteredByEmail && pickingListFilteredByEmail.slice(from, to + 1)) || [];
    const selectionPickingListIds = selection.map(x => x.id_picking_list);
    const nextPickingListSelected = [...new Set([
      ...pickingListSelected,
      ...selectionPickingListIds,
    ])];
    this.setState({
      pickingListSelected: nextPickingListSelected,
      selectionStartIndex: null,
    });

    const selectAllIndeterminate = (
      nextPickingListSelected.length > 0
      && nextPickingListSelected.length !== pickingListFilteredByEmail.length
    );
    this.selectAllRef.current.indeterminate = selectAllIndeterminate;
  }

  onToggleCheckbox = (id_picking_list, index) => {
    const { pickingListSelected, pickingListFilteredByEmail } = this.state;
    const checkedIndex = pickingListSelected.indexOf(id_picking_list);
    const wasChecked = checkedIndex !== -1;
    let nextSelectionStartIndex = null;
    let nextPickingListSelected;

    if (!wasChecked) {
      // checking
      // remember starting point for shift+click selection
      nextSelectionStartIndex = index;
      nextPickingListSelected = [
        ...pickingListSelected,
        id_picking_list,
      ];
    } else {
      // unckecking
      nextPickingListSelected = pickingListSelected.filter(x => x !== id_picking_list);
    }
    this.setState({
      pickingListSelected: nextPickingListSelected,
      selectionStartIndex: nextSelectionStartIndex,
    });

    const selectAllIndeterminate = (
      nextPickingListSelected.length > 0
      && nextPickingListSelected.length !== pickingListFilteredByEmail.length
    );
    this.selectAllRef.current.indeterminate = selectAllIndeterminate;
  }

  onToggleSelectAll = () => {
    const { pickingListSelected, pickingListFilteredByEmail } = this.state;
    if (pickingListSelected.length === 0) {
      this.setState({
        pickingListSelected: pickingListFilteredByEmail.map(x => x.id_picking_list),
        selectionStartIndex: null,
      });
    } else {
      this.setState({
        pickingListSelected: [],
        selectionStartIndex: null,
      });
    }
    this.selectAllRef.current.indeterminate = false;
  }

  // -------------------------------
  // insert a row in my picking list
  // -------------------------------
  displayRowActions = (product, canPick) => {
    const changing = !!this.state.itemsLoading[product.id_picking_list];
    const invalid = (product.error !== "") || (product.qty_stock < product.qty) || !product.qty || Number.isNaN(Number(product.qty));
    return (
      <>
        <div className="col-2 d-none d-md-block">
          <div className="row align-items-center">
            <input
              type="text"
              className={`form-control form-control-sm inputPickingList ${invalid ? 'is-invalid' : ''}`}
              value={product.qty}
              onChange={event => this.handleChange(event, product)}
            />
            {changing && (
              <div className="ml-1">
                <ClipLoader color="#ccc" size={15} loading />
              </div>
            )}
          </div>
        </div>
        <div className="col-3 d-none d-md-block">
          {canPick ? (
            <div className="row">
              <button className="btn btn-success btn-sm btn-block"
                disabled = {invalid || changing}
                onClick={event => this.pickItem(product.id_picking_list, product.item_code, product.qty, product.qty_stock, product.address)}
              >
                <Trans i18nKey="shared.pick">Pick</Trans>
              </button>
            </div>
          ) : null}
          <div className="row">
            <button className="btn btn-sm btn-block btn-danger" disabled={changing} onClick={event => this.deletePickingRow(product.id_picking_list)}>
              <i className="vtmn-icon_delete vtmn-icon-22px"></i>
            </button>
          </div>
        </div>
      </>
    );
  }

  displayRowActionsMobile = (product, canPick) => (
    <div className="row d-flex flex-nowrap d-md-none">
      <input type="text" className="form-control form-control-sm inputPickingList" value={ product.qty } onChange={event => this.handleChange(event, product)} />
      <div className="col" />
      {canPick ? (
        <button className="btn btn-success btn-sm w-25"
          disabled = {(product.error !== "") || (product.qty_stock < product.qty)}
          onClick={event => this.pickItem(product.id_picking_list, product.item_code, product.qty, product.qty_stock, product.address)}
        >
          <Trans i18nKey="shared.pick">Pick</Trans>
        </button>
      ) : null}
      <button className="btn btn-sm btn-danger w-25" onClick={event => this.deletePickingRow(product.id_picking_list)}>
        <i className="vtmn-icon_delete vtmn-icon-22px"></i>
      </button>
    </div>
  );

  displayRow(product, index) {
    const { pickingListSelected } = this.state;
    const checked = pickingListSelected.indexOf(product.id_picking_list) !== -1;
    const canPick = (!this.state.reserveIsPickersOnly || this.state.iAmPicker || this.state.iAmViewer) && !product.address_disabled;
    return (
      <div className="border-bottom" key={product.id_picking_list}>
        <div className="row pb-3 pt-3 flex-nowrap align-items-center">
          <div className="col-1">
            <div className="row p-3 h-100 justify-content-center align-items-center">
              <div
                className="custom-control form-control-lg custom-checkbox"
                onClick={(e) => this.onShiftClickCheckbox(e, index)}
              >
                <input
                  type="checkbox" className="custom-control-input" id={`check-${product.id_picking_list}`}
                  checked={checked} onChange={(e) => this.onToggleCheckbox(product.id_picking_list, index)}
                />
                <label className="custom-control-label" htmlFor={`check-${product.id_picking_list}`}> </label>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-11">
            <div className="row flex-nowrap align-items-center">
              {this.displayImage(product)}
              <div className="col">
                <div className="row">
                  <span className="text-muted">{ product.item_code } - { product.item_description }</span>
                </div>
                <div className="row">
                  <span className="text-muted">{ product.address }</span>
                </div>
                <div className="row">
                  <span className="text-info small"><em>{ product.email }</em></span>
                </div>
                {product.comment && (
                  <div className="row">
                    <span className="text-muted small" style={{ whiteSpace: 'pre-wrap' }}>
                      <em>{product.comment}</em>
                    </span>
                  </div>
                )}
                {this.displayWarningNotEnoughStock(product)}
                {this.displayStockProblem(product)}
                { product.error !== "" ?
                  <div className="row">
                    <span className="text-danger small"><em>{product.error}</em></span>
                  </div> : null
                }
                { product.address_disabled ?
                  <div className="row">
                    <span className="text-danger small">
                      <em>
                        <Trans i18nKey="pickingList.addressDisabled">Address is disabled</Trans>
                      </em>
                    </span>
                  </div> : null
                }
              </div>
            </div>
            {this.displayRowActionsMobile(product, canPick)}
          </div>
          {this.displayRowActions(product, canPick)}
        </div>
      </div>
    );
  }

  displayImage = (product) => (
    <div style={{ width: '60px', height: '60px' }}>
      {product.pixlId
        ? (
          <img
            key={product.pixlId}
            src={`https://contents.mediadecathlon.com/p${product.pixlId}/a.jpg?f=60x60`}
            alt=""
            style={{ cursor: 'pointer' }}
            onClick={() => {
              this.setState({
                imageModalData: {
                  pixlId: product.pixlId,
                  item: product.item_code,
                  item_description: product.item_description,
                }
              })
              window.$("#imageModal").modal('show');
            }}
          />
        )
        : null
      }
    </div>
  );

  displayStockProblem = (product) => {
    const { t } = this.props;
    if (product.stock_problem) {
      return (
        <div className="row" id="stockAlertPickingListPage">
          <span className="text-danger small">
            <em>
              {t('pickingList.moreThenStoresCom', {
                defaultValue: 'There is more stock in Product Locator ({{qty}}) than in stores.com ({{qtyInStock}}).',
                qty: product.stock_problem.in_reserve,
                qtyInStock: product.stock_problem.stores_com_stock,
              })}
            </em>
          </span>
        </div>
      );
    }
  }

  displayWarningNotEnoughStock = (product) => {
    const { t } = this.props;
    if ((product.qty > product.qty_stock) && (product.error === "")){
      return (
        <div className="row">
          <span className="text-danger small">
            <em>
              {t('pickingList.insufficientStock', {
                defaultValue: 'Insufficient stock. Only {{count}} quantity in this address',
                count: product.qty_stock,
              })}
            </em>
          </span>
        </div>
      )
    }
  }

  // ----------------------
  // modify the qty to pick
  // ----------------------
  handleChange = (event, product) => {
    // read all the pickingList to change the state.
    const { t } = this.props;
    const newPickingList = this.state.pickingListFilteredByEmail.map(productInState => {
      if (productInState.id_picking_list === product.id_picking_list) {
        productInState.qty = event.target.value;
        if (productInState.qty > product.qty_stock){
          product.error = t('pickingList.insufficientStock', {
            defaultValue: 'Insufficient stock. Only {{count}} quantity in this address',
            count: product.qty_stock,
          });
        } else {
          product.error = "";
        }
      }
      return productInState;
    });
    this.setState({pickingListFilteredByEmail : newPickingList});

    // update the qty in the pickinglist if the stock is sufficient
    if (product.qty_stock >= event.target.value && event.target.value > 0){

      this.setState (({ itemsLoading }) => ({ itemsLoading: { ...itemsLoading, [product.id_picking_list]: true } }));
      const { user, updateTokens } = this.props;
      const { token, refreshToken, tokenExpireDate } = user;

      return fetchWithJWT(`${serverUrl}/v1/${this.props.match.params.store}/picking_lists/${product.id_picking_list}`,{
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        },
        method: "PATCH",
        body: JSON.stringify({ "qty" : event.target.value })
      })
      .then(response => response.json())
      .then(result => {
        this.setState(({ itemsLoading }) => ({ itemsLoading: { ...itemsLoading, [product.id_picking_list]: false } }));
      })
      .catch(error => {
        console.warn(error);
        this.setState(({ itemsLoading }) => ({ itemsLoading: { ...itemsLoading, [product.id_picking_list]: false } }));
        this.showError(error.message || t('shared.defaultError', 'Error occured'));
      });
    }
  }

  // ---------------------------------
  // delete a row from my picking list
  // ---------------------------------
  deletePickingRow(id_picking_list){
    const { t } = this.props;
    this.setState ({
      loading: true
    });
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;

    return fetchWithJWT(`${serverUrl}/v1/${this.props.match.params.store}/picking_lists/${id_picking_list}`,{
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      method: "DELETE"
    })
    .then(response => response.json())
    .then(result => {
      if (result.code === "200") {
        this.reload();
      } else {
        this.showError(result.text || t('shared.defaultError', 'Error occured'));
      }
    })
    .catch(error => {
      console.warn(error);
    });
  }

  deletePickingRowMass = (pickingList_ids) => {
    const { t } = this.props;
    this.setState ({
      loading: true
    });
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;

    return fetchWithJWT(`${serverUrl}/v1/${this.props.match.params.store}/picking_lists/delete`,{
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      method: "POST",
      body: JSON.stringify({
        pickingList_ids: pickingList_ids,
      }),
    })
    .then(response => response.json())
    .then(result => {
      if (result.code === "200") {
        this.reload();
      } else {
        this.showError(result.text || t('shared.defaultError', 'Error occured'));
      }
    })
    .catch(error => {
      console.warn(error);
    });
  }

  // -------------------------------------------------------------
  // pick an Item from my pickingList, for a qty, and an addresses
  // -------------------------------------------------------------
  pickItem = (id_picking_list, item_id, qty, qty_stock, address) => {
    // pick an item if there is stock enough
    const { t } = this.props;
    if (qty_stock >= qty){
      this.setState ({
        loading : true
      });

      let myUser = localStorage.getItem("email");
      if (this.props.user.email !== null || this.props.user.email !== undefined){
        myUser = this.props.user.email;
      }
      const { user, updateTokens } = this.props;
      const { token, refreshToken, tokenExpireDate } = user;

      // first fetch to pick an item with (x) qty from one address
      return fetchWithJWT(`${serverUrl}/v1/${this.props.match.params.store}/addresses/${address}`,{
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        },
        method: "PUT",
        body: JSON.stringify(
          {
            "store": this.props.match.params.store,
            "address" : address,
            "item_id" : item_id,
            "qty" : -1*qty,
            "user" : myUser
          }
        )
      })
      .then(response => response.json())
      .then(response => {
        if (response.code === "201") {
          // second fetch to remove the picking list line
          this.deletePickingRow(id_picking_list);
        } else {
          this.showError(response.text || t('shared.defaultError', 'Error occured'));
        }
      })
      .then(
        this.setState ({
          loading : false
        })
      )
      .catch(error => {
          console.warn(error);
      });

    }
  }

  showError = (error) => {
    window.$(this.modalErrorRef.current).modal('show');
    this.setState({ error });
  }

  showConfirm = (confirmMode) => {
    window.$(this.modalConfirmRef.current).modal('show');
    this.setState({ confirmMode });
  }

  pickItemsMass = () => {
    const { pickingListSelected, pickingListFilteredByEmail } = this.state;
    const selectedProducts = pickingListFilteredByEmail
      .filter(product => (
        pickingListSelected.indexOf(product.id_picking_list) !== -1
      ));
    const selectedPickingList_ids = selectedProducts.map(product => product.id_picking_list);
    const invalids = selectedProducts.filter(product => product.qty_stock < product.qty);
    if (invalids.length > 0) {
      return;
    }
    this.setState ({
      loading : true
    });
    let myUser = localStorage.getItem("email");
    if (this.props.user.email !== null || this.props.user.email !== undefined){
      myUser = this.props.user.email;
    }
    const { t, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;

    return fetchWithJWT(`${serverUrl}/v1/${this.props.match.params.store}/addresses/mass/assign`,{
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      method: "PUT",
      body: JSON.stringify(
        {
          "store": this.props.match.params.store,
          "user" : myUser,
          "stockList": selectedProducts.map(product => ({
            "address" : product.address,
            "item_id" : product.item_code,
            "qty" : -1 * product.qty,
          })),
        }
      ),
    })
    .then(response => response.json())
    .then(response => {
      if (response.code === "201") {
        // second fetch to remove the picking list line
        return this.deletePickingRowMass(selectedPickingList_ids);
      } else {
        this.showError(response.text || t('shared.defaultError', 'Error occured'));
      }
    })
    .catch(error => {
      this.showError(t('shared.defaultError', 'Error occured'));
      console.log(error);
    });
  }

  // -------------------------------
  // filter the pickingList by email
  // -------------------------------
  filterListByEmail = (email) => {
    let newList = [];
    if (email === "all"){
      newList = this.state.pickingList;
    } else {
      newList = this.state.pickingList.filter( pickingList => pickingList.email === email );
    }

    this.setState({
      defaultEmail : email,
      pickingListFilteredByEmail : newList,
      pickingListSelected: [],
      selectionStartIndex: null,
    });
  }

  onEmailChange = (event) => {
    const { defaultEmail } = this.state;
    const nextEmail = event.target.value;
    if (nextEmail !== defaultEmail) {
      this.filterListByEmail(nextEmail);
    }
  }

  exportToCSV = () => {
    const export_date = dayjs();
    const export_dateUTC = export_date.format('DD-MM-YYYY HH:mm:ss [UTC]Z');

    const csvFormattedData = Papa.unparse(this.state.pickingListFilteredByEmail.map(element => {
      let picking_list_creation_date = '';
      if (element.created_date) {
        picking_list_creation_date = dayjs(element.created_date).format('DD-MM-YYYY HH:mm:ss [UTC]Z');
      }
      return {
        email: element.email,
        address: element.address,
        item_code: element.item_code,
        item_description: element.item_description,
        qty: element.qty,
        picking_list_creation_date,
        export_date: export_dateUTC
      }
    }));
    fileDownload(csvFormattedData, `picking list ${export_date.format('DD/MM/YYYY')}.csv`);
  }

  renderMassImportButton = () => {
    const { user } = this.props;
    if (!user.admin) {
      return null;
    }
    if (!this.state.userClickedOnMassImport) {
      return (
        <button className="icon-button m5Button btn btn-sm btn-primary" onClick={this.openMassImport}>
          <i className="vtmn-icon_document"></i>
          <Trans i18nKey="layoutRequirements.import">Import</Trans>
        </button>
      )
    } else {
      return (
        <button className="btn icon-button m5Button btn-sm btn-yellow" onClick={this.importOAuth} disabled={this.state.buttonValidateImportDisabled}>
          {this.state.isImporting ? (
            <span className="button-loader">
              <ClipLoader color="#fff" size={15} loading />
            </span>
          ) : <i className="vtmn-icon_document"></i>}
          <Trans i18nKey="layoutRequirements.validateImport">Validate Import</Trans>
        </button>
      )
    };
  }

  renderErrorDialog() {
    const { error } = this.state;
    return (
      <div
        key="error-modal" className="modal fade" id="modal-error" tabIndex="-1"
        role="dialog" aria-labelledby="modalErrorTitle" aria-hidden="true"
        ref={this.modalErrorRef}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalErrorTitle">
                <Trans i18nKey="shared.defaultErrorTitle">Error!</Trans>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close-modal-error">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {error}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn" data-dismiss="modal">
                {' '}
                <Trans i18nKey="shared.ok">OK</Trans>
                {' '}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderConfirmDialog() {
    const { t } = this.props;
    const { confirmMode, pickingListSelected } = this.state;
    const pickingListsCount = pickingListSelected.length;
    let title;
    let message;
    if (confirmMode === 'pick') {
      title = t('pickingList.confirmPick', 'Confirm pick');
      message = t('pickingList.askPickSelected', 'Pick selected lists?');
    } else if (confirmMode === 'delete') {
      title = t('pickingList.confirmDelete', 'Confirm delete');
      message = t('pickingList.askDeleteSelected', 'Delete selected lists?');
    }
    return (
      <div
        key="confirm-modal" className="modal fade" id="modal-confirm" tabIndex="-1"
        role="dialog" aria-labelledby="modalConfirmTitle" aria-hidden="true"
        ref={this.modalConfirmRef}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalConfirmTitle">
                {title}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close-modal-confirm">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>{message}</p>
              <p>
                {t('pickingList.cntPickingListSelected', {
                  defaultValue: '({{count}}) picking list selected',
                  count: pickingListsCount,
                })}
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn m5Button" data-dismiss="modal">
                {' '}
                <Trans i18nKey="shared.no">No</Trans>
                {' '}
              </button>
              <button
                type="button" className="btn blueButton" data-dismiss="modal"
                onClick={() => {
                  if (confirmMode === 'pick') {
                    this.pickItemsMass();
                  } else if (confirmMode === 'delete') {
                    this.deletePickingRowMass(pickingListSelected);
                  }
                }}
              >
                {' '}
                <Trans i18nKey="shared.yes">Yes</Trans>
                {' '}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render(){
    const { t } = this.props;
    const {
      pickingListSelected,
      pickingListFilteredByEmail,
      loading,
      letter,
    } = this.state;
    const notSelected = pickingListSelected.length === 0;
    const selectedProducts = pickingListFilteredByEmail.filter(product => (
      pickingListSelected.indexOf(product.id_picking_list) !== -1
    ));
    const selectedInsufficients = selectedProducts.filter(product => product.qty_stock < product.qty);
    const isInsufficientsselected = selectedInsufficients.length > 0;
    const allCheckedOrIntermidiate = pickingListSelected.length > 0;
    const canPick = !this.state.reserveIsPickersOnly || this.state.iAmPicker || this.state.iAmViewer;

    let pickingListFiltered = pickingListFilteredByEmail;
    if (letter && letter !== "all") {
      pickingListFiltered = pickingListFiltered.filter(product => product.address.substring(0, 1) === letter);
    }
    return (
      <WithSidebar user={this.props.user} updateTokens={this.props.updateTokens} logOut={this.props.logOut}>
        <div>
          <div className = "jumbotron container">
            <h2 className="text-center">
              <Trans i18nKey="pickingList.pickingList">Picking List</Trans>
            </h2>
            {this.state.loading
              ? (
                <Loader
                  loading
                  text={t('shared.loading', 'Loading')}
                />
              )
              : (
                <>
                  <div>
                    <div className="row">
                      <div className="col">
                        <h5 className="text-center">
                          <select
                            name="emails"
                            value={this.state.defaultEmail}
                            onChange={this.onEmailChange}>
                            <option key="all" value="all">
                              {t('pickingList.allUsers', 'All users')}
                            </option>
                            {this.state.emails.map((email) => {
                              return <option  key={email} value={email}>{email}</option>;
                            })}
                          </select>
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col text-center mb-1">
                        <AlleyFilter
                          addresses={this.state.pickingListFilteredByEmail}
                          selected={this.state.letter}
                          onFilter={(letter) => this.setState({ letter })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="border-bottom">
                    <div className="row">
                      <div className="col-1">
                        <div className="row pl-3 pr-3" style={{ justifyContent: 'center', height: '100%', alignItems: 'center'}}>
                          <div className="custom-control form-control-lg custom-checkbox">
                            <input
                              ref={this.selectAllRef}
                              type="checkbox" className="custom-control-input" id="check-all"
                              checked={allCheckedOrIntermidiate} onChange={this.onToggleSelectAll}
                            />
                            <label className="custom-control-label" htmlFor="check-all"> </label>
                          </div>
                        </div>
                      </div>
                      <div className="col pr-0">
                        <button className="btn blueButton btn-sm" onClick={this.exportToCSV}>
                          <Trans i18nKey="pickingList.exportCsv">Export to CSV</Trans>
                        </button>
                        <button
                          className="btn blueButton btn-sm" disabled={notSelected}
                          data-toggle="modal" data-target="#modal-assign-picking-lists"
                        >
                          <Trans i18nKey="pickingList.assignPickingLists">Assign picking lists</Trans>
                        </button>
                        {this.renderMassImportButton()}
                      </div>
                      <div className="col pl-0" style={{ textAlign: 'right' }}>
                        {canPick && (
                          <button
                            className="btn m5Button btn-success btn-sm"
                            disabled={notSelected || isInsufficientsselected || loading}
                            onClick={() => this.showConfirm('pick')}
                          >
                            <Trans i18nKey="pickingList.pickSelected">Pick selected</Trans>
                          </button>
                        )}
                        <button
                          className="btn m5Button btn-danger btn-sm"
                          disabled={notSelected || loading}
                          onClick={() => this.showConfirm('delete')}
                        >
                          <Trans i18nKey="pickingList.deleteSelected">Delete selected</Trans>
                        </button>
                      </div>
                    </div>
                  </div>
                  {pickingListFiltered.map((product, index) => this.displayRow(product, index))}
                </>
              )
            }
          </div>
        </div>
        <PickingListAssignModal
          pickingLists={this.state.pickingListSelected}
          store={this.props.match.params.store}
          user={this.props.user}
          updateTokens={this.props.updateTokens}
          onFinish={() => this.reload()}
        />
        {this.renderConfirmDialog()}
        {this.renderErrorDialog()}
        <ResupplyResultDialog
          resupplyResults={this.state.importResults}
          onItemLink={this.onItemLink}
          modalResultsRef={this.modalImportResultsRef}
          title={t('pickingList.importFinished','Import finished')}
        />
        <ImageModal
          pixlId={this.state.imageModalData.pixlId}
          item={this.state.imageModalData.item}
          item_description={this.state.imageModalData.item_description}
        />
      </WithSidebar>
    );
  }
}


export default withTranslation()(PickingList);
