import {
  useReducer,
  useCallback,
  useRef,
  useEffect,
} from 'react';

const REQUEST_START = 'REQUEST_START';
const REQUEST_DONE = 'REQUEST_DONE';
const REQUEST_ERROR = 'REQUEST_ERROR';
const SET_FILTERS = 'SET_FILTERS';
const SET_PAGINATION = 'SET_PAGINATION';
const SET_SORT = 'SET_SORT';
const UPDATE_DATA_ROW = 'UPDATE_DATA_ROW'
const INSERT_DATA_ROW = 'INSERT_DATA_ROW'
const DELETE_DATA_ROW = 'DELETE_DATA_ROW'

const defaultInitialState = {
  requestId: 0,
  loading: false,
  data: [],
  error: null,
  filters: {},
  sort: undefined,
  pagination: {
    page: 0,
    size: 20,
  },
  totals: {
    items: 0,
    pages: 0,
  },

};

let counter = 0;

const reducer = (state, action) => {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        data: [],
        filters: action.payload,
        pagination: {
          ...state.pagination,
          page: 0,
        },
        totals: {
          items: 0,
          pages: 0,
        },
      };
    case SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };
    case SET_SORT:
      return {
        ...state,
        sort: action.payload,
      };
    case UPDATE_DATA_ROW: {
      const {
        index,
        row,
      } = action.payload;
      const data = [...state.data];
      data[index] = {
        ...state.data[index],
        ...row,
      };
      return {
        ...state,
        data,
      };
    }
    case INSERT_DATA_ROW: {
      const {
        index,
        row,
      } = action.payload;
      const data = [...state.data];
      data.splice(index, 0, row);
      return {
        ...state,
        data,
      };
    }
    case DELETE_DATA_ROW: {
      const { index } = action.payload;
      const data = [...state.data];
      data.splice(index, 1);
      return {
        ...state,
        data,
      };
    }
    case REQUEST_START:
      return {
        ...state,
        data: [],
        loading: true,
        error: null,
        requestId: action.payload.requestId,
      };
    case REQUEST_DONE: {
      const { data, contentRange, requestId } = action.payload;
      if (requestId < state.requestId) {
        return state;
      }
      const { size } = state.pagination;
      const items = contentRange.max + 1;
      const pages = Math.max(1, Math.ceil(items / size));
      const totals = {
        items,
        pages,
      };
      return {
        ...state,
        loading: false,
        error: null,
        data,
        totals,
      };
    }
    case REQUEST_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      throw new Error('error');
  }
};

const tableRequest = async (
  fetcher,
  dispatch,
  isMountedRef,
  token,
  filters,
  pagination,
  sort,
  args,
) => {
  try {
    counter += 1;
    const requestId = counter;
    if (!isMountedRef.current) {
      return;
    }
    dispatch({ type: REQUEST_START, payload: { requestId } });
    const { result, contentRange } = await fetcher(token, { filters, pagination, sort }, ...args);
    if (!isMountedRef.current) {
      return;
    }
    dispatch({
      type: REQUEST_DONE,
      payload: {
        data: result,
        contentRange,
        requestId,
      },
    });
    return result;
  } catch (error) {
    if (!isMountedRef.current) {
      return;
    }
    dispatch({ type: REQUEST_ERROR, error });
    return;
  }
};

const setFilters = (dispatch) => (filters) => dispatch({
  type: SET_FILTERS,
  payload: filters,
});

const setPagination = (dispatch) => (pagination) => dispatch({
  type: SET_PAGINATION,
  payload: pagination,
});

const setSort = (dispatch) => (sort) => dispatch({
  type: SET_SORT,
  payload: sort,
});

const updateDataRow = (dispatch) => (index, row) => dispatch({
  type: UPDATE_DATA_ROW,
  payload: {
    index,
    row,
  },
});

const insertDataRow = (dispatch) => (index, row) => dispatch({
  type: INSERT_DATA_ROW,
  payload: {
    index,
    row,
  },
});

const deleteDataRow = (dispatch) => (index) => dispatch({
  type: DELETE_DATA_ROW,
  payload: {
    index,
  },
});

export const useTableRequest = (
  fetcher,
  {
    initialState,
  } = {},
) => {
  const initialIState = {
    ...defaultInitialState,
    ...initialState,
  };
  const isMountedRef = useRef(true);
  useEffect(() => () => {
    isMountedRef.current = false;
  }, []);
  const [state, dispatch] = useReducer(reducer, initialIState);

  const requestCallback = (
    token,
    { filters, pagination, sort } = {},
    ...args
  ) => tableRequest(
    fetcher,
    dispatch,
    isMountedRef,
    token,
    filters,
    pagination,
    sort,
    args,
  );
  const memoizedRequestCallback = useCallback(requestCallback, [fetcher]);
  /* eslint-disable react-hooks/exhaustive-deps */
  const memoizedSetFilters = useCallback(setFilters(dispatch), []);
  const memoizedSetPagination = useCallback(setPagination(dispatch), []);
  const memoizedSetSort = useCallback(setSort(dispatch), []);
  const memoizedUpdateDataRow = useCallback(updateDataRow(dispatch), []);
  const memoizedInsertDataRow = useCallback(insertDataRow(dispatch), []);
  const memoizedDeleteDataRow = useCallback(deleteDataRow(dispatch), []);
  /* eslint-enable react-hooks/exhaustive-deps */
  return [
    state,
    memoizedRequestCallback,
    memoizedSetFilters,
    memoizedSetPagination,
    memoizedSetSort,
    memoizedUpdateDataRow,
    memoizedInsertDataRow,
    memoizedDeleteDataRow,
  ];
};

export default useTableRequest;
