import { useCallback } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { ECOM_ORDER_STATUSES, IN_PROGRESS_STATUS } from './constants';

const EMPTY_FILTERS = {
  count_from: null,
  count_to: null,
  order_date_from: null,
  order_date_to: null,
  states: [],
  teammate: null,
  shipping_methods: [],
  customer_name: null,
  alley: null,
  sport: null,
  voluminous: null,
  recommended_box: null,
};

const DEFAULT_FILTERS = {
  ...EMPTY_FILTERS,
  states: ECOM_ORDER_STATUSES.filter((x) => x !== IN_PROGRESS_STATUS),
};

const INITIAL_STATE = {
  version: '1.0.1',
  filters: DEFAULT_FILTERS,
  sort: {
    field: 'status',
    direction: 'DESC',
  },
  showImages: true,
};

const getInitialState = () => {
  try {
    if (localStorage.ecomOrders) {
      const state = JSON.parse(localStorage.ecomOrders);
      if (state.filters.order_date_from) {
        state.filters.order_date_from = new Date(state.filters.order_date_from);
      }
      if (state.filters.order_date_to) {
        state.filters.order_date_to = new Date(state.filters.order_date_to);
      }
      if (state.version === INITIAL_STATE.version) {
        return state;
      }
    }
  } catch (e) {}
  return INITIAL_STATE;
};

export const ecomOrdersSlice = createSlice({
  name: 'ecomOrders',
  initialState: getInitialState(),
  reducers: {
    setSortAction: (state, action) => {
      state.sort = action.payload;
      localStorage.ecomOrders = JSON.stringify(state);
    },
    setFiltersAction: (state, action) => {
      state.filters = action.payload;
      localStorage.ecomOrders = JSON.stringify(state);
    },
    setShowImagesAction: (state, action) => {
      state.showImages = action.payload;
      localStorage.ecomOrders = JSON.stringify(state);
    },
    resetFiltersAction: (state) => {
      state.filters = DEFAULT_FILTERS;
      localStorage.ecomOrders = JSON.stringify(state);
    },
    clearFiltersAction: (state) => {
      state.filters = EMPTY_FILTERS;
      localStorage.ecomOrders = JSON.stringify(state);
    },
    resetAction: () => {
      localStorage.removeItem("ecomOrders");
      return INITIAL_STATE;
    },
  },
});

export const {
  setSortAction,
  setFiltersAction,
  setShowImagesAction,
  resetFiltersAction,
  clearFiltersAction,
  resetAction,
} = ecomOrdersSlice.actions;

export const useEcomOrdersState = () => {
  const filters = useSelector((state) => state.ecomOrders.filters);
  const hasFilters = !_.isEqual(EMPTY_FILTERS, filters);
  const sort = useSelector((state) => state.ecomOrders.sort);
  const showImages = useSelector((state) => state.ecomOrders.showImages);
  const dispatch = useDispatch();
  const setFilters = useCallback((x) => dispatch(setFiltersAction(x)), [dispatch]);
  const setSort = useCallback((x) => dispatch(setSortAction(x)), [dispatch]);
  const setShowImages = useCallback((x) => dispatch(setShowImagesAction(x)), [dispatch]);
  const resetFilters = useCallback(() => dispatch(resetFiltersAction()), [dispatch]);
  const clearFilters = useCallback(() => dispatch(clearFiltersAction()), [dispatch]);
  const reset = useCallback(() => dispatch(resetAction()), [dispatch]);


  return {
    filters,
    hasFilters,
    sort,
    showImages,
    setFilters,
    setSort,
    setShowImages,
    resetFilters,
    clearFilters,
    reset,
  };
}

export default ecomOrdersSlice.reducer;
