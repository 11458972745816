import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import useRequest from '../../functions/useRequest';
import useDebounce from '../../functions/useDebounce';
import { queryOrdersByCustomer } from './api';

const SearchByCustomer = ({ onSelectOrder, user, updateTokens }) => {
  const { t } = useTranslation();
  const { store } = useParams();
  const [{
    loading,
    error,
    data: options,
  }, fetchQueryOrdersByCustomer] = useRequest(queryOrdersByCustomer);
  const [firstNameValue, setFirstNameValue] = useState('');
  const [lastNameValue, setLastNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [mobileValue, setMobileValue] = useState('');
  const debouncedFirstName = useDebounce(firstNameValue, 500);
  const debouncedLastName = useDebounce(lastNameValue, 500);
  const debouncedEmail = useDebounce(emailValue, 500);
  const debouncedMobile = useDebounce(mobileValue, 500);
  useEffect(() => {
    fetchQueryOrdersByCustomer({
      store,
      email: debouncedEmail,
      firstName: debouncedFirstName,
      lastName: debouncedLastName,
      mobile: debouncedMobile,
      user,
      updateTokens,
    })
  }, [fetchQueryOrdersByCustomer, store, debouncedEmail, debouncedFirstName, debouncedLastName, debouncedMobile, user, updateTokens]);
  return (
    <>
      {error && (<p id="search_error" className="text-center">{error.message || error}</p>)}
      <div className="search-by-customer form-group row col-12 mb-2">
        <div className="input-group search-wrapper">
          <AsyncTypeahead
            id="order_search_by_customer"
            key="order_search_by_customer"
            isLoading={loading}
            useCache={false}
            options={options || []}
            filterBy={() => true}
            labelKey={op => `${op.order_number || op.shipping_group_numbers[0]}`}
            onSearch={() => {}}
            onChange={([option]) => {
              const value = option ? (
                option.order_number || option.shipping_group_numbers[0]
              ) : '';
              onSelectOrder(value);
              if (option) {
                setFirstNameValue('');
                setLastNameValue('');
                setEmailValue('');
                setMobileValue('');
              }
            }}
            renderInput={({ inputRef, referenceElementRef, onChange, ...inputProps }) => {
              const filteredInputsProps = _.omit(inputProps, ['type', 'className', 'placeholder', 'value', 'onChange']);
              return (
              <div
                className="search-by-customer-inputs input-group m-0"
                ref={(wrapper) => {
                  referenceElementRef(wrapper);
                }}
              >
                <input type="text"
                  className="form-control m-0"
                  placeholder={t('orders.enterLastName', 'Enter last name...')}
                  value={lastNameValue}
                  onChange={(event) => {
                    setLastNameValue(event.target.value);
                    onChange(event);
                  }}
                  ref={(input) => {
                    inputRef(input);
                  }}
                  {...filteredInputsProps}
                />
                <input type="text"
                  className="form-control m-0"
                  placeholder={t('orders.enterFirstName', 'Enter first name...')}
                  value={firstNameValue}
                  onChange={(event) => {
                    setFirstNameValue(event.target.value);
                    onChange(event);
                  }}
                  {...filteredInputsProps}
                />
                <input type="text"
                  className="form-control m-0"
                  placeholder={t('orders.enterEmail', 'Enter email...')}
                  value={emailValue}
                  onChange={(event) => {
                    setEmailValue(event.target.value);
                    onChange(event);
                  }}
                  {...filteredInputsProps}
                />
                <input type="text"
                       className="form-control m-0"
                       placeholder={t('orders.enterMobile', 'Enter phone...')}
                       value={mobileValue}
                       onChange={(event) => {
                         setMobileValue(event.target.value.replace(/[-_.\s]/gm, ""));
                         onChange(event);
                       }}
                       {...filteredInputsProps}
                />
              </div>
            )}}
            renderMenuItemChildren={(option, props, index) => (
              <div className="order-by-customer-search-item">
                <i className={`order-search-icon vtmn-icon_valid_rounded ${option.in_stock ? 'order-instock' : ''}`} />
                <div className="order-item-info">
                  <div className="order-item-info-row">
                    <div className="order-number">
                      {option.order_number}
                    </div>
                    <div className="order-customer-name">
                      <Highlighter search={firstNameValue}>
                        {option.firstName || ''}
                      </Highlighter>
                      {' '}
                      <Highlighter search={lastNameValue}>
                        {option.lastName || ''}
                      </Highlighter>
                    </div>
                  </div>
                  <div className="order-shipping-group-numbers">
                    {option.shipping_group_numbers.join(', ')}
                  </div>
                </div>
              </div>
            )}
          />
          <div className="input-group-append">
            <button
              onClick={() => {
                fetchQueryOrdersByCustomer({
                  store,
                  email: emailValue,
                  firstName: firstNameValue,
                  lastName: lastNameValue,
                  mobile: mobileValue,
                  user,
                  updateTokens,
                });
              }}
              id="buttonOrderSubmit"
              className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_with-only-icon"
            >
              <i className="vtmn-icon_search"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchByCustomer;
