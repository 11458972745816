import React from 'react';

const ScannerButton = ({ scannerRef, disabled }) => {
  return (
    <button
      className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_with-only-icon"
      data-toggle="button"
      aria-pressed="false"
      type="button"
      disabled={disabled}
      onClick={() => {
        if (scannerRef && scannerRef.current) {
          scannerRef.current.onClick();
        }
      }}
    >
      <i className="vtmn-icon_picture"></i>
    </button>
  );
}

export default ScannerButton;
