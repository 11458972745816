import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en.json';
import fr from './locales/fr.json';
import it from './locales/it.json';
import de from './locales/de.json';
import es from './locales/es.json';
import nl from './locales/nl.json';

import { LANGUAGES } from './locales/constants';

const isDevelop = process.env.REACT_APP_Redirect_URI && process.env.REACT_APP_Redirect_URI.startsWith('http://localhost:3000');

const langDetectorOptions = {
  order: ['localStorage', 'navigator'],
  lookupLocalStorage: 'locale',
  caches: ['localStorage'],
  excludeCacheFor: ['cimode'],
  checkWhitelist: true,
};




i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { // translation is the default namespace
        translation: en
      },
      fr: {
        translation: fr
      },
      it: {
        translation: it
      },
      de: {
        translation: de
      },
      es: {
        translation: es
      },
      nl: {
        translation: nl
      }
    },
    fallbackLng: 'en',
    debug: isDevelop,
    whitelist: LANGUAGES,
    detection: langDetectorOptions,
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;
