import React, { useState, useEffect, useMemo } from 'react';
import { Form, Spinner, Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { partition, isNil } from 'lodash';

import { useUserReduxState } from '../../store/user';
import useRequest from '../../functions/useRequest';

import { editReleaseChangelogTranslationsQuery } from './api';
import ReleaseTranslationsList from './ReleaseTranslationsList';
import translateSvg from '../../images/translate.svg';

const DEFAULT_TRANSLATION = {
  lang: null,
  title: null,
  description: null,
  isChanged: true,
}

const mapTranslationsToForm = (t) => t.map((x) => ({
  id: x.id,
  lang: x.lang,
  title: x.title,
  description: x.description,
  isChanged: false,
}));

const ReleaseChangelogTranslationsEditDialog = (props) => {
  const { show, release, changelog, translations: allTranslations, onDone, onUpdate, onHide } = props;
  const { t } = useTranslation();
  const { user, updateTokens } = useUserReduxState();
  const changelogId = changelog.id;

  const changelogTranslations = useMemo(
    () => allTranslations.filter((x) => x.changelog_id === changelogId),
    [changelogId, allTranslations],
  );

  const [translations, setTranslations] = useState([]);
  const [toDelete, setToDelete] = useState([]);
  const [errors, setErrors] = useState([]);

  const [{ loading, error }, editReleaseChangelogTranslations] = useRequest(editReleaseChangelogTranslationsQuery, {
    onSuccess: ({ payload }) => {
      if (payload?.failed?.length) {
        onUpdate();
        setErrors(payload.failed);
      } else onDone();
    },
  });

  const isChanged = translations.some((x) => x.isChanged) || toDelete.length > 0;
  const isValid = translations.length ? translations.every((x) => !!x.title && !!x.lang) : true;
  const canSave = isChanged && isValid && !loading;

  const onSaveClick = () => {
    const [toAdd, toEdit] = partition(translations, (x) => isNil(x.id));
    editReleaseChangelogTranslations({
      id: release.id,
      changelogId: changelog.id,
      actions: {
        to_add: toAdd,
        to_edit: toEdit,
        to_delete: toDelete,
      },
      user,
      updateTokens,
    });
  }

  const handleTranslationAdd = () => setTranslations([...translations, DEFAULT_TRANSLATION]);

  const handleTranslationDelete = (v, index) => {
    const newTranslations = [...translations];
    newTranslations.splice(index, 1);
    setTranslations(newTranslations);
    if (v.id) setToDelete([...toDelete, v.id]);
  };

  const handleTranslationChange = (key, v, index) => {
    const newTranslations = translations.map((x, i) => {
      if (i === index) {
        const newTranslation = { ...x };
        newTranslation[key] = v;
        newTranslation.isChanged = true;
        return newTranslation;
      }
      return x;
    })
    setTranslations(newTranslations);
  };

  const handleLanguageChange = (lang, index) => {
    const newTranslations = translations.map((x, i) => {
      if (i === index) return { ...x, lang, isChanged: true };
      return x;
    })
    setTranslations(newTranslations);
  };

  const fields = [
    { id: 'title', label: t('shared.title', 'Title') },
    { id: 'description', label: t('shared.description', 'Description') },
  ];

  useEffect(() => {
    setTranslations(mapTranslationsToForm(changelogTranslations));
  }, [changelogTranslations])

  return (
    <Modal
      show={show}
      size="lg"
      onHide={onHide}
      className="release-view-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <img src={translateSvg} width={20} height={20} alt='' className='mr-2' />
          {t('releases.changelogTranslations', 'Changelog translations')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center p-0">
        {!loading && error && (
          <Form.Control.Feedback type="invalid">
            {(error && error.message) || t('shared.error', 'Error')}
          </Form.Control.Feedback>
        )}
        {!loading && !!errors.length && (
          <Form.Control.Feedback type="invalid">
            {t('releases.translationsError', {
              defaultValue: 'Failed to save {{count}} translations',
              count: errors.length,
            })}
          </Form.Control.Feedback>
        )}
        <ReleaseTranslationsList
          translations={translations}
          fields={fields}
          original={{ title: changelog.title, description: changelog.description }}
          onChange={handleTranslationChange}
          onDelete={handleTranslationDelete}
          onLangChange={handleLanguageChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          size="sm"
          onClick={handleTranslationAdd}
          className="icon-button mr-auto"
        >
          <i className="vtmn-icon_plus_v2" />
          {t('shared.add', 'Add')}
        </Button>
        <Button
          variant="light"
          onClick={onHide}
        >
          {t('shared.close', 'Close')}
        </Button>
        <Button variant="primary" disabled={!canSave} onClick={onSaveClick}>
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                className="mr-2"
              />
              {t('shared.saving', 'Saving')}
            </>
          ) : t('shared.save', 'Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReleaseChangelogTranslationsEditDialog;
