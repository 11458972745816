import React from 'react';
import { Alert, Badge, Button, Dropdown, SplitButton } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import _ from 'lodash';

import { formatDurationSec } from '../../functions/formatDuration';
import { getErrorTooltip, normalizeName } from '../../functions/formatText';
import SummaryImage from '../../components/widgets/SummaryImage';
import RowField from '../../components/widgets/RowField';
import Loader from '../../components/Loader';

import { getItemsForItemView, getDetailsStatusTagColor, itemToFieldsData, getEcomOrderStatusType, getEcomOrderStatusLabel, getOrderMessages } from './helpers';
import dangerousItemTeammate from '../../images/dangerous_item_teammate.png';
import dangerousItem from '../../images/dangerous_item.png';

export const OrderStatusTag = ({ boxStatus, orderStatus }) => {
  const { t } = useTranslation()
  if (boxStatus === 'cancelled' || orderStatus === 'Cancelled') {
    // picking list was removed
    // or cant pick any item
    return (
      <Badge className="ml-2" variant="danger" title={t('trolley.statusCancelled', 'Cancelled')}>
        <i className="vtmn-icon_cross font-weight-bold" />
      </Badge>
    )
  }

  if (orderStatus === 'Done') {
    return (
      <Badge className="ml-2" variant="success" title={t('trolley.statusCompleted', 'Picked')}>
        <i className="vtmn-icon_valid font-weight-bold" />
      </Badge>
    )
  }

  if (orderStatus === 'Done with changes') {
    return (
      <Badge className="ml-2" variant="success" title={t('trolley.statusCompletedWithChanges', 'Picked with changes')}>
        <i className="vtmn-icon_valid font-weight-bold" />
      </Badge>
    )
  }
  if (orderStatus === 'Wait') {
    return (
      <Badge className="ml-2" variant="warning">
        {t('trolley.statusWait', 'Wait')}
      </Badge>
    )
  }
  if (orderStatus === 'In progress') {
    return (
      <Badge className="ml-2" variant="warning">
        {t('trolley.statusInProgress', 'In progress')}
      </Badge>
    )
  }
  return null;
};

const FINILIZED_STATUSES = ['finalized', 'skipped', 'cancelled']

export const OrderView = (props) => {
  const {
    orders,
    loading,
    boxes,
    setImageModalData,
    statusResolver,
    onPrintShippingLabels,
    onAddress,
    onTransporterInfoClick,
    actionsDisabled,
    onRemoveOrder,
    onRetry,
    onFinalizeTrolley,
  } = props;
  const { t } = useTranslation();
  return orders.map((order) => {

    const totalQty = _.sumBy(order.items, item => item.qty);
    const hasDangerousMessage = order.can_print && order.items.some(boxItem => boxItem.is_dangerous);
    const hasNotAddressed = order.items.some(
      item => item.resolve_action === 'picked' && (!item.addressed_qty || item.qty - item.addressed_qty > 0),
    );
    const resolvedStatus = statusResolver(order);
    const resolvedStatusType = getEcomOrderStatusType(resolvedStatus);
    const canRetryFinalizeOrder = order.finalize_status === 'error';
    const canRemoveOrder = !order.finalize_status || !FINILIZED_STATUSES.includes(order.finalize_status);
    const orderBoxes = boxes.filter((x) => x.order_id === order.order_id);

    return (
      <div key={order.order_id} className={`trolley-summary-box trolley-summary-box-${resolvedStatusType}`}>
        {hasDangerousMessage && (
          <Alert variant="info" className="trolley-summary-alert">
            <img src={dangerousItemTeammate} alt="dangerous item" height="35" width="35" className="trolley-summary-alert-dangerous-item"/>
            <Trans i18nKey="ecomPickingList.dangerousItemInfo">There is a dangerous product inside the order. Don't forget to put a sticker on the box</Trans>
          </Alert>
        )}
        <div className="trolley-summary-box-head" key={order.order_id}>
          <div className="trolley-summary-box-number">{order.box_numbers.map(n => `#${n}`).join(' / ')}</div>
          <RowField
            inline
            label={t('shared.order', 'Order')}
            value={(
              <>
                <span>{order.order_id}</span>
                <OrderStatusTag boxStatus={order.status} orderStatus={order.order_status} />
                {(order.can_print || order.can_address) && (
                  <span
                    className="icon-button-hover ml-2 transporter-icon"
                    onClick={() => onTransporterInfoClick(order)}
                  >
                    <i className="vtmn-icon_eye_on vtmn-icon-22px m-0"></i>
                  </span>
                )}
              </>
            )}
          />
          <div className="summary-box-view-tag-line">
            {order.customer_tag ? (
              <div className="trolley-customer-tag mr-2">
                <Badge variant="dark">{order.customer_tag}</Badge>
              </div>
            ) : null}
            <div className="d-inline-flex mr-2">{normalizeName(order.customer_name)}</div>
            <Badge pill variant="yellow">{totalQty}</Badge>
            {order.status !== 'cancelled' && orderBoxes.map(({ box_name, order_box_number }) => (
              <Badge key={order_box_number} className="d-flex align-items-center ml-2" variant="info">
                <img className="img-icon mr-1" alt="lbox" src="/icons/package_variant_closed.svg" />
                {box_name}
              </Badge>
            ))}
          </div>
        </div>
        {loading ? <Loader /> : (
          <div className="trolley-summary-box-images-row">
            <div className="trolley-summary-box-images">
              {order.items.map((boxItem, index) => {
                const itemInfo = boxItem.item_info;
                const itemStatus =
                  boxItem.order_details_status === 'To pick' && boxItem.resolve_action === 'picked' && order.status === 'cancelled'
                    ? 'Pick cancelled'
                    : boxItem.order_details_status;

                return (
                  <div className="trolley-summary-box-image-with-addresses" key={`${boxItem.item_id}-${index}`}>
                    <SummaryImage
                      qty={boxItem.qty}
                      item={itemInfo}
                      onClick={() => setImageModalData({
                        messages: getOrderMessages(order, t),
                        order,
                        pixlId: itemInfo?.pixlId,
                        item: itemInfo?.item_id,
                        item_description: itemInfo?.item_description,
                        is_dangerous:  boxItem.is_dangerous,
                        end_of_life: boxItem.end_of_life,
                        order_id: order.order_id,
                        customer_tag: order.customer_tag,
                        customer_name: order.customer_name,
                        fields: itemToFieldsData(t, boxItem, order),
                        index,
                        isFirst: index <= 0,
                        isLast: index >= order.items.length - 1,
                        items: order.items,
                      })}
                    />
                    {boxItem.addresses?.map((address, index) => (
                      <Badge className="mx-1 mb-1" key={index} variant="primary">{address}</Badge>
                    ))}
                    {itemStatus !== 'Picked' && (
                      <Badge className="mx-1 mb-1" variant={getDetailsStatusTagColor(itemStatus)}>
                        {itemStatus}
                      </Badge>
                    )}
                    {boxItem.is_dangerous && (
                      <div className="d-flex align-items-center mb-1">
                        <img src={dangerousItem} alt="dangerous item" height="30" width="30"/>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
            <div className="d-flex flex-column align-items-end mb-4 pb-1">
              {order.can_print && (
                <Button
                  variant="primary"
                  className="mr-2"
                  disabled={actionsDisabled}
                  onClick={() => onPrintShippingLabels([order.order_id])}
                  title={actionsDisabled
                    ? t('ecomPickingList.printDisabledMessage', 'You have to finish your current picking list in order to be able to print labels in the trolley.')
                    : t('trolley.printLabel', 'Print shipping label')
                  }
                >
                  <i className="vtmn-icon_printer m-0" />
                </Button>
              )}
              {canRetryFinalizeOrder && onRetry && (
                <SplitButton
                  onClick={() => onRetry(order.order_id)}
                  disabled={loading}
                  size="sm"
                  variant="primary"
                  className="mx-2 mt-1 mb-0"
                  title={
                    <div className="d-flex flex-row align-items-center">
                      <Trans i18nKey="shared.retry">Retry</Trans>
                    </div>
                  }
                >
                  <Dropdown.Item onClick={() => onRetry(order.order_id)} disabled={loading}>
                    <div className="d-flex flex-row align-items-center">
                      <Trans i18nKey="trolley.finalizeOrder">Finalize order</Trans>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={onFinalizeTrolley} disabled={loading}>
                    <div className="d-flex flex-row align-items-center">
                      <Trans i18nKey="trolley.finalize">Finalize trolley</Trans>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => onRemoveOrder(order.order_id)} disabled={loading}>
                    <div className="d-flex flex-row align-items-center">
                      <Trans i18nKey="trolley.removeOrder">Remove from trolley</Trans>
                    </div>
                  </Dropdown.Item>
                </SplitButton>
              )}
              {!canRetryFinalizeOrder && canRemoveOrder && (
                <Button
                  variant="outline-danger"
                  className="mx-2 mt-1 mb-0"
                  disabled={loading}
                  onClick={() => onRemoveOrder(order.order_id)}
                  title={t('trolley.removeOrder', 'Remove from trolley')}
                >
                  <i className="vtmn-icon_delete m-0"></i>
                </Button>
              )}
              {order.can_address && (
                hasNotAddressed ? (
                  <Button
                    variant="primary"
                    className="mx-2 mt-1 mb-0"
                    disabled={actionsDisabled}
                    onClick={() => onAddress(order)}
                    title={actionsDisabled
                      ? t('ecomPickingList.addressDisabledMessage', 'You have to finish your current picking list in order to be able to address.')
                      : t('orders.address', 'Address')
                    }
                  >
                    <Trans i18nKey="orders.address">Address</Trans>
                  </Button>
                ) : (
                  <i
                    className="mx-2 mt-1 mb-0 addressed-icon text-primary vtmn-icon_valid_rounded"
                    title={t('trolley.addressed', 'Addressed')}
                  />
                )
              )}
            </div>
          </div>
        )}
        {resolvedStatus && (
          <div className="trolley-order-status-wrap">
            {order.finalize_error && (
              <i
                className="trolley-order-error-icon text-danger vtmn-icon_notif_alert"
                title={getErrorTooltip(order.finalize_error)}
                onClick={() => navigator.clipboard.writeText(order.finalize_error.text || 'Error')}
              />
            )}
            {order.finalize_status === 'cancelled' && (
              <i
                className="trolley-order-error-icon text-warning vtmn-icon_notif_alert"
                title={t('trolley.otherCancelledOrderMessage', 'Order was cancelled with other app')}
                onClick={() => navigator.clipboard.writeText(t('trolley.otherCancelledOrderMessage', 'Order was cancelled with other app'))}
              />
            )}
            <div className={`trolley-order-status trolley-order-status-${resolvedStatusType}`}>
              {getEcomOrderStatusLabel(resolvedStatus, t)}
            </div>
          </div>
        )}
      </div>
    );
  });
};

export const ItemView = (props) => {
  const {
    orders,
    loading,
    setImageModalData,
    boxes,
    onTransporterInfoClick,
  } = props;
  const { t } = useTranslation();

  if (loading) {
    return <Loader />;
  }
  const itemViewItems = getItemsForItemView(orders);

  return itemViewItems.map(({ order, item }) => {
    const box = boxes.find((x) => x.order_box_number === item.order_box_number);
    return (
      <div key={item.id} className="trolley-summary-address-item">
        <div className="summary-address-image-column">
          <SummaryImage
            key={item.item_id}
            qty={item.qty}
            item={item.item_info}
            onClick={() => {
              setImageModalData({
                messages: getOrderMessages(order, t),
                order,
                pixlId: item.item_info?.pixlId,
                item: item.item_info?.item_id,
                item_description: item.item_info?.item_description,
                is_dangerous: item.is_dangerous,
                end_of_life: item.end_of_life,
                order_id: order.order_id,
                customer_tag: order.customer_tag,
                customer_name: order.customer_name,
                fields: itemToFieldsData(t, item, order),
                index: 0,
                isFirst: true,
                isLast: true,
                items: [item],
              })
            }}
          />
          <Badge variant="dark">{order.customer_tag}</Badge>
        </div>
        <div className="ml-1">
          <RowField
            inline
            label={t('shared.order', 'Order')}
            value={(
              <>
              <span>{order.order_id}</span>
                {(order.can_print || order.can_address) && (
                  <span
                    className="icon-button-hover ml-2 transporter-icon"
                    onClick={() => onTransporterInfoClick(order)}
                  >
                    <i className="vtmn-icon_eye_on vtmn-icon-22px m-0"></i>
                  </span>
                )}
              </>

            )}
          />
          <RowField
            inline
            label={t('orders.customer', 'Customer')}
            value={normalizeName(order.customer_name)}
          />
          <RowField
            inline
            label={t('items.item', 'Item')}
            value={`${item.item_id} ${item.item_info?.item_description || ''}`}
          />
          <RowField
            inline
            label={t('items.qty', 'Qty')}
            value={item.qty}
          />
          <RowField
            inline
            label={t('trolley.pickDate', 'Pick date')}
            value={dateFormat(item.create_date, "dd/mm/yyyy HH:MM:ss")}
          />
          {!!item.addresses?.length && (
            <RowField
              inline
              label={t('items.address', 'Address')}
              value={item.addresses.join(',')}
            />
          )}
          {item.status !== 'cancelled' && box && (
            <RowField
              inline
              label={t('shopOrders.box', 'Box')}
              value={
                <div className="d-flex">
                  <Badge className="d-flex align-items-center ml-1" variant="info">
                    <img className="img-icon mr-1" alt="lbox" src="/icons/package_variant_closed.svg" />
                    <div>
                      {box.box_name}
                    </div>
                  </Badge>
                </div>
              }
            />
          )}
        </div>
        <div className="trolley-summary-box-number">{`#${item.box_number}`}</div>
      </div>
    )
  });
};

export const Messages = ({ trolley, actionsDisabled }) => {
  const orders = trolley?.orders || [];
  const showCubeMessage = orders.some(x => x.need_cube_finish) || false;
  const hasToPrint = orders.some(x => x.can_print) || false
  const hasToAddress = orders.some(x => x.can_address) || false

  const resolveCubeMessage = () => {
    const itemStatuses =
    (showCubeMessage && _.uniq(_.flatMap(orders, 'items').map((x) => x.order_details_status))) || [];
    const hasToCancelItems = itemStatuses.includes('To cancel');
    const hasToReplaceItems = itemStatuses.includes('To replace');
    const hasBoth = hasToCancelItems && hasToReplaceItems;

    if (hasBoth) {
      return <Trans i18nKey="ecomPickingList.replaceOrCancelInCube">Use «CubeInStore» to cancel or replace the items</Trans>
    }
    if (hasToCancelItems) {
      return <Trans i18nKey="ecomPickingList.cancelInCube">Use «CubeInStore» to cancel the items</Trans>
    }

    if (hasToReplaceItems) {
      return <Trans i18nKey="ecomPickingList.replaceInCube">Use «CubeInStore» to replace the items</Trans>
    }

    return <Trans i18nKey="ecomPickingList.finishInCube">Use «CubeInStore» to finish the preparation</Trans>
  }

  return (
    <>
      {showCubeMessage && (
        <Alert variant="info">
          {resolveCubeMessage()}
        </Alert>
      )}
      {actionsDisabled && hasToPrint && (
        <Alert variant="info">
          <Trans i18nKey="ecomPickingList.printDisabledMessage">You have to finish your current picking list in order to be able to print labels in the trolley.</Trans>
        </Alert>
      )}
      {actionsDisabled && hasToAddress && (
        <Alert variant="info">
          <Trans i18nKey="ecomPickingList.addressDisabledMessage">You have to finish your current picking list in order to be able to address.</Trans>
        </Alert>
      )}
    </>
  )
};

export const RecommendedBoxes = ({ orderIds, recommendedBoxes }) => {
  const { t } = useTranslation();
  const boxes = _.countBy(
    orderIds,
    orderId => recommendedBoxes?.[orderId]
      ? recommendedBoxes[orderId].box_name
      : _.capitalize(t('trackingNumbers.trackingNumberTypes.voluminous', 'voluminous')));

  return (
    <div className="d-flex flex-wrap mt-2">
      {recommendedBoxes ? Object.keys(boxes).map((box_name) => (
        <div key={box_name} className="d-flex align-items-center mr-2 mb-2">
          <Badge className="d-flex align-items-center mr-1" variant="info">
            <img className="img-icon mr-1" alt="lbox" src="/icons/package_variant_closed.svg" />
            {box_name}
          </Badge>
          : {boxes[box_name]}
        </div>
      )) : null}
    </div>
  );
};

export const getTrolleyBoxes = ({ trolley, boxes, emptyLabel }) => {
  const trolleyBoxes = [];
  for (const order of trolley?.orders || []) {
    for (const item of order.items || []) {
      const box = boxes.find((x) => x.id === item.package_id);
      if (box) {
        trolleyBoxes.push({
          order_id: order.order_id,
          order_box_number: item.order_box_number,
          box_name: box.name,
          package_id: item.package_id,
        })
      } else {
        trolleyBoxes.push({
          order_id: order.order_id,
          order_box_number: item.order_box_number,
          box_name: emptyLabel,
          package_id: null,
        })
      }
    }
  }
  return _.uniqBy(trolleyBoxes, 'order_box_number');
}

export const TrolleyBoxes = ({ trolleyBoxes }) => {
  const boxCounts = _.countBy(trolleyBoxes, 'package_id');

  return (
    <div className="d-flex flex-wrap mt-2">
      {trolleyBoxes.map(({ box_name, package_id }, index) => (
        <div key={`${package_id}-${index}`} className="d-flex align-items-center mr-2 mb-2">
          <Badge className="d-flex align-items-center mr-1" variant="info">
            <img className="img-icon mr-1" alt="lbox" src="/icons/package_variant_closed.svg" />
            {box_name}
          </Badge>
          : {boxCounts[package_id]}
        </div>
      ))}
    </div>
  );
};

export const EcomTrolleySummary = ({ trolley, showInfo }) => {
  const { t } = useTranslation();
  const duration = trolley?.duration && formatDurationSec(trolley.duration);
  return (
    <div className="trolley-summary-head">
      <div>
        {showInfo && trolley && (
          <>
            <RowField
              inline
              label={t('trolley.createdAt', 'Created at')}
              value={dateFormat(trolley.create_date, "dd/mm/yyyy HH:MM:ss")}
            />
            <RowField
              inline
              label={t('trolley.status', 'Status')}
              value={trolley.status?.toUpperCase() || '-'}
            />
            <RowField
              inline
              label={t('trolley.picker', 'Picker')}
              value={trolley.email || '-'}
            />
            {trolley.status === 'packed' && trolley.pack_email && (
              <RowField
                inline
                label={t('trolley.packer', 'Packer')}
                value={trolley.pack_email || '-'}
              />
            )}
          </>
        )}
        <RowField
          inline
          label={t('trolley.boxCount', 'Boxes count')}
          value={trolley.box_count}
        />
        <RowField
          inline
          label={t('trolley.duration', 'Duration')}
          value={duration || '-'}
        />
      </div>
    </div>
  );
};
