import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';

import Select from '../../components/widgets/Select';
import Confirm from '../../components/widgets/Confirm';

const EditOrdersApiModal = ({ show, ordersApi, ordersApiTypes, onHide, onSave }) => {
  const { t } = useTranslation();

  const [newApiType, setNewApiType] = useState(ordersApi);
  const [confirm, setConfirm] = useState(false);

  const options = Object.entries(ordersApiTypes).map(([id, label]) => ({ id, label }));
  const isDisabled = !newApiType || !ordersApiTypes[newApiType];

  const handleSaveClick = () => {
    setConfirm(true);
  }

  useEffect(() => {
    if (show) {
      setConfirm(false);
    }
  }, [show]);

  useEffect(() => {
    if (ordersApi) {
      setNewApiType(ordersApi);
    }
  }, [ordersApi]);

  if (confirm) {
    return (
      <Confirm
        show={show}
        onHide={onHide}
        onConfirm={() => onSave(newApiType)}
        icon={<i className="modal-header-icon text-danger vtmn-icon_edit" />}
        title={t('adminAddreses.confirmOrdersApiTitle', 'Confirm orders API change')}
        body={(
          <div>
            <p>
              <Trans i18nKey="adminAddreses.confirmOrdersApiQuestion">Are you sure, you want to change store orders API?</Trans>
            </p>
            <mark>{`${t('adminAddreses.newApi', 'New API')}: ${ordersApiTypes[newApiType]}`}</mark>
          </div>
        )}
      />
    )
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <i className="modal-header-icon text-primary vtmn-icon_edit" />
        <Modal.Title>
          <Trans i18nKey="adminAddreses.editOrdersApi">Edit orders API</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select
          controlId="ordersApiType"
          placeholder={t('adminAddreses.chooseOrdersApi', 'Select store orders API')}
          options={options}
          selected={newApiType}
          onChange={(val) => setNewApiType(val)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
        >
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="primary"
          disabled={isDisabled}
          onClick={handleSaveClick}
        >
          <Trans i18nKey="shared.save">Save</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditOrdersApiModal;
