import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  source: null,
  type: null,
  value: null,
  duration: null,
  date: null,
  readingType: 'other',
}

const slice = createSlice({
  name: 'lastBarcode',
  initialState,
  reducers: {
    setLastBarcode: (state, { payload }) => {
      return { ...state, ...payload }
    },
    clearLastBarcode: () => initialState,
  },
})

export const { setLastBarcode, clearLastBarcode } = slice.actions

export default slice.reducer
