import React, { useState, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import Loader from '../../components/Loader';
import useRequest from '../../functions/useRequest';
import ZXScanner from '../../components/widgets/scanner/ZXScanner';
import ScannerButton from '../../components/widgets/scanner/ScannerButton';
import { NoPackagingOrderAddingError } from '../TrackingNumber/messages';
import { logBarcodeReadEvent } from '../../functions/analytics';
import { foundMissing } from './api';

const ModalFoundMissing = ({
  store,
  missingOrderItem,
  show,
  onClose,
  user,
  updateTokens,
  onSuccess,
}) => {
  const [address, setAddress] = useState('');
  const [error, setError] = useState(null);
  const addressScannerRef = useRef();
  const { t } = useTranslation();
  const onHide = () => {
    setAddress('');
    onClose();
  };
  const [{
    loading: isSaving,
  }, doFoundMissing] = useRequest(foundMissing, {
    onSuccess: () => {
      logBarcodeReadEvent({ success: true, readingType: 'address', value: address });
      onHide();
      onSuccess();
    },
    onError: (e) => {
      const isAddressNotFound = e.response && e.response.address && e.response.exists === false;
      if (isAddressNotFound) {
        logBarcodeReadEvent({ success: false, readingType: 'address', value: address });
      }
      return setError(e);
    },
  });
  const onSave = () => doFoundMissing({
    store,
    id: missingOrderItem?.id,
    address,
    user,
    updateTokens,
  });

  const loading = isSaving;

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="orders.foundMissing">Found missing item</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? <Loader/> : (
          <>
            <p>
              <Trans i18nKey="orders.placeItem">Please place item to an address.</Trans>
            </p>
            <div className="d-flex align-items-start flex-column flex-sm-row">
              <img
                className="mb-2"
                src={`${missingOrderItem?.item.image}?f=200x200`}
                alt=""
                style={{ maxWidth: '100%' }}
              />
              <div>
                <div>
                  <div className="text-secondary font-weight-bold">
                    <Trans i18nKey="orders.order">Order</Trans>
                    {':'}
                  </div>
                  <div>{missingOrderItem?.order_number}</div>
                </div>
                <div>
                  <div className="text-secondary font-weight-bold">
                    <Trans i18nKey="orders.shippingGroup">Shipping group</Trans>
                    {':'}
                  </div>
                  <div>{missingOrderItem?.shipping_group_number}</div>
                </div>
                <div>
                  <div className="text-secondary font-weight-bold">
                    <Trans i18nKey="orders.itemCode">Item code</Trans>
                    {':'}
                  </div>
                  <div>{missingOrderItem?.item_id}</div>
                </div>
                <div>
                  <div className="text-secondary font-weight-bold">
                    <Trans i18nKey="orders.description">Description</Trans>
                    {':'}
                  </div>
                  <div>{missingOrderItem?.item.item_description}</div>
                </div>
              </div>
            </div>
            <InputGroup>
              <Form.Control
                placeholder={t('orders.addressInput', 'Input address...')}
                type="text"
                value={address}
                onChange={(event) => setAddress(event.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.repeat) {
                    setTimeout(onSave, 100);
                  }
                }}
              />
              <InputGroup.Append>
                <ScannerButton scannerRef={addressScannerRef} />
              </InputGroup.Append>
            </InputGroup>
          </>
        )}
        {!loading && error && (
          <NoPackagingOrderAddingError
            error={error}
            onClose={() => setError(null)}
            onForceAssignSameItem={() => doFoundMissing({
              store,
              id: missingOrderItem?.id,
              address,
              force_same_item_to_address: true,
              user,
              updateTokens,
            })}
          />
        )}
        <ZXScanner
          readingType="address"
          ref={addressScannerRef}
          onDetected={(val) => setAddress(val)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        <Button variant="primary" onClick={onSave} disabled={loading || !address}>
          <Trans i18nKey="shared.save">Save</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalFoundMissing;
