import React, { PureComponent, Fragment } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Badge } from 'react-bootstrap';
import dateFormat from 'dateformat';
import _ from 'lodash';
import WithSidebar from '../WithSidebar';
import EcomOrderDetails from './EcomOrderDetails';
import ErrorDialog from '../../components/widgets/ErrorDialog';
import ImageModal from '../../components/widgets/ImageModal';
import Confirm from '../../components/widgets/Confirm';
import fetchWithJWT from '../../functions/fetchWithJWT';
import { handleApiResponse } from '../../functions/handleApiResponse';
import Loader from '../../components/Loader';
import MassResultsDialog from './MassResultsDialog';
import ImageThumbs from '../../components/widgets/ImageThumbs';
import { normalizeText } from '../../functions/formatText';
import {IN_PROGRESS_STATUS, TODO_STATUS, WAIT_STATUS} from './constants';
import MobileFiltersModal from './MobileFiltersModal';
import MobileSort from './MobileSort';
import InlineFilterTag from './InlineFilterTag';
import { useEcomOrdersState } from './state';
import { MassWrongPickerDialog, WrongPickerDialog } from './WrongPickers';
import '../../stylesheets/ecomOrders.css';
import defaultImage from '../../images/default_image.png';

const serverUrl = process.env.REACT_APP_SERVERURL;
const NUMBER_OF_COL = 12

const isWrongPicker = (error) => error?.status === 412 && !!error?.response?.current_picker

class EcomOrders extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      tmporders: [],
      sports: [],
      alleys: [],
      shippingMethods: [],
      customersName: [],
      teammates: new Set(),
      boxes: new Set(),
      order_id: null,
      order_details: null,
      showDiv: false,
      decaShopIsActive: true,
      checkedOrd: [],
      checkAll: false,
      error: null,
      imageModalData: {
        pixlId: null,
        item: null,
        item_description: null,
      },
      loading: false,
      massResults: null,
      massProgress: null,
      wrongPickers: null,
      wrongPickerOrders: null,
      repickingOrder: null,
      filtersModalShow: false,
    };
    this.selectAllRefDesktop = React.createRef();
    this.selectAllRefMobile = React.createRef();
  }

  //retrieve decashop orders.
  componentDidMount() {
    this.refrechComponent();
  }

  refrechComponent = async (keepError) => {
    const { t, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    this.setState({
      loading: true,
      error: keepError ? this.state.error : null
    });
    return fetchWithJWT(
      `${serverUrl}/v1/${this.props.match.params.store}/ecommerce_orders`,
      {
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        },
      }
    )
      .then(handleApiResponse)
      .then((result) => {
        const sports = [];
        const alleys = [];
        const shippingMethods = [];
        const customersName = [];
        const teammates = new Set();
        const boxes = new Set();
        result.forEach((ord, index) => {
          ord.isChecked = false;
          ord.sports = _.uniq(ord.items.map((item) => item.department_label).filter(Boolean));
          ord.id_ecompicking_list = "";
          sports.push(ord.sports);
          if (ord.location_type === 'store') ord.alleys.push(t("shopOrders.store", "Store").toUpperCase());
          alleys.push(ord.alleys);
          if (ord.shipping_method) {
            shippingMethods.push(ord.shipping_method);
          }
          if (ord.customer_name) {
            customersName.push(ord.customer_name);
          }
          if(ord.status === "In progress"){
            const {email, ids_ecompicking_list} = ord
            teammates.add(email)
            ord.id_ecompicking_list = ids_ecompicking_list && ids_ecompicking_list[0]
          }
          if(!ord.recommended_box) ord.recommended_box = {box_name:'-', box_volume: null}
          boxes.add(ord.recommended_box.box_name)
        });
        if (this.props.filters.sport) {
          sports.push([this.props.filters.sport]);
        }
        if (this.props.filters.alley) {
          alleys.push([this.props.filters.alley]);
        }
        if (this.props.filters.teammate) {
          teammates.add(this.props.filters.teammate);
        }
        if (this.props.filters.customer_name) {
          customersName.push(this.props.filters.customer_name);
        }
        if (this.props.filters.recommended_box) {
          boxes.push(this.props.filters.recommended_box);
        }
        for (const sm of this.props.filters.shipping_methods) {
          shippingMethods.push(sm);
        }
        const tmporders = result.map((order) => ({ ...order }));

        this.setState({
          orders: result,
          tmporders: tmporders,
          sports: _.uniq(_.flatten(sports)),
          alleys: _.uniq(_.flatten(alleys)),
          shippingMethods: _.uniq(shippingMethods),
          customersName: _.uniq(customersName),
          teammates,
          boxes,
          showDiv: false,
          loading: false,
        }, () => this.searchFilter());
      })
      .catch((error) => {
        this.setState({ error, loading: false });
        console.warn(error);
      })
  }

  //Mass Picking
  massPicking = async (orderIds, handleWrongPicker) => {
    let orders = this.state.orders;
    let processed = 0;
    this.setState({
      loading: true,
      massProgress: {
        total: orderIds.length,
        processed,
      },
    });
    const succeed = [];
    const failed = [];
    const wrongPickers = [];
    const wrongPickerOrders = [];

    for (const order_id of orderIds) {
      const details = await this.retrieveOrderDetail(order_id, true);
      if (details?.can_pick) {
        try {
          await this.pickItemNoRefresh(order_id, details, handleWrongPicker);
          succeed.push({
            order_id,
            created: _.sumBy(details.items, 'addresses.length'),
          });
        } catch (error) {
          if (isWrongPicker(error)) {
            wrongPickers.push(error.response.current_picker)
            wrongPickerOrders.push(order_id)
          } else {
            failed.push({ order_id });
          }
        }
      }
      processed += 1;
      if (!this.state.massProgress) {
        // stopped
        break;
      }
      this.setState({
        massProgress: {
          total: orderIds.length,
          processed,
        },
      });
    }

    if (handleWrongPicker) {
      const { massResults } = this.state;
      succeed.unshift(...massResults.succeed);
      failed.unshift(...massResults.failed);
    }

    this.setState({
      order_id: null,
      order_details: null,
      showDiv: false,
      checkedOrd: [],
      checkAll: false,
      orders: orders,
      massResults: {
        succeed,
        failed,
      },
      massProgress: null,
      wrongPickers: wrongPickers.length ? wrongPickers : null,
      wrongPickerOrders: wrongPickerOrders.length ? wrongPickerOrders : null,
    });
    await this.refrechComponent();
  }

  //Check Order
  handleCheck = (event) => {
    let updatedList = [...this.state.checkedOrd];
    let checkedId = event.target.id;
    let orders = this.state.orders;
    if (event.target.checked) {
      orders.forEach((item) => {
        if (item.order_id === checkedId && item.can_pick) {
          item.isChecked = true;
          updatedList = [...this.state.checkedOrd, checkedId];
        }
      });
    } else {
      const index = this.state.checkedOrd.indexOf(checkedId);
      orders.forEach((item) => {
        if (item.order_id === checkedId) {
          item.isChecked = false;
        }
      });
      if (index !== -1) {
        updatedList.splice(index, 1);
      }
    }
    const noneChacked = updatedList.length === 0;
    const allChecked = updatedList.length === orders.filter((x) => x.can_pick).length;
    if (this.selectAllRefDesktop && this.selectAllRefDesktop.current) {
      this.selectAllRefDesktop.current.indeterminate  = !noneChacked && !allChecked;
    }
    if (this.selectAllRefMobile && this.selectAllRefMobile.current) {
      this.selectAllRefMobile.current.indeterminate  = !noneChacked && !allChecked;
    }
    this.setState({
      checkedOrd: updatedList,
      showDiv: false,
      orders: orders,
    });
  };

  handleCheckAll = (event) => {
    let updatedList = [];
    let orders = this.state.orders;
    if (event.target.checked) {
      orders.forEach(function (item) {
        if (item.can_pick) {
          updatedList.push(item.order_id);
          item.isChecked = true;
        }
      });
    } else {
      updatedList = [];
      orders.forEach(function (item) {
        item.isChecked = false;
      });
    }
    this.setState({
      checkAll: !this.state.checkAll,
      checkedOrd: updatedList,
      orders: orders,
    });
  };

  handleOrderClick = (order_id) => {
    const showDivValue = (this.state.order_id === order_id) ? !this.state.showDiv : true;
    this.setState({
      order_id,
      order_details: null,
      showDiv: showDivValue,
    });
    if (showDivValue) {
      this.retrieveOrderDetail(order_id, false);
    }
  }

  onImageClick = (product, pixlId) => {
    this.setState({
      imageModalData: {
        pixlId: pixlId,
        item: product.item_id,
        item_description: product.item_description,
      }
    })
    window.$("#imageModal").modal('show');
  }

  toggleShowImages = async () => {
    this.props.setShowImages(!this.props.showImages);
  }

  onClearFilters = () => {
    this.props.clearFilters();
    this.searchFilter();
  }

  onResetFilters = () => {
    this.props.resetFilters();
    this.searchFilter();
  }

  updateFilter = (newFilters) => {
    for (const key in newFilters) {
      if (newFilters[key] === undefined) {
        newFilters[key] = null;
      }
    }
    this.props.setFilters({
      ...this.props.filters,
      ...newFilters,
    })
    this.searchFilter();
  }

  updateSort = (field) => {
    const { sort } = this.props;
    const nextSort = { field };
    if (sort.field !== field) {
      nextSort.direction = 'ASC';
    } else if (sort.direction === 'ASC') {
      nextSort.direction = 'DESC';
    } else {
      nextSort.direction = 'ASC';
    }
    this.props.setSort(nextSort);
    this.searchFilter();
  }

  getSortIcon = (field) => {
    const { sort } = this.props;
    if (sort.field !== field) {
      return 'sort';
    } else if (sort.direction === 'ASC') {
      return 'arrow1_down';
    } else {
      return 'arrow1_up';
    }
  }

  //Filter
  searchFilter = () => setTimeout(this.doSearchFilter, 1)

  doSearchFilter = () => {
    const filters = {};
    if (this.props.filters.states.length) {
      filters.status = this.props.filters.states;
    }
    if (!_.isNil(this.props.filters.teammate)) {
      filters.teammate = this.props.filters.teammate;
    }
    if (this.props.filters.shipping_methods.length) {
      filters.shipping_methods = this.props.filters.shipping_methods;
    }
    if (!_.isNil(this.props.filters.customer_name)) {
      filters.customer_name = this.props.filters.customer_name;
    }
    if (!_.isNil(this.props.filters.count_from)) {
      filters.count_from = Number(this.props.filters.count_from);
    }
    if (!_.isNil(this.props.filters.count_to)) {
      filters.count_to = Number(this.props.filters.count_to);
    }
    if (!_.isNil(this.props.filters.order_date_from)) {
      filters.order_date_from = dateFormat(this.props.filters.order_date_from, 'yyyy-mm-dd') + 'T00:00:00';
    }
    if (!_.isNil(this.props.filters.order_date_to)) {
      filters.order_date_to = dateFormat(this.props.filters.order_date_to, 'yyyy-mm-dd') + 'T23:59:59';
    }
    if (!_.isNil(this.props.filters.sport)) {
      filters.sport = this.props.filters.sport;
    }
    if (!_.isNil(this.props.filters.alley)) {
      filters.alley = this.props.filters.alley;
    }
    if (!_.isNil(this.props.filters.voluminous)) {
      filters.voluminous = this.props.filters.voluminous;
    }
    if (!_.isNil(this.props.filters.recommended_box)) {
      filters.recommended_box = this.props.filters.recommended_box;
    }

    let newArray = this.state.tmporders.filter((item) => {
      for (const key in filters) {
        if (key === "order_date_from" && item.order_date < filters[key]) {
          return false;
        }
        if (key === "order_date_to" && item.order_date > filters[key]) {
          return false;
        }
        if (key === "count_from" && item.count < filters[key]) {
          return false;
        }
        if (key === "count_to" && item.count > filters[key]) {
          return false;
        }
        if (key === "status" && !filters[key].includes(item[key])) {
          return false;
        }
        if (key === "alley") {
          if (!item.alleys.includes(filters[key])) return false;
        }
        if (key === "sport" && !item.sports.includes(filters[key])) {
          return false;
        }
        if (key === "voluminous" && item.has_voluminous.toString() !== filters[key]) {
          return false;
        }
        if (key === "teammate" && !item.email?.includes(filters[key])) {
          return false;
        }
        if (key === "shipping_methods" && !filters[key].includes(item.shipping_method)) {
          return false;
        }
        if (key === "customer_name" && item.customer_name !== filters[key]) {
          return false;
        }
        if (key === "recommended_box" && item.recommended_box.box_name !== filters[key]) {
          return false;
        }
      }
      return true;
    });

    const getSortOrder = (field) => {
      if (field === 'status') {
        return ['status', 'order_date'];
      }
      if (field === 'action') {
        return [(x) => x.can_pick ? 'can_pick' : x.status];
      }
      if (field === 'sport') {
        return [(x) => x.sports.join(', ')];
      }
      if (field === 'recommended_box') {
        return [(x) => x.recommended_box.box_name];
      }
      return [field];
    }

    newArray = _.orderBy(
      newArray,
      getSortOrder(this.props.sort.field),
      [this.props.sort.direction.toLowerCase()],
    );
    this.setState({
      orders: newArray,
    });
  };

  //picking button color
  setClassName = (status, canPick) => {
    if (!canPick && (status === TODO_STATUS || status === WAIT_STATUS)) {
      return "btn btn-secondary btn-sm ecom-action-btn";
    } else if (canPick && (status === TODO_STATUS || status === WAIT_STATUS)) {
      return "btn btn-success btn-sm ecom-action-btn";
    } else if(status === "In progress"){
      return "btn btn-danger btn-sm ecom-action-btn";
    }
    else {
      return "hidden";
    }
  };

  retrieveOrderDetail = (order_id, reset_cache) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    let url = `${serverUrl}/v1/${this.props.match.params.store}/ecommerce_orders/${order_id}`;
    if (reset_cache) {
      url += '?reset_cache=true';
    }
    return fetchWithJWT(
      url,
      {
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        },
      }
    )
      .then(handleApiResponse)
      .then((result) => {
        this.setState({
          order_details: result,
        });
        return result;
      })
      .catch((error) => {
        this.setState({ error });
        console.warn(error);
      });
  };

  //add to Ecommerce picking list
  pickItemNoRefresh = (order_id, details, force) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const toCreate = [];
    for (const element of details.items) {
      for (const elt of element.addresses) {
        toCreate.push({
          id: elt.stock.id,
          stock_order_id: elt.stock.stock_order_id,
          item_id: elt.stock.item_id,
          location_type: elt.stock.location_type,
          ordered_qty: elt.toPick,
        })
      }
    }
    let url = `${serverUrl}/v1/${this.props.match.params.store}/ecommerce_orders/${order_id}/create_picking_lists`;
    if (force) {
      url += '?force=true';
    }
    return fetchWithJWT(
      url,
      {
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        },
        method: "POST",
        body: JSON.stringify(toCreate),
      }
    ).then(handleApiResponse);
  };

  // ---------------------------------
  // force pick, deleting old picking-list
  // ---------------------------------
  repickOrder = async (order_id) => {
    await this.handlePick(order_id, true);
  }

  //add to picking list
  handlePick = async (order_id, handleWrongPicker) => {
    this.setState({ order_id: order_id, loading: true });
    const details = await this.retrieveOrderDetail(order_id, true);
    if (details) {
      try {
        await this.pickItemNoRefresh(order_id, details, handleWrongPicker);
      } catch (error) {
        this.setState({ error });
        console.log(error)
      }
      await this.refrechComponent(true);
    }
  }

  handleAction = async (e, item) => {
    if (item.status === TODO_STATUS || item.status === WAIT_STATUS) {
      await this.handlePick(item.order_id, false);
    } else {
      this.setState({ repickingOrder: item })
    }
    e.stopPropagation();
    e.preventDefault();
    return false;
  }

  getActionLabel = (item) => {
    const { t } = this.props;
    if(item.status === IN_PROGRESS_STATUS){
      return t("shopOrders.repick", "Re-pick");
    }else{
      if (item.location_type === 'warehouse') {
        return t("shopOrders.pickFromReserve", "Pick from reserve");
      } else if (item.location_type === 'store') {
        return t("shopOrders.orderFromStore", "Order from store");
      } else {
        return t(
          "shopOrders.mixedOrder",
          "Mixed order (store and reserve)"
        );
      }
    }
  }

  renderMobileHeader = ({ allCheckedOrIntermidiate }) => {
    return (
      <tr className="mobile-row">
        <th scope="col" className="to-center text-center" style={{ width: 50 }}>
          <input
            ref={this.selectAllRefMobile}
            type="checkbox"
            disabled={this.state.loading}
            onChange={(e) => this.handleCheckAll(e)}
            checked={allCheckedOrIntermidiate}
          />
        </th>
        <th scope="col" className="to-center" colSpan={NUMBER_OF_COL - 2}>
          <Trans i18nKey="shopOrders.order">Order</Trans>
        </th>
        <th scope="col" style={{ width: 140 }}>
          <Trans i18nKey="shopOrders.action">Action</Trans>
        </th>
      </tr>
    );
  }

  renderDesktopHeader = ({
    allCheckedOrIntermidiate,
  }) => {
    const { t } = this.props;
    return (
      <tr className="desktop-row">
        <th scope="col" className="to-center text-center" style={{ width: 50 }}>
          <input
            ref={this.selectAllRefDesktop}
            type="checkbox"
            disabled={this.state.loading}
            onChange={(e) => this.handleCheckAll(e)}
            checked={allCheckedOrIntermidiate}
          />
        </th>
        <th scope="col" onClick={() => this.updateSort('order_id')}>
          <Trans i18nKey="shopOrders.order">Order</Trans>{" "}
          {" "}
          <i className={`sort-icon vtmn-icon_${this.getSortIcon('order_id')}`} />
        </th>
        <th scope="col" style={{ width: 80 }} onClick={() => this.updateSort('count')}>
          <Trans i18nKey="shopOrders.nbItems">nb Items</Trans>
          {" "}
          <i className={`sort-icon vtmn-icon_${this.getSortIcon('count')}`} />
        </th>
        <th scope="col" onClick={() => this.updateSort('order_date')}>
          <Trans i18nKey="shopOrders.date">Date</Trans>
          {" "}
          <i className={`sort-icon vtmn-icon_${this.getSortIcon('order_date')}`} />
        </th>
        <th scope="col" onClick={() => this.updateSort('status')}>
          <Trans i18nKey="shopOrders.status">Status</Trans>
          {" "}
          <i className={`sort-icon vtmn-icon_${this.getSortIcon('status')}`} />
        </th>
        <th scope="col" style={{ width: 180 }} onClick={() => this.updateSort('sport')}>
          <Trans i18nKey="shopOrders.sport">Sport</Trans>
          {" "}
          <i className={`sort-icon vtmn-icon_${this.getSortIcon('sport')}`} />
        </th>
        <th scope="col" style={{ width: 100 }} onClick={() => this.updateSort('alleys')}>
          <Trans i18nKey="shopOrders.alley">Alley</Trans>
          {" "}
          <i className={`sort-icon vtmn-icon_${this.getSortIcon('alleys')}`} />
        </th>
        <th scope="col" onClick={() => this.updateSort('recommended_box')}>
        <Trans i18nKey="shopOrders.box">Box</Trans>
          {" "}
          <i className={`sort-icon vtmn-icon_${this.getSortIcon('recommended_box')}`} />
        </th>
        <th scope="col" style={{ width: 80 }} onClick={() => this.updateSort('has_voluminous')}>
          {_.capitalize(t('trackingNumbers.trackingNumberTypes.voluminous'))}
          {" "}
          <i className={`sort-icon vtmn-icon_${this.getSortIcon('has_voluminous')}`} />
        </th>
        <th scope="col" onClick={() => this.updateSort('email')}>
          <Trans i18nKey="shopOrders.teammate">Teammate</Trans>
          {" "}
          <i className={`sort-icon vtmn-icon_${this.getSortIcon('email')}`} />
        </th>
        <th scope="col" style={{ width: 180 }} onClick={() => this.updateSort('shipping_method')}>
          <Trans i18nKey="orders.shippingMethod">Shipping method</Trans>
          {" "}
          <i className={`sort-icon vtmn-icon_${this.getSortIcon('shipping_method')}`} />
        </th>
        <th scope="col" style={{ width: 140 }} onClick={() => this.updateSort('action')}>
          <Trans i18nKey="shopOrders.action">Action</Trans>
          {" "}
          <i className={`sort-icon vtmn-icon_${this.getSortIcon('action')}`} />
        </th>
      </tr>
    );
  }

  getStatusBadgeColor = (item) => {
    const { can_pick, status } = item;
    if (status === WAIT_STATUS) {
      return 'warning';
    }
    if (can_pick) {
      return 'success';
    }
    if (status === IN_PROGRESS_STATUS) {
      return 'warning';
    }
    return 'secondary';
  }

  renderDesktopRowContent = (item) => {
    const { t } = this.props;
    return (
      <>
        <td className="desktop-col text-sm to-center">{item.order_id}</td>
        <td className="desktop-col text-sm to-center text-center">{item.count}</td>
        <td className="desktop-col text-sm to-center">
          <span>{dateFormat(item.order_date, "dddd dd/mm/yyyy")}</span>
          <br/>
          <span>{dateFormat(item.order_date, "HH:MM:ss")}</span>
        </td>
        <td className="desktop-col text-sm to-center text-center">
            <Badge
              className="status-badge"
              variant={this.getStatusBadgeColor(item)}
            >
              {item.status}
            </Badge>
        </td>
        <td className="desktop-col to-center">
          <span className="text-sm text-muted">
            {item.sports.join(', ') || '-'}
          </span>
        </td>
        <td className="desktop-col to-center">
          <span className="text-sm text-muted">
            {item.alleys.join(', ') || '-'}
          </span>
        </td>
        <td className="desktop-col to-center text-center">
          {item.recommended_box.box_name !== '-' ? (
            <Badge className="d-flex align-items-center"  variant='info'>
              <img className="img-icon mr-1" alt="lbox" src="/icons/package_variant_closed.svg" />
              {item.recommended_box.box_name}
            </Badge>
          ) : '-'}
        </td>
        <td className="desktop-col to-center text-center">
          {item.has_voluminous ? (
            <Badge className="tag tag-warning no-margin">
              {t('trackingNumbers.trackingNumberTypes.voluminous').toUpperCase()}
            </Badge>
          ) : '-'}
        </td>
        <td className="desktop-col text-sm to-center text-break" >
          {item.email}
        </td>
        <td className="desktop-col text-sm to-center text-break" >
          {normalizeText(item.shipping_method)}
        </td>
      </>
    )
  }

  renderMobileRowContent = (item) => {
    const { t } = this.props;
    return (
      <td className="mobile-col" colSpan={NUMBER_OF_COL - 2}>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex flex-column mr-1">
            <div className="d-flex flex-row align-items-start">
              <div className="mr-1 d-flex flex-column align-items-start">
                {item.order_id.split('-').map((x, index) => (
                  <div className="d-flex flex-row font-weight-bold ecom-mobile-order-text" key={index}>{x}</div>
                ))}
              </div>
              {item.has_voluminous && (
                <Badge
                  className="mr-1"
                  variant="danger"
                  pill
                  title={_.capitalize(t('trackingNumbers.trackingNumberTypes.voluminous'))}
                >
                  !
                </Badge>
              )}
              <Badge className="mr-1" variant="secondary">{item.alleys.join(', ') || '-'}</Badge>
              <Badge className="mr-1" variant="yellow" pill>{item.count}</Badge>
              {item.recommended_box.box_name !== '-' &&
                <Badge className="d-flex align-items-center" variant="info" pill>
                  <img className="img-icon mr-1" alt="lbox" src="/icons/package_variant_closed.svg" />
                  {item.recommended_box.box_name}
                </Badge>
              }
            </div>
            <div className="ecom-mobile-order-text">
              {dateFormat(item.order_date, "dd/mm/yyyy HH:MM:ss")}
            </div>
            <div className="text-muted ecom-mobile-order-text">
              {item.email}
            </div>
            <div className="d-flex flex-row flex-wrap">
              {item.sports.map((sport, index) => <Badge key={index} variant="secondary">{sport}</Badge>)}
            </div>
          </div>
          <div className="d-flex flex-column align-items-end">
            <Badge
              className="mb-1"
              variant={this.getStatusBadgeColor(item)}
            >
              {item.status}
            </Badge>
            <Badge className="ecom-breakable-badge text-break" variant="custom-light">{normalizeText(item.shipping_method)}</Badge>
          </div>
        </div>
      </td>
    )
  }

  updateInlineFilter = (event, field) => {
    const prevVal = this.props.filters[field];
    const { checked, value } = event.currentTarget;
    if (checked) {
      this.updateFilter({
        [field]: [
          ...prevVal,
          value,
        ],
      });
    } else {
      this.updateFilter({
        [field]: prevVal.filter(x => x !== value),
      });
    }
  }

  renderInlineFilters = () => {
    const { shippingMethods, tmporders } = this.state;
    const { states: statesFilter, shipping_methods: shippingMethodsFilter } = this.props.filters;
    const todoCount = tmporders.filter(x => x.status === TODO_STATUS).length;
    const inProgressCount = tmporders.filter(x => x.status === IN_PROGRESS_STATUS).length;
    const todoChecked = statesFilter.includes(TODO_STATUS);
    const inProgressChecked = statesFilter.includes(IN_PROGRESS_STATUS);

    const smState = {};
    for (const sm of shippingMethods) {
      smState[sm] = {
        count: tmporders.filter(x => x.shipping_method === sm).length,
        checked: shippingMethodsFilter.includes(sm),
      };
    }

    return (
      <div className="ecom-orders-status-tags">
        <InlineFilterTag
          value={TODO_STATUS}
          text={TODO_STATUS}
          count={todoCount}
          checked={todoChecked}
          onChange={(e) => this.updateInlineFilter(e, 'states')}
        />
        <InlineFilterTag
          value={IN_PROGRESS_STATUS}
          text={IN_PROGRESS_STATUS}
          count={inProgressCount}
          checked={inProgressChecked}
          onChange={(e) => this.updateInlineFilter(e, 'states')}
        />

        {Object.keys(smState).map((sm) => {
          const { count, checked } = smState[sm];
          const text = normalizeText(sm);
          return (
            <InlineFilterTag
              key={sm}
              value={sm}
              text={text}
              count={count}
              checked={checked}
              onChange={(e) => this.updateInlineFilter(e, 'shipping_methods')}
            />
          );
        })}
      </div>
    );
  }

  //display all orders
  renderOrder = (myArray) => {
    if (this.state.loading) {
      return (
        <tr>
          <td colSpan={NUMBER_OF_COL}>
            <Loader />
          </td>
        </tr>
      );
    }

    if (this.state.decaShopIsActive) {
      const isDetailsLoading = this.state.showDiv && !this.state.order_details;
      return myArray.map((item, index) => {
        return (
          <Fragment key={index}>
            <tr onClick={() => this.handleOrderClick(item.order_id)}>
              <td className="to-center text-center">
                <div>
                  <input
                    id={item.order_id}
                    type="checkbox"
                    onChange={(e) => this.handleCheck(e)}
                    checked={!!item.isChecked}
                    disabled={!item.can_pick}
                  />
                </div>
              </td>
              {this.renderDesktopRowContent(item)}
              {this.renderMobileRowContent(item)}
              <td className="to-center">
                <button
                  type="button"
                  id="pickingButton"
                  className={this.setClassName(
                    item.status,
                    item.can_pick
                  )}
                  disabled={!item.can_pick && item.status !== "In progress"}
                  onClick={e => this.handleAction(e, item)}
                >
                  {this.getActionLabel(item)}
                </button>
              </td>
            </tr>
            <tr
              className={this.props.showImages && (!this.state.showDiv || this.state.order_id !== item.order_id) ? "" : "hidden"}
              onClick={() => this.handleOrderClick(item.order_id)}
            >
              <td colSpan={NUMBER_OF_COL} className="ecom-orders-table-thumbs">
                {item.items.length ? (
                  <div className="ecom-orders-table-thumbs-content">
                    <ImageThumbs
                      value={item.items.map((item) => {
                        if (item.pixlId) return `https://contents.mediadecathlon.com/p${item.pixlId}/a.jpg?f=100x100`;
                        return defaultImage;
                      })}
                      size={50}
                      qty={6}
                    />
                  </div>
                ) : null}
              </td>
            </tr>
            <tr className={this.state.showDiv ? "" : "hidden"}>
              <td
                colSpan={NUMBER_OF_COL}
                id={item.order_id}
                className={
                  this.state.order_id === item.order_id ? "" : "hidden"
                }
              >
                {isDetailsLoading ? <Loader /> : (
                  <EcomOrderDetails
                    order_id={this.state.order_id}
                    order_details={this.state.order_details}
                    email={this.props.user.email}
                    onImageClick={this.onImageClick}
                    onClose={() => {
                      this.setState({ showDiv: !this.state.showDiv });
                    }}
                  />
                )}
              </td>
            </tr>
          </Fragment>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={NUMBER_OF_COL} className="noDecaShop">
            {myArray[0].msg}
          </td>
        </tr>
      );
    }
  };

  render() {
    const { t } = this.props;
    const allCheckedOrIntermidiate = this.state.checkedOrd.length > 0;
    const filtered = this.props.hasFilters;

    return (
      <WithSidebar
        user={this.props.user}
        updateTokens={this.props.updateTokens}
        logOut={this.props.logOut}
      >
        <div className="jumbotron container full-width-container">
          <div className="ordersList">
            <div className="ecom-orders-header">
              <h2 className="text-center">
                {t("shopOrders.orderList", {
                  defaultValue: "E-commerce order list : {{count}} orders",
                  count: this.state.decaShopIsActive
                    ? this.state.orders.length
                    : 0,
                })}
              </h2>
              <div className="ecom-orders-header-actions">
                <button
                  type="button"
                  id="buttonRefreshe"
                  className="vtmn-btn vtmn-btn_size--small vtmn-btn_variant--secondary vtmn-btn_with-only-icon "
                  onClick={() => this.massPicking(this.state.checkedOrd)}
                  disabled={this.state.loading || !allCheckedOrIntermidiate}
                >

                  <Trans i18nKey="shopOrders.pickFromReserve">Pick from reserve</Trans>
                  {allCheckedOrIntermidiate ? ` (${this.state.checkedOrd.length})` : null}
                </button>
                <button
                  type="button"
                  id="buttonShowImages"
                  className="vtmn-btn vtmn-btn_size--small vtmn-btn_variant--secondary vtmn-btn_with-only-icon "
                  onClick={() => this.toggleShowImages()}
                  disabled={!this.state.orders.length}
                >
                  <img
                    className="img-icon"
                    alt="showImages"
                    src={this.props.showImages ? '/icons/image_off_outline.svg' : '/icons/image_outline.svg'}
                  />
                </button>
                <button
                  type="button"
                  id="buttonRefreshe"
                  className="vtmn-btn vtmn-btn_size--small vtmn-btn_variant--secondary vtmn-btn_with-only-icon "
                  onClick={() => this.refrechComponent()}
                  disabled={this.state.loading}
                >
                  <i className="vtmn-icon_exchange_return " alt="search"></i>
                </button>
                <button
                  className={`vtmn-btn vtmn-btn_size--small vtmn-btn_variant--secondary vtmn-btn_with-only-icon ${filtered ? 'ecom-filtered-button' : ''}`}
                  disabled={this.state.loading}
                  onClick={() => this.setState({ filtersModalShow: true })}
                >
                  <i className="vtmn-icon_filter2 font-weight-bold m-0" />
                  {filtered && (<i className="vtmn-icon_tiny_bold_valid subicon ml-2"></i>)}
                </button>
                <MobileSort
                  sort={this.props.sort}
                  updateSort={this.updateSort}
                  getSortIcon={this.getSortIcon}
                />
              </div>
            </div>
            {this.renderInlineFilters()}
            <table className="ecom-orders-table data-table table mt-4">
              <thead>
                {this.renderDesktopHeader({
                  allCheckedOrIntermidiate,
                })}
                {this.renderMobileHeader({
                  allCheckedOrIntermidiate,
                })}
              </thead>
              <tbody>
                {this.renderOrder(this.state.orders)}
              </tbody>
            </table>
          </div>
        </div>
        <ImageModal
          pixlId={this.state.imageModalData.pixlId}
          item={this.state.imageModalData.item}
          item_description={this.state.imageModalData.item_description}
        />

        {this.state.error && isWrongPicker(this.state.error) ? (
          <WrongPickerDialog
            wrongPicker={this.state.error.response.current_picker}
            orderId={this.state.order_id}
            onConfirm={() => {
              this.setState({ error: null });
              return this.handlePick(this.state.order_id, true)
            }}
            onClose={() => this.setState({ error: null })}
          />
        ) : (
          <ErrorDialog
            error={this.state.error}
            setError={() => this.setState({ error: null })}
          />
        )}
        {this.state.wrongPickers ? (
          <MassWrongPickerDialog
            wrongPickers={this.state.wrongPickers}
            onConfirm={() => {
              this.setState({ wrongPickers: null, wrongPickerOrders: null });
              return this.massPicking(this.state.wrongPickerOrders, true);
            }}
            onClose={() => this.setState({
              wrongPickers: null,
              wrongPickerOrders: null,
              massResults: {
                ...this.state.massResults,
                failed: [
                  ...this.state.massResults.failed,
                  ...this.state.wrongPickerOrders.map(order_id => ({ order_id })),
                ],
              },
            })}
          />
        ) : (
          <MassResultsDialog
            massResults={this.state.massResults}
            massProgress={this.state.massProgress}
            onStop={() => this.setState({ massProgress: null })}
            onClose={() => this.setState({ massResults: null })}
          />
        )}
        <MobileFiltersModal
          show={this.state.filtersModalShow}
          onClose={() => this.setState({ filtersModalShow: false })}
          onClear={this.onClearFilters}
          onReset={this.onResetFilters}
          sport={this.state.sports}
          alleys={this.state.alleys}
          teammates={this.state.teammates}
          shippingMethods={this.state.shippingMethods}
          customersName={this.state.customersName}
          boxes={this.state.boxes}
          filters={this.props.filters}
          updateFilter={this.updateFilter}
        />
        <Confirm
          show={!!this.state.repickingOrder}
          onHide={()=>this.setState({repickingOrder:null})}
          onConfirm={() => {
            const { order_id } = this.state.repickingOrder
            this.repickOrder(order_id);
            this.setState({ repickingOrder: null });
          }}
          title={<Trans i18nKey="shopOrders.repick">Re-pick</Trans>}
          icon={<img className="img-icon mr-1" alt="re-take" src={'/icons/account_switch.svg'} />}
          body={t('shopOrders.repickDialog', {
            defaultValue: 'Do you want to unassign this order from {{unassignUser}} and put it to your picking list?',
            unassignUser: this.state.repickingOrder?.email
          })}
          buttonOpts={{
            variant: 'danger',
            icon: <img className="img-icon mr-1" alt="re-take" src={'/icons/account_switch_white.svg'} />,
            text: <Trans i18nKey="shopOrders.repick">Re-pick</Trans>
          }}
        />

      </WithSidebar>
    );
  }
}

const EcomOrdersWithState = (props) => {
  const {
    filters,
    hasFilters,
    sort,
    showImages,
    setFilters,
    setSort,
    setShowImages,
    resetFilters,
    clearFilters,
  } = useEcomOrdersState();
  return (
    <EcomOrders
      filters={filters}
      hasFilters={hasFilters}
      sort={sort}
      showImages={showImages}
      setFilters={setFilters}
      setSort={setSort}
      setShowImages={setShowImages}
      resetFilters={resetFilters}
      clearFilters={clearFilters}
      {...props}
    />
  );
}

export default withTranslation()(EcomOrdersWithState);
