import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Button, Row } from 'react-bootstrap';
import WithSidebar from '../WithSidebar';
import Loader from '../../components/Loader';
import useRequest from '../../functions/useRequest';
import useIsMounted from '../../functions/useIsMounted';
import {
  getTrackingNumberData,
  getTrackingNumberDuration,
  assignOrderItem,
  saveUnexpectedItem,
  saveMissing,
  exportTrackingNumber,
  encodeEpc,
  findNextItem,
  foundMissing,
  findUnexpectedByItem,
  unexpectedMatchMissing,
  lockItem,
  unlockItem,
  finishTrackingNumber,
} from './api';
import useTrackingNumberState from './state';
import { useTrackingNumberReduxState } from './reduxState';
import SearchByItem from './SearchByItem';
import OrdersContent from './OrdersContent';
import ActionModal from './ActionModal';
import ConfirmMissing from './ConfirmMissing';
import TrackingNumberComments from './TrackingNumberComments';
import ScanUnexpectedModal from './ScanUnexpectedModal';
import OrderComments from '../Orders/OrderComments';
import ErrorMessage from '../../components/widgets/errorMessage';
import ImageReactModal from '../../components/widgets/ImageReactModal';
import ExportButton from '../../components/widgets/ExportButton';
import ButtonBadge from '../../components/widgets/ButtonBadge';
import BarcodeModal from '../../components/widgets/BarcodeModal';
import ProblemMissing from './ReviewProblems/Missing';
import ProblemUnexpected from './ReviewProblems/Unexpected';
import { PreferAppMessage } from './messages';
import '../../stylesheets/trackingNumber.css';
import PrinterSummaryDialog from './PrinterSummaryDialog';

const useTrakingNumberDuration = ({
  store,
  trackingNumber,
  trackingNumberStatus,
  user,
  updateTokens,
}) => {
  const [{
    loading,
    error,
    data,
  }, fetchTrackingNumberDuration] = useRequest(getTrackingNumberDuration);
  useEffect(() => {
    if (trackingNumberStatus === 'finished') {
      fetchTrackingNumberDuration({ store, trackingNumber, user, updateTokens });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, trackingNumber, trackingNumberStatus]);
  return {
    loading,
    error,
    data,
  };
};

const TrackingNumber = ({ user, updateTokens, logOut }) => {
  const { state, stateActions } = useTrackingNumberState();
  const [trackingCommentsOpen, setTrackingCommentsOpen] = useState(false);
  const [orderCommentsOpen, setOrderCommentsOpen] = useState(false);
  const [problemMissingOpened, setProblemMissingOpened] = useState(false);
  const [problemUnexpectedOpened, setProblemUnexpectedOpened] = useState(false);
  const [scanUnexpectedModalShow, setScanUnexpectedModalShow] = useState(false);
  const [barcodePreview, setBarcodePreview] = useState(null);
  const [orderSGToPrint, setOrderSGToPrint] = useState(null);
  const {
    searchItem,
    modalError,
    actionOpened,
    fromItemSearch,
    missingOpened,
    previewOpened,
    selectedSGItem,
    lockedOptions,
    otherTrackingOptions,
    trackingNumberData,
    unexpectedMatches,
    epc,
    latestAddress,
  } = state;
  const {
    setSearchItem,
    setModalError,
    setActionOpened,
    setMissingOpened,
    setPreviewOpened,
    setLockedOptions,
    setOtherTrackingOptions,
    setSelectedSGItem,
    patchSelectedSGItem,
    setTrackingNumberData,
    setUnexpectedMatches,
    setEpc,
    setLatestAddress,
  } = stateActions;
  const { activeTab, setActiveTab } = useTrackingNumberReduxState();
  const { store, trackingNumber } = useParams();
  const isMounted = useIsMounted();
  const history = useHistory();
  const onBack = () => {
    if (isMounted()) {
      history.goBack();
    }
  };

  const [{
    loading: locking,
  }, doLockItem] = useRequest(lockItem, {
    onSuccess: () => {
      setModalError(null);
      patchSelectedSGItem({ lockedBy: user.uid });
    },
    onError: e => {
      setModalError(e);
      if (e.response?.lockedBy) {
        patchSelectedSGItem({ lockedBy: e.response?.lockedBy });
      }
    }
  });
  const [{
    loading: unlocking,
    error: unlockError,
  }, doUnlockItem] = useRequest(unlockItem);

  const [{
    loading: sgLoading,
    error: sgError,
  }, fetchTrackingNumberData] = useRequest(getTrackingNumberData, {
    onSuccess: setTrackingNumberData,
    onError: () => setTrackingNumberData(null),
  });

  const [{
    loading: loadingUnexpected,
  }, fetchUnexpectedByItem] = useRequest(findUnexpectedByItem, {
    onSuccess: setUnexpectedMatches,
    onError: (e) => {
      setUnexpectedMatches(null);
      setModalError(e);
    },
  });

  const { status, countComments } = trackingNumberData || {};
  const memoizedExport = useMemo(() => exportTrackingNumber(store, trackingNumber), [store, trackingNumber]);

  const reloadOrderItems = () => fetchTrackingNumberData({ store, trackingNumber, user, updateTokens });
  useEffect(() => {
    fetchTrackingNumberData({ store, trackingNumber, resetCache: true, user, updateTokens });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, trackingNumber]);

  const [{
    loading: finishing,
    error: finishError,
  }, doFinish] = useRequest(finishTrackingNumber, {
    onSuccess: reloadOrderItems,
  });

  const [{
    loading: encodingEpc,
  }, doEncodeEpc] = useRequest(encodeEpc, {
    onError: setModalError,
  });

  const [{
    loading: searching,
  }, doFindNextItem] = useRequest(findNextItem, {
    onError: setModalError,
    onSuccess: ({
      orderItem,
      lockedOptions,
      otherTrackingOptions,
    }) => {
      setModalError(null);
      setLockedOptions(lockedOptions);
      setOtherTrackingOptions(otherTrackingOptions);
      setSelectedSGItem(orderItem);
      setActionOpened(true, true);
    }
  });

  const loading = sgLoading || unlocking || finishing;
  const error = sgError || unlockError || finishError;
  const isFinished = status === 'finished';
  const onSearchHandler = async (item) => {
    setActionOpened(true, true);
    const isSgtin = item && item.length === 30;
    const isEan = item && item.length === 13;
    const params = {
      store,
      trackingNumber,
      item,
      user,
      updateTokens,
    };
    if (isEan) {
      params.ean = item;
      setEpc(null);
    } else if (isSgtin) {
      params.epc = await doEncodeEpc({
        sgtin: item,
        user,
        updateTokens,
      });
      setEpc(params.epc);
    } else {
      params.item_id = item;
      setEpc(null);
    }
    return doFindNextItem(params);
  };
  const doUnlockItemHandler = () => {
    if (selectedSGItem?.id) {
      doUnlockItem({
        store,
        tracking_number: trackingNumber,
        tracking_number_item_id: selectedSGItem.id,
        user,
        updateTokens,
      });
    }
  };
  const doForceLockItemHandler = (orderItem) => doLockItem({
    store,
    tracking_number: trackingNumber,
    tracking_number_item_id: orderItem?.id,
    force: true,
    user,
    updateTokens,
  });

  const duration = useTrakingNumberDuration({
    store,
    trackingNumber,
    trackingNumberStatus: trackingNumberData?.status,
    user,
    updateTokens,
  });

  return (
    <WithSidebar user={user} updateTokens={updateTokens} logOut={logOut}>
      <div className="jumbotron container tracking-number">
        <h2 className="text-center">
          <Trans i18nKey="trackingNumber.trackingNumber">Tracking number</Trans>
        </h2>
        <h5 className="text-center">{trackingNumber}</h5>
        <PreferAppMessage />
        <Row className="button-row">
          <Button
            variant="primary"
            onClick={onBack}
          >
            <Trans i18nKey="shared.back">Back</Trans>
          </Button>
          <div className="flex-fill"/>
          <Button
            variant="primary"
            onClick={() => setTrackingCommentsOpen(true)}
          >
            <Trans i18nKey="comments.comments">Comments</Trans>
            {!loading && countComments ? <ButtonBadge value={countComments} /> : null}
          </Button>
          <ExportButton
            user={user}
            updateTokens={updateTokens}
            exportReport={memoizedExport}
            size="md"
          />
        </Row>
        <SearchByItem
          item={searchItem}
          setItem={setSearchItem}
          onSearch={onSearchHandler}
        />
        {loading && <Loader/>}
        {!loading && error && <ErrorMessage error={error} />}
        {!loading && !error && trackingNumberData && (
          <OrdersContent
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isFinished={isFinished}
            trackingNumberData={trackingNumberData}
            duration={duration}
            onAssign={(orderItem) => {
              setSelectedSGItem(orderItem);
              setEpc(null);
              setActionOpened(true, false);
              doLockItem({
                store,
                tracking_number: trackingNumber,
                tracking_number_item_id: orderItem?.id,
                force: false,
                user,
                updateTokens,
              });
            }}
            onMissing={(orderItem) => {
              setSelectedSGItem(orderItem);
              setEpc(null);
              setMissingOpened(true);
              fetchUnexpectedByItem({
                store,
                item_id: orderItem?.item.item_id,
                user,
                updateTokens,
              });
              doLockItem({
                store,
                tracking_number: trackingNumber,
                tracking_number_item_id: orderItem?.id,
                force: false,
                user,
                updateTokens,
              });
            }}
            onPreview={(orderItem) => {
              setSelectedSGItem(orderItem);
              setPreviewOpened(true);
            }}
            onFound={(orderItem) => {
              setSelectedSGItem(orderItem);
              setActionOpened(true, false);
              setEpc(null);
              doLockItem({
                store,
                tracking_number: trackingNumber,
                tracking_number_item_id: orderItem?.id,
                force: false,
                user,
                updateTokens,
              });
            }}
            onUnexpectedItem={(orderItem) => {
              const isCanceled = orderItem?.shipping_group?.is_canceled;
              const isCompleted = orderItem?.shipping_group?.is_completed;
              const isWrongStore = orderItem?.shipping_group?.is_wrong_store;
              const isUnexpectedOrder = isCanceled || isCompleted || isWrongStore;
              setSelectedSGItem(orderItem);
              setActionOpened(true, false);
              setEpc(null);
              if (isUnexpectedOrder) {
                doLockItem({
                  store,
                  tracking_number: trackingNumber,
                  tracking_number_item_id: orderItem?.id,
                  force: false,
                  user,
                  updateTokens,
                });
              }
            }}
            onUnexpectedButton={() => {
              setScanUnexpectedModalShow(true);
            }}
            onFinishButton={() => {
              doFinish({
                store,
                trackingNumber,
                user,
                updateTokens,
              });
            }}
            onReviewMissing={(orderItem) => {
              setSelectedSGItem(orderItem);
              setEpc(null);
              setProblemMissingOpened(true);
            }}
            onReviewUnexpected={(orderItem) => {
              setSelectedSGItem(orderItem);
              setEpc(null);
              setProblemUnexpectedOpened(true);
            }}
            onOrderComments={(orderItem) => {
              setSelectedSGItem(orderItem);
              setEpc(null);
              setOrderCommentsOpen(true);
            }}
            onPreviewBarcode={setBarcodePreview}
            onPrintSummarySheet={setOrderSGToPrint}
          />
        )}
      </div>
      <ActionModal
        user={user}
        updateTokens={updateTokens}
        modalLoading={searching || encodingEpc || locking}
        modalError={modalError}
        show={actionOpened}
        fromItemSearch={fromItemSearch}
        onClose={() => {
          setActionOpened(false, false);
          setSelectedSGItem(null);
          setEpc(null);
          setModalError(null);
          setLockedOptions([]);
          setOtherTrackingOptions([]);
        }}
        orderItem={selectedSGItem}
        reloadOrderItems={reloadOrderItems}
        assignOrderItem={({
          address,
          is_tomount,
          force_same_item_to_address,
        }) => assignOrderItem({
          store,
          tracking_number: trackingNumber,
          order_number: selectedSGItem?.order?.order_number,
          shipping_group_number: selectedSGItem?.shipping_group?.shipping_group_number,
          shipping_method: selectedSGItem?.shipping_group?.shippingMethod,
          tracking_number_item_id: selectedSGItem?.id,
          shipperbox_number: selectedSGItem?.shipperbox_number,
          address,
          item_id: selectedSGItem?.item.item_id,
          epc,
          is_tomount,
          force_same_item_to_address,
          user,
          updateTokens,
        })}
        saveUnexpectedItem={() => {
          const isCanceled = selectedSGItem?.shipping_group?.is_canceled;
          const isCompleted = selectedSGItem?.shipping_group?.is_completed;
          const isWrongStore = selectedSGItem?.shipping_group?.is_wrong_store;
          const isUnexpectedOrder = isCanceled || isCompleted || isWrongStore;
          return saveUnexpectedItem({
            store,
            tracking_number: trackingNumber,
            order_number: isUnexpectedOrder ? selectedSGItem?.order?.order_number : null,
            shipping_group_number: isUnexpectedOrder ? selectedSGItem?.shipping_group?.shipping_group_number : null,
            shipping_method: isUnexpectedOrder ? selectedSGItem?.shipping_group?.shippingMethod : null,
            tracking_number_item_id: isUnexpectedOrder ? selectedSGItem?.id : null,
            item_id: selectedSGItem?.item.item_id,
            epc,
            missing_id: selectedSGItem?.missing?.id,
            do_back_to_sale: false,
            user,
            updateTokens,
          })
        }}
        backToSaleUnexpectedItem={() => saveUnexpectedItem({
          store,
          tracking_number: trackingNumber,
          order_number: selectedSGItem?.order?.order_number,
          shipping_group_number: selectedSGItem?.shipping_group?.shipping_group_number,
          shipping_method: selectedSGItem?.shipping_group?.shippingMethod,
          tracking_number_item_id: selectedSGItem?.id,
          item_id: selectedSGItem?.item.item_id,
          epc,
          missing_id: selectedSGItem?.missing?.id,
          do_back_to_sale: true,
          user,
          updateTokens,
        })}
        foundMissing={({
          address,
          is_tomount,
          force_same_item_to_address,
        }) => foundMissing({
          store,
          id: selectedSGItem?.missing?.id,
          epc,
          tracking_number: trackingNumber,
          address,
          is_tomount,
          force_same_item_to_address,
          shipperbox_number: selectedSGItem?.shipperbox_number,
          user,
          updateTokens,
        })}
        doUnlockItem={doUnlockItemHandler}
        doForceLockItem={doForceLockItemHandler}
        lockedOptions={lockedOptions}
        otherTrackingOptions={otherTrackingOptions}
        setLockedOptions={setLockedOptions}
        setOtherTrackingOptions={setOtherTrackingOptions}
        setSelectedSGItem={setSelectedSGItem}
        setBarcodePreview={setBarcodePreview}
        setEpc={setEpc}
        latestAddress={latestAddress}
        setLatestAddress={setLatestAddress}
      />
      <ConfirmMissing
        store={store}
        user={user}
        updateTokens={updateTokens}
        uid={user.uid}
        modalLoading={loadingUnexpected || locking}
        modalError={modalError}
        show={missingOpened}
        orderItem={selectedSGItem}
        unexpectedMatches={unexpectedMatches}
        setUnexpectedMatches={setUnexpectedMatches}
        onClose={() => {
          setSelectedSGItem(null);
          setEpc(null);
          setModalError(null);
          setMissingOpened(false);
        }}
        reloadOrderItems={reloadOrderItems}
        saveMissing={() => saveMissing({
          store,
          tracking_number: trackingNumber,
          order_number: selectedSGItem?.order?.order_number,
          shipping_group_number: selectedSGItem?.shipping_group?.shipping_group_number,
          shipping_method: selectedSGItem?.shipping_group?.shippingMethod,
          tracking_number_item_id: selectedSGItem?.id,
          item_id:  selectedSGItem?.item.item_id,
          user,
          updateTokens,
        })}
        unexpectedMatchMissing={({ id, address, is_tomount, force_same_item_to_address }) => unexpectedMatchMissing({
          store,
          id,
          tracking_number: trackingNumber,
          order_number: selectedSGItem?.order?.order_number,
          shipping_group_number: selectedSGItem?.shipping_group?.shipping_group_number,
          shipping_method: selectedSGItem?.shipping_group?.shippingMethod,
          item_id:  selectedSGItem?.item.item_id,
          epc,
          tracking_number_item_id: selectedSGItem?.id,
          shipperbox_number: selectedSGItem?.shipperbox_number,
          address,
          is_tomount,
          force_same_item_to_address,
          user,
          updateTokens,
        })}
        doUnlockItem={doUnlockItemHandler}
        doForceLockItem={doForceLockItemHandler}
        setBarcodePreview={setBarcodePreview}
        setEpc={setEpc}
      />
      <ProblemMissing
        id={problemMissingOpened && selectedSGItem?.missing?.id}
        user={user}
        updateTokens={updateTokens}
        onClose={() => {
          setProblemMissingOpened(false);
          setSelectedSGItem(null);
          setEpc(null);
          reloadOrderItems();
        }}
        setEpc={setEpc}
        epc={epc}
        shipperboxNumber={selectedSGItem?.shipperbox_number}
      />
      <ProblemUnexpected
        id={problemUnexpectedOpened && selectedSGItem?.unexpected?.id}
        user={user}
        updateTokens={updateTokens}
        onClose={() => {
          setProblemUnexpectedOpened(false);
          setSelectedSGItem(null);
          setEpc(null);
          reloadOrderItems();
        }}
        setEpc={setEpc}
        epc={epc}
      />
      <ImageReactModal
        show={previewOpened}
        onHide={() => setPreviewOpened(false)}
        imageUrl={selectedSGItem?.item.image}
        item={selectedSGItem?.item.item_id}
        item_description={selectedSGItem?.item.item_description}
      />
      <TrackingNumberComments
        isOpen={trackingCommentsOpen}
        onClose={() => {
          setTrackingCommentsOpen(false);
          reloadOrderItems();
        }}
        store={store}
        trackingNumber={trackingNumber}
        user={user}
        updateTokens={updateTokens}
      />
      <OrderComments
        isOpen={orderCommentsOpen}
        onClose={() => {
          setSelectedSGItem(null);
          setOrderCommentsOpen(false);
          reloadOrderItems();
        }}
        store={store}
        orderNumber={selectedSGItem?.order?.order_number}
      />
      <ScanUnexpectedModal
        show={scanUnexpectedModalShow}
        onClose={() => setScanUnexpectedModalShow(false)}
        onConfirm={(value) => {
          setScanUnexpectedModalShow(false);
          onSearchHandler(value);
        }}
      />
      <BarcodeModal
        code={barcodePreview}
        show={!!barcodePreview}
        onHide={() => setBarcodePreview(null)}
      />
      <PrinterSummaryDialog
        show={!!orderSGToPrint}
        shippingGroup={orderSGToPrint}
        trackingNumber={trackingNumber}
        onHide={() => setOrderSGToPrint(null)}
      />
    </WithSidebar>
  );
};

export default TrackingNumber;
