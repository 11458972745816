const zeroPad2 = (val) => {
  if (val > 9) {
    return `${val}`;
  }
  return `00${val || 0}`.slice(-2);
};

export const formatDurationSec = (sec) => {
  let seconds = Math.floor(sec);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  seconds = seconds % 60;
  seconds = zeroPad2(seconds);
  minutes = zeroPad2(minutes);
  hours = zeroPad2(hours);
  const duration = `${hours}:${minutes}:${seconds}`;
  return duration;
};
