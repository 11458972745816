import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Badge, Row, Col, Form } from 'react-bootstrap';
import _ from 'lodash';
import dayjs from 'dayjs';

import useRequest from '../../functions/useRequest';
import { normalizeName } from '../../functions/formatText';
import { logEvent } from '../../functions/analytics';
import WithSidebar from '../WithSidebar';
import ImageModal from '../../components/widgets/ImageModal';
import ErrorDialog from '../../components/widgets/ErrorDialog';
import Confirm from '../../components/widgets/Confirm';
import Loader from '../../components/Loader';
import Tabs from '../../components/widgets/Tabs';
import ErrorMessage from '../../components/widgets/errorMessage';
import ButtonBadge from '../../components/widgets/ButtonBadge';
import ItemListGroup from '../../components/widgets/ItemListGroup';
import { useEcomOrdersState } from '../EcomOrders/state';
import EcomTrolleyContainer from '../EcomTrolleyContainer';
import { RecommendedBoxes } from '../EcomTrolleyContainer/components';
import { handleFinalizeRes, isOrderAddressed } from '../EcomTrolleyContainer/helpers';
import PickConfirmModal from './PickConfirmModal';
import CantPickDialog from "./CantPickDialog";
import {
  getPickingLists,
  getCurrentTrolley,
  getRecommendedBoxes,
  getPackCount,
  getItemDetails,
  deletePickingRow,
  cantPickPickingList,
  pickPickingList,
  getBoxes
} from './api';
import { useEcomPickingListState } from './state';

import '../../stylesheets/ecomPickingList.css';
import defaultImage from '../../images/default_image.png';

const groupItems = (items, path) => {
  if (!items || !path) return {};
  return _.groupBy(items, x => {
    const val = _.get(x, path);
    return _.isNil(val) ? undefined : val;
  })
}

const resolvePickingOrderStatus = (order, trolleyStatus) => {
  const isAddressed = isOrderAddressed(order)
  const boxStatus = order.status
  const orderStatus = order.order_status
  const finalizeStatus = order.finalize_status

  // special cases
  if (boxStatus === 'cancelled' || orderStatus === 'Cancelled') return 'cancelled'
  if (orderStatus === 'Wait') return 'wait'
  if (boxStatus === 'started') return 'inProgress'
  // flow
  if (isAddressed) return 'addressed'
  // ready to address only for C&C orders
  if (order.can_address && !order.is_carrier_package) return 'readyToAddress'

  if (finalizeStatus) {
    if (finalizeStatus === 'error') return 'error'
    if (finalizeStatus === 'finalized' || finalizeStatus === 'skipped') return 'picked'
    if (finalizeStatus === 'cancelled') return 'cancelled'
  }
  if (trolleyStatus === 'started' && !finalizeStatus) {
    return 'inProgress'
  }

  return null
}

const EcomPickingList = (props) => {
  const { user, updateTokens, logOut } = props;
  const { store } = useParams();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState('pickingList'); // 'pickingList' | 'trolleySummary'
  const [locations, setLocations] = useState([]);
  const [groupedBy, setGroupedBy] = useState(null);
  const [dialog, setDialog] = useState(null); // null | 'confirmDelete' | 'pickConfirm' | 'cantPick'
  const [selectedPl, setSelectedPl] = useState(null);
  const [imageModalData, setImageModalData] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  const [{ loading, data }, fetchPickingLists] = useRequest(getPickingLists, {
    onError: setError,
  });

  const [{ loading: isTrolleyLoading, data: trolley }, fetchTrolley] = useRequest(getCurrentTrolley, {
    onError: setError,
  });

  const [{ loading: isItemsInfoLoading, data: itemInfos }, fetchItemsInfo] = useRequest(getItemDetails, {
    onError: setError,
  });

  const [{ data: recommendedBoxes }, fetchRecommendedBoxes] = useRequest(getRecommendedBoxes, {
    onError: setError,
  });

  const [{ data: boxes, loading: isBoxesLoading }, fetchBoxes] = useRequest(getBoxes, {
    onError: setError,
  });

  const [{ data: packCount }, fetchPackCount] = useRequest(getPackCount, {
    onError: setError,
  });

  const isLoading = loading || isProcessing;

  const {
    pickingListCount,
    trolleyBoxCount,
    failedActions,
    removeFailedActionsByOrder,
    setPickingListCount,
    setTrolleyBoxCount,
    setFailedAction,
  } = useEcomPickingListState();
  const { resetFilters: resetOrdersFilters } = useEcomOrdersState();

  const groupedItems = groupItems(data, groupedBy);
  const hasWarehouse = locations.includes('warehouse');
  const hasFailedPickActions = failedActions?.some(x => x.action === 'pick') || false

  const refetchPl = async () => {
    const pl = await fetchPickingLists({ store, user, updateTokens });
    const plByLocation = _.groupBy(pl, 'location_type');
    const newLocations = Object.keys(plByLocation).filter(l => l !== 'undefined');
    const newGroupedBy = newLocations.length === 1 ? (newLocations[0] === 'store' ? 'department_label' : 'alley') : null;
    const newCount = pl?.length || 0;
    setLocations(newLocations);
    setGroupedBy(newGroupedBy);
    setPickingListCount(newCount);
    if (newCount === 0) setActiveTab('trolleySummary');
    return pl;
  };

  const refetchTrolley = async () => {
    const t = await fetchTrolley({ store, user, updateTokens });
    setTrolleyBoxCount(t?.orders.length || 0);
    if (t?.item_ids.length) await fetchItemsInfo({ store, items: t?.item_ids, endOfLife: true, user, updateTokens });
    return t;
  };

  const doDeletePickingRow = async (pl) => {
    try {
      setIsProcessing(true);
      const res = await deletePickingRow({
        store,
        id_ecompicking_list: pl.id_ecompicking_list,
        user,
        updateTokens,
      });
      handleFinalizeRes(res, setError, t);
      await refetchPl();
      await refetchTrolley();
    } catch (e) {
      setError(e);
    } finally {
      setIsProcessing(false);
      removeFailedActionsByOrder(pl.order_id);
      handleDialogClose();
    }
  };

  const handlePickCubeError = (order_id, error) => {
    if (!error.response.origin) return;
    setFailedAction({ action: 'pick', orderId: order_id });
    setError(new Error(error.response.origin.text || error.response.text));
    logEvent('ecom_pick_fail', {
      orderId: order_id,
      email: user?.email,
      code: error.response.origin.code,
      text: error.response.origin.text,
      failedAt: dayjs().toISOString(),
    });
    handleDialogClose();
  }

  const pickItem = async (boxNumber, package_id, qty, force) => {
    const { id_ecompicking_list, order_id } = selectedPl;
    try {
      setIsProcessing(true);
      const res = await pickPickingList({
        store,
        id_ecompicking_list,
        boxNumber,
        package_id,
        qty,
        force,
        user,
        updateTokens,
      });
      handleFinalizeRes(res, setError, t);
      await refetchPl();
      await refetchTrolley();
      resetOrdersFilters();
      removeFailedActionsByOrder(order_id);
      handleDialogClose();
    } catch (error) {
      console.log(error);
      const { code, mail, text } = error.response;
      let message;
      if (text === "Teammate unassigned") {
        message = new Error(t('ecomPickingList.unassignError', {
          defaultValue: "Teammate {{unassigner}} has removed the order from your picking list",
          unassigner: mail || "",
        }));
      } else if (code === '420' && error.response.origin) {
        handlePickCubeError(order_id, error);
        return;
      }
      setError(message || error);
    } finally {
      setIsProcessing(false);
    }
  };

  const cantPickItemConfirm = async (action, force) => {
    const { id_ecompicking_list, order_id } = selectedPl;
    try {
      setIsProcessing(true);
      const res = await cantPickPickingList({ store, id_ecompicking_list, action, force, user, updateTokens });
      handleFinalizeRes(res, setError, t);
      await refetchPl();
      await refetchTrolley();
      removeFailedActionsByOrder(order_id);
      handleDialogClose();
    } catch (error) {
      const { code } = error.response;
      if (code === '420' && error.response.origin) {
        handlePickCubeError(order_id, error);
      } else {
        setError(error);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDialogClose = () => {
    setDialog(null);
    setSelectedPl(null);
  };

  const init = async () => {
    const pl = await refetchPl();
    const t = await refetchTrolley();
    const plOrderIds = pl?.map((x) => x.order_id) || [];
    const trolleyOrderIds = t?.orders.map((x) => x.order_id) || [];
    const orderIds = _.uniq([...plOrderIds, ...trolleyOrderIds].filter(Boolean));
    if (orderIds.length) fetchRecommendedBoxes({ store, orderIds, user, updateTokens });
    fetchPackCount({ store, user, updateTokens });
    fetchBoxes({ store, user, updateTokens });
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  const tabs = [
    {
      code: "pickingList",
      label: (
        <>
          {t('pickingList.pickingList', 'Picking List')}
          {pickingListCount > 0 ? <ButtonBadge value={pickingListCount} variant="yellow" /> : null}
        </>
      ),
    },
    {
      code: "trolleySummary",
      label: (
        <>
          {t('trolley.summary', 'Trolley')}
          {trolleyBoxCount > 0 ? <ButtonBadge value={trolleyBoxCount} variant="yellow" /> : null}
        </>
      ),
    },
  ];

  const renderImage = (product) => (
    <div className="ecom-picking-list-image-wrap">
      <img
        key={product.pixlId}
        src={product.pixlId ? `https://contents.mediadecathlon.com/p${product.pixlId}/a.jpg?f=100x100` : defaultImage}
        alt=""
        className="ecom-picking-list-image"
        onClick={() => {
          setImageModalData({
            endOfLife: product.end_of_life,
            pixlId: product.pixlId,
            item: product.item_code,
            item_description: product.item_description,
            price: product.price,
            currency: product.currency,
            width: product.width,
            height: product.height,
            length: product.length,
          });
          window.$("#imageModal").modal('show');
        }}
      />
    </div>
  );

  const renderTag = (name, label) => {
    const isActive = groupedBy === name;
    return (
      <span className={`tag tag-link font-weight-bold${isActive ? ' active' : ''}`} onClick={() => setGroupedBy(isActive ? null : name)}>
        {label || '-'}
      </span>
    )
  };

  const renderRow = (product) => {
    const { address, department_label, sub_department_label, family_label, location_qty } = product;
    const hasTags = department_label || sub_department_label || family_label;
    const orderFails = failedActions?.filter(x => x.orderId === product.order_id) || [];
    const hasFails = orderFails.length > 0;
    return (
      <div className="d-flex border-bottom pb-3 pt-3" key={product.id_ecompicking_list}>
        <div className="mx-1 ecom-image">
          {renderImage(product)}
          {product.price > 0 && (
            <span className="ecom-price tag tag-sm tag-accent">
              {`${product.price.toFixed(2)} ${product.currency || ''}`}
            </span>
          )}
        </div>
        <div className="d-flex flex-column justify-content-center flex-fill mx-1">
          <div className="row">
            {product.end_of_life && (
              <>
                <span className="tag tag-warning font-weight-bold">
                  {t('items.endOfLife').toUpperCase()}
                </span>
                <span className='tag-divider'>{'/'}</span>
              </>
            )}
            {product.location_type !== 'warehouse' && hasTags ? (
              <>
                {renderTag('department_label', department_label)}
                <span className='tag-divider'>{'/'}</span>
                {renderTag('sub_department_label', sub_department_label)}
                <span className='tag-divider'>{'/'}</span>
                {renderTag('family_label', family_label)}
              </>
            ) : address ? (
              <span
                className={`tag tag-link font-weight-bold${groupedBy === 'alley' ? ' active' : ''}`}
                onClick={() => setGroupedBy(groupedBy === 'alley' ? null : 'alley')}
              >
                {address}
              </span>
            ) : null}
          </div>
          <div className="row">
            <span className={`text-secondary${hasFails ? ' text-danger' : ''}`}>
              {product.item_description}
            </span>
          </div>
          <div className="row align-items-center">
            <span className="text-muted small">
              <Trans i18nKey="items.item">Item</Trans>
              {': '}
              {product.item_code}
            </span>
            <span className="tag ml-2 mb-0">
              {`${product.width} x ${product.height} x ${product.length}cm`}
            </span>
          </div>
          <div className="row">
            <span className={`small ${hasFails ? 'text-danger' : 'text-muted'}`}>
              <Trans i18nKey="ecomPickingList.order">Order</Trans>
              {': '}
              {product.order_id}
            </span>
          </div>
          <div className="row">
            {product.customer_tag ? (
              <Badge className="mr-1" variant="dark">{product.customer_tag}</Badge>
            ) : null}
            <span className="text-muted small">
              {normalizeName(product.customer_name)}
            </span>
          </div>

          <div>
            {product.is_voluminous && (
              <span className="tag tag-warning mr-1 mt-1">
                {t('trackingNumbers.trackingNumberTypes.voluminous').toUpperCase()}
              </span>
            )}
          </div>
        </div>
        <div className="ecom-picking-list-qty-col d-flex flex-column justify-content-center align-items-center mx-1">
          <div className="d-flex flex-column justify-content-center align-items-center m-1">
            <span className="text-muted text-qty text-center">
              <Trans i18nKey="ecomPickingList.toPick">To Pick</Trans>
            </span>
            <div className="d-flex align-items-center mx-1 mx-md-3">
              <Badge variant={product.qty > 1 ? 'warning' : 'custom-light'} pill>{product.qty}</Badge>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center m-1">
            <span className="text-muted text-qty text-center">
              {t('ecomPickingList.qtyInAddress', {
                defaultValue: 'Qty in {{address}}',
                address: product.address,
              })}
            </span>
            <div className="d-flex align-items-center mx-1 mx-md-3">
              <Badge variant="custom-light" className="font-weight-light" pill>{location_qty}</Badge>
            </div>
          </div>
        </div>
        <div className="mx-1">
          <div className="row ecom-picking-list-action-cell">
            <button
              className="btn btn-success btn-sm btn-block"
              onClick={() => {
                setSelectedPl(product);
                setDialog('pickConfirm');
              }}
            >
              <Trans i18nKey="shared.pick">Pick</Trans>
            </button>
            <button
              className="btn btn-sm btn-block btn-outline-danger"
              onClick={() => {
                setSelectedPl(product);
                setDialog('confirmDelete');
              }}
            >
              <i className="vtmn-icon_delete vtmn-icon-22px"></i>
            </button>
            <button
              className="btn btn-warning btn-sm btn-block"
              onClick={() => {
                setSelectedPl(product);
                setDialog('cantPick');
              }}
            >
              <Trans i18nKey="shared.cantpick">Can't Pick</Trans>
            </button>
          </div>
        </div>
      </div>
    )
  };

  const renderGroup = (name, groupItems) => {
    return (
      <ItemListGroup
        key={name}
        name={name === 'undefined' ? t('shared.noGroup', 'No group').toUpperCase() : name}
        count={groupItems.length}
      >
        {groupItems.map((item) => renderRow(item))}
      </ItemListGroup>
    )
  };

  return (
    <WithSidebar user={user} updateTokens={updateTokens} logOut={logOut}>
      <div>
        <div className="jumbotron container">
          <Tabs
            className="tracking-number-tabs"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={tabs}
          />
          {isLoading && <Loader />}
          {!isLoading && activeTab === 'pickingList' && (
            <>
              <h2 className="text-center">
                <Trans i18nKey="ecomPickingList.ecomPickingList">E-commerce Picking List</Trans>
              </h2>
              <Row className="align-items-center mb-3" noGutters>
                {hasFailedPickActions && (
                  <Col xs={12}>
                    <ErrorMessage
                      error={{
                        message: t(
                          'ecomPickingList.listContainsPickFails',
                          'Picking list contains failed to pick orders. Please try again.'
                        ),
                      }}
                    />
                  </Col>
                )}
                <Col xs={12} sm={12} md={8} className="d-flex flex-wrap mt-2 justify-content-center justify-content-md-start">
                  <RecommendedBoxes orderIds={data?.map((x) => x.order_id) || []} recommendedBoxes={recommendedBoxes} />
                </Col>
                <Col xs={12} sm={12} md={4} className="d-flex justify-content-center justify-content-md-end">
                  {data?.length > 0 && (
                    <Form inline>
                      <Form.Label className="my-1 mr-2 small">
                        {`${t('shared.groupBy', 'Group by').toUpperCase()}: `}
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        as="select"
                        className="my-1 mr-sm-2"
                        value={groupedBy || 'null'}
                        custom
                        onChange={(e) => setGroupedBy(e.target.value === 'null' ? null : e.target.value)}
                      >
                        <option value="null">{t('shared.noGroup', 'No group')}</option>
                        <option value="department_label">{t('shared.department', 'Department')}</option>
                        <option value="sub_department_label">{t('shared.subdepartment', 'Sub department')}</option>
                        <option value="family_label">{t('shared.family', 'Family')}</option>
                        {hasWarehouse && <option value="alley">{t('shared.alley', 'Alley')}</option>}
                      </Form.Control>
                    </Form>
                  )}
                </Col>
              </Row>
              <div className="row border-bottom" />
              {groupedBy
                ? Object.keys(groupedItems).sort().map((name) => renderGroup(name, groupedItems[name]))
                : data?.map((product) => renderRow(product))}
            </>
          )}
          {!isTrolleyLoading && activeTab === 'trolleySummary' && (
            <div className="trolley-summary">
              <h2 className="text-center">
                <Trans i18nKey="trolley.summary">Trolley</Trans>
              </h2>
              <EcomTrolleyContainer
                boxes={boxes || []}
                trolley={trolley || null}
                itemInfos={itemInfos || []}
                isLoading={isItemsInfoLoading || isBoxesLoading}
                packCount={packCount}
                store={store}
                user={user}
                updateTokens={updateTokens}
                onError={setError}
                onRefresh={() => {
                  refetchPl();
                  refetchTrolley();
                }}
                pickingListCount={pickingListCount}
                statusResolver={resolvePickingOrderStatus}
              />
            </div>
          )}
        </div>
      </div>
      {imageModalData && (
        <ImageModal
          pixlId={imageModalData.pixlId}
          item={imageModalData.item}
          item_description={imageModalData.item_description}
          tags={(
            <>
              {imageModalData.price && (
                <span className="tag tag-accent">
                  {`${imageModalData.price.toFixed(2)} ${imageModalData.currency || ''}`}
                </span>
              )}
              {imageModalData.endOfLife && (
                <span className="tag tag-warning">
                  {t('items.endOfLife').toUpperCase()}
                </span>
              )}
              {imageModalData.width && (
                <span className="tag">
                  {`${t('shared.width', 'Width')}: ${imageModalData.width}cm`}
                </span>
              )}
              {imageModalData.height && (
                <span className="tag">
                  {`${t('shared.height', 'Height')}: ${imageModalData.height}cm`}
                </span>
              )}
              {imageModalData.length && (
                <span className="tag">
                  {`${t('shared.length', 'Length')}: ${imageModalData.length}cm`}
                </span>
              )}
            </>
          )}
        />
      )}
      {dialog === 'confirmDelete' && (
        <Confirm
          show
          onHide={handleDialogClose}
          onConfirm={() => {
            handleDialogClose();
            doDeletePickingRow(selectedPl);
          }}
          icon={<i className="modal-header-icon text-danger vtmn-icon_delete vtmn-icon-22px" />}
          body={<Trans i18nKey="ecomPickingList.removingPickingListsQuestion">Are you going to remove all picking lists for this order?</Trans>}
          buttonOpts={{
            variant: 'danger',
            text: <Trans i18nKey="ecomPickingList.removingPickingLists">Remove picking lists</Trans>,
          }}
        />
      )}
      {dialog === 'pickConfirm' && (
        <PickConfirmModal
          trolley={trolley}
          pickingList={selectedPl}
          recommendedBoxes={recommendedBoxes}
          boxes={boxes}
          show
          onHide={handleDialogClose}
          onConfirm={(boxNumber, package_id, qty, force) => {
            pickItem(boxNumber, package_id, qty, force);
          }}
          loading={isProcessing}
        />
      )}
      {dialog === 'cantPick' && (
        <CantPickDialog
          item={selectedPl}
          show
          ifStoreFulfiller={selectedPl && selectedPl.source === 'stores_fulfiller_api'}
          loading={isProcessing}
          onHide={handleDialogClose}
          onConfirm={cantPickItemConfirm}
        />
      )}
      {error && <ErrorDialog error={error} setError={setError} />}
    </WithSidebar>
  );
};

export default EcomPickingList;
