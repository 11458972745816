import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import useRequest from '../../../functions/useRequest';
import Loader from '../../../components/Loader';
import ErrorMessage from '../../../components/widgets/errorMessage';
import ItemScannerInput from '../../../components/widgets/ItemScannerInput';
import AddressScannerInput from '../../../components/widgets/AddressScannerInput';
import { OrderToMountMessage, SameItemError } from '../messages';
import {
  getUnexpected,
  resolveProblemUnexpected,
  assignOrderFromWrongStore,
  unexpectedBackToSale,
} from './api';
import { CanceledMessage, CompletedMessage, UnexpectedMessage, WrongStoreMessage } from './messages';
import ProblemDetails from './ProblemDetails';
import { TNRelatedSgippingGroupMessage } from '../../../components/widgets/RelatedSgippingGroupMessage';
import ItemToMountSwitch from '../../OrdersMount/components/ItemToMountSwitch';
import { useStoreInfoReduxState } from '../../../store/storeInfo';

const ProblemUnexpected = ({
  id,
  user,
  updateTokens,
  onClose,
  epc,
  setEpc,
}) => {
  const { t } = useTranslation();
  const [{
    loading: fetching,
    error: fetchError,
    data: problem,
  }, fetchUnexpected] = useRequest(getUnexpected);
  const { store } = useParams();
  const { store: storeInfo } = useStoreInfoReduxState();
  const [actionError, setActionError] = useState(null);
  const [step, setStep] = useState('call');
  const [itemId, setItemId] = useState('');
  const [address, setAddress] = useState('');
  const [isTomount, setIsTomount] = useState(false);
  const [validatedAddress, setValidatedAddress] = useState(null);
  const isOrderToMount = problem?.order?.is_order_tomount;
  const mountStatus = problem?.order?.mount_status;

  useEffect(() => {
    if (id) {
      setActionError(null);
      setStep('call');
      setItemId('');
      setAddress('');
      fetchUnexpected(user, updateTokens, id, store);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [{
    loading: resolving,
  }, doResolve] = useRequest(resolveProblemUnexpected, {
    onSuccess: onClose,
    onError: setActionError,
  });

  const [{
    loading: puttingBack,
  }, doBackToSale] = useRequest(unexpectedBackToSale, {
    onSuccess: onClose,
    onError: setActionError,
  });

  const [{
    loading: assigning,
  }, doAssign] = useRequest(assignOrderFromWrongStore, {
    onSuccess: (res) => {
      onClose();
    },
    onError: setActionError,
  });

  const loading = fetching || resolving || puttingBack || assigning;

  const isCanceled = problem?.shipping_group?.is_canceled;
  const isCompleted = problem?.shipping_group?.is_completed;
  const isWrongStore = !isCanceled && !isCompleted && problem?.shipping_group?.is_wrong_store;
  const isUnexpected = !isCanceled && !isCompleted && !isWrongStore;
  const isCallCustomerStep = isWrongStore && step === 'call';
  const isAssignStep = isWrongStore && step === 'assign';
  const showBackToSale = isCanceled || isCompleted || (isWrongStore && step === 'putBack');
  const sameItemError = isAssignStep && actionError && actionError.response?.has_same_item;
  const hasRelatedSG = !!problem?.related_shipping_groups?.length;

  return (
    <Modal
      show={!!id}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <Trans i18nKey="reviewProblems.unexpectedItem">Unexpected item</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        {loading && <Loader />}
        {!loading && !mountStatus && !!isOrderToMount && (
          <OrderToMountMessage />
        )}
        {!loading && hasRelatedSG && (
          <TNRelatedSgippingGroupMessage
            relatedShippingGroups={problem.related_shipping_groups}
            mode="contained"
          />
        )}
        {!loading && fetchError && <ErrorMessage error={fetchError} />}
        {!loading && !fetchError && !!problem && (
          <>
            <ProblemDetails problem={problem}/>
            {sameItemError && (
              <SameItemError
                onClose={() => setActionError(null)}
                onForceAssignSameItem={() => doAssign(user, updateTokens, {
                  id,
                  store,
                  item_id: itemId,
                  address,
                  is_tomount: isTomount,
                  original_store: problem.shipping_group.storeId,
                  force_same_item_to_address: true,
                })}
              />
            )}
            {!sameItemError && actionError && <ErrorMessage error={actionError} />}
            {isWrongStore && <WrongStoreMessage problem={problem} step={step} />}
            {isCanceled && <CanceledMessage />}
            {isCompleted && <CompletedMessage />}
            {isUnexpected && <UnexpectedMessage />}
            {isAssignStep && (
              <>
                <ItemToMountSwitch
                  active={storeInfo?.to_mount_enabled}
                  isTomount={isTomount}
                  setIsTomount={setIsTomount}
                  isOrderToMount={isOrderToMount}
                  mountStatus={mountStatus}
                />
                <ItemScannerInput
                  user={user}
                  updateTokens={updateTokens}
                  placeholder={t('reviewProblems.inputItemCode', 'Scan item...')}
                  value={itemId}
                  onChange={setItemId}
                  setEpc={setEpc}
                  isValid={itemId && itemId === problem.item.item_id}
                  isInvalid={itemId && itemId !== problem.item.item_id}
                  invalidMessage={t('reviewProblems.invalidItemCode', 'Item code is not correct')}
                />
                <AddressScannerInput
                  user={user}
                  updateTokens={updateTokens}
                  store={store}
                  placeholder={t('reviewProblems.inputAddress', 'Scan address...')}
                  value={address}
                  onChange={setAddress}
                  validatedAddress={validatedAddress}
                  setValidatedAddress={setValidatedAddress}
                />
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          <Trans i18nKey="shared.close">Close</Trans>
        </Button>
        {!fetching && (isUnexpected || actionError) && (
          <Button
            variant="success"
            disabled={loading}
            onClick={() => doResolve(user, updateTokens, store, id)}
          >
            <Trans i18nKey="reviewProblems.resolve">Mark resolved</Trans>
          </Button>
        )}
        {!fetching && isCallCustomerStep && (
          <div className="m-0 text-right">
            <Button
              variant="primary"
              disabled={loading}
              onClick={() => setStep('putBack')}
            >
              <Trans i18nKey="reviewProblems.cantPick">No, put the quantities in the store stock</Trans>
            </Button>
            <Button
              variant="primary"
              disabled={loading}
              onClick={() => setStep('assign')}
            >
              <Trans i18nKey="reviewProblems.canPick">Yes, customer will come</Trans>
            </Button>
          </div>
        )}
        {!fetching && showBackToSale && (
          <Button
            variant="primary"
            disabled={loading}
            onClick={() => doBackToSale(user, updateTokens, store, id)}
          >
            <Trans i18nKey="reviewProblems.putInStock">Put in store stock</Trans>
          </Button>
        )}
        {!fetching && isAssignStep && (
          <Button
            variant="primary"
            disabled={
              loading
              || itemId !== problem.item.item_id
              || !address
              || validatedAddress !== address
            }
            onClick={() => doAssign(user, updateTokens, {
              id,
              store,
              item_id: itemId,
              epc,
              address,
              is_tomount: isTomount,
              original_store: problem.shipping_group.storeId,
            })}
          >
            <Trans i18nKey="reviewProblems.assign">Assign</Trans>
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ProblemUnexpected;
